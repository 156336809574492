import React from "react"
import {connect} from "react-redux";
import {Info} from "../../icons/Info";
import {Plus} from "../../icons/Plus";
import {Trash} from "../../icons/Trash";
import {withTranslation} from 'react-i18next';
import GLDetails from '../../modals/GLDetails';
import CardCommon from './CardCommon';

class CardGL extends CardCommon {

    render() {
        const {t} = this.props
        return (
            <>
                <div className={'fq_card'}>
                    <p className={'fq_card_ttl'}>{t('common.gl_policy')}</p>
                    {this.getPolicyCarrier()}
                    {this.getPolicyPrice()}
                    <div className="fq_card_controls">
                      <button type={'button'}
                              className={'fq_card_btn'}
                              onClick={() => {
                                this.openQuoteDetails()
                              }}>
                        <Info className={'icon_info'}/> <span>{t('common.btn_details')}</span>
                      </button>
                      <button type={'button'}
                              className={'fq_card_btn'}
                              onClick={() => {
                                this.props.policyToggle()
                              }}
                      >{this.props.selected ? (
                        <>
                          <Trash className={'icon_trash'}/> <span>{t('common.btn_remove')}</span>
                        </>
                      ) : (
                        <>
                          <Plus className={'icon_plus'}/> <span>{t('common.btn_add')}</span>
                        </>
                      )}</button>
                    </div>
                </div>
                <GLDetails open={this.state.details}
                           quote={this.props.quote}
                           quotes={this.props.quotes}
                           answers={this.props.answers}
                           gl_limit_available={this.props.bop_limit_available}
                           price={this.getPolicyPrice()}
                           onClose={() => this.closeQuoteDetails()}/>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        quotes: state.insurance.quotes,
        answers: state.insurance.answers,
        bop_limit_available: state.insurance.bop_limit_available,
        oc_availability: state.insurance.oc_availability
    }
}

export default connect(mapStateToProps)(withTranslation()(CardGL))
