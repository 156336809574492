import i18next from "i18next";
import {initReactI18next} from "react-i18next";
/*
//
// import { withTranslation } from 'react-i18next';
// ...
// const {t} = this.props
// ...
// withTranslation()(COMPONENT)
//
//
// RENDER STRING
// JSON:     doc_gen: "Documents are being generated"
// TEMPLATE: <p>{t('doc_gen')}</p>
//
//
// RENDER STRING WITH VAR
// JSON:     tria_incl: "TRIA included {{value}}/year"
// TEMPLATE: <p>{t('tria_incl', {value: "SOME_VALUE" })}</p>
//
//
// RENDER STRING WITH TAGS
// JSON:     automatically_deducted: "Payment to be automatically deducted on
//                               <bold>{{date}}</bold> from {{payment_type}} ending
//                               in <bold>{{last4}}</bold>",
// TEMPLATE: <Trans i18nKey="automatically_deducted"
//                 components={{ bold: <strong /> }}
//                 values={{
//                    date: SOME_DATE,
//                    payment_type: SOME_TYPE,
//                    last4: SOME_LAST4
//                  }}/>
//
//
// RENDER ARRAY OF OBJECTS
// JSON:      list: [
//                  {
//                      icon: "building",
//                      txt: "Fire damage to buildings and fire department service charges",
//                  },
//                  {
//                      icon: "computer",
//                      txt: "Debris removal and increased cost of construction",
//                  }
//              ]
// TEMPLATE: {t('cover_bop.property.list', {returnObjects: true}).map(({icon, txt}) => (
//           <li>
//               <i>
//                   <img src={`https://b2z-public-images.s3.amazonaws.com/icon-${icon}.png`} alt=""/></i>
//                   {txt}
//           </li>))}
//
*/
const resources = {
    en: {
        translation: {
            auth: {
                title: {},
                email_confirm: "Is this the email address you prefer to use for your payment and account creation?",
                email_enter: "Please enter your email to complete your payment.",
                pass_reset: "Reset your password",
                found_already_registered: "Welcome Back!",
                validation_n_setup: "Email Validation & Account Setup",
                sign_in_into_acc: "We found that you have a previous quote or account. Please sign in to continue.",
                sign_up_txt: "Please check your email for the temporary password to validate your account. You will be unable to purchase a policy without a validated email.",
                user_email: "Enter user email to complete payment.",
                current_pass: "Current Password",
                current_pass_tooltip: "Your current password. Leave it blank if you don't want to change the password.",
                email_login: "Login (Email)",
                pass: "Password",
                new_pass: "New Password",
                new_pass_tooltip: "Must contain at least one of every type: number, lowercase character, uppercase character, and symbol. Much be 8 to 16 characters in length. No spaces allowed.",
                confirm_pass: "Confirm Password",
                confirm_pass_tooltip: "Must be equal to your new password above.",
                temp_pass: "Temporary password from email",
                forgot_pass_title: "Forgot Password",
                forgot_pass_txt: "Enter the email address associated with your account to reset your password.",
                forgot_pass_reset_link: "Reset Link Sent!",
                forgot_pass_txt_2: "If your email is found in our system a link to reset your password will be sent to {{value}}. Follow the link to reset your password.",
                finalize_reg_title: "Complete Your Registration",
                finalize_reg_reset_sent: "Reset password email sent. Check out your email.",
                sign_in_title: "Welcome Back",
                sign_in_expired: "Your session is expired.",
                sign_in_forgot: "Forgot password?",
                sign_in_sub_title: "New to Acrisure?",
                sign_in_have_account: "Don't have an account? Get a quote here: ",
                error: {
                    invalid_email: 'Please enter a valid email address.',
                    invalid_req_email: 'User email is required and must be a correct email',
                    invalid_req_login: 'Login is required and must be a correct email',
                    invalid_temp_pass: 'Invalid temporary password',
                    code_req: 'Code is required',
                    pass_req: 'Password is required',
                    temp_pass_req: 'Temporary password is required',
                    pass_length: 'Password must be in range of 8  to 16 symbols length',
                    pass_not_contains: 'Password must contain: at least one of: uppercase, lowercase symbol, digit, special symbol and contain no whitespaces',
                    pass_not_equal: 'Must be equal to password',
                    user_not_found: 'User with such email is not found',
                    unexpected_error_please_retry: 'An unexpected error was encountered. Please try to reset your password again',
                    invalid_credentials_code: 'Incorrect credentials/verification code or reset session inspired. Please verify input or try reset your password again',
                    not_agent: 'This account is not allowed to login here',
                    auth: "Authorization error",
                    login_or_pass: "Incorrect username or password.",
                },
                btn: {
                    pass_reset: "Reset Password",
                    pass_reset_login: "Reset Password & Sign In",
                    temp_resend: "Resend temporary password",
                    pass_change: "Change Account Password",
                    sign_in: "Sign In",
                    complete_sign_in: "Complete & Sign In",
                    request_quote: "Request a Quote"
                }
            },
            common: {
                btn_download_all: "Download All",
                btn_download: "Download",
                btn_submit: "Submit",
                btn_disagree: "Disagree",
                btn_agree: "Agree",
                industry: "Industry",
                employees: "Employees",
                locations: "Locations",
                primary_location: "Primary Location",
                payroll: "Payroll",
                policy_duration: "Policy Duration",
                twelve_month: '12 month',
                wc_medical_limit: 'WC Medical Limit',
                owners_officers: 'Owners/Officers',
                premium_amount: 'Total Premium Amount',
                month: 'month',
                autofilled: 'Autofilled',
                autofilled_title: 'Based on the information about your company that we found over the internet open sources',
                billed_monthly: "Monthly Premium",
                billed_annually: "Annual Premium",
                policy_number: 'Policy Number',
                month_short: "mo",
                month_long: "month",
                year_short: "year",
                yes: "Yes",
                no: "No",
                none: "None",
                email: "Email",
                policy: "policy",
                pay_due: "Payment Due",
                pay_amount: "Payment Amount",
                pay_amount_total: "Total Payment Amount",
                na: "N/A",
                unlimited: "Unlimited",
                total_business_limit: "Total Business limit",
                payments: "Payments",
                hide_details: "Hide details",
                hide_policy_details: "Hide Policy Details",
                show_details: "Show details",
                show_policy_details: "Show Policy Details",
                claim: "Claim",
                submitted: "Submitted",
                cvc: "CVC*",
                exp: "Exp.*",
                card_num: "Card Number*",
                claim_num: "Claim Number",
                connect_bank_acc: "Connect Your Bank Account",
                connect_bank_acc_error: "You need to connect Your bank account",
                select_payment_method: "You need to select payment method",
                cardholder_req: "Cardholder name is required",
                exp_req: "Field is required and must be a valid expiry date",
                cc_num_req: "Field is required and must be a valid credit card number",
                ba_exist_err: "A bank account with that routing number and account number already exists for this customer",
                total_due_today: "Total due today",
                bank_account: "Bank Account",
                credit_card: "Credit Card",
                ba_account_last4: "Bank Account ({{num}})",
                cc_last4: "Credit Card ({{num}})",
                bank_select_account: "Select Account",
                account_mask: "{{type}} (last 4 digits - {{mask}})",
                choose_existing_acc: "Choose existing payment method",
                choose_existing_acc_placeholder: "Choose existing payment method",
                choose_or_add_method: "Choose an existing payment method or add a new one.",
                no_polices_selected: "You need to select at least one policy to purchase",
                bank: "bank account",
                card: "card",
                payment_date: "Payment Date",
                add_new_pay: "Add a New Method",
                cookie_disabled_title: "Ugh... Something went wrong",
                cookie_disabled_desc: "Cookies must be enabled on your device to optimize your quote.",
                btn_complete_purchase: "Complete Purchase",
                btn_back_exit_pay_method: "Back to existing payment methods",
                cc_holder_name: "Full Name on Credit/Debit Card*",
                cc_holder_name_place_holder: 'Enter Full Name on Card',
                cc_number_place_holder: 'Enter Card Number',
                btn_customize_short: "Customize",
                is_selected: "Policy is selected",
                btn_download_quote: "Download Quote",
                btn_save_changes: "Save changes",
                btn_apply: "Apply",
                btn_view_faqs: "View All FAQs",
                btn_add: "Add",
                btn_add_more: "Add More",
                btn_add_addIns: "Add Additional Insured",
                btn_details: "Details",
                btn_change_billing_address: "Change Billing Address",
                tria: "TRIA",
                bop_policy: "Business Owner's Policy",
                pl_policy: "Professional Liability Policy",
                cyber_policy: "Cyber Policy",
                gl_policy: "General Liability Policy",
                crum_for: "Crum & Forster",
                acuity: "Acuity",
                rated: "Rated A (A.M. Best)",
                rated_a_plus: "Rated A+ (A.M. Best)",
                occurrence_limit: "Occurrence Limit",
                aggregate_limit: "Aggregate Limit",
                total_prop_limit: "Total Property Limit",
                building_limit: "Building Limit",
                personal_property_limit: "Personal Property Limit",
                premises_rented: "Damage To Premises Rented To You",
                medical_limit: "Medical Limit",
                limit: "Limit",
                deductible: "Deductible",
                damage_deductible: "Property Damage Deductible",
                eff_date: "Effective date",
                exp_date: "Expiration date",
                tria_incl: "TRIA included {{value}}/year",
                pli: "Professional Liability Insurance",
                per_occur: "Per Occurrence Limit",
                per_empl: "Per Employee Limit",
                per_policy: "Total Policy Limit",
                ann_aggregate: "Annual Aggregate Limit",
                cyber_ins: "Cyber Insurance",
                eb_ins: "Equipment Breakdown Insurance",
                hnoa_ins: "Hired And Non-Owned Auto (HNOA) Insurance",
                wc_policy: "Workers' Compensation Policy",
                wc: `Workers' Compensation`,
                gl: "General Liability",
                bop: `Business Owner's Policy`,
                insurer: "Insurer",
                insurer_emp: "Employers Preferred Insurance Company",
                insurer_cf: "Crum and Forster",
                insurer_acuity: "Acuity, A Mutual Insurance Company",
                provider_underwritten_by_cf: "Crum & Forster (C&F), a registered trademarks of United States Fire Insurance Company | Morristown, NJ 07960",
                coverages: "Coverages",
                employers: "EMPLOYERS®",
                ins: "Insurance",
                buildings: "Buildings",
                started: "{{businessName}} - Started on {{date}}",
                alert_success: "Success!",
                downloading: "Downloading",
                btn_learn_more: "Learn More",
                btn_change_pay_method: "Change Payment Method",
                btn_update_pay_method: "Update billing Information",
                btn_please_update_pay_method: "Please update your billing Information",
                btn_claim_details: "View Claim Details",
                btn_file_claim: "File a Claim",
                btn_resume_quote: "Resume Quote",
                btn_get_policies: "Get these policies",
                btn_get_policy: "Get this policy",
                btn_notify_me: "Notify Me",
                btn_reset_chat: "Reset Chat",
                btn_send: "Send",
                btn_save: "Save",
                btn_accept: "Accept",
                btn_edit: "Edit",
                btn_continue: "Continue",
                btn_continue_pay: "Continue With Payment",
                btn_back: "Back",
                btn_close: "Close",
                btn_cancel: "Cancel",
                btn_remove: "Remove",
                show_more: "Show more",
                show_less: "Show less",
                expand: "Expand",
                show: "Show",
                collapse: "Collapse",
                btn_add_owner: "Add Additional Owner",
                btn_add_address: "Add Another Address",
                btn_add_payroll: "Add Payroll Class",
                included: "included",
                excluded: "excluded",
                progress: "Complete",
                in_progress: 'In Progress',
                btn_save_progress: "Save Your Progress",
                validate_email: "*We value your data privacy. Read our <a href=\"https://www.acrisure.com/privacy-center\" target=\"_blank\" rel=\"noopener noreferrer\" class='txt_link'>privacy policy here</a>.",
                wc_locations_address_errors: "There are errors in address",
                maintenance_title: "Ugh... we’re experiencing technical issues.",
                maintenance_desc: "No worries: Our team is working to resolve them quickly! Check back soon.",
                header_logged_as: "Logged in as agent",
                header_hello: "Hello, {{value}}!",
                header_home: "Dashboard",
                header_claims: "Claims",
                header_settings: "Settings",
                header_payment_center: "Payment Center",
                header_contact: "Contact",
                header_profile: "Profile",
                header_logout: "Logout",
                contact_number: "800-330-1750",
                // footer_disclaimer: "The information contained in this website is for illustrative purposes only. Coverage issuance is subject to underwriting and is expressly subject to the conditions, restrictions, limitations, exclusions and terms of the policy documentation issued by the insurer. Availability of this program is subject to each state's approval and coverage may vary by state. Inaccurate information provided may result in the re-rating of the policy and/or the cancellation of the policy. Insurance policies are underwritten by the preferred partners <a href='https://www.b2z-insurance.com/about#partners' target='_blank'>listed on our website</a>. Plans are administered by B2Z Insurance Inc., also operating under the name B2Z Insurance Services in California and New York. Coverage Tune-Up and Diya are separate non-insurance services administered wholly by B2Z Insurance Inc. They are not intended to replace licensed insurance agent guidance on coverage.",
                footer_disclaimer: "100 Ottawa Avenue SW, Grand Rapids, MI 49503",
                // footer_copy: "Copyright © {{year}} B2Z Insurance Inc. All rights reserved. <a href='https://www.acrisure.com/privacy-policy' target='_blank'>Privacy Policy</a>",
                footer_copy: "© {{year}} Acrisure, LLC. All rights reserved.",
                user_prop_saved_ok: "User props updated successfully",
                pass_updated_ok: "Your password updated",
                user_prop_not_saved: "Something went wrong with data saving...",
                something_went_wrong: "Something went wrong. Please try again",
                leave_alert_message: "Are you sure you want to leave? Changes will not be saved.",
                animation_await_message: "(This may take at least a few seconds)",
                email_validation_animation_strings: ["Validating your email..."],
                fq_validation_animation_strings: ["We're working on your quote..."],
                animation_strings: [
                    'Selecting the best-priced options for your business...'
                ],
                overdue: 'overdue',
                declined: 'Declined',
                paid: 'Paid',
                active: 'Active',
                empty: "Empty",
                payment_schedule: "Payment Schedule",
                not_match_for_quote: "Based on the information you provided you are not a match for a {{value}} right now. We are always updating our policies and criteria and may have a policy that suits you in the future.",
                // max_limit: "This is the maximum amount the insurer will pay for all claims resulting from a single occurrence."
            },
            customize_card:{
              wc:{
                title:"Workers' Compensation Policy Limits & Deductible",
                txt:"The following are the limits of liability for employers liability coverage under your Workers' Compensation Policy."
              },
              bop:{
                title:"Business Owner's Policy Limits & Deductible",
                txt:"The following are the limits of liability for your Business Owner's Policy."
              },
              gl:{
                title:"General Liability Policy Limits & Deductible",
                txt:"The following are the limits of liability for your General Liability Policy."
              },
              pl:{
                title:"Professional Liability Policy Limits & Deductible",
                txt:"The following are the limits of liability for your Professional Liability Policy."
              },
              cyber:{
                title:"Cyber Insurance Policy Limits & Deductible",
                txt:"This is the maximum amount the insurer will pay for all claims resulting from a single occurrence."
              },

            },
            form: {
                label: {
                    first_name: "First name",
                    last_name: "Last name",
                    phone: "Phone Number",
                    phone_num: "Primary Phone Number",
                    address: "Address",
                    address2: "Floor/Apt/Suite Number",
                    address_line1: "Address Line 1",
                    address_line2: "Address Line 2 (Optional)",
                    email: "Email address",
                    e_mail: "Email",
                    e_mail_required: "Your Email*",
                    e_mail_friends: "Your Friend’s Email",
                    street: "Street address",
                    floor: "Floor/Apt/Suite Number",
                    city: "City",
                    state: "State",
                    zip: "ZIP Code",
                    primary: "Primary",
                    billing_address: "Billing Address",
                    employees_qty_at_location: "How many full time employees do you have at this location for payroll*",
                    industry_class: "Payroll class*"
                },
                placeholder: {
                    share_emails: "Type or paste email addresses and press `Space`...",
                    billing_address_placeholder: "Use primary business address",
                    industry_class: "Select a class from list",
                    business_type: "Start typing business description",
                    payroll_amount: "Payroll amount*"
                },
                error: {
                    bn_req: "Business name is required",
                    field_req: "Field is required",
                    first_name_req: "First name is required",
                    last_name_req: "Last name is required",
                    st_address_req: "Street address is required",
                    st_address2: "Suite number must be at least 3 characters long",
                    city_req: "City is required",
                    state_req: "State is required",
                    zip_req: "Zip code is required",
                    employee_num_req: "Number of employees is required",
                    incorrect_phone: "Please enter a valid phone number.",
                    invalid_email: "You must enter the valid email",
                    incorrect_email: "Please enter a valid email address.",
                    invalid_email_req: "Field is required and must be a valid email address",
                    invalid_email_save_progress: "Valid email address is required.",
                    added_email: "{{val}} has already been added.",
                    incorrect_amount: "Incorrect amount",
                    pass_string: "Password must contain at least one of each: uppercase, lowercase, special character, and number. Password cannot contain any spaces.",
                    rep_pass_mismatch: "Must be equal to password",
                    has_errors: "Form has errors",
                    max_permitted_value: "Maximum permitted value is {{value}}",
                    min_permitted_value: "Minimum permitted value is {{value}}",
                    // eslint-disable-next-line
                    min_payroll_value: "Please enter an amount greater than {{value}} for estimated payroll.",
                    max_payroll_value: "Please enter an amount less than  or equal to {{value}} for estimated payroll.",
                    max_permitted_date: "Maximum permitted date is {{value}}",
                    min_permitted_date: "Minimum permitted date is {{value}}",
                    business_type: "You must select business type",
                    industry_class: "Select an industry class",
                    employees_qty: "The combined total of full and part-time employees must be {{qty}} or less.",
                    min_array_entries: "Minimum of {{qty}} entries expected",
                    to_much_chars: "Your response should be {{val}} characters",
                    at_least_chars: "Your response should be at least {{val}} characters",
                    no_more_than_chars: "Your response should be no more than {{val}} characters",
                    date_in_future: "{{val}}  must be in future",
                    date_in_past: "{{val}} must be in past",
                    incorrect_date: "{{val}} must be a correct date",
                    fien_incorrect_date: "This date must be correct."
                },
                no_options_text: "Start typing to search",
                class_no_options_text: "No industry class found with that name"
            },
            modal: {
                delayed_quote: {
                    title: 'Your quote is taking a little longer than usual. Here are two options:',
                    option_1: 'Keep this window open and we will try to have your quote ready in a few minutes.',
                    option_2: 'Close this window and we will try to email your quote as soon as possible.'
                },
                hnoa_rejection: {
                    title: "We cannot offer your business HNOA coverage at this time. ",
                    txt: "This coverage will be removed and will not be reflected in your quote.",
                    sub_txt: "*HNOA does not cover vehicles owned by the business. It typically covers rented vehicles or vehicles owned by employees used for business purposes. "
                },
                cover_tria: {
                    btn: "What's covered?",
                    title: "Terrorism Risk Insurance Act ",
                    txt: "Losses resulting from acts of terrorism are generally not considered to be insurable and are excluded from  most insurance policies.  The Terrorism Risk Insurance Act (TRIA) created a government backstop in partnership with insurance companies to help cover some of those losses.",
                    question: "What’s Covered?",
                    list: [
                        {
                            icon: "dollar.svg",
                            txt: "A portion of losses resulting from Certified Acts of Terrorism as defined in TRIA and certified by the U.S. Secretary of the Treasury"
                        }
                    ]

                },
                not_cover_tria: {
                    question: "What’s Not Covered?",
                    list: [
                        {
                            icon: "notify.svg",
                            txt: "Losses from uncertified terrorist actions"
                        },
                        {
                            icon: "notify.svg",
                            txt: "Losses from Nuclear, Biological, Chemical, or Radiological attacks regardless of certification under TRIA except for fire losses following a certified event"
                        }
                    ]
                },
                cover_hnoa: {
                    btn: "What's covered?",
                    title: "Hired And Non-Owned Auto (HNOA) Insurance ",
                    txt: "Protects your business from losses involving vehicles that are driven for business purposes but are personally owned by your employees or rented by your company or employees.",
                    cover: {
                        heading: "What’s Covered?",
                        list: [
                            {
                                icon: "medical.svg",
                                txt: "Medical expenses if someone else is injured"
                            },
                            {
                                icon: "notify.svg",
                                txt: "Damage to someone else’s property"
                            },
                            {
                                icon: "gavel.svg",
                                txt: "Attorney fees, and related legal and judgement costs assessed to your business"
                            },
                            {
                                icon: "dollar.svg",
                                txt: "For personal autos, coverages kicks in once you or your employee’s personal automobile coverage has reached its limit"
                            }
                        ]
                    }
                },
                not_cover_hnoa: {
                    question: "What’s Not Covered?",
                    txt: "HNOA insurance typically does not cover…",
                    list: [
                        {
                            icon: "crash.svg",
                            txt: "Claims involving vehicles owned or regularly operated by your business",
                            tip: "Tip: The claims would generally be covered by your commercial auto policy."
                        },
                        {
                            icon: "crash-other.svg",
                            txt: "Physical damage to the hired or non-owned automobile",
                            tip: "Tip: The claims are generally covered by a personal auto insurance policy or the rental car agreement."
                        },
                        {
                            icon: "notify.svg",
                            txt: "Damage to property being transported, such as sales samples or tools",
                            tip: "Tip: This is generally covered by the Business Personal Property section of your BOP."
                        }
                    ]
                },
                cover_bop: {
                    btn: "Learn More",
                    title: "Business Owner's Policy",
                    txt: "You might think of a Business Owner's Policy (BOP) like a personal renter's or homeowner's policy—except a BOP is only for your business. A BOP may cover things like the building you use for your office and your business assets, as well as liability and injury expenses.\n",
                    question: "What May Be Covered?",
                    property: {
                        title: "Business Property",
                        txt: "Protects your business’ building and its contents",
                        list: [
                            {
                                icon: "fire.png",
                                txt: "Fire damage to buildings and fire department service charges"
                            },
                            {
                                icon: "bulldozer.png",
                                txt: "Debris removal and increased cost of construction"
                            },
                            {
                                icon: "building.png",
                                txt: "Income loss due to covered property damage"
                            },
                            {
                                icon: "robbery.png",
                                txt: "Income loss due to fraud and forgery"
                            }
                        ]
                    },
                    liability: {
                        title: "Liability",
                        txt: "Protects your business in the event of lawsuits and third-party claims",
                        list: [
                            {
                                icon: "plus.png",
                                txt: "Medical payments regardless of fault"
                            },
                            {
                                icon: "scale.png",
                                txt: "Legal defense against liability lawsuit"
                            },
                            {
                                icon: "gavel.png",
                                txt: "All court costs"
                            },
                            {
                                icon: "dollar.png",
                                txt: "Most interest and additional expenses resulting from a trial"
                            }
                        ]
                    }
                },
                not_cover_bop: {
                    question: "What May Not Be Covered?",
                    txt: "BOP Insurance typically does not cover…",
                    list: [
                        {
                            icon: "plus.png",
                            txt: "Your employees ",
                            tip: "Tip: Injuries to your employees are generally covered under a separate Workers Compensation policy."
                        },
                        {
                            icon: "crash.png",
                            txt: "Your company-owned vehicles ",
                            tip: "Tip: Claims involving your company-owned vehicles would generally be covered under a separate commercial auto policy."
                        },
                        {
                            icon: "gavel.png",
                            txt: "Intentional or Illegal acts—these just aren’t insurable.",
                            tip: ""
                        },
                        {
                            icon: "message_circle.png",
                            txt: "Professional Services—such as legal advice, medical services, optometry or pharmacy services, and, yes, hair styling",
                            tip: "Tip: Claims resulting from providing professional services are generally covered under a professional liability policy or endorsement."
                        },
                        {
                            icon: "notify.png",
                            txt: "Liquor liability",
                            tip: "Tip: There is a liquor liability endorsement specific for this coverage."
                        }


                    ]
                },

                cover_gl: {
                    btn: "Learn More",
                    title: "General Liability Policy",
                    txt: "A General Liability Policy typically covers damage or injury caused or allegedly caused by your business or employees to a third party, including bodily injury, damage to property, or personal and advertising injury. It differs from a BOP because it does not cover damage to your business's property.",
                    question: "What May Be Covered?",
                    cover_content: {
                        txt: "General Liability Insurance typically covers...",
                        list: [
                            {
                                icon: "plus.png",
                                txt: "Medical payments regardless of fault"
                            },
                            {
                                icon: "scale.png",
                                txt: "Legal defense against liability lawsuit"
                            },
                            {
                                icon: "gavel.png",
                                txt: "All court costs"
                            },
                            {
                                icon: "gavel.png",
                                txt: "Most interest and additional expenses resulting from a trial"
                            },
                            {
                                icon: "pr.png",
                                txt: "Advertising injury such as slander, libel, or copyright infringement"
                            }
                        ]
                    }
                },
                not_cover_gl: {
                    question: "What May Not Be Covered?",
                    txt: "General Liability Insurance typically does not cover...",
                    list: [
                        {
                            icon: "plus.png",
                            txt: "Your employees ",
                            tip: "Tip: Injuries to your employees are generally covered under a separate Workers Compensation policy."
                        },
                        {
                            icon: "building.png",
                            txt: "Your business property",
                            tip: "Tip: Claims involving your business property would be covered by obtaining a BOP that combines general liability and commercial property coverage instead."
                        },
                        {
                            icon: "crash.png",
                            txt: "Your company-owned vehicles ",
                            tip: "Tip: Claims involving your company-owned vehicles would generally be covered under a separate commercial auto policy."
                        },
                        {
                            icon: "gavel.png",
                            txt: "Intentional or Illegal acts—these just aren’t insurable.",
                            tip: ""
                        },
                        {
                            icon: "message_circle.png",
                            txt: "Professional Services—such as legal advice, medical services, optometry or pharmacy services, and, yes, hair styling",
                            tip: "Tip: Claims resulting from providing professional services are generally covered under a professional liability policy or endorsement."
                        },
                        {
                            icon: "notify.png",
                            txt: "Liquor liability",
                            tip: "Tip: There is a liquor liability endorsement specific for this coverage."
                        }
                    ]
                },

                cover_wc: {
                    btn: "Learn More",
                    title: "Workers’ Compensation Policy",
                    txt: "Workers Compensation (WC) is mandatory insurance that provides wage replacement and medical benefits to employees that are injured or disabled on the job.",
                    question: "What May Be Covered?",
                    wc: {
                        title: "Workers’ Compensation",
                        txt: "Typically covers medical costs and lost wages related to workplace injuries",
                        list: [
                            {
                                icon: "customer_injury.png",
                                txt: "Injury due to worksite accidents"
                            },
                            {
                                icon: "plus.png",
                                txt: "Medical treatment and ongoing care costs"
                            },
                            {
                                icon: "dollar.png",
                                txt: "Lost employee wages"
                            },
                            {
                                icon: "flower.png",
                                txt: "Funeral expenses and death benefits for fatal incidents"
                            }
                        ]
                    },
                    el: {
                        title: "Employers’ Liability",
                        txt: "Workers' Compensation also typically provides coverage for an employer who is sued by an employee for work-related bodily injury or illness",
                        list: [
                            {
                                icon: "scale.png",
                                txt: "Legal defense against employee injury lawsuits"
                            },
                            {
                                icon: "gavel.png",
                                txt: "Court costs and related fees"
                            },
                            {
                                icon: "dollar.png",
                                txt: "Interest and additional expenses resulting from a trial"
                            }
                        ]
                    }
                },
                not_cover_wc: {
                    question: "What May Not Be Covered?",
                    txt: "Workers’ Compensation insurance typically does not cover…",
                    list: [
                        {
                            icon: "plus.png",
                            txt: "Most injuries while commuting to or from work"
                        },
                        {
                            icon: "plus.png",
                            txt: "Injuries to an employee resulting from that employee engaging in horseplay"
                        },
                        {
                            icon: "robbery.png",
                            txt: "Injuries to the employee resulting from their own criminal behavior"
                        },
                        {
                            icon: "notify.png",
                            txt: "Injuries to the employee resulting from their own intoxication"
                        }

                    ]
                },
                cover_cyber: {
                    btn: "What's covered?",
                    title: "Cyber Insurance Policy",
                    txt: "Cyber Insurance protects your small business from internet-based risks and risks relating to information technology infrastructure, information privacy, information governance liability, and related activities.",
                    question: "What May Be Covered?",
                    cp: {
                        list: [
                            {
                                icon: "computer.png",
                                txt: "Network and information security liability"
                            },
                            {
                                icon: "gavel.png",
                                txt: "Regulatory defense and penalties"
                            },
                            {
                                icon: "dollar.png",
                                txt: "Breach response services and costs"
                            },
                            {
                                icon: "notify.png",
                                txt: "Multimedia content liability"
                            },
                            {
                                icon: "dollar.png",
                                txt: "PCI fines and assessments"
                            },
                            {
                                icon: "notify.png",
                                txt: "Crisis management and public relations"
                            },
                            {
                                icon: "service.png",
                                txt: "Cyber extortion"
                            },
                            {
                                icon: "pr.png",
                                txt: "Business interruption and extra expenses related to cyber attacks"
                            },
                            {
                                icon: "circle_alert.png",
                                txt: "Digital asset restoration"
                            },
                            {
                                icon: "fingerprint.png",
                                txt: "Identity recovery protection"
                            },
                            {
                                icon: "message_circle.png",
                                txt: "Reputational harm loss and repair"
                            },
                            {
                                icon: "robbery.png",
                                txt: "Funds transfer fraud (sub-limit of $250,000, sub-retention of $112,500)"
                            },
                            {
                                icon: "computer.png",
                                txt: "Computer replacement (sub-limit of $500,000)"
                            },
                            {
                                icon: "pr.png",
                                txt: "Service fraud (sub-limit of $100,000)"
                            },
                            {
                                icon: "plus.png",
                                txt: "1st party bodily harm and property damage (sub-limit of $250,000)"
                            },
                            {
                                icon: "pr.png",
                                txt: "Pollution (sub-limit of $250,000)"
                            }

                        ]
                    }
                },
                not_cover_cyber: {
                    question: "What May Not Be Covered?",
                    txt: "Cyber insurance typically does not cover…",
                    list: [
                        {
                            icon: "fingerprint.png",
                            txt: "Loss of intellectual property, including infringement or products, technology, patents or trade secrets."
                        },
                        {
                            icon: "pr.png",
                            txt: "Product recalls, including recall costs caused by security failures or cyber incidents."
                        },
                        {
                            icon: "bx_wind.png",
                            txt: "Natural disasters, including damage to data or assets caused by a natural disaster."
                        },
                        {
                            icon: "dollar.png",
                            txt: "Loss resulting from a cyber event at a third-party, such as your internet provider or payment vendor",
                            tip: "Tip: That is generally covered under their cyber policy, not yours."
                        }

                    ]
                },
                cover_eb: {
                    btn: "What's covered?",
                    title: "Equipment Breakdown Insurance",
                    txt: "Covers repairs and associated costs for a wide-range of vital business equipment following a mechanical breakdown or electrical failure.",
                    question: "What’s Covered?",
                    covered: {
                        txt: "Generally, the following types of equipment are covered:",
                        list: [
                            {
                                icon: "i_fan.svg",
                                txt: "Generators, fans, motors, compressors, and pumps"
                            },
                            {
                                icon: "i_wind.svg",
                                txt: "Heating and air conditioning equipment"
                            },
                            {
                                icon: "dollar.svg",
                                txt: "Drill presses, lathes, planers, and routers"
                            },
                            {
                                icon: "computer.svg",
                                txt: "Computer-controlled or automation equipment"
                            },
                            {
                                icon: "i_phone.svg",
                                txt: "Telephones, copiers, and printers"
                            },
                            {
                                icon: "i_barcode-scanner.svg",
                                txt: "Barcode scanners"
                            },
                            {
                                icon: "PR.svg",
                                txt: "Alarm systems"
                            }
                        ]
                    },
                    expenses: {
                        txt: "And the following related expenses:",
                        list: [
                            {
                                icon: "dollar.svg",
                                txt: "Cost of inspection and related maintenance"
                            },
                            {
                                icon: "i_time.svg",
                                txt: "Time and labor repair costs"
                            },
                            {
                                icon: "i_elevation-decline.svg",
                                txt: "Lost income due to production/work stoppage"
                            },
                            {
                                icon: "i_elevation-decline.svg",
                                txt: "Loss of inventory due to spoilage"
                            }
                        ]
                    }, causes: {
                        txt: "Typically, equipment breakdown coverage is based on the following causes:",
                        list: [
                            {
                                icon: "notify.svg",
                                txt: "Mechanical failure"
                            },
                            {
                                icon: "i_alarm-warning-line.svg",
                                txt: "Electrical current overload"
                            },
                            {
                                icon: "notify.svg",
                                txt: "Conditions within the equipment that cause it to cease operating "
                            }

                        ]
                    }
                },
                not_cover_eb: {
                    question: "What’s Not Covered?",
                    list: [
                        {
                            icon: "i_circle-alert.svg",
                            txt: "Ordinary wear and tear"
                        },
                        {
                            icon: "i_alarm-warning-line.svg",
                            txt: "Rust or corrosion"
                        },
                        {
                            icon: "notify.svg",
                            txt: "Faulty, inadequate, or defective maintenance"
                        }

                    ]
                },
                cover_mpl: {
                    btn: "Learn More",
                    title: "Professional Liability (Errors & Omissions Insurance)",
                    txt: "Professional Liability, or Errors and Omissions insurance (E&O), is coverage for customer claims of negligence or injury made about the professional services provided by your small business. Whether you are at fault or allegedly at fault this policy may provide coverage for related losses, attorneys' fees and court costs.\n",
                    question: "What May Be Covered?",
                    cfp: {
                        title: "Coverage for Professionals",
                        txt: "Typically covers expenses related to customer claims made against the professional services or advice provided by your company.",
                        list: [
                            {
                                icon: "plus.png",
                                txt: "Actual or alleged mistakes or negligence"
                            },
                            {
                                icon: "pr.png",
                                txt: "Inaccurate professional advice"
                            },
                            {
                                icon: "helpline.png",
                                txt: "Misrepresentation"
                            },
                            {
                                icon: "message_circle.png",
                                txt: "Personal injury, such as libel or slander"
                            },
                            {
                                icon: "fingerprint.png",
                                txt: "Copyright infringement"
                            },
                            {
                                icon: "notify.png",
                                txt: "Punitive damages"
                            },
                            {
                                icon: "employee_injury.png",
                                txt: "Claims against temporary staff and independent contractors"
                            },
                            {
                                icon: "dollar.png",
                                txt: "Related legal costs"
                            }
                        ]
                    }
                },
                not_cover_mpl: {
                    question: "What May Not Be Covered?",
                    title: "Professional Liability (Errors & Omissions Insurance)",
                    txt: "Professional Liability insurance typically does not cover…",
                    list: [
                        {
                            icon: "fingerprint.png",
                            txt: "Fraudulent, dishonest, or criminal acts"
                        },
                        {
                            icon: "employee_injury.png",
                            txt: "Employment practices"
                        },
                        {
                            icon: "dollar.png",
                            txt: "False advertising"
                        },
                        {
                            icon: "fingerprint.png",
                            txt: "Copyright, patent, or trademark infringement"
                        },
                        {
                            icon: "notify.png",
                            txt: "Regular claims not related to your expertise",
                            tip: "Tip: Those are generally covered under your regular BOP."
                        }

                    ]
                },
                contact_us: {
                    title: "ASK US ANYTHING",
                    txt: "Fill in your details, write your question, and we will back to you",
                    full_name: "Full name",
                    how_can_we_help: "How can we help?"
                },
                invalid_email_title: "We need a working email for you to access your quote and policy documents. The email address you provided is not a valid email address.",
                invalid_fein_title: "The FEIN entered is not valid. A valid FEIN is needed to provide your quote.",
                ooead_disclaimer: "You have selected to exclude one or more owner/officer from coverage. Do you agree to comply with the applicable workers’ compensation laws of {{value}}?",
                quote_not_eligible: {
                    title: "Unfortunately, one of your quotes is not eligible at the time.",
                    desc: "We will notify you if this quote becomes available in the future."
                },
                save_progress: {
                    title: "Save or Share Your Quote",
                    txt_verify: "To send a copy of this quote to yourself, enter your email address in the field below. If you’d like to send a copy to another person, enter their email below. Then click to submit.",
                    txt_add: "Add your email address so you can return to your application from any device without losing the progress you’ve made."
                },
                payment_schedule: {
                    first_payment: "First Payment: ",
                    policy_monthly: "Policy Monthly Payment Schedule",
                    paid: "Paid"
                },
                get_notified: {
                    title: "Get Notified",
                    verify_email: "Verify your email address",
                    enter_email: "Enter your email address below to be notified when we have a policy for you.",
                    notify_me: "Notify Me",
                    contact_me: "Contact Me",
                    your_policy: "your policy",
                    your_policies: "your policies",
                    wc: `Workers' Compensation Policy`,
                    bop: `Business Owner's Policy`,
                    pl: `Professional Liability Policy`,
                    gl: `General Liability Policy`,
                    cyber: `Cyber Policy`,
                    oops: "Oops! Something went wrong with your quote.",
                    unable_offer_at_time: "We are unable to offer you a quote at this time.",
                    unable_cover_at_time: "We are unable to cover your business at this time.",
                    unable_to_offer: "We are unable to offer you a quote at this time.",
                    unable_to_provide: "We are unable to provide a quote for your policy at this time. Please contact us if you have any questions.",
                    unable_to_provide_coverage: "Based on the information submitted, we are unable to provide coverage for {{value}} right now. We are expanding our business reach and may have a policy that matches your needs in the future. Please confirm your information below and click the “Notify Me” button, and we will reach out to you when we are able to serve your business.",
                    unable_to_provide_yet: "Based on the information provided, we are unable to provide coverage for {{value}} at this time. The good news is that we are expanding our business reach, and in the near future, may be able to serve your needs.",
                    receive_notifications: "If you would like to receive notification when coverage is available, please confirm your information below and click the “Notify Me” button. We look forward to reaching out at that time with coverage options for your business.",
                    retry_or_confirm: "We are unable to provide a quote for your policy at this time. Please contact us if you have any questions.",
                    notify_title: 'We are expanding our product availability and may be able to cover {{value}} in the near future.',
                    notify_description: `We are unable to provide a quote for your policy at this time. Please <a target="_blank" href="https://www.b2z-insurance.com/contact" class="txt_link">contact us</a> if you have any questions.`
                },
                get_notified_state: {
                    title: "Coverage not available in your state.",
                    txt: "Unfortunately, we are not able to offer insurance coverage to businesses in {{value}} right now. We are working to add coverage for more states and may have coverage available for your business soon.",
                    txt_2: ""
                },
                customize_plan: {
                    title: "Customize plan",
                    txt: "Edit your coverage to create a plan that works for you. Changing the limits on your policy will cause your premium costs to change.",
                    occurrence_txt: "This is the maximum amount the insurer will pay for all claims resulting from a single occurrence.",
                    building_txt: "Select your preferred annual limit for building damages claims.",
                    personal_property_txt: "Select your preferred annual limit for personal property claims.",
                    tria_txt: "Select if you accept an optional coverage for losses from Certified Acts under the Terrorism Risk Insurance Act (TRIA)",
                    wc_select_liability: "Select Liability Limits",
                    wc_select_liability_txt: "Select one of available options for policy liability limits",
                    each_claim: "Each Claim",
                    policy_limit: "Policy Limit",
                    each_employee: "Each Employee",
                    medical_limit_txt: "Choose the limit that will provide ample coverage for medical payments incurred per person within the policy year.",
                    damage_to_premises_rented_to_you_txt: "Choose the limit that will provide ample coverage for rented property damage incurred within the policy year.",
                    property_deductible_txt: "Choose your property damage deductible. Keep in mind, the selected deductible along with your chosen limit, will be factored into a claim payout.",
                    cyber_txt: "This optional coverage helps small businesses pay for customer notification and fraud monitoring costs, legal fees, and system damage associated with data breaches, malicious hacking, and cyberattacks.",
                    eb_txt: "This optional coverage is designed to provide relief for damages related to equipment/electronics breakdowns due to specific types of malfunction, such as power surges, electrical shorts, and mechanical breakdowns.",
                    hnoa_txt: "This optional coverage extends to employees driving personal vehicles or rental vehicles for business purposes, providing both liability for bodily injuries and property damage to third-parties involved in accidents.",
                    pl_txt: `This optional coverage (aka "errors and omissions insurance") typically covers work mistakes, missed deadlines, and professional negligence lawsuits for businesses providing expertise.`,
                    no_proposal: "There is no proposal in these boundaries"
                }
            },
            product: {
                bop: {
                    lob: "BOP",
                    lob_full: "Business Owner’s",
                    lob_policy_full: "Business Owner’s Policy",
                    quote_card: {
                        tria_included: "TRIA included {{value}}/year",
                        mpl_included: "PL included",
                        cyber_included: "Cyber included",
                        eb_included: "EB included",
                        hnoa_included: "HNOA included"
                    }
                },
                wc: {
                    lob: "WC",
                    lob_full: "Workers’ Compensation",
                    lob_policy_full: "Workers’ Compensation Policy",
                    quote_card: {}
                },
                pl: {
                    lob: "PL",
                    lob_full: "Professional Liability",
                    pl_policy_full: "Professional Liability Policy",
                    quote_card: {}
                },
                gl: {
                    lob: "GL",
                    lob_full: "General Liability",
                    gl_policy_full: "General Liability Policy",
                    quote_card: {}
                },
              cyber: {
                lob: "CYBER",
                lob_full: "Cyber Insurance",
                cyber_policy_full: "Cyber Insurance",
                quote_card: {}
              }
            },
            page: {
                bn_n_a: {// Business name and address
                    title: "Hi! We're here to help.\nLet’s get started with your business name:",
                    description: "Confirm or enter the name and primary address of your business below, even if it is your home.",
                    name: "Your business name",
                    next_section: "Business Type"
                },
                underwriterStatements: {
                    title: 'I acknowledge the following do not pertain to my business.',
                    titleCNA: 'I acknowledge the following do not pertain to my business.',
                    titleCOALITION: 'I acknowledge the following statements are true for my business.',
                    acknowledge: 'By checking this box, I hereby acknowledge that the statements made and information submitted by me are, to the best of my knowledge and belief, truthful and accurate. Please keep in mind that inaccurate information may void or impact your coverage.'
                },
                sp: {//Select policy
                    title: "For {{value}}, we recommend the following coverage: ",
                    description: "Select each coverage you want added to your policy and provide a policy start date. Not sure which coverage you need for your business? <a href='/tuneup-quiz'>Take our Coverage Tune-Up℠</a>.",
                    optional_title: "Available Optional Coverages",
                    optional_description: "Business Owner's Policy can provide the additional coverages below. Select all of the optional coverages you would like to quote.",
                    soon: "coming soon",
                    cyber_title: "Cyber Insurance",
                    hnoa_title: "Hired And Non-Owned Automobile Insurance",
                    pli_title: "Professional Liability Insurance",
                    eb_title: "Equipment Breakdown Insurance",
                    no_polices_selected: "You need to select at least one policy type",
                    policy_start_date_title: "Policy Start Date",
                    next_section: "Business Details"
                },
                ic: {//Industry classes
                    title: "{{value}} appears to be one of these business types:",
                    provide_title: "Describe {{value}} below to find the business type that fits best:",
                    description: "Click the description that best fits your business or click “None of These” below.",
                    provide_description: "Select the best description for your business",
                    diya_description: "Click the best description for your business or click the link below to chat with B2Z.",
                    dropdown_description: "If the business type below is accurate, click the Next button to proceed.",
                    business_type: "Type or select business description below:",
                    none_describes: "None of these describe my business or I'm not sure.",
                    none_describes_short: "None of these",
                    not_selected_error: "Business type must be selected",
                    next_section: "Coverage Choices",
                    contact_us: "Click here to chat with B2Z if you don’t see your business type"
                },
                ci: {//Contact Information
                    title: "Contact Info",
                    next_section: "Coverage Decision"
                },
                bd: {//Additional Details of Business
                    next_section: "Business Location"
                },
                ooi: {//Owners/Officers Information
                    title: "Do you co-own {{value}} or have additional business officers?",
                    description: "For your Workers’ Compensation policy, please add all owners and officers below.",
                    officer: {//Owner
                        title: 'Co-Owner Or Officer Information',
                        additional_title: 'Additional Co-Owner Or Officer Information',
                        name: "Owner",
                        title_code: {
                            question: "Title*",
                            tooltip: "Title",
                            noOptionsText: "No title found with this name"
                        },
                        is_included: {
                            question: "Would you prefer this owner/officer be excluded on the policy?"
                        },
                        first_name: {
                            question: "First Name*"
                        },
                        last_name: {
                            question: "Last Name*"
                        },
                        percent: {
                            question: "Ownership percentage*"
                        },
                        salary: {
                            question: "Salary*"
                        }
                    },
                    title_req: "Title is required",
                    salary_req: "Salary is required",
                    ownership_percent_req: "Ownership percentage is required",
                    total_percent_warn: "Percent ownership should total 100 percent between owners/officers listed",
                    info_error: "There is an error with the owner information provided",
                    you_inc_exc: "You are currently {{value}} as an owner/officer based on information you provided.",
                    next_section: "Contact Info"
                },
                bl: {//Business Locations
                    title: "Is this {{value}}’s primary location?",
                    description: "Confirm your primary business location",
                    name: "Primary Address",
                    not_offer: "Currently, we do not offer coverage in {{value}}",
                    no_primary_loc: "No primary location specified",
                    to_many_primary_loc: "Only one location should be specified as primary",
                    address_incomplete: "Address is not complete",
                    next_section: "Owner/Officer Info",
                    next_section2: "Contact Info"

                },
                pi: {//Payroll Information
                    title: "Payroll Information",
                    additional_title: 'Additional Payroll Class',
                    description: "Enter the payroll details for each of your business locations.",
                    name: "Address",
                    class_code_req: "Class code is required",
                    select_a_class: "You need to select Class code from list",
                    payroll_req: "Payroll amount is required",
                    payroll_not_zero: "Payroll amount can't be 0",
                    info_error: "There are errors in payroll line",
                    not_specified: "not specified",
                    next_section: "Coverage Decision"
                },
                quick_quote: {
                    qq: {
                        title: "Great news! We can offer you coverage for {{value}}",
                        txt: "Here's your current quote. You can customize it in the next step!"
                    },
                    oneError: {
                        title: "We can offer the plan below, but we cannot fulfill all your coverage needs.",
                        txt: "While we cannot provide full coverage now, we are expanding our product availability and may be able to cover {{value}} in the near future."
                    },
                    reject: {
                        title: "We hope to expand our product availability and may be able to assist you in obtaining coverage for {{value}} in the near future.",
                        txt: ""
                    }
                },
                pq: {
                    title: "Current Quote",
                    next_section: "Qualifying Questions"
                },
                qq: {
                    title: "Qualifying Questions",
                    next_section: "Payment Details"

                },
                cq: {
                    title: "Would you like to adjust the coverage limits, {{name}}?",
                    txt: "Customizing your coverage limits will help determine your actual rate."
                },
                ascend_checkout:{
                  title: "Confirm your quote and preferences:",
                  txt: "To purchase your policy, confirm your email and proceed to our payment processor."
                },
                checkout: {
                    title: "Provide your payment details and preferences:",
                    txt: "To purchase your policy, confirm your email, choose billing frequency, and enter payment " +
                        "details. You can also opt in for Hassle-Free Cancelation below.",
                    email_question: {
                        label: "Valid Email Address",
                        txt: "Is {*} your preferred email address for your policy information and account records?"
                    },
                    confirm_email_question: "Confirm Email",
                    email_question_opts: {
                        yes: 'Yes',
                        no: 'No'
                    },
                    payment_schedule_opts: {
                        annual: 'Annual',
                        monthly: 'Monthly'
                    },
                    billing_address: {
                        street: 'Street Address*',
                        street_placeholder: 'Enter Street Address',
                        apt: 'Floor/Apt/Suite Number (Optional)',
                        apt_placeholder: 'Enter Floor/Apt/Suite Number',
                        city: 'City*',
                        city_placeholder: 'Enter City',
                        state: 'State*',
                        state_placeholder: 'Select State',
                        zip: 'Zip Code*',
                        zip_placeholder: 'Enter Zip'
                    },
                    payment_preference_question: 'Payment Preferences',
                    view_schedule: "View Payment Schedule",
                    payment_ref: "Payment Preference",
                    payment_terms: "Accept Payment Terms & Conditions",
                    payment_terms_txt: `<h1>Checkout Terms</h1>
                                        <p>Electronic Documentation. Documents related to your purchase through Acrisure, LLC—including, but not limited to, payment-related and transactional account messages, as well as all policy documents—will be sent to you via email. Please check your spam folder for communications and add <a href="http://b2z-insurance.com/" target="_blank" class="txt_link">b2z-insurance.com</a> to your safe domain list. If you would like to receive a print copy of these documents via standard mail, <a href="https://www.b2z-insurance.com/contact" target="_blank" class="txt_link">contact Customer Experience</a>. </p>
                                        <p>Policy Cancellation. Customers may cancel this policy anytime provided the first named insured on the policy submits the request in writing to us by email or postal mail. Acrisure, LLC may cancel policies based on certain circumstances and will deliver advance written notice of cancellation in writing to the first named insured by email or postal mail. Timing and delivery of cancellation notices may vary by state. Please refer to your policy documents for more detailed information. </p>
                                        <p>To cancel your policy via email, send the request to: <a target="_blank" href="mailto:cl_service@acrisure.com" class="txt_link">cl_service@acrisure.com</a>. To cancel your policy via postal mail, address the request to: B2Z Insurance Inc. | P.O. Box 59271 | Schaumburg, IL 60173 | Attn: Customer Experience Department. </p>
                                        <p>Additional Terms And Conditions. Acrisure, LLC encourages you to review the <a href="https://www.acrisure.com/privacy" target="_blank" class="txt_link">Privacy Policy</a>, <a href="https://www.acrisure.com/terms-of-use" target="_blank" class="txt_link">Terms of Use</a>, and <a href="https://www.b2z-insurance.com/faq/disclosures-licenses" target="_blank" class="txt_link">Disclosures & Licenses</a> prior to purchase. Your acknowledgement and acceptance of the purchase terms at checkout will be recorded. If you have any questions regarding these terms, please <a href="https://www.b2z-insurance.com/contact" target="_blank" class="txt_link">contact Customer Experience</a>.</p>`,
                  next_section: "Submit Payment",
                    error: {
                        paymentFailure: {
                            message: 'Uh-oh... looks like something went wrong! Please try again or select a different payment method.'
                        }
                    }
                },
                not_found: {
                    title: "Looks like something went wrong... Error 404",
                    desc: "The page you are looking for is not available. Refresh the page or go back to try again."
                },
                insurance_error: {
                    title: "Looks like something went wrong...",
                    desc: "The page you are looking for is not available. Refresh the page or go back to try again."
                },
                cookies_error: {
                    title: "Looks like something went wrong...",
                    desc: "Cookies must be enabled on your device to receive the best coverage match and to customize your quote."
                },
                you_r_covered: {
                    head_ttl: 'Thank you!',
                    congrats: `<p>We can't thank you enough! We appreciate your decision to protect your business with an insurance policy through Acrisure, LLC.</p>
                                        <p><span class="boldWeight">Your policy documents will be emailed to you as soon as they’re available</span>, then they will be in the Coverage Central customer portal when you need them.</p> 
                                        <a href="/portal/home" class="a_btn a_btn_action_1 a_btn_go_to_portal">Coverage Central</a>
                                        <p class="boldWeight">Acrisure is committed to trying to provide small business owners like you with an experience to help fit your needs. Thank you for being a part of the Acrisure, LLC community!</p>`,
                },
                marketing_unsubscribe: {
                    title: "You have been unsubscribed from marketing messages."
                }
            },
            diya: {
                greetings_title: "Hi, I’m Diya.",
                greetings_txt: "You’ll see me throughout the application. I am here to help explain all the insurance jargon and inform you about all of the behind the scenes stuff that we use to make the application easier.",
                address_modal_title: "How did you find this information?\n",
                address_modal_txt: "We connect with a Google API that has information about your business such as address, business hours, type of business, and more information. We automatically populate this information in order to make the application form easier for you.",
                help_tip: "Diya Help Tip",
                help_knows: "Diya Knows.",
                help_address_search: "I <span>found some details</span> about your business online. Check the information below to make sure it’s correct.",
                help_aoi: "Making sure your business is fully covered is critical and may involve extending coverage to other persons involved in providing goods or services. For example, you may be required to include the building owner(s), certain customers, vendors, etc. on your policy. To provide coverage for these persons, complete the form below by adding their name(s) and address(es) as evidence of their status as an additional insured on your policy.",
                help_additional_questions: "Please adjust or confirm the information below for accuracy. This will help optimize your coverage.",
                help_contact_info: "A message will be sent to the email you provide for validation. Your email will need to be validated prior to purchasing coverage.",
                help_effective_dates: "Enter your preferred policy start date to begin within the next 30 days. The policy term is 12 months from the start date you select.",
                help_industry_class: "Based on the information I found I think your business falls into one of the following categories.",
                help_optional_coverages: "The majority of Small Business Owners like you do not elect to purchase this coverage; however, if your business is in a metropolitan area, it may be an option to consider.",
                help_owners: "Included owners/officers will be entitled to benefits if covered injury happens. We recommend they are included if they do hands on work for your business.",
                help_policy: "Based on the information you provided, I recommend getting these policies to protect your business.",
                help_qualify_questions: "Please note: Inaccurate information may void your policy when filing a claim.",
                help_reset: "A new temporary password has been sent to the email on file. Didn’t receive email? Please check your spam folder or review the email address on file.",
                select_rate: {
                    empty: "Unfortunately we can’t offer you a policy today",
                    one: "Here is the best quote I’ve found for your business",
                    default: "Here are the best quotes I’ve found for your business",
                    session_one: "Here is the quote that I’ve retrieved for your business",
                    session_default: "Here are the quotes that I’ve retrieved for your business"
                },
                bulk_greetings_title: "Hi, I’m Diya.\n" +
                    "Here is your pre-quote.",
                bulk_greetings_txt: "We’ve prepared a Pre-Quote BOP Estimate for your business. Whether you want to customize your quote or you’re ready to purchase coverage now, click the button below to confirm or adjust your information.",
                effective_date_updated: "Your policy effective date is the date your coverage begins. Please enter your preferred start date below."
            },
            portal: {
                helpful_links: {
                    request_policy_documents: {
                        title: "Request Policy Documents",
                        sub_title: "Please submit this form and we will email your documents to you.",

                        business_name_question: "Business Name",
                        business_name_placeholder: "Enter your business name ",
                        business_name_error: "Business Name is required",

                        policy_number_question: "Policy Number",
                        policy_number_placeholder: "Enter your policy number",
                        policy_number_error: "Policy Number is required"
                    },
                    add_certificate_holder: {
                        title: "Add Certificate Holder",
                        sub_title: "Please provide the information for the certificate holder you wish to add.",

                        policy_number_question: "Policy Number",
                        policy_number_error: "Policy Number is required",

                        name_holder_question: "Name of Certificate Holder",
                        name_holder_error: "Name of Certificate Holder is required",

                        street_address_holder_question: "Street Address of Certificate Holder",
                        street_address_holder_error: "Street Address of Certificate Holder is required",

                        street_address2_holder_question: "Floor/Apt/Suite Number (Optional)",

                        city_holder_question: "City",
                        city_holder_error: "City is required",

                        state_holder_question: "State",
                        state_holder_error: "State is required",

                        zip_code_holder_question: "ZIP Code",
                        zip_code_holder_error: "ZIP code is required",
                    },
                    add_additional_insured: {
                        title: "Add Additional Insured",
                        sub_title: "Please provide the information for the additional insured you wish to add.",

                        name_question: "Name of Additional Insured",
                        name_error: "Name of Additional Insured is required",

                        insured_type_question: "Type of Insured ",
                        insured_type_error: "Type of Insured  is required",

                        reason_question: "Reason",
                        reason_error: "Reason is required",

                        street_address_question: "Street Address of Additional Insured",
                        street_address_error: "Street Address of Additional Insured is required",


                    },
                    success_add_certificate_holder: {
                      title: "All set!",
                      sub_title: "We're sending you an email with the additional certificate holder documents.",
                      btn: "Back to Portal"
                    },
                  success_cancel_policy: {
                    title: "All set!",
                    sub_title: "Your cancelation information has been submitted. A B2Z customer experience agent will process your policy cancelation and email you confirmation.",
                    btn: "Back to Portal"
                  },
                    success_add_additional_insured: {
                      sub_title: "Your request has been submitted. A B2Z customer experience agent will email you confirmation.",
                    },
                    cancel_policy: {
                        title: "Cancel My Policy",
                        sub_title: "Please provide the information below. Once you submit it, a customer experience agent will process your policy cancelation and email you confirmation.",

                        business_name_question: "Business Name",
                        business_name_placeholder: "Enter your business name ",
                        business_name_error: "Business Name is required",

                        policy_number_question: "Policy Number",
                        policy_number_placeholder: "Enter your policy number",
                        policy_number_error: "Policy Number is required",

                        cancel_by_date_question: "Cancel-By Date",
                        cancel_by_date_required_error: "Cancel-By Date is required",
                        cancel_by_date_no_backdate_error: "Backdate not allowed",

                        reason_question: "Reason for Cancelation",
                        reason_error: "Reason for Cancelation is required"
                    },
                    change_coverage: {
                        title: "Change Coverage",
                        sub_title: "Please schedule a call with us to discuss updating your coverage."
                    }
                },
                billing_address: {
                    title: "Billing Address"
                },
                claim: {
                    completed_at: "Completed at {{date}}",
                    active: "Active Claims",
                    empty: "You do not have any active claims right now.",
                    resolved: "Claimed that have been resolved will be shown here.",
                    select_policy_to_claim: "Select a policy for claim",
                    closed: "Closed Claims",
                    select_type: "Did your loss involve one of the following?",
                    select_type_desc: "Select all applicable loss types.",
                    select_policy: "Which business are you filing this claim for?",
                    select_policy_desc: "If this claim applies to more than one business, please make a separate claim for each business. ",
                    not_selected: "Not selected",
                    type_of_loss: "What type of loss occurred?",
                    address: "Address",
                    not_indicated: "Not indicated",
                    desc_loss: "Description of Loss",
                    property_helper: "Includes damage to buildings owned by you, burglary, theft, and income loss due to fraud or forgery",
                    liability_helper: "Includes customer injury, damage to property of others, product liability and reputational harm"
                },
                policies: {
                    unfinished_quotes: "You have unfinished quotes.",
                    empty: "You have no active policies yet"
                },
                policy: {
                    num: "Policy Number",
                    ann_prem: "Annual Premium",
                    doc: "Policy Documents",
                    doc_gen: "Documents are being generated",
                    doc_gen_tooltip: "You will receive an email when your policy documents are ready. This process may take up to 24 hours.",
                    certificate: "Certificate of Insurance",
                    view_schedule: "View Payment Schedule",
                    automatically_deducted: "Payment to be automatically deducted on <bold>{{date}}</bold> from {{payment_type}} ending in <bold>{{last4}}</bold>",
                    automatically_decline: "Declined Payment on <bold>{{date}}</bold> from {{payment_type}} ending in <bold>{{last4}}</bold>",
                    unable_payment: "We were unable to process your recent payment on {{num}}.",
                    add_cov: "Additional Coverages",
                    buildings: {
                        title: "Buildings",
                        txt: "Covers structures including fixtures and permanently installed machinery and equipment"
                    },
                    bpp: {
                        title: "Business Personal Property",
                        txt: "Covers items used in your business as well as items of others that are in the care of your business"
                    },
                    biee: {
                        title: "Business Income & Extra Expense",
                        txt: "Covers the net income (before income taxes) that you would have earned and any extra expenses in the event that your business can not operate because of a loss",
                        als: "Actual Loss Sustained (12 Months)"
                    },
                    liability: {
                        title: "Liability",
                        txt: "Covers sums that you are legally obligated to pay to others because of bodily injury, property damage, or personal and advertising injury",
                        ops_agg_limit: "Prod/Comp Ops Agg. Limit",
                        gen_agg_limit: "General Agg. Limit"
                    },
                    drb: {
                        title: "Damages to Rented Building",
                        txt: "Covers sums that you are legally obligated to pay others because of property damage to a premises while the premises is rented to you"
                    },
                    ml: {
                        title: "",
                        txt: "Covers medical expenses for bodily injury caused by an accident on premises that you own or rent, or by an accident that results from your business operations."
                    },
                    cyber: {
                        title: "Cyber Insurance",
                        txt: "Covers the loss and liability related to cyber attacks, including data breaches and damage to your computer systems and data."
                    },
                    hnoa: {
                        title: "Hired And Non-Owned Auto (HNOA) Insurance",
                        txt: "Protects your business from losses involving vehicles that are driven for business purposes but are personally owned by your employees or rented by your company or employees."
                    },
                    eb: {
                        title: "Equipment Breakdown Insurance",
                        txt: "Covers the loss and liability related to cyber attacks, including data breaches and damage to your computer systems and data."
                    },
                    mpl: {
                        title: "Professional Liability Insurance",
                        txt: "Covers businesses that provide expertise and consulting services from costs resulting from work mistakes, missed deadlines, professional negligence lawsuits—among others."
                    },
                    wc: {
                        title: "Workers’ Compensation",
                        txt: "Covers wage replacement and medical benefits to employees that are injured or disabled on the job."
                    },
                    el: {
                        title: "Employer’s Liability",
                        txt: "Covers additional costs associated with employee injury and illness including legal defense and court costs."
                    },
                    gl: {
                        limits: {
                            gl_damage_to_premises_rented_to_you: {
                                label: "Per Occurrence Limit",
                                tooltip: "This is the maximum amount the insurer will pay for all claims resulting from a single occurrence."
                            }
                        }
                    }
                },
                change_payment_method:{
                  success: "Success!",
                  txt: "Your payment method has been updated."
                },
                change_pay_method_txt: "Currently using {{type}} ending in ****{{num}}",
                cyber_promo_title: "Do you run your business on the internet?",
                cyber_promo_txt: "In a world run by technology, hacks and data breaches are becoming one of the most common losses for small businesses. Cyber policies protect you from these kinds of attacks.",
                faq: {
                    title: "Frequently Asked Questions"
                },
                account: {
                    title: "Account Settings",
                    title_info: "Account information",
                    title_communication: "Communication Preferences",
                    title_change_pass: "Change Password",
                    update_sub_title: "How would you like to recieve notifications about your account?",
                    comm_pref_email_label: "Email",
                    comm_pref_email_txt: "Import account information will be delivered via the email associated with this account.",
                    comm_pref_phone_label: "Phone",
                    comm_pref_phone_txt: "Account updates will be delivered via the phone number associated with this account.",
                    comm_pref_sms_label: "Text (SMS)",
                    comm_pref_sms_txt: "Import account information will be delivered to the phone number associated with this account via text message. Standard data and messaging rates may apply."
                },
                wc_hotline: {
                    employers: {
                        title: "EMPLOYERS Injured Employee Hotline",
                        phone: "1 (855) 365-6010",
                        list: [
                            {
                                txt: 'For reporting of a new work-related injury or occupational disease when the injured employee has not yet received medical treatment.'
                            },
                            {
                                txt: 'Access to registered nurses who are specially trained to provide nurse triage and medical guidance'
                            }
                        ]
                    },
                    customer: {
                        title: "Customer Service Center",
                        phone: "1 (888) 682-6671",
                        list: [
                            {
                                txt: 'For reporting of a new work-related injury or occupational disease when the injured employee has already received medical treatment.'
                            },
                            {
                                txt: 'Injured employees who have not yet sought medical treatment will be transferred to our Injured Employee Hotline (IEH) and provided the IEH phone number.'
                            }
                        ]
                    }
                }
            },
            certificate: {
                wc_desc: "Employers Insurance Company of Nevada. (National Association of Insurance Commissioners [NAIC] Company Code 10640), domiciled in Nevada, or one of the following Employer brands and subsidiaries: Employers Holdings, Inc., a Nevada holding company; Employers Group, Inc., a Nevada corporation; Employers Compensation Insurance Company (NAIC Company Code 11512), domiciled in California; Employers Preferred Insurance Company (NAIC Company Code 10346), domiciled in Florida; Employers Assurance Company (NAIC Company Code 25402), domiciled in Florida; Elite Insurance Services, Inc., a Nevada corporation; EIG Services, Inc., a Florida corporation.",
                bop_acuity_desc: "Business Owner’s Policy coverage underwritten by: Acuity, A Mutual Insurance Company | 2800 South Taylor Drive, Sheboygan, WI 53081 “Acuity” and its logo are registered trademarks of Acuity, A Mutual Insurance Company. All coverages are underwritten by Acuity, A Mutual Insurance Company with the exception of personal automobile coverage in the state of Texas, which is offered by Acuity TX MGA, Inc. and underwritten by Home State County Mutual Insurance Company. Home State County Mutual Insurance Company is not an affiliate of any Acuity company.",
                bop_cf_desc: ""
            }
        }
    }
}

i18next.use(initReactI18next).init({
    resources,
    lng: "en",
    interpolation: {
        escapeValue: false
    }
})

export default i18next;
