import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {formatCurrency} from '../../../helpers/currency'
import {GeneralAggLimitLabel} from './limits/GeneralAggLimitLabel'
import {PerOccurrenceLimitLabel} from './limits/PerOccurrenceLimitLabel'

const CYBERPolicyDetails = (props) => {
    const {t} = props
    const optionalCoveragesData = [
        {
            'qid': 'cyber.coalition.coverage_instances_bipd_first_party',
            'name': 'BIPD First Party Coverage',
            'text': 'Covers first party damage for intrusions that result in bodily injury or property damage.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_extortion',
            'name': 'Extortion',
            'text': 'Typically covers the cost of cyber extortion demands, hiring experts to prevent future attempts, and negotiation expenses.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_crisis_management',
            'name': 'Crisis Management',
            'text': 'Typically reimburses expenses to restore confidence in the security of the insured\'s computer system.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_breach_response_costs',
            'name': 'Breach Response Costs',
            'text': 'Typically covers costs to investigate and respond to a security breach event.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_business_interruption',
            'name': 'Business Interruption',
            'text': 'Typically covers the income loss after a business is impacted by a security breach event.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_regulatory_defense',
            'name': 'Regulatory Defense',
            'text': 'Covers the costs of dealing with government regulatory agencies including attorney costs to consult with regulators and fines and penalties.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_media_liability',
            'name': 'Media Liability',
            'text': 'Coverage for media-related businesses like publishing, ad agencies, and film production to help with costly claims from plagiarism, copyright infringement, libel, and more.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_pci',
            'name': 'PCI Breach',
            'text': 'Covers assessments, fines or penalties imposed by banks or credit card companies due to non-compliance with PCI standards.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_funds_transfer',
            'name': 'Funds Transfer',
            'text': 'Covers the loss of your business\'s assets in the event of a funds transfer breach or fraud, like wire transfer fraud.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_asset_restoration',

            'name': 'Asset Restoration',
            'text': 'Covers restoration costs to reinstate or recreate digital (not hardware) assets to their pre-loss state.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_bipd_third_party',
            'name': 'BIPD Third Party',
            'text': 'Covers third party bodily injury/property damage client claims for online security and data your business is responsible for protecting, including legal defense costs, after a cyberattack.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_reputation',
            'name': 'Reputation',
            'text': 'Helps restore customer confidence by providing coverage to hire a PR firm in order to help rebuild the brand and reputation.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_reputational_harm',
            'name': 'Reputation Harm',
            'text': 'Covers restoration costs to reinstate or recreate digital (not hardware) assets to their pre-loss state.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_computer_replacement',
            'name': 'Computer Replacement',
            'text': 'Also known as “bricking,” covers hardware damage caused by a cyber attack.'
        },
        {
            'qid': 'cyber.coalition.coverage_instances_pollution',
            'name': 'Pollution',
            'text': 'Covers pollution that results directly from a cyberattack such as pollutant seepage.'
        }
    ]
    const [optionalCoveragesList, setOptionalCoveragesList] = useState(optionalCoveragesData)

    useEffect(() => {
        const getOcListWithAnswer = () => {
            return props.quote['optional_coverages'].map(item => {
                const itemDescription = optionalCoveragesData.find(obj => obj.qid === item.qid);
                return {
                    ...item,
                    answer: props.answers_snapshot[item.qid],
                    name: itemDescription.name,
                    text: itemDescription.text
                }
            })
        }
        let listWithAnswers = getOcListWithAnswer()
        setOptionalCoveragesList(listWithAnswers)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (<div className="detail_info">
        <p className="policy_coverages">{t('common.coverages')}</p>
        <div className="detail_info_columns">
            {optionalCoveragesList.map((item, key) => {
                if (item.answer !== 'No') {
                    return (
                        <div className="detail_info_group" key={key}>
                            <div className="group_ttl">{item.name}</div>
                            <div className="group_txt">{item.text}</div>
                            <div className="group_list group_limit_list">
                                <div className="list_item">
                                    <GeneralAggLimitLabel name={'Aggregate Limit'}/>
                                    <span
                                        className="item_value">{formatCurrency(item.aggregate_limit)}</span>
                                </div>
                                <div className="list_item">
                                    <PerOccurrenceLimitLabel name={'Retention Limit'}/>
                                    <span
                                        className="item_value">{formatCurrency(item.retention_limit)}</span>
                                </div>
                            </div>
                        </div>)
                } else {
                    return null
                }
            })}
        </div>
    </div>)
}

export default withTranslation()(CYBERPolicyDetails)