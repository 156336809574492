import React from "react";
import {FormControl} from "@material-ui/core";
import {formatCurrency} from "../../../helpers/currency";

export default class UnselectableLimit extends React.Component {

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth} error={this.props.error} className={'form_control'}>
                <div className="limit-question">
                    <div className="spacer"/>
                    <div className="value">{formatCurrency(this.props.answer)}</div>
                    <div className="spacer"/>
                </div>
            </FormControl>
        )
    }
}