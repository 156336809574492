import React from "react";
import Question from "./Question";
import {withTranslation} from 'react-i18next';
import {Box, Grid } from "@material-ui/core";

class BillingAddressPortal extends React.Component {

    state = {
        states: [
            {
                text: 'AZ'
            },
            {
                text: 'AR'
            },
            {
                text: 'CA'
            },
            {
                text: 'CO'
            },
            {
                text: 'CT'
            },
            {
                text: 'DE'
            },
            {
                text: 'FL'
            },
            {
                text: 'GA'
            },
            {
                text: 'HI'
            },
            {
                text: 'ID'
            },
            {
                text: 'IL'
            },
            {
                text: 'IN'
            },
            {
                text: 'IA'
            },
            {
                text: 'KS'
            },
            {
                text: 'KY'
            },
            {
                text: 'LA'
            },
            {
                text: 'ME'
            },
            {
                text: 'MD'
            },
            {
                text: 'MA'
            },
            {
                text: 'MI'
            },
            {
                text: 'MN'
            },
            {
                text: 'MS'
            },
            {
                text: 'MO'
            },
            {
                text: 'MT'
            },
            {
                text: 'NE'
            },
            {
                text: 'NV'
            },
            {
                text: 'NH'
            },
            {
                text: 'NJ'
            },
            {
                text: 'NM'
            },
            {
                text: 'NY'
            },
            {
                text: 'NC'
            },
            {
                text: 'ND'
            },
            {
                text: 'OH'
            },
            {
                text: 'OK'
            },
            {
                text: 'OR'
            },
            {
                text: 'PA'
            },
            {
                text: 'RI'
            },
            {
                text: 'SC'
            },
            {
                text: 'SD'
            },
            {
                text: 'TN'
            },
            {
                text: 'TX'
            },
            {
                text: 'UT'
            },
            {
                text: 'VT'
            },
            {
                text: 'VA'
            },
            {
                text: 'WA'
            },
            {
                text: 'WV'
            },
            {
                text: 'WI'
            },
            {
                text: 'WY'
            }
        ],
    }

    render() {
        const {t} = this.props

        return (
            <Box textAlign="left" pt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p className={'address_ttl'}>{t('portal.billing_address.title')}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Question fullWidth
                                          type="text"
                                          question={t('form.label.address')}
                                          answer={this.props.address.street_address}
                                          variant="outlined"
                                          error={!!this.props.errors.street_address}
                                          helperText={this.props.errors?.street_address}
                                          onChange={(id, value) => {
                                              this.props.onChange("street_address", value)
                                          }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Question type="text"
                                          question={t('form.label.address2')}
                                          answer={this.props.address.street_address2}
                                          variant="outlined"
                                          fullWidth
                                          onChange={(id, value) => {
                                              this.props.onChange("street_address2", value)
                                          }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Question fullWidth
                                          type="text"
                                          question={t('form.label.city')}
                                          answer={this.props.address.city}
                                          variant="outlined"
                                          error={!!this.props.errors.city}
                                          helperText={this.props.errors?.city}
                                          onChange={(id, value) => {
                                              this.props.onChange("city", value)
                                          }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Question fullWidth
                                          type="dropdown"
                                          question={t('form.label.state')}
                                          answer={this.props.address.state}
                                          variant="outlined"
                                          options={this.state.states}
                                          error={!!this.props.errors.state}
                                          helperText={this.props.errors?.state}
                                          onChange={(id, value) => {
                                              this.props.onChange("state", value)
                                          }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Question fullWidth
                                          type="zip"
                                          question={t('form.label.zip')}
                                          answer={this.props.address.zip_code}
                                          variant="outlined"
                                          error={!!this.props.errors.zip_code}
                                          helperText={this.props.errors?.zip_code}
                                          onChange={(id, value) => {
                                              this.props.onChange("zip_code", value)
                                          }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default withTranslation()(BillingAddressPortal)
