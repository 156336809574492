import { Hidden } from '@material-ui/core'
import React from 'react'
import Loading from '../../Loading'
import GLPolicy from '../policy/GLPolicy'
import WCPolicy from '../policy/WCPolicy'
import BOPPolicy from '../policy/BOPPolicy'
import PLPolicy from '../policy/PLPolicy'
import CyberPolicy from '../policy/CYBERPolicy'
import HelpfulLinks from '../sidebar/HelpfulLinks'
import Payments from '../sidebar/Payments'
import SidebarAds from '../sidebar/SidebarAds'
import FeedRss from './feedRSS'

const PoliciesTab = (props) => {

  return (
    <>
      <div className="content_column">
        {/*POLICIES FULL LIST*/}
        <div
          className={`grid_item grid_policies_full`}>
          {props.policyLoading ? <Loading/> :

            props.policies.map((policy, i) => {

              const { type } = policy
              if (type === 'WC') {
                return <WCPolicy {...policy} selectedPolicyRef={props.selectedPolicyRef} key={i}/>
              } else if (type === 'PL') {
                return <PLPolicy {...policy} selectedPolicyRef={props.selectedPolicyRef} key={i}/>
              } else if (type === 'GL') {
                return <GLPolicy {...policy} selectedPolicyRef={props.selectedPolicyRef} key={i}/>
              } else if (type === 'CYBER') {
                return <CyberPolicy {...policy} selectedPolicyRef={props.selectedPolicyRef} key={i}/>
              } else return <BOPPolicy {...policy} selectedPolicyRef={props.selectedPolicyRef} key={i}/>
            })

          }
        </div>

      </div>
      <div className="sidebar_column">
        {props.policyLoading ? <Loading/> : <HelpfulLinks/>}
        <Hidden smDown>
          <div className="grid_item grid_promo">
            <SidebarAds/>
          </div>
        </Hidden>
        <div className="grid_item grid_claims_payments">
          {props.policyLoading ? <Loading/> :
            props.paidPayments && props.paidPayments.length > 0 && (
              <Payments paidPayments={props.paidPayments}/>
            )
          }
        </div>
        <Hidden mdUp>
          <FeedRss feedsRSS={props.feedsRSS}/>
        </Hidden>
      </div>
    </>
  )
}
export default PoliciesTab