import React, {useState, useRef, useEffect, useContext} from 'react'
// import NumberFormat from 'react-number-format'
import {TextField, FormControl} from '@material-ui/core'
import InputMask from 'react-input-mask'
import {cvv} from 'card-validator'
import {Verification} from 'card-validator/dist/types'
import {InputProps} from './helper.types'
import {CreditCardDataContext} from '../custom/CreditCardFields'
import {absLenght} from './converters'

interface CVCInputProps extends InputProps {
    charcount?: number;
}


// function CVVNumberFormat(props) {
//     return (
//         <NumberFormat
//             className={props.className}
//             value={props.value}
//             getInputRef={props.inputRef}
//             // onValueChange={(values) => {
//             //     props.onChange(values)
//             // }}
//             // mask="_"
//             allowEmptyFormatting
//             format="###"
//             placeholder="###"
//         />
//     )
// }

//TODO: chnange to props
const CreditCardCVVField = ({leaveFieldCallback, focus, tabIndex, fieldError}: CVCInputProps) => {
    const [error, setError] = useState(false)
    const [info, setInfo] = useState('')

    const CardContext = useContext(CreditCardDataContext)
    const inputRef = useRef<HTMLInputElement>(null!)
    const CVCLengthRequired = CardContext?.cardData?.cvcLength


    const handleChange = (event: any) => {

        const CVCLength = absLenght(inputRef?.current.value)
        const CVCverify: Verification = cvv(event?.target?.value)

        if (CVCLength > 0 && CVCLength !== CVCLengthRequired) {
            setError(true)
            setInfo('Incorect CVC length')
        } else if (CVCLength > 0 && !CVCverify.isPotentiallyValid) {
            setError(true)
            setInfo('Dont now what but something sticky')
        } else if (CVCverify.isValid) {
            setError(false)
            setInfo('')
            if (leaveFieldCallback) {
                leaveFieldCallback(tabIndex + 1)
            }
        }
    }

    const handleBlur = (event: any) => {
        const CVCvalue = event?.target?.value
        const CVCLength = absLenght(CVCvalue)
        const value: Verification = cvv(CVCvalue)
        if (CVCLength > 0 && value.isValid) {
            setError(false)
            setInfo('')
            CardContext?.setCardData({
                ...CardContext.cardData,
                cardCVC: {
                    cvc: CVCvalue,
                    complete: true
                }
            })
        } else {
            setError(true)
            CardContext?.setCardData({
                ...CardContext?.cardData,
                cardCVC: {
                    cvc: '',
                    complete: false
                }
            })
        }
    }
    useEffect(() => {
        if (fieldError) {
            setError(true)
            setInfo('Invalid number')
        }
    }, [fieldError])

    useEffect(() => {
        if (focus) {
            inputRef.current.focus()
        }
    }, [focus])
    //TODO: props cvc count i mask na to ustawić
    return (
        <FormControl fullWidth error={error} className={'form_control'}>
            <div className="label_blk">
                <div className="label">CVV*</div>
            </div>
            <InputMask
                mask="999"
                maskChar=" "
                onChange={handleChange}
                onBlur={handleBlur}
                tabIndex={tabIndex}>
                {() =>
                    <TextField variant="outlined"
                        className={'fieldText'}
                        name="cvc"
                        type="tel"
                        placeholder="XXX"
                        required
                        autoFocus={focus}
                        inputRef={inputRef}
                        // InputProps={{
                        //     inputComponent: CVVNumberFormat
                        // }}
                    />
                }
            </ InputMask>
            {error ? (
                <p className="error_txt error_txt_md">{info}</p>
            ) : null}
        </FormControl>
    )
}

export default CreditCardCVVField