const momentConstant = {
    DAY_STR: 'day',
    ONE_DAY_NUM: 1,
    DAYS_STR: 'days',
    MONTH_STR: 'month',
    YEAR_STR: 'year',
    YEAR_NUM: 1,
    YEAR_DAYS_NUM: 365
}


export default Object.freeze(momentConstant)

