import React from "react";
import {uniq} from 'lodash'
import {connect} from "react-redux";
import {Close} from "@material-ui/icons";
import {withTranslation} from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import { ModalTransition } from '../../common/ModalTransition'
import '../../scss/v2/modals/_ooea_disclaimer.scss'


class FraudDisclaimer extends React.Component {

    state = {
        read_in_full: false,
    }

    constructor(props) {
        super(props);
        this.end_ref = React.createRef()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open === true) {
            setTimeout(() => {
                if (!this.state.read_in_full) this.checkIfEndIsShown(this.end_ref.current)
            }, 300)
        }
    }

    checkIfReadInFull(e) {
        if (this.state.read_in_full) return

        let el = e.currentTarget

        this.checkIfEndIsShown(el)
    }

    checkIfEndIsShown(el) {
        if (!el) return
        if (el.scrollHeight - el.scrollTop === el.clientHeight) {
            this.setState({read_in_full: true})
        }
    }

    getStatesText() {
        let states = uniq(this.props.locations.map(itm => itm.state))

        return states.join(', ')
    }

    render() {
      const {t} = this.props

      return (
            <Dialog open={this.props.open}
                    fullWidth
                    container={() => document.getElementById('themeAppComponent')}
                    className={'modal__wrapper ooea_disclaimer_modal__wrapper'}
                    maxWidth="sm"
                    scroll="paper"
                    onClose={() => {
                        this.props.onClose()
                    }}
                    TransitionComponent={ModalTransition}>
              <DialogActions className={'modal_head'}>
                <button className={'modal_close_btn show_scroll_paper'}
                        type={'button'}
                        onClick={() => {
                          this.props.onClose()
                        }}><Close/>
                  <span>{t('common.btn_cancel')}</span></button>

              </DialogActions>
              <DialogContent onScroll={e => {
                this.checkIfReadInFull(e)
              }} ref={this.end_ref} className={'modal_body'}>
                <div className={'ooea_disclaimer_modal_cnt'}>
                  <p className={'modal_txt'}>{t('modal.ooead_disclaimer', {value: this.getStatesText()})}</p>

                  <button type={'button'} className={'a_btn a_btn_action_1 a_btn_ok'}
                          onClick={() => this.props.onAccept()}>{t('common.btn_accept')}</button>

                </div>

              </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
        locations: state.insurance.wc_locations,
    }
}

export default connect(mapStateToProps)(withTranslation()(FraudDisclaimer))
