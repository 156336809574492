import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    MenuItem,
    TextField,
    useTheme
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Question from '../../form-components/Question.js'
import LoadingButton from '../../../components/LoadingButton'
import '../../../scss/portal/_helpfull_modal.scss'
import {ModalTransition} from '../../../common/ModalTransition'

const RequestPolicyDocumentsModal = ({policies, open, onClose, t}:
                                         { policies: any, open: boolean, onClose: () => void, t: (string) => string }) => {
    const [formData, setFormData] = useState({businessName: '', policyNumber: ''})
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [policiesOptions, setPoliciesOptions] = useState([])

    const onChange = (field, value) => {
        let data = {...formData}
        data[field] = value
        setFormData(data)
    }

    useEffect(() => {
        const policiesOptions = () => {
            let policiesOptions = policies.map((itm) => itm.type !== 'WC' ? {
                id: itm.quote.policyNumber || itm.quote.policy_number,
                value: itm.id,
                text: itm.type + ' (' + (itm.quote.policyNumber || itm.quote.policy_number) + ')',
            } : null)
            return policiesOptions.filter(policy => policy !== null)
        }
        setPoliciesOptions(policiesOptions)
    }, [policies, formData])

    useEffect(() => {
        if (policiesOptions.length === 1 && formData['businessName'] === '' && formData['policyNumber'] === '') {
            let data = {...formData}
            // @ts-ignore
            data['policyNumber'] = policiesOptions[0].id
            setFormData(data)
        }
    }, [policiesOptions, formData])

    useEffect(() => {
        if (!open) {
            setFormData({businessName: '',
                policyNumber: policiesOptions.length === 1 ? formData.policyNumber : '',
            })
            setErrors({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, formData.policyNumber, policiesOptions.length])

    const validateFormFields = () => {
        setLoading(true)
        let errors = {}

        if (!/^.{2,}$/.test(formData.businessName)) {
            errors['businessName'] = t('portal.helpful_links.request_policy_documents.business_name_error')
        }

        if (!/^.{2,}$/.test(formData.policyNumber)) {
            errors['policyNumber'] = t('portal.helpful_links.request_policy_documents.policy_number_error')
        }

        if (Object.keys(errors).length === 0) {

            let html = `<p><strong>Business Name :</strong> ${formData.businessName}</p><p><strong>Policy Number :</strong> ${formData.policyNumber}</p>`
            axios.post('/api/send_email',
                {
                    recipients: ['cl_service@acrisure.com'],
                    subject: 'Request Policy Documents',
                    text: 'Request Policy Documents',
                    html: html,
                    attachments: []
                })
                .then(() => {
                    setLoading(false)
                    onClose()
                })
                .catch(e => {
                    setLoading(false)
                    throw new Error(`Can't Send Email. ${e}`)
                })
        } else {
            setErrors(errors)
            setLoading(false)
        }
    }

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            className={'modal__wrapper helpful_common__wrapper '}
            maxWidth="sm"
            scroll={'paper'}
            fullWidth
            TransitionComponent={ModalTransition}>
            <DialogActions className={'modal_head'}>
                <button className={'modal_close_btn show_scroll_paper'}
                    type={'button'}
                    onClick={onClose}><Close/>
                    <span>{t('common.btn_close')}</span></button>
            </DialogActions>

            <DialogContent>
                <div className={'helpful_cnt'}>
                    <p className={'helpful_ttl'}>{t('portal.helpful_links.request_policy_documents.title')}</p>
                    <p className={'helpful_sub_ttl'}>{t('portal.helpful_links.request_policy_documents.sub_title')}</p>
                    <Question
                        fullWidth
                        type="text"
                        question={t('portal.helpful_links.request_policy_documents.business_name_question')}
                        placeholder={t('portal.helpful_links.request_policy_documents.business_name_placeholder')}
                        answer={formData.businessName}
                        onChange={(_id, val) => {
                            onChange('businessName', val)
                        }}
                        error={errors['businessName'] ? errors['businessName'] : null}
                        helperText={errors['businessName'] ? errors['businessName'] : null}
                    />

                    {policiesOptions.length > 1 ?
                        <FormControl fullWidth error={errors['policyNumber'] ? errors['policyNumber'] : null}
                            className={'form_control'}>
                            <div className="label_blk">
                                <div className="label">
                                    <span>{t('portal.helpful_links.add_certificate_holder.policy_number_question')}</span>
                                </div>
                            </div>
                            <TextField
                                select
                                className={'fieldDropdown'}
                                value={formData.policyNumber}
                                error={!!errors['policyNumber']}
                                variant="outlined"
                                id={'downloading-documents-select'}
                                onChange={(e) => onChange('policyNumber', e.target.value)}>
                                {policiesOptions && policiesOptions.map((opt) => (
                                    // @ts-ignore
                                    <MenuItem value={opt.id} key={opt.id}>{opt.text}</MenuItem>
                                ))}
                            </TextField>
                            {errors['policyNumber'] &&
                              <p className="error_txt error_txt_md">{errors['policyNumber']}</p>}

                        </FormControl> :
                        <FormControl fullWidth error={errors['policyNumber'] ? errors['policyNumber'] : null}
                            className={'form_control'}>
                            <div className="label_blk">
                                <div className="label">
                                    <span>{t('portal.helpful_links.add_certificate_holder.policy_number_question')}</span>
                                </div>
                            </div>
                            <TextField
                                disabled
                                value={formData.policyNumber}
                                variant="outlined">
                            </TextField></FormControl>}
                    {loading ? <LoadingButton/> : <button className={'a_btn a_btn_action_1 btn__submit'}
                        onClick={validateFormFields}>{t('common.btn_submit')}</button>
                    }

                </div>
            </DialogContent>
        </Dialog>
    )
}

export default connect(null, null)(withTranslation()(RequestPolicyDocumentsModal))