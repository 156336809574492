import React from 'react';
import {Collapse} from "@material-ui/core";
import { withTranslation } from 'react-i18next'
import moment from "moment";
import axios from "axios";
import {setPolicies, setPoliciesLoading} from "../../../store/portal/policies/actions";
import {connect} from "react-redux";
import ChangePaymentMethod from '../ChangePaymentMethod'

const PaymentItem = (props) => {

  const onSuccessChangePaymentMethod = () => {
    props.onHandleSuccess({
      success: true,
      notifyContent: {
        title: props.t('portal.change_payment_method.success'),
        text: props.t('portal.change_payment_method.txt'),
        btnTxt: props.t('common.btn_close'),
        isIcon: false
      }
    })
  }
    const getPolicies = () => {

        axios.get("/api/policies")
            .then(async (data) => {
                if (data.data && data.data.length > 0) {
                    // this.checkIfAllDocumentsDone(data.data)
                    let policies = data.data

                    const filteredPolicies = policies.filter(item => {
                        let currentDate = moment(new Date());
                        let futureMonth = moment(item.expiration_date).add(1, 'M');
                        return currentDate.diff(futureMonth, 'days') < 0
                    })

                    props.setPolicies(filteredPolicies)
                    props.setPoliciesLoading(false)
                } else {
                    props.setPolicies([])
                    props.setPoliciesLoading(false)
                }
            }).catch(e => {
            props.setPolicies([])
            props.setPoliciesLoading(false)
            throw new Error(`Can't get user's policies. ${e}`)

        })
    }

    return (<div className={'payment_item payment_item_next'}>
            <div className={'payment_header'}>
                <div className={`payment_desc ${props.failed ? 'failed_installment':''}`}>
                    <p className={`payment_policy_num`}>Policy - {props.policyNumber}</p>
                    <p className={'payment_ttl'}>Next payment date: {moment(props.due_date).format('MM/DD')}</p>
                    {props.policy.billing_method === 'AGENCY' &&
                      <p className={'payment_txt'}>{props.policy.default_payment_source_details.brand} ending
                          in {props.policy.default_payment_source_details.last4}</p>}

                </div>
              {props.policy.billing_method === 'AGENCY' &&
                <button className={'a_btn a_btn_outlined btn_edit_payment_method'}
                        onClick={props.onEditModeHandler}>
                  {props.paymentEdit ? 'Cancel' : 'Edit Payment Method'}</button>}
            </div>
          {props.policy.billing_method === 'AGENCY' &&
            <Collapse in={props.paymentEdit} className={'payment_main'}>
              <ChangePaymentMethod
                isEditMethod={props.paymentEdit}
                policy={props.policy}
                onSuccess={() => {
                  getPolicies()
                  props.isEdited()
                  onSuccessChangePaymentMethod()
                }}
              />
            </Collapse>}
        </div>
    );
}

const mapDispatchToProps = {
    setPolicies,
    setPoliciesLoading
}
export default connect(null, mapDispatchToProps)(withTranslation()(PaymentItem));