import React from "react";
import {connect} from "react-redux";
import Loading from "../../Loading";
import {GoogleApiWrapper} from "google-maps-react";
import SearchLocation from "../../portal/claim/SearchLocation";

class AddressAutocomplete extends React.Component {

    state = {
        place_service: null,
        sessionToken: null
    }

    constructor(props) {
        super(props);

        this.details_ref = React.createRef()
    }

    componentDidMount() {
        let place_service = new this.props.google.maps.places.PlacesService(this.details_ref.current),
            sessionToken  = new this.props.google.maps.places.AutocompleteSessionToken()

        setTimeout(() => {
            this.setState({
                place_service: place_service,
                sessionToken: sessionToken
            })
        }, 0)
    }

    onInputChange(newValue, reason) {
        if (reason === 'clear') this.props.onChangeLocation({
            street_address: '',
            street_address2: '',
            state: '',
            city: '',
            zip_code: ''
        })
        else this.props.onChange(this.props.id, newValue, reason)
    }

    onStreetChange(desc, newValue, reason) {
        if (newValue?.place_id) this.getStreetDetails(newValue)
    }

    getStreetDetails(place) {
        this.state.place_service.getDetails({
            placeId: place.place_id,
            fields: ['address_components'],
            sessionToken: this.state.sessionToken
        }, (place, status) => {
            this.updateStreetFields(place, status)
        })
    }

    updateStreetFields(place, status) {
        let data = {
            street_address: '',
            street_address2: '',
            state: '',
            city: '',
            zip_code: ''
        }

        if (status === this.props.google.maps.places.PlacesServiceStatus.OK && place) {

            place.address_components.forEach((item) => {
                if (item.types.includes('street_number')) {
                    data.street_address += item.long_name + ' '
                } else if (item.types.includes('route')) {
                    data.street_address += item.short_name
                } else if (item.types.includes('administrative_area_level_1')) {
                    data.state += item.short_name
                } else if (item.types.includes('locality')) {
                    data.city += item.short_name
                } else if (item.types.includes('sublocality')) {
                    data.city += item.short_name
                } else if (item.types.includes('room')) {
                    data.street_address2 = item.long_name
                } else if (item.types.includes('floor')) {
                    data.street_address2 = item.long_name
                } else if (item.types.includes('postal_code')) {
                    data.zip_code = item.long_name
                }
            })

            this.props.onChangeLocation(data)
        }
    }

    render() {
        return (
            <>
                <SearchLocation
                    fullWidth
                    google={this.props.google}
                    question={this.props.question}
                    answer={this.props.answer}
                    error={this.props.helperText}
                    onChangeLocation={(desc, newValue, reason) => {
                        this.onStreetChange(desc, newValue, reason)
                    }}
                    onChange={(newValue, reason) => {
                        this.onInputChange(newValue, reason)
                    }}
                />
                <div ref={this.details_ref}/>
            </>
        )
    }
}

const mapStateToProps = state => ({
    google_api_key: state.app.google_api_key
})

export default connect(mapStateToProps)(GoogleApiWrapper(
    (props) => {
        return {
            apiKey: props.google_api_key,
            language: 'us',
            libraries: ['places'],
            region: 'US',
            LoadingContainer: Loading
        }
    })(AddressAutocomplete))