import React from "react";
import {withTranslation} from 'react-i18next';
import {getLimitValue} from "../../../helpers/display";

const GLPolicyLimits = (props) => {
    const {t, quote: {limits}} = props
    return (
        <>
            <div className={'limit_info'}>

                <div className="portal_limit_card">
                    <span className="limit_name">{t('common.occurrence_limit')}</span>
                    <span
                        className="limit_value">{getLimitValue(limits?.gl_liability_occurrence_limit)}</span>
                </div>
                <div className="portal_limit_card">
                    <span className="limit_name">{t('common.aggregate_limit')}</span>
                    <span
                        className="limit_value">{getLimitValue(limits?.gl_liability_aggregate)}</span>
                </div>
                <div className="portal_limit_card">
                    <span className="limit_name">{t('common.deductible')}</span>
                    <span
                        className="limit_value">{getLimitValue(limits?.gl_damage_to_premises_rented_to_you_deductible)}</span>
                </div>
            </div>
            {/*OPTIONAL COVERAGES*/}
            {Object.keys(props.optional_coverages).some(item => ['cyber', 'hnoa', 'mpl', 'eb'].includes(item)) && (
                <div className="limit_additional_info">
                    {props.optional_coverages.hasOwnProperty('hnoa') && (
                        <div className="portal_limit_card">
                            <span className="addition_name">{t('common.hnoa_ins')}</span>
                        </div>)}
                    {props.optional_coverages.hasOwnProperty('mpl') && (
                        <div className="portal_limit_card">
                            <span className="addition_name">{t('common.pli')}</span>
                        </div>)}
                    {props.optional_coverages.hasOwnProperty('cyber') && (
                        <div className="portal_limit_card">
                            <span className="addition_name">{t('common.cyber_ins')}</span>
                        </div>)}
                    {props.optional_coverages.hasOwnProperty('eb') && (
                        <div className="portal_limit_card">
                            <span className="addition_name">{t('common.eb_ins')}</span>
                        </div>)}
                </div>)}
        </>
    )
}

export default withTranslation()(GLPolicyLimits)