import React from 'react';
import moment from "moment";
import {Link} from "react-router-dom";
import {AkarBell} from "../icons/AkarBell";

const SummaryUpcomingBills = (props) => {
    return (
        <div className={'content_upcoming_bills portal_card'}>
            <AkarBell/>
            <div className="bills_list">
                {props.upcomingBills.map((item, key) => {
                    return (<div className="row" key={key}>
                        <p><strong>You have an upcoming bill on {moment(item.due_date).format('MM/DD')}</strong> <Link
                            to="/portal/payment-center" className={'portal_link'}>Go to Payment Center</Link></p>
                    </div>)
                })}
            </div>
        </div>
    );
}
export default SummaryUpcomingBills;