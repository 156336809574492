import React from "react";

export const BrokenLink = ({style}) => {
    return (
        <svg width="361" height="319" viewBox="0 0 361 319" fill="none" xmlns="http://www.w3.org/2000/svg"
             style={style}>
            <g clipPath="url(#clip0)">
                <path
                    d="M93.2831 147.996C93.2831 212.574 145.573 264.925 210.075 264.925C274.578 264.925 326.867 212.574 326.867 147.996C326.867 83.4174 274.578 31.0664 210.075 31.0664C145.573 31.0664 93.2831 83.4174 93.2831 147.996Z"
                    fill="#F5F8FA"/>
                <path
                    d="M156.449 150.258L86.3393 150.258C74.193 150.258 64.3651 140.402 64.3651 128.258L64.3651 22C64.3651 12.9575 57.0462 5.64724 48.0315 5.64724L2.80273 5.64724C1.2455 5.64724 -0.0175783 4.38267 -0.0175782 2.82362C-0.0175782 1.26456 1.2455 -6.78417e-06 2.80273 -6.7161e-06L48.0315 -4.73909e-06C60.1606 -4.20891e-06 70.0058 9.85669 70.0058 22L70.0057 128.258C70.0057 137.283 77.3247 144.611 86.3393 144.611L156.449 144.611L156.449 150.258Z"
                    fill="#172152"/>
                <path
                    d="M167.506 166.195L145.064 166.195C141.604 166.195 138.784 163.389 138.784 159.907L138.784 134.962C138.784 131.497 141.587 128.674 145.064 128.674L167.506 128.674L167.506 166.195Z"
                    fill="#003EFF"/>
                <path
                    d="M172.455 126.439L172.455 168.429C172.455 168.88 172.092 169.261 171.625 169.261L168.337 169.261C167.887 169.261 167.507 168.897 167.507 168.429L167.507 126.439C167.507 125.989 167.87 125.607 168.337 125.607L171.625 125.607C172.092 125.625 172.455 125.989 172.455 126.439Z"
                    fill="#003EFF"/>
                <path
                    d="M172.454 141.804L172.454 135.412L187.733 135.412C189.497 135.412 190.934 136.85 190.934 138.617C190.934 140.384 189.497 141.822 187.733 141.822L172.454 141.822L172.454 141.804Z"
                    fill="#003EFF"/>
                <path
                    d="M240.415 153.082L240.415 159.475L225.137 159.475C223.372 159.475 221.936 158.037 221.936 156.27C221.936 154.503 223.372 153.065 225.137 153.065L240.415 153.065L240.415 153.082Z"
                    fill="#00BFFF"/>
                <path
                    d="M172.454 159.457L172.454 153.064L187.733 153.064C189.497 153.064 190.934 154.502 190.934 156.269C190.934 158.036 189.497 159.474 187.733 159.474L172.454 159.474L172.454 159.457Z"
                    fill="#003EFF"/>
                <path
                    d="M240.415 135.43L240.415 141.822L225.137 141.822C223.372 141.822 221.936 140.384 221.936 138.618C221.936 136.851 223.372 135.413 225.137 135.413L240.415 135.413L240.415 135.43Z"
                    fill="#00BFFF"/>
                <path d="M138.801 156.616L138.801 138.271L137.624 138.271L137.624 156.616L138.801 156.616Z"
                      fill="#23294F"/>
                <path
                    d="M141.328 138.271L130.099 138.271C128.888 138.271 127.798 138.705 126.95 139.432C125.912 140.316 125.237 141.649 125.237 143.139L125.237 151.749C125.237 154.434 127.417 156.616 130.099 156.616L138.802 156.616L141.328 156.616L141.328 138.271Z"
                    fill="#172152"/>
                <path
                    d="M358.18 319.001C356.622 319.001 355.359 317.736 355.359 316.177L355.359 166.629C355.359 157.586 348.04 150.276 339.026 150.276L264.936 150.276L264.936 144.629L339.026 144.629C351.155 144.629 361 154.486 361 166.629L361 316.177C361 317.736 359.737 319.001 358.18 319.001Z"
                    fill="#172152"/>
                <path
                    d="M271.286 156.616L282.515 156.616C285.197 156.616 287.377 154.433 287.377 151.748L287.377 143.122C287.377 141.632 286.719 140.315 285.664 139.415C284.816 138.687 283.709 138.254 282.515 138.254L271.286 138.254L271.286 156.616Z"
                    fill="#172152"/>
                <path
                    d="M245.088 166.195L267.53 166.195C270.99 166.195 273.811 163.389 273.811 159.907L273.811 134.962C273.811 131.497 271.008 128.674 267.53 128.674L245.088 128.674L245.088 166.195Z"
                    fill="#00BFFF"/>
                <path
                    d="M240.139 126.439L240.139 168.429C240.139 168.88 240.503 169.261 240.97 169.261L244.257 169.261C244.707 169.261 245.088 168.897 245.088 168.429L245.088 126.439C245.088 125.989 244.725 125.607 244.257 125.607L240.97 125.607C240.52 125.625 240.139 125.989 240.139 126.439Z"
                    fill="#00BFFF"/>
                <path
                    d="M245.088 166.195L267.53 166.195C270.99 166.195 273.811 163.389 273.811 159.907L273.811 134.962C273.811 131.497 271.008 128.674 267.53 128.674C265.661 155.628 245.088 158.088 245.088 158.088L245.088 166.195Z"
                    fill="#172152"/>
                <path
                    d="M220.826 98.2368L220.826 86.6132C220.826 85.7298 221.535 85.0195 222.418 85.0195C223.3 85.0195 224.01 85.7298 224.01 86.6132L224.01 98.2368C224.01 99.1203 223.3 99.8305 222.418 99.8305C221.535 99.8305 220.826 99.1203 220.826 98.2368Z"
                    fill="#00BFFF"/>
                <path
                    d="M235.55 112.978C235.55 112.095 236.259 111.385 237.142 111.385L248.752 111.385C249.634 111.385 250.344 112.095 250.344 112.978C250.344 113.862 249.634 114.572 248.752 114.572L237.142 114.572C236.259 114.572 235.55 113.862 235.55 112.978Z"
                    fill="#00BFFF"/>
                <path
                    d="M231.467 102.308C231.467 101.892 231.623 101.494 231.934 101.182L239.911 93.1962C240.534 92.5726 241.537 92.5726 242.177 93.1962C242.8 93.8199 242.8 94.8246 242.177 95.4655L234.201 103.451C233.578 104.075 232.574 104.075 231.934 103.451C231.623 103.14 231.467 102.724 231.467 102.308Z"
                    fill="#00BFFF"/>
                <path
                    d="M184.619 196.648L184.619 208.272C184.619 209.155 183.91 209.866 183.027 209.866C182.145 209.866 181.435 209.155 181.435 208.272L181.435 196.648C181.435 195.765 182.145 195.055 183.027 195.055C183.91 195.055 184.619 195.765 184.619 196.648Z"
                    fill="#003EFF"/>
                <path
                    d="M169.895 181.907C169.895 182.79 169.185 183.5 168.303 183.5L156.693 183.5C155.81 183.5 155.101 182.79 155.101 181.907C155.101 181.023 155.81 180.313 156.693 180.313L168.303 180.313C169.185 180.295 169.895 181.023 169.895 181.907Z"
                    fill="#003EFF"/>
                <path
                    d="M173.979 192.56C173.979 192.976 173.823 193.374 173.511 193.686L165.535 201.672C164.912 202.296 163.908 202.296 163.268 201.672C162.645 201.048 162.645 200.044 163.268 199.403L171.245 191.417C171.868 190.793 172.871 190.793 173.511 191.417C173.823 191.746 173.979 192.144 173.979 192.56Z"
                    fill="#003EFF"/>
                <path
                    d="M167.506 158.088C167.506 158.088 146.933 155.611 145.064 128.674C141.604 128.674 138.784 131.48 138.784 134.962L138.784 142.394L138.784 159.907C138.784 163.371 141.587 166.195 145.064 166.195L158.024 166.195L167.506 166.195L167.506 158.088Z"
                    fill="#172152"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="319" height="361" fill="white" transform="translate(361) rotate(90)"/>
                </clipPath>
            </defs>
        </svg>

    )
}