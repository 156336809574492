import React from "react";
import {Percent} from "../../icons/Percent";
import {withTranslation} from "react-i18next";
import NumberQuestion from "./NumberQuestion";
import NumberFormat from "react-number-format";
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, InputAdornment, TextField} from "@material-ui/core";

function PercentageNumberFormat(props) {
    return (
        <NumberFormat
            className={props.className}
            getInputRef={props.inputRef}
            // format="###"
            allowEmptyFormatting
            value={props.value}
            placeholder={props.placeholder}
            onValueChange={(values) => {
                props.onChange(values)
            }}
            onBlur={props.onBlur}
        />
    )
}

class Percentage extends NumberQuestion {

    state = {
        value: ''
    }

    constructor(props) {
        super(props);

        this.state.value = this.props.answer
    }

    onChange(values) {
        let value = this.verifyMaxValue(this.props.max_value, values.floatValue)

        this.setState({value: value})

        if (this.props.onChange) this.props.onChange(this.props.id, value)
    }

    onBlur() {
        if (this.props.onBlur) this.props.onBlur(this.props.id, this.state.value)
    }

    render() {
        const {t} = this.props

        return (
            <FormControl fullWidth={this.props.fullWidth}
                         className={'form_control'}
                         error={this.props.error}>
                {this.props.question && (
                    <div className="label_blk">
                        <div className="label">{this.props.question} &nbsp;{this.props.tooltip} </div>
                        {this.props.autofilled && <AutofilledBadge/>}
                    </div>
                )}
                <TextField
                    className={'fieldText'}
                    variant="outlined"
                    type="text"
                    value={this.props.answer}
                    error={this.props.error}
                    placeholder={this.props.placeholder}
                    InputProps={{
                        inputComponent: PercentageNumberFormat,
                        startAdornment: <InputAdornment position="start"><Percent/></InputAdornment>,
                    }}
                    onChange={(values) => {
                        this.onChange(values)
                    }}
                    onBlur={() => {
                        this.onBlur()
                    }}
                />
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
                {this.state.above_max &&
                <p className="error_txt error_txt_md error">{t('form.error.max_permitted_value', {
                    value: this.props.max_value
                })}</p>}
            </FormControl>
        )
    }
}

export default withTranslation()(Percentage)
