import React from 'react'

const Error = () => {
    return (<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.1719 24.3173L24.2279 6.07592C22.7914 3.68236 20.594 2.3125 18.1949 2.3125C15.7958 2.3125 13.5985 3.68236 12.162 6.07221L1.2179 24.3173C-0.237117 26.7405 -0.396317 29.3247 0.782875 31.4072C1.96021 33.4916 4.25936 34.6875 7.08794 34.6875H29.3019C32.1305 34.6875 34.4296 33.4916 35.607 31.4091C36.7862 29.3265 36.627 26.7423 35.1719 24.3173ZM18.1949 28.2991C16.614 28.2991 15.3256 27.0126 15.3256 25.4317C15.3256 23.8489 16.6122 22.5605 18.1949 22.5605C19.7777 22.5605 21.0642 23.8489 21.0642 25.4317C21.0642 27.0126 19.7758 28.2991 18.1949 28.2991ZM21.2179 14.5561C21.1975 14.6135 18.6244 20.9759 18.6244 20.9759C18.554 21.1499 18.3837 21.2647 18.1968 21.2647C18.0098 21.2647 17.8395 21.1499 17.7692 20.9759L15.1942 14.6116C15.0387 14.2262 14.9576 13.8148 14.9554 13.3991C14.9554 11.6127 16.4085 10.1596 18.1949 10.1596C18.7196 10.1609 19.2361 10.2895 19.7001 10.5344C20.1641 10.7792 20.5618 11.133 20.8591 11.5653C21.1563 11.9976 21.3443 12.4956 21.4068 13.0165C21.4692 13.5374 21.4044 14.0657 21.2179 14.5561Z"
            fill="#A92525" />
    </svg>)
}

export default Error



