
export enum ProductTypes {
    BOP = 'BOP',
    WC  = 'WC',
    PL  = 'PL',
    GL  = 'GL',
    CYBER= 'CYBER'
}

export const indexProducts = (arr: [], key: ProductTypes, idxKey: string) => {
    /**
     * Function takes in an array of objects for products or quotes and adds an index number to each object in the
     * array.
     * @param arr - array of objects containing products or quotes
     * @param key - object property where product type is stored
     * @param idxKey - new object property where index value should be stored
     */
    return arr.map((itm: {}) => {
        let idx: number
        if (itm[key] === ProductTypes.BOP) idx = 0
        else if (itm[key] === ProductTypes.WC) idx = 1
        else idx = 3
        return {...itm, [idxKey]: idx}
    })
}

export const sortProducts = (field: string) => {
    /**
     * Takes in array of objects and sorts based on the provided index value.
     * @param field - object property key where index value exists
     */
    return function(a, b) {
        if (a[field] < b[field]) return -1
        else if (a[field] > b[field]) return 1
        return 0
    }
}