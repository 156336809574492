import DoRequest from "./do-request";

const RegisterNewUser = async (answers, suppress_email = false) => {

    return await DoRequest({
        url: "/api/user",
        method: "post",
        body: {
            "username": answers["contact.email"],
            "first_name": answers["contact.first_name"],
            "last_name": answers["contact.last_name"],
            "phone_number": answers["contact.phone"].toString(),
            "street_address": answers["business.street_address"],
            "street_address2": answers["business.street_address2"],
            "city": answers["business.city"],
            "state": answers["business.state"],
            "zip": answers["business.zip_code"].toString(),
            "suppress_email": suppress_email
        }
    })
}

export default RegisterNewUser