import React from "react";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {Calendar} from "../../icons/Calendar";
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl} from "@material-ui/core";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
//scss
import '../../../scss/date-time-picker.scss'

export default class DateTime extends React.Component {

    state = {
        defaultFormat: "MM/dd/yyyy HH:mm",
        value: null
    }

    componentDidMount() {
        let value = this.props.answer || null
        this.onDateTimeChange(value)
    }

    getMinDate() {
        return this.props.date_only_in_future
            ? this.getTomorrow().toString()
            : undefined
    }

    getTomorrow() {
        return moment().add(1, 'days');
    }

    getMaxDate() {
        return this.props.date_only_in_past
            ? this.getYesterday().toString()
            : undefined
    }

    getYesterday() {
        return moment().add(-1, 'days');
    }

    onDateTimeChange(e) {
        this.setState({value: e}, () => {
            if (this.props.onChange) this.props.onChange(this.props.id, e)
        })
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth}
                         className={'form_control'}
                         error={this.props.error}>
                <div className="label_blk">
                    <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                    {this.props.autofilled && <AutofilledBadge/>}
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                        autoOk={this.props.autoOk || true}
                        disableFuture={this.props.disable_future}
                        disablePast={this.props.disable_past}
                        minDate={this.getMinDate()}
                        maxDate={this.getMaxDate()}
                        maxDateMessage={null}
                        minDateMessage={null}
                        invalidDateMessage={null}
                        variant="inline"
                        format={this.props.format ? this.props.format : this.state.defaultFormat}
                        margin="none"
                        id={'date-picker-' + this.props.id}
                        views={['date', 'month', 'year', 'hours', 'minutes']}
                        value={this.props.answer}
                        inputVariant="outlined"
                        error={this.props.error}
                        keyboardIcon={<Calendar/>}
                        onChange={(e) => this.onDateTimeChange(e)}
                        KeyboardButtonProps={{'aria-label': 'Select date'}}
                        openTo="date"
                        placeholder="mm/dd/yyyy hh:mm"
                    />
                </MuiPickersUtilsProvider>
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}