import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import DoRequest from '../../hooks/do-request'
import Question from '../form-components/Question'
import { Grid } from '@material-ui/core'
import { updateWCIndustryClasses, updateWCLocations } from '../../store/insurance/actions'
import Text from '../form-components/question/Text'
import sources_update from '../../common/decorator'

const PayrollQuestions = (props) => {
  const [openIndustryClassesDropdown, setOpenIndustryClassesDropdown] = useState(false)
  const industryClassesLoading = openIndustryClassesDropdown && props.wc_industry_classes.length === 0

  const { t, updateWCIndustryClasses, answers } = props

  const removePayrollLine = () => {
    const current_class = rateBlock()
    const locations = [...props.wc_locations]

    let deletedItem = locations[0].rate_classes[current_class]
    const index = locations[0].rate_classes.indexOf(deletedItem)
    if (index > -1) {
      locations[0].rate_classes.splice(index, 1)
    }
    if (locations[0].rate_classes.length < 2) locations[0].is_addition_payroll_classes = false
    props.updateWCLocations(locations)
    props.clearErrors()
  }

  const getQuestionAnswer = (qid) => {
    let path_components = qid.split('.'),
      prop_name = path_components[path_components.length - 1]

    return props.wc_locations[props.location_index].rate_classes[props.index][prop_name]
  }

  const getPayrollPath = (key) => {
    return key
      ? props.location_index + '.rate_classes.' + props.index + '.' + key
      : props.location_index + '.rate_classes.' + props.index
  }

  const onChange = (key, value) => {
    let rate_classes = [...props.wc_locations[props.location_index].rate_classes]

    rate_classes[props.index][key] = value

    let locations = [...props.wc_locations]
    locations[props.location_index].rate_classes = [
      ...rate_classes
    ]

    props.updateWCLocations(locations)
    sources_update(answers, key)
  }

  const onChangeClass = (text, value) => {
    let rate_classes = [...props.wc_locations[0].rate_classes]

    if (value && text) {
      rate_classes[props.index].class_code_description = value
      rate_classes[props.index].class_code = text
    } else {
      rate_classes[props.index].class_code_description = ''
      rate_classes[props.index].class_code = ''
    }

    let locations = [...props.wc_locations]
    locations[0].rate_classes = [
      ...rate_classes
    ]
    props.updateWCLocations(locations)
    sources_update(answers, text)
  }

  const rateBlock = () => {
    const locations = [...props.wc_locations]
    return locations[0].rate_classes.indexOf(locations[0].rate_classes[props.index])
  }

  const activateZendeskChat = () => {
    window.zE.activate()
  }

  useEffect(() => {
    let active = true

    if (!industryClassesLoading) {
      return undefined
    }

    (async () => {

      await DoRequest({
        url: '/api/classes/v2',
        method: 'post',
        body: {
          payload: {
            level: '10',
            line_of_business: 'WC',
            state: answers['business.state']
          }
        },
        onSuccess: (response) => {
          if (response.data.length > 0 && active) {
            updateWCIndustryClasses(response.data)
          }
        },
        onError: (e) => {
            throw new Error(`Something went wrong while fetching WC industry classes. ${e}`)
        }
      })
    })()

    return () => {
      active = false
    }

  }, [industryClassesLoading, answers, updateWCIndustryClasses])

  return (
      <Grid container spacing={2}>
        {props.index > 0 && (<Grid item xs={12} className={'wc_custom_item_header'}>
          <p className="wc_additional_ttl">
            {t('page.pi.additional_title')}
          </p>
          <button type={'button'} className="wc_additional_remove"
                  onClick={() => removePayrollLine()}
          >{t('common.btn_remove')}</button>
        </Grid>)}
        <Grid item xs={12}>
          {props.index === 0 ?
            <Text fullWidth
                  type="text"
                  question={t('form.label.industry_class')}
                  answer={getQuestionAnswer('premises.*.class_code_description')}
                  field_disabled
                  autofilled
            /> :
            <Question type="typeahead"
                      fullWidth
                      question={t('form.label.industry_class')}
                      placeholder={t('form.placeholder.industry_class')}
                      answer={getQuestionAnswer('premises.*.class_code')}
                      options={props.wc_industry_classes}
                      noOptionsText={t('form.class_no_options_text')}
                      loading={industryClassesLoading}
                      open={openIndustryClassesDropdown}
                      onOpen={(val) => setOpenIndustryClassesDropdown(val)}
                      renderOption
                      onChange={(keyId, desc, id) => onChangeClass(id, desc)}
                      error={props.errors?.[getPayrollPath('class_code_description')]}
                      helperText={props.errors?.[getPayrollPath('class_code_description')]}
            />
          }
        </Grid>
        {props.index > 0 && <Grid item xs={12} alignItems={'flex-start'}>
          <span onClick={activateZendeskChat} className={'underline_link cant_find_payroll_class'}>Can’t find the right payroll class?</span>
        </Grid>}
        <Grid item xs={12}>
          <Question fullWidth
                    type="currency"
                    question={t('form.placeholder.payroll_amount')}
                    field_length={30}
                    answer={getQuestionAnswer('premises.*.payroll_amount')}
                    variant="outlined"
                    error={!!props.errors?.[getPayrollPath('payroll_amount')]}
                    helperText={props.errors?.[[getPayrollPath('payroll_amount')]]}
                    onChange={(id, value) => onChange('payroll_amount', value)}
          />
        </Grid>
      </Grid>
  )
}

const mapStateToProps = state => ({
  answers: state.insurance.answers,
  wc_industry_classes: state.insurance.wc_industry_classes,
  wc_locations: state.insurance.wc_locations,
  wc_rate_classes_template: state.insurance.wc_rate_classes_template
})

const mapDispatchToProps = {
  updateWCLocations,
  updateWCIndustryClasses
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PayrollQuestions))
