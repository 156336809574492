import React from "react";
import NumberFormat from 'react-number-format'
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, TextField} from "@material-ui/core";
import sources_update from '../../../common/decorator'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'

function PhoneNumberFormat(props) {
    return (
        <NumberFormat
            className={props.className}
            value={props.value}
            getInputRef={props.inputRef}
            onValueChange={(values) => {
                props.onChange(values)
            }}
            mask="_"
            allowEmptyFormatting
            format="+1 (###) ### ####"
            placeholder="+1 (###) ### ####"
        />
    );
}

class Phone extends React.Component {

    onChange(values) {
        if (this.props.onChange) this.props.onChange(this.props.id, values.floatValue)
        sources_update(this.props.answers, this.props.qid)
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth}
                         className={'form_control'}
                         error={this.props.error}>
                <div className="label_blk">
                    <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                    {this.props.autofilled && <AutofilledBadge/>}
                </div>
                <TextField
                    className={'fieldText'}
                    variant="outlined"
                    type="text"
                    value={this.props.answer}
                    error={!!this.props.error}
                    InputProps={{
                        inputComponent: PhoneNumberFormat,
                    }}
                    onChange={(values) => this.onChange(values)}
                />
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
})
  
export default connect(mapStateToProps)(withTranslation()(Phone))
  