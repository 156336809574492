export const SET_USER_AUTH = 'SET_USER_AUTH'
export const LOG_OUT = 'LOG_OUT'

export const setUserAuth = data => ({
    type: SET_USER_AUTH,
    payload: data
})

export const logOut = data => ({
    type: LOG_OUT,
    payload: data
})
