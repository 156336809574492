import React from "react";
import axios from "axios";
import {includes} from "lodash";
import {connect} from "react-redux";
import withWidth from '@material-ui/core/withWidth';
import {
    changeAnswers,
    setNexSectionButtonText,
    setRestoreSession,
    updateIndustryClasses,
    updateInsStoreKey,
    updateQuestionsAutofill,
    updateQuestionsClassStateBased,
    updateSections,
    updateWCIndustryClasses
} from "../store/insurance/actions";
import Loading from "../components/Loading";
import Header from "../layouts/insurance/Header";
import GetNotified from "./insurance/GetNotified";
import NotFoundError from "./errors/NotFoundError";
import {Route, Switch, withRouter} from "react-router-dom";
import {Box, Dialog, Grid} from '@material-ui/core'
import GetNotifiedState from "./insurance/GetNotifiedState";
import SaveYourProgress from "../components/modals/SaveYourProgress";
import SectionIndicator from '../components/insurance/SectionIndicator'
import BeforeUnloadComponent from '../components/BeforeUnloadComponent'
import SectionDetailInfo from "../components/insurance/SectionDetailInfo";
import {ChevronRight} from "@material-ui/icons";
import ErrorBoundary from "../components/ErrorBoundary";
import DoRequest from "../hooks/do-request";
import Footer from "../layouts/insurance/Footer";
import {datadogRum} from "@datadog/browser-rum";
import LoadingButton from "../components/LoadingButton";

class Insurance extends React.Component {

    state = {
        rejected: false,
        state_rejected: false,
        loading: true,
        rejection_data: null,
        isOpenSidebarNav: false,
        isAcrisure:false
    }

    getParam(key) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get(key)
    }

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.performReject = this.performReject.bind(this)
        this.onQuoteChanged = this.onQuoteChanged.bind(this)

      //try to restore session by key
        if (this.props.restore_session) {this.restoreSession()}
        else if(this.props.restoreCheckout){
          this.restoreSession()
        } else {
            if (this.props.history.location.search) this.presetData()
            else if (window.location.pathname !== "/get-insurance/business-name-and-address") {
                this.props.history.push("/get-insurance/business-name-and-address");
                this.state.loading = false
            } else {
                this.state.loading = false
            }
        }
    }

    componentDidMount() {
        document.addEventListener('quotes-changed', this.onQuoteChanged)
        document.addEventListener('mousedown', this.handleClickOutside);

        this.getNextSectionButtonText(this.props.current_subsection, this.props.subsections)

      if(this.props.answers['acrisure_id'] !== ''){
        this.setState({isAcrisure: true})
      }
    }

    // path is already wrong at this point
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.restore_session
            && prevProps.location.pathname !== this.props.location.pathname
            && this.props.history.location.pathname !== '/get-insurance/business-name-and-address') {
            //check if subsection is available
            let available = this.props.subsections.filter(s => '/get-insurance' + s.path === this.props.history.location.pathname)

            if (available.length === 0) this.props.history.push("/get-insurance/business-name-and-address");
        }
        if(this.props.current_subsection !== prevProps.current_subsection || this.props.answers['coverage.types'] !== prevProps.answers['coverage.types']){
            this.getNextSectionButtonText(this.props.current_subsection, this.props.subsections)
        }
        if (this.props.close_get_notified && prevProps.close_get_notified !== this.props.close_get_notified) {
            this.setState({
                rejected: false,
                rejection_data: null,
            }, () => {
                this.props.updateInsStoreKey('close_get_notified', false)
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('quotes-changed', this.onQuoteChanged)
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    presetData() {
        let type = new URLSearchParams(this.props.history.location.search)

        type = type.get('type')
        if (type) {
            const search = this.props.coverage_types.filter(itm => {
                return itm.lob === type
            })
            if (search.length > 0) {
                this.props.changeAnswers({
                    "coverage.types": [type]
                })
                this.getSections([type]).then(response => {
                    this.props.history.replace("/get-insurance" + this.props.subsections[0].path)
                    this.setState({loading: false})
                }).catch(e => {
                    throw new Error(`Something went wrong while getting the application sections. ${e}`)
                })
            }
        } else {
            this.props.history.replace("/get-insurance/business-name-and-address");
            // eslint-disable-next-line
            this.state.loading = false
        }
    }

    toStep(path) {
        //Disable further session restoring
        let toPath =  path === '/get-insurance/checkout' && this.props.answers['coverage.types'].includes('CYBER') ? `${path}?checkout_session=${this.props.unique_session_id}`: path

        this.props.setRestoreSession(false)
        //Push to last available insurance step
        this.props.history.push(toPath)
        // eslint-disable-next-line
        if (path === '/get-insurance/business-name-and-address') this.state.loading = false
    }

    async restoreSession() {
        if (
            this.props?.location?.pathname === '/get-insurance/select-your-policy-type' 
            && this.getParam("source") === 'tuneup'
        ) {
            this.props.updateInsStoreKey('filteredCoverageTypes', [])
            
            if (this.props.answers["b2z_naics_code"]) {
                await DoRequest({
                    /**
                    * On Success: coverage_types is updated based on appetite API response
                    * On Error: coverage_types is set to blank string to force an error on SelectPolicy.js
                    */
                    url: "/api/classes/get-appetite",
                    method: "post",
                    body: {
                        state: this.props.answers['business.state'],
                        naics: this.props.answers['b2z_naics_code']
                    },
                    onSuccess: (appetite) => {
                        let appetiteKeys = Object.keys(appetite?.data)
                        let availableProducts = this.props.coverage_types.map(obj => {
                            if (!appetiteKeys.includes(obj.lob)) return {...obj, isDisabled: true}
                            else return {...obj, appetite: appetite.data[obj.lob], isDisabled: false}
                        })

                        this.props.updateInsStoreKey('filteredCoverageTypes', availableProducts)
                    },
                    onError: (e) => {
                        this.props.updateInsStoreKey('filteredCoverageTypes', '')
                        throw new Error(`Something went wrong while getting appetite. ${e}`)
                    }
                })
            }
            this.setState({loading: false})
            this.toStep(this.props?.location?.pathname)
            return true
        }
        let checkoutRestoreSession = this.props.restoreCheckout

        //Default start point
        let step =checkoutRestoreSession? "/get-insurance/checkout": this.props.is_from_bulk || this.props?.location?.pathname === '/get-insurance/new-lead'
            ? "/get-insurance/business-name-and-address"
            : "/get-insurance/select-your-rate"

        // Unique url condition specific to the early customer identification alerts in slack
        if(this.props.location.pathname === '/get-insurance/new-lead' && !this.props.is_from_bulk) {
            this.setState({loading: false})
            this.toStep(step)
        }

        // Fallback if coverage type wasn't selected
        else if (this.props.answers["coverage.types"].length === 0) {
          return true
        }
        else {
            this.getAnimationCards()
                .then(() => {
                    this.updateQuestions(this.props.answers["coverage.types"])
                        .then(() => {
                            this.getSections(this.props.answers["coverage.types"])
                                .then(async result => {
                                    var question = null
                                    const isIncludeCoverage = this.props.answers['coverage.types'].some(r=> ['BOP', 'WC'].indexOf(r) >= 0)
                                    if (isIncludeCoverage) {
                                        this.loadIndustryClasses()
                                        this.advisorServicePreCheck()
                                    }

                                    let answers_to_proceed = {}
                                    if (this.props.answers?.prefills) answers_to_proceed = {...this.props.answers.prefills}
                                    if (this.props.answers.hasOwnProperty('hide'))
                                        answers_to_proceed['hide'] = [...this.props.answers['hide']]

                                    let fill = !(this.props.is_from_bulk || this.props.is_session_restored)

                                    this.props.updateQuestionsAutofill({
                                        answers: answers_to_proceed,
                                        mark: fill,
                                        fill_values: false
                                    })

                                    //Search for last unanswered question
                                    for (let i = 0; i < this.props.subsections_questions.length; i++) {
                                        let q = this.props.subsections_questions[i]
                                        //If new question appears - this is our start point
                                        if (!this.props.answers.hasOwnProperty(q.qid)) {
                                            question = q
                                            break
                                        } else {
                                            //Else check old questions set. Only for required
                                            if (q.required) {
                                                if (q.question_type === 'Boolean') {
                                                    //If question type is boolean and not boolean value in answer - that's what we looking for
                                                    if (typeof this.props.answers[q.qid] !== Boolean) {
                                                        question = q
                                                        break
                                                    }
                                                } else {
                                                    //If question type is different from boolean and has no answer - that's what we looking for too
                                                    if (this.props.answers[q.qid] === undefined || this.props.answers[q.qid] === '') {
                                                        question = q
                                                        break
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    if (question) {
                                        // let subsection = this.props.subsections.filter(itm => {
                                        //     return itm.section_index === question.section && itm.subsection_index === question.subsection
                                        // })?.[0]
                                        // if (subsection) step = '/get-insurance' + subsection.path
                                        this.toStep(step)
                                    } else if (this.props.answers['coverage.types'][0] === 'PL'
                                        && this.props.answers['coverage.types']?.length === 1  && !question ) {
                                        /**
                                         * Every question is answered, but coverage type is PL, GL, CYBER... last questions are
                                         * defaulted, but we still want customer to still review/confirm them since
                                         * they are returning to quote from a saved session.
                                         */
                                        this.toStep(step)
                                    }else if (this.props.answers['coverage.types'][0] === 'GL'
                                        && this.props.answers['coverage.types']?.length === 1  && !question ) {

                                        this.toStep(step)
                                    }else if (this.props.answers['coverage.types'][0] === 'CYBER'
                                        && this.props.answers['coverage.types']?.length === 1  && !question ) {

                                        this.toStep(step)
                                    } else {
                                        //Every question is answered, proceed to create account
                                        step = '/get-insurance/create-an-account'
                                        this.toStep(step)
                                    }
                                })
                                .catch(e => {
                                    //Something wrong, proceed to start point
                                  this.toStep(step)
                                  throw new Error(`Can't get sections data. ${e}`)
                                })
                                .finally(() => {
                                    this.setState({loading: false})
                                })
                        })
                })
        }
    }
    async advisorServicePreCheck() {
        const { answers, unique_session_id, advisorCache, is_from_bulk, is_session_restored } = this.props

        return await DoRequest({
            url: '/api/advisor-service-pre-check/business-data',
            method: 'post',
            body: {
                businessName: answers['business.name'],
                zipCode: answers['business.zip_code'],
                state: answers['business.state'],
                sessionId: unique_session_id,
                advisorCache: advisorCache,
                isFromBulk: is_from_bulk,
                isSessionRestored: is_session_restored,
                appetite: answers['appetite']
            },
            onSuccess: (result) => {
                const {callAdvisorService, advisorCache} = result.data
                if (callAdvisorService) {
                    this.props.updateInsStoreKey('advisorCache', advisorCache)
                    return true
                } else return false
            },
            onError: (e) => {
                datadogRum.addError(`Failure calling /api/advisor-service-pre-check/business-data. ${e}`)
                return true
            }
        })
    }

    async loadIndustryClasses() {
        return this.getIndustryClasses()
            .then(async (content_length) => {
                if (content_length > 0) {
                    return await this.getIndustryClassesByGoogleTags(this.props.answers['business.g_types'] || [])
                        .then(async (fetched_classes) => {
                            if (fetched_classes.length === 0 && this.props.answers['business.naics_code'])
                                return await this.getIndustryClassesByNaicsCode(this.props.answers['business.naics_code'])
                            else return true
                        })
                }
            })
    }

    async getIndustryClasses() {
        /**
         * Only called for bulk and restored sessions.  Would need to add additional API call to get WC and BOP classes
         * when saved session is fetched through return to quote link.  Both requests are executed asynchronously for
         * speed improvement.
         */
        DoRequest({
            url: '/api/classes/v2',
            method: 'post',
            body: {
                payload: {
                    level: '10',
                    line_of_business: 'ALL',
                    state: 'ALL'
                }
            },
            onSuccess: (response) => {
                if (response.data.length > 0) this.props.updateIndustryClasses(response.data)
            },
            onError: (e) => {
              throw new Error(`Error fetching industry classes. ${e}`)
            }
        })

        DoRequest({
            url: '/api/classes/v2',
            method: 'post',
            body: {
                payload: {
                    level: '10',
                    line_of_business: 'WC',
                    state: this.props.answers['business.state']
                }
            },
            onSuccess: (response) => {
                if (response.data.length > 0) this.props.updateWCIndustryClasses(response.data)
            },
            onError: (e) => {throw new Error(`Error fetching WC industry classes. ${e}`)}
        })
    }


    async getIndustryClassesByGoogleTags(tags) {
        const fetched_classes = this.props.industry_classes.filter(itm => {
            return includes(tags, itm?.related_google_tag)
        })

        this.props.updateInsStoreKey('fetched_industry_classes', fetched_classes)

        if (fetched_classes.length > 0) {

            this.props.changeAnswers({
                "b2z_class_code_id": (fetched_classes.length === 1) ? fetched_classes[0].id : this.props.answers['b2z_class_code_id'],
                "b2z_naics_code": (fetched_classes.length === 1) ? fetched_classes[0].naics_code : this.props.answers['b2z_naics_code'],
                "diya.class_found": true
            })
        }

        return fetched_classes
    }

    async getIndustryClassesByNaicsCode(code) {
        if (this.props.fetched_classes.length === 0) {

            code = code.substr(0, 6)

            const fetched_classes = this.props.industry_classes.filter(itm => {
                return itm.naics_code.startsWith(code)
            })

            this.props.updateInsStoreKey('fetched_industry_classes', fetched_classes)

            if (fetched_classes.length > 0) {
                this.props.changeAnswers({
                    "b2z_class_code_id": (fetched_classes.length === 1) ? fetched_classes[0].id : this.props.answers['b2z_class_code_id'],
                    "b2z_naics_code": (fetched_classes.length === 1) ? fetched_classes[0].naics_code : this.props.answers['b2z_naics_code'],
                    "diya.class_found": true
                })
            }
        }

        return true
    }

    async getSections(lob) {
        if (lob.length === 0) this.props.history.push("/get-insurance/business-name-and-address")
        return this.props.updateSections(lob)
    }

    closeDialog() {
        this.setState({rejected: false})
    }

    closeStateDialog() {
        this.setState({state_rejected: false})
    }

    performReject(quotes, coverages = null) {

        this.setState({
            rejected: true,
            rejection_data: {
                quotes: quotes,
                coverages: coverages
            },
        }, () => {
            this.props.changeAnswers({
                'quote_status': 'Rejected Quote'
            })

            this.props.updateInsStoreKey('is_quote_rejected', true)
        })
    }

    performStateReject() {
        this.setState({state_rejected: true})
    }

    onQuoteSaved() {
        this.props.changeAnswers({
            'session_saved': true
        })
    }

    shouldRoutesBlock() {
        if (!!this.props.answers?.["session_complete"]) return false

        return (
                this.props.answers["selected_quotes"].length > 0
                && (this.props.full_quote.length === 0 || this.props.full_quote.filter(itm => !itm?.error).length > 0)
            )
            && !this.props.answers["session_saved"]
    }

    async getAnimationCards() {
        return await axios.get('/api/get-animation-cards')
            .then(response => {
                this.props.updateInsStoreKey('loading_cards', response.data)
                return true
            })
            .catch(e => {
                datadogRum.addError(`Can't load animation cards. ${e}`)
                return true
            })
    }

    updateIndicatorData(quotes) {
        if (quotes) {
            let price = 0
            let type = []

            quotes.forEach(q => {
                price += q.term_premium
                type.push(q.quote_type)
            })

            if (price > 0) {
                setTimeout(() => {
                    this.props.changeAnswers({
                        "bulk": {
                            "term_premium": price,
                            "type": type
                        }
                    })
                }, 0)
            } else {
                setTimeout(() => {
                    this.props.changeAnswers({
                        "bulk": {
                            "term_premium": null,
                            "type": type
                        }
                    })
                }, 0)
            }
        }
    }

    onQuoteChanged(event) {
        this.updateIndicatorData(event.detail || [])
    }

    async updateQuestions(coverage_types) {

        let address = ''

        if (this.props.answers['business.name'] && this.props.answers['business.zip_code']) {
          address += this.props.answers['business.street_address'] + ', '
          if (this.props.answers['business.street_address2']) address += this.props.answers['business.street_address2'] + ', '
          address += this.props.answers['business.city'] + ', '
          address += this.props.answers['business.state'] + ', '
          address += 'USA'
        }

        return await axios.post("/api/coverage-type", {
            name: this.props.answers['business.name'],
            zip: this.props.answers['business.zip_code'],
            address_str: address,
            phone: this.props.answers['contact.phone']?.toString() || '',
            website: this.props.answers['business.website'] || '',
            place_id: this.props.answers['business.place_id'] || '',
            session_id: this.props.unique_session_id,
            lob: coverage_types,
            state: this.props.answers['business.state'],
            class_code: this.props.answers['b2z_naics_code']
        }).then(response => {
                if (!response.data) {
                    //raise an error, try to repeat
                    datadogRum.addError(`Can't load carrier-based questions, empty response.`)
                } else this.props.updateQuestionsClassStateBased(response.data, coverage_types)
                return true
            }
        ).catch(e => {
            datadogRum.addError(`Can't load carrier-based questions. ${e}`)
            return true
        })
    }

    onSidebarHandler(val) {
        this.setState({isOpenSidebarNav: !val})
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({isOpenSidebarNav: false})
        }
    }

    convertSectionNextButton(nextSection) {
        /**
         *
         * Check next subsection path and return value for next button
         *
         **/
        if(nextSection){
            switch (nextSection.path) {
                case '/industry-class':
                    return 'Business Type'
                case '/select-your-policy-type':
                    return 'Coverage Choices'
                case '/experience-and-revenue':
                    return 'Business Details'
                case '/business-locations':
                    return 'Business location'
                case '/premises-information':
                    return 'Business Location'
                case '/owners-and-officers':
                    return 'Owner/Officer Info'
                case '/contact-information':
                    return 'Contact Info'
                case '/select-your-rate':
                    return 'Coverage Decision'
                case '/customize-rate':
                    return 'Customize & Complete Purchase'
                case '/qualifying-questions':
                case '/additional-property-information':
                case '/previous-insurer':
                    return 'Qualifying Questions'
                case '/underwriter-statements':
                    return 'Qualifying Questions'
                case '/create-an-account':
                    return 'Payment Details'
                case '/checkout':
                    return 'Sign In'
                case '/request-your-quote':
                    return 'Submit Payment'
                default:
                    return nextSection.name;
            }
        }

    }

    getNextSectionButtonText(current_subsection, subsections) {
        let nextSection = subsections[current_subsection + 1]

        let text = this.convertSectionNextButton(nextSection)

        this.props.setNexSectionButtonText(text)
    }

    render() {
        return (
            <>
                <Header/>
                <ErrorBoundary>
                    <div className="content insurance_content">
                        <div className="full-width-container">
                            {this.state.loading || !this.props.layout_built ? (
                                <Grid container spacing={6} alignContent="stretch"
                                      justifyContent={'center'}
                                      style={{height: '100%', margin: '0 -24px'}}>
                                    <Grid item>
                                        <LoadingButton size={50}/>
                                    </Grid>
                                </Grid>
                            ) : (
                                <div className={'insurance_layout'}>
                                    <div ref={this.setWrapperRef.bind(this)}
                                         className={`sections-indicator-container ${this.state.isOpenSidebarNav && 'in'}`}
                                         style={{marginTop: `-13px`}}>
                                        {this.props.width === 'sm' || this.props.width === 'xs' ?
                                            <div onClick={() => this.onSidebarHandler(this.state.isOpenSidebarNav)}
                                                 className={'sidebar_nav_btn'}><ChevronRight fontSize={'large'}/></div>
                                            : null}
                                        <SectionIndicator sections={this.props.sections}
                                                          current={this.props.current_section}
                                                          current_index={this.props.current_section_index}
                                                          total_progress={this.props.total_progress}
                                        />
                                        {this.props.total_progress !== 0 && <div className="section_details_info">
                                            <SectionDetailInfo sections={this.props.sections}
                                                               current={this.props.current_section}
                                                               current_index={this.props.current_section_index}
                                                               total_progress={this.props.total_progress}
                                            />
                                        </div>}
                                    </div>
                                    <div className="sections-container">
                                        {/*<ProcessTotalProgress history={this.props.history}/>*/}
                                        <BeforeUnloadComponent
                                            blockRoute={this.shouldRoutesBlock()}
                                            modalComponentHandler={({handleModalLeave, handleModalCancel}) => {
                                                return (
                                                    <SaveYourProgress show
                                                                      answers={this.props.answers}
                                                                      handleModalLeave={handleModalLeave}
                                                                      handleModalCancel={handleModalCancel}
                                                                      onSaved={() => {
                                                                          this.onQuoteSaved()
                                                                      }}
                                                    />
                                                )
                                            }}
                                        >
                                            <Box px={{xs: 3, md: 7}}>

                                                <Switch>
                                                    {this.props.subsections.map((route, i) => (
                                                        <Route key={i} path={this.props.match.path + route.path}>
                                                            <route.layout {...this.props}
                                                                          next_subsection={i + 1}
                                                                          section={route}
                                                                          onReject={(e) => {
                                                                              this.performReject(e)
                                                                          }}
                                                                          onStateReject={() => {
                                                                              this.performStateReject()
                                                                          }}
                                                            />
                                                        </Route>
                                                    ))}
                                                    <Route key="error" path={this.props.match.path + '*'}>
                                                        <NotFoundError history={this.props.history}/>
                                                    </Route>
                                                </Switch>

                                            </Box>
                                        </BeforeUnloadComponent>
                                        <Footer/>
                                    </div>
                                </div>
                            )}
                            <Dialog fullScreen className={'get_notify_dialog'} open={this.state.rejected}
                                    container={() => document.getElementById('themeAppComponent')}
                                    onClose={() => {
                                        this.closeDialog()
                                    }}>
                                <GetNotified data={this.state.rejection_data} history={this.props.history}
                                             onClose={() => {
                                                 this.closeDialog()
                                             }}/>
                            </Dialog>
                            <Dialog fullScreen
                                    container={() => document.getElementById('themeAppComponent')}
                                    open={this.state.state_rejected} onClose={() => {
                                this.closeStateDialog()
                            }}>
                                <GetNotifiedState history={this.props.history} onClose={() => {
                                    this.closeStateDialog()
                                }}/>
                            </Dialog>
                        </div>
                    </div>
                </ErrorBoundary>
            </>
        )
    }
}

export const InsuranceWrapped = withRouter(Insurance)

const mapStateToProps = state => {
    return {
        unique_session_id: state.insurance.unique_session_id,
        current_section: state.insurance.current_section,
        current_section_index: state.insurance.current_section_index,
        current_subsection: state.insurance.current_subsection,
        coverage_types: state.insurance.coverage_types,
        restore_session: state.insurance.restore_session,
        subsections: state.insurance.subsections,
        subsections_questions: state.insurance.subsections_questions,
        sections: state.insurance.sections,
        answers: state.insurance.answers,
        full_quote: state.insurance.full_quote,
        industry_classes: state.insurance.industry_classes,
        fetched_classes: state.insurance.fetched_industry_classes,
        total_progress: state.insurance.total_progress,
        is_from_bulk: state.insurance.is_from_bulk,
        is_session_restored: state.insurance.is_session_restored,
        layout_built: state.insurance.layout_built,
        close_get_notified: state.insurance.close_get_notified,
        advisorCache: state.insurance.advisorCache,
        restoreCheckout: state.insurance.restoreCheckout
    }
}

const mapDispatchToProps = {
    setNexSectionButtonText,
    changeAnswers,
    updateSections,
    updateInsStoreKey,
    setRestoreSession,
    updateIndustryClasses,
    updateWCIndustryClasses,
    updateQuestionsAutofill,
    updateQuestionsClassStateBased
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(InsuranceWrapped))
