import React from "react";
import {Box, Button, Container, Dialog, Grid, Slide, Typography, withStyles} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {withTranslation} from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const styles = theme => ({
    header: {
        fontWeight: 900,
        fontSize: '24px',
        lineHeight: '29px',
        margin: '1rem 0 .5rem'
    },
    header_text: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        margin: '0 0 .5rem'
    },
})

class HNOArejection extends React.Component {

    render() {
        const {t, classes} = this.props;

        return (
            <Dialog open={this.props.is_open}
                    container={() => document.getElementById('themeAppComponent')}
                    fullWidth
                    maxWidth="xs"
                    onClose={() => this.props.onClose()}
                    TransitionComponent={Transition}
            >
                <Container>
                    <Box pt={1} pb={2}>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <Button variant="text" color="primaryDark"
                                        style={{marginLeft: '-16px', minWidth: '32px'}}
                                        onClick={() => {
                                            this.props.onClose()
                                        }}><Close/> {t('common.btn_cancel')}</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.header}>{t('modal.hnoa_rejection.title')}</Typography>
                                <Typography className={classes.header_text}>{t('modal.hnoa_rejection.txt')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained"
                                        size="large"
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                            this.props.onContinue()
                                        }}>{t('common.btn_continue')}</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Dialog>
        )
    }
}

export default withStyles(styles)(withTranslation()(HNOArejection))
