import React from 'react'
import { connect } from 'react-redux'
import { ArrowForward } from '@material-ui/icons'

const nextSectionText = (props) => {
  return (
    <>
      <span>Next</span><span className={'next_btn_txt'}>:&nbsp;{props.next_section_button_text}</span><ArrowForward/>
    </>
  )
}

const NextSectionButton = (props) => {
  /** Important Note: Use validateFormMethod prop for class
   components and validateForm prop for functional hooks

   Class Components: prop should pass arrow function
   Function Hooks: props should just pass helper function name
   */

  return (
    <button
      type="submit"
      disabled={props.nextDisabled}
      onClick={props.validateForm ? (e) => props.validateForm(e) : props.validateFormMethod}
      className={`a_btn a_btn_action_1 ${props.className}`}>{nextSectionText(props)}</button>
  )
}

function mapStateToProps (state) {
  return { next_section_button_text: state.insurance.next_section_button_text }
}

export default connect(mapStateToProps)(NextSectionButton)
