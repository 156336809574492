import React from "react";
import {connect} from "react-redux";
import {changeAnswers, updateAdditionalInsureds as updatebusinessadditionalinsuredlist} from "../../../../store/insurance/actions";
import Question from "../../Question";
import MultipleForms from "./MultipleForms";
import {withTranslation} from "react-i18next";
import {AutofilledBadge} from "../AutofilledBadge";
import {FormControl, Grid} from "@material-ui/core";
//scss
import '../../../../scss/q_custom.scss'

class MultipleAddInsuredQuestionSet extends MultipleForms {

    componentDidMount() {
        super.componentDidMount();
       if (this.props[this.getDName(this.props.qid)].length === 0) {
            this.onChange(true)
        }

          if (this.props.answers['business.additional_insured_is_selected'] === 'Yes') {
             let updated = [{
                 first_name: '',
             }]
             this.props[this.getAName(this.props.qid)](updated)
         }
         if (this.props.answers['business.additional_insured_is_selected'] === 'Yes' && this.props.business_additional_insured[0] !== undefined) {
             let updated
             if (this.props.answers['contact.first_name'] === this.props.business_additional_insured[0].first_name) {
                 updated = [{
                     first_name: '',
                 }]
             } else {
                 updated = [{
                     first_name: this.props.business_additional_insured[0].first_name,
                 }]
             }

             this.props[this.getAName(this.props.qid)](updated)
         }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.answers['business.additional_insured_is_selected'] === 'Yes' && prevProps.answers['business.additional_insured_is_selected'] !== 'Yes') {
            let updated = [{
                first_name: '',
            }]
            this.props[this.getAName(this.props.qid)](updated)
        }
    }

    clearEmptyForms() {
        return true
    }

    render() {
        return (
            <>
                {(this.props.answers['business.additional_insured_is_selected'] === 'Yes')
                    && <FormControl fullWidth={this.props.fullWidth} error={this.hasError()} className={'owner_questions'}>
                        <Grid container spacing={2} justifyContent="space-between" wrap="nowrap">
                            <Grid item><p className={'owns_title'}>{this.props.question}&nbsp;{this.props.tooltip}</p></Grid>
                            {this.props.autofilled && <Grid item><AutofilledBadge/></Grid>}
                        </Grid>
                        <Grid item className={'is_owner_question_item'}>
                            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                                <Grid item xs={12}>
                                    {this.props[this.getDName(this.props.qid)].map((f, index) => {
                                        return (
                                            <Grid container py={1} key={index}>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        {this.state.questions.filter(i => i.qid !== "business.additional_insured_is_selected").map((q) => {
                                                            return (
                                                                <Grid item xs={12} key={q.qid} className={'own_item'}>
                                                                    <Question
                                                                        fullWidth
                                                                        {...q}
                                                                        options={q.options}
                                                                        required={q.required}
                                                                        type={q.question_type}
                                                                        question={q.question_text}
                                                                        field_length={q.max_length}
                                                                        answer={this.props.answers[this.getKey(q.qid, index)]}
                                                                        error={!!this.props.error?.[this.getKey(q.qid, index)]}
                                                                        helperText={this.props.error?.[this.getKey(q.qid, index)]}
                                                                        onChange={(id, value) => this.updateAnswer(q.qid, index, value)}
                                                                        onChangeLocation={data => this.updateLocation(index, data)}
                                                                    />
                                                                </Grid>)
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.hasError() && <div>
                            {Object.keys(this.props.error).map((key, idx) => (
                                <p key={idx} className="error_txt error_txt_md">{this.props.error[key]}</p>))}
                        </div>}
                    </FormControl>}
            </>
        )
    }
}

const
    mapStateToProps = state => ({
        data_map: state.insurance.data_map,
        answers: state.insurance.answers,
        business_additional_insured: state.insurance.business_additional_insured,
        business_additional_insured_template: state.insurance.business_additional_insured_template,
        subsections_questions: state.insurance.subsections_questions
    })

const
    mapDispatchToProps = {
        changeAnswers,
        updatebusinessadditionalinsuredlist
    }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MultipleAddInsuredQuestionSet))