const getPolicyType = (type) => {

    switch (type) {
        case 'BOP':
            return {
                longName: 'Business Owner\'s Policy'
            }
        case 'WC':
            return {
                longName: 'Workers\' Compensation'
            }
        case 'PL':
            return {
                longName: 'Professional Liability'
            }
        case 'GL':
            return {
                longName: 'General Liability'
            }
        case 'CYBER':
            return {
                longName: 'Cyber Insurance'
            }
        default:
            return {
                longName: 'Policy'
            }
    }
}

export default getPolicyType