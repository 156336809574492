
enum Carrier {
    ACUITY = 'Acuity',
    AMTRUST = 'AmTrust',
    COTERIE = 'Coterie',
    CNA = 'CNA',
    EMPLOYERS = 'Employers',
    MARKEL = 'Markel',
    COALITION = 'Coalition',
    CRUMANDFORESTER = 'Crum & Forster',
    PIE = 'Pie',
    TIMBLE = 'Thimble',
    WINGMAN = 'Wingman'
}

const GetLeadUnderwriter = (quotes): any =>{
    let underwriters = []
    quotes.forEach(quote=>{
        // @ts-ignore
        underwriters.push(Carrier[quote.source])
    })
    // @ts-ignore
    return [...new Set(underwriters)].join(';')
}

export {GetLeadUnderwriter as getLeadUnderwriter}