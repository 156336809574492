import React, {useEffect, useState} from 'react';
import axios from "axios";
import moment from "moment";
import {connect} from "react-redux";
import {sortBy} from "lodash";
import LoadingButton from '../../components/LoadingButton'
import {setPolicies} from "../../store/portal/policies/actions";
import withWidth from "@material-ui/core/withWidth";
import UpcomingBills from "../../components/portal/UpcomingBills";
import {Link} from "react-router-dom";
import {ArrowLeft} from "../../components/icons/ArrowLeft";
import PaymentHistoryItem from "../../components/portal/payment/PaymentHistoryItem";
import PaymentMethods from "../../components/portal/sidebar/PaymentMethods";
import policyType from "../../helpers/policyType"
import '../../scss/v2/portal/_payment.scss'

const PaymentCenter = (props) => {
    const [userPaymentMethods, setUserPaymentMethods] = useState([])
    const [upcomingBills, setUpcomingBills] = useState([])

    const checkActiveCards = (month, year) => {
        let newDate = new Date()
        let currentDate = moment(newDate, "MM-YY")
        let cardExpDate = moment(`${month}-${year}`, "MM-YY")
        return cardExpDate.diff(currentDate, 'days') < 0
    }

    useEffect(() => {
        const getUserPaymentMethods = () => {
            axios.get('/api/get-saved-payments-methods')
                .then(response => {
                    let paymentMethods = []
                    if(response.data){
                      response.data.sources.data.forEach(item => {
                        let method = {
                          brand: item.brand,
                          last4: item.last4,
                          isActive: checkActiveCards(item.exp_month, item.exp_year)
                        }
                        paymentMethods.push(method)
                      })
                    }
                    setUserPaymentMethods(paymentMethods)
                }).catch(e=>{
                    setUserPaymentMethods([])
                    throw new Error(`Something went wrong while getting saved payments methods. ${e.message}`)
            })
        }
        setUserPaymentMethods(getUserPaymentMethods)
    }, [])

  const checkFailedInstallment = (installment) => {
    let due_date = moment(installment.due_date)
    let today = moment(new Date())

    return today.diff(due_date, 'days') > 1 && installment.paid_date === null
  }

    useEffect(() => {
        const getUpcomingBills = () => {
            let upcoming = []

            props.policiesList.forEach(itm => {
                let upcoming_installments = sortBy(itm.installments, 'payment_installment_id').filter(itm => !itm.paid_date)
                if (upcoming_installments.length > 0) {
                    upcoming_installments[0].policyType = policyType(itm.type).longName
                    upcoming_installments[0].policyNumber = itm.quote.policyNumber || itm.quote.policy_number
                    upcoming_installments[0].policy = itm
                    upcoming_installments[0].paymentEdit = false
                    upcoming_installments[0].failed = checkFailedInstallment(upcoming_installments[0])
                    upcoming.push(upcoming_installments[0])
                }
            })
            return upcoming
        }
        setUpcomingBills(getUpcomingBills)
    }, [props.policiesLoading, props.policiesList])

    return (

        <div className="portal_page portal_page_back portal_payment_page">
            <div className="cnt_full portal_back_cnt">
                <Link to={'/portal/home'}
                      className={'portal_back_btn'}><ArrowLeft/><span>Back to Portal</span></Link>
            </div>

            <div className="cnt_full">
                <div className="cnt head_cnt">
                    <h1 className={'head_ttl'}>Payment Center</h1>
                </div>
            </div>
            <div className="cnt_full">
                <div className="cnt">
                    {props.policiesLoading ? <LoadingButton/> : <UpcomingBills upcomingBills={upcomingBills}/>}
                </div>

                <div className="cnt cnt_payment_center_sidebar">
                    <div className="content_column">
                        {props.policiesLoading ? <LoadingButton/> : props.policiesList.map((item, index) => {
                            return (<PaymentHistoryItem {...item} key={index}/>)
                        })}
                    </div>
                    <div className="sidebar_column">
                        {userPaymentMethods ?
                          userPaymentMethods.length !== 0?<PaymentMethods paymentMethods={userPaymentMethods}/> : null
                          : <LoadingButton/>}
                    </div>
                </div>
            </div>
        </div>)

}
const mapStateToProps = state => {
    return {
        policiesList: state.policies.policiesList,
        policiesLoading: state.policies.policiesLoading
    }
}
const mapDispatchToProps = {
    setPolicies
}
export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(PaymentCenter));