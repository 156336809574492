import React from 'react'
import { FormControl } from '@material-ui/core'
import clsx from 'clsx'

export default class XsCheckbox extends React.Component {

  state = {
    checked: false
  }

  componentDidMount () {
    if (this.props.answer === 'Yes') this.setState({ checked: true })
  }

  onBooleanChange (value) {
    let val = value === true ? 'Yes' : 'No'
    this.setState({ checked: !this.state.checked })
    if (this.props.onChange) this.props.onChange(this.props.id, val)
  }

  render () {
    const questionClass = `q__${this.props.qid.split('.').join('_')}`

    let classes = clsx(questionClass, {
      'xsCheckbox': true, 'checked': this.state.checked
    })

    return (<FormControl fullWidth={this.props.fullWidth} error={this.props.error}>
        <label htmlFor={'switch-' + this.props.id} className={classes}>
                                <span className="checkbox_blk">
                                    <input type="checkbox" id={'switch-' + this.props.id}
                                           onChange={(e) => this.onBooleanChange(e.target.checked)}
                                           checked={this.state.checked}/>
                                </span>
          <p>{this.props.question}&nbsp;{this.props.tooltip}</p>

        </label>
      { this.props.error && <p className='error_txt error_txt_md'>{this.props.helperText}</p> }
    </FormControl>)
  }
}