import React, {useEffect, useState} from 'react'
// import DiyaRounded from '../icons/new/DiyaRounded'
import {Warning as WarningIcon} from '../icons/new/Warning'
import {withTranslation} from 'react-i18next'
import {Dialog, DialogContent,
    useTheme} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Question from '../form-components/Question'
import LoadingButton from '../LoadingButton'
import {ModalTransition} from '../../common/ModalTransition'

import '../../scss/v2/modals/_home_based_business.scss'

/*
*
*
*  This modal shows when customer home-based business answer NOT equal propertySearch home-based business answer
*
*
* */

const HomeBasedBusinessModal = ({open, onHomeBusinessSet, onClose, advisorHomeBusiness, businessName}:
                                    {
                                        open: boolean,
                                        onHomeBusinessSet: (value: any) => void,
                                        onClose: () => void,
                                        advisorHomeBusiness: string
                                        businessName: string
                                    }) => {


    const [homeBaseBusinessValue, setHomeBaseBusinessValue] = useState(null)
    const [title, setTitle] = useState('')
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (homeBaseBusinessValue !== null) {
            setLoading(true)
            onHomeBusinessSet(homeBaseBusinessValue)
            onClose()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homeBaseBusinessValue])

    useEffect(() => {
        if (advisorHomeBusiness === 'No') {
            setTitle(`Heads up! ${businessName} looks like a non-residential property.`)
        } else {
            setTitle(`Heads up! ${businessName} looks like a residential property.`)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advisorHomeBusiness])


    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    return (
        <Dialog
            className={'modal__wrapper home_base_business_modal__wrapper'}
            fullScreen={fullScreen}
            open={open}
            fullWidth
            maxWidth="sm"
            scroll="body"
            TransitionComponent={ModalTransition}>

            <DialogContent className={'modal_body'}>
                <div className={'home_based_business_modal_cnt'}>
                    <div className={'modal_warning_icon_cnt'}>
                        <WarningIcon className={'warning_icon'}/>
                    </div>
                    <p className={'modal_ttl'}>{title}</p>
                    <p className={'home_based_txt'}>Please confirm your answer below to proceed.</p>

                    {loading ? <LoadingButton/> : <Question
                        fullWidth
                        id="business.location.home"
                        name="business.location.home"
                        question={''}
                        type="boolean"
                        options={[
                            {
                                'id': 1,
                                'text': 'Yes, my business is home-based',
                                'value': 'Yes'
                            },
                            {
                                'id': 2,
                                'text': 'No, my business is not home-based',
                                'value': 'No'
                            }
                        ]}
                        answer={homeBaseBusinessValue}
                        onChange={(_, value) => setHomeBaseBusinessValue(value)}
                    />}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default withTranslation()(HomeBasedBusinessModal)