import { datadogRum } from '@datadog/browser-rum'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect, useStore } from 'react-redux'
import { includes, some } from 'lodash'
import { withTranslation } from 'react-i18next'
import { updateInsStoreKey, updateProgress } from '../../store/insurance/actions'
import ChangeHighlight from 'react-change-highlight'
import { getLimitValue } from '../../helpers/display'
import { Edit } from '../icons/Edit'
import { currency, formatCurrency } from '../../helpers/currency'
import clsx from 'clsx'
import objReducer from '../../helpers/objReducer'

const SectionDetailInfoItem = (props) => {

  const { t } = props
  const store = useStore()
  const insurance_store = store.getState().insurance
  const {
    answers,
    sections,
    subsections,
    subsections_data,
    current_subsection,
    subsections_questions,
    industry_classes,
    wc_locations,
    is_quote_rejected
  } = insurance_store

  const [fields, setFields] = useState([])
  const [total, setTotal] = useState(0)

  let term_premium = 0

  if (answers?.selected_quotes.length > 0) {
    answers.selected_quotes.forEach(q => {
      if (q?.term_premium) term_premium += q?.term_premium
    })
  }

  useEffect(() => {
    updateFieldsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_subsection, term_premium, total])

  useEffect(() => {
    if(answers['coverage.types'][0] === 'CYBER') {
      if (props?.estimatedPremium?.hasOwnProperty("term_payment_cents")) {
        setTotal(props.estimatedPremium.term_payment_cents / 100)
      } else {
        setTotal(term_premium)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.estimatedPremium, current_subsection, term_premium, total])

  useEffect(() => {
    if (props.current_section < 4) {
      props.updateInsStoreKey('sidebarTotalPremiumAmount', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.current_section])

  useEffect(() => {
    document.addEventListener('total-changed', onTotalChanged)

    return () => {
      document.removeEventListener('total-changed', onTotalChanged)
    }

  })

  useEffect(() => {
    document.addEventListener('quotes-changed', onQuoteChanged)

    return () => {
      document.removeEventListener('quotes-changed', onQuoteChanged)
    }
  })

  const updateFieldsData = () => {
    let fields = []
    subsections.forEach((s, idx) => {
      //for each subsection in current section, if it is complete
      if (props.section.section_index === s.section_index && isSubSectionComplete(s, idx)) {
        let fields_data = getCompletedSectionData(props.section, s)
        fields = fields.concat(fields_data)
      }
    })
    if (fields.length > 0) {
      setFields(fields)
    }
  }

  const isSubSectionComplete = (subsection, idx) => {
    return idx < current_subsection
  }

  const onQuoteChanged = (event) => {
    setTimeout(() => {
      updateFieldsData()
    }, 0)
  }

  const onTotalChanged = (event) => {
    setTotal(event?.detail || getTotal())

    setTimeout(() => {
      updateFieldsData()
    }, 0)
  }

  const getCompletedSectionData = (section, subsection) => {
    let fields = getCustomFields(subsection.name)

    subsections_questions.forEach(q => {
      if (section.section_index === q.section && subsection.subsection_index === q.subsection) {
        if (q.sidebar_field_name && q.sidebar_field_name !== 'TRIA covered'
          && q.sidebar_field_name !== 'Cyber included'
          && !q.qid.startsWith('cyber.coalition')
          && q.sidebar_field_name !== 'PL included') {
          if (!some(fields, { name: q.sidebar_field_name })) {
            fields.push({
              name: q.sidebar_field_name + ':',
              value: formatValue(answers[q.qid], q.question_type)
            })
          }
        }
      }
    })
    if (subsection.name === 'Select Your Policy Type') {
      fields.push({
        name: 'NAICS Code:',
        value: answers['b2z_class_code_id']?.split('-')[0]
      })
    }

    return fields
  }

  const getOCName = (l) => {
    switch (l) {
      case 'cyber':
        return 'Cyber Limit'
      case 'tria':
        return 'TRIA Limit'
      case 'mpl':
        return 'Professional Liability Limit'
      case 'hnoa': // TODO: update when we are ready to include hnoa limits
        return  // "Hired Auto And Non Owned Auto Coverage Limit"
      default:
        return l
    }
  }
  const getCyberOC = () => {
    const cyberOCq = subsections_questions.filter(q => q.qid.startsWith('cyber.coalition') && q.sidebar_field_name)
    let optionalCoverages = []
    cyberOCq.forEach(item => {
      optionalCoverages.push({ name: `${item.sidebar_field_name} :`, value: item.default['value'] })
    })
    return optionalCoverages
  }
  const getOCIncluded = (line_of_business) => {
    switch (line_of_business) {
      case 'BOP':
        return [
          { name: 'TRIA Included:', value: answers['policy.tria_accepted'] },
          { name: 'Cyber Included:', value: answers['cyber.coverage.is_selected'] },
          { name: 'PL Included:', value: answers['mpl.coverages.is_selected'] },
          { name: 'HNOA Included:', value: answers['hnoa.coverage.is_selected'] }
        ]
      case 'GL':
        return [
          { name: 'TRIA Included:', value: answers['policy.tria_accepted'] },
          { name: 'Cyber Included:', value: answers['cyber.coverage.is_selected'] },
          { name: 'PL Included:', value: answers['mpl.coverages.is_selected'] },
          { name: 'HNOA Included:', value: answers['hnoa.coverage.is_selected'] }
        ]
      case 'CYBER':
        return getCyberOC()
      case 'WC':
        return []
      default:
        return []
    }
  }
  const getPolicyName = (line_of_business) => {
    switch (line_of_business) {
      case 'BOP':
        return 'Business Owner\'s Policy'
      case 'PL':
        return 'Professional Liability Policy'
      case 'WC':
        return 'Workers\' Compensation Policy'
      case 'GL':
        return 'General Liability Policy'
      case 'CYBER':
        return 'Cyber Policy'
      default:
        return line_of_business
    }
  }
  const getQuoteLimits = (quote) => {
    try {
      let limits = []

      Object.keys(quote.limits).forEach(k => {
        if (k !== 'employee_limit') {
          let l = quote.limits[k]
          limits.push({
            name: titleCase(l.display_name) + ':',
            value: getLimitValue(l)
          })
        }

      })

      Object.keys(quote.optional_coverages).forEach(k => {
        let l = quote.optional_coverages[k]

        if (!l.qid && l.coverage_name !== 'hnoa') {
          limits.push({
            name: getOCName(l.coverage_name) + ':',
            value: l.coverage_name === 'mpl'
              ? formatCurrency(l.occurrence_limit)
              : formatCurrency(l.annual_aggregate_limit)
          })
        }
      })
      limits = [...limits, ...getOCIncluded(quote.line_of_business)]
      return [{
        name: getPolicyName(quote.line_of_business),
        group: true,
        editable: true,
        value: limits
      }]
    } catch (e) {
      datadogRum.addError(`Failed to retrieve quote limits. ${e}`)
      return []
    }
  }

  const getTotal = () => {
    /**
     * Calculates aggregate premium total - optional coverages
     * @type {null|number|*}
     */

    const selected_ocs = []
    let oc_premium_total = 0
    let oc_total = 0

    if (answers.selected_quotes.filter(q => q.line_of_business === 'BOP').length > 0) {
      try {
        const bop = answers.selected_quotes.filter(q => q.line_of_business === 'BOP')[0]
        const oc = bop?.optional_coverages.filter(c => c.coverage_name !== 'tria')
        oc_premium_total = objReducer(oc, 'term_premium')

        if (answers['cyber.coverage.is_selected'] === 'Yes') selected_ocs.push(bop?.optional_coverages.filter(c => c.coverage_name === 'cyber'))
        if (answers['mpl.coverages.is_selected'] === 'Yes') selected_ocs.push(bop?.optional_coverages.filter(c => c.coverage_name === 'mpl'))
        if (answers['hnoa.coverage.is_selected'] === 'Yes') selected_ocs.push(bop?.optional_coverages.filter(c => c.coverage_name === 'hnoa'))
        selected_ocs.forEach(el => {
          oc_total += el[0].term_premium
        })
      } catch (e) {
        throw new Error(`Something went wrong while getting BOP premium. ${e}`)
      }
    }
    setTotal(currency(term_premium - oc_premium_total + oc_total))
    // If the coverage type is CYBER, then we show the premium yearly only
    props.updateInsStoreKey(
      'sidebarTotalPremiumAmount',
      answers['coverage.types'][0] === 'CYBER' && props.estimatedPremium === null
        ? formatCurrency(total, true) + '/year'
        : formatCurrency(total, true) + '/month'
    );
  }

  const getCustomFields = (subsection) => {
    let fields = []
    let STEStr = answers['business.location.home'] === 'Yes' ? '' : 'STE '

    if (subsection === 'Business Name & Address') {
      let line1 = answers['business.street_address']
      if (answers['business.street_address2']) line1 += `, ${STEStr}${answers['business.street_address2']}`

      let line2 = answers['business.city']
      line2 += ', ' + answers['business.state']
      line2 += ' ' + answers['business.zip_code']

      fields.push({
        name: null,
        value: line1
      })
      fields.push({
        name: null,
        value: line2
      })
      if (answers['business.location.home'] === 'Yes') {
        fields.push({
          name: null,
          value: 'Home-Based Business'
        })
      }

    } else if (subsection === 'Industry class') {
      if (answers['coverage.types'].length) {
        let ind_class = industry_classes.filter(c => c.id === answers['b2z_class_code_id'])
        let industryValue = ind_class.length > 0 ? ind_class[0].text : answers['b2z_class_code_description']
        fields.push({
          name: t('common.industry') + ':',
          value: industryValue,
          classStyle: 'blank_line'
        })
      }
    } else if (subsection === 'Select Your Policy Type') {
      fields.push({
        name: t('common.policy_duration') + ':',
        value: t('common.twelve_month')
      })
    } else if (subsection === 'Business locations') {
      let locations = []
      let payroll = 0
      let employees = 0

      wc_locations.forEach(l => {
        let addressLine1 = ''
        let addressLine2 = ''
        addressLine1 += l.street_address
        if (l.street_address2) addressLine1 += ', ' + l.street_address2
        addressLine2 += l.city
        addressLine2 += ', ' + l.state
        addressLine2 += ' ' + l.zip_code

        locations.push(addressLine1)
        locations.push(addressLine2)
        if (l.rate_classes) {
          l.rate_classes.forEach(rt => {
            payroll += parseFloat(rt.payroll_amount)
          })
        }
        employees = l.full_time_employees
      })
      fields.push({
        name: t('common.primary_location') + ':',
        value: null,
        classStyle: 'blank_line'
      })
      fields.push({
        name: null,
        value: locations[0]
      })
      fields.push({
        name: null,
        value: locations[1]
      })
      fields.push({
        name: t('common.employees') + ':',
        value: employees
      })
      fields.push({
        name: t('common.payroll') + ':',
        value: formatCurrency(payroll)
      })

    } else if (subsection === 'Owners and Officers') {
      if (includes(answers['coverage.types'], 'WC')) {
        let owners = []
        wc_locations.forEach(l => {
          let l_owners = l?.owners || []
          l_owners.forEach(own => {
            let person = own.first_name + ' ' + own.last_name
            if (!includes(owners, person)) owners.push(person)
          })
        })

        fields.push({
          name: t('common.owners_officers') + ':',
          value: owners.join('\n')
        })
      }
    } else if (subsection === 'Select your rate' && Array.isArray(answers['selected_quotes'])) {
      getTotal()
      let amount = total
      let limits = []

      answers['selected_quotes'].forEach(q => {
        amount += formatCurrency(amount)
        limits = limits.concat(getQuoteLimits(q))
      })
      return limits
    }
    return fields
  }

  const formatValue = (value, format) => {
    switch (format.toLowerCase()) {
      case 'currency':
        return formatCurrency(value)
      case 'currencydropdown':
        return formatCurrency(value)
      case 'date':
        return moment(value).format('MM/DD/YYYY')
      case 'datetime':
        return moment(value).format('dddd MM/DD [at] h:mm A')
      case 'boolean':
        return value ? t('common.yes') : t('common.no')
      default:
        return value?.toString()
    }
  }

  const goToSection = (section_index) => {
    if (is_quote_rejected) {
      props.updateInsStoreKey('close_get_notified', true)
    }
    let currentSection = sections.find(item => item.section_index === section_index)
    let currentSectionIndex = currentSection.section_index
    let toSection = subsections_data.find(item => item.section_index === currentSectionIndex)

    props.updateProgress({
      current_section: toSection.section_index,
      current_subsection: toSection.subsection_index
    })
    props.history.replace(`/get-insurance${toSection.path}`)
  }

  const goToSubSection = (section_index, subsection_index) => {
    if (is_quote_rejected) {
      props.updateInsStoreKey('close_get_notified', true)
    }
    let toSection = subsections_data.find(item => item.section_index === section_index && item.subsection_index === subsection_index)
    props.updateProgress({
      current_section: section_index,
      current_subsection: subsection_index
    })
    props.history.replace(`/get-insurance${toSection.path}`)
  }
  const titleCase = (str) => {
    let splitStr = str.toLowerCase().split(' ')
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
  }

  return fields.length > 0
    ? (<div className="fields">
      {props.section.name !== 'Customize Rate' && <div className="title">
        <div className={'title_head'}>
          <p>{props.section.name === 'Getting Started' ? answers['business.name'] : props.section.name}</p>
          {props.quote_status !== 'Qualified' && !props.session_complete
            && props.current_section !== props.section.section_index &&
            <div className={'edit_icon'}
                 onClick={() => goToSection(props.section_index)}
            ><Edit/></div>}
        </div>
      </div>}
      {fields.map((f, i) => {
        const value_class = clsx({
          'value': true,
          'bold': f?.use_as_title,
          'blank_line': f?.classStyle
        })

        return f?.group
          ? (
            <div className="row" key={i}>
              <div className="group">
                <div className="group-name title">{f.name && (
                  <div className="name title_head">{f.name}
                    {props.quote_status !== 'Qualified' && !props.session_complete
                      && f.editable && props.subsection_index !== 10 && props.subsection_index !== 0 &&
                      <div className={'edit_icon'}
                           onClick={() => goToSubSection(4, 10)}><Edit/></div>}
                  </div>)}</div>
                <div className="group-data">
                  {f.value.map((gf, idx) => (
                    <ChangeHighlight key={idx}>
                      {gf.name && (<div className="name">{gf.name}</div>)}
                      <div className="value">{gf.value}</div>
                    </ChangeHighlight>
                  ))}
                </div>
              </div>
            </div>
          )
          : (<div className={`row ${value_class}`} key={i}>
            {f?.name && (<div className="name">{f.name}</div>)}
            <div className={value_class}>{f.value}</div>
          </div>)
      })}
    </div>) : null
}

/**
 * IMPORTANT: DO NOT REMOVE mapStateToProps. This component indirectly monitors change to props.answers and is required
 * for dynamic update of some fields such as selected_quotes.  This is especially critical on the checkout page.
 * @param state
 * @returns {{answers}} **Required**
 */
const mapStateToProps = state => {
  return {
    estimatedPremium: state.insurance.estimatedPremium,
    current_section: state.insurance.current_section,
    answers: state.insurance.answers
  }
}

const mapDispatchToProps = {
  updateInsStoreKey,
  updateProgress
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SectionDetailInfoItem))
