import React from "react";
import {routes} from "../routes/web";
import Header from "../layouts/page/Header";
import Footer from "../layouts/page/Footer";
import {Route, Switch, useHistory} from "react-router-dom";
import NotFoundError from "./errors/NotFoundError";

export const Pages = () => {

    let history = useHistory();

    let auth_routes = [
        '/sign-in',
        '/finalize-registration'
    ].indexOf(history.location.pathname) !== -1
    return (
        <>
            <Header/>
            <div className="content" style={auth_routes ? {background: '#F6F8FA'} : null}>
                <Switch>
                    {routes.map((route, i) => (
                            <Route key={i} path={route.path} exact={true}>
                                <route.component history={history}/>
                            </Route>
                        )
                    )}
                    <Route component={NotFoundError}/>
                </Switch>
            </div>
            <Footer style={auth_routes ? {background: '#F5F8FA'} : null}/>
        </>
    )
}
