import React from "react"
import axios from "axios";
import {Grid} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {withRouter} from 'react-router-dom'
//scss
import "../../scss/_marketing_unsubscribe.scss"

class MarketingUnsubscribe extends React.Component {

    state = {
        user: null
    }

    constructor(props) {
        super(props);

        if (this.props.history.location.search) this.state.user = this.parseURLParam();
    }

    componentDidMount() {
        if (this.state.user) {
            this.sendRequest()
        }
    }

    parseURLParam() {
        let user = new URLSearchParams(this.props.history.location.search)
        user = user.get('e')

        return user ? user : ''
    }

    sendRequest() {
        axios.post("/api/unsubscribe", {
            email: this.state.user
        })
        .catch(e => {
            throw new Error(`Can't send user to unsubscribe. ${e}`)
        })
    }

    render() {
        const {t} = this.props

        return (
            <Grid container spacing={2} justify="center" alignContent="center">
                <Grid item xs={12}>
                    <div className="marketing-unsubscribe">
                        <div className="title">{t('page.marketing_unsubscribe.title')}</div>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default withRouter((withTranslation()(MarketingUnsubscribe)))
