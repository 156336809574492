import moment from "moment";

const industryYearsCalc = (questions) => {
    /**
     * Function takes in a questions object and prefill industry years
     * when business start date is provided.
     * 1. Filter for the following questions and store answers in variables:
     *      a. business.years_in_industry
     *      b. business.start_date
     * 2. Check if the following conditions are met:
     *      a. is business.start_date provided
     *      b. if provided, is date in the past
     *      c. if all the above are true, business.years_in_industry can be calculated
     * 3. If condition test above passed:
     *      a. store today's date in variable
     *      b. calculate difference of today - business.start_date
     * 4. Update state of questions object
     */

    let businessStartDate = questions['business.start_date'],
        yearsInIndustry   = questions['business.years_in_industry'],
        today             = moment()

    if(businessStartDate !== "") {
        businessStartDate = moment(businessStartDate)

        if (businessStartDate < today && businessStartDate.isValid()) {
            yearsInIndustry = Math.round(today.diff(businessStartDate, 'years', true))
        }
    } else {
        yearsInIndustry = 0
    }

    return yearsInIndustry
}

export default industryYearsCalc