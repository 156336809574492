import React, {Component} from 'react';
import {ChevronRight} from "@material-ui/icons";
import withWidth from "@material-ui/core/withWidth";

class InsuranceLayout extends Component {

    state = {
        isOpenSidebarNav: false
    }

    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);

    }

    onSidebarHandler(val) {
        this.setState({isOpenSidebarNav: !val})
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({isOpenSidebarNav: false})
        }
    }

    render() {
        return (
            <div className={'insurance_layout'}>
                <div ref={this.setWrapperRef}
                     className={`sections-indicator-container ${this.state.isOpenSidebarNav && 'in'}`}
                     style={{marginTop: `-13px`}}>
                    {this.props.width === 'sm' || this.props.width === 'xs' ?
                        <div onClick={() => this.onSidebarHandler(this.state.isOpenSidebarNav)}
                             className={'sidebar_nav_btn'}><ChevronRight fontSize={'large'}/></div>
                        : null}
                    {this.props.sidebar}
                </div>
                <div className="sections-container">
                    {this.props.sections}
                </div>
            </div>
        );
    }
}

export default withWidth()(InsuranceLayout);