export interface NodeElement {
    value: string
    next: null | NodeElement
    previous: null | NodeElement
}


function createNode(value: string): NodeElement {
    return {
        value: value,
        next: null,
        previous: null
    }
}

export class DoublyLinkedList {
    public head: null | NodeElement
    public tail: null | NodeElement
    public length: number

    constructor() {
        this.head = null
        this.tail = null
        this.length = 0
    }

    insert(value: string) {
        this.length++
        let newNode = createNode(value)

        if (this.tail) {
            // means list is not empty
            this.tail.next = newNode
            newNode.previous = this.tail
            this.tail = newNode

            return newNode
        }

        this.head = this.tail = newNode
        return newNode
    }

    getNthNode(nodeIndex: number): NodeElement | null {
        // Returns nth node in list based on 0-index value provided
        let current = this.head
        let idx = 0

        while (idx < nodeIndex && current) {
            idx++
            current = current.next
        }
        return current
    }
}