import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import AnimatedText from "./AnimatedText";


//scss
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './../scss/loading.scss'
import {withTranslation} from "react-i18next";
import {Grid, Box} from "@material-ui/core";


const B2ZLoadingEmailValidation = () => {
    // states
    const [timing, setTiming] = useState(0)

    useEffect(() => {
        let interval = setInterval(
            () => timing >= 3 ? setTiming(0) : setTiming(timing + 1), 1000)

        return () => {
            clearInterval(interval)
        }
    }, [timing])


    const getAnimationStage = () => {
        let stage;
        switch (timing) {
            case 1:
                stage = 'stage-1'
                break
            case 2:
                stage = 'stage-2'
                break
            case 3:
                stage = 'stage-3'
                break
            default:
                stage = 'stage-0'
        }
        return "logo " + stage
    }

    return (
        <>
            <Box pt={4} textAlign="left" className="b2z-loading">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <div className={getAnimationStage()}>
                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none"  style={{marginRight: "20px"}}
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                    <path id="layer-1"
                                          d="M63.9849 51.4163V12.5918C63.9849 5.64248 58.3504 0.00891113 51.4 0.00891113C44.4496 0.00891113 38.8152 5.64248 38.8152 12.5918V51.4163C38.8152 58.3657 44.4496 63.9992 51.4 63.9992C58.3504 63.9992 63.9849 58.3657 63.9849 51.4163Z"
                                          fill="#162252"/>
                                    <path id="layer-2"
                                          d="M32.8412 48.9435L60.2983 21.4905C65.213 16.5766 65.213 8.60948 60.2983 3.69554C55.3837 -1.2184 47.4154 -1.21839 42.5007 3.69554L15.0435 31.1486C10.1289 36.0625 10.1289 44.0296 15.0435 48.9435C19.9582 53.8575 27.9265 53.8575 32.8412 48.9435Z"
                                          fill="#003EFF"/>
                                    <path id="layer-3"
                                          d="M12.5855 25.1659L51.4158 25.1659C58.3662 25.1659 64.0006 19.5323 64.0006 12.583C64.0006 5.6336 58.3662 3.05176e-05 51.4158 3.05176e-05L12.5855 3.05176e-05C5.63504 3.05176e-05 0.000621796 5.6336 0.000621796 12.583C0.000621796 19.5323 5.63504 25.1659 12.5855 25.1659Z"
                                          fill="#00BFFF"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="64" height="64" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <AnimatedText
                                strings={['Validating email...']}
                                show_in={1000}
                                delay={4000}
                                show_out={1000}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    )

}

const mapStateToProps = state => {
    return {
        loading_cards: state.insurance.loading_cards,
    }
}

export default connect(mapStateToProps)(withTranslation()(B2ZLoadingEmailValidation))