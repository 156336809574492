import React from "react";
import {ArrowForward} from "@material-ui/icons";
import LoadingButton from "../../LoadingButton";
import {withTranslation} from 'react-i18next';
import Question from "../../form-components/Question";
import { Grid} from "@material-ui/core";
import SectionHead from '../SectionHead';

class ResetPassword extends React.Component {

    render() {
        const {t} = this.props

        return (
          <div className={'reset_pass_section'}>

            <form autoComplete="off" className={'section_container'} onSubmit={(e) => this.props.validate(e)}>


                    <SectionHead
                        title={t('auth.pass_reset')}
                        txt={''}
                    />
              <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={7} lg={5} className={'sign_in_container'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Question fullWidth
                                          type="password"
                                          question={t('auth.temp_pass')}
                                          answer={this.props.temp_pass}
                                          error={!!this.props.errors?.temp_pass}
                                          helperText={this.props.errors?.temp_pass}
                                          onChange={(id, value) => this.props.onTempPasswordChange(value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Question fullWidth
                                          type="password"
                                          question={t('auth.new_pass')}
                                          tooltip={t('auth.new_pass_tooltip')}
                                          answer={this.props.pass}
                                          error={!!this.props.errors?.pass}
                                          helperText={this.props.errors?.pass}
                                          onChange={(id, value) => this.props.onPasswordChange(value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Question fullWidth
                                          type="password"
                                          question={t('auth.confirm_pass')}
                                          tooltip={t('auth.confirm_pass_tooltip')}
                                          answer={this.props.pass_repeat}
                                          error={!!this.props.errors?.pass_repeat}
                                          helperText={this.props.errors?.pass_repeat}
                                          onChange={(id, value) => this.props.onPasswordRepeatChange(value)}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <div className="resend-temp-pass">*/}
                            {/*        <Button type="button"*/}
                            {/*                variant="text"*/}
                            {/*                color="primaryDark"*/}
                            {/*                style={{*/}
                            {/*                    marginLeft: '-8px',*/}
                            {/*                    marginTop: '8px',*/}
                            {/*                }}*/}
                            {/*                onClick={() => {*/}
                            {/*                    this.props.resendTempPasswordEmail()*/}
                            {/*                }}*/}
                            {/*        >Reset password*/}
                            {/*        </Button>*/}
                            {/*    </div>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                {this.props.processing ? (<LoadingButton/>)
                                    : (

                                        <button type="submit"
                                                className={'a_btn a_btn_action_1 a_btn_reset_pass'}
                                                onClick={(e) => {
                                                    this.props.validate(e)
                                                }}>
                                            {t('common.btn_continue')}
                                            <ArrowForward style={{marginLeft: '10px'}}/>
                                        </button>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </form>
          </div>
        )
    }
}

export default withTranslation()(ResetPassword)
