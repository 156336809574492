import React, {Component} from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { isEqual } from 'lodash'
import { withTranslation } from 'react-i18next'
import { formatCurrency } from '../../../../helpers/currency'
import { changeAnswers } from '../../../../store/insurance/actions'
import sources_update from '../../../../common/decorator'

//scss
import '../../../../scss/q_custom.scss'

class CyberOptionalCoverageQuestion extends Component {

  state = {
    questions: []
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!isEqual(this.props.answers, prevProps.answers)) {
      this.limitsUpdate()
    }
  }

  onChange (newValue) {
    const value = newValue ? 'Yes' : 'No'
    this.props.changeAnswers({
      [this.props.qid]: value
    })
    document.dispatchEvent(new CustomEvent('total-changed'))
    this.props.onLimitChanged()
    sources_update(this.props.answers, this.props.qid)
  }

  limitsUpdate () {

    const ocLimits = this.props.answers.selected_quotes.filter(q => q.quote_type === 'CYBER')[0].optional_coverages
    let ocLimitsValues = {}
    ocLimits.forEach((item) => {
      ocLimitsValues[`${item.qid}_aggregate_limit`] = item['aggregate_limit']
      ocLimitsValues[`${item.qid}_retention_limit`] = item['retention_limit']
    })

    this.props.changeAnswers(ocLimitsValues)
  }

  render () {

    let switcherCheck = clsx({
      'switch-body': true,
      'checked': this.props.answers[this.props.qid] === 'Yes'
    })

    return (
      <Grid item className="oc-question oc__cyber">
          <div className="switch-wrapper">
            <div className="oc-type">
                                <span className={switcherCheck}>
                                    <label htmlFor={'switch-' + this.props.qid}>
                                        <input type="checkbox" id={'switch-' + this.props.qid}
                                               onChange={(e) => this.onChange(e.target.checked)}
                                               checked={this.props.answers[this.props.qid] === 'Yes'}/>
                                        <span className="thumb"/>
                                    </label>
                                </span>
              <p className="oc-text">{this.props.question}&nbsp;{this.props.tooltip}</p>
            </div>

            {this.props.diya_helper && (
              <div className="oc-desc">
                <p className="custom-question-diya-helper">{this.props.diya_helper}</p>
              </div>
            )}
          </div>
        {!this.props.updateNeeded && this.props.answers[this.props.qid] === "Yes" && (
              <div className="oc_limits__cyber">
                <div className="oc_limit_item__cyber">
                  <div className="limit_name">Aggregate Limit</div>
                  <div className="limit_value">{formatCurrency(this.props.answers[this.props.qid + '_aggregate_limit'])}</div>
                </div>
                <div className="oc_limit_item__cyber">
                  <div className="limit_name">Retention Limit</div>
                  <div className="limit_value">{formatCurrency(this.props.answers[this.props.qid + '_retention_limit'])}</div>
                </div>
              </div>
          )}
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  answers: state.insurance.answers,
})

const mapDispatchToProps = {
  changeAnswers
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CyberOptionalCoverageQuestion))