import React from 'react'
import {withTranslation} from 'react-i18next'
import {Dialog, useTheme} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {Warning as WarningIcon} from '../icons/new/Warning'
import {ModalTransition} from '../../common/ModalTransition'
import '../../scss/v2/modals/_delayed_quote_message.scss'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const DelayedQuoteMessage = ({open, onClose, t} : {open: boolean, onClose: () => void, t: (string) => string}) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Dialog
            className={'delayed_quote_message_modal__wrapper'}
            fullScreen={fullScreen}
            open={open}
            fullWidth
            maxWidth='sm'
            scroll='body'
            TransitionComponent={ModalTransition}>

            <button className={'modal_close_btn'}
                type={'button'}
                onClick={onClose}><Close/> <span>{t('common.btn_close')}</span></button>

            <div className="delayed_quote_message_modal_cnt">
                <div className={'modal_warning_icon_cnt'}>
                    <WarningIcon className={'warning_icon'}/>
                </div>

                <div className="txt_cnt">
                    <p className="modal_ttl">
                        {t('modal.delayed_quote.title')}
                    </p>
                    <ul className="list_blk">
                        <li className={'list_item'}>
                            <span className={'txt_bold'}>Option 1: </span>
                            <span>{t('modal.delayed_quote.option_1')}</span>
                        </li>
                        <li className={'list_item'}>
                            <span className={'txt_bold'}>Option 2: </span>
                            <span>{t('modal.delayed_quote.option_2')}</span>
                        </li>
                    </ul>
                </div>

            </div>



            {/*    <DialogActions style={{justifyContent: 'flex-start'}}>
                    <ThemeProvider theme={buttonTheme}>
                        <Button
                            variant='text'
                            color='primary'
                            style={{minWidth: '32px', textTransform: 'none', fontSize: '16px'}}
                            onClick={onClose}
                        ><Close style={{marginRight: '2px'}}/> {t('common.btn_close')}</Button>
                    </ThemeProvider>
                </DialogActions>

                <DialogContent>
                    <Grid container justify='center' style={{marginBottom: 0}}>
                        <Grid item justify='center'>
                            <AlertIcon/>
                        </Grid>
                        <Grid item justify='center' style={styles.optionTitleGrid}>
                            <p style={styles.title}>{t('modal.delayed_quote.title')}</p>
                        </Grid>
                    </Grid>
                    <Grid container xs style={styles.optionsContainer}>
                        <Grid item>
                            <p style={styles.optionTitleText}>Option 1: </p>
                            <p style={styles.optionTextBody}>{t('modal.delayed_quote.option_1')}</p>
                        </Grid>
                        <Grid item style={{marginTop: '20px', marginBottom: '45px'}}>
                            <p style={styles.optionTitleText}>Option 2: </p>
                            <p style={styles.optionTextBody}>{t('modal.delayed_quote.option_2')}</p>
                        </Grid>
                    </Grid>
                </DialogContent>*/}
        </Dialog>
    )
}

export default withTranslation()(DelayedQuoteMessage)