export const wc_locations= ()=>{
  return [
    {
      city: 'Frisco',
      collapsed: false,
      full_time_employees: 2,
      is_addition_owners: false,
      is_addition_payroll_classes: false,
      owners: [
        {
          disclaimer_required: true,
          first_name: 'Ernesto',
          id: 0,
          is_included: true,
          last_name: 'Fernandez',
          percent: 12,
          salary: 12222,
          title_code: 'CH'
        }
      ],
      primary: true,
      rate_classes: [
        {
          class_code: '812112-014',
          class_code_description: 'Unisex hair stylist shops',
          payroll_amount: 51000
        }
      ],
      state: 'TX',
      street_address: '6201 Technology Dr',
      street_address2: '',
      zip_code: '75033'
    }
  ]
}