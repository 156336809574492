import moment from "moment";


const DateTimeHelper = class {
    constructor(dateTimeObj) {
        this.dateTimeObj = dateTimeObj;
    }

    checkDate (minDate, maxDate, d, setter) {
        if (d >= minDate && d <= maxDate) {
            return setter(true)
        }
        else if (d > maxDate) {
            return setter(false)
        } else if (d <= minDate ) {
            return setter(false)
        } else {
            return setter(false)
        }
    }
    isDateValid() {
        const dateFormat = 'MM/DD/YYYY'
        const dateInput = moment(moment(this.dateTimeObj).toISOString().split('T')[0]).format(dateFormat)
        return moment(dateInput, dateFormat, true).isValid()
    }

    futureDate(n, inc) {
        let momentObj = moment(this.dateTimeObj.toISOString())
        momentObj.add(n, inc)

        return momentObj
    }

    pastDate(n, inc) {
        let momentObj = moment(this.dateTimeObj.toISOString())
        momentObj.subtract(n, inc)

        return momentObj
    }

    localTime() {
        return moment(this.dateTimeObj).format('YYYY-MM-DD HH:mm:ss')
    }

    setPolicyStartDate(today, answers) {
        let responseData = answers['policy.desired_effective_date']
        if (this.futureDate(1, 'days').isSameOrBefore(responseData)) {
            return moment(responseData)
        } else {
            return this.futureDate(1, 'days')
        }
    }
}


export default DateTimeHelper