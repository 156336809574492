import WhatsCoveredGL from '../../components/modals/WhatsCoveredGL';
import { coalitionCYBER } from '../../testPayloads/coalition-CYBER'
// import {coterieGLQQ} from '../../testPayloads/coterie-GL-QQ'
// import {coteriePlQq} from '../../testPayloads/coterie-pl-qq'
// import {employersWC} from '../../testPayloads/employers-WC'
import {wc_locations} from '../../testPayloads/wc-location'
import {
    CHANGE_ANSWERS,
    PLACE_DEFAULTS_ANSWERS,
    SET_ANSWERS,
    SET_NEXT_SECTION_BUTTON_TEXT,
    SET_RESTORE_SESSION,
    UPDATE_ADDITIONAL_INSURED_QUESTIONS,
    UPDATE_ADDITIONAL_INSUREDS,
    UPDATE_AOI_DESIGNATED_PERSON,
    UPDATE_AOI_LOSS_PAYABLE_CLAUSES,
    UPDATE_AOI_MANAGERS_OR_LESSORS_PREMISES,
    UPDATE_AOI_MORTGAGE_HOLDER_INFORMATION,
    UPDATE_AOI_MORTGAGEE_OR_RECEIVER,
    UPDATE_AOI_WTRR_AGAINST_OTHERS,
    UPDATE_APP_PRODUCTS,
    UPDATE_BUSINESS_OWNERS,
    UPDATE_INDUSTRY_CLASSES,
    UPDATE_PROGRESS,
    UPDATE_QUESTIONS,
    UPDATE_QUESTIONS_AUTOFILL,
    UPDATE_QUESTIONS_CLASS_STATE_BASED,
    UPDATE_SECTIONS,
    UPDATE_STORE_KEY,
    UPDATE_UNDERWRITER_STATEMENTS,
    UPDATE_WC_ENDORSEMENT,
    UPDATE_WC_INDUSTRY_CLASSES,
    UPDATE_WC_LOCATIONS,
    UPDATE_UW_QUESTIONS,
    UPDATE_WC_UW_QUESTIONS
} from './actions'
import axios from 'axios'
import moment from 'moment'
import Cookie from 'js-cookie'
import {BOP} from '../../components/icons/BOP'
import {WC} from '../../components/icons/WC'
import {PL} from '../../components/icons/PL'
import {GL} from '../../components/icons/GL';
import {CT} from '../../components/icons/CT';
import WCOwners from '../../pages/insurance/WCOwners'
import Section from '../../components/insurance/Section'
import BusinessData from '../../pages/insurance/BusinessData'
import SelectPolicy from '../../pages/insurance/SelectPolicy'
import YouAreCovered from '../../pages/insurance/YouAreCovered'
import IndustryClass from '../../pages/insurance/IndustryClass'
import SelectYourRate from '../../pages/insurance/SelectYourRate'
import WhatsCoveredWC from '../../components/modals/WhatsCoveredWC'
import WhatsCoveredBOP from '../../components/modals/WhatsCoveredBOP'
import WhatsCoveredCyber from '../../components/modals/WhatsCoveredCyber'
import WhatsCoveredPL from '../../components/modals/WhatsCoveredPL'
import PaymentInformation from '../../pages/insurance/PaymentInformation'
import {cloneDeep, findIndex, includes, isEqual, some, sortBy, throttle, uniq} from 'lodash'
import CustomizeYourRate from '../../pages/insurance/CustomizeYourRate'
import CreateAnAccount from '../../pages/insurance/CreateAnAccount'
import WCLocations from '../../pages/insurance/WCLocations'

const REACT_APP_TEST_QUOTES_PAYLOADS = process.env.REACT_APP_TEST_QUOTES_PAYLOADS && process.env.REACT_APP_TEST_QUOTES_PAYLOADS === "true"

const slug = require('slug')
const objectPath = require('object-path')
const products_data = [
    {
        id: 1,
        lob: 'BOP',
        carrier_id: 10,
        name: 'Business Owner\'s Policy',
        icon: BOP,
        hint: WhatsCoveredBOP,
        isDisabled: false,
        description: `Provides value to small businesses by combining property insurance and general liability 
      insurance into a single cost-effective policy.`,
        bulletPoints:[
            'Annual revenues up to $10M  ($5M for contractors)',
            'Up to 50 Employees  (15 for contractors)',
            'Limits up to $1M/$2M',
            'Building Coverage  up to $1M',
            'Business Personal Property up to $500K',
            'Deductible as low as $250',
        ]
    },
    {
        id: 2,
        lob: 'WC',
        carrier_id: 20,
        name: 'Workers\' Compensation',
        icon: WC,
        hint: WhatsCoveredWC,
        isDisabled: false,
        description: `Typically a state required coverage that extends to medical expenses related to employee injuries 
      or illnesses that occur on-the-job.`,
        bulletPoints: [
            'Annual Payroll up to $5M',
            'Employees up to 50',
            'Limits $1M/$1M/$1M',
        ]
    },
    {
        id: 3,
        lob: 'PL',
        carrier_id: 30,
        name: 'Errors & Omissions',
        subName: '(Professional Liability)',
        icon: PL,
        hint: WhatsCoveredPL,
        isDisabled: false,
        description: `For businesses that provide professional services. May cover work mistakes, such as missed deadlines, and lawsuits arising out of professional negligence.`,
        bulletPoints: [
            'Annual Revenue Up to $5M',
            'Limits up to $1M/$2M',
            'Deductible as low as $0',
        ]
    },
    {
        id: 4,
        lob: 'GL',
        carrier_id: 5,
        name: 'General Liability',
        icon: GL,
        hint: WhatsCoveredGL,
        isDisabled: false,
        description: `Helps protect your small business from claims caused or allegedly caused by third party bodily injuries, property damage or personal and advertising injury.`,
        bulletPoints:[
            'Annual revenues up to $10M  ($5M for contractors)',
            'Up to 50 Employees (15 for contractors)',
            'Limits up to $1M/$2M',
            'Deductible as low as $250',
        ]
    },
    {
        id: 50,
        lob: 'CYBER',
        carrier_id: 40,
        name: 'Cyber Insurance',
        icon: CT,
        hint: WhatsCoveredCyber,
        isDisabled: false,
        description: `Protects from risks related to information technology infrastructure, privacy, and governance 
      liability, and related internet activities.`,
        bulletPoints: [
            'Annual Revenue up to $5M',
            'Limits up to $1M/$1M',
            'Retention as low as $1,500',
        ]

    }
]

const initialState = {
    unique_session_id: null,
    unique_browser_id: null,
    answers: {
        'acrisure_id': '',
        'appetite': {},
        'notify_email': '',
        'billing.frequency': 'Monthly',
        'coverage.types': ['GL'],
        // 'selected_quotes': REACT_APP_TEST_QUOTES_PAYLOADS? [coteriePlQq(), coterieGLQQ(), employersWC()]:[],
        'selected_quotes': REACT_APP_TEST_QUOTES_PAYLOADS? [coalitionCYBER()]:[],
        'business.name': '',
        'business.street_address': '',
        'business.street_address2': '',
        'business.city': '',
        'business.state': '',
        'business.zip_code': '',
        'billing.state': '',
        'business.g_types': [],
        'quote_status': 'No Quote',
        'validated_email': '',
        'validated_phone_number': '',
        'is_business_info_myself': false,
        'newCustomerSlackRequestSent': false,
        'isRestoreCheckout': 0
    },
    'premises.area_occupied_sf': 0,
    session_answers: {},
    raw_questions: [],
    sections: [],
    sections_data: [],
    current_section: 0,
    current_section_index: 0,
    current_subsection: 0,
    total_progress: 0,
    subsections: [],
    subsectionsCache: undefined,
    subsections_data: [],
    subsections_questions: [],
    subsections_questions_data: [],
    industry_classes: [],
    wc_industry_classes: [],
    fetched_industry_classes: [],
    // quotes: REACT_APP_TEST_QUOTES_PAYLOADS ? [coteriePlQq(), coterieGLQQ(), employersWC()] : [],
    quotes: REACT_APP_TEST_QUOTES_PAYLOADS ? [coalitionCYBER()] : [],
    // full_quote: REACT_APP_TEST_QUOTES_PAYLOADS ? [coteriePlQq(), coterieGLQQ(), employersWC()] : [],
    full_quote: REACT_APP_TEST_QUOTES_PAYLOADS ? [coalitionCYBER()] : [],
    payment_method: '',
    notify_email: '',
    coverage_types: products_data,
    filteredCoverageTypes: products_data,
    restore_session: false,
    is_session_restored: false,
    is_from_bulk: false,
    wc_locations_template: {
        street_address: '',
        street_address2: '',
        city: '',
        state: '',
        zip_code: '',
        primary: false,
        collapsed: false,
        full_time_employees: '',
        is_addition_owners: false,
        is_addition_payroll_classes: false,
        rate_classes: [
            {
                class_code: '',
                class_code_description: '',
                payroll_amount: '',
                // is_addition: "No",
            }
        ]
    },
    wc_rate_classes_template: {
        class_code: '',
        class_code_description: '',
        payroll_amount: '',
        // is_addition: "No"
    },
    wc_locations: REACT_APP_TEST_QUOTES_PAYLOADS ? [wc_locations()] :[],
    wc_classes: [],
    legal_entities: [],
    designated_person: [],
    designated_person_template: {
        name: '',
        street_address: '',
        street_address2: '',
        city: '',
        state: '',
        zip_code: ''
    },
    managers_or_lessors_premises: [],
    managers_or_lessors_premises_template: {
        name: '',
        street_address: '',
        street_address2: '',
        city: '',
        state: '',
        zip_code: ''
    },
    mortgagee_or_receiver: [],
    mortgagee_or_receiver_template: {
        name: '',
        street_address: '',
        street_address2: '',
        city: '',
        state: '',
        zip_code: ''
    },
    mortgageholder_information: [],
    mortgageholder_information_template: {
        name: '',
        street_address: '',
        street_address2: '',
        city: '',
        state: '',
        zip_code: ''
    },
    loss_payable_clauses: [],
    loss_payable_clauses_template: {
        name: '',
        street_address: '',
        street_address2: '',
        city: '',
        state: '',
        zip_code: '',
        premises_description: '',
        provision_applicable: ''
    },
    wtrr_against_others: [],
    wtrr_against_others_template: {
        name: ''
    },
    business_owners: [],
    business_owners_template: {
        first_name: '',
        last_name: ''
    },
    business_additional_insured: [],
    business_additional_insured_template: {
        first_name: '',
        last_name: '',
        email: '',
        street_address: '',
        city: '',
        state: '',
        zip_code: '',
        type: '',
    },
    //Add new  key => store  rows here to parse data from answers snapshot
    data_map: {
        //answers key => store key
        'premises': 'wc_locations',
        'aoi.designated_person.is_selected': 'designated_person',
        'aoi.managers_or_lessors_premises.is_selected': 'managers_or_lessors_premises',
        'aoi.mortgagee_or_receiver.is_selected': 'mortgagee_or_receiver',
        'aoi.mortgageholder_information.is_selected': 'mortgageholder_information',
        'aoi.loss_payable_clauses.is_selected': 'loss_payable_clauses',
        'aoi.wtrr_against_others.is_selected': 'wtrr_against_others',
        'business.owners_list': 'business_owners',
        'business.additional_insured_list': 'business_additional_insured',
        'premises.building_limit': 0,
        'premises.bpp_limit': 0,
        'business.location.home': undefined
    },
    bop_limit_available: [],
    gl_limit_available: [],
    pl_limit_available: [],
    cyber_limit_available: [],
    loading_cards: [],
    oc_availability: {
        tria: true,
        cyber: false,
        pl: false,
        hnoa: false,
        eb: false
    },
    is_new_user: false,
    is_effective_date_updated: true,
    is_answers_changed: false,
    layout_built: false,
    bop_premium_total: 0,
    pl_premium_total: 0,
    gl_premium_total: 0,
    cyber_premium_total: 0,
    next_section_button_text: '',
    is_choose_naics_manually: false,
    advisorCache: {
        businessDataSearch: null,
        propertySearch: null
    },
    WCOwnerEndorsement: undefined,
    passedClassStateSelection: false,
    hide: [],
    underwriterStatements: undefined,
    filteredQuestionsCache: undefined,
    partnerData: undefined,
    isPartner: true,
    estimatedPremium: null
}

const operatorMap = {
    '==': (a, b) => {
        return a === b
    },
    '!=': (a, b) => {
        return a !== b
    }
}

function prepareFlatOptions(options) {
    return options.map((itm, idx) => {
        let parsed_value = itm.replaceAll('$;', ',')
        return {
            id: idx + 1,
            text: parsed_value,
            value: parsed_value
        }
    })
}

function selectComponentForSection(path) {
    switch (path) {
        case '/business-name-and-address':
            return REACT_APP_TEST_QUOTES_PAYLOADS ? PaymentInformation: BusinessData
        case '/select-your-policy-type':
            return SelectPolicy
        case '/industry-class':
            return IndustryClass
        case '/owners-and-officers':
            return WCOwners
        case '/business-locations':
            return WCLocations
        case '/select-your-rate':
            return SelectYourRate
        case '/customize-rate':
            return CustomizeYourRate
        case '/create-an-account':
            return CreateAnAccount
        case '/checkout':
            return PaymentInformation
        case '/request-your-quote':
            return YouAreCovered
        default:
            return Section
    }
}

function calculateProgress(state, action) {
    //Calculate per section progress
    action.payload.sections = state.sections.map(section => {
        //section all subsections
        let s_subsections = [],
            //section completed subsections
            c_subsections = []
        //iterate iver subsections and get all for curr section
        //and completed for curr section
        for (let i = 0; i < state.subsections.length; i++) {
            if (state.subsections[i].section_index === section.section_index) {
                s_subsections.push(i)
                if (i < action.payload.current_subsection)
                    c_subsections.push(i)
            }
            if (state.subsections[i].section_index > section.section_index) break
        }

        const progress = (c_subsections.length > 0)
            ? Math.ceil(c_subsections.length * 100 / s_subsections.length)
            : 0
        return {
            ...section,
            progress: progress
        }
    })
    //calculate total progress via current subsection over total subsections length formula
    let total_progress = Math.floor((action.payload.current_subsection + 1) * 100 / state.subsections.length)
    action.payload.total_progress = (action.payload.current_subsection > 0)
        ? total_progress
        : 0
    //TODO: remove if not necessary anymore, test first
    // action.payload.total_progress = (action.payload.current_subsection > 0)
    //     ? ((total_progress === 100)
    //             ? 99
    //             : total_progress
    //     )
    //     : 0

    action.payload.current_section_index = getCurrentSectionIndexFromID(action.payload.current_section, state)

    //return modified action
    return action
}

function getCurrentSectionIndexFromID(id, state) {
    return findIndex(state.sections, itm => itm.section_index === id)
}

function postAnswers(state, answers) {
    let completed = getCompletedSectionsList(state)

    axios.post('/api/post-answers', {
        session_id: state.unique_session_id,
        answers: {
            ...answers,
            ...completed
        }
    }).catch(e => {
        throw new Error(`Failed to post answers. ${e}`)
    })
}


function getCompletedSectionsList(state) {
    let sections = [...state.sections]
    let subsections = [...state.subsections]

    let completed_sections = sections.filter((itm) => itm?.progress && itm?.progress === 100).map(itm => itm.name)
    let completed_subsections = subsections.filter((itm, idx) => idx < state.current_subsection).map(itm => itm.name)

    return {
        completed_sections: completed_sections,
        completed_subsections: completed_subsections
    }
}

function getBusinessWebSiteUrl(url) {
    try {
        return parseUrl(url)
    } catch (e) {
        return ''
    }
}

function getActualDesiredDates() {
    const today = moment().set({
        hours: 12,
        minutes: 1,
        seconds: 0,
    })
    const tomorrow = moment(today).add(1, 'day')
    const after_year = moment(tomorrow).add(1, 'year')
    //Suppress these given dates with a valid one
    return {
        'policy.desired_effective_date': tomorrow,
        'policy.desired_expiration_date': after_year,
    }
}

function setAnswers(state, action) {
    let dates = getActualDesiredDates()
    let data = parseComplexDataObjects(action.payload)
    let businessUrl = {'business.website_url': getBusinessWebSiteUrl(action.payload?.['business.website_url'])}

    return {
        ...state,
        answers: {
            ...action.payload,
            ...dates,
            ...businessUrl,
            // 'selected_quotes': REACT_APP_TEST_QUOTES_PAYLOADS? [coteriePlQq(), coterieGLQQ(), employersWC()]:[],
            'selected_quotes': REACT_APP_TEST_QUOTES_PAYLOADS? [coalitionCYBER()]:[],
        },
        ...data
    }
}

function ifArrayContainsValues(arr, values) {
    return arr.some(v => values.includes(v))
}

function getSectionsForProducts(state, action) {
    let list = ['COMMON'].concat(action.payload)
    let sections = state.sections_data.filter(s => ifArrayContainsValues(s.lobs, ['COMMON', 'WC', 'BOP', 'PL', 'GL','CYBER'])),
        subsections = state.subsections_data.filter(s => ifArrayContainsValues(s.lobs, list)),
        subsections_questions = state.subsections_questions_data.filter(s => ifArrayContainsValues(s.lobs, list))

    sections.sort((a, b) => (a.section_index > b.section_index) ? 1 : ((b.section_index > a.section_index) ? -1 : 0))
    //sort subsections
    subsections.sort((a, b) => {
        return a.section_index - b.section_index || a.subsection_index - b.subsection_index
    })

    return {
        ...state,
        sections: sections,
        subsections: subsections,
        subsections_questions: subsections_questions,
    }
}

function prepareQuestionsPayload(state, action) {
    let sections = [],
        subsections = [],
        questions = [],
        answers = {...state.answers},
        dates = getActualDesiredDates()

    const hardCodeQuestions = []
    const hardCodedPayload = [...hardCodeQuestions, ...action.payload]

    for (let itm of hardCodedPayload) {
        //first, section itself
        if (itm.section) {
            const section_match = sections.findIndex((item) => {
                return item.name.toLowerCase() === itm.section.toLowerCase()
            })
            if (section_match === -1) {
                sections.push({
                    name: itm.section,
                    section_index: itm.section_index,
                    collapsed: false,
                    lobs: itm.lobs
                })
            } else {
                sections[section_match] = {
                    ...sections[section_match],
                    lobs: uniq([
                        ...itm.lobs,
                        ...sections[section_match].lobs
                    ])
                }
            }
        }
        //then subsections
        if (itm.subsection) {
            const subsections_match = subsections.findIndex((item) => {
                return item.name.toLowerCase() === itm.subsection.toLowerCase()
            })
            if (subsections_match === -1) {
                const path = '/' + slug(itm.subsection, '-')
                subsections.push({
                    name: itm.subsection,
                    section_index: itm.section_index,
                    path: path,
                    layout: selectComponentForSection(path),
                    desc: (itm.subsection_desc) ? itm.subsection_desc : null,
                    subsection_index: itm.subsection_index,
                    lobs: itm.lobs,
                })
            } else {
                subsections[subsections_match] = {
                    ...subsections[subsections_match],
                    lobs: uniq([
                        ...itm.lobs,
                        ...subsections[subsections_match].lobs
                    ])
                }
            }
        }
        //next, questions
        //try to find question in existing
        const state_questions_match = state.subsections_questions.find((item) => {
            return item.qid === itm.qid
        })
        if (state_questions_match) questions.push({
            ...state_questions_match,
            qid: itm.qid,
            section: itm.section_index,
            subsection: itm.subsection_index,
            question_text: itm.question_text,
            question_type: itm.question_type,
            min_length: itm.min_length,
            max_length: itm.max_length,
            date_only_in_future: itm.date_only_in_future,
            options: (itm.options) ? prepareFlatOptions(itm.options.split(',')) : [],
            tooltip: itm.tooltip,
        })
        //else add new question
        else {
            const questions_match = questions.find((item) => {
                return item.qid === itm.qid
            })
            if (!questions_match) {
                questions.push({
                    qid: itm.qid,
                    section: itm.section_index,
                    subsection: itm.subsection_index,
                    question_text: itm.question_text,
                    question_type: itm.question_type,
                    min_length: itm.min_length,
                    max_length: itm.max_length,
                    date_only_in_future: itm.date_only_in_future,
                    options: (itm.options) ? prepareFlatOptions(itm.options.split(',')) : [],
                    required: itm.required,
                    lobs: itm.lobs,
                    tooltip: itm.tooltip,
                    placeholder: itm?.placeholder,
                    conditions: itm?.conditions,
                    default: itm?.default,
                    max_value: itm.max_value,
                    min_value: itm.min_value,
                    sidebar_field_name: itm.sidebar_field_name,
                    sidebar_section_title: itm.sidebar_section_title,
                    hidden: !!itm.hidden_question,
                    disabled: !!itm.disabled,
                    diya_helper: itm.diya_helper,
                })
            }
        }
        //Last, we need to provide default answers for all questions to optimize react block render
        if (answers[itm.qid] === undefined) {
            if (['date', 'datetime'].includes(itm.question_type.toLowerCase())) {
                answers[itm.qid] = null

                if (itm.qid === 'policy.desired_effective_date') answers[itm.qid] = dates['policy.desired_effective_date']
                if (itm.qid === 'policy.desired_expiration_date') answers[itm.qid] = dates['policy.desired_expiration_date']

            } else answers[itm.qid] = ''
        }
        //set default values to questions
        if (itm.default && itm.default.value !== undefined && !answers[itm.qid]) answers[itm.qid] = itm.default.value
    }

    let new_state = {
        ...state,
        raw_questions: hardCodedPayload,
        sections_data: sections,
        subsections_data: subsections,
        subsections_questions_data: questions,
        subsections_questions: questions,
        answers: answers,
        layout_built: true
    }

    return checkQuestionConditions(new_state.answers, new_state, true)
}

function updateQuestionsClassStatePayload(state, action) {
    let sections = [],
        subsections = [],
        questions = [],
        answers = {...state.answers},
        dates = getActualDesiredDates()

    // testing snipper for whole hidden subsection:
    // const test = action.payload.questions.map(e => {
    //   if (e.subsection === 'Business locations') {
    //     return {...e, hidden_question: true}
    //   }
    //   else {
    //     return e
    //   }
    // })

    // by filtering out the hidden questions we should avoid the creation of subsections
    // that are entirely created by hidden questions

    const visibleQuestions = action.payload.questions.filter(q => (!q?.hidden_question || q?.hidden || q?.qid.startsWith('business.additional_insured') || q?.qid.startsWith('b2z_naics_code')))

    const subsectionQuestions = [...visibleQuestions]
    for (let itm of subsectionQuestions) {
        //first, section itself
        if (itm.section) {
            const section_match = sections.findIndex((item) => {
                return item.name.toLowerCase() === itm.section.toLowerCase()
            })
            if (section_match === -1) {
                sections.push({
                    name: itm.section,
                    section_index: itm.section_index,
                    collapsed: false,
                    lobs: itm.lobs
                })
            } else {
                sections[section_match] = {
                    ...sections[section_match],
                    lobs: uniq([
                        ...itm.lobs,
                        ...sections[section_match].lobs
                    ])
                }
            }
        }
        //then subsections
        if (itm.subsection) {
            const subsections_match = subsections.findIndex((item) => {
                return item.name.toLowerCase() === itm.subsection.toLowerCase()
            })
            if (subsections_match === -1) {
                const path = '/' + slug(itm.subsection, '-')
                subsections.push({
                    name: itm.subsection,
                    section_index: itm.section_index,
                    path: path,
                    layout: selectComponentForSection(path),
                    desc: (itm.subsection_desc) ? itm.subsection_desc : null,
                    subsection_index: itm.subsection_index,
                    lobs: itm.lobs,
                })
            } else {
                subsections[subsections_match] = {
                    ...subsections[subsections_match],
                    lobs: uniq([
                        ...itm.lobs,
                        ...subsections[subsections_match].lobs
                    ])
                }
            }
        }
        //next, questions
        //try to find question in existing
        const state_questions_match = state.subsections_questions.find((item) => {
            return item.qid === itm.qid
        })

        if (state_questions_match) questions.push({
            ...state_questions_match,
            qid: itm.qid,
            section: itm.section_index,
            subsection: itm.subsection_index,
            question_id: itm.question_id,
            question_text: itm.question_text,
            question_type: itm.question_type,
            min_length: itm.min_length,
            max_length: itm.max_length,
            date_only_in_future: itm.date_only_in_future,
            options: (itm.options) ? prepareFlatOptions(itm.options.split(',')) : [],
            tooltip: itm.tooltip,
        })
        //else add new question
        else {
            const questions_match = questions.find((item) => {
                return item.qid === itm.qid
            })
            if (!questions_match) {
                questions.push({
                    qid: itm.qid,
                    section: itm.section_index,
                    subsection: itm.subsection_index,
                    question_id: itm.question_id,
                    question_text: itm.question_text,
                    question_type: itm.question_type,
                    min_length: itm.min_length,
                    max_length: itm.max_length,
                    date_only_in_future: itm.date_only_in_future,
                    options: (itm.options) ? prepareFlatOptions(itm.options.split(',')) : [],
                    required: itm.required,
                    lobs: itm.lobs,
                    tooltip: itm.tooltip,
                    placeholder: itm.question?.placeholder,
                    conditions: itm?.conditions,
                    default: itm?.default,
                    max_value: itm.max_value,
                    min_value: itm.min_value,
                    sidebar_field_name: itm.sidebar_field_name,
                    sidebar_section_title: itm.sidebar_section_title,
                    hidden: !!itm.hidden_question,
                    disabled: !!itm.disabled,
                    diya_helper: itm.diya_helper,
                })
            }
        }
        //Last, we need to provide default answers for all questions to optimize react block render
        if (answers[itm.qid] === undefined) {
            if (['date', 'datetime'].includes(itm.question_type.toLowerCase())) {
                answers[itm.qid] = null

                if (itm.qid === 'policy.desired_effective_date') answers[itm.qid] = dates['policy.desired_effective_date']
                if (itm.qid === 'policy.desired_expiration_date') answers[itm.qid] = dates['policy.desired_expiration_date']

            } else answers[itm.qid] = ''
        }
        //set default values to questions
        if (itm.default && itm.default.value !== undefined && !answers[itm.qid]) answers[itm.qid] = itm.default.value
    }
    //now we need to add some extra hardcoded sections

    let list = ['COMMON'].concat(action.payload.lobs)

    let new_state = {
        ...state,
        raw_questions: subsectionQuestions,
        sections: sections
            .filter(s => ifArrayContainsValues(s.lobs, list))
            .sort((a, b) => (a.section_index > b.section_index) ? 1 : ((b.section_index > a.section_index) ? -1 : 0)),
        subsections: subsections
            .filter(s => ifArrayContainsValues(s.lobs, list))
            .sort((a, b) => {
                return a.section_index - b.section_index || a.subsection_index - b.subsection_index
            }),
        sections_data: sections,
        subsections_data: subsections,
        subsections_questions_data: questions,
        subsections_questions: questions.filter(s => ifArrayContainsValues(s.lobs, list)),
        answers: answers,
        passedClassStateSelection: true,
    }

    return checkQuestionConditions(new_state.answers, new_state, true)
}

function parseComplexDataObjects(answers) {
    let data_objects = {
            wc_locations: REACT_APP_TEST_QUOTES_PAYLOADS? [wc_locations()]: [],
            designated_person: [],
            managers_or_lessors_premises: [],
            mortgagee_or_receiver: [],
            mortgageholder_information: [],
            loss_payable_clauses: [],
            wtrr_against_others: [],
            business_owners: [],
            business_additional_insured: []
        },
        keys = Object.keys(answers).filter(itm => (/\.\d\./).test(itm))
    //sort keys by indexes
    keys.sort()

    Object.keys(initialState.data_map).forEach(key => {
        let count = uniq(keys.filter(itm => itm.startsWith(key)).map(itm => itm.match(/\d/)[0]))
        count.forEach(itm => {
            [key].push(cloneDeep(initialState.data_map[key]))
        })
    })

    keys.forEach(key => {
        let map_key = Object.keys(initialState.data_map).filter(itm => {
            let primary_key = itm.replace('.is_selected', '')
                .replace('_list', '')
            return key.startsWith(primary_key)
        })?.[0]
        if (map_key) {
            let primary_key = map_key.replace('.is_selected', '')
                .replace('_list', '')
            let clear_key = key.replace(primary_key + '.', '')
            objectPath.set(data_objects[initialState.data_map[map_key]], clear_key, answers[key])
        }
    })

    return {...data_objects}
}

function prepareAnswersFromDataObjects(state, data, store_key, key_prefix) {
    let _answers = {...state.answers}
    //delete previous and wildcard answers

    key_prefix = key_prefix.replace('.is_selected', '')
        .replace('_list', '')

    Object.keys(_answers).forEach(itm => {

        if (itm.startsWith(key_prefix) && (/\.[\d*]\./).test(itm)) delete _answers[itm]
    })
    //add current
    data.forEach((itm, key) => {
        let props = Object.keys(itm)
        _answers = recursiveAnswersBuilder(_answers, data, props, key, key_prefix)
    })
    //send updated answers to BE server
    throttled(state, _answers)
    //return updated answers
    return {
        ...state,
        answers: _answers,
        [store_key]: data,
    }
}

function recursiveAnswersBuilder(answers, locations, props, key, prefix, glue = '.') {
    props.forEach((itm) => {
        let path = [prefix, key, itm].join(glue)
        let value = locations[key][itm]
        if (typeof value === 'object' && value !== null) {
            value.forEach((sub_itm, sub_key) => {
                let sub_props = Object.keys(sub_itm)
                answers = recursiveAnswersBuilder(answers, value, sub_props, sub_key, path)
            })
        } else {
            answers[path] = value
        }
    })

    return answers
}

function updateWCUWQuestions(state, questions) {
    let subsection_questions = [...state.subsections_questions]

    if (!questions || questions.length < 1) return subsection_questions

    return subsection_questions.map(itm => {

        if (includes(questions, itm.qid)) {
            itm.required = false
            itm.hidden = true
        }

        return itm
    })
}

function updateUWQuestions(state, questions) {

    let subsection_questions = [...state.subsections_questions]

    if (!questions || questions.length < 1) return subsection_questions

    return subsection_questions.map(itm => {
      if (includes(['uw.trims_trees_over_35_ft', 'uw.does_tree_trimming_yes_no'], itm.qid)) {

        let question = questions.find(q=>q.qid === itm.qid)
        itm.hidden = question.hidden_question
        itm.required = question.required
      }
      return itm
    })
}

function updateQuestionsAutofill(state, payload, mark_autofilled = true, fill_values = true, filterBySubsection = true) {
    /**
     * @param filterBySubsection: Optional parameter used to indicate whether autofill function should execute on entire payload
     * or just the questions for the current subsection.
     * @type {string[]}
     */
    const keys = Object.keys(payload)

    let updatedQuestions
    if (filterBySubsection === true) {
        updatedQuestions = state.subsections_questions.map(itm => {
            if (payload.hide && includes(payload.hide, itm.qid)) {
                itm.required = false
                itm.hidden = true
            }

            if (mark_autofilled) itm.autofilled = includes(keys, itm.qid)

            return itm
        })
    } else {
        updatedQuestions = state.subsections_questions_data.map(itm => {
            if (payload.hide && includes(payload.hide, itm.qid)) {
                itm.required = false
                itm.hidden = true
            }

            if (mark_autofilled) itm.autofilled = includes(keys, itm.qid) && !includes([undefined, null, ''], payload[itm.qid])

            return itm
        })
    }


    if (fill_values) {
        keys.forEach(k => {
            if (k === 'business.website_url') {
                state.answers[k] = getBusinessWebSiteUrl(payload[k])
            }
            else if (!state.is_session_restored) state.answers[k] = payload[k]
            else {
                if (state.answers[k] == null || state.answers[k] === '')
                    state.answers[k] = payload[k]
            }
        })
    }

    const {sources, hide, ...cleanedPayload} = payload

    let answers = {
        ...state.answers,
        sources,
        prefills: cleanedPayload
    }

    let new_state = {
        ...state,
        hide,
        answers,
        subsections_questions: updatedQuestions
    }

    return checkQuestionConditions(answers, new_state, true)
}

const checkQuestionConditions = (payload, state, force_update = false) => {
    //TODO: refactor this
    const answers = {
        ...state.answers,
        ...payload
    }

    const questions = state.subsections_questions || []
    //check and apply conditions to all subsection questions

    const new_questions = questions.map(question => {
        //skip question if no conditions key found
        if (!question.hasOwnProperty('conditions')) return question;
        //iterate over question conditions list
        (question.conditions || []).forEach(c => {
            //Perform conditional actions
            if (c?.conditions) {
                let match_conditions = 0
                //search for applicable conditions
                c.conditions.forEach(itm => {
                    //perform a search if force mode or previous & current answer is different
                    if (force_update || itm?.force_check || checkIfAnswersDiff(itm?.keys || itm.key, answers, state)) {
                        let key = itm?.compare_key || itm.key
                        if (itm.operator === '==') {
                            if (answers[key] === itm.value) match_conditions++
                        } else if (itm.operator === '!=') {
                            if (answers[key] !== itm.value) match_conditions++
                        } else if (itm.operator === '<') {
                            if (answers[key] < itm.value) match_conditions++
                        } else if (itm.operator === '>') {
                            if (answers[key] > itm.value) match_conditions++
                        } else if (itm.operator === '>=') {
                            if (answers[key] >= itm.value) match_conditions++
                        } else if (itm.operator <= '<=') {
                            if (answers[key] !== itm.value) match_conditions++
                        } else if (itm.operator === 'in') {
                            if (includes(itm.value || [], answers[key]))
                                match_conditions++
                        } else if (itm.operator === 'notin') {
                            if (!includes(itm.value || [], answers[key]))
                                match_conditions++
                        } else if (itm.operator === 'sum_gt') {
                            let sum = 0
                            itm.keys.forEach(a => {
                                sum += parseInt(answers[a])
                            })

                            if (sum > itm.value) match_conditions++
                        } else if (itm.operator === 'sum_in') {
                            let sum = 0
                            itm.keys.forEach(a => {
                                sum += parseInt(answers[a])
                            })

                            if (sum >= itm.min && sum <= itm.max) match_conditions++
                        }
                    }
                })
                // perform an action if all conditions match
                if (c.action === 'enable&require_or') {
                    // if either of the conditions are met, enable question
                    const conditionsResult = c.conditions.map(condition => {
                        return operatorMap[condition.operator](answers[condition.key], condition.value)
                    })
                    if (conditionsResult.includes(true)) {
                        question.disabled = false
                        question.required = true
                    }
                }
                if (c.action === 'disable&notrequire_and') {
                    // if ALL conditions of the question are met, disable question
                    const conditionalResult = c.conditions.map(condition => {
                        return operatorMap[condition.operator](answers[condition.key], condition.value)
                    })
                    if (conditionalResult.every(Boolean) === true) {
                        question.disabled = true
                        question.required = false
                    }
                }
                if (match_conditions === c.conditions.length) {
                    if (c.action === 'set_value') {
                        //get value
                        answers[question.qid] = c.value
                        //if get_value_from key present get value by rule
                        if (c.get_value_from) {
                            const key = c.get_value_from.split('.').slice(1).join('.')
                            answers[question.qid] = state.answers?.prefills?.[key] || question.default.value
                        }
                    } else if (c.action === 'set_options') {
                        let new_options = prepareFlatOptions(c.options.split(','))
                        question.options = new_options
                        //set to default if current value is out of range
                        if (!some(new_options, {text: answers[question.qid]})) answers[question.qid] = c.value
                    } else if (c.action === 'hide') question.hidden = true
                    else if (c.action === 'show') question.hidden = false
                    else if (c.action === 'hide&notrequire') {
                        question.hidden = true
                        question.required = false
                    } else if (c.action === 'show&require') {
                        question.hidden = false
                        question.required = true
                    } else if (c.action === 'disable&notrequire') {
                        question.disabled = true
                        question.required = false
                    } else if (c.action === 'enable&require') {
                        question.disabled = false
                        question.required = true
                    } else if (c.action === 'notrequire') {
                        question.required = false
                    } else if (c.action === 'require') {
                        question.required = true
                    }
                }
            } else { // TODO: what is this date check?  We need to get rid of this CORE-2999
                if (force_update || c?.force_check || answers[question.qid] !== state.answers[question.qid]) {
                    //Perform unconditional actions
                    if (c.action === 'add_1_year') {
                        let value = moment(answers[question.qid])
                        value = value.add(1, 'year') || ''

                        answers[c.key] = value
                    } else if (c.action === 'adjust_date_frames') {

                        let value = moment(answers[question.qid]).set({
                            hours: 12,
                            minutes: 1,
                            seconds: 0,
                        })

                        if (c?.min) {
                            let fallback = (typeof c.min.fallback === 'string')
                                ? getDateFromString(c.min.fallback)
                                : getDateFromConfig(false, c.min.fallback.amount, c.min.fallback.units)

                            let min_value = fallback

                            if (c.min.key) {
                                let key_value = moment(answers[c.min.key]).set({
                                    hours: 12,
                                    minutes: 1,
                                    seconds: 0,
                                })

                                if (key_value.isValid() && key_value.diff(fallback, 'days') > 0) {
                                    min_value = key_value
                                }
                            }

                            question.min_value = min_value
                            if (value.isValid() && value.diff(min_value, 'days') < 0) answers[question.qid] = min_value
                        }

                        if (c?.max) {
                            let fallback = (typeof c.max.fallback === 'string')
                                ? getDateFromString(c.max.fallback)
                                : getDateFromConfig(true, c.max.fallback.amount, c.max.fallback.units)

                            let max_value = fallback

                            if (c.max.key) {
                                let key_value = moment(answers[c.max.key]).set({
                                    hours: 12,
                                    minutes: 1,
                                    seconds: 0,
                                })

                                if (key_value.isValid() && key_value.diff(fallback, 'days') > 0) {
                                    max_value = key_value
                                }
                            }

                            question.max_value = max_value
                            if (value.isValid() && value.diff(max_value, 'days') > 0) answers[question.qid] = max_value
                        }
                    }

                }
            }
        })

        return question
    })
    const data = parseComplexDataObjects(answers)

    let subsections = state.subsections ? [...state.subsections] : []

    if (state.answers['business.structure'] !== answers['business.structure'] && !state.restore_session) {
        subsections = subsections.map(itm => {
            if (itm.path === '/owners-and-officers') itm.skipped = shouldSkipSection(state, answers['business.structure'])
            return itm
        })
        data.wc_locations.forEach(loc => {
            loc.owners = []
        })
        Object.keys(answers).forEach(key => {
            if ((/premises\.\d\.owners\.\d\./).test(key)) {
                delete answers[key]
            }
        })
    }

    let response = {
        ...state,
        answers: answers,
        subsections_questions: new_questions,
        subsections: subsections,
        ...data
    }

    if ((state.is_from_bulk || state.is_session_restored)
        && Object.keys(state.session_answers).length > 0
        && !state.is_answers_changed) {

        const compare_keys = Object.keys(payload)
        const is_answers_changed = isAnswersNotEqual(state.session_answers, answers, compare_keys)

        if (is_answers_changed) {
            response.is_answers_changed = is_answers_changed
            response.answers['session_saved'] = false
            response.answers['bulk'] = {
                'term_premium': null,
                'type': []
            }
        }
    }

    return response
}

function checkIfAnswersDiff(key, answers, state) {
    if (Array.isArray(key)) {
        let changed = false

        key.forEach(k => {
            if (answers[k] !== state.answers[k]) changed = true
        })

        return changed

    } else return answers[key] !== state.answers[key]
}

function getDateFromString(str) {
    switch (str) {
        case 'tomorrow':
            return moment().add(1, 'days').set({
                hours: 12,
                minutes: 1,
                seconds: 0,
            })
        default: //today
            return moment().set({
                hours: 12,
                minutes: 1,
                seconds: 0,
            })
    }
}

function parseUrl(url) {
    const urlRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
    const protocols = ['https', 'http']

    if (protocols.some(protocol => url.includes(protocol))) {
        const domain = new URL(url).hostname
        if (!urlRegex.test(domain)) {
            throw new Error('invalid url or domain')
        } else return domain
    } else if (urlRegex.test(url)) {
        return url
    } else throw new Error('invalid url or domain')
}

function getDateFromConfig(add = true, amount, units) {
    let today = moment().set({
        hours: 12,
        minutes: 1,
        seconds: 0,
    })

    return add
        ? today.add(amount, units)
        : today.subtract(amount, units)
}

function shouldSkipSection(state, search) {
    return !some(state.legal_entities, {description: search})
}

function processIndustryClasses(state, action, key = 'industry_classes') {
    let classes = []

    action.payload.forEach(itm => {
        if (Array.isArray(itm.display_description)) {
            itm.display_description.forEach((d, idx) => {
                classes.push({
                    id: itm.id + idx,
                    value: itm.id + idx,
                    text: d.trim(),
                    level: itm.level,
                    naics_code: itm.naics_code,
                    related_google_tag: itm.related_google_tag
                })
            })
        } else {
            classes.push({
                id: itm.id,
                value: itm.id,
                text: itm.display_description,
                level: itm.level,
                naics_code: itm.naics_code,
                related_google_tag: itm.related_google_tag
            })
        }
    })

    classes = sortBy(classes, 'text')

    return {
        ...state,
        [key]: classes
    }
}

function sortProducts(a, b) {
    if (a.carrier_id < b.carrier_id) return -1
    if (a.carrier_id > b.carrier_id) return 1
    return 0
}

export function preProcessProducts(state, products) {
    products = products.map(p => {
        let pd = products_data.filter(d => d.lob === p.type)

        p.lob = pd?.[0]?.lob || p.type
        p.carrier_id = pd?.[0]?.carrier_id
        p.name = pd?.[0]?.name || ''
        p.subName = pd?.[0]?.subName || null
        p.icon = pd?.[0]?.icon || null
        p.hint = pd?.[0]?.hint || null
        p.isDisabled = pd?.[0]?.isDisabled
        p.description = pd?.[0]?.description || ''
        p.bulletPoints = pd?.[0]?.bulletPoints || []

        return p
    }).sort(sortProducts)

    return {
        ...state,
        coverage_types: products,
        filteredCoverageTypes: products
    }
}

function placeDefaultAnswers(state) {
    let __answers = {...state.answers}

    for (let itm of state.raw_questions) {
        if (itm.default && itm.default.value !== undefined && !__answers[itm.qid]) __answers[itm.qid] = itm.default.value
        if ([undefined, null].includes(__answers[itm.qid])) __answers[itm.qid] = ''
    }

    return {
        ...state,
        answers: {...__answers}
    }
}

function getDiffFromAnswersKey(a1, a2, keys) {
    let diff = 0

    const check = [
        'b2z_naics_code',
        'b2z_naics_code_id',
        'business.city',
        'business.naics_code',
        'business.name',
        'business.start_date',
        'business.state',
        'business.street_address',
        'business.street_address2',
        'business.structure',
        'business.zip_code',
        'coverage.types',
        'cyber.coverage.is_selected',
        'eb.coverage.is_selected',
        'cyber.coverage.aggregate_limit',
        'cyber.coverage.deductible',
        'hnoa.coverage.is_selected',
        'policy.building_limit',
        'policy.damage_to_premises_rented_to_you',
        'policy.medical_limit',
        'policy.occurrence_limit',
        'policy.other_business_ventures_exist',
        'policy.total_bpp_limit',
        'policy.tria_accepted',
        'premises.area_occupied_sf',
        'premises.building_limit',
        'premises.city',
        'premises.county',
        'premises.estimated_sales_at_location',
        'premises.full_time_employees',
        'premises.is_property_owned',
        'premises.major_classification',
        'premises.naics_code',
        'premises.part_time_employees',
        'premises.state',
        'premises.street_address',
        'premises.sub_classification',
        'premises.zip',
    ]

    for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        if (check.includes(key)) {
            if (a1.hasOwnProperty(key) && a2.hasOwnProperty(key)) {
                if (key === 'business.start_date') {
                    if (!moment(a1[key]).startOf('day').isSame(moment(a2[key]).startOf('day'))) {
                        diff += 1
                    }
                } else if (!isEqual(a1[key], a2[key])) {
                    diff += 1
                }
            }
        }
    }

    return diff
}

function isAnswersNotEqual(a1, a2, keys) {
    let diff = getDiffFromAnswersKey(a1, a2, keys)

    return diff > 0
}

const throttled = throttle(postAnswers, 5000)


function checkForUnderwriterStatements(payload, state) {
    /**
     * Function intended to handle state logic based on if underwriter statements are present or not.
     * 1. if underwriter statements are not present, then the question uw.acknowledge_underwriter_statements
     *    is hidden from view and the subsection /underwriter-statements is removed from the application state
     *    to prevent displaying a blank page.
     * 2. if underwriter statements are present, then the question uw.acknowledge_underwriter_statements is
     *    enabled and the subsection /underwriter-statements is left in application state to ensure the question
     *    and statements display.
     *
     * When the question uw.acknowledge_underwriter_statements is enabled, the UI will display a list of
     * underwriter statements and require the customer to acknowledge them through the question
     * uw.acknowledge_underwriter_statements before proceeding.
     * @type {*[]}
     */
    const questions = state.subsections_questions || []
    const unfilteredSubsections = state.subsections
    const filteredSubsections = state.subsections.filter(s => s.path !== '/underwriter-statements')
    const filteredQuestions = questions.filter(q => q.qid !== 'uw.acknowledge_underwriter_statements')
    let uwQuestion = questions.filter(q => q.qid === 'uw.acknowledge_underwriter_statements')[0]


    let newState = {}
    let newQuestions = questions

    if (payload.statementsList !== 'error' && payload.statementsList?.length > 0) {
        if (uwQuestion) {
            uwQuestion = {...uwQuestion, hidden: false, required: true, disabled: false}

            let mergedQuestions = {
                ...filteredQuestions,
                ...[uwQuestion]
            }

            newQuestions = Object.values(mergedQuestions)

            newState = {
                ...state,
                subsections: unfilteredSubsections,
                subsections_questions: newQuestions,
                underwriterStatements: payload
            }

        }
    } else if (payload.statementsList !== 'error' && payload.statementsList?.length === 0) {
        newState = {
            ...state,
            subsections: filteredSubsections,
            subsections_questions: filteredQuestions,
            underwriterStatements: undefined
        }
    } else {
        newState = {
            ...state,
            subsections: unfilteredSubsections,
            subsections_questions: questions,
            underwriterStatements: undefined
        }
    }

    return newState
}


// TODO: DO NOT Delete this... we will be using it in V2 implementation of additional insured.
function checkForAdditionalInsured(state) {
    /**
     * Function intended to handle state logic based on if question business.additional_insured_is_selected is answered
     * as `YES`.
     * 1. if GL coverage type is selected AND business.additional_insured_is_selected is answered as `YES`, then the
     *    subsection /additional-insured subsection is displayed with all additional insured questions nested in
     *    core/carriers/{carrier-name}/questions/03-Account Info/11-Additional Insured/
     * 2. if GL coverage type is selected AND business.additional_insured_is_selected is answered as `NO`, then the
     *    above questions are marked as hidden and not required. The subsection /additional-insured subsection is also
     *    removed from the application state to prevent displaying a blank page.
     * @type {*[]}
     */

    const questions = state.subsections_questions_data || []
    const unfilteredQuestions = state.subsections_questions_data
    const unfilteredSubsections = state.subsections_data
    const filteredSubsections = state.subsections_data.filter(s => s.path !== '/additional-insured')
    const sections = state.sections

    sections.sort((a, b) => (a.section_index > b.section_index) ? 1 : ((b.section_index > a.section_index) ? -1 : 0))

    let filteredQuestions = unfilteredQuestions.filter(
        q => !q['qid'].includes('business.additional_insured.') && !q['qid'].includes('business.additional_insured_list')
    )

    const additionalInsuredQuestions = unfilteredQuestions.filter(
        q => (q['qid'].includes('business.additional_insured.') || q['qid'].includes('business.additional_insured_list'))
    )

    let newState = {}

    if (additionalInsuredQuestions.length > 0 && state.answers['business.additional_insured_is_selected'] === 'Yes') {

        const changedAdditionalInsuredQuestions = additionalInsuredQuestions.map(q => {
            return {
                [q['qid']]: {
                    ...q,
                    hidden: false,
                    required: true,
                    disabled: false
                }
            }
        })

        filteredQuestions = filteredQuestions.map(q => {
            return {
                [q['qid']]: {
                    ...q
                }
            }
        })

        let mergedQuestions = {
            ...Object.assign({}, ...filteredQuestions),
            ...Object.assign({}, ...changedAdditionalInsuredQuestions)
        }

        const newQuestions = Object.values(mergedQuestions)

        unfilteredSubsections.sort((a, b) => {
            return a.section_index - b.section_index || a.subsection_index - b.subsection_index
        })


        newState = {
            ...state,
            subsections: unfilteredSubsections,
            subsections_questions: newQuestions
        }
    } else if (additionalInsuredQuestions.length > 0 && state.answers['business.additional_insured_is_selected'] === 'No') {
        filteredSubsections.sort((a, b) => {
            return a.section_index - b.section_index || a.subsection_index - b.subsection_index
        })
        newState = {
            ...state,
            subsections: filteredSubsections,
            subsections_questions: filteredQuestions
        }
    } else {
        filteredSubsections.sort((a, b) => {
            return a.section_index - b.section_index || a.subsection_index - b.subsection_index
        })

        newState = {
            ...state,
            subsections_data: filteredSubsections,
            subsections_questions: questions
        }
    }

    return newState
}


export const insuranceReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_STORE_KEY:
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        case SET_NEXT_SECTION_BUTTON_TEXT:
            return {
                ...state,
                next_section_button_text: action.payload
            }
        case SET_ANSWERS:
            return setAnswers(state, action)
        case CHANGE_ANSWERS:
            const data = checkQuestionConditions(action.payload, state)

            throttled(state, data.answers)

            return {
                ...state,
                ...data
            }
        case UPDATE_INDUSTRY_CLASSES:
            return processIndustryClasses(state, action)
        case UPDATE_WC_INDUSTRY_CLASSES:
            return processIndustryClasses(state, action, 'wc_industry_classes')
        case UPDATE_SECTIONS:
            return getSectionsForProducts(state, action)
        case UPDATE_QUESTIONS:
            return prepareQuestionsPayload(state, action)
        case UPDATE_QUESTIONS_CLASS_STATE_BASED:

            return updateQuestionsClassStatePayload(state, action)
        case UPDATE_PROGRESS:
            action = calculateProgress(state, action)

            return {
                ...state,
                ...action.payload,
            }
        case UPDATE_QUESTIONS_AUTOFILL:
            return updateQuestionsAutofill(state, action.payload.answers, !!action.payload.mark,
                action.payload.fill_values, action.payload.filterBySubsection)
        case PLACE_DEFAULTS_ANSWERS:
            return placeDefaultAnswers(state)
        case SET_RESTORE_SESSION:
            return {
                ...state,
                restore_session: action.payload,
                is_session_restored: action.payload || state.is_session_restored
            }
        case UPDATE_WC_LOCATIONS:
            return prepareAnswersFromDataObjects(state, action.payload, 'wc_locations', 'premises')
        case UPDATE_WC_ENDORSEMENT:
            return {
                ...state,
                WCOwnerEndorsement: action.payload || state.WCOwnerEndorsement
            }
        case UPDATE_WC_UW_QUESTIONS:
            let wc_uw_questions = updateWCUWQuestions(state, action.payload)

            return {
                ...state,
                subsections_questions: wc_uw_questions,
            }
        case UPDATE_UW_QUESTIONS:
          let uw_questions = updateUWQuestions(state, action.payload)

          return {
            ...state,
            subsections_questions: uw_questions,
          }
        case UPDATE_APP_PRODUCTS:
            return preProcessProducts(state, action.payload)
        case UPDATE_AOI_DESIGNATED_PERSON:
            return prepareAnswersFromDataObjects(state, action.payload, 'designated_person', 'aoi.designated_person')
        case UPDATE_AOI_MANAGERS_OR_LESSORS_PREMISES:
            return prepareAnswersFromDataObjects(state, action.payload, 'managers_or_lessors_premises', 'aoi.managers_or_lessors_premises')
        case UPDATE_AOI_MORTGAGEE_OR_RECEIVER:
            return prepareAnswersFromDataObjects(state, action.payload, 'mortgagee_or_receiver', 'aoi.mortgagee_or_receiver')
        case UPDATE_AOI_MORTGAGE_HOLDER_INFORMATION:
            return prepareAnswersFromDataObjects(state, action.payload, 'mortgageholder_information', 'aoi.mortgageholder_information')
        case UPDATE_AOI_LOSS_PAYABLE_CLAUSES:
            return prepareAnswersFromDataObjects(state, action.payload, 'loss_payable_clauses', 'aoi.loss_payable_clauses')
        case UPDATE_AOI_WTRR_AGAINST_OTHERS:
            return prepareAnswersFromDataObjects(state, action.payload, 'wtrr_against_others', 'aoi.wtrr_against_others')
        case UPDATE_BUSINESS_OWNERS:
            return prepareAnswersFromDataObjects(state, action.payload, 'business_owners', 'business.owners_list')
        case UPDATE_ADDITIONAL_INSUREDS:
            return prepareAnswersFromDataObjects(state, action.payload, 'business_additional_insured', 'business.additional_insured_list')
        case UPDATE_UNDERWRITER_STATEMENTS:
            return checkForUnderwriterStatements(action.payload, state)
        case UPDATE_ADDITIONAL_INSURED_QUESTIONS:
            return checkForAdditionalInsured(state)
        default:
            return state

    }
}