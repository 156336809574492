import React from "react"
import BusinessAdditionalInsured from './question/custom/BusinessAdditionalInsured';
import CyberOptionalCoverageQuestion from './question/custom/CyberOptionalCoverageQuestion'
import MultipleAddInsuredQuestionSet from './question/custom/MultipleAddInsuredQuestionSet';
import Fein from "./question/Fein";
import Year from "./question/Year";
import TextField from "./question/Text";
import DateField from "./question/Date";
import EmailField from "./question/Email";
import PhoneField from "./question/Phone";
import Checkbox from "./question/Checkbox";
import Password from "./question/Password";
import NumberField from "./question/Number";
import Typeahead from "./question/Typeahead";
import BooleanField from "./question/Boolean";
import Percentage from "./question/Percentage";
import DateTimeField from "./question/DateTime";
import CurrencyField from "./question/Currency";
import CustomBoolean from "./CustomBoolean";
import DropdownField from "./question/Dropdown";
import CardsSelect from "./question/CardsSelect";
import ZipField from './question/Zip'
import {QuestionTooltip} from "./QuestionTooltip";
import CreditCardExp from "./question/CreditCardExp";
import CreditCardCVC from "./question/CreditCardCVC";
import StyledBoolean from "./question/StyledBoolean";
import ValidatedEmail from "./question/ValidatedEmail";
import MultipleText from "./question/custom/MultipleText";
import CreditCardNumber from "./question/CreditCardNumber";
import CurrencyDropdown from "./question/CurrencyDropdown";
import AddressAutocomplete from "./question/AddressAutocomplete";
import LossPayableClauses from "./question/custom/LossPayableClauses";
import CheckBoxQuestionSet from "./question/custom/CheckBoxQuestionSet";
import MultipleQuestionSet from "./question/custom/MultipleQuestionSet";
import BusinessNameAndAddress from "./question/custom/BusinessNameAndAddress";
import Limit from "./question/Limit";
import OptionalCoverageQuestion from "./question/custom/OptionalCoverageQuestion";
import BillingBoolean from "./question/BillingBoolean";
import MultipleOwnerQuestionSet from "./question/custom/MultipleOwnerQuestionSet";
import UnselectableLimit from "./question/UnselectableLimit";
import Chat from "./question/Chat"
import XsCheckbox from "./question/xsCheckbox";
import LinkedLimit from "./question/LinkedLimit";
import URLInput from "./question/URLInput";


class Question extends React.Component {

    state = {
        id: null,
    }

    constructor(props) {
        super(props);

        this.state.id = (this.props.id === undefined) ? Date.now().toString() : this.props.id
    }


    getTooltip() {
        return (<QuestionTooltip text={this.props.tooltip}/>)
    }

    render() {
        let type = this.props?.type?.toLowerCase()

        let tooltip = this.props.tooltip ? this.getTooltip() : null
        //cast simple dropdown with 2 options to boolean
        if (type === 'dropdown' && this.props.options.length === 2) type = 'boolean'

        if (!!this.props.disabled || !!this.props.hidden) return null

        let question = this.props.required && type !== 'oc' && type !=='multipleownerquestionset' && type !=='multipleaddinsuredquestionset' ? this.props.question + '*' : this.props.question

        switch (type) {
            case 'select':
                return (<DropdownField {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'dropdown':
                return (<DropdownField {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'autocomplete':
                return (<Typeahead {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'typeahead':
                return (<Typeahead {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'number':
                return (<NumberField {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'currency':
                return (<CurrencyField {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'currencydropdown':
                return (<CurrencyDropdown {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'percentage':
                return (<Percentage {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'email':
                return (<EmailField {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'validatedemail':
                return (<ValidatedEmail {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'phone':
                return (<PhoneField {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'date':
                return (<DateField {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'datetime':
                return (<DateTimeField {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'year':
                return (<Year {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'boolean':
                return (<BooleanField {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'xscheckbox':
                return (<XsCheckbox {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'billingboolean':
                return (<BillingBoolean {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'creditcardnumber':
                return (<CreditCardNumber {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'creditcardexp':
                return (<CreditCardExp {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'creditcardcvc':
                return (<CreditCardCVC {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'customboolean':
                return (<CustomBoolean {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'password':
                return (<Password {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'checkbox':
                return (<Checkbox {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'cardselect':
                return (<CardsSelect {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'fein':
                return (<Fein {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'businessnameandaddress':
                return (<BusinessNameAndAddress {...this.props} question={question} tooltip={tooltip} diya_helper={this.props.diya_helper}
                                                id={this.state.id}/>)
            case 'losspayableclauses':
                return (<LossPayableClauses {...this.props} question={question} tooltip={tooltip} diya_helper={this.props.diya_helper}
                                            id={this.state.id}/>)
            case 'multipletext':
                return (<MultipleText {...this.props} question={question} tooltip={tooltip} diya_helper={this.props.diya_helper}
                                      id={this.state.id}/>)
            case 'addressautocomplete':
                return (<AddressAutocomplete {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'styledboolean':
                return (<StyledBoolean {...this.props} question={question} tooltip={tooltip} diya_helper={this.props.diya_helper}
                                       id={this.state.id}/>)
            case 'checkboxquestionset':
                return (<CheckBoxQuestionSet {...this.props} question={question} tooltip={tooltip} diya_helper={this.props.diya_helper}
                                             id={this.state.id}/>)
            case 'multiplequestionset':
                return (<MultipleQuestionSet {...this.props} question={question} tooltip={tooltip} diya_helper={this.props.diya_helper}
                                             id={this.state.id}/>)

            case 'multipleownerquestionset':
                return (<MultipleOwnerQuestionSet {...this.props} question={question} tooltip={tooltip} diya_helper={this.props.diya_helper}
                                                  id={this.state.id}/>)
            case 'multipleaddinsuredquestionset':
                return (<MultipleAddInsuredQuestionSet {...this.props} question={question} tooltip={tooltip} diya_helper={this.props.diya_helper}
                                                  id={this.state.id}/>)
            case 'businessadditionalinsured':
                return (<BusinessAdditionalInsured {...this.props} question={question} tooltip={tooltip} diya_helper={this.props.diya_helper}
                                                       id={this.state.id}/>)
            case 'limit':
                return (<Limit {...this.props} id={this.state.id} question={question} coverageType={this.props.coverageType}/>)
            case 'linkedlimit':
                return (<LinkedLimit {...this.props} id={this.state.id} question={question} coverageType={this.props.coverageType}/>)
            case 'unselectablelimit':
                return (<UnselectableLimit {...this.props} question={question} id={this.state.id}/>)
            case 'oc':
                return (<OptionalCoverageQuestion {...this.props} question={question} id={this.state.id}/>)
            case 'cyberoc':
                return (<CyberOptionalCoverageQuestion {...this.props} question={question} id={this.state.id}/>)
            case 'chat':
                return (<Chat {...this.props} question={question} tooltip={tooltip} id={this.state.id}/>)
            case 'textitem':
                return (<TextField {...this.props} question={question} tooltip={tooltip} id={this.state.id} />)
           case 'zip':
                return (<ZipField {...this.props} question={question} tooltip={tooltip} id={this.state.id} />)
            case 'url':
                return (<URLInput props={this.props}
                                  id={this.state.id}
                                  fullWidth={this.props.fullWidth}
                                  tooltip={tooltip}
                                  autofilled={this.props.autoComplete}
                    />
                )
            default:
                return (<TextField {...this.props} hidden={false} question={question} tooltip={tooltip} id={this.state.id}/>)
        }
    }

}



//export default Question
export default Question
