import React from "react";


const GreenCheckmark = (props) => {

    return (
        <div className={props.className} style={props.style}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <circle cx="14" cy="14" r="14" fill="#3FAA6C"/>
                <path d="M19.5999 11.2L11.8999 18.8999L8.3999 15.4" stroke="white" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )
}

export default GreenCheckmark