import React from "react";
import NumberFormat from 'react-number-format'
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, Grid, TextField, Typography} from "@material-ui/core";

function CardNumberFormat(props) {
    return (
        <NumberFormat
            className={props.className}
            value={props.value}
            getInputRef={props.inputRef}
            onValueChange={(values) => {
                props.onChange(values)
            }}
            mask="_"
            allowEmptyFormatting
            format="#### #### #### ####"
            placeholder="#### #### #### ####"
        />
    )
}

export default class CreditCardNumber extends React.Component {

    onChange(values) {
        if (this.props.onChange) this.props.onChange(this.props.id, values.floatValue)
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth} error={this.props.error}>
                <Grid container spacing={2} justifyContent="space-between" wrap="nowrap">
                    <Grid item><Typography variant="caption">{this.props.question}&nbsp;{this.props.tooltip}</Typography></Grid>
                    {this.props.autofilled && <Grid item><AutofilledBadge/></Grid>}
                </Grid>
                <TextField
                    variant="outlined"
                    type="text"
                    value={this.props.answer}
                    error={this.props.error}
                    InputProps={{
                        inputComponent: CardNumberFormat,
                    }}
                    onChange={(values) => this.onChange(values)}
                />
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}