import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    useTheme
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import { ModalTransition } from '../../common/ModalTransition'

//scss
import '../../scss/v2/modals/_whats_covered.scss'

const WhatsCoveredPL = ({ t }) => {

    const [show, setShow] = useState(false)
    const [iconUrl] = useState('https://b2z-public-images.s3.amazonaws.com/acrisure/icons/')

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const onOpenModal = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setShow(true)
    }

    const onCloseModal = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setShow(false)
    }
    return (
        <>
            <div className="show_whats_covered_modal_btn"
                onClick={(e) => onOpenModal(e)}>{t('modal.cover_bop.btn')}</div>
            <Dialog open={show}
                onClick={(e) => {
                    e.stopPropagation()
                }}
                container={() => document.getElementById('themeAppComponent')}
                className={'modal__wrapper whats_covered_modal__wrapper'}
                fullScreen={fullScreen}
                scroll={'paper'}
                fullWidth
                maxWidth="sm"
                onClose={(e) => onCloseModal(e)}
                TransitionComponent={ModalTransition}>
                <DialogActions className={'modal_head'}>
                    <button className={'modal_close_btn show_scroll_paper'}
                        type={'button'}
                        onClick={(e) => onCloseModal(e)}><Close/>
                        <span>{t('common.btn_close')}</span></button>
                </DialogActions>
                <DialogContent className={'modal_body'}>
                    <div className={'whats_covered_modal_cnt'}>
                        <div className={'txt_cnt'}>
                            <div className="covered_header">
                                <p className="covered_ttl">{t('modal.cover_mpl.title')}</p>
                                <p className="covered_txt">{t('modal.cover_mpl.txt')}</p>
                            </div>
                            <div className={'covered_body'}>
                                <p className="covered_sub_ttl">{t('modal.cover_mpl.question')}</p>
                                <div className="covered_card">
                                    <p
                                        className="card_ttl">{t('modal.cover_mpl.cfp.title')}</p>
                                    <p className="card_txt">{t('modal.cover_mpl.cfp.txt')}</p>
                                    <ul className="card_list">
                                        {t('modal.cover_mpl.cfp.list', { returnObjects: true }).map(({
                                            icon,
                                            txt
                                        }, idx) => (
                                            <li className="card_list_item" key={idx}>
                                                <i className="list_item_icon">
                                                    <img src={`${iconUrl}${icon}`} alt={txt}/></i>
                                                {txt}
                                            </li>))}
                                    </ul>
                                </div>


                                <p className="covered_sub_ttl">{t('modal.not_cover_mpl.question')}</p>
                                <div className="covered_card">
                                    <p
                                        className="card_txt">{t('modal.not_cover_mpl.txt')}</p>
                                    <ul className="card_list">
                                        {t('modal.not_cover_mpl.list',
                                            { returnObjects: true }).map(({ icon, txt, tip }, idx) => (
                                            <li className="card_list_item" key={idx}>
                                                <i className="list_item_icon">
                                                    <img src={`${iconUrl}${icon}`} alt={txt}/></i>
                                                <span className="list_item_tip">
                                                    <span className="txt">{txt}</span>
                                                    <span className="tip">{tip}</span>
                                                </span>
                                            </li>))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default withTranslation()(WhatsCoveredPL)
