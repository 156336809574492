import clsx from 'clsx';
import React, {useReducer} from "react";
import Question from "./Question";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import {changeAnswers} from "../../store/insurance/actions";
import {FormControl, Grid} from "@material-ui/core";
import {Chevron} from "../icons/Chevron";

const reducer = (data, action) => {
    switch (action.type) {
        case 'UPDATE':
            return {...action.data.prevState, [action.data.label]: action.data.nextState}
        case 'USE_DEFAULT':
            return !action.data
        default:
            break
    }
}

const billingAddress = {
    'billing.street_address': '',
    'billing.street_address2': '',
    'billing.city': '',
    'billing.state': '',
    'billing.zip_code': ''
}

const BillingAddress = React.memo((props) => {
    const {t, states} = props

    const [state, dispatch] = useReducer(reducer, billingAddress)

    const changeHandler = (label, e) => {
        switch (label) {
            case 'use_default':
                return props.switchAddress(props.use_default_address)
            default:
                props.onChange(label, e)
                dispatch({type: 'UPDATE', data: {prevState: state, label: label, nextState: e}})
        }
    }

    let classes = clsx({
        'xsCheckbox': true,
        'checked': props.use_default_address
    })
    return (<>
            <p className="checkout_ttl">{t('form.label.billing_address')}</p>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormControl fullWidth className={'billing_address_placeholder'}>
                        <label htmlFor={'switch_use_default'} className={classes}>
                                <span className="checkbox_blk">
                                    <input type="checkbox" id={'switch_use_default'}
                                           onChange={(e) => {
                                               changeHandler('use_default')
                                           }}
                                           checked={props.use_default_address}/>
                                </span>
                            <p>{t("form.placeholder.billing_address_placeholder")}</p>

                        </label>
                    </FormControl>
                </Grid>
                <Grid item xs={12} className="address-fields">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Question fullWidth
                                      type="text"
                                      question={t('page.checkout.billing_address.street')}
                                      answer={props.use_default_address
                                          ? props.answers['business.street_address']
                                          : props.answers['billing.street_address']}
                                      variant='outlined'
                                      error={!!props.errors["billing.street_address"]}
                                      helperText={props.errors?.["billing.street_address"]}
                                      placeholder={t('page.checkout.billing_address.street_placeholder')}
                                      onChange={(id, value) => {
                                          changeHandler(props.use_default_address
                                              ? 'business.street_address'
                                              : 'billing.street_address', value)
                                      }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Question fullWidth
                                      type="text"
                                      question={t('page.checkout.billing_address.apt')}
                                      answer={props.use_default_address
                                          ? props.answers['business.street_address2']
                                          : props.answers['billing.street_address2']}
                                      variant="outlined"
                                      placeholder={t('page.checkout.billing_address.apt_placeholder')}
                                      onChange={(id, value) => {
                                          changeHandler(props.use_default_address
                                              ? 'business.street_address2'
                                              : 'billing.street_address2', value)
                                      }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Question fullWidth
                                      type="text"
                                      question={t('page.checkout.billing_address.city')}
                                      answer={props.use_default_address
                                          ? props.answers['business.city']
                                          : props.answers['billing.city']}
                                      variant="outlined"
                                      error={!!props.errors["billing.city"]}
                                      placeholder={t('page.checkout.billing_address.city_placeholder')}
                                      helperText={props.errors?.["billing.city"]}
                                      onChange={(id, value) => {
                                          changeHandler(props.use_default_address
                                          ? 'business.city'
                                          : 'billing.city', value)
                                      }}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}
                              className={!!props.answers["billing.state"] ? "" : "states-dropdown"}>
                            <Question fullWidth
                                      type="dropdown"
                                      question={t('page.checkout.billing_address.state')}
                                      answer={props.use_default_address ? props.answers['business.state'] : props.answers['billing.state']}
                                      variant="outlined"
                                      options={states.options || []}
                                      error={!!props.errors["billing.state"]}
                                      placeholder={t('page.checkout.billing_address.state_placeholder')}
                                      helperText={props.errors?.["billing.state"]}
                                      onChange={(id, value) => {
                                          changeHandler(props.use_default_address
                                          ? "business.state"
                                          : "billing.state", value)
                                      }}
                                      IconComponent={Chevron}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Question fullWidth
                                      type="zip"
                                      question={t('page.checkout.billing_address.zip')}
                                      answer={props.use_default_address
                                          ? props.answers['business.zip_code']
                                          : props.answers['billing.zip_code']}
                                      variant="outlined"
                                      error={!!props.errors["billing.zip_code"]}
                                      placeholder={t('page.checkout.billing_address.zip_placeholder')}
                                      helperText={props.errors?.["billing.zip_code"]}
                                      onChange={(id, value) => {
                                          changeHandler(props.use_default_address
                                          ? 'business.zip_code'
                                          : 'billing.zip_code', value)
                                      }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
})

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
        subsections_questions: state.insurance.subsections_questions,
    }
}

const mapDispatchToProps = {
    changeAnswers,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingAddress))
