import React, {useEffect, useState} from "react";

export const DiyaRounded = function () {
    const [path, setPath] = useState('');

    useEffect(() => {
        setPath('https://b2z-public-images.s3.amazonaws.com/icons/diya_logo_v2.svg')
    }, []);

    return (
        <img src={path} alt="diya"/>
    );
}