import React from "react";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {FormControl, Grid, IconButton, InputAdornment, TextField} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export default class Password extends React.Component {

    state = {
        show: false
    }

    showPassword() {
        this.setState({show: !this.state.show})
    }

    onChange(e) {
        const value = e.target.value

        if (this.props.onChange) this.props.onChange(this.props.id, value)
    }

    onBlur() {
      if (this.props.onBlur) this.props.onBlur()
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth}
                         className={'form_control'}
                         error={this.props.error}>
                <div className="label_blk">
                    <div className="label">{this.props.question} &nbsp;{this.props.tooltip} </div>
                </div>
                <TextField
                    className={'fieldText'}
                    variant="outlined"
                    type={this.state.show ? 'text' : 'password'}
                    value={this.props.answer}
                    error={!!this.props.error}
                    autoComplete={this.props.autoComplete}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {this.showPassword()}}
                                edge="end"
                            >
                                {this.state.show ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    onBlur={() => this.onBlur()}
                    onChange={(e) => this.onChange(e)}
                />
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
                {this.props.passwordHint && this.props.showPasswordHint && <Grid item xs={12}>
                    <ul className={'password_hint'}>
                        {Object.keys(this.props.passwordHint).map((item, i) => (
                            <li key={i}
                                className={`hint ${this.props.passwordHint[item].error ? 'error' : 'complete'}`}>
                                {this.props.passwordHint[item].error ? <CancelIcon/> :
                                    <CheckCircleIcon/>}
                                <span>{this.props.passwordHint[item].txt}</span>
                            </li>
                        ))}
                    </ul>
                </Grid>}
            </FormControl>
        )
    }
}