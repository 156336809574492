import {UPDATE_APP_STORE_KEY} from "./actions";

const initialState = {
    google_api_key: null,
    site: 'https://b2z-insurance.com'
}

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_APP_STORE_KEY:
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        default:
            return state
    }
}
