import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {Helmet} from 'react-helmet'
import { KeysIcon } from './icons/KeysIcon'
import { HouseIcon } from './icons/HouseIcon'
import { DocumentKeyIcon } from './icons/DocumentKeyIcon'
import { ClientSiteIcon } from './icons/ClientSiteIcon'
import { FurnitureIcon } from './icons/FurnitureIcon'
import { ComputerEquipmentIcon } from './icons/ComputerEquipmentIcon'
import { ToolsMachinesIcon } from './icons/ToolsMachinesIcon'
import { CompanyOwnedIcon } from './icons/CompanyOwnedIcon'
import { EmployeeOwnedIcon } from './icons/EmployeeOwnedIcon'
import { NoneIcon } from './icons/NoneIcon'
import { YesIcon } from './icons/YesIcon'
import { SingleEmployeeIcon } from './icons/SingleEmployeeIcon'
import { MoreEmployeesIcon } from './icons/MoreEmployeesIcon'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import classnames from 'classnames'
import Question from './../../components/form-components/Question'
import Header from '../../layouts/insurance/Header'
import DoRequest from '../../hooks/do-request'
import { changeAnswers } from '../../store/insurance/actions'
import PolicyStartDate from '../../components/form-components/PolicyStartDate'
import DateTimeHelper from '../../helpers/dateTimeHelper'
import Footer from '../../layouts/insurance/Footer'
import '../../scss/v2/pages/_tune_up_quiz.scss'

interface IAnswer {
  id: string
  icon: React.ReactNode
  answer: React.ReactNode
  needsExclusivity: boolean
}

interface IQuestion {
  id: string
  question: string
  hint: string
  answers: IAnswer[]
}

interface ITrackingBar {
  steps: number
  current: number
}

interface IQuestionSlide extends IQuestion {
  onClick: (question: IQuestion, answer: IAnswer) => void
  onBack: () => void
  onNext: () => void
  isBackVisible: boolean
  isNextDisabled: boolean
  value: {
    [key: string]: boolean
  }
}

interface IAnswersContainer {
  [key: string]: {
    [subKey: string]: boolean
  }
}

const policyTypes = {
    GL: 'GL',
    BOP: 'BOP',
    WC: 'WC',
    PL: 'PL',
    CYBER: 'CYBER',
    AUTO: 'AUTO',
} as const

interface CoverageType {
    id: number
    not_sold_in: null
    sold_in: [string]
    type: keyof typeof policyTypes
    lob: keyof typeof policyTypes
    carrier_id: number
    name: string
    subName: null | string
    isDisabled: boolean
    description: string
}


const questions = [
    {
        id: 'business_location',
        question: 'Where does your business operate?',
        hint: 'Please select all answers that apply.',
        answers: [
            { 
                id: 'home',
                icon: <HouseIcon/>,
                answer: <div> From my <br/><u>home</u> </div>,
                needsExclusivity: false,
            },
            { 
                id: 'owned_office_or_storefront',
                icon: <KeysIcon/>,
                answer: <div> From an office or storefront <br/><u>I own</u> </div>,
                needsExclusivity: false,
            },
            { 
                id: 'rented_office_or_storefront',
                icon: <DocumentKeyIcon/>,
                answer: <div> From an office or storefront <br/><u>I rent</u> </div>,
                needsExclusivity: false,
            },
            { 
                id: 'client_sites',
                icon: <ClientSiteIcon/>,
                answer: <div> From <br/><u>client sites</u> </div>,
                needsExclusivity: false,
            }
        ]
    },
    {
        id: 'owned_or_rented_goods',
        question: 'Does your business own or rent any of the following?',
        hint: 'Please select all answers that apply.',
        answers: [
            {
                id: 'office_furniture',
                icon: <FurnitureIcon/>,
                answer: <div> Office <u>furniture</u> </div>,
                needsExclusivity: false,
            },
            { 
                id: 'computer_equipment',
                icon: <ComputerEquipmentIcon/>,
                answer: <div> Computer <u>equipment</u> </div>,
                needsExclusivity: false,
            },
            {
                id: 'tools_or_machines',
                icon: <ToolsMachinesIcon/>,
                answer: <div> <u>Tools</u> or <u>machines</u> </div>,
                needsExclusivity: false,
            },
            { 
                id: 'none',
                icon: <NoneIcon/>,
                answer: <div> <u>None</u> <br/> of these </div>,
                needsExclusivity: true,
            },
        ]
    },
    {
        id: 'vehicles_informations', 
        question: 'Tell me about the vehicles your business uses:',
        hint: 'Please select all answers that apply.',
        answers: [
            {
                id: 'owned_by_company',
                icon: <CompanyOwnedIcon/>,
                answer: <div> They are <u>company-owned</u> </div>,
                needsExclusivity: false,
            },
            { 
                id: 'owned_by_employee',
                icon: <EmployeeOwnedIcon/>,
                answer: <div> They are <u>employee-owned</u> </div>,
                needsExclusivity: false,
            },
            { 
                id: 'rented',
                icon: <DocumentKeyIcon/>,
                answer: <div> We <u>rent</u> vehicles </div>,
                needsExclusivity: false,
            },
            {
                id: 'no-vehicles',
                icon: <NoneIcon/>,
                answer: <div> My business <u> doesn't use </u> vehicles </div>,
                needsExclusivity: true,
            },
        ]
    },
    {
        id: 'sensitive_data',
        question: 'Does your business store data like credit card or personal customer info (PII)?',
        hint: 'Please choose one answer.',
        answers: [
            {
                id: 'yes',
                icon: <YesIcon/>,
                answer: <div> Yes </div>,
                needsExclusivity: true,
            },
            { 
                id: 'no',
                icon: <NoneIcon/>,
                answer: <div> No </div>,
                needsExclusivity: true,
            },
        ]
    },
    {
        id: 'business_cunsultant',
        question: 'Does your business provide expert advice and services?',
        hint: 'Services include consulting and if you are professionally licensed. Please choose one answer.',
        answers: [
            {
                id: 'yes',
                icon: <YesIcon/>,
                answer: <div> Yes </div>,
                needsExclusivity: true,

            },
            { 
                id: 'no',
                icon: <NoneIcon/>,
                answer: <div> No </div>,
                needsExclusivity: true,
            },
        ]
    },
    {
        id: 'how_many_employees',
        question: 'How many employees does your business have?',
        hint: 'Please choose one answer.',
        answers: [
            {
                id: 'one',
                icon: <SingleEmployeeIcon/>,
                answer: <div> It's <u>just me</u> </div>,
                needsExclusivity: true,
            },
            { 
                id: 'more_than_one',
                icon: <MoreEmployeesIcon/>,
                answer: <div> It's <u>more</u> than just me </div>,
                needsExclusivity: true,
            },
        ]
    }
]

const answersContainer = questions.reduce<IAnswersContainer>((accumulator, question) => {
    accumulator[question.id] = question.answers.reduce(
        (subAccumulator, answer) => {
            subAccumulator[answer.id] = false
            return subAccumulator
        }, {})
    return accumulator
}, {})

const QuestionSlide = ({
    id,
    question, 
    hint, 
    answers, 
    value, 
    onClick,
    onBack,
    onNext,
    isBackVisible,
    isNextDisabled,
}: IQuestionSlide) =>
    <>
        <h2 className="title"> { question } </h2>
        <h3 className="subtitle">{ hint } </h3>
        <div className="answers-container noselect">
            {
                answers.map((answer, index) =>
                    <React.Fragment key={index}>
                        <div 
                            onClick={() => onClick({id, question, hint, answers}, answer)}
                            className={classnames('answer-container', {
                                'selected': value[answer.id],
                                'extra-margin-top': ['yes', 'no'].includes(answer.id),
                            })}
                        >
                            <div className='answer-content'>
                                <div className="answer-icon"> {answer.icon} </div>
                                <div className="answer-answer"> {answer.answer} </div>
                            </div>
                        </div>
                        { index % 2 === 0 ? <></> : <div className="break"/> }
                    </React.Fragment>
                )
            }
        </div>
        <div className="actions">
            { isBackVisible ? <PreviousButton onClick={onBack}/> : <></> }
            <NextButton disabled={isNextDisabled} onClick={onNext}/> 
        </div>
    </>

const ContactForm = ({
    onBack, 
    onNext, 
    businessName, 
    email, 
    renewalDate,
    setBusinessName,
    setEmail,
    setRenewalDate
}) => {

    const [emailError, setEmailError] = useState(false)
    const [businessNameError, setBusinessNameError] = useState(false)

    const today = moment()
    const minDate = new DateTimeHelper(today).futureDate(1, 'days')
    const maxDate = new DateTimeHelper(today).futureDate(90, 'days')

    const isNextDisabled = !email || !businessName || emailError || businessNameError

    const onBusinessNameChange = (_, value) => {
        setBusinessNameError(value.length === 0)
        setBusinessName(value)
    }

    const onEmailChange = (_, value) => {
        const error = /^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(value)
        setEmailError(!error)
        setEmail(value)
    }

    const onRenewalDateChange = (value) => {
        setRenewalDate(value)
    }

    return (
        <>
            <h2 className="title contact-form"> 
                Just a few questions while I finalize your results...
            </h2>
            <div className="contact-form-wrapper">
                <Question
                    fullWidth
                    required
                    type="text"
                    question="Business Name"
                    value={businessName}
                    answer={businessName}
                    onChange={onBusinessNameChange}
                    error={businessNameError}
                    helperText={businessNameError && 'Please insert a business name'}
                />
                <Question
                    fullWidth
                    required
                    type="email"
                    question="Email Address"
                    value={email}
                    answer={email}
                    onChange={onEmailChange}
                    error={emailError}
                    helperText={emailError && 'Please insert a valid email'}
                />
                <div className="start-date-wrapper">
                    <PolicyStartDate
                        value={renewalDate}
                        onChange={onRenewalDateChange}
                        minDate={minDate._d}
                        maxDate={maxDate._d}
                        tooltip="Renewal Date (if you have one)"
                        title="Renewal Date"
                    />
                </div>
            </div>
            <div className="actions">
                <PreviousButton onClick={onBack}/>
                <NextButton disabled={isNextDisabled} onClick={() => onNext(businessName)}/> 
            </div>
        </>
    )
} 

const ResultSlide = ({
    answers, 
    activePolicy, 
    setActivePolicy,
    getQuoteCallback,
    onBack,
}: {
    answers: IAnswersContainer, 
    activePolicy?: keyof typeof policyTypes, 
    setActivePolicy: (p: keyof typeof policyTypes) => void
    getQuoteCallback: () => void
    onBack: () => void
}) => {

    const policies: {
        id: keyof typeof policyTypes, 
        title: string, 
        description: string,
        warning: string,
        recommended: boolean,
    }[] = [
        {
            id: policyTypes.GL,
            title: 'General Liability Policy',
            description: 'General Liability Insurance typically covers claims concerning third parties. If your business causes an injury to another person or damage to another property or business then the resulting claims would be covered depending on the coverage you choose.',
            warning: 'A general liability insurance policy does not cover damage to your own business’s property. For general liability plus property coverage, you will want to obtain a business owner’s policy instead.',
            recommended: answers['owned_or_rented_goods']['none']
        },
        {
            id: policyTypes.BOP,
            title: 'Business Owner\'s Policy',
            description: 'A Business Owner\'s Policy typically covers direct physical loss to buildings and a business\'s personal property, lost income, liability claims for bodily injury, property damage, and personal and advertising injury—and associated medical payments, regardless of fault.',
            warning: 'A Business Owner\'s Policy is not recommended if you do not have business property of your own to protect. A General Liability policy is recommended instead.',
            recommended: !answers['owned_or_rented_goods']['none']
        },
        {
            id: policyTypes.WC,
            title: 'Workers\' Compensation Insurance',
            description: 'Workers’ Compensation typically provides coverage in three key areas: employee work-related injury and illness expenses, state law compliance, and employee injury lawsuits.',
            warning: 'Workers’ compensation is not recommended for businesses like yours. It is generally required for businesses that have employees, and regulations vary by state.',
            recommended: answers['how_many_employees']['more_than_one'],
        },
        {
            id: policyTypes.PL,
            title: 'Professional Liability Insurance',
            description: 'Professional Liability Insurance typically covers expenses related to customer or client claims made against the professional services or advice provided by your company, such as negligence, inaccurate professional advice, and related legal costs.',
            warning: 'Professional Liability Insurance is not recommended for businesses like yours. This coverage is for businesses providing professional advice or services, including industries requiring professional licensing.',
            recommended: answers['business_cunsultant']['yes'],
        },
        {
            id: policyTypes.CYBER,
            title: 'Cyber Insurance',
            description: 'Cyber Insurance typically covers losses and liabilities related to cyber attacks like data breaches and ransomware, including customer communication and fraud monitoring, and damages to your computer systems and data.',
            warning: 'Cyber Insurance is not recommended for businesses like yours. This coverage is for businesses at risk for a cyber attack by storing customer data, having an ecommerce website, or communicating via email.',
            recommended: answers['sensitive_data']['yes'],
        },
        {
            id: policyTypes.AUTO,
            title: 'Hired and Non-Owned Automobile Insurance',
            description: 'Hired and Non-Owned Automobile Insurance for personal or rented cars used for business purposes typically provides coverage for medical expenses and property damage claimed by third parties, and legal fees associated with any claims and/or judgments.',
            warning: 'Hired and Non-Owned Automobile Insurance is not recommended for businesses like yours. This coverage is for businesses that rent or use their own personal vehicles for business purposes.',
            recommended: (
                answers['vehicles_informations']['owned_by_employee'] 
                || answers['vehicles_informations']['rented']
            ),
        }
    ]

    const onClick = (policy: keyof typeof policyTypes) => {
        setActivePolicy(policy)
    }

    useEffect(()=>{
        if(!answers['owned_or_rented_goods']['none']){
            setActivePolicy(policyTypes.BOP)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div className="result">
            <h2 className="title"> 
                Here are your results!
            </h2>
            <h3 className="subtitle"> 
                Your recommendations from Diya are in dark blue. Click each button to learn more.
            </h3>
            <div className="policies-list">
                {/* sorts all the reccomended first and then the NON reccomented at the end */}
                {policies
                    .sort((a,b) => (a.recommended === b.recommended) ? 0 : a.recommended ? -1 : 1)
                    .map(policy => (
                        <div className="policy-container" key={policy.id}>
                            <div className="policy-button-wrapper">
                                <button type={'button'}
                                    onClick={() => onClick(policy.id)} 
                                    className={classnames('a_btn a_btn_action_2 policy_btn', {
                                        active: activePolicy === policy.id,
                                        'not_recommended': !policy.recommended,
                                    })} > 
                                    { policy.title }
                                </button>
                            </div>
                            <div className={classnames('policy-description-wrapper', {
                                active: activePolicy === policy.id
                            })}>
                                <div className="policy-description">
                                    { policy.recommended ? policy.description : policy.warning }
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="actions result_actions">
                <PreviousButton onClick={onBack}/>
                <button
                    className="a_btn a_btn_action_1 btn_continue"
                    disabled={!activePolicy}
                    onClick={getQuoteCallback}>
                    I'd like to get a quote now!
                </button>
            </div>

        </div>
    )
}

const TrackingBar = ({ steps, current }: ITrackingBar) => {
    const localSteps = Array.from(Array(steps).keys())
    return (
        <div className="tracking-bar">
            {[...localSteps].map(e => {
                return (
                    <span key={e}>
                        <div className={classnames('step', {
                            visited: e <= current,
                            first: e === 0
                        })}/>
                    </span>
                )
            })}
        </div>
    )
}

const PreviousButton = ({onClick})  => (
    <button type={'button'}
        className="a_btn a_btn_transparent btn_back"
        onClick={onClick}>
        <ArrowBack/> <span className="text">Back</span>
    </button>
)


const NextButton = ({onClick, disabled})  => (
    <button
        className={'a_btn a_btn_action_1 btn_continue'}
        onClick={onClick}
        disabled={disabled}>
        <span className="text"> Continue </span> <ArrowForward/>
    </button>
)

const TuneUpQuiz = ({
    mainAnswers, 
    sessionId,
    changeAnswers,
    filteredCoverageTypes
}: {
    mainAnswers: {[key: string]: any},
    sessionId: string,
    changeAnswers: (answers: {[key: string]: any}) => void
    filteredCoverageTypes: CoverageType[]
}) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [answers, setAnswers] = useState<IAnswersContainer>(answersContainer)
    const [email, setEmail] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [renewalDate, setRenewalDate] = useState<moment.Moment | null>(null)
    const [activePolicy, setActivePolicy] = useState<keyof typeof policyTypes>(policyTypes.GL)

    const getQuoteCallback = () => {
        if (mainAnswers?.['business.name']) {
            
            // update product selection
            let availablePolicies = filteredCoverageTypes.filter(itm => !itm.isDisabled)
                .map(obj => {
                    return obj.lob
                })


            changeAnswers({
                'coverage.types': activePolicy && availablePolicies.includes(activePolicy) ? [activePolicy] : answers['coverage.types'],
                'policy.desired_effective_date': renewalDate ? renewalDate : answers['policy.desired_effective_date'],
            })
            
            // can't do it this way 
            // before fixing App.js inside constructor:
            // (start: this.checkStartPoint('start'))
            
            // history.push({
            //     pathname: '/get-insurance/select-your-policy-type',
            //     search: `?start=${sessionId}&source=tuneup`,
            // })

            window.location.href = `/get-insurance/select-your-policy-type?start=${sessionId}&source=tuneup`
        }
        else {

            // can't do it this way 
            // before fixing App.js inside constructor:
            // (start: this.checkStartPoint('start'))

            // history.push({
            //     pathname: '/get-insurance/business-name-and-address',
            //     search: `?name=${businessName}`,
            // })
            window.location.href = `/get-insurance/business-name-and-address?name=${businessName}`

        }
    }

    const handleClickNextQuestion = () => {
        setCurrentStep(currentStep + 1)
    }

    const handleClickPreviousQuestion = () => {
        setCurrentStep(currentStep - 1)
    }

    const handleAnswerClick = (question: IQuestion, answer: IAnswer) => {
        setAnswers({
            ...answers, 
            [question.id]: 
                answer.needsExclusivity
                    ? {...answersContainer[question.id], [answer.id]: !answers[question.id][answer.id]} 
                    : question.answers.reduce((accumulator, localAnswer) => {
                        accumulator[localAnswer.id] = localAnswer.id === answer.id 
                            ? !answers[question.id][answer.id] 
                            : localAnswer.needsExclusivity 
                                ? false
                                : answers[question.id][localAnswer.id] 
                        return accumulator
                    }, {})
        })
    }

    const handleContactFormNext = async (businessName: string) => {
        handleClickNextQuestion()
        await DoRequest({
            url: '/api/slack/new-customer-tuneup',
            method: 'post',
            body: { businessName, email },
        })
    }

    const slides = [
        ...questions.map((question, index) => 
            <QuestionSlide 
                {...question} 
                onClick={handleAnswerClick} 
                value={answers[question.id]}
                onBack={handleClickPreviousQuestion}
                onNext={handleClickNextQuestion}
                isBackVisible={index !== 0}
                isNextDisabled={Object.values(answers[questions[index].id]).every(a => !a)}
            />
        ),
        <ContactForm
            onBack={handleClickPreviousQuestion}
            onNext={handleContactFormNext}
            email={email}
            setEmail={setEmail}
            businessName={businessName || mainAnswers?.['business.name']}
            setBusinessName={setBusinessName}
            renewalDate={renewalDate}
            setRenewalDate={setRenewalDate}
        />,
        <ResultSlide
            onBack={handleClickPreviousQuestion}
            answers={answers}
            activePolicy={activePolicy}
            setActivePolicy={setActivePolicy}
            getQuoteCallback={getQuoteCallback}
        />,

    ]

    return (
        <>
            <Helmet>
                <title>Coverage Tune-Up | B2Z Insurance Inc</title>
            </Helmet>
            <Header/>
            <div className="tuneup-quiz-wrapper">
                <div className="tuneup-quiz-container">
                    <div className="intro">
                        Use the Coverage Tune-Up℠ tool to find your coverage needs.
                    </div>
                    <div className="slide-container">
                        { slides[currentStep] }
                    </div>
                    
                    <TrackingBar steps={slides.length} current={currentStep} />
                    
                    <div className="tuneup-quiz-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        mainAnswers: state.insurance.answers,
        sessionId: state.insurance.unique_session_id,
        filteredCoverageTypes: state.insurance.filteredCoverageTypes
    }
}

const mapDispatchToProps = {
    changeAnswers,
}

const ConnectedTuneUpQuiz = connect(mapStateToProps, mapDispatchToProps)(TuneUpQuiz)


export { ConnectedTuneUpQuiz as TuneUpQuiz }