import React from 'react'

export const FurnitureIcon = () => (
    <svg width="33" height="40" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.34375 24.1538V12.7557C8.34375 10.3077 11.0625 7.53845 16.5 7.53845C21.9375 7.53845 24.6562 10.3077 24.6562 12.7557V24.1538" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.1562 38H22.8438" stroke="#827252" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.5 29.6923V38" stroke="#827252" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.625 29.6923V24.1539H27.375V29.6923H5.625Z" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.875 2H20.125" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 13.077V24.1539H31V13.077" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.5 2V7.53846" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)