import React from 'react'

export const AcrisureLogoNew = ({className}) => {
    return <svg className={className} width="203" height="29" viewBox="0 0 203 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8942_13663)">
            <path
                d="M47.3075 21.3935L46.1325 24.6228H42.999L48.3887 10.9202H51.514L56.9037 24.6228H53.7682L52.5931 21.3935H47.3095H47.3075ZM49.9554 14.3514L48.2826 18.8517H51.618L49.9554 14.3514Z"
                fill="#0B0C0C"/>
            <path
                d="M73.438 10.916C74.0888 11.1037 74.6212 11.3322 75.0394 11.5994C75.4576 11.8667 75.8269 12.1339 76.1471 12.4032C76.4674 12.6725 76.7 12.8969 76.8407 13.0825L77.061 13.3702L75.1985 15.2327C75.1598 15.1817 75.1088 15.1103 75.0414 15.0185C74.9741 14.9267 74.8252 14.7676 74.5926 14.5452C74.3601 14.3228 74.1071 14.127 73.8338 13.9577C73.5604 13.7884 73.1993 13.6333 72.7546 13.4885C72.3099 13.3436 71.8529 13.2743 71.3878 13.2743C70.1067 13.2743 69.0377 13.7047 68.1768 14.5636C67.316 15.4244 66.8876 16.4934 66.8876 17.7745C66.8876 19.0556 67.318 20.1246 68.1768 20.9855C69.0377 21.8464 70.1067 22.2748 71.3878 22.2748C71.8856 22.2748 72.3609 22.2054 72.8158 22.0687C73.2707 21.932 73.6481 21.7688 73.948 21.5812C74.2479 21.3935 74.511 21.2038 74.7416 21.012C74.97 20.8202 75.1373 20.655 75.2393 20.5142L75.3923 20.3143L77.161 22.1768C77.11 22.2523 77.0325 22.3543 76.9264 22.4788C76.8203 22.6032 76.5878 22.8235 76.2287 23.1377C75.8697 23.4539 75.4841 23.7293 75.072 23.97C74.662 24.2087 74.1235 24.427 73.4564 24.6248C72.7913 24.8227 72.1018 24.9206 71.3878 24.9206C69.2988 24.9206 67.5342 24.2209 66.0981 22.8235C64.6619 21.4261 63.9438 19.7411 63.9438 17.7725C63.9438 15.8039 64.6619 14.1229 66.0981 12.7276C67.5342 11.3322 69.2988 10.6345 71.3878 10.6345C72.1079 10.6345 72.7934 10.7284 73.4421 10.916H73.438Z"
                fill="#0B0C0C"/>
            <path
                d="M86.1533 10.9202H92.2223C93.5789 10.9202 94.703 11.3547 95.5904 12.2237C96.4798 13.0928 96.9225 14.1597 96.9225 15.4204C96.9225 15.9692 96.8368 16.4812 96.6654 16.9586C96.4941 17.4359 96.2921 17.8215 96.0596 18.1152C95.827 18.409 95.5904 18.6599 95.3476 18.87C95.1048 19.0802 94.8988 19.223 94.7274 19.3005L94.4786 19.4351L97.4121 24.6249H94.2766L91.6389 19.8268H89.097V24.6249H86.1533V10.9202ZM93.4586 14.0924C93.1056 13.7415 92.6609 13.566 92.1244 13.566H89.095V17.187H92.1244C92.6589 17.187 93.1036 17.0157 93.4586 16.675C93.8115 16.3343 93.989 15.9161 93.989 15.4184C93.989 14.8839 93.8115 14.4412 93.4586 14.0903V14.0924Z"
                fill="#0B0C0C"/>
            <path d="M106.704 10.9202H109.648V24.6228H106.704V10.9202Z" fill="#0B0C0C"/>
            <path
                d="M125.091 19.5024C124.63 19.2229 124.064 18.9883 123.399 18.8006C122.734 18.613 122.071 18.3906 121.412 18.1356C120.754 17.8806 120.19 17.4604 119.725 16.8749C119.26 16.2894 119.028 15.5468 119.028 14.6472C119.028 13.5395 119.472 12.5929 120.362 11.8096C121.251 11.0262 122.406 10.6345 123.826 10.6345C124.387 10.6345 124.927 10.6998 125.45 10.8304C125.972 10.9609 126.4 11.1139 126.735 11.2894C127.069 11.4648 127.363 11.6423 127.614 11.8238C127.865 12.0054 128.047 12.1625 128.155 12.2971L128.326 12.4889L126.568 14.3514C126.492 14.2616 126.384 14.1515 126.247 14.0209C126.111 13.8904 125.823 13.717 125.382 13.5007C124.944 13.2845 124.491 13.1764 124.026 13.1764C123.465 13.1764 123.014 13.3192 122.673 13.6068C122.332 13.8944 122.161 14.2412 122.161 14.6492C122.161 15.1082 122.392 15.4754 122.855 15.7529C123.318 16.0303 123.877 16.2649 124.536 16.4546C125.195 16.6464 125.858 16.8688 126.523 17.1238C127.188 17.3788 127.755 17.799 128.218 18.3845C128.681 18.97 128.916 19.7125 128.916 20.6101C128.916 21.877 128.455 22.9133 127.534 23.715C126.614 24.5167 125.344 24.9186 123.726 24.9186C123.089 24.9186 122.473 24.8411 121.878 24.684C121.282 24.5269 120.792 24.3413 120.407 24.125C120.021 23.9088 119.683 23.6885 119.389 23.4661C119.095 23.2438 118.885 23.0561 118.758 22.9031L118.538 22.6644L120.307 20.9059C120.397 21.0079 120.521 21.1405 120.684 21.3017C120.847 21.4628 121.208 21.6832 121.769 21.9565C122.33 22.2299 122.916 22.3666 123.528 22.3666C124.236 22.3666 124.789 22.2013 125.186 21.8688C125.584 21.5383 125.784 21.116 125.784 20.6081C125.784 20.1491 125.554 19.7798 125.091 19.5004V19.5024Z"
                fill="#0B0C0C"/>
            <path
                d="M146.219 21.3466C146.811 20.7285 147.109 19.9614 147.109 19.0434V10.9202H150.042V19.0434C150.042 20.7122 149.475 22.1096 148.341 23.2336C147.206 24.3576 145.785 24.9207 144.073 24.9207C142.361 24.9207 140.94 24.3576 139.805 23.2336C138.671 22.1096 138.104 20.7122 138.104 19.0434V10.9202H141.038V19.0434C141.038 19.9553 141.335 20.7203 141.931 21.3425C142.527 21.9627 143.243 22.2748 144.077 22.2748C144.911 22.2748 145.625 21.9668 146.217 21.3486L146.219 21.3466Z"
                fill="#0B0C0C"/>
            <path
                d="M160.12 10.9202H166.189C167.545 10.9202 168.669 11.3547 169.557 12.2237C170.446 13.0928 170.889 14.1597 170.889 15.4204C170.889 15.9692 170.803 16.4812 170.632 16.9586C170.46 17.4359 170.258 17.8215 170.026 18.1152C169.793 18.409 169.557 18.6599 169.314 18.87C169.071 19.0802 168.865 19.223 168.694 19.3005L168.445 19.4351L171.378 24.6249H168.243L165.605 19.8268H163.063V24.6249H160.12V10.9202ZM167.425 14.0924C167.072 13.7415 166.627 13.566 166.091 13.566H163.061V17.187H166.091C166.625 17.187 167.07 17.0157 167.425 16.675C167.778 16.3343 167.955 15.9161 167.955 15.4184C167.955 14.8839 167.778 14.4412 167.425 14.0903V14.0924Z"
                fill="#0B0C0C"/>
            <path
                d="M190.954 21.9872V24.6249H180.672V10.9202H190.754V13.5681H183.616V16.3098H189.187V18.9476H183.616V21.9872H190.956H190.954Z"
                fill="#0B0C0C"/>
            <path
                d="M202.046 16.2302C201.417 16.8524 200.648 17.1625 199.744 17.1625C198.841 17.1625 198.072 16.8524 197.445 16.2302C196.819 15.608 196.503 14.8532 196.503 13.9658C196.503 13.0784 196.817 12.3236 197.445 11.7014C198.074 11.0792 198.839 10.7692 199.744 10.7692C200.65 10.7692 201.417 11.0792 202.046 11.7014C202.674 12.3236 202.99 13.0784 202.99 13.9658C202.99 14.8532 202.676 15.608 202.046 16.2302ZM197.843 15.8671C198.353 16.3771 198.986 16.6321 199.744 16.6321C200.503 16.6321 201.136 16.3771 201.648 15.8671C202.16 15.3571 202.413 14.7227 202.413 13.9638C202.413 13.2049 202.158 12.5725 201.648 12.0625C201.138 11.5525 200.503 11.2996 199.744 11.2996C198.986 11.2996 198.353 11.5546 197.843 12.0625C197.333 12.5725 197.08 13.2049 197.08 13.9638C197.08 14.7227 197.335 15.3551 197.843 15.8671ZM201.166 15.608H200.597L199.92 14.3636H199.165V15.608H198.631V12.3196H199.885C200.212 12.3196 200.477 12.4175 200.681 12.6133C200.885 12.8092 200.987 13.0519 200.987 13.3436C200.987 13.5395 200.936 13.7129 200.83 13.8598C200.724 14.0066 200.624 14.1066 200.522 14.1596L200.361 14.2331L201.162 15.608H201.166ZM199.165 13.8292H199.92C200.305 13.8292 200.497 13.666 200.497 13.3416C200.497 13.0172 200.305 12.854 199.92 12.854H199.165V13.8312V13.8292Z"
                fill="#0B0C0C"/>
            <path d="M0.428223 28.8802H7.80486L20.663 7.00531L17.0175 0.924072L0.428223 28.8802Z" fill="#0B0C0C"/>
            <path d="M21.3221 8.23547L17.6909 14.4126L26.1957 28.8803H33.5723L21.3221 8.23547Z" fill="#0B0C0C"/>
            <path d="M9.13916 28.8802H24.8268L21.3037 22.9111H12.6622L9.13916 28.8802Z" fill="#0B0C0C"/>
            <path d="M16.9746 15.561L13.374 21.7076H20.5752L16.9746 15.561Z" fill="#0B0C0C"/>
        </g>
        <defs>
            <clipPath id="clip0_8942_13663">
                <rect width="202.562" height="27.9562" fill="white" transform="translate(0.428223 0.924072)"/>
            </clipPath>
        </defs>
    </svg>


}




