import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import * as serviceWorker from './serviceWorker'

import Root from './Root'

declare global {
    interface Window {
        stripe_api: string,
        coterie_stripe_api_key: string,
        coterie_stripe_publishable_key: string,
        b2z_stripe_api_key: string,
        b2z_stripe_publishable_key: string
    }
}

//TODO revert it
// if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_REPORT_ERRORS === 'true') {
//     import('react-error-overlay').then(m => {
//         m.stopReportingRuntimeErrors()
//     })
// }

// @ts-expect-error TOFIX
window.getNextSubSectionPath = function (subsections, current_index = 0) {

    for (let i = current_index; i < subsections.length; i++) {
        if (subsections?.[i] && !subsections[i]?.skipped) return subsections[i].path
    }

    return '/error'
}

// @ts-expect-error TOFIX
window.getPrevSubSectionPath = function (subsections, current_index = 0) {

    for (let i = current_index; i > 0; i--) {
        if (subsections?.[i] && !subsections[i]?.skipped) return subsections[i].path
    }

    return subsections[0].path
}

// @ts-expect-error TOFIX
const sentry_dsn = process.env.REACT_APP_SENTRY_DSN || window.sentry_dsn
// @ts-expect-error TOFIX
const sentry_env = process.env.REACT_APP_SENTRY_ENV || window.sentry_env

if (sentry_env !== 'local') {
    Sentry.init({
        dsn: sentry_dsn,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.5,
        environment: sentry_env
    })
}

ReactDOM.render(
    <Root/>,
    document.getElementById('app')
)

// Change unregister() to register() below to use service worker. Check for pitfalls.
serviceWorker.unregister()
