import React, {useEffect, useReducer, useState} from 'react'
import PolicyCheckoutSummary from '../../components/PolicyCheckoutSummary'
import { changeAnswers, updateInsStoreKey } from '../../store/insurance/actions'
import {useHistory} from 'react-router-dom'

import {withCookies} from 'react-cookie'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {getFeeValue} from '../../helpers/GetFeeValue'
import { formatCurrency } from '../../helpers/currency'
import {getLeadUnderwriter} from '../../helpers/GetLeadUnderwriter'
import {Grid} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import LoadingButton from '../../components/LoadingButton'
import NextSectionButton from '../../components/form-components/NextSectionButton'
import FraudDisclaimer from '../../components/modals/FraudDisclaimer'
import TermsAndConditions from '../../components/modals/TermsAndConditions'
import axios from 'axios'
import SectionHead from '../../components/insurance/SectionHead'
import PreferredEmail from '../../components/insurance/checkout/PreferredEmail'

const quotesReducer = (_quotes, action) => {
    switch (action.type) {
    case 'SET':
        return action.quotes
    default:
        throw new Error('quotesReducer error')
    }
}

const AscendCheckout = (props) => {

    let history = useHistory()
    const quotes = props.full_quote.filter(itm => !itm?.error)
    const selectedQuotes = props.answers['selected_quotes']
    const [errors, setErrors] = useState({})
    const [quotes_to_purchase, dispatchQuotesToPurchase] = useReducer(quotesReducer, [])

    const [showTermsInfo, setShowTermsInfo] = useState(false)
    const [showFraudInfo, setShowFraudInfo] = useState(false)

    const [processing, setProcessing] = useState(false)
    const [disableToProceed , setDisableToProceed] = useState(false)


    useEffect(() => {
        dispatchQuotesToPurchase({type: 'SET', quotes: quotes})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        const onDisableToProceedHandler = ()=>{
            setDisableToProceed(!(selectedQuotes?.length && selectedQuotes?.length > 0))
        }

        onDisableToProceedHandler()
    }, [props.answers, selectedQuotes])



    const isQuoteSelected = (id) => {
        let selected = quotes_to_purchase.filter(itm => itm.id === id)
        return selected.length > 0
    }


    const getTotal = () => {
        let period = (props.answers['billing.frequency'] === 'Monthly') ? 12 : 1
        let fee = 0
        let total = 0

        quotes_to_purchase.forEach(itm => {
            total += (period === 1)
                ? parseFloat(itm.payment_formats[0].installments[0].due_amount)
                : parseFloat(itm.payment_formats[1].installments[1].due_amount)
        })

        quotes_to_purchase.forEach(itm => {
            fee += (itm.source === 'COTERIE') ? getFeeValue() : 0
        })

        let price = formatCurrency(total + fee, true)
        return <span className="amount">{price}</span>
    }

    const onPolicyToggle = (id) => {
        let updated = [...quotes_to_purchase]
        let present = quotes_to_purchase.filter(itm => itm.id === id)
        if (present.length > 0) {
            updated = quotes_to_purchase.filter(itm => itm.id !== id)
        } else {
            let quote = props.full_quote.filter(itm => itm.id === id)
            updated.push(quote[0])
        }

        dispatchQuotesToPurchase({type: 'SET', quotes: updated})
        updateAnswer('selected_quotes', updated)
        updateAnswer('underwriter', getLeadUnderwriter(updated))

        //document.dispatchEvent(new CustomEvent('quotes-changed'))
    }

    const updateAnswer = async (k, v) => {
        return await props.changeAnswers({
            [k]: v
        })
    }


    const performFormValidation = (event) => {
        if (event) event.preventDefault()


        let policySelectionErrors = {}
        if (quotes_to_purchase.length === 0) policySelectionErrors = props.t('common.no_polices_selected')


        // Building final errors object
        const errorsData = {
            ...policySelectionErrors,
        }

        if (Object.keys(errorsData).length === 0 && props.full_quote.length > 0) {
            onShowTermsInfoHandler()
        }
        else setErrors(errorsData)
    }

    const goBack = () => {
        let index = props.current_subsection - 1
        if (props.subsections.length > 0 && index >= 0 && props.subsections[index]) {
            // @ts-ignore
            let path = '/get-insurance' + window.getPrevSubSectionPath(props.subsections, index)
            history.push(path)
        } else {
            history.goBack()
        }

    }

    const onShowFraudInfoHandler = () => {
        setShowFraudInfo(true)
        setShowTermsInfo(false)
    }

    const onShowTermsInfoHandler = () => {
        setShowTermsInfo(true)
    }

    const makePayment = async () => {
        setShowFraudInfo(false)
        setProcessing(true)
        await onAscendBindAndPurchase()
    }

    const onAscendBindAndPurchase = async () => {

        try {
            let quotes = quotes_to_purchase.map(q=>{
                return {'business_name': q.business_name,
                    'effective_date': q.effective_date,
                    'expiration_date': q.expiration_date,
                    'id': q.id,
                    'line_of_business': q.line_of_business,
                    'source': q.source,
                    'term_premium': q.term_premium,
                    'user_id': q.user_id}
            })
            let payload = {
                quotes: quotes,
                session_id: props.sessionId,
                user_email: props.answers['contact.email']
            }

            axios.post('/api/ascend-purchase-and-bind', payload).then(res=>{
                props.updateInsStoreKey('restoreCheckout', false)
                window.location.href = res.data['ascend_checkout_url']
            }).catch((e)=>{
                pushErrors('paymentError', props.t('common.something_went_wrong'), false)
                throw new Error(`Something went wrong while Ascend purchase and bind. ${e}`)

            })
        } catch (e) {
            pushErrors('paymentError', props.t('common.something_went_wrong'), false)
            throw new Error(`Something went wrong onAscendBindAndPurchase handler. ${e}`)
        }
    }

    const pushErrors = (key, value, processing = false) =>  {
        const errorsData = {
            ...errors,
            [key]: value
        }
        setErrors(errorsData)
        setProcessing(processing)
    }
    return (
        <div>
            <div className={'checkout_section'}>
                <div className={'section_container'}>
                    <SectionHead
                        title={props.t('page.ascend_checkout.title')}
                        txt={props.t('page.ascend_checkout.txt')}
                    />
                    <Grid container spacing={2}>
                        <Grid item className="checkout_container">
                            {
                                props.questions.length > 0
                                    ?
                                    <><PolicyCheckoutSummary
                                        isAscendIntegration
                                        full_quote={props.full_quote}
                                        isQuoteSelected={isQuoteSelected}
                                        onPolicyToggle={onPolicyToggle}
                                        onCustomizeDone={true}
                                        getTotal={getTotal}
                                        t={props.t}
                                        answers={props.answers}
                                    />


                                    <PreferredEmail full_quote={props.full_quote}
                                        isNewUser={props.is_new_user}/>

                                    <Grid container className={'section_navigation is_back_btn'}>
                                        {processing ? (
                                            <LoadingButton/>
                                        ) : (<>
                                            <button className="section_back_btn"
                                                type={'button'}
                                                onClick={() => {
                                                    goBack()
                                                }}>
                                                <span className={'a_btn a_btn_transparent'}>
                                                    <ArrowBack/>
                                                </span>
                                                <span className="a_mob_back">Back</span>
                                            </button>
                                            <NextSectionButton
                                                nextDisabled={disableToProceed}
                                                validateFormMethod={performFormValidation}
                                                className="section_next_btn"
                                            />
                                            <NextSectionButton
                                                nextDisabled={disableToProceed}
                                                validateFormMethod={performFormValidation}
                                                className="section_next_btn__mobile"
                                            />
                                        </>
                                        )}
                                    </Grid>

                                    <FraudDisclaimer open={showFraudInfo}
                                        onClose={() => {
                                            setShowFraudInfo(false)
                                        }}
                                        onAccept={async () => {
                                            await makePayment()
                                        }}
                                    />

                                    <TermsAndConditions open={showTermsInfo}
                                        onClose={() => {
                                            setShowTermsInfo(false)
                                        }}
                                        onAccept={() => {
                                            onShowFraudInfoHandler()
                                        }}
                                    />
                                    </>
                                    : <></>
                            }

                        </Grid>
                    </Grid>

                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        is_new_user: state.insurance.is_new_user,
        answers: state.insurance.answers,
        full_quote: state.insurance.full_quote,
        subsections: state.insurance.subsections,
        questions: state.insurance.subsections_questions
    }
}

const mapDispatchToProps = {
    changeAnswers,
    updateInsStoreKey
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AscendCheckout)))