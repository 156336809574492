import React from "react";
import {
    Card,
    CardActionArea,
    CardContent,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    withStyles
} from "@material-ui/core";
import sources_update from '../../common/decorator'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'


const NewStyledRadio = withStyles({
    root: {
        color: "#666E6A",
        "&$checked": {
            color: "#0B0C0C",
        },
    },
    checked: {},
})((props) => <Radio {...props} />);

class CustomBoolean extends React.Component {
    state={
        isNewTheme:null
    }
    constructor() {
        super();
        this.state.isNewTheme = process.env?.REACT_APP_IS_NEW_THEME_ENABLED === "TRUE"
            || window.is_new_theme_enabled === 'TRUE'
    }
    onBooleanChange(value) {
        if (this.props.onChange) this.props.onChange(this.props.id, value)
        sources_update(this.props.answers, this.props.qid)
    }

    render() {
        //Boolean input
        return (
            <FormControl fullWidth={this.props.fullWidth}
                         className={'form_control boolean_control'}
                         error={this.props.error}>
                {this.props.question && (
                    <div className="label_blk">
                        <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                    </div>
                )}

                {(this.props.options && this.props.options.length > 1) ? (
                    <Grid container spacing={2}>
                        {this.props.options.map((opt, idx) => {
                            let value = (opt.hasOwnProperty('value')) ? opt.value : opt.text
                            return (
                                <Grid item xs={6} key={idx}>
                                    <Card variant="outlined" square={true}
                                          className={this.props.answer === value ? 'selected' : null}
                                          onClick={() => {
                                              this.onBooleanChange(value)
                                          }}>
                                        <CardActionArea>{opt.text}</CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                ) : (this.state.isNewTheme? <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <FormControlLabel value={'annual'}
                                                  control={<NewStyledRadio
                                                      onChange={() => {this.onBooleanChange(true)}}
                                                      size="small"
                                                      checked={this.props.answer}/>}
                                                  label={'Annual'} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel value={'monthly'}
                                                  control={<NewStyledRadio
                                                      onChange={() => {this.onBooleanChange(false)}}
                                                      size="small"
                                                      checked={!this.props.answer}/>}
                                                  label={'Monthly'} />
                            </Grid>
                        </Grid>
                        :  <Grid container spacing={2}>
                            {/*if not provided*/}
                            <Grid item xs={6}>
                                <Card variant="outlined" square={true}
                                      className={this.props.answer === true ? 'selected' : null}
                                      onClick={() => {
                                          this.onBooleanChange(true)
                                      }}>
                                    <CardActionArea>
                                        <CardContent>
                                            Annual
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card variant="outlined" square={true}
                                      className={this.props.answer === undefined || this.props.answer === false ? 'selected' : null}
                                      onClick={() => {
                                          this.onBooleanChange(false)
                                      }}>
                                    <CardActionArea>
                                        <CardContent>
                                            Monthly
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>








                )}
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
})
  
export default connect(mapStateToProps)(withTranslation()(CustomBoolean))
  