import React from "react";
import {AutofilledBadge} from "./AutofilledBadge";
import {Checkbox as CheckboxElement, FormControl, FormControlLabel, Grid, Typography} from "@material-ui/core";

export default class Checkbox extends React.Component {

    onChange(e) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, e.target.checked)
        }
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth} error={this.props.error}>
                <Grid container spacing={2} justifyContent="space-between" wrap="nowrap">
                    <Grid item>
                        <Typography variant="caption">{this.props.tooltip}&nbsp;</Typography>
                    </Grid>
                    {this.props.autofilled && <Grid item><AutofilledBadge/></Grid>}
                </Grid>
                <FormControlLabel
                    control={
                        <CheckboxElement
                            checked={this.props.answer}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                            name={this.props.id}
                            color="primary"
                        />
                    }
                    label={this.props.question}
                />
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}