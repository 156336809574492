import React from "react";
import {connect} from "react-redux";
import {updateBusinessOwners as updatebusinessownerslist} from "../../../../store/insurance/actions";
import Question from "../../Question";
import MultipleForms from "./MultipleForms";
import {withTranslation} from "react-i18next";
import {AutofilledBadge} from "../AutofilledBadge";
import {Box, Button, Divider, FormControl, Grid, Typography} from "@material-ui/core";
//scss
import '../../../../scss/q_custom.scss'

class MultipleQuestionSet extends MultipleForms {

    componentDidMount() {
        super.componentDidMount();

        if (this.props[this.getDName(this.props.qid)].length === 0) {
            this.onChange(true)
        }
    }

    addAdditionalLine() {
        let updated = []

        if (this.props.business_owners.length === 0 && this.props.answers['contact.title'] === "Owner") {
            updated = [{
                ...this.props.business_owners_template,
                first_name: this.props.answers['contact.first_name'],
                last_name: this.props.answers['contact.last_name']
            }]
        } else {
            updated = [...this.props[this.getDName(this.props.qid)]]
            updated.push({...this.props[this.getTName(this.props.qid)]})
        }

        this.props[this.getAName(this.props.qid)](updated)
    }

    clearEmptyForms() {
        return true
    }

    render() {
        const {t} = this.props

        return (
            <FormControl fullWidth={this.props.fullWidth} error={this.hasError()}>
                <Grid container spacing={2} justifyContent="space-between" wrap="nowrap">
                    <Grid item><Typography
                        variant="caption">{this.props.question}&nbsp;{this.props.tooltip}</Typography></Grid>
                    {this.props.autofilled && <Grid item><AutofilledBadge/></Grid>}
                </Grid>
                <Grid item className={'additional-question-item'}>
                    <Grid container alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item xs={12}>
                            {this.props[this.getDName(this.props.qid)].map((f, index) => {
                                return (
                                    <Grid container py={1} key={index}>
                                        {this.props[this.getDName(this.props.qid)].length > 1 && (
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} justifyContent="flex-end">
                                                    <Grid item>
                                                        <Button variant="text"
                                                                color="ripple"
                                                                size="small"
                                                                className="remove-additional"
                                                                onClick={() => this.removeAddition(index)}
                                                        >{t('common.btn_remove')}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={10}>
                                            <Grid container>
                                                {this.state.questions.map((q) => (
                                                    <Grid item xs={this.getCellSize(q.qid)} key={q.qid}>
                                                        <Question
                                                            fullWidth
                                                            {...q}
                                                            options={q.options}
                                                            required={q.required}
                                                            type={q.question_type}
                                                            question={q.question_text}
                                                            field_length={q.max_length}
                                                            answer={this.props.answers[this.getKey(q.qid, index)]}
                                                            error={!!this.props.error?.[this.getKey(q.qid, index)]}
                                                            helperText={this.props.error?.[this.getKey(q.qid, index)]}
                                                            onChange={(id, value) => this.updateAnswer(q.qid, index, value)}
                                                            onChangeLocation={data => this.updateLocation(index, data)}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box py={2}><Divider/></Box>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12}>
                                <Button className="add_additional"
                                        onClick={() => {
                                            this.addAdditionalLine()
                                        }}
                                ><span>{t('common.btn_add_more')}</span></Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {this.hasError() && <div>
                    {/*<p className="error_txt error_txt_md">{t('form.error.has_errors')}</p>*/}
                    {Object.keys(this.props.error).map((key, idx) => (
                        <p key={idx} className="error_txt error_txt_md">{this.props.error[key]}</p>))}
                </div>}
            </FormControl>
        )
    }
}

const mapStateToProps = state => ({
    data_map: state.insurance.data_map,
    answers: state.insurance.answers,
    business_owners: state.insurance.business_owners,
    business_owners_template: state.insurance.business_owners_template,
    subsections_questions: state.insurance.subsections_questions
})

const mapDispatchToProps = {
    updatebusinessownerslist
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MultipleQuestionSet))