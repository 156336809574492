import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {withTranslation} from 'react-i18next'
import {
    // Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    MenuItem,
    TextField,
    useTheme
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Question from '../../form-components/Question.js'
import LoadingButton from '../../../components/LoadingButton'
import '../../../scss/v2/portal/_helpfull_modal.scss'
import {getCertificatePayload} from '../../../helpers/GetCertificatePayload'
import {ModalTransition} from '../../../common/ModalTransition'
import {unitedStates} from '../../../common/UnitedStates'


const AddCertificateHolderModal = ({
    policies,
    additional,
    open,
    onClose,
    onHandleSuccess,
    customerEmail,
    t
}: {
    policies: any,
    additional: string,
    open: boolean,
    onClose: () => void,
    onHandleSuccess: (object) => object,
    customerEmail: string,
    t: (string) => string
}) => {


    const typeAddInsuredOptions = [
        {text: 'Vendors'},
        {text: 'Lessor or Leased Equipment'},
        {text: 'Designated Person or Organisation'},
        {text: 'Controlling Interest'},
        {text: 'Grantor or Franchise'},
        {text: 'Co-Owner of Insured Premises'},
        {text: 'Engineers, Architects, or Surveyors Not Engaged By the Named Insured'},
        {text: 'Mortgagee, Assignee, or Receiver'},
        {text: 'Owners, Lessees, or Contractors - Scheduled Person or Organization'},
        {text: 'Primary Noncontributory'},
        {text: 'Waiver of Subrogation'},
        {text: 'Managers or Lessors of Premises'},
        {text: 'Lessee'},
        {text: 'Mortgagee'},
        {text: 'Lessor'},
        {text: 'Leased Equipment'},
        {text: 'Other'}
    ]

    const [formData, setFormData] = useState({
        policyId: '',
        policyNumber: '',
        name: '',
        streetAddress: '',
        streetAddress2: '',
        city: '',
        state: '',
        zipCode: '',
        typeAddInsured: '',
        reason: ''
    })
    const [errors, setErrors] = useState({})

    const [policiesOptions, setPoliciesOptions] = useState([])

    const [loading, setLoading] = useState(false)

    const onChange = (field, value) => {
        let data = {...formData}
        data[field] = value
        setFormData(data)
    }
    const onSuccessSendCertificate = () => {
        onHandleSuccess({
            success: true,
            notifyContent: {
                title: t('portal.helpful_links.success_add_certificate_holder.title'),
                text: additional === 'holder' ? t('portal.helpful_links.success_add_certificate_holder.sub_title') : t('portal.helpful_links.success_add_additional_insured.sub_title'),
                btnTxt: t('portal.helpful_links.success_add_certificate_holder.btn'),
                isIcon: true
            }
        })
        onClose()
    }
    useEffect(() => {
        if (!open) {
            let data = formData
            setFormData({
                policyId: policiesOptions.length === 1 ? data.policyId : '',
                policyNumber: policiesOptions.length === 1 ? data.policyNumber : '',
                name: '',
                streetAddress: '', streetAddress2: '', city: '', state: '', zipCode: '', typeAddInsured: '', reason: ''
            })
            setErrors({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
        const policiesOptions = () => {
            let policiesOptions = policies.map((itm) => itm.type !== 'WC' && itm.type !== 'CYBER'? {
                id: itm.id,
                value: itm.id,
                text: itm.type + ' (' + (itm.quote.policyNumber || itm.quote.policy_number) + ')',
                number: itm.quote.policyNumber || itm.quote.policy_number
            } : null)
            return policiesOptions.filter(policy => policy !== null)
        }
        setPoliciesOptions(policiesOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additional])


    useEffect(() => {
        if (policiesOptions.length === 1) {
            let data = {...formData}
            // @ts-ignore
            data['policyId'] = policiesOptions[0].id
            // @ts-ignore
            data['policyNumber'] = policiesOptions[0].number
            setFormData(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policiesOptions])

    const validateFormFields = () => {
        setLoading(true)
        let errors = {}
        if (!formData.policyId) {
            errors['policyId'] = t('portal.helpful_links.add_certificate_holder.policy_number_error')
        }

        if (!/^.{2,}$/.test(formData.name)) {
            errors['name'] = additional === 'holder' ? t('portal.helpful_links.add_certificate_holder.name_holder_error') : t('portal.helpful_links.add_additional_insured.name_error')
        }

        if (!/^.{2,}$/.test(formData.streetAddress)) {
            errors['streetAddress'] = additional === 'holder' ? t('portal.helpful_links.add_certificate_holder.street_address_holder_error') : t('portal.helpful_links.add_additional_insured.street_address_error')
        }

        if (!/^.{2,}$/.test(formData.city))
            errors['city'] = t('portal.helpful_links.add_certificate_holder.city_holder_error')

        if (!/^.{2,}$/.test(formData.state))
            errors['state'] = t('portal.helpful_links.add_certificate_holder.state_holder_error')

        if (!/^.{4,}$/.test(formData.zipCode))
            errors['zipCode'] = t('portal.helpful_links.add_certificate_holder.zip_code_holder_error')

        if (additional === 'insured') {
            if (!formData.typeAddInsured) {
                errors['typeAddInsured'] = t('portal.helpful_links.add_additional_insured.insured_type_error')
            }

            if (!/^.{2,}$/.test(formData.reason)) {
                errors['reason'] = t('portal.helpful_links.add_additional_insured.reason_error')
            }

        }

        if (Object.keys(errors).length === 0) {
            const policy = policies.find(policy => policy.id === formData.policyId)
            const policyNum = policy.quote.policyNumber || policy.quote.policy_number

            if (additional === 'holder') {
                getCertificate(formData).then(data => {
                    axios.post('/api/send-email',
                        {
                            to: [customerEmail],
                            reply_to: customerEmail,
                            template: 'new_certificate_holder',
                            attachments: [data],
                            data: {
                                additional_type: 'Certificate Holder',
                                link: `${process.env.REACT_APP_SITE}/portal/home`,
                                document: `${policy.type} (${policyNum})`
                            }
                        })
                        .then(() => {
                            setLoading(false)
                            onSuccessSendCertificate()
                        })
                        .catch(e => {
                            setLoading(false)
                            throw new Error(`Can't send email while adding the certificate holder. ${e}`)
                        })
                })
            } else {
                // @ts-ignore
                let html = `<p><strong>Policy Number :</strong> ${policyNum}</p>
<p><strong>Name of additional insured :</strong> ${formData.name}</p><p><strong>Address of Additional Insured :</strong> ${formData.streetAddress}, ${formData.city}, ${formData.state} ${formData.zipCode}</p>
<p><strong>Type of Additional Insured :</strong> ${formData.typeAddInsured}</p><p><strong>Reason :</strong> ${formData.reason}</p>`
                axios.post('/api/send_email',
                    {
                        recipients: ['cl_service@acrisure.com'],
                        subject: `Add Additional Insured (${policyNum})`,
                        text: 'Add Additional Insured',
                        html: html,
                        attachments: []
                    })
                    .then(() => {
                        setLoading(false)
                        onSuccessSendCertificate()
                        onClose()
                    })
                    .catch(e => {
                        setLoading(false)
                        throw new Error(`Can't send email while adding Additional Insured. ${e}`)
                    })
            }

        } else {
            setErrors(errors)
            setLoading(false)
        }
    }
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const getCertificate = (holderData) => {
        const policyData = policies.find(policy => policy.id === formData.policyId)
        const certificatePayload = getCertificatePayload(policyData)

        return axios.post('/api/get-certificate', {
            doc_type: certificatePayload.docType,
            strict: true,
            payload: {
                ...certificatePayload.payload,
                addit_insured_or_holder_data: {
                    // @ts-ignore
                    name: `${holderData.name}`,
                    // @ts-ignore
                    addressLine1: `${holderData.streetAddress}`,
                    // @ts-ignore
                    addressLine2: `${holderData.city}, ${holderData.state} ${holderData.zipCode}`
                }
            }
        }).then((data) => {
            // @ts-ignore
            return {data: data.data, name: `${certificatePayload['payload']['policy_number']}.pdf`}
        }).catch(e => {
            throw new Error(`Can't get user's COI while adding Additional Insured or new holder. ${e}`)
        })
    }

    // @ts-ignore
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            className={'modal__wrapper helpful_common__wrapper '}
            maxWidth="sm"
            scroll={'paper'}
            fullWidth
            TransitionComponent={ModalTransition}>
            <DialogActions className={'modal_head'}>
                <button className={'modal_close_btn show_scroll_paper'}
                    type={'button'}
                    onClick={onClose}><Close/>
                    <span>{t('common.btn_close')}</span></button>
            </DialogActions>
            <DialogContent>
                <Grid container spacing={2} className={'helpful_cnt'}>
                    {additional === 'holder' ? <Grid item>
                        <p className={'helpful_ttl'}>{t('portal.helpful_links.add_certificate_holder.title')}</p>
                        <p className={'helpful_sub_ttl'}>{t('portal.helpful_links.add_certificate_holder.sub_title')}</p>
                    </Grid> : <Grid item>
                        <p className={'helpful_ttl'}>{t('portal.helpful_links.add_additional_insured.title')}</p>
                        <p className={'helpful_sub_ttl'}>{t('portal.helpful_links.add_additional_insured.sub_title')}</p>

                    </Grid>}

                    {<Grid item xs={12}>
                        {policiesOptions.length > 1 ?
                            <FormControl fullWidth error={errors['policyId'] ? errors['policyId'] : null}
                                className={'form_control'}>
                                <div className="label_blk">
                                    <div className="label">
                                        <span>{t('portal.helpful_links.add_certificate_holder.policy_number_question')}</span>
                                    </div>
                                </div>
                                <TextField
                                    select
                                    className={'fieldDropdown'}
                                    value={formData.policyId}
                                    error={!!errors['policyId']}
                                    variant="outlined"
                                    id={'downloading-documents-select'}
                                    onChange={(e) => onChange('policyId', e.target.value)}>
                                    {policiesOptions && policiesOptions.map((opt) => (
                                        // @ts-ignore
                                        <MenuItem value={opt.value} key={opt.id}>{opt.text}</MenuItem>
                                    ))}
                                </TextField>
                                {errors['policyId'] &&
                                      <p className="error_txt error_txt_md">{errors['policyId']}</p>}

                            </FormControl> :
                            <FormControl fullWidth error={errors['policyId'] ? errors['policyId'] : null}
                                className={'form_control'}>
                                <div className="label_blk">
                                    <div className="label">
                                        <span>{t('portal.helpful_links.add_certificate_holder.policy_number_question')}</span>
                                    </div>
                                </div>
                                <TextField
                                    disabled
                                    value={formData.policyNumber}
                                    variant="outlined">
                                </TextField></FormControl>}

                    </Grid>}



                    <Grid item xs={12}>
                        <Question
                            fullWidth
                            type="text"
                            question={additional === 'holder' ? t('portal.helpful_links.add_certificate_holder.name_holder_question') : t('portal.helpful_links.add_additional_insured.name_question')}
                            answer={formData.name}
                            onChange={(_id, val) => {
                                onChange('name', val)
                            }}
                            error={errors['name'] ? errors['name'] : null}
                            helperText={errors['name'] ? errors['name'] : null}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Question
                            fullWidth
                            type="text"
                            question={additional === 'holder' ? t('portal.helpful_links.add_certificate_holder.street_address_holder_question') : t('portal.helpful_links.add_additional_insured.street_address_question')}
                            answer={formData.streetAddress}
                            onChange={(_id, val) => {
                                onChange('streetAddress', val)
                            }}
                            error={errors['streetAddress'] ? errors['streetAddress'] : null}
                            helperText={errors['streetAddress'] ? errors['streetAddress'] : null}/>
                    </Grid>


                    {/*
                         <Grid item xs={12}>
                        {policiesOptions.length > 1 ?
                            <FormControl fullWidth error={errors['policyNumber'] ? errors['policyNumber'] : null}
                                className={'form_control'}>
                                <div className="label_blk">
                                    <div className="label">
                                        <span>{t('portal.helpful_links.add_certificate_holder.policy_number_question')}</span>
                                    </div>
                                </div>
                                <TextField
                                    select
                                    className={'fieldDropdown'}
                                    value={formData.policyNumber}
                                    error={!!errors['policyNumber']}
                                    variant="outlined"
                                    id={'downloading-documents-select'}
                                    onChange={(e) => onChangePolicy(e.target.value)}>
                                    {policiesOptions && policiesOptions.map((opt) => (
                                        // @ts-ignore
                                        <MenuItem value={opt.value} key={opt.id}>{opt.text}</MenuItem>
                                    ))}
                                </TextField>
                                {errors['policyNumber'] &&
                                  <p className="error_txt error_txt_md">{errors['policyNumber']}</p>}

                            </FormControl> :
                            <FormControl fullWidth error={errors['policyNumber'] ? errors['policyNumber'] : null}
                                className={'form_control'}>
                                <div className="label_blk">
                                    <div className="label">
                                        <span>{t('portal.helpful_links.add_certificate_holder.policy_number_question')}</span>
                                    </div>
                                </div>
                                <TextField
                                    disabled
                                    value={formData.policyNumber}
                                    variant="outlined">
                                </TextField></FormControl>}

                    </Grid>
                    */}
                    <Grid item xs={12}>

                        <Question
                            fullWidth
                            type="text"
                            question={t('portal.helpful_links.add_certificate_holder.street_address2_holder_question')}
                            answer={formData.streetAddress2}
                            onChange={(_id, val) => {
                                onChange('streetAddress2', val)
                            }}/>
                    </Grid>
                    <Grid item xs={4}>

                        <Question
                            fullWidth
                            type="text"
                            question={t('portal.helpful_links.add_certificate_holder.city_holder_question')}
                            answer={formData.city}
                            onChange={(_id, val) => {
                                onChange('city', val)
                            }}
                            error={errors['city'] ? errors['city'] : null}
                            helperText={errors['city'] ? errors['city'] : null}/>
                    </Grid>
                    <Grid item xs={4}>

                        <Question
                            fullWidth
                            type="dropdown"
                            options={unitedStates}
                            question={t('portal.helpful_links.add_certificate_holder.state_holder_question')}
                            answer={formData.state}
                            onChange={(_id, val) => {
                                onChange('state', val)
                            }}
                            error={errors['state'] ? errors['state'] : null}
                            helperText={errors['state'] ? errors['state'] : null}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Question
                            fullWidth
                            type="zip"
                            question={t('portal.helpful_links.add_certificate_holder.zip_code_holder_question')}
                            answer={formData.zipCode}
                            onChange={(_id, val) => {
                                onChange('zipCode', val)
                            }}
                            error={errors['zipCode'] ? errors['zipCode'] : null}
                            helperText={errors['zipCode'] ? errors['zipCode'] : null}
                        />
                    </Grid>

                    {additional === 'insured' ? <>
                        <Grid item xs={12}>
                            <Question fullWidth
                                type="dropdown"
                                question={t('portal.helpful_links.add_additional_insured.insured_type_question')}
                                options={typeAddInsuredOptions}
                                answer={formData.typeAddInsured}
                                onChange={(_id, val) => {
                                    onChange('typeAddInsured', val)
                                }}
                                error={errors['typeAddInsured'] ? errors['typeAddInsured'] : null}
                                helperText={errors['typeAddInsured'] ? errors['typeAddInsured'] : null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Question
                                fullWidth
                                type="text"
                                field_length={1000}
                                question={t('portal.helpful_links.add_additional_insured.reason_question')}
                                answer={formData.reason}
                                onChange={(_id, val) => {
                                    onChange('reason', val)
                                }}
                                error={errors['reason'] ? errors['reason'] : null}
                                helperText={errors['reason'] ? errors['reason'] : null}
                            />
                        </Grid>
                    </> : null}
                    <Grid item xs={12}>
                        {loading ? <LoadingButton/> : <button className={'a_btn a_btn_action_1 btn__submit'}
                            onClick={validateFormFields}>{t('common.btn_submit')}</button>}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default withTranslation()(AddCertificateHolderModal)