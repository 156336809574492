import React from 'react';
import {AppBar, Toolbar} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import AcrisureLogo from '../../components/icons/new/AcrisureLogo'
import UserMenu from "../../components/portal/UserMenu";
import {Link} from "react-router-dom";
import '../../scss/v2/portal/_header.scss'

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: "white",
        color: theme.palette.primary.main,
        borderRadius: '0',
        paddingLeft: '0',
        boxShadow: '0px 1px 0px rgba(23, 33, 82, 0.4)'
    },
    headerCnt:{
        paddingLeft: '12px',
        paddingRight: '12px',
    },
    link: {
        textDecoration: "none",
        color: "white"
    },
    agent: {
        color: 'red',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    }
}))

function Header({t}) {
    const classes = useStyles()
    const anchorRef = React.useRef(null);

    return (
        <AppBar position="static" className={`${classes.header} main-header`}>
            <Toolbar ref={anchorRef} className={classes.headerInner}>
                <div className="header_logo">
                    <Link to="/portal/home" className={'insurance_header_logo_link'}>
                      <AcrisureLogo className={'insurance_header_logo'}/>
                    </Link>
                </div>
                <UserMenu anchorRef={anchorRef}/>
            </Toolbar>
        </AppBar>
    )
}

export default withTranslation()(Header)