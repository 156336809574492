import React from 'react'
import {Link} from 'react-router-dom'
import Error from '../../icons/new/Error'
import '../../../scss/v2/portal/_failed_installments.scss'

type Installment = {
    [key: string]: any
}
const FailedInstallments = ({
    failedInstallments
}: { failedInstallments: Array<Installment> }) => {
    return (
        <div className="failed_installments_cnt">
            <div className="icon"><Error/></div>
            <ul className={'failed_installments_list'}>
                {failedInstallments.map((item) => {
                    return (<li className={'failed_installment'}>
                        We were unable to process your recent payment on <span
                            className={'num'}>#{item.policyNumber}</span><Link to={'/portal/payment-center'}>Please
                        update your billing Information</Link>
                    </li>)
                })}
            </ul>
        </div>
    )
}

export default FailedInstallments