export const currency = (val) => {
    return (val * 100 / 12 / 100).toFixed(2)
}

export const formatCurrency = (x, keep_trailing_zeroes = false, prepend = '$') => {
    x = Number(parseFloat(x).toFixed(2))
    //Add trailing zeroes
    if (keep_trailing_zeroes) {
        x = x.toLocaleString("en", {useGrouping: false, minimumFractionDigits: 2})
    }
    x = x.toString()
    return prepend + x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}