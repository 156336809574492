import React from 'react'

export const HelpPortal = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="21.8028" cy="21.8027" rx="14.1972" ry="14.1972" fill="#ffffff"/>
    <path
      d="M18.0001 3C15.0334 3 12.1333 3.87973 9.66658 5.52796C7.19984 7.17618 5.27725 9.51886 4.14194 12.2597C3.00662 15.0006 2.70957 18.0166 3.28835 20.9264C3.86713 23.8361 5.29574 26.5088 7.39353 28.6066C9.49132 30.7044 12.1641 32.133 15.0738 32.7118C17.9835 33.2906 20.9995 32.9935 23.7404 31.8582C26.4813 30.7229 28.824 28.8003 30.4722 26.3336C32.1204 23.8668 33.0001 20.9667 33.0001 18C33.0001 16.0302 32.6121 14.0796 31.8583 12.2597C31.1045 10.4399 29.9996 8.78628 28.6067 7.3934C27.2139 6.00052 25.5603 4.89563 23.7404 4.14181C21.9205 3.38799 19.97 3 18.0001 3ZM18.0001 30C15.6268 30 13.3067 29.2962 11.3333 27.9776C9.3599 26.6591 7.82183 24.7849 6.91358 22.5922C6.00532 20.3995 5.76768 17.9867 6.23071 15.6589C6.69373 13.3311 7.83662 11.1929 9.51485 9.51472C11.1931 7.83649 13.3313 6.6936 15.659 6.23058C17.9868 5.76755 20.3996 6.00519 22.5923 6.91345C24.785 7.8217 26.6592 9.35977 27.9778 11.3332C29.2963 13.3065 30.0001 15.6266 30.0001 18C30.0001 21.1826 28.7359 24.2348 26.4854 26.4853C24.235 28.7357 21.1827 30 18.0001 30Z"
      fill="#000000"/>
    <path
      d="M18.0002 9.0001C16.6079 9.0001 15.2725 9.55322 14.2879 10.5378C13.3034 11.5224 12.7502 12.8577 12.7502 14.2501C12.7502 14.6479 12.9083 15.0295 13.1896 15.3108C13.4709 15.5921 13.8524 15.7501 14.2502 15.7501C14.6481 15.7501 15.0296 15.5921 15.3109 15.3108C15.5922 15.0295 15.7502 14.6479 15.7502 14.2501C15.7502 13.8051 15.8822 13.3701 16.1294 13.0001C16.3767 12.6301 16.7281 12.3417 17.1392 12.1714C17.5503 12.0011 18.0027 11.9565 18.4392 12.0433C18.8757 12.1302 19.2766 12.3444 19.5912 12.6591C19.9059 12.9738 20.1202 13.3747 20.207 13.8111C20.2938 14.2476 20.2493 14.7 20.079 15.1111C19.9087 15.5223 19.6203 15.8737 19.2503 16.1209C18.8803 16.3681 18.4453 16.5001 18.0002 16.5001C17.6024 16.5001 17.2209 16.6581 16.9396 16.9394C16.6583 17.2207 16.5002 17.6023 16.5002 18.0001V21.0001C16.5002 21.3979 16.6583 21.7795 16.9396 22.0608C17.2209 22.3421 17.6024 22.5001 18.0002 22.5001C18.3981 22.5001 18.7796 22.3421 19.0609 22.0608C19.3422 21.7795 19.5002 21.3979 19.5002 21.0001V19.2601C20.7124 18.9061 21.7557 18.1259 22.438 17.0633C23.1202 16.0006 23.3953 14.7272 23.2127 13.4777C23.03 12.2282 22.4018 11.0868 21.4439 10.264C20.486 9.44114 19.263 8.99226 18.0002 9.0001Z"
      fill="#000000"/>
    <path
      d="M18.0001 27C18.8285 27 19.5001 26.3284 19.5001 25.5C19.5001 24.6716 18.8285 24 18.0001 24C17.1717 24 16.5001 24.6716 16.5001 25.5C16.5001 26.3284 17.1717 27 18.0001 27Z"
      fill="#000000"/>
  </svg>

)