import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Grid } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { includes, isEmpty } from 'lodash'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { IndustryClassCard } from '../../components/form-components/IndustryClassCard'
import NextSectionButton from '../../components/form-components/NextSectionButton'
import Question from '../../components/form-components/Question'
import SectionHead from '../../components/insurance/SectionHead'
import LoadingButton from '../../components/LoadingButton'
import { trimBusinessName } from '../../helpers/trimBusinessName'

// hooks
import DoRequest from '../../hooks/do-request'
import {
  changeAnswers,
  updateAppProducts,
  updateIndustryClasses,
  updateInsStoreKey,
  updateProgress,
} from '../../store/insurance/actions'
// css
import '../../scss/v2/_industry_class.scss'
import {datadogRum} from "@datadog/browser-rum";

export const IndustryClass = React.memo((props) => {

    const {t, answers, updateProgress, section, next_subsection, subsections, is_choose_naics_manually, updateIndustryClasses} = props

    const [callbackSetup, setCallbackSetup] = useState(false)
    const [business_type, set_business_type] = useState(answers['b2z_class_code_id'])
    const [error, setError] = useState(false)
    const [nextUrl, setNextUrl] = useState('/results')
    const [, set_show_helper] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [showDropDown, setShowDropDown] = useState(false)
    const [dropdownUsed, setDropdownUsed] = useState(false)
    const [headTitle, setHeadTitle] = useState('')
    const [headText, setHeadText] = useState('')
    const [coverageTypes, setCoverageTypes] = useState(answers['coverage.types'])
    const [appetiteKeys, setAppetiteKeys] = useState(answers['coverage.types'])
    const [appetite, setAppetite] = useState(answers['appetite'])

    const [startLoadIndustryClasses, setStartLoadIndustryClasses] = useState(false);
    const [industryClassesLoaded, setIndustryClassesLoaded] = useState(false);
    const industryClassesLoading = startLoadIndustryClasses && props.industry_classes.length === 0;


    const coverageTypesRef = useRef()
    const appetiteKeysRef = useRef()
    const appetiteRef = useRef()
    coverageTypesRef.current = coverageTypes
    appetiteKeysRef.current = appetiteKeys
    appetiteRef.current = appetite

    let selected_class_codes = answers['business.b2z_class_codes']


  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'})
  }, [])

    useEffect(() => {
        updateProgress({
            current_section: section.section_index,
            current_subsection: next_subsection - 1
        })

        const initializeNextUrl = "/get-insurance" + window.getNextSubSectionPath(subsections, next_subsection)
        setNextUrl(initializeNextUrl)

      if(props.industry_classes.length >0){
        setIndustryClassesLoaded(true)
      }

    }, [answers, business_type, next_subsection, props.industry_classes.length, section.section_index, selected_class_codes, subsections, updateProgress])

    const goBack = () => {
        let index = props.current_subsection - 1
        if (props.subsections.length > 0 && index >= 0 && props.subsections[index]) {
            let path = "/get-insurance" + window.getPrevSubSectionPath(props.subsections, index)

            props.history.push(path)
        } else props.history.goBack()
    }

  const   businessSearchQuestionsHandler = async  (answerData)=>{

    const {answers} = props

    if (answers['business.name'] && answers['business.zip_code']) {
      let address = ''
      address += answers['business.street_address'] + ', '
      if (answers['business.street_address2']) address += answers['business.street_address2'] + ', '
      address += answers['business.city'] + ', '
      address += answers['business.state'] + ', '
      address += 'USA'

      return await axios.post("/api/business/search_questions", {
        search_input: {
          website: answers['business.website'],
          place_id: answers['business.place_id'],
          name: answers['business.name'],
          zip: answers['business.zip_code'],
          address_str: address,
          session_id:  props.unique_session_id,
        },
        session_id: props.unique_session_id,
        application_answers: { ...answers, ...answerData }
      }).then(response => {
        return response.data
      }).catch(e => {
        datadogRum.addError(`Something went wrong while /api/business/search_questions call. ${e}`)
        return { application_answers: { ...answers, ...answerData } }
      })
    }

  }


  const setSelected = async (item, isDropdown = false) => {
        /**
         * Answers update is performed directly through API endpoint and then updated
         * in Redux state because the answers must be updated in the Core DB prior to
         * making a call to wc_update API endpoint.  This ensures the async nature of
         * node.js does not outpace call to wc_update API endpoint.
         */
        setProcessing(true)
        if (!isDropdown) {
            let selected = answers['business.b2z_class_codes'].filter(itm => itm.id === item || item.id)

            setError(false)
            set_business_type(item?.id || item)

            const answerData = {
                "b2z_class_code_id": selected[0].id,
                "b2z_naics_code": selected[0].naics_code,
                "b2z_class_code_description": selected[0].display_description,
                "business.description": selected[0].display_description,
                "business.naics_code": selected[0].naics_code.split("-")[0]
            }

            await DoRequest({
                url: "/api/post-answers",
                method: "post",
                body: {
                    session_id: props.unique_session_id,
                    answers: {
                        ...props.answers,
                        ...answerData
                    }
                },
                onSuccess: () => {
                    props.updateInsStoreKey('answers', {
                        ...props.answers,
                        ...answerData
                    })
                    setProcessing(false)
                }
            })

            props.updateInsStoreKey('is_choose_naics_manually', true)
            /**
             * When is_choose_naics_manually is set to false, the business description related to the
             * customer selected industry class code does not persist to the business.description field show on
             * the experience-and-revenue.  This is because SelectPolicy.js performs a second api/business/search
             * and overwrites this value to what ever advisor service returns.  Setting it to true, fixes this but
             * this should be revisited if bugs show up later.
             */
        } else {
            setError(false)
            let selected = props.industry_classes.filter(itm => itm.id === item.id)[0].id
            set_business_type(selected)

            const answerData = {
                "b2z_class_code_id": item.id,
                "b2z_naics_code": item.naics_code,
                "b2z_class_code_description": item.display_description,
                "business.description": item.display_description,
                "business.b2z_class_codes": [item],
                "business.naics_code": item.naics_code.split("-")[0]
            }



            props.updateInsStoreKey('is_choose_naics_manually', true)

          businessSearchQuestionsHandler(answerData).then(async (res)=>{
            let answersDataToUpdate = res

              await DoRequest({
                url: "/api/post-answers",
                method: "post",
                body: {
                  session_id: props.unique_session_id,
                  answers: {
                    ...answersDataToUpdate.application_answers
                  }
                },
                onSuccess: () => {
                  props.updateInsStoreKey('answers', {
                    ...answersDataToUpdate.application_answers

                  })
                  setProcessing(false)
                }
              })
              setShowDropDown(false)
              setDropdownUsed(true)
              props.updateInsStoreKey('is_choose_naics_manually', true)

          })

        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const clear = () => {
        props.updateInsStoreKey('fetched_industry_classes', [])

        props.changeAnswers({
            "business.b2z_class_codes": []
        })

        //Reset data
        setError(false)
        set_show_helper(true)
        set_business_type('')
        setShowDropDown(true)

        props.changeAnswers({
            "b2z_class_code_id": '',
            "b2z_naics_code": '',
        })
    }

    const onNoneDescribesIndustry = ()=>{
      if(props.industry_classes.length > 0){
        clear()
        setIndustryClassesLoaded(true)
      }else{
        setStartLoadIndustryClasses(true)
      }
    }


    const updateWCcodes = async () => {
        /**
         * Function calls advisor service to obtain WC specific class recommendation data.  WC policy card is disabled
         * on SelectPolicy.js if this call fails.
         */
        return await DoRequest({
            url: "/api/business/wc_update",
            method: "post",
            body: {
                session_id: props.unique_session_id
            }
        }).then(response => {
            if (isEmpty(response?.data) || response?.data === undefined) {
                /**
                 * Filtering out WC policy card when this endpoint fails.
                 */
                let filteredCoverageTypesList = includes(coverageTypesRef.current, 'WC') && coverageTypesRef.current?.length > 1
                    ? coverageTypesRef.current.filter(cov => cov !== 'WC')
                    : coverageTypesRef.current?.length === 1 && coverageTypesRef.current[0] === 'WC'
                        ? [appetiteKeysRef.current[0]]
                        : coverageTypesRef.current

                let filteredAppetite = appetiteRef.current?.map(obj => {
                    if (obj.lob === 'WC') return {...obj, isDisabled: true}
                    else return obj
                })

                props.updateInsStoreKey('filteredCoverageTypes', filteredAppetite)
                props.changeAnswers({
                    'coverage.types': filteredCoverageTypesList,
                    'premises.0.rate_classes.0.class_code': '',
                    'premises.0.rate_classes.0.class_code_description': ''
                })
            } else {
                props.changeAnswers({
                    'premises.0.rate_classes.0.class_code': response.data['premises.0.rate_classes.0.class_code'],
                    'premises.0.rate_classes.0.class_code_description': response.data['premises.0.rate_classes.0.class_code_description']
                })
            }
        }).catch(e => {
            datadogRum.addError(`Can't get WC codes. ${e}`)
            return true
        })
    }

    function getCustomerSelections(appetite, customerSelections) {
      let selections = customerSelections.filter(itm => includes(appetite, itm))
      if (selections.length === 0) return appetite[0]? [appetite[0]] : []
      else return selections
    }

    const validateForm = async (event) => {
        event.preventDefault()
        let {coverage_types, filteredCoverageTypes} = props

        if (answers["b2z_naics_code"]) {
            setError(false)
            setProcessing(true)

            if (filteredCoverageTypes.length < 3) { //
                /**
                 * Resets filteredCoverageTypes to default object in Redux store so that a newly updated list of
                 * products is always generated and then subsequently filtered based on appetite from updated
                 * questions answered in the application.
                 */
                filteredCoverageTypes = coverage_types
            }

            await DoRequest({
                /**
                 * On Success: coverage_types is updated based on appetite API response
                 * On Error: coverage_types is set to blank string to force an error on SelectPolicy.js
                 */
                url: "/api/classes/get-appetite",
                method: "post",
                body: {
                    state: answers['business.state'],
                    naics: answers['b2z_naics_code'],
                    isPartner: props?.isPartner,
                    partnerData: props?.partnerData
                },
                onSuccess: (appetite) => {
                    let appetiteKeys = Object.keys(appetite?.data).sort()
                    let availableProducts = filteredCoverageTypes.map(obj => {
                        if (!appetiteKeys.includes(obj.lob)) return {...obj, isDisabled: true}
                        else return {...obj, appetite: appetite.data[obj.lob], isDisabled: false}
                    })

                    let preSelectedCoverage = getCustomerSelections(appetiteKeys, answers['coverage.types'])
                    let availableProductKeys = [...new Set(availableProducts.map(itm => itm.lob))]

                    setCoverageTypes(preSelectedCoverage)
                    setAppetiteKeys(availableProductKeys)
                    setAppetite(availableProducts)
                    setCallbackSetup(!callbackSetup)

                    props.updateInsStoreKey('filteredCoverageTypes', availableProducts)
                    props.changeAnswers({
                        'appetite': appetite?.data,
                        'coverage.types': preSelectedCoverage
                    })
                },
                onError: (e) => {
                    props.updateInsStoreKey('filteredCoverageTypes', '')
                    throw new Error(`Something went wrong while getting appetite. ${e}`)
                }
            })

            await updateWCcodes()

            props.history.push(nextUrl)
            setProcessing(false)

        } else {
            setError(!props.answers["b2z_naics_code"])
            setProcessing(false)
        }
    }

    const updateBusinessType = async (id, desc) => {
        if (!id) {
            clear()
        }
        if (id) {
            const obj = {
                id: id,
                naics_code: props.industry_classes.filter(itm => itm.id === id)[0].naics_code,
                display_description: desc
            }
            await setSelected(obj, true)
        }
    }

    useEffect(() => {
      if (!(answers['business.b2z_class_codes']?.length > 0 && showDropDown === false)) {
        setStartLoadIndustryClasses(true)
      }
        const getSectionHeadTxt = () => {
            let text

            if (showDropDown) {
              text = t('page.ic.provide_description')
            } else if (is_choose_naics_manually && answers['business.b2z_class_codes'].length === 1) {
                text = t('page.ic.dropdown_description')
            } else {
                text = t('page.ic.description')
            }
            setHeadText(text)
        }

        const getSectionHeadTitle = () => {
            let title
            if (showDropDown) {
                title = t('page.ic.provide_title', {value: trimBusinessName(answers['business.name'])})
            } else {
                title = t('page.ic.title', {value: trimBusinessName(answers['business.name'])})
            }
            setHeadTitle(title)
        }
        getSectionHeadTxt()
        getSectionHeadTitle()
    }, [answers, is_choose_naics_manually, showDropDown, t, industryClassesLoaded])


    useEffect(() => {
        let active = true;

        if (!industryClassesLoading) {
            return undefined;
        }

        (async () => {

            await DoRequest({
                url: '/api/classes/v2',
                method: 'post',
                body: {
                    payload: {
                        level: '10',
                        line_of_business: 'ALL',
                        state: 'ALL'
                    }
                },
                onSuccess: (response) => {
                    if (response.data.length > 0 && active) {
                        updateIndustryClasses(response.data)
                    }else{
                      setStartLoadIndustryClasses(false)
                      updateIndustryClasses([])
                    }
                    setIndustryClassesLoaded(true)
                    clear()
                },
                onError: (e) => {throw new Error(`Error fetching Industry Classes. ${e}`)}
            })
        })();

        return () => {
            active = false;
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [industryClassesLoading, answers, updateIndustryClasses]);

    return (
      <div className={'industry_class_section'}>

        <form autoComplete={'off'} className={'section_container'} onSubmit={e => {
          validateForm(e)
        }}>
            <SectionHead title={headTitle}
                         txt={headText}/>

          {answers['business.b2z_class_codes']?.length > 0 && showDropDown === false ? (
            <Grid container spacing={2} className={'industry_classes_list__cnt'}>
              <Grid item container xs={12} wrap={'wrap'}>
                {(answers['business.b2z_class_codes'].length > 0 > 100) ? (
                  <Grid item xs={12} sm={8} lg={6} xl={5}>
                    <Question fullWidth
                              type="dropdown"
                              question={null}
                              answer={answers['business.b2z_class_codes'][0].id}
                              variant="outlined"
                              placeholder={t('form.error.industry_class')}
                              onChange={async (id, text, value) => {
                                await setSelected(value)
                              }}
                    />
                  </Grid>
                ) : ((answers['b2z_class_code_id']?.length > 0) &&
                (answers['business.b2z_class_codes']?.filter(itm => itm.id === answers['b2z_class_code_id'])?.length <= 0) ? (
                  /**
                   * Condition active only when customer selects class code not in advisor service recommendations by
                   * using the dropdown or Diya.  In those instances, their choice will persist even when returning
                   * to beginning of app unless a new business is provided.
                   */
                  <Grid container>
                        <IndustryClassCard selected={true}
                                           id={answers['b2z_class_code_id']}
                                           display_description={answers['b2z_class_code_description']}/>
                  </Grid>
                ) : answers['business.b2z_class_codes'].length > 0 && (
                  /**
                   * Condition is active when class code recommendations are available from Advisor service
                   * after initial page load or when customer selects class from dropdown.
                   */
                  <Grid container>
                    {answers['business.b2z_class_codes'].map((item, idx) => (
                        <IndustryClassCard {...item}
                                           key={idx}
                                           selected={item.id === business_type}
                                           onSelected={industryClassesLoading ? false : async (e) => {
                                             await setSelected(item.id)
                                           }}
                        />
                      )
                    )}
                  </Grid>
                ))}
              </Grid>
              {((answers['business.b2z_class_codes'].length > 0 && !showDropDown && !dropdownUsed) ||
              (answers['business.b2z_class_codes'].length > 0 && !showDropDown && !dropdownUsed)) && !industryClassesLoading ? (
                /**
                 * Displays text allowing user to clear class code recommendations or dropdown selection.
                 * Condition is active when one of the following conditions is met:
                 * 1. On initial page load when class code recommendations are available from Advisor service
                 * 2. Or, when customer selects class code from dropdown
                 */
                <Grid item container xs={12} style={{marginTop: '20px'}}>
                  <Grid item xs={12}>
                    <span className={'underline_link'} onClick={() => onNoneDescribesIndustry()}>{t('page.ic.none_describes')}</span>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          ) : (
            /**
             * Displays dropdown selector if clear() function is activated or 0 class code recommendations are
             * available from Advisor service during initial page load.  This is hidden from view after completing
             * a chat exchange with Diya bot.
             */
            industryClassesLoaded === true && (<Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Question type="typeahead"
                            fullWidth
                            question={t('page.ic.business_type')}
                            placeholder={t('form.placeholder.business_type')}
                            answer={business_type}
                            autoFocus
                            options={props.industry_classes}
                            noOptionsText={props.industry_classes.length === 0? 'No industry classes': t('form.class_no_options_text')}
                            renderOption
                            onChange={(keyId, desc, id) => updateBusinessType(id, desc)}
                            error={!!error?.business_type}
                            helperText={t('form.error.business_type')}/>
                </Grid>
              </Grid>
            )
          )}

          {error && (
              <Grid item xs={12}>
                  <p className={'error_txt error_txt_lg'}>{t('page.ic.not_selected_error')}</p>
              </Grid>
          )}


          <Grid container className={'section_navigation is_back_btn'}>
            {processing || industryClassesLoading ? (
              <LoadingButton/>
            ) : (<>
                <button className="section_back_btn"
                        type={'button'}
                        onClick={() => {
                          goBack()
                        }}>
                  <span className={'a_btn a_btn_transparent'}>
                    <ArrowBack/>
                  </span>
                  <span className="a_mob_back">Back</span>
                </button>
                <NextSectionButton
                  validateFormMethod={(e) => validateForm(e)}
                  className="section_next_btn"/>
                <NextSectionButton
                  validateFormMethod={(e) => validateForm(e)}
                  className="section_next_btn__mobile"/>
              </>
            )}
          </Grid>

        </form>
      </div>
    )
})

const mapStateToProps = state => {
    return {
        unique_session_id: state.insurance.unique_session_id,
        answers: state.insurance.answers,
        subsections: state.insurance.subsections,
        industry_classes: state.insurance.industry_classes,
        fetched_industry_classes: state.insurance.fetched_industry_classes,
        current_subsection: state.insurance.current_subsection,
        is_choose_naics_manually: state.insurance.is_choose_naics_manually,
        coverage_types: state.insurance.coverage_types,
        filteredCoverageTypes: state.insurance.filteredCoverageTypes,
        isPartner: state.insurance.isPartner,
        partnerData: state.insurance.partnerData
    }
}

const mapDispatchToProps = {
    changeAnswers,
    updateInsStoreKey,
    updateProgress,
    updateAppProducts,
    updateIndustryClasses,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IndustryClass))
