import React from "react";
import {withTranslation} from 'react-i18next';
import ClaimFAQItem from "./ClaimFAQItem";

const SidebarAds = () => {
    const claimFAQ = [
        {
            question: "How do I contact Acrisure?",
            answer: `Acrisure Customer Experience is available M-Th 8:30 - 5:30pm ET, F 8:30am - 5:00pm ET.<br/>
                    By phone: <a href="tel:800-330-1750">800-330-1750</a><br/>
                    By email: <a href="mailto:cl_service@acrisure.com">cl_service@acrisure.com</a>`
        },
        {
            question: "How do I change my plan or coverage?",
            answer: `We can review your plan or coverage with you and make any changes you need. Just give us a call at 800-330-1750 M-Th 8:30 - 5:30pm ET, F 8:30am - 5:00pm ET. We're happy to help!`
        }
    ]

    return (
        <div className="grid_item sidebar_faq_cnt">
            <div className="blk_sidebar claims_sidebar portal_card">
                <p className="sidebar_ttl">FAQ</p>
                {claimFAQ.map((item, key) => {
                    return (<ClaimFAQItem key={key} {...item}/>)
                })}
            </div>
        </div>
    )
}

export default withTranslation()(SidebarAds)
