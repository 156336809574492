import React from "react";
import InsuranceErrorBoundary from "../pages/errors/InsuranceErrorBoundary";
import { datadogRum } from '@datadog/browser-rum';
class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
      const renderingError = new Error(error.message);
      renderingError.name = `ReactRenderingError`;
      renderingError.stack = errorInfo.componentStack;
      renderingError.cause = error;
      datadogRum.addError(renderingError);
    }

    render() {
        if (this.state.hasError) {
            return <InsuranceErrorBoundary/>;
        }

        return this.props.children;
    }

}

export default ErrorBoundary;