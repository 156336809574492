import React from "react";

class NumberQuestion extends React.Component {

    state = {
        above_max: false
    }

    verifyMaxValue(max_value = null, value) {
        let val = value
        if(max_value && value > max_value) {
            this.setState({
                above_max: true
            })
            val = max_value

        } else this.setState({
            above_max: false
        })

        return val
    }

    render() {
        return null
    }
}

export default NumberQuestion