import React from 'react';
import moment from "moment";
import {formatCurrency} from "../../../helpers/currency";

const PaymentItem = (props) => {
    return (
        <div className="row payment_item_sidebar">
            <p className="policy_number">Policy<br/> {props.policyNumber || props.policy_number}</p>
            <div className="column_right">
                <p className="payment_price">{formatCurrency(props.amount)}</p>
                <p className="payment_date">{moment(props.paid_date).format('MM/DD/YY')}</p>
            </div>
        </div>
    );
}

export default PaymentItem;