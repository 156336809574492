import React from 'react'

export const MoreEmployeesIcon = () => (
    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.8991 23.69L20.0991 17.285C19.9857 16.3778 19.5448 15.5432 18.8593 14.9383C18.1738 14.3333 17.2909 13.9996 16.3766 14H13.6216C12.7078 14.0003 11.8254 14.3342 11.1405 14.9391C10.4555 15.544 10.0149 16.3782 9.9016 17.285L9.10035 23.69C9.05638 24.0419 9.08777 24.3991 9.19245 24.7379C9.29712 25.0768 9.47269 25.3894 9.70748 25.6552C9.94228 25.921 10.2309 26.1338 10.5543 26.2794C10.8776 26.4251 11.2282 26.5003 11.5828 26.5H18.4178C18.7724 26.5001 19.1229 26.4248 19.446 26.279C19.7692 26.1333 20.0577 25.9205 20.2924 25.6547C20.527 25.389 20.7025 25.0764 20.8071 24.7376C20.9117 24.3989 20.9431 24.0418 20.8991 23.69V23.69Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 9C17.0711 9 18.75 7.32107 18.75 5.25C18.75 3.17893 17.0711 1.5 15 1.5C12.9289 1.5 11.25 3.17893 11.25 5.25C11.25 7.32107 12.9289 9 15 9Z" stroke="#827252" strokeWidth="2"/>
        <path d="M5 12.75C6.38071 12.75 7.5 11.6307 7.5 10.25C7.5 8.86929 6.38071 7.75 5 7.75C3.61929 7.75 2.5 8.86929 2.5 10.25C2.5 11.6307 3.61929 12.75 5 12.75Z" stroke="#000000" strokeWidth="2"/>
        <path d="M25 12.75C26.3807 12.75 27.5 11.6307 27.5 10.25C27.5 8.86929 26.3807 7.75 25 7.75C23.6193 7.75 22.5 8.86929 22.5 10.25C22.5 11.6307 23.6193 12.75 25 12.75Z" stroke="#000000" strokeWidth="2"/>
        <path d="M5.00023 16.5H4.61773C4.02593 16.4999 3.4533 16.7098 3.0017 17.0923C2.5501 17.4747 2.24883 18.005 2.15148 18.5887L1.73523 21.0887C1.67549 21.4469 1.69451 21.8138 1.79094 22.1639C1.88738 22.514 2.05892 22.8389 2.29364 23.116C2.52836 23.393 2.82062 23.6156 3.1501 23.7683C3.47957 23.921 3.83835 24 4.20148 24H8.75023" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25 16.5H25.3825C25.9743 16.4999 26.5469 16.7098 26.9985 17.0923C27.4501 17.4747 27.7514 18.005 27.8487 18.5887L28.265 21.0887C28.3247 21.4469 28.3057 21.8138 28.2093 22.1639C28.1129 22.514 27.9413 22.8389 27.7066 23.116C27.4719 23.393 27.1796 23.6156 26.8501 23.7683C26.5207 23.921 26.1619 24 25.7987 24H21.25" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>  
)
