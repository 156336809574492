import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { updateProgress } from '../../store/insurance/actions'
import SectionHead from '../../components/insurance/SectionHead'
import {checkStartPoint} from '../../helpers/CheckStartPoint'
//scss
import '../../scss/v2/_you_r_covered.scss'

const YouAreCovered = (props) => {

  const programId = checkStartPoint('program_id')

    useEffect(() => {
      if(!programId){
        props.updateProgress({
          current_section: 0,
          current_subsection: 0
        })
        window.scrollTo({top: 0, behavior: 'smooth'})
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = props

  return (
    <div className={'you_r_covered_section'}>
      <form autoComplete="off" className={'section_container'}>
        <SectionHead
          title={t('page.you_r_covered.head_ttl')}
          txt={''}/>
        <Grid container spacing={2}>
          <Grid item className={'section_form section_you_r_covered'}>
            <div className={'congrats'}
                 dangerouslySetInnerHTML={{__html: t('page.you_r_covered.congrats')}}></div>
                </Grid>
            </Grid>
        </form>
      </div>
    )
}

const mapStateToProps = state => {
  return {
    subsections: state.insurance.subsections,
  }
}

const mapDispatchToProps = {
  updateProgress
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(YouAreCovered))
