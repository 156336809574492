import React, { useEffect } from 'react'
import { connect, useStore } from 'react-redux'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import axios from 'axios'
import { portal_routes } from '../routes/web'
import Footer from '../layouts/portal/Footer'
import Header from '../layouts/portal/Header'
import NotFoundError from './errors/NotFoundError'
import ContactFooter from '../layouts/portal/ContactFooter'

import moment from 'moment'
import { setFailedInstallments, setPolicies, setPoliciesLoading } from '../store/portal/policies/actions'
import withWidth from '@material-ui/core/withWidth'
import '../scss/v2/portal/_common.scss'

const Portal = (props) => {

  let store = useStore()
  let history = useHistory()
  let { path } = useRouteMatch()

  const checkFailedInstallment = (installment) => {
    let due_date = moment(installment.due_date)
    let today = moment(new Date())

    return today.diff(due_date, 'days') > 1 && installment.paid_date === null
  }

  useEffect(() => {
    let failedInstalments = []
    props.policiesList.filter(policy => policy.billing_method !== 'DIRECT').forEach(policy => {
      const installments = policy.installments
      installments.forEach(instalment => {
        if (checkFailedInstallment(instalment)) {
          failedInstalments.push({
            quoteId: policy.quote_id,
            policyNumber: policy.quote.policy_number,
            ...instalment
          })
        }
      })
    })
    props.setFailedInstallments(failedInstalments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.policiesList])

  useEffect(() => {
    const getPolicies = () => {
      axios.get('/api/policies')
        .then(async (data) => {
          if (data.data && data.data.length > 0) {
            // this.checkIfAllDocumentsDone(data.data)
            let policies = data.data

            const filteredPolicies = policies.filter(item => {
              let currentDate = moment(new Date())
              let futureMonth = moment(item.expiration_date).add(1, 'M')
              return currentDate.diff(futureMonth, 'days') < 0 && item['policy_status'] !== 'UNDERWRITER_REVIEW'
            })

            props.setPolicies(filteredPolicies)
            props.setPoliciesLoading(false)
          } else {
            props.setPolicies([])
            props.setPoliciesLoading(false)
          }
        }).catch(e => {
          props.setPolicies([])
          props.setPoliciesLoading(false)
          throw new Error(`Can't get user's policies. ${e}`)
      })
    }

    getPolicies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header/>
      <div className="portal_content">
        <Switch>
          {portal_routes.map((route, i) => {
              return !store.getState().portal.isAuthorized
                ? (<Redirect to={{
                  pathname: '/sign-in',
                  state: { from: history.location.pathname }
                }}
                />)
                : (<Route key={i} path={path + route.path}>
                  <route.component history={history}/>
                </Route>)
            }
          )}
          <Route key="error" path={path + '*'}>
            <NotFoundError history={history}/>
          </Route>
        </Switch>
      </div>
      <ContactFooter/>
      <Footer/>
    </>
  )
}

const mapStateToProps = state => {
  return {
    policiesList: state.policies.policiesList
  }
}

const mapDispatchToProps = {
  setPolicies,
  setFailedInstallments,
  setPoliciesLoading
}
export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(Portal))