import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import axios from 'axios'
import {FormControl, Grid, TextField} from '@material-ui/core'
import { changeAnswers } from '../../../store/insurance/actions'
import DoRequest from '../../../hooks/do-request'
import CheckUserExists from '../../../hooks/check-user-exists'
import RegisterNewUser from '../../../hooks/register-new-user'
import GreenCheckmark from '../../icons/GreenCheckmark'
import LoadingButton from '../../LoadingButton'
import Question from '../../form-components/Question'
import paramaterizedJSX from '../../../helpers/paramaterizedJSX'
import {datadogRum} from '@datadog/browser-rum'

import '../../../scss/v2/_preferred_email.scss'

const PreferredEmail = ({isNewUser, answers, full_quote,t, changeAnswers}:
                            {isNewUser: boolean, answers: {[key: string]: any}, full_quote: any, t: any,
                                changeAnswers: (answers: {[key: string]: any}) => void})=> {

    const firstEmail = answers['contact.email']
    const [emailUpdated, setEmailUpdated] = useState<boolean>(false)
    const [emailValidationProcessing, setEmailValidationProcessing] = useState<boolean>(false)
    const [procceedEmailValidate, setProcceedEmailValidate] = useState<boolean>(false)
    const [emailConfirmed, setEmailConfirmed] = useState<boolean>(true)
    const [contactEmail, setContactEmail] = useState<string>('')
    const [confirmEmail, setConfirmEmail] = useState<string>('')
    const [errors, setErrors] = useState<{[key: string]: string}>({})

    const onSetEmailConfirmedHandler = (v)=>{
        setErrors({})
        setContactEmail('')
        setConfirmEmail('')
        setEmailConfirmed(v)
    }

    const onValidateEmailHandler = (emailValue)=>{
        return /^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(emailValue)
    }
    const onChangeEmailHandler = (id, field)=>{
        let errorsObj = errors
        let error

        switch (field) {
        case 'contactEmail':
            error = !onValidateEmailHandler(id.target.value)? 'Email entered is invalid.': null
            if (error != null) {
                errorsObj[field] = error
            }else if(firstEmail === id.target.value){
                errorsObj[field] = 'The email address is the same'
            }else{
                delete errorsObj[field]
            }
            setErrors(errorsObj)
            setContactEmail(id.target.value)
            break

        case 'confirmEmail':
            error = id.target.value !== contactEmail ? 'Provided emails do not match.': null
            if(error != null){
                errorsObj[field] = error
            }else{
                delete errorsObj[field]
                if(id.target.value !== '' && firstEmail !== id.target.value && onValidateEmailHandler(contactEmail)){
                    setProcceedEmailValidate(true)
                }
            }
            setErrors(errorsObj)
            setConfirmEmail(id.target.value)
            break

        default:
            break
        }
    }

    //Make call to API for validation new email address
    const emailValidationHelper = async (emailToValidate)=>{
        try {
            const response = await axios.post('/api/validate-email', {
                email: emailToValidate
            })
            const {status}  = response.data

            return  status

        } catch (e) {
            return e
        }
    }

    //Update quote after successful changed email
    const updateQuote = async (answers) => {
        const data = await full_quote.map(quote => {
            return DoRequest({
                url: '/api/session/quote',
                method: 'put',
                body: {
                    quote: quote.id,
                    data: {...answers}
                }
            })
        })
        return await Promise.all(data)
    }

    useEffect(() => {
        if(procceedEmailValidate){
            let errorsObj = errors
            let error
            setEmailValidationProcessing(true)
            emailValidationHelper(contactEmail).then(async res => {

                if (res === 'valid' || res === 'unknown') {
                    changeAnswers({'contact.email': contactEmail})
                    answers['contact.email'] = contactEmail
                    await updateQuote(answers)

                    const newUserStatus = await CheckUserExists(contactEmail)
                    if (!newUserStatus?.data['exists']) await RegisterNewUser(answers, false)

                    setEmailValidationProcessing(false)
                    setEmailUpdated(true)
                    datadogRum.setUserProperty('email', contactEmail)
                    setErrors({})
                    setProcceedEmailValidate(false)

                } else if (res === 'invalid') {
                    setEmailValidationProcessing(false)
                    setProcceedEmailValidate(false)
                    error = 'Email entered is invalid.'

                } else {
                    error = 'Something went wrong. Please try again later.'
                    setEmailValidationProcessing(false)
                    setProcceedEmailValidate(false)
                }

                if (error != null) {
                    errorsObj['commonEmail'] = error
                }else{
                    delete errorsObj['commonEmail']
                }

            }).catch(e=>{
                if (error != null) {
                    errorsObj['commonEmail'] = e.message
                }else{
                    delete errorsObj['commonEmail']
                }
            })
        }
        // eslint-disable-next-line
    }, [procceedEmailValidate])


    return (
        <div>
            {isNewUser && <Grid container className={'preferred_email_blk'}>


                {emailUpdated? <Grid item xs={12} className="form-input-column ">
                    <div className="email_confirmed">
                        <GreenCheckmark className={'icon_email_confirmed'}/>
                        <p>Your email address has been successfully updated.</p></div>
                </Grid>: emailValidationProcessing ? <LoadingButton/>:

                    <Grid container spacing={1}>
                        {/* Confirm Email Yes/No */}
                        <Grid item xs={12} className="form-input-column label-large confirm-email-column">
                            <Question
                                type="boolean"
                                fullWidth
                                answer={emailConfirmed}
                                question={paramaterizedJSX(t('page.checkout.email_question.txt'), '{*}', `${answers['contact.email']}`, 'bolded-email')}
                                options={t('page.checkout.email_question_opts', {returnObjects: true})}
                                onChange={(_e, v) => onSetEmailConfirmedHandler(v)}
                            />
                        </Grid>

                        {/* Enter email address */}
                        {!emailConfirmed && <Grid item xs={12} className="form-input-column">
                            <FormControl fullWidth error={!!contactEmail}
                                className={'form_control'}>
                                <div className="label_blk">
                                    <div className="label">{t('page.checkout.email_question.label')}</div>
                                </div>
                                <TextField
                                    className='fieldText'
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={contactEmail}
                                    onChange={(event) => onChangeEmailHandler(event, 'contactEmail')}
                                />
                                {errors && errors['contactEmail'] && (
                                    <p className="error_txt error_txt_md">{errors['contactEmail']}</p>
                                )}
                            </FormControl>
                        </Grid>}

                        {/* Confirm email address */}
                        {!emailConfirmed && <Grid item xs={12} className="form-input-column">
                            <FormControl fullWidth error={(confirmEmail !== '' && confirmEmail !== contactEmail)} className={'form_control'}>
                                <div className="label_blk">
                                    <div className="label">{t('page.checkout.confirm_email_question')}</div>
                                </div>

                                <TextField
                                    className='fieldText'
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={confirmEmail}
                                    onChange={(event) => onChangeEmailHandler(event, 'confirmEmail')}
                                />
                                {errors && errors['confirmEmail'] && (
                                    <p className="error_txt error_txt_md">{errors['confirmEmail']}</p>
                                )}
                            </FormControl>
                        </Grid>}
                    </Grid>}
            </Grid>}
        </div>
    )
    
}



const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
    }
}

const mapDispatchToProps = {
    changeAnswers,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PreferredEmail))