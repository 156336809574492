import Main from "../pages/Main";
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
// import FileAClaim from "../pages/portal/FileAClaim";
// import Claims from "../pages/portal/Claims";
import Profile from "../pages/portal/Profile";
import FinalizeRegistration from "../pages/auth/FinalizeRegistration";
import MarketingUnsubscribe from "../pages/insurance/MarketingUnsubscribe";
import PortalHome from "../pages/portal/Home";
import PaymentCenter from "../pages/portal/PaymentCenter";
import AfterCheckout from '../pages/static/AfterCheckout'

export const routes = [
    {
        title: 'Unsubscribe',
        path: '/unsubscribe',
        component: MarketingUnsubscribe,
    },
    {
        title: 'Sign In',
        path: '/sign-in',
        component: SignIn,
    },
    {
        title: 'Reset password',
        path: '/reset',
        component: ResetPassword,
    },
    {
        title: '',
        path: '/finalize-registration',
        component: FinalizeRegistration
    },
  {
        title: '',
        path: '/after-checkout',
        component: AfterCheckout
    },
    {
        title: 'Home',
        path: "/",
        component: Main
    }
]

export const portal_routes = [
    {
        title: 'Summary Page',
        path: '/home',
        component: PortalHome,
    },
    /*{
        title: 'File a Claim',
        path: '/claim',
        component: FileAClaim,
    },*/
    {
        title: 'Payment Center',
        path: '/payment-center',
        component: PaymentCenter,
    },
   /* {
        title: 'Submitted claims',
        path: '/claims',
        component: Claims,
    },*/
    {
        title: 'Profile',
        path: '/profile',
        component: Profile,
    },
]