import DoRequest from "./do-request";

const CheckUserExists = async (email) => {
    return await DoRequest({
        url: "/api/user/check",
        method: "post",
        body: {
            username: email
        }
    })
}

export default CheckUserExists