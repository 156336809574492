import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { datadogRum } from '@datadog/browser-rum'
import { Alert } from '@material-ui/lab'
import FailedInstallments from '../../components/portal/payment/failedInstallments'
import Switch from '../../components/Switch'
import { withTranslation } from 'react-i18next'
import Loading from '../../components/Loading'
import { GoogleApiWrapper } from 'google-maps-react'
import { setUserAuth } from '../../store/portal/actions'
import LoadingButton from '../../components/LoadingButton'
import Question from '../../components/form-components/Question'
import SearchLocation from '../../components/portal/claim/SearchLocation'
import { FormControl, Grid, Snackbar, TextField, Typography } from '@material-ui/core'
import { ArrowLeft } from '../../components/icons/ArrowLeft'
import { Link } from 'react-router-dom'
import { unitedStates } from '../../common/UnitedStates'
import '../../scss/v2/portal/_profile.scss'

class Profile extends React.Component {

  state = {
    place_service: null,
    data_changed: false,
    edit_account_mode: false,
    edit_communications_mode: false,
    edit_password_mode: false,
    errors: {},
    loading: false,
    data: {
      'username': '',
      'first_name': '',
      'last_name': '',
      'street_address': '',
      'street_address2': '',
      'city': '',
      'state': '',
      'zip': '',
      'phone_number': '',
      'comm_pref_email': true,
      'comm_pref_sms': true,
      'comm_pref_phone': true
    },
    messages: []
  }

  constructor (props) {
    super(props)

    this.details_ref = React.createRef()
  }

  componentDidMount () {
    let place_service = new this.props.google.maps.places.PlacesService(this.details_ref.current),
      sessionToken = new this.props.google.maps.places.AutocompleteSessionToken()

    setTimeout(() => {
      this.setState({
        place_service: place_service,
        sessionToken: sessionToken
      })
    }, 0)
  }

  toggleEditMode (section, isEdit) {
    let userData = {
      'username': this.props.user_email,
      'first_name': this.props.name,
      'last_name': this.props.surname,
      'street_address': this.props.street_address,
      'street_address2': this.props.street_address2,
      'city': this.props.city,
      'state': this.props.state,
      'zip': this.props.zip,
      'phone_number': this.props.phone,
      'comm_pref_email': this.props.comm_pref_email,
      'comm_pref_sms': this.props.comm_pref_sms,
      'comm_pref_phone': this.props.comm_pref_phone,
      'password': null,
      'new_password': null,
      'confirm_password': null
    }
    switch (section) {
      case 'edit_account_mode':
        this.setState({
          edit_account_mode: isEdit,
          edit_communications_mode: false,
          edit_password_mode: false,
          data: userData
        })
        break
      case 'edit_communications_mode':
        this.setState({
          edit_account_mode: false,
          edit_communications_mode: isEdit,
          edit_password_mode: false,
          data: userData
        })
        break
      case 'edit_password_mode':
        this.setState({
          edit_account_mode: false,
          edit_communications_mode: false,
          edit_password_mode: isEdit,
          data: userData
        })
        break
      default:
        return {}
    }
  }

  getCompactAddress () {
    let line1 = ''
    if (this.props.street_address) line1 += this.props.street_address
    if (this.props.street_address2) line1 += ', ' + this.props.street_address2

    let line2 = ''
    if (this.props.city) line2 += this.props.city
    if (this.props.state) line2 += ', ' + this.props.state
    if (this.props.zip_code) line2 += ', ' + this.props.zip_code

    if (!line1 && !line2) return '---'

    return (<>{line1}<br/>{line2}</>)
  }

  onChange (field, value) {
    let data = { ...this.state.data }

    data[field] = value

    this.setState({ data: { ...data } })
  }

  onStreetChange (desc, newValue, reason) {
    if (newValue?.place_id) {
      this.getStreetDetails(newValue)
    }
  }

  getStreetDetails (place) {
    this.state.place_service.getDetails({
      placeId: place.place_id,
      fields: ['address_components', 'geometry'],
      sessionToken: this.state.sessionToken
    }, (place, status) => {
      this.updateStreetFields(place, status)
    })
  }

  updateStreetFields (place, status) {
    let answers = {
      'business.street_address': '',
      'business.street_address2': '',
      'business.state': '',
      'business.city': '',
      'business.zip_code': ''
    }

    if (status === this.props.google.maps.places.PlacesServiceStatus.OK && place) {

      place.address_components.forEach((item) => {
        if (item.types.includes('street_number')) {
          answers['business.street_address'] += item.long_name + ' '
        } else if (item.types.includes('route')) {
          answers['business.street_address'] += item.short_name
        } else if (item.types.includes('administrative_area_level_1')) {
          answers['business.state'] += item.short_name
        } else if (item.types.includes('locality')) {
          answers['business.city'] += item.short_name
        } else if (item.types.includes('sublocality')) {
          answers['business.city'] += item.short_name
        } else if (item.types.includes('room')) {
          answers['business.street_address2'] = item.long_name
        } else if (item.types.includes('floor')) {
          answers['business.street_address2'] = item.long_name
        } else if (item.types.includes('postal_code')) {
          answers['business.zip_code'] = item.long_name
        }
      })

      this.setState({
        data: {
          ...this.state.data,
          'street_address': answers['business.street_address'],
          'street_address2': answers['business.street_address2'],
          'city': answers['business.city'],
          'state': answers['business.state'],
          'zip': answers['business.zip_code']
        }
      })
    }
  }

  validate (e = null) {

    if (e) e.preventDefault()

    let errors = {}

    const { t } = this.props
    const pass_re = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/

    if (!/^.{2,}$/.test(this.state.data.first_name))
      errors.first_name = t('form.error.first_name_req')

    if (!/^.{2,}$/.test(this.state.data.last_name))
      errors.last_name = t('form.error.last_name_req')

    if (!/^.{2,}$/.test(this.state.data.street_address))
      errors.street_address = t('form.error.st_address_req')

    if (!/^.{2,}$/.test(this.state.data.city))
      errors.city = t('form.error.city_req')

    if (!/^.{2,}$/.test(this.state.data.state))
      errors.state = t('form.error.state_req')

    if (!/^[\d]{10}$/.test(this.state.data.phone_number))
      errors.phone_number = t('form.error.incorrect_phone')

    if (!/^\d{5,8}$/.test(this.state.data.zip))
      errors.zip = t('form.error.zip_req')

    if (this.state.data.password) {
      if (!pass_re.test(this.state.data.new_password)) errors.new_password = t('form.error.pass_string')

      if (this.state.data.new_password !== this.state.data.confirm_password) errors.confirm_password = t('form.error.rep_pass_mismatch')
    }

    this.setState({
      errors: errors,
      loading: Object.keys(errors).length === 0
    }, async () => {
      if (Object.keys(errors).length === 0) {
        let messages = []
        this.saveData()
          .then(async () => {
            messages.push(t('common.user_prop_saved_ok'))
            if (this.state.data.password) {
              this.changePassword()
                .then(() => {
                  messages.push(t('common.pass_updated_ok'))
                  this.finishSaving(messages)
                })
                .catch((e) => {
                  this.setState({
                    errors: {
                      common: e.response.data.message
                    },
                    loading: false
                  })
                  throw new Error(`Can't update user password. ${e}`)
                })
            } else this.finishSaving(messages)
          })
          .catch((e) => {

            this.setState({
              errors: {
                common: t('common.user_prop_not_saved')
              },
              loading: false
            })
            throw new Error(`Can't update user data. ${e}`)
          })

      }
    })
  }

  async saveData () {
    return await axios.post('/api/user/change-data', {
      'first_name': this.state.data.first_name,
      'last_name': this.state.data.last_name,
      'street_address': this.state.data.street_address,
      'street_address2': this.state.data.street_address2,
      'city': this.state.data.city,
      'state': this.state.data.state,
      'zip': this.state.data.zip.toString(),
      'phone_number': this.state.data.phone_number.toString(),
      'comm_pref_email': this.state.data.comm_pref_email,
      'comm_pref_sms': this.state.data.comm_pref_sms,
      'comm_pref_phone': this.state.data.comm_pref_phone
    }).then(() => {
      datadogRum.setUserProperty('name', `${this.state.data.first_name} ${this.state.data.last_name}`)
      return true
    })
  }

  async changePassword () {
    return await axios.post('/api/user/change-pass', {
      'username': this.props.user_email,
      'password': this.state.data.password,
      'new_password': this.state.data.new_password
    }).then(() => {
      return true
    })
  }

  finishSaving (messages) {
    axios.get('/api/user/update-data')
      .then(response => {
        this.props.setUserAuth(response.data)
        this.setState({
          edit_account_mode: false,
          edit_communications_mode: false,
          edit_password_mode: false,
          loading: false,
          messages: messages
        })
      })
      .catch((e) => {
        throw new Error(`Can't update user data without pass update. ${e}`)
      })
  }

  closeNotification () {
    this.setState({ messages: [] })
  }

  render () {
    const { t } = this.props

    return (
      <div className="portal_page portal_page_back portal_profile_page">
        {this.props.failedInstallmentsList.length ? <div className="cnt_full portal_failed_installments_cnt"><FailedInstallments
          classNme={'portal_back_cnt'} failedInstallments={this.props.failedInstallmentsList}/></div> : null}

        <div className="cnt_full portal_back_cnt">
          <Link to={'/portal/home'}
                className={'portal_back_btn'}><ArrowLeft/><span>Back to Portal</span></Link>
        </div>

        <div className="cnt_full">
          <div className="cnt head_cnt">
            <h1 className={'head_ttl'}>{t('portal.account.title')}</h1>
          </div>
        </div>
        <div className="cnt_full">
          <div className="cnt">
            <div className="portal_card">
              {this.state.edit_account_mode ?
                (<Grid container spacing={2} className={'profile_edit_group'}>
                  <Grid item xs={12}>
                    <p className="profile_ttl">{t('portal.account.title')}</p>
                  </Grid>
                  <Grid item xs={12} sm={8} md={6}>
                    <Question fullWidth
                              type="text"
                              question={t('form.label.first_name')}
                              answer={this.state.data.first_name}
                              onChange={(id, val) => {
                                this.onChange('first_name', val)
                              }}
                              error={this.state.errors?.first_name}
                              helperText={this.state.errors?.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={6}>
                    <Question fullWidth
                              type="text"
                              question={t('form.label.last_name')}
                              answer={this.state.data.last_name}
                              onChange={(id, val) => {
                                this.onChange('last_name', val)
                              }}
                              error={this.state.errors?.last_name}
                              helperText={this.state.errors?.last_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={6}>
                    <FormControl fullWidth className={'form_control'}>
                      <div className="label_blk">
                        <div className="label">{t('form.label.email')}</div>
                      </div>
                      <TextField
                        className={'fieldText'}
                        variant="outlined"
                        type="email"
                        value={this.state.data.username}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={8} md={6}>
                    <Question fullWidth
                              type="phone"
                              question={t('form.label.phone_num')}
                              answer={this.state.data.phone_number}
                              onChange={(id, val) => {
                                this.onChange('phone_number', val)
                              }}
                              error={this.state.errors?.phone_number}
                              helperText={this.state.errors?.phone_number}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SearchLocation
                          fullWidth
                          google={this.props.google}
                          question={t('form.label.address_line1')}
                          answer={this.state.data.street_address}
                          error={this.state.errors?.street_address}
                          onChangeLocation={(desc, newValue, reason) => {
                            this.onStreetChange(desc, newValue, reason)
                          }}
                          onChange={(newValue) => {
                            this.onChange('street_address', newValue)
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Question fullWidth
                                  type="text"
                                  question={t('form.label.address_line2')}
                                  answer={this.state.data.street_address2}
                                  onChange={(id, val) => {
                                    this.onChange('street_address2', val)
                                  }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Question fullWidth
                                  type="text"
                                  question={t('form.label.city')}
                                  answer={this.state.data.city}
                                  onChange={(id, val) => {
                                    this.onChange('city', val)
                                  }}
                                  error={this.state.errors?.city}
                                  helperText={this.state.errors?.city}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Question fullWidth
                                  type="dropdown"
                                  question={t('form.label.state')}
                                  options={unitedStates}
                                  answer={this.state.data.state}
                                  onChange={(id, val) => {
                                    this.onChange('state', val)
                                  }}
                                  error={this.state.errors?.state}
                                  helperText={this.state.errors?.state}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Question fullWidth
                                  type="zip"
                                  question={t('form.label.zip')}
                                  autoComplete="zip"
                                  answer={this.state.data.zip}
                                  onChange={(id, val) => {
                                    this.onChange('zip', val)
                                  }}
                                  error={this.state.errors?.zip}
                                  helperText={this.state.errors?.zip}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <p className={'profile_edit_disclaimer_txt'}>Changing your mailing address
                      here does not change your policy information or your billing
                      information. To update your business’s physical address, please call us
                      at 1-800-330-1750. To update your billing address, please visit the
                      &nbsp;<Link to={'/portal/payment-center'}>Payment Center</Link>.</p>
                  </Grid>
                  <Grid item xs={12} className="controls">
                    <button type={'button'}
                            className={'a_btn a_btn_outlined'}
                            onClick={() => this.setState({ edit_account_mode: false })}
                            disabled={this.state.loading}
                    >{t('common.btn_cancel')}</button>
                    {this.state.loading ? (
                      <LoadingButton/>
                    ) : (
                      <button type={'button'} className={'a_btn a_btn_action_1 btn_save'}
                              disabled={this.state.loading}
                              onClick={() => {
                                this.validate()
                              }}
                      >{t('common.btn_save_changes')}</button>
                    )}
                  </Grid>
                </Grid>)
                : (<div className="profile_group_info account_info">
                  <div className="profile_row profile_row_actions">
                    <p className="profile_ttl">{t('portal.account.title_info')}</p>

                    <button type={'button'} className={'a_btn a_btn_action_3 profile_edit_btn'}
                            disabled={(this.state.edit_communications_mode || this.state.edit_password_mode)}
                            onClick={() => this.toggleEditMode('edit_account_mode', true)}
                    >{t('common.btn_edit')}</button>
                  </div>
                  <div className="profile_row profile_row_info">
                    <p className="sub_ttl">Mailing Address</p>
                    <ul>
                      <li className={'profile_txt profile_txt_semibold'}>{this.props.name} {this.props.surname}</li>
                      <li className={'profile_txt'}>{this.getCompactAddress()}</li>
                      <li className={'profile_txt'}>{this.props.user_email}</li>
                      <li className={'profile_txt'}>{this.props.phone ? '+1' + this.props.phone : '---'}</li>
                    </ul>
                  </div>
                </div>)}


              {this.state.edit_communications_mode ?
                (<Grid container spacing={2} className={'profile_edit_group'}>
                  <Grid item xs={12}>
                    <p className="profile_ttl">{t('portal.account.title_communication')}</p>
                  </Grid>
                  <Grid item xs={12} sm={10}
                        md={8}><p className={'comm_pref_subtitle'}>{t('portal.account.update_sub_title')}</p>
                  </Grid>
                  <Grid item xs={12} sm={10} md={8}>
                    <div className="switch_config">
                      <div className="description">
                        <div
                          className="profile_edit_txt_bold">{t('portal.account.comm_pref_email_label')}</div>
                        <div
                          className="profile_edit_txt">{t('portal.account.comm_pref_email_txt')}</div>
                      </div>
                      <div className="switch">
                        <Switch checked={this.state.data.comm_pref_email}
                                onChange={(value) => {
                                  this.onChange('comm_pref_email', value)
                                }}/>
                      </div>
                    </div>
                    <div className="switch_config">
                      <div className="description">
                        <div
                          className="profile_edit_txt_bold">{t('portal.account.comm_pref_phone_label')}</div>
                        <div
                          className="profile_edit_txt">{t('portal.account.comm_pref_phone_txt')}</div>
                      </div>
                      <div className="switch">
                        <Switch checked={this.state.data.comm_pref_phone}
                                onChange={(value) => {
                                  this.onChange('comm_pref_phone', value)
                                }}/>
                      </div>
                    </div>
                    <div className="switch_config">
                      <div className="description">
                        <div
                          className="profile_edit_txt_bold">{t('portal.account.comm_pref_sms_label')}</div>
                        <div
                          className="profile_edit_txt">{t('portal.account.comm_pref_sms_txt')}</div>
                      </div>
                      <div className="switch">
                        <Switch checked={this.state.data.comm_pref_sms}
                                onChange={(value) => {
                                  this.onChange('comm_pref_sms', value)
                                }}/>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="controls">
                    <button type={'button'}
                            className={'a_btn a_btn_outlined'}
                            onClick={() => this.setState({ edit_communications_mode: false })}
                            disabled={this.state.loading}
                    >{t('common.btn_cancel')}</button>
                    {this.state.loading ? (
                      <LoadingButton/>
                    ) : (
                      <button type={'button'}
                              className={'a_btn a_btn_action_1 btn_save'}
                              disabled={this.state.loading}
                              onClick={() => {
                                this.validate()
                              }}
                      >{t('common.btn_save_changes')}</button>
                    )}
                  </Grid>
                </Grid>)
                : (<div className="profile_group_info communication_preferences">
                  <div className="profile_row profile_row_actions">
                    <p className="profile_ttl">{t('portal.account.title_communication')}</p>
                    <button type={'button'}
                            className={'a_btn a_btn_action_3 profile_edit_btn'}
                            disabled={(this.state.edit_account_mode || this.state.edit_password_mode)}
                            onClick={() => this.toggleEditMode('edit_communications_mode', true)}
                    >{t('common.btn_edit')}</button>
                  </div>
                  <div className="profile_row profile_row_info">
                    <p className={'profile_txt'}>Weekly Email updates about B2Z</p>
                    <p className={'profile_txt profile_txt_semibold'}>{this.props.comm_pref_email ? t('common.yes') : t('common.no')}</p>
                  </div>
                </div>)}


              {this.state.edit_password_mode ?
                (<Grid container spacing={2} className={'profile_edit_group'}>
                  <Grid item xs={12}>
                    <p className="profile_ttl">{t('portal.account.title_change_pass')}</p>
                  </Grid>
                  <Grid item xs={12} sm={10} md={8}>
                    <Question fullWidth
                              type="password"
                              question={t('auth.current_pass')}
                              autoComplete="old-pass"
                              tooltip={t('auth.current_pass_tooltip')}
                              answer={this.state.data.password}
                              error={this.state.errors?.password}
                              helperText={this.state.errors?.password}
                              onChange={(id, val) => {
                                this.onChange('password', val)
                              }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} md={8}>
                    <Question fullWidth
                              type="password"
                              question={t('auth.new_pass')}
                              autoComplete="new-pass"
                              answer={this.state.data.new_password}
                              tooltip={t('auth.new_pass_tooltip')}
                              error={this.state.errors?.new_password}
                              helperText={this.state.errors?.new_password}
                              onChange={(id, val) => {
                                this.onChange('new_password', val)
                              }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} md={8}>
                    <Question fullWidth
                              type="password"
                              question={t('auth.confirm_pass')}
                              autoComplete="confirm-pass"
                              answer={this.state.data.confirm_password}
                              tooltip={t('auth.confirm_pass_tooltip')}
                              error={this.state.errors?.confirm_password}
                              helperText={this.state.errors?.confirm_password}
                              onChange={(id, val) => {
                                this.onChange('confirm_password', val)
                              }}
                    />
                  </Grid>
                  {this.state.errors.common && (
                    <Grid item xs={12} sm={10} md={8}>
                      <Typography color="error">{this.state.errors.common}</Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} className="controls">
                    <button type={'button'}
                            className={'a_btn a_btn_outlined'}
                            onClick={() => this.setState({ edit_password_mode: false })}
                            disabled={this.state.loading}
                    >{t('common.btn_cancel')}</button>
                    {this.state.loading ? (
                      <LoadingButton/>
                    ) : (
                      <button type={'button'}
                              className={'a_btn a_btn_action_1 btn_save'}
                              disabled={this.state.loading}
                              onClick={() => {
                                this.validate()
                              }}
                      >{t('common.btn_save_changes')}</button>
                    )}
                  </Grid>
                </Grid>)
                : (<div className="profile_group_info profile_password">
                  <div className="profile_row profile_row_actions">
                    <p className="profile_ttl">Password</p>
                    <button type={'button'}
                            className={'a_btn a_btn_action_3 profile_edit_btn'}
                            disabled={(this.state.edit_communications_mode || this.state.edit_account_mode)}
                            onClick={() => this.toggleEditMode('edit_password_mode', true)}
                    >{t('common.btn_edit')}</button>
                  </div>
                  <div className="profile_row profile_row_info ">
                    <p className={'profile_txt'}>XXXXXXXXXXX</p>
                  </div>
                </div>)}
            </div>
          </div>
          <div id="details" ref={this.details_ref}/>

        </div>
        {this.state.messages.length > 0 && (
          <Snackbar open autoHideDuration={2000} onClose={() => {
            this.closeNotification()
          }}>
            <Alert onClose={() => {
              this.closeNotification()
            }} severity="success">
              {this.state.messages.join('. ')}
            </Alert>
          </Snackbar>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    failedInstallmentsList: state.policies.failedInstallmentsList,
    name: state.portal.name,
    surname: state.portal.surname,
    phone: state.portal.phone,
    street_address: state.portal.street_address,
    street_address2: state.portal.street_address2,
    city: state.portal.city,
    state: state.portal.state,
    zip: state.portal.zip,
    comm_pref_email: state.portal.comm_pref_email,
    comm_pref_sms: state.portal.comm_pref_sms,
    comm_pref_phone: state.portal.comm_pref_phone,
    user_email: state.portal.username,
    token_exp: state.portal.token_exp,
    google_api_key: state.app.google_api_key
  }
}

const mapDispatchToProps = {
  setUserAuth
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper(
  (props) => {
    return {
      apiKey: props.google_api_key,
      language: 'us',
      libraries: ['places'],
      region: 'US',
      LoadingContainer: Loading
    }
  })(withTranslation()(Profile)))
