import React from "react";
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, TextField} from "@material-ui/core";

export default class Email extends React.Component {

    onChange(e) {
        const value = e.target.value

        if (this.props.onChange) this.props.onChange(this.props.id, value.toLowerCase())
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth} error={this.props.error} className={'form_control'}>
                <div className="label_blk">
                    <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                    {this.props.autofilled && <AutofilledBadge/>}
                </div>
                <TextField
                    variant="outlined"
                    type="email"
                    className={'fieldText'}
                    {...this.props}
                    value={this.props.answer}
                    error={!!this.props.error}
                    placeholder={this.props.placeholder}
                    helperText={null}
                    onChange={(e) => {
                        this.onChange(e)
                    }}
                />
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}