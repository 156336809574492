import React, {
    useRef,
    useContext,
    useEffect,
    useState
} from 'react'
import {FormControl, TextField} from '@material-ui/core'
import InputMask from 'react-input-mask'
// import CardIcon from '@material-ui/icons/Payment'
import {number} from 'card-validator'
import {CardNumberVerification} from 'card-validator/dist/card-number'

import {CreditCardDataContext} from '../custom/CreditCardFields'
import {absLenght} from './converters'
import {InputProps} from './helper.types'

const CreditCardNumberField = ({leaveFieldCallback, focus, tabIndex, fieldError}: InputProps) => {
    const [, setCardType] = useState('')
    const [error, setError] = useState(false)
    const [info, setInfo] = useState('')
    const inputRef = useRef<HTMLInputElement>(null!)
    const CardContext = useContext(CreditCardDataContext)

    const handleChange = (event: any) => {
        const cardNumberValue: string = event?.target?.value
        const cardNumberValidator: CardNumberVerification = number(cardNumberValue)

        setCardType(cardNumberValidator?.card?.type || '')
        if ((absLenght(cardNumberValue) > 0 && !cardNumberValidator.isPotentiallyValid) || (absLenght(cardNumberValue) === 16 && !cardNumberValidator.isValid)) {
            setError(true)
            setInfo('Invalid card number')
        } else if (!/([0-9]+)/.test(cardNumberValue)) {
            setError(false)
            setInfo('')
        } else if (cardNumberValidator.isValid) {
            setError(false)
            setInfo('')
            if (leaveFieldCallback) {
                leaveFieldCallback(tabIndex + 1)
            }
        }
    }

    const handleBlur = (event: any | KeyboardEvent) => {
        const cardNumberValue = event?.target?.value
        const cardNumberValidator: CardNumberVerification = number(cardNumberValue)
        if (cardNumberValidator.isValid) {
            setError(false)
            setInfo('')
            CardContext?.setCardData({
                ...CardContext.cardData,
                cardNumber: {
                    num: event?.target?.value || '',
                    complete: true
                },
                cvcLength: cardNumberValidator?.card?.code.size || 3
            })
        } else {
            setError(true)
            setInfo('Invalid card number')
            CardContext?.setCardData({
                ...CardContext.cardData,
                cardNumber: {
                    num: event?.target?.value,
                    complete: false
                }
            })
        }
    }


    useEffect(() => {
        if(fieldError){
            setError(true)
            setInfo('Invalid card number')
        }
    }, [fieldError])

    useEffect(() => {
        if(focus) {
            inputRef.current.focus()
        }
    }, [focus])

    return (
        <FormControl fullWidth error={error} className={'form_control'}>
            <div className="label_blk">
                <div className="label">Card Number*</div>
            </div>
            <InputMask
                mask="9999 9999 9999 9999"
                maskChar=" "
                onChange={handleChange}
                onBlur={handleBlur}
                tabIndex={tabIndex}>
                {() =>
                    <TextField variant="outlined"
                        type={'tel'}
                        className={'fieldText'}
                        placeholder={'XXXX XXXX XXXX XXXX'}
                        autoFocus={focus}
                        inputRef={inputRef}
                    />
                }
            </InputMask>
            {error ? (
                <p className="error_txt error_txt_md">{info}</p>
            ) : null}
        </FormControl>
    )
}

export default CreditCardNumberField