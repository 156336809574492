import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    MenuItem,
    TextField,
    useTheme
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import moment from 'moment'
import axios from 'axios'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Question from '../../form-components/Question'
import LoadingButton from '../../../components/LoadingButton'
import {ModalTransition} from '../../../common/ModalTransition'

const CancelMyPolicyModal = ({
    policies,
    open,
    onHandleSuccess,
    onClose,
    t
}: { policies: any, open: boolean, onHandleSuccess: (object) => object, onClose: () => void, t: (string) => string }) => {

    const [formData, setFormData] = useState({
        businessName: '', policyNumber: '',
        cancelByDate: moment().format('MM/DD/YYYY'), reason: ''
    })
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [policiesOptions, setPoliciesOptions] = useState([])

    const onChange = (field, value) => {
        let data = {...formData}
        data[field] = value
        setFormData(data)
    }
    const onSuccessCancelPolicy = () => {
        onHandleSuccess({
            success: true,
            notifyContent: {
                title: t('portal.helpful_links.success_cancel_policy.title'),
                text: t('portal.helpful_links.success_cancel_policy.sub_title'),
                btnTxt: t('portal.helpful_links.success_cancel_policy.btn'),
                isIcon: true
            }
        })
        onClose()

    }
    const handleUpdatecancelByDate = (val) => {
        onChange('cancelByDate', moment(val).format('MM/DD/YYYY'))
    }

    useEffect(() => {
        const policiesOptions = () => {
            let policiesOptions = policies.map((itm) => itm.type !== 'WC' ? {
                id: itm.quote.policyNumber || itm.quote.policy_number,
                value: itm.id,
                text: itm.type + ' (' + (itm.quote.policyNumber || itm.quote.policy_number) + ')',
            } : null)
            return policiesOptions.filter(policy => policy !== null)
        }
        setPoliciesOptions(policiesOptions)
    }, [policies])

    useEffect(() => {
        if (policiesOptions.length === 1) {
            let data = {...formData}
            // @ts-ignore
            data['policyNumber'] = policiesOptions[0].id
            setFormData(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policiesOptions])

    useEffect(() => {
        if (!open) {
            setFormData({
                policyNumber: policiesOptions.length === 1 ? formData.policyNumber : '',
                businessName: '', cancelByDate: '', reason: ''})
            setErrors({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, formData.policyNumber, policiesOptions.length])

    const validateFormFields = () => {
        setLoading(true)
        let errors = {}

        if (!/^.{2,}$/.test(formData.businessName)) {
            errors['businessName'] = t('portal.helpful_links.cancel_policy.business_name_error')
        }

        if (!/^.{2,}$/.test(formData.policyNumber)) {
            errors['policyNumber'] = t('portal.helpful_links.cancel_policy.policy_number_error')
        }

        if (!/^.{2,}$/.test(formData.reason)) {
            errors['reason'] = t('portal.helpful_links.cancel_policy.reason_error')
        }

        if (formData.cancelByDate === 'Invalid date' || formData.cancelByDate === null) {
            errors['cancelByDate'] = t('portal.helpful_links.cancel_policy.cancel_by_date_required_error')
        }

        if (moment(formData.cancelByDate).diff(moment(), 'days') < 0) {
            errors['cancelByDate'] = t('portal.helpful_links.cancel_policy.cancel_by_date_no_backdate_error')
        }

        if (Object.keys(errors).length === 0) {
            let html = `<p><strong>Business Name :</strong> ${formData.businessName}</p><p><strong>Policy Number :</strong> ${formData.policyNumber}</p>
<p><strong>Cancel-By Date :</strong> ${formData.cancelByDate}</p>
<p><strong>Reason for Cancelation :</strong> ${formData.reason}</p>`
            axios.post('/api/send_email',
                {
                    recipients: ['cl_service@acrisure.com'],
                    subject: 'Cancel My Policy',
                    text: 'Cancel My Policy',
                    html: html,
                    attachments: []
                })
                .then(() => {
                    setLoading(false)
                    onSuccessCancelPolicy()
                    // onClose()
                })
                .catch(e => {
                    setLoading(false)
                    throw new Error(`Can't send email when trying cancel policy. ${e}`)
                })
        } else {
            setErrors(errors)
            setLoading(false)
        }
    }
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            className={'modal__wrapper helpful_common__wrapper '}
            maxWidth="sm"
            scroll={'paper'}
            fullWidth
            TransitionComponent={ModalTransition}>
            <DialogActions className={'modal_head'}>
                <button className={'modal_close_btn show_scroll_paper'}
                    type={'button'}
                    onClick={onClose}><Close/>
                    <span>{t('common.btn_close')}</span></button>
            </DialogActions>

            <DialogContent>
                <div className={'helpful_cnt'}>
                    <p className={'helpful_ttl'}>{t('portal.helpful_links.cancel_policy.title')}</p>
                    <p className={'helpful_sub_ttl'}>{t('portal.helpful_links.cancel_policy.sub_title')}</p>
                    <Question
                        fullWidth
                        type="text"
                        question={t('portal.helpful_links.cancel_policy.business_name_question')}
                        placeholder={t('portal.helpful_links.cancel_policy.business_name_placeholder')}
                        answer={formData.businessName}
                        onChange={(_id, val) => {
                            onChange('businessName', val)
                        }}
                        error={errors['businessName'] ? errors['businessName'] : null}
                        helperText={errors['businessName'] ? errors['businessName'] : null}
                    />
                    {policiesOptions.length > 1 ?
                        <FormControl fullWidth error={errors['policyNumber'] ? errors['policyNumber'] : null}
                            className={'form_control'}>
                            <div className="label_blk">
                                <div className="label">
                                    <span>{t('portal.helpful_links.add_certificate_holder.policy_number_question')}</span>
                                </div>
                            </div>
                            <TextField
                                select
                                className={'fieldDropdown'}
                                value={formData.policyNumber}
                                error={!!errors['policyNumber']}
                                variant="outlined"
                                id={'downloading-documents-select'}
                                onChange={(e) => onChange('policyNumber', e.target.value)}>
                                {policiesOptions && policiesOptions.map((opt) => (
                                    // @ts-ignore
                                    <MenuItem value={opt.id} key={opt.id}>{opt.text}</MenuItem>
                                ))}
                            </TextField>
                            {errors['policyNumber'] &&
                                  <p className="error_txt error_txt_md">{errors['policyNumber']}</p>}

                        </FormControl> :
                        <FormControl fullWidth error={errors['policyNumber'] ? errors['policyNumber'] : null}
                            className={'form_control'}>
                            <div className="label_blk">
                                <div className="label">
                                    <span>{t('portal.helpful_links.add_certificate_holder.policy_number_question')}</span>
                                </div>
                            </div>
                            <TextField
                                disabled
                                value={formData.policyNumber}
                                variant="outlined">
                            </TextField></FormControl>}

                    <Question
                        fullWidth
                        type="text"
                        field_length={1000}
                        question={t('portal.helpful_links.cancel_policy.reason_question')}
                        answer={formData.reason}
                        onChange={(_id, val) => {
                            onChange('reason', val)
                        }}
                        error={errors['reason'] ? errors['reason'] : null}
                        helperText={errors['reason'] ? errors['reason'] : null}
                    />

                    <Question
                        fullWidth
                        type={'date'}
                        disable_past
                        question={t('portal.helpful_links.cancel_policy.cancel_by_date_question')}
                        answer={formData.cancelByDate}
                        onChange={(_id, value) => {
                            handleUpdatecancelByDate(value)
                        }}
                        error={errors['cancelByDate'] ? errors['cancelByDate'] : null}
                        helperText={errors['cancelByDate'] ? errors['cancelByDate'] : null}
                    />

                    {loading ? <LoadingButton/> : <button className={'a_btn a_btn_action_1 btn__submit'}
                        onClick={validateFormFields}>{t('common.btn_submit')}</button>
                    }

                </div>
            </DialogContent>
        </Dialog>
    )
}

export default withTranslation()(CancelMyPolicyModal)