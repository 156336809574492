import React, { useEffect, useState } from 'react'
import Notify from '../../common/modal/Notify'
import PaymentItem from './payment/PaymentItem'

const UpcomingBills = (props) => {

  const [upcomingBills, setUpcomingBills] = useState([])
  const [collapsedIndex, setCollapsedIndex] = useState(null)
  const [showNotify, setShowNotify] = useState(null)
  const [notifyContent, setNotifyContent] = useState({})

  const onEditModeHandler = (index) => {
    setCollapsedIndex(index)
    if (collapsedIndex === index) {
      setCollapsedIndex(null)
      upcomingBills.forEach((item) => {
        item.paymentEdit = false
      })
    } else {
      upcomingBills.forEach((item, key) => {
        item.paymentEdit = key === index
      })
    }
    setUpcomingBills(upcomingBills)
  }
  const isEditedHandler = (index) => {
    setCollapsedIndex(null)
    upcomingBills[index].paymentEdit = false
    setUpcomingBills(upcomingBills)
  }

  const onHandleSuccess = (payload) => {
    setNotifyContent(payload.notifyContent)
    setShowNotify(payload.success)
  }

  useEffect(() => {
    setUpcomingBills(props.upcomingBills)
  }, [props.upcomingBills])

  return (
    upcomingBills && upcomingBills.length > 0 && <div className={'portal_card portal_next_payments'}>
      <div className="bills_list">
        {upcomingBills.map((item, key) => {
          return (<PaymentItem {...item} key={key} index={key} collapsed={key === collapsedIndex}
                               onEditModeHandler={() => onEditModeHandler(key)}
                               onHandleSuccess={onHandleSuccess}
                               isEdited={() => isEditedHandler(key)}/>)
        })}
      </div>
      <Notify notifyContent={notifyContent}
              open={showNotify}
              onClose={() => setShowNotify(!showNotify)}/>
    </div>

  )
}
export default UpcomingBills