import React from 'react';

export const Warning = (props) => {
    return (
        <svg className={props.className} width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18.7734 2.92134C18.4609 2.27101 17.9709 1.72214 17.3599 1.33798C16.7489 0.953823 16.0417 0.75 15.3198 0.75C14.5979 0.75 13.8907 0.953823 13.2797 1.33798C12.6686 1.72214 12.1787 2.27101 11.8662 2.92134L0.843882 24.5892C-0.474826 27.176 1.19155 30.6111 4.29615 30.6111H26.3421C29.448 30.6111 31.1117 27.1774 29.7957 24.5892L18.7734 2.92134ZM15.3198 10.253C15.68 10.253 16.0254 10.396 16.2801 10.6505C16.5348 10.905 16.6779 11.2502 16.6779 11.6102V18.3962C16.6779 18.7562 16.5348 19.1014 16.2801 19.3559C16.0254 19.6104 15.68 19.7534 15.3198 19.7534C14.9596 19.7534 14.6142 19.6104 14.3595 19.3559C14.1048 19.1014 13.9617 18.7562 13.9617 18.3962V11.6102C13.9617 11.2502 14.1048 10.905 14.3595 10.6505C14.6142 10.396 14.9596 10.253 15.3198 10.253ZM15.3198 21.7893C15.68 21.7893 16.0254 21.9322 16.2801 22.1868C16.5348 22.4413 16.6779 22.7865 16.6779 23.1465V23.8251C16.6779 24.185 16.5348 24.5302 16.2801 24.7848C16.0254 25.0393 15.68 25.1823 15.3198 25.1823C14.9596 25.1823 14.6142 25.0393 14.3595 24.7848C14.1048 24.5302 13.9617 24.185 13.9617 23.8251V23.1465C13.9617 22.7865 14.1048 22.4413 14.3595 22.1868C14.6142 21.9322 14.9596 21.7893 15.3198 21.7893Z"
                  fill="url(#paint0_linear_17499_39159)"/>
            <defs>
                <linearGradient id="paint0_linear_17499_39159" x1="23.7177" y1="32.4774" x2="11.5866" y2="-5.47107"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FEA60B"/>
                    <stop offset="1" stopColor="#FFE68D"/>
                </linearGradient>
            </defs>
        </svg>

    );
}
