import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Loading from '../Loading'
import {connect} from 'react-redux'
import {Close} from '@material-ui/icons'
import ReactMarkdown from 'react-markdown'
import {withTranslation} from 'react-i18next'
import { Dialog, DialogActions, DialogContent, Grid, useTheme} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {ModalTransition} from '../../common/ModalTransition'

import '../../scss/v2/modals/_terms_fraud.scss'

const FraudDisclaimer = ({
    t,
    open,
    onClose,
    onAccept,
    answers
}: {
    t: any,
    open: boolean,
    onClose: () => void,
    onAccept: () => void,
    answers: object
}) => {
    const [readInFull, setReadInFull] = useState(false)
    const [fraudInfo, setFraudInfo] = useState('')
    

    useEffect(()=>{
        const showFraudInfo=()=> {
            axios.post('/api/get-fraud-info', {
                'state': answers['business.state'],
            }).then(response => {
                setFraudInfo(response.data.text.replace('INSURANCE APPLICATION FRAUD WARNINGS', 'Fraud Warnings'))
            })
        }
        if (!fraudInfo) showFraudInfo()
    },[fraudInfo, answers])


    const checkIfEndIsShown = (el: EventTarget & HTMLDivElement) => {
        if (!el) return
        if (el.scrollHeight - el.scrollTop === el.clientHeight) setReadInFull(true)
    }

    const checkIfReadInFull = (e: React.UIEvent<HTMLDivElement>) => {
        if (readInFull) return
        checkIfEndIsShown(e.currentTarget)
    }

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    
    return (
        <Dialog open={open}
            fullScreen={fullScreen}
            maxWidth="md"
            container={() => document.getElementById('themeAppComponent')}
            className={'modal__wrapper fraud_modal__wrapper'}
            onClose={onClose}
            TransitionComponent={ModalTransition}>
            <DialogActions className={'modal_head'}>
                <button className={'modal_close_btn show_scroll_paper'}
                    type={'button'}
                    onClick={onClose}><Close/>
                    <span>{t('common.btn_cancel')}</span></button>
            </DialogActions>
            <DialogContent onScroll={e => checkIfReadInFull(e)}>

                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        {fraudInfo === '' ? (<Loading/>) : (
                            <ReactMarkdown source={fraudInfo} className="fraud_info"/>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container className={'dialog_actions'}>
                    <button className={'a_btn a_btn_action_1 btn_accept'}
                        type={'button'}
                        onClick={onAccept}
                    >{t('common.btn_accept')}</button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
    
}

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
    }
}

export default connect(mapStateToProps)(withTranslation()(FraudDisclaimer))
