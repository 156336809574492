enum Carrier {
    ACUITY = 'ACUITY',
    AMTRUST = 'AMTRUST',
    COTERIE = 'COTERIE',
    CNA = 'CNA',
    EMPLOYERS = 'EMPLOYERS',
    MARKEL = 'MARKEL',
    COALITION = 'COALITION'
}

const GetCarrierDetailInfo = (carrier: Carrier, state: string)=>{
    switch (carrier) {
    case Carrier.ACUITY:
        return {
            logo: 'acuity', name: 'Acuity', rating: 'Rated A+ (A.M. Best)',
            insurer: 'Acuity, A Mutual Insurance Company',
            portal_insurer: 'Acuity Insurance',
            underwritten_by: 'ACUITY, A MUTUAL INSURANCE COMPANY | 2800 SOUTH TAYLOR DRIVE, SHEBOYGAN, WI 53081'
        }
    case Carrier.AMTRUST:
        return {
            logo: 'amtrust', name: 'AmTrust', rating: 'Rated A- (A.M.Best)',
            insurer: 'AmTrust Financial Services, Inc.',
            portal_insurer: 'AmTrust Insurance',
            underwritten_by: 'AMTRUST FINANCIAL SERVICES | 59 MAIDEN LANE, 42ND FLOOR, NEW YORK, NY 10038'
        }
    case Carrier.CNA:
        return {
            logo: 'cna', name: 'CNA', rating: 'Rated A (A.M.Best)',
            insurer: 'CNA Financial Corporation',
            portal_insurer: 'CNA Insurance',
            underwritten_by: 'CNA FINANCIAL CORPORATION | 151 NORTH FRANKLIN ST, CHICAGO, IL 60606'
        }
    case Carrier.COTERIE:
        switch (state) {
        case 'FL':
            return {
                logo:'coterie',
                name: 'ClearSprint', rating: 'Rated A+ (A.M. Best)',
                insurer: 'CLEAR SPRING PROPERTY AND CASUALTY COMPANY',
                portal_insurer: 'ClearSpring Insurance',
                underwritten_by: 'CLEAR SPRING PROPERTY AND CASUALTY COMPANY | 227 WEST MONROE, CHICAGO, IL 60606'
            }
        default:
            return {
                logo:'coterie',
                name: 'Benchmark', rating: 'Rated A (A.M.Best)',
                insurer: 'Benchmark Insurance Company',
                portal_insurer: 'Benchmark Insurance',
                underwritten_by: 'Benchmark Insurance Company | 150 Lake Street West, Wayzata, MN 55391'
            }
        }
    case Carrier.EMPLOYERS:
        return {
            logo: 'employers', name: 'EMPLOYERS', rating: 'Rated A (A.M.Best)',
            insurer: 'Employers Preferred Insurance Company',
            portal_insurer: 'Employers Preferred Insurance Company',
            underwritten_by: 'EMPLOYERS®, EMPLOYERS INSURANCE COMPANY OF NEVADA | RENO, NV 89521'
        }
    case Carrier.MARKEL:
        return {
            logo: 'markel', name: 'Markel', rating: 'Rated A+ (A.M. Best)',
            insurer: 'Markel Insurance Company',
            portal_insurer: 'Markel Insurance Company',
            underwritten_by: 'MARKEL INSURANCE COMPANY | 4521 HIGHWOODS PARKWAY, GLEN ALLEN, VA 23060'
        }
    case Carrier.COALITION:
        return {
            logo: 'coalition', name: 'Coalition', rating: 'Rated A+ (A.M. Best)',
            insurer: 'Coalition Inc',
            portal_insurer: 'Swiss Re Corporate Solutions America Insurance Corporation',
            underwritten_by: 'COALITION | 55 2ND STREET FLOOR 25, SAN FRANCISCO, CA 94105'
        }
    }
}


export {GetCarrierDetailInfo as getCarrierDetailInfo}