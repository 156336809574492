import {includes} from 'lodash'

export enum BillingMethod {
    Agency = 'AGENCY',
    Direct = 'DIRECT'
}

enum Integration {
    Stripe = 'STRIPE'
}

export class CarrierConfig {
    name: string
    displayName: string
    paymentMethod: BillingMethod
    integrations: Array<Integration> | []

    stripePublicToken?: string
    stripePublishableToken?: string

    constructor(name: string, displayName: string, paymentMethod: BillingMethod, integrations: Array<Integration> = []) {
        this.name = name
        this.displayName = displayName
        this.paymentMethod = paymentMethod
        this.integrations = integrations

        this.setStripePublicToken()
    }

    setStripePublicToken() {
        if (includes(this.integrations, Integration.Stripe) && this.paymentMethod === BillingMethod.Direct) {
            let stripeAuthToken = process.env?.[`REACT_APP_${this.name.toUpperCase()}_STRIPE_API_KEY`] || window?.[`${this.name.toLowerCase()}_stripe_api_key`]
            let stripePublishableToken = process.env?.[`REACT_APP_${this.name.toUpperCase()}_STRIPE_PUBLISHABLE_API_KEY`] || window?.[`${this.name.toLowerCase()}_stripe_publishable_key`]
            if (stripeAuthToken) this.stripePublicToken = stripeAuthToken
            if (stripePublishableToken) this.stripePublishableToken = stripePublishableToken
        } else if (includes(this.integrations, Integration.Stripe) && this.paymentMethod === BillingMethod.Agency) {
            let stripeAuthToken = process.env?.REACT_APP_B2Z_STRIPE_API_KEY || window?.b2z_stripe_api_key
            let stripePublishableToken = process.env?.REACT_APP_B2Z_STRIPE_PUBLISHABLE_API_KEY || window?.b2z_stripe_publishable_key
            if (stripeAuthToken) this.stripePublicToken = stripeAuthToken
            if (stripePublishableToken) this.stripePublishableToken = stripePublishableToken
        } else {
            // TODO: placeholder to implement additional payment integrations... for now we will log an error
            // console.log(`No payment integration set for carrier: ${this.name}`)
        }
    }
}

const AcuityConfig = new CarrierConfig('ACUITY', 'Acuity', BillingMethod.Agency, [Integration.Stripe])
const AmtrustConfig = new CarrierConfig('AMTRUST', 'Amtrust', BillingMethod.Direct)
const CNAConfig = new CarrierConfig('CNA', 'CNA', BillingMethod.Agency, [Integration.Stripe])
const CoalitionConfig = new CarrierConfig('COALITION', 'Coalition', BillingMethod.Agency, [Integration.Stripe])
const CoterieConfig = new CarrierConfig('COTERIE', 'Coterie', BillingMethod.Direct, [Integration.Stripe])
const EmployersConfig = new CarrierConfig('EMPLOYERS', 'Employers', BillingMethod.Agency, [Integration.Stripe])
const MarkelConfig = new CarrierConfig('MARKEL', 'Markel', BillingMethod.Agency, [Integration.Stripe])

type CarrierService = {
    [key: string]: CarrierConfig
}

const carrierConfigWrapper: CarrierService = {
    acuity: AcuityConfig,
    amtrust: AmtrustConfig,
    cna: CNAConfig,
    coalition: CoalitionConfig,
    coterie: CoterieConfig,
    employers: EmployersConfig,
    markel: MarkelConfig,
}

type Quote = {
    [key: string]: any
}

export function addPaymentMethodAuth(quote: Quote) {
    try {
        let carrier = quote.data.source.toLowerCase()
        if (carrier) {
            quote.carrierConfig = carrierConfigWrapper[carrier]
        }
        return quote
    } catch (e) {
        return quote
    }
}


export function updatePaymentMethodAuth(quote: Quote) {
    try {
        let carrier = quote.source.toLowerCase()
        if (carrier) {
            quote.carrierConfig = carrierConfigWrapper[carrier]
        }
        return quote
    } catch (e) {
        return quote
    }
}
