import React from 'react'

export const EmployeeOwnedIcon = () => (
    <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.4783 11.1304C12.9996 11.1304 15.0435 9.08652 15.0435 6.56522C15.0435 4.04392 12.9996 2 10.4783 2C7.957 2 5.91309 4.04392 5.91309 6.56522C5.91309 9.08652 7.957 11.1304 10.4783 11.1304Z" stroke="#827252" strokeWidth="2.5"/>
        <path d="M11.1304 21.5652H4.09115C3.79467 21.5653 3.50156 21.5063 3.23129 21.3923C2.96102 21.2782 2.71976 21.1117 2.52352 20.9037C2.32728 20.6957 2.18056 20.4511 2.09308 20.186C2.0056 19.9209 1.97937 19.6414 2.01614 19.3661L2.42382 16.31C2.51863 15.6 2.88734 14.9469 3.46063 14.4734C4.03391 14 4.77227 13.7389 5.53686 13.7391H5.90377" stroke="#827252" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M29.7175 11.913C29.6914 11.887 29.6784 11.8609 29.6523 11.8217C29.1567 11.1435 28.4523 11.1304 28.4523 11.1304H17.2871C17.2871 11.1304 16.5827 11.1435 16.0871 11.8348C16.061 11.8609 16.048 11.887 16.0219 11.913C15.9306 12.0565 15.8393 12.2261 15.774 12.4348C15.4871 13.2956 14.8088 15.3304 13.7393 18.5522V27.0304C13.7393 27.6174 14.1958 28.087 14.7566 28.087H15.3306C15.8914 28.087 16.348 27.6174 16.348 27.0304V25.4783H29.3914V27.0304C29.3914 27.6174 29.8349 28.087 30.4088 28.087H30.9827C31.5436 28.087 32.0001 27.6174 32.0001 27.0304V18.5522C30.9306 15.3435 30.2523 13.2956 29.9653 12.4348C29.9001 12.2261 29.8088 12.0565 29.7175 11.913ZM18.0827 13.7391H27.6566L27.9566 14.6391L28.5175 16.3478H17.2219L18.0827 13.7391ZM29.3914 22.8696H16.348V18.9565H29.3914V22.8696Z" fill="#000000"/>
        <path d="M19.4658 22.3378C20.1562 22.3378 20.7158 21.7782 20.7158 21.0878C20.7158 20.3975 20.1562 19.8378 19.4658 19.8378C18.7755 19.8378 18.2158 20.3975 18.2158 21.0878C18.2158 21.7782 18.7755 22.3378 19.4658 22.3378Z" fill="#000000"/>
        <path d="M26.1553 22.3378C26.8456 22.3378 27.4053 21.7782 27.4053 21.0878C27.4053 20.3975 26.8456 19.8378 26.1553 19.8378C25.4649 19.8378 24.9053 20.3975 24.9053 21.0878C24.9053 21.7782 25.4649 22.3378 26.1553 22.3378Z" fill="#000000"/>
    </svg>
)