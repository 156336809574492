import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { CircleSuccess } from '../icons/CircleSuccess'

class SectionHead extends Component {
  render () {
    return (
      <div className="section_head_cnt">
        <div className={`section_head`}>
          {this.props.icon_check && <CircleSuccess/>}
          {this.props.icon && this.props.icon}
          <div className="head_desc">
            <p className="head_title" style={{ whiteSpace: 'pre-wrap' }}>{this.props.title}</p>
            {this.props.isHtml ? <p className="head_txt" dangerouslySetInnerHTML={{ __html: this.props.txt }}/> : <p
              className="head_txt">{this.props.txt}</p>}
          </div>
        </div>
      </div>

    )
  }
}

export default withTranslation()(SectionHead)