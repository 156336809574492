import React from 'react'
import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Close} from '@material-ui/icons'
import {withTranslation} from 'react-i18next'
import {useTheme} from '@material-ui/core/styles'
import {Dialog, DialogActions, DialogContent} from '@material-ui/core'
import {ModalTransition} from '../../common/ModalTransition'
import {getLimitValue} from '../../helpers/display'
import {formatCurrency} from '../../helpers/currency'
import {getInsuranceCarrier} from '../../helpers/GetInsuranceCarrier'
//scss
import '../../scss/v2/modals/_fq_policy_details.scss'

const BOPDetails = (props) => {
    const {t, answers, quotes} = props
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const provider_data = getInsuranceCarrier(answers, 'BOP', quotes)
    let optional_coverages = {}
    const assetsUrl = 'https://b2z-public-images.s3.amazonaws.com/acrisure';

    (props.quote?.optional_coverages || []).forEach(itm => {
        optional_coverages[itm.coverage_name] = itm
    })

    return (
        <Dialog open={props.open}
            fullWidth
            fullScreen={fullScreen}
            maxWidth={'sm'}
            container={() => document.getElementById('themeAppComponent')}
            className={'modal__wrapper fq_details_modal__wrapper whats_covered_modal__wrapper'}
            scroll={'paper'}
            onClose={() => props.onClose()}
            TransitionComponent={ModalTransition}>

            <DialogActions className={'modal_head'}>
                <button className={'modal_close_btn show_scroll_paper'}
                    type={'button'}
                    onClick={() => props.onClose()}><Close/>
                    <span>{t('common.btn_cancel')}</span></button>
            </DialogActions>

            <DialogContent className={'modal_body'}>
                <div className={'fq_details_card'}>
                    <div className={'detail_name'}>{t('common.bop_policy')}</div>
                    <div className={'mobile_price'}>{props.price}</div>
                    <div className={'insurer_rate'}>
                        <div className="row">
                            <div
                                className={'fq_insurer'}>{props.quote.source.toUpperCase() === 'CF' ? t('common.crum_for') : provider_data?.name}</div>
                            <div className="divider"/>
                            <div
                                className={'fq_rate'}>{props.quote.source.toUpperCase() === 'CF' ? t('common.rated') : provider_data?.rating}</div>
                        </div>
                        {provider_data?.logo &&
                          <div className={'fq_carrier_logo'}><img src={`${assetsUrl}/carrier_logos/${provider_data.logo}.png`} alt=""/></div>}

                    </div>
                    <div className="limits_price_dates">
                        <div className="limits">
                            {Object.keys(props.quote.limits).map((key, idx) =>
                                (<div key={idx}><p>{props.quote.limits[key].display_name}</p>
                                    {getLimitValue(props.quote.limits[key])}
                                </div>)
                            )}
                        </div>
                        <div className="price-dates">
                            <div className="price">{props.price}</div>
                            <div className="dates">
                                <div>
                                    <p>{t('common.eff_date')}</p>{moment(props.quote.effective_date).format('MM/DD/YYYY')}
                                </div>
                                <div>
                                    <p>{t('common.exp_date')}</p>{moment(props.quote.expiration_date).format('MM/DD/YYYY')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-dates">
                        <div className="dates">
                            <div>
                                <p>{t('common.eff_date')}</p>{moment(props.quote.effective_date).format('MM/DD/YYYY')}
                            </div>
                            <div>
                                <p>{t('common.exp_date')}</p>{moment(props.quote.expiration_date).format('MM/DD/YYYY')}
                            </div>
                        </div>
                    </div>
                    <div className="optional-coverages">
                        {props.answers['policy.tria_accepted'] === 'Yes' && props.quote?.optional_coverages.filter(q => q?.coverage_name === 'tria').length > 0 && (
                            <div>{t('common.tria_incl', {value: formatCurrency(props.quote?.optional_coverages.filter(q => q?.coverage_name === 'tria')[0].term_premium)})}</div>
                        )}
                    </div>
                    <div className="optional-coverages-cards">
                        {optional_coverages.hasOwnProperty('mpl') && (
                            <div className="card">
                                <h2>{t('common.pli')}</h2>
                                <div className="limits">
                                    {optional_coverages['mpl']?.occurrence_limit !== null && (
                                        <div className="limit">
                                            <div className="desc">{t('common.per_occur')}</div>
                                            <div
                                                className="value">{formatCurrency(optional_coverages['mpl'].occurrence_limit)}</div>
                                        </div>
                                    )}
                                    {optional_coverages['mpl']?.annual_aggregate_limit !== null && (
                                        <div className="limit">
                                            <div className="desc">{t('common.ann_aggregate')}</div>
                                            <div
                                                className="value">{formatCurrency(optional_coverages['mpl'].annual_aggregate_limit)}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {optional_coverages.hasOwnProperty('cyber') && (
                            <div className="card">
                                <h2>{t('common.cyber_ins')}</h2>
                                <div className="limits">
                                    <div className="limit">
                                        <div className="desc">{t('common.ann_aggregate')}</div>
                                        <div
                                            className="value">{formatCurrency(optional_coverages['cyber'].annual_aggregate_limit)}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {optional_coverages.hasOwnProperty('hnoa') && (
                            <div className="card">
                                <h2>{t('common.hnoa_ins')}</h2>
                                <div className="limits">
                                    <div className="limit">
                                        <div className="desc">{t('common.per_occur')}</div>
                                        <div
                                            className="value">{formatCurrency(optional_coverages['hnoa'].occurrence_limit)}</div>
                                    </div>
                                    <div className="limit">
                                        <div className="desc">{t('common.ann_aggregate')}</div>
                                        <div
                                            className="value">{formatCurrency(optional_coverages['hnoa'].annual_aggregate_limit)}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="whats_covered_modal_cnt">
                    <p className="covered_sub_ttl">{t('modal.not_cover_bop.question')}</p>
                    <div className="not_covered_card">
                        <p className="card_txt">{t('modal.not_cover_bop.txt')}</p>
                        <ul className="card_list">
                            {t('modal.not_cover_bop.list',
                                {returnObjects: true}).map(({icon, txt, tip}, idx) => (
                                <li className="card_list_item" key={idx}>
                                    <i className="list_item_icon">
                                        <img src={`${assetsUrl}/icons/${icon}`} alt={txt}/></i>
                                    <span className="list_item_tip">
                                        <span className="txt">{txt}</span>
                                        <span className="tip">{tip}</span>
                                    </span>
                                </li>))}
                        </ul>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default withTranslation()(BOPDetails)
