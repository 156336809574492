import React from "react";

export const AkarBell = () => (
    <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M24.9118 9.58879L22.0409 9.58304C15.4923 9.56771 9.80721 14.7753 9.76217 21.083V28.3472C9.76217 29.8614 9.56634 31.3391 8.7223 32.5984L8.16025 33.4379C7.30446 34.7105 8.22488 36.4164 9.76217 36.4164H37.2376C38.7749 36.4164 39.6933 34.7105 38.8395 33.4379L38.2775 32.5984C37.4354 31.3391 37.2376 29.8595 37.2376 28.3453V21.085C37.1593 14.7753 31.4605 9.60412 24.9118 9.58879V9.58879Z"
            stroke="#172152" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M29.375 36.416C29.375 37.941 28.756 39.4035 27.6543 40.4819C26.5525 41.5602 25.0581 42.166 23.5 42.166C21.9419 42.166 20.4475 41.5602 19.3457 40.4819C18.244 39.4035 17.625 37.941 17.625 36.416"
            stroke="#172152" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M23.5002 3.83301C24.5389 3.83301 25.5351 4.23688 26.2697 4.95576C27.0042 5.67465 27.4168 6.64968 27.4168 7.66634V9.58301H19.5835V7.66634C19.5835 6.64968 19.9961 5.67465 20.7307 4.95576C21.4652 4.23688 22.4614 3.83301 23.5002 3.83301Z"
            stroke="#172152" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)