import React from "react";
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum';
import {Cookies, withCookies} from 'react-cookie';
import axios from "axios"
import {createStore} from "redux";
import { StatsigProvider } from 'statsig-react'
import {v4 as uuidv4} from 'uuid';
import {instanceOf} from 'prop-types'
import {Provider} from "react-redux";
import {rootReducer} from "../store/index";
import {
    changeAnswers,
    setAnswers,
    setRestoreSession,
    updateAppProducts,
    updateInsStoreKey,
    updateQuestions,
    updateSections
} from "../store/insurance/actions";
//components
import {acrisureTheme} from "../acrisure-theme";
import {Pages} from "../pages/Pages";
import Portal from "../pages/Portal";
import { TuneUpQuiz } from "../pages/TuneUpQuiz/TuneUpQuiz";
import Insurance from "../pages/Insurance";
import {withTranslation} from 'react-i18next';
import Header from "./../layouts/page/Header";
import {Elements} from "@stripe/react-stripe-js";
import {setUserAuth} from "../store/portal/actions";
import MHeader from "./../layouts/maintenance/Header";
import {updateAppStoreKey} from "../store/app/actions";
import {Box, Container, Grid, ThemeProvider} from "@material-ui/core";
import SectionIndicator from "./insurance/SectionIndicator";
import SectionHead from "./insurance/SectionHead";
import Layout from "../layouts/insurance/Layout";
import {loadStripe} from '@stripe/stripe-js';
// import CookiesDisabled from "./icons/CookiesDisabled";
// styles
import '../scss/v2/app.scss'
import {BrokenLink} from "./icons/BrokenLink";
import LoadingButton from "./LoadingButton";


if((process.env.REACT_APP_DATADOG_RUM_APP_VERSION || window.datadog_rum_app_version)
  && (process.env.REACT_APP_DATADOG_RUM_APP_VERSION !=="" || window.datadog_rum_app_version !== "")){
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID || window.datadog_rum_app_id,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || window.datadog_rum_client_token,
    site: 'datadoghq.com',
    service: 'b2z-ui',
    env: process.env.REACT_APP_DATADOG_RUM_ENV || window.datadog_rum_env,
    version: process.env.REACT_APP_DATADOG_RUM_APP_VERSION || window.datadog_rum_app_version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const cookie_name = 'b2z_data'

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || window.stripe_pub_key)

const store = (process.env.REACT_APP_REDUX_DEBUG && window.__REDUX_DEVTOOLS_EXTENSION__)
    ? createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__())
    : createStore(rootReducer)

const stripe_fonts = [
    {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap'
    }
]

const utm_tags_names = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_id', 'utm_partner_id']

class App extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    state = {
        cookie_disabled: false
    }

    constructor(props) {
        super(props);
        if (this.checkIfCookieEnabled()) {
            this.state = {
                loading: true,
                browser_id: this.getBrowserId(),
                session_id: uuidv4(),
                isAuthorized: false,
                start: this.checkStartPoint('start'),
                restoreCheckout: this.checkStartPoint('checkout_session'),
                programId: this.checkStartPoint('program_id'),
                sessionId: this.checkStartPoint('session_id'),
                bulk: this.checkStartPoint('bq'),
                urlBusinessParams: this.checkBusinessParams()
            }
            this.updateStoreValues()
            this.updateCookie()
        } else {
            this.state.cookie_disabled = true
            window.maintenance = 'true'
        }
    }

    checkBusinessParams() {
        let search = window.location.search
        let index = search.indexOf('?')
        let params
        if (index >= 0) {
            const hashes = search.slice(index + 1).split('&')
            params = {}
            hashes.map(hash => {
                const [key, val] = hash.split('=')
                params[key] = decodeURIComponent(val)
                return true
            })
        } else {
            params = null
        }
        return params
    }

    componentDidMount() {
        if (window.maintenance !== 'true') this.initApp()
    }

    updateBusinessData(urlBusinessParams) {

        let businessAnswers = {};
        for (let key in urlBusinessParams) {
            if (key === "email") { // TODO: we will update this to also populate validated_email field if Acrisure does email validation
                businessAnswers = {...businessAnswers, 'contact.email': urlBusinessParams[key]}
            }

            if (key === 'customer_name') {
                businessAnswers = {...businessAnswers, 'contact.first_name': urlBusinessParams[key]}
            } else if (key === "coverage_type") {
                // TODO: Implement after we determine from Acrisure what this answer will actually look like.
                // TODO: Discuss if Acrisure will take responsibility for email verification on their leads.  If yes,
                //  then email should also be added to `validated_email` answer key.

            } else if (key === "acrisure_id") {
                businessAnswers = {...businessAnswers, acrisure_id: "aid" + urlBusinessParams[key]}
            } else if (key === 'types') {
                businessAnswers[`business.g_${key}`] = urlBusinessParams[key].split(',')
            } else {
                businessAnswers[`business.${key}`] = urlBusinessParams[key]
            }
        }
        return businessAnswers
    }

    checkIfCookieEnabled() {
        return navigator.cookieEnabled
    }

    checkStartPoint(key) {
        let value = null
        if (window.location.search) {
            value = this.checkURLParam(key)
        }
        return value
    }

    updateStoreValues() {
        store.dispatch(updateInsStoreKey('unique_session_id', this.state.session_id))
        store.dispatch(updateInsStoreKey('unique_browser_id', this.state.browser_id))
    }

    checkURLParam(name) {
        let param = new URLSearchParams(window.location.search)
        param = param.get(name)

        return param ? param : false
    }

    initApp() {
        if(this.state.urlBusinessParams !== null){
            /*
            * When user can't find business in marketing site
            * we set is_business_info_myself : true
            * and will provide user to fill business info himself
            * */
            if(this.state.urlBusinessParams.hasOwnProperty('enter_business_info_myself')){
                let answers = {
                    'is_business_info_myself': true,
                    "business.name": '',
                    "business.street_address": '',
                    "business.street_address2": '',
                    "business.state": '',
                    "business.city": '',
                    "business.zip_code": '',
                    "business.place_id": ''
                }

                store.dispatch(changeAnswers({
                    ...store.getState().insurance.answers,
                    ...answers
                }))
            }
            if (!this.state.urlBusinessParams.hasOwnProperty('enter_business_info_myself')) {
                let businesAnswers = this.updateBusinessData(this.state.urlBusinessParams)
                store.dispatch(changeAnswers({
                    ...store.getState().insurance.answers,
                    ...businesAnswers
                }))
            }
        }

        if(this.state.programId && this.state.sessionId){
            // Restore session data after user comes from ascend page
            this.resumeSession(this.state.sessionId)
            this.setState({loading: false})
        }else{
          if (!!this.state.start) {
            if (this.state.start.length === 36) this.resumeSession(this.state.start)
            else this.startFreshSession()
          } else if (!!this.state.restoreCheckout) {
            store.dispatch(updateInsStoreKey('restoreCheckout', true))
            this.resumeSession(this.state.restoreCheckout)
          } else if (!!this.state.bulk) {
            if (this.state.bulk.length === 36) {
              store.dispatch(updateInsStoreKey('is_from_bulk', true))
              this.resumeSession(this.state.bulk)
            }
          } else this.startFreshSession()
        }

    }

    /**
     * Retrieves the URL parameters specified in the given parameter names.
     *
     * @param {Array} paramNames - An array containing the names of the parameters to retrieve.
     *
     * @return {Object} - An object containing the retrieved parameter values.
     *                   The keys of the object are the parameter names and the values are the corresponding parameter values.
     */
    getParams(paramNames) {
        return paramNames.reduce((params, name) => {
            let value = this.checkURLParam(name);
            if (value !== null && value !== false) {
                params[name] = value;
            }
            return params;
        }, {});
    }

    /**
     * NOTE: code in startSession is only executed when the environment variable NODE_ENV is set
     * to 'development'.
     */
    startSession(utmTags) {
        axios.get("/api/init", {params: utmTags})
            .then(response => {
                store.dispatch(updateAppStoreKey('google_api_key', response.data.gk))
                store.dispatch(updateAppStoreKey('site', response.data.site))
                store.dispatch(setUserAuth(response.data.user_token))
                store.dispatch(updateAppProducts(response.data.products))
                store.dispatch(updateQuestions(response.data.questions))
                store.dispatch(updateSections([]))
                store.dispatch(updateInsStoreKey('legal_entities', response.data.legal_entities))
                store.dispatch(updateInsStoreKey('unique_session_id', response.data.session_id))
                store.dispatch(updateInsStoreKey('isPartner', response.data?.isPartner))
                store.dispatch(updateInsStoreKey('partnerData', response.data?.partnerData))
                this.updateCookie(response.data.session_id)

                if (response.data.application_answers) {
                    store.dispatch(setAnswers({
                        ...store.getState().insurance.answers,
                        ...response.data.application_answers
                    }))
                }
            })
            .catch(e => {
              throw new Error(`Can't obtain initial data from api server! ${e}`)
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    /**
     * Starts a fresh session.
     *
     * IMPORTANT NOTE: If the  environment variable NODE_ENV is set to
     * 'development', startSession method is called in order to separately
     * handle production environment specific application action dispatches
     * and in-store key updates.  As such, it's imperative to thoroughly vet
     * any code additions scoped within the startSession method.  Code
     * implemented only in the startSession method is unreachable in the
     * production environment and any other environment where the environment
     * variable NODE_ENV is not set to 'development'.
     *
     * @returns {void}
     * @throws {Error} If initial data cannot be obtained from the API server.
     */
    startFreshSession() {
        let utmTags = this.getParams(utm_tags_names);
        if (process.env.NODE_ENV === 'development') this.startSession(utmTags)
        else {
            axios.get("/api/init-fresh",{params: utmTags})
                .then(response => {
                    store.dispatch(updateAppStoreKey('google_api_key', response.data.gk))
                    store.dispatch(updateAppStoreKey('site', response.data.site))
                    store.dispatch(setUserAuth(response.data.user_token))
                    store.dispatch(updateAppProducts(response.data.products))
                    store.dispatch(updateInsStoreKey('legal_entities', response.data.legal_entities))
                    store.dispatch(updateQuestions(response.data.questions))
                    store.dispatch(updateSections([]))
                    store.dispatch(updateInsStoreKey('isPartner', response.data?.isPartner))
                    store.dispatch(updateInsStoreKey('partnerData', response.data?.partnerData))
                })
                .catch(e => {
                  throw new Error(`Can't obtain initial data from api server!. ${e}`)
                })
                .finally(() => {
                    this.setState({loading: false})
                })
        }
    }

    resumeSession(start) {
        axios.post("/api/init", {sid: start})
            .then(response => {
                if (response.data.application_answers && Object.keys(response.data.application_answers).length !== 0) {
                    store.dispatch(updateInsStoreKey('unique_session_id', start))
                    this.updateCookie(start)
                    this.setState({session_id: start}, () => {
                        let appetite = response.data.application_answers['appetite']
                        let appetiteKeys = Object.keys(response.data.application_answers['appetite'])
                        let availableProducts = response.data.products.map(obj => {
                            if (!appetiteKeys.includes(obj.type)) return {...obj, isDisabled: true}
                            else return {...obj, appetite: appetite[obj.type], isDisabled: false}
                        })
                        store.dispatch(setRestoreSession(true))
                        store.dispatch(updateAppStoreKey('google_api_key', response.data.gk))
                        store.dispatch(updateAppStoreKey('site', response.data.site))
                        store.dispatch(setUserAuth(response.data.user_token))
                        store.dispatch(updateAppProducts(availableProducts))
                        store.dispatch(updateInsStoreKey('legal_entities', response.data.legal_entities))
                        store.dispatch(updateQuestions(response.data.questions))
                        store.dispatch(updateSections([]))

                        let answers = {
                            ...store.getState().insurance.answers,
                            ...response.data.application_answers,
                          //TODO check it
                            isRestoreCheckout: 1
                        }
                        //Mark email is validated for those who came from bulk quote
                        if (!answers?.['validated_email']) {
                            answers['validated_email'] = answers['contact.email']
                        }

                        if (!answers?.['session_saved']) {
                            answers['session_saved'] = true
                        }

                        Object.keys(answers).forEach(a => {
                            if (typeof answers[a] === "string") answers[a] = answers[a].trim()
                        })

                        store.dispatch(changeAnswers(answers))
                        store.dispatch(updateInsStoreKey('session_answers', {...answers}))
                        store.dispatch(updateInsStoreKey('is_effective_date_updated', false))

                    })
                } else {
                    store.dispatch(setRestoreSession(false))
                    store.dispatch(updateAppStoreKey('google_api_key', response.data.gk))
                    store.dispatch(updateAppStoreKey('site', response.data.site))
                    store.dispatch(setUserAuth(response.data.user_token))
                    store.dispatch(updateAppProducts(response.data.products))
                    store.dispatch(updateInsStoreKey('legal_entities', response.data.legal_entities))
                    store.dispatch(updateQuestions(response.data.questions))
                    store.dispatch(updateSections([]))
                }
            })
            .catch(e => {
                datadogRum.addError(`Can't restore session! ${e}`)
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({loading: false})
                }, 0)
            })
    }

    getBrowserId() {
        const saved = this.props.cookies.get(cookie_name)
        const data = (saved) ? saved : null
        if (data?.ubi) return data.ubi
        else return uuidv4()
    }

    updateCookie(session_id) {
        this.props.cookies.set(cookie_name, JSON.stringify({
            usi: session_id ? session_id : this.state.session_id,
            ubi: this.state.browser_id,
        }), {path: '/', sameSite: 'none', secure: true});
    }

    render() {
        const {t} = this.props
        const CookieDisabledSidebar = <div
            className="MuiBox-root jss1385 sections-indicator">
            <div className="stepper"
                 style={{marginTop: '-75px'}}>
                <div
                    className="step completed">
                    <div className="number">
                        <div
                            className="glue-top"></div>
                        <div
                            className="glue-bottom"></div>
                        <div
                            className="indicator">
                            <svg width="20"
                                 height="20"
                                 viewBox="0 0 20 20"
                                 fill="#172152"
                                 stroke="#ABBAC7"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle
                                    cx="10"
                                    cy="10"
                                    r="8"
                                    fill="inherit"
                                    stroke="inherit"
                                    strokeWidth="4"></circle>
                            </svg>
                        </div>
                    </div>
                    <div className="label">
                        <div
                            className="name">Account
                            Info
                        </div>
                    </div>
                </div>
                <div
                    className="step active completed">
                    <div className="number">
                        <div
                            className="glue-top"></div>
                        <div
                            className="glue-bottom"></div>
                        <div
                            className="indicator">
                            <svg width="20"
                                 height="20"
                                 viewBox="0 0 20 20"
                                 fill="#172152"
                                 stroke="#ABBAC7"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle
                                    cx="10"
                                    cy="10"
                                    r="8"
                                    fill="inherit"
                                    stroke="inherit"
                                    strokeWidth="4"></circle>
                            </svg>
                        </div>
                    </div>
                    <div className="label">
                        <div
                            className="name">Customize Rate
                        </div>
                        <div
                            className="progress">
                            <div
                                className="MuiLinearProgress-root MuiLinearProgress-colorPrimary MuiLinearProgress-determinate"
                                role="progressbar"
                                aria-valuenow="67">
                                <div
                                    className="MuiLinearProgress-bar MuiLinearProgress-barColorPrimary MuiLinearProgress-bar1Determinate"
                                    style={{transform: 'translateX(-33%)'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        return (
            <div id="themeAppComponent" className={this.props.newThemeEnabled ? 'newTheme' : 'oldTheme'}>
            <Elements stripe={stripe} options={{fonts: stripe_fonts}}>
                <Router>
                    <ThemeProvider theme={acrisureTheme}>
                        <Provider store={store}>
                            {window.maintenance === 'true' ? (
                                <>
                                    <MHeader/>
                                    <div className="content cookie_disabled">
                                        {this.state.cookie_disabled ? (
                                            <>
                                              <div className="full-width-container get_notify_container" style={{ height: '100%' }}>
                                                <Grid container spacing={6} alignContent="stretch"
                                                      style={{ height: '100%', margin: '0 -24px' }}>
                                                  <Layout
                                                    sidebar={<SectionIndicator html_content={CookieDisabledSidebar}/>}
                                                    sections={<div className={'fields_column'}><Box
                                                      px={{ xs: 3, md: 7 }}>
                                                      <div className={'industry_class_section'}>
                                                        <div className={'section_container'}>
                                                          <SectionHead
                                                            title={this.props.t('common.cookie_disabled_title')}
                                                            txt={this.props.t('common.cookie_disabled_desc')}/>
                                                          <Grid container spacing={2}
                                                                className={'cookies_disabled_content'}>
                                                            <BrokenLink/>
                                                          </Grid>
                                                        </div>
                                                      </div>
                                                    </Box></div>}
                                                  />
                                                </Grid>
                                              </div>
                                            </>
                                        ) : (
                                            <>
                                                <h1 className="header">{t('common.maintenance_title')}</h1>
                                                <div className="text">{t('common.maintenance_desc')}</div>
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {this.state.loading ? (
                                        <>
                                            <Header/>
                                            <div className="content">
                                                <Container fixed className="content">
                                                    <Grid container spacing={6} alignContent="stretch"
                                                          justifyContent={'center'}
                                                          style={{height: '100%', margin: '0 -24px'}}>
                                                        <Grid item>
                                                            <LoadingButton size={50}/>
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            </div>
                                        </>
                                    ) : (
                                        <Switch>
                                          <Redirect exact from="/" to="/get-insurance"/>
                                          <Route key='insurance' path="/get-insurance">
                                              <StatsigProvider
                                                sdkKey="client-C0dRlKN8TzqYJ7g6ieUUs43G5FgwWMwzi3498EAqska"
                                                user={{
                                                  userID: `${process.env.NODE_ENV}--${this.state.session_id}`,
                                                  email: ""
                                                }}
                                                options={{
                                                  environment: { tier: `production` },
                                                }}
                                                waitForInitialization={false}
                                              >
                                                {(process.env.REACT_APP_SALESFORCE_CHAT_ENABLED === 'TRUE'
                                                    || window.sf_chat_enabled === 'TRUE') &&
                                                  <Helmet>
                                                  <script type="text/javascript"
                                                          src="https://service.force.com/embeddedservice/5.0/esw.min.js"></script>

                                                  <script src="/sfchat.js"></script>
                                                </Helmet>}
                                                <Insurance/>
                                              </StatsigProvider>
                                            </Route>
                                            <Route key="portal" path="/portal">
                                              <Portal/>
                                            </Route>
                                            <Route key='tuneup' path="/tuneup-quiz">
                                                <TuneUpQuiz/>
                                            </Route>
                                            <Route key="pages" path="*" exact>
                                                <Pages/>
                                            </Route>
                                        </Switch>
                                    )}
                                </>
                            )}
                        </Provider>
                    </ThemeProvider>
                </Router>
            </Elements>
            </div>
        )
    }

}

export default withCookies(withTranslation()(App))
