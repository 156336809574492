import React from 'react'

export const DocumentKeyIcon = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.2006 28.125H14.0625V23.9869L18.7819 19.2675C18.7609 19.0958 18.7503 18.923 18.75 18.75C18.7493 17.8007 19.037 16.8735 19.5748 16.0912C20.1126 15.3089 20.8753 14.7084 21.7619 14.369C22.6485 14.0296 23.6172 13.9675 24.5399 14.1907C25.4626 14.414 26.2958 14.9122 26.9292 15.6193C27.5625 16.3265 27.9663 17.2093 28.087 18.1509C28.2077 19.0925 28.0396 20.0486 27.605 20.8926C27.1704 21.7366 26.4898 22.4288 25.6532 22.8775C24.8166 23.3263 23.8635 23.5104 22.92 23.4056L18.2006 28.125ZM15.9375 26.25H17.4244L22.305 21.3694L22.8113 21.4856C23.4139 21.629 24.0469 21.5718 24.6141 21.3227C25.1813 21.0736 25.6517 20.6462 25.9538 20.1054C26.256 19.5646 26.3734 18.9399 26.2882 18.3263C26.203 17.7127 25.9199 17.1437 25.4818 16.7056C25.0438 16.2676 24.4748 15.9845 23.8612 15.8993C23.2476 15.8141 22.6229 15.9315 22.0821 16.2337C21.5413 16.5358 21.1139 17.0062 20.8648 17.5734C20.6157 18.1406 20.5585 18.7736 20.7019 19.3762L20.8181 19.8825L15.9375 24.7631V26.25Z" fill="#827252"/>
        <path d="M23.4375 19.6875C23.9553 19.6875 24.375 19.2678 24.375 18.75C24.375 18.2322 23.9553 17.8125 23.4375 17.8125C22.9197 17.8125 22.5 18.2322 22.5 18.75C22.5 19.2678 22.9197 19.6875 23.4375 19.6875Z" fill="#827252"/>
        <path d="M7.6665 5.625H18.9165V7.5H7.6665V5.625Z" fill="#000000"/>
        <path d="M7.6665 9.66669H18.9165V11.5417H7.6665V9.66669Z" fill="#000000"/>
        <path d="M7.6665 13.6667H13.2915V15.5417H7.6665V13.6667Z" fill="#000000"/>
        <path d="M7.6665 17.6666H13.2915V19.5416H7.6665V17.6666Z" fill="#000000"/>
        <path d="M7.6665 21.6666H11.4165V23.5416H7.6665V21.6666Z" fill="#000000"/>
        <path d="M11.25 28.125H5.625C5.12787 28.1245 4.65125 27.9268 4.29972 27.5753C3.9482 27.2238 3.7505 26.7471 3.75 26.25V3.75C3.7505 3.25287 3.9482 2.77625 4.29972 2.42472C4.65125 2.0732 5.12787 1.8755 5.625 1.875H20.625C21.1221 1.8755 21.5988 2.0732 21.9503 2.42472C22.3018 2.77625 22.4995 3.25287 22.5 3.75V12.1875H20.625V3.75H5.625V26.25H11.25V28.125Z" fill="#000000"/>
    </svg>
  
)