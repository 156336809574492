import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { datadogRum } from '@datadog/browser-rum'
import { withTranslation } from 'react-i18next'
import { ModalTransition } from '../../common/ModalTransition'
import AcrisureLogo from '../../components/icons/new/AcrisureLogo'
import ForgotPassword from './ForgotPassword'
import { setUserAuth } from '../../store/portal/actions'
import LoadingButton from '../../components/LoadingButton'
import Question from '../../components/form-components/Question'
import { Dialog, Grid, Typography } from '@material-ui/core'

//scss

class SignIn extends React.Component {

  state = {
    login: '',
    password: '',
    email: '',
    forgot_modal: false,
    expired: false,
    errors: {},
    loading: false
  }

  componentDidMount () {
    this.checkIfAlreadyLoggedIn()

    if (this.props.history.location.search) {
      this.setState({
        expired: this.checkExpired(),
        forgot_modal: this.checkForgot()
      })
    }
  }

  checkIfAlreadyLoggedIn () {
    if (this.props.isAuthorized) this.props.history.push('/portal/home')
  }

  checkExpired () {
    let expired = new URLSearchParams(this.props.history.location.search)
    expired = expired.get('expired')

    return !!expired
  }

  checkForgot () {
    let forgot = new URLSearchParams(this.props.history.location.search)
    forgot = forgot.get('forgot')

    return !!forgot
  }

  setLogin (value) {
    this.setState({ login: value.toLowerCase() })
  }

  setPassword (value) {
    this.setState({ password: value })
  }

  login () {
    this.setState({ loading: true }, () => {
      axios.post('/api/user/login', {
        username: this.state.login,
        password: this.state.password
      })
        .then(response => {
          if (response.data.authorized) {
            datadogRum.setUser({
              session_id: 'portal',
              name: `${response.data.name} ${response.data.surname}`,
              email: response.data.email
            })
            this.props.setUserAuth(response.data)
            this.setState({ loading: false }, () => {
              setTimeout(() => {
                if (this.props.history.location?.state?.from) this.props.history.replace(this.props.history.location.state.from)
                else this.props.history.push('/portal/home')
              }, 0)
            })
          }
        })
        .catch(e => {
          this.setState({
            errors: {
              login: e.response.status === 404
                ? this.props.t('auth.error.user_not_found')
                : this.props.t('common.something_went_wrong')
            },
            loading: false
          })
        })
    })
  }

  toggleForgotPasswordModal () {
    this.setState({ forgot_modal: !this.state.forgot_modal })
  }

  validate (e) {
    if (e) e.preventDefault()

    let errors = {}

    if (!/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(this.state.login))
      errors.login = this.props.t('auth.error.invalid_req_login')

    if (this.state.password.length < 1) errors.password = this.props.t('auth.error.pass_req')

    this.setState({ errors: errors })

    if (Object.keys(errors).length === 0) this.login()
  }

  render () {
    const { t } = this.props

    return (
      <div className={'content insurance_content'}>
        <div className={'insurance_layout auth_layout'}>
          <form className={'auth_card sign_in_card'} autoComplete="off"
                onSubmit={(e) => this.validate(e)}>
            <Grid container spacing={2} style={{ height: '100%' }}>
              <Grid item xs={12}>
                <AcrisureLogo className={'auth_logo'}/>
              </Grid>
              <Grid item xs={12}>
                <p className={'ttl'}>{t('auth.sign_in_title')}</p>
              </Grid>

              {this.state.expired && (
                <Grid item xs={12}>
                  <Typography>{t('auth.sign_in_expired')}</Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Question fullWidth
                          id="login"
                          type="email"
                          question={t('form.label.email')}
                          answer={this.state.login}
                          error={!!this.state.errors.login}
                          helperText={this.state.errors.login}
                          onChange={(id, value) => {
                            this.setLogin(value)
                          }}
                />
              </Grid>
              <Grid item xs={12}>
                <Question fullWidth
                          id="password"
                          type="password"
                          question={t('auth.pass')}
                          answer={this.state.password}
                          error={!!this.state.errors.password}
                          helperText={this.state.errors.password}
                          onChange={(id, value) => {
                            this.setPassword(value)
                          }}/>
              </Grid>

              <Grid item xs={12} className={'form_actions'}>
                {this.state.loading ? (
                  <LoadingButton/>
                ) : (
                  <>
                    <button type="button" className={'a_btn a_btn_link a_btn_forgot_pass'} onClick={() => {
                      this.toggleForgotPasswordModal()
                    }}>{t('auth.sign_in_forgot')}</button>

                    <button type="submit"
                            className={'a_btn a_btn_action_1 a_btn_sign_in'}
                            onClick={(e) => {
                              this.validate(e)
                            }}
                    >{t('auth.btn.sign_in')}</button>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <div className="is_new">
                  <p className={'ttl'}>{t('auth.sign_in_sub_title')}</p>
                  <p className={'txt'}>{t('auth.sign_in_have_account')}</p>
                  <a className={'a_btn a_btn_request_quote'}
                     href="/get-insurance/business-name-and-address"
                  >{t('auth.btn.request_quote')}</a>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
        <Dialog
          className={'modal__wrapper forgot_password_modal__wrapper'}
          maxWidth="sm"
          scroll="body"
          open={this.state.forgot_modal} onClose={() => {
          this.toggleForgotPasswordModal()
        }} TransitionComponent={ModalTransition}>
          <ForgotPassword history={this.props.history} onClose={() => {
            this.toggleForgotPasswordModal()
          }}/>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthorized: state.portal.isAuthorized
  }
}

const mapDispatchToProps = {
  setUserAuth
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignIn))
