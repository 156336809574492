import { some } from 'lodash'
import React from 'react'
import axios from 'axios'
import AcrisureLogo from '../../components/icons/new/AcrisureLogo'
import { withTranslation } from 'react-i18next'
import Question from '../../components/form-components/Question'
import { Grid } from '@material-ui/core'

import '../../scss/v2/_password_hints.scss'

class ResetPassword extends React.Component {

  state = {
    code: '',
    password: '',
    confirm: '',
    errors: {},
    notification: {
      show: false,
      type: 'success',
      text: ''
    },
    showPasswordHint: false,
    passwordHint: {
      uppercaseSymbol: {
        error: true,
        txt: 'An uppercase letter (A-Z)'
      },
      lowercaseSymbol: {
        error: true,
        txt: 'A lowercase letter (a-z)'
      },
      digitSymbol: {
        error: true,
        txt: 'A number (0-9)'
      },
      specialSymbol: {
        error: true,
        txt: 'A special character (!@#$)'
      },
      length: {
        error: true,
        txt: 'Range of 8 to 16 symbols length'
      }
    }
  }

  constructor (props) {
    super(props)
    if (this.props.history.location.search) this.state.code = this.parseURLCode()
  }

  parseURLCode () {
    let code = new URLSearchParams(this.props.history.location.search)
    code = code.get('code')

    return code ? code : ''
  }

  onChangeHint (field, value) {
    let passwordHint = { ...this.state.passwordHint }
    passwordHint[field].error = value
    this.setState({ passwordHint: { ...passwordHint } })
  }

  checkPasswordHandler = (value) => {
    let pass = value

    let lowerCaseLetters = /^(?=.*[a-z])/
    if (!lowerCaseLetters.test(pass)) {
      this.onChangeHint('lowercaseSymbol', true)
    } else {
      this.onChangeHint('lowercaseSymbol', false)
    }
    //Validate capital letters
    let upperCaseLetters = /^(?=.*[A-Z])/
    if (!upperCaseLetters.test(pass)) {
      this.onChangeHint('uppercaseSymbol', true)
    } else {
      this.onChangeHint('uppercaseSymbol', false)
    }
    //Validate a number
    let numbers = /^(?=.*\d)/
    if (!numbers.test(pass)) {
      this.onChangeHint('digitSymbol', true)
    } else {
      this.onChangeHint('digitSymbol', false)
    }
    //Validate length
    let pasLength = /^([^\s]){8,16}$/
    if (!pasLength.test(pass)) {
      this.onChangeHint('length', true)
    } else {
      this.onChangeHint('length', false)
    }
    //Validate Special Character
    let specialSymbol = /^(?=.*[^\w\d\s:])/
    if (!specialSymbol.test(pass)) {
      this.onChangeHint('specialSymbol', true)
    } else {
      this.onChangeHint('specialSymbol', false)
    }
    this.setState({ showPasswordHint: this.isPasswordHelpBoxHasErrors(), password: value })

  }

  setConfirm (value) {
    this.setState({ confirm: value })
  }

  validate (e) {
    e.preventDefault()

    let errors = {}

    const { t } = this.props

    if (this.state.code.length < 1) errors.code = t('auth.error.code_req')

    if (this.state.password !== this.state.confirm) errors.confirm = t('auth.error.pass_not_equal')

    Object.keys(this.state.passwordHint).forEach((item) => {
      if (this.state.passwordHint[item].error) {
        errors.password = 'common'
      }
    })
    if (errors.password) this.checkPasswordHandler(this.state.password)
    this.setState({ showPasswordHint: this.isPasswordHelpBoxHasErrors(), errors: errors })

    if (Object.keys(errors).length === 0) this.reset()
  }

  reset () {
    const { t } = this.props
    axios.post('/api/reset-pass', {
      code: this.state.code,
      password: this.state.password
    })
      .then(response => {
        this.setState({ errors: {} }, () => {
          setTimeout(() => {
            this.props.history.push('/sign-in')
          }, 0)
        })
      })
      .catch(e => {
        //error notification
        this.setState({
          errors: {
            common: e.response.status === 400
              ? t('auth.error.user_not_found')
              : t('auth.error.unexpected_error_please_retry')
          }
        })
      })
  }

  isPasswordHelpBoxHasErrors(){
    let list = Object.keys(this.state.passwordHint).map((item) => {
      return  {error: !!this.state.passwordHint[item].error};
    })
    return some(list,['error', true])
  }

  onBlurPassField(){
    this.setState({showPasswordHint : this.isPasswordHelpBoxHasErrors()})
  }
  render () {
    const { t } = this.props

    return (
      <div className={'auth_section'}>
        <form className={'auth_wrapper'} autoComplete="off" onSubmit={(e) => this.validate(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AcrisureLogo className={'registration_logo'}/>
            </Grid>
            <Grid item xs={12}>
              <h1 className={'ttl'}>{t('auth.btn.pass_reset')}</h1>
            </Grid>
            <Grid item xs={12}>
              <Question fullWidth
                        type="password"
                        question={t('auth.new_pass')}
                        tooltip={t('auth.new_pass_tooltip')}
                        answer={this.state.password}
                        onChange={(id, value) => {
                          this.checkPasswordHandler(value)
                        }}
                        onBlur={()=>this.onBlurPassField()}
                        passwordHint={this.state.passwordHint}
                        showPasswordHint={this.state.showPasswordHint}
              />
            </Grid>
            <Grid item xs={12}>
              <Question fullWidth
                        type="password"
                        question={t('auth.confirm_pass')}
                        tooltip={t('auth.confirm_pass_tooltip')}
                        answer={this.state.confirm}
                        error={!!this.state.errors.confirm}
                        helperText={this.state.errors.confirm}
                        onChange={(id, value) => {
                          this.setConfirm(value)
                        }}
              />
            </Grid>
            {!!this.state.errors.common && (
              <Grid item xs={12}>
                <p className={'error_txt error_txt_lg'}>{this.state.errors.common}</p>
              </Grid>
            )}
            <Grid item xs={12} className={'form_actions right_action'}>
              <button type={'button'}
                      className={'a_btn a_btn_action_1'}
                      color="primary" onClick={(e) => {
                this.validate(e)
              }}>{t('auth.btn.pass_reset_login')}</button>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

export default withTranslation()(ResetPassword)
