import React, {useEffect, useState} from 'react'
import App from './components/App';
import {CookiesProvider} from 'react-cookie';

import "./services/i18n";

export default function Root() {
    const [isNewTheme, setIsNewTheme] = useState(null)

    useEffect(()=>{
        const isEnabled = process.env?.REACT_APP_IS_NEW_THEME_ENABLED === 'TRUE'
            || window.is_new_theme_enabled === 'TRUE'
        setIsNewTheme(isEnabled)
    }, [])
    return (
        <CookiesProvider>
                <App newThemeEnabled={isNewTheme}/>
        </CookiesProvider>
    );
}