import React from "react";
import axios from "axios";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import WCOwner from "../../components/insurance/WCOwner";
import {ArrowBack} from "@material-ui/icons";
import {Grid} from "@material-ui/core";
import OOEADisclaimer from "../../components/modals/OOEADisclaimer";
import {updateProgress, updateWCLocations} from "../../store/insurance/actions";
import NextSectionButton from "../../components/form-components/NextSectionButton";
import SectionHead from "../../components/insurance/SectionHead";
import {trimBusinessName} from "../../helpers/trimBusinessName";
import Question from "../../components/form-components/Question";

const ownerTemplate = {
    first_name: '',
    last_name: '',
    percent: '',
    salary: '',
    title_code: null,
    is_included: true,
    disclaimer_required: false,
}

class WCOwners extends React.Component {

    state = {
        errors: {},
        titles: [],
        legal_entities: [],
        legal_entity: null,
        show_disclaimer: false,
        isAdditionOwners: false,
        options: [
            {
                "id": 1,
                "text": "Yes",
                "value": "Yes"
            },
            {
                "id": 2,
                "text": "No",
                "value": "No"
            }
        ],
    }

    constructor(props) {
        super(props);

        this.state.nextUrl = "/get-insurance" + window.getNextSubSectionPath(this.props.subsections, this.props.next_subsection)

        this.validateForm = this.validateForm.bind(this)

        this.props.updateProgress({
            current_section: this.props.section.section_index,
            current_subsection: this.props.next_subsection - 1,
        })
    }

    componentDidMount() {
        this.getLegalEntities();
        const owners = this.getOwnersSettings();
        if (owners.length === 0) {
            this.addOwnerLine({
                ...ownerTemplate,
                first_name: this.props.answers["contact.first_name"],
                last_name: this.props.answers["contact.last_name"]
            })
        }
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.legal_entity !== prevState.legal_entity) this.getTitles()
    }

    getLegalEntities() {
        const match = this.props.legal_entities.find(i => i.description === this.props.answers["business.structure"]);
        this.setState({legal_entity: match ? match.code : ''})
    }

    getTitles() {
        axios.get("/api/wc_owner_titles?legal_entity=" + (this.state.legal_entity || "___")).then(response => {
            const titles = response.data.sort((a, b) => {
                if (a.description < b.description) {
                    return -1;
                }
                if (a.description > b.description) {
                    return 1;
                }
                return 0;
            });

            this.setState({titles})
        })
    }

    addOwnerLine(template) {
        const index = this.getPrimaryLocationIndex();
        const owners = [...(this.props.wc_locations[index].owners || [])]
        const locations = [...this.props.wc_locations]

        owners.push({...template, id: this.getOwnersSettings().length})
        locations[index].owners = [...owners]
        if (owners.length >1) locations[0].is_addition_owners = true

        this.props.updateWCLocations(locations)
    }

    validateForm(event) {
        event.preventDefault()
        const errors = {}

        let key = 0
        let percentage_sum = 0
        let disclaimer_required = false

        this.props.wc_locations.forEach(locations => {
            (locations.owners || []).forEach(itm => {
                percentage_sum += Number(itm.percent)
                if (itm.disclaimer_required && !itm.is_included)
                    disclaimer_required = true

                if (!itm.title_code)
                    errors[key + '.title_code'] = this.props.t('page.ooi.title_req')

                if (!/^(?!\s*$).+/.test(itm.first_name))
                    errors[key + '.first_name'] = this.props.t('form.error.first_name_req')

                if (!/^(?!\s*$).+/.test(itm.last_name))
                    errors[key + '.last_name'] = this.props.t('form.error.last_name_req')

                if (itm.is_included && (itm.salary === 0 || !/^[\d]{1,10}$/.test(itm.salary)))
                    errors[key + '.salary'] = this.props.t('page.ooi.salary_req')

                if (itm.is_included && (!/^[\d]{1,3}$/.test(itm.percent)))
                    errors[key + '.percent'] = this.props.t('page.ooi.ownership_percent_req')

                if (percentage_sum > 100)
                    errors[key + '.percent'] = this.props.t('page.ooi.total_percent_warn')

                key++
            })
        })

        this.setState({errors})

        if (Object.keys(errors).length === 0) {
            if (disclaimer_required) this.setState({show_disclaimer: true})
            else this.submit()
        }
    }

    submit() {
        this.props.history.push(this.state.nextUrl)
    }

    clearErrors() {
        this.setState({errors: {}})
    }

    closeDisclaimer() {
        this.setState({show_disclaimer: false})
    }

    goBack() {
        let index = this.props.current_subsection - 1
        if (this.props.subsections.length > 0 && index >= 0 && this.props.subsections[index]) {
            let path = "/get-insurance" + window.getPrevSubSectionPath(this.props.subsections, index)
            this.props.history.push(path)
        } else this.props.history.goBack()
    }

    getPrimaryLocationIndex() {
        let index = 0;
        this.props.wc_locations.forEach((location, idx) => {
            if (location.primary) index = idx;
        });
        return index;
    }

    getOwnersSettings() {
        const owners = []
        this.props.wc_locations.forEach((location, idx) => {
            if (location.primary && location.owners) {
                location.owners.forEach((o, id) => {
                    owners.push({id, locationIndex: idx, ...o});
                });
            }
        })

        return owners
    }

    isAdditionOwners(value) {
        const locations = [...this.props.wc_locations]

        if (value === "No" && locations[0].is_addition_owners) {
            locations[0].owners = locations[0].owners.slice(0,1)
            // locations[0].owners = [locations[0].owners[0]]
            locations[0].is_addition_owners = false
            this.props.updateWCLocations(locations)

        } else if (value === "Yes" && !locations[0].is_addition_owners) {
            this.addOwnerLine(ownerTemplate)
        }

    }

    render() {
        const {t} = this.props

        return (
            <div className="wc_owners_section wc_custom">
                <form autoComplete="off" onSubmit={this.validateForm} className={'section_container'}>
                        <SectionHead
                            title={t('page.ooi.title', {value: trimBusinessName(this.props.answers['business.name'])})}
                            txt={t('page.ooi.description')}/>
                    <Grid container spacing={2} className={'section_form'}>

                        {this.getOwnersSettings().slice(0, 1).map((i, idx) =>
                            <WCOwner
                                key={idx}
                                index={idx}
                                locationIndex={i.locationIndex}
                                titles={this.state.titles}
                                errors={this.state.errors}
                                legal_entity={this.state.legal_entity}
                                onClearErrors={() => {
                                    this.clearErrors()
                                }}
                            />)
                        }
                        <Grid item xs={12} className={'owner_officer_cnt'}>
                            <Question fullWidth
                                      type="dropdown"
                                      question={'Does your business have additional owners?'}
                                      answer={this.props.wc_locations[0].is_addition_owners ? "Yes" : "No"}
                                      variant="outlined"
                                      placeholder={null}
                                      options={this.state.options}
                                      onChange={(id, value) => {
                                          this.isAdditionOwners(value)
                                      }}
                            />
                        </Grid>

                        {this.getOwnersSettings().slice(1).map((i, idx) =>
                            <WCOwner
                                isNotFirst
                                key={idx + 1}
                                index={idx + 1}
                                locationIndex={i.locationIndex}
                                titles={this.state.titles}
                                errors={this.state.errors}
                                legal_entity={this.state.legal_entity}
                                onClearErrors={() => {
                                    this.clearErrors()
                                }}
                            />)
                        }
                        {this.getOwnersSettings().length > 1 && <Grid item xs={12}>
                            <button type={'button'} className="wc_additional_add"
                                    onClick={() => {
                                        this.addOwnerLine(ownerTemplate)
                                    }}
                            ><span>+{t('common.btn_add_owner')}</span></button>
                        </Grid>}
                    </Grid>

                    <Grid container className={'section_navigation is_back_btn'}>
                        <button className="section_back_btn"
                                type={'button'}
                                onClick={() => {
                                  this.goBack()
                                }}>
                          <span className={'a_btn a_btn_transparent'}>
                            <ArrowBack/>
                          </span>
                          <span className="a_mob_back">Back</span>
                        </button>
                        <NextSectionButton
                          validateFormMethod={(e) => this.validateForm(e)}
                          className="section_next_btn"/>
                        <NextSectionButton
                          validateFormMethod={(e) => this.validateForm(e)}
                          className="section_next_btn__mobile"/>
                      </Grid>
                        <OOEADisclaimer open={this.state.show_disclaimer}
                                        onClose={() => {
                                            this.closeDisclaimer()
                                        }}
                                        onAccept={() => {
                                            this.submit()
                                        }}
                        />
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
    subsections: state.insurance.subsections,
    current_subsection: state.insurance.current_subsection,
    subsections_questions: state.insurance.subsections_questions,
    wc_locations: state.insurance.wc_locations,
    legal_entities: state.insurance.legal_entities,
})

const mapDispatchToProps = {
    updateProgress,
    updateWCLocations,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WCOwners));
