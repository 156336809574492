import React from "react";

export const Bank = (props) => (
    <svg width="41" height="43" viewBox="0 0 41 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.7159 16.7978H36.0027C37.1671 16.7978 37.5376 16.2103 37.6435 15.8365C37.7493 15.4627 37.8023 14.7685 36.8495 14.0742L21.6059 3.12664C20.6532 2.4324 19.0653 2.4324 18.1126 3.12664L2.86903 14.1276C1.91631 14.8219 1.96924 15.5161 2.07509 15.8899C2.18095 16.2103 2.55146 16.7978 3.7159 16.7978ZM19.2771 4.78212C19.5417 4.56851 20.1239 4.56851 20.3886 4.78212L34.2031 14.7685H5.46256L19.2771 4.78212Z"
            fill="black" stroke="black" strokeWidth="0.5"/>
        <path
            d="M37.4319 39.6543H2.2869C1.70468 39.6543 1.28125 40.1349 1.28125 40.669C1.28125 41.2564 1.75761 41.6836 2.2869 41.6836H37.4319C38.0141 41.6836 38.4375 41.203 38.4375 40.669C38.4375 40.0815 38.0141 39.6543 37.4319 39.6543Z"
            fill="black" stroke="black" strokeWidth="0.5"/>
        <path
            d="M34.7323 21.2837C35.3145 21.2837 35.7379 20.8031 35.7379 20.269C35.7379 19.735 35.2616 19.2544 34.7323 19.2544H4.98612C4.4039 19.2544 3.98047 19.735 3.98047 20.269C3.98047 20.8031 4.45683 21.2837 4.98612 21.2837H7.10329V35.115H4.98612C4.4039 35.115 3.98047 35.5957 3.98047 36.1297C3.98047 36.7171 4.45683 37.1444 4.98612 37.1444H34.6794C35.2616 37.1444 35.685 36.6637 35.685 36.1297C35.685 35.5423 35.2087 35.115 34.6794 35.115H32.5622V21.2837H34.7323ZM22.7703 21.2837V35.115H16.9481V21.2837H22.7703ZM9.16752 21.2837H14.9368V35.115H9.16752V21.2837ZM30.6038 35.115H24.7816V21.2837H30.5509V35.115H30.6038Z"
            fill="black" stroke="black" strokeWidth="0.5"/>
    </svg>
)