import React from "react";
import clsx from "clsx";
import {connect} from "react-redux";
import {cloneDeep, includes, isEqual} from "lodash";
import Question from "../../Question";
import {withTranslation} from "react-i18next";
import {Grid, Typography} from "@material-ui/core";
import {DiyaRounded} from "../../../icons/DiyaRounded";
import WhatsCoveredPL from "../../../modals/WhatsCoveredPL";
import WhatsCoveredHNOA from "../../../modals/WhatsCoveredHNOA";
import WhatsCoveredCyber from "../../../modals/WhatsCoveredCyber";
import {changeAnswers} from "../../../../store/insurance/actions";
import WhatsCoveredEB from "../../../modals/WhatsCoveredEB";
import sources_update from '../../../../common/decorator'

//scss
import '../../../../scss/q_custom.scss'

class CheckBoxQuestionSet extends React.Component {

    state = {
        questions: []
    }

    constructor(props) {
        super(props);

        let questions = this.props.subsections_questions.filter((i) => {
            let parent_key = this.props.qid.replace('.is_selected', '')

            return i.qid !== this.props.qid && i.qid.startsWith(parent_key)
        })
        this.state.questions = cloneDeep(questions)
            .map(q => {
                q.hidden = false
                return q
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let questions = this.props.subsections_questions.filter((i) => {
            let parent_key = this.props.qid.replace('.is_selected', '')

            return i.qid !== this.props.qid && i.qid.startsWith(parent_key)
        })
        let new_questions = cloneDeep(questions)
            .map(q => {
                q.hidden = false
                return q
            })

        if (!isEqual(this.state.questions, new_questions))
            this.setState({
                questions: new_questions
            })
    }

    onChange(newValue) {
        this.props.changeAnswers({
            [this.props.qid]: newValue ? 'Yes' : 'No'
        })
        sources_update(this.props.answers, this.props.qid)
    }

    hasError() {
        let error = false

        let questions_names = this.state.questions.map(q => q.qid)

        Object.keys(this.props.error).forEach(line => {
            if (includes(questions_names, line)) error = true
        })

        return error
    }

    updateAnswer(qid, value) {
        this.props.changeAnswers({
            [qid]: value
        })
    }

    getQuestionHelper(optionalCoverage) {

        switch (optionalCoverage) {
            case 'policy.hassle_free_policy_cancelation':
                return <DiyaRounded/>
            case 'eb.coverage.is_selected':
                return <WhatsCoveredEB/>
            case 'cyber.coverage.is_selected':
                return <WhatsCoveredCyber/>
            case 'hnoa.coverage.is_selected':
                return <WhatsCoveredHNOA/>
            case 'mpl.coverages.is_selected':
                return <WhatsCoveredPL/>
            default:
                return null
        }
    }

    render() {
        const {t} = this.props
        let switcherCheck = clsx({
            'switch-body': true,
            'checked': this.props.answers[this.props.qid] === 'Yes'
        })

        return (
            <Grid item
                  className={`additional-question-item ${this.props.qid === 'policy.hassle_free_policy_cancelation' ? 'hfp-cancelation' : null}`}>
                <Grid container alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item xs={12} md={12} lg={12} className={'additional-question-switcher-item'}>
                        <div className="switch-wrapper">
                            <span className={switcherCheck}>
                            <label htmlFor={'switch-' + this.props.qid}>
                                <input type="checkbox" id={'switch-' + this.props.qid}
                                       onChange={(e) => this.onChange(e.target.checked)}
                                       checked={this.props.answers[this.props.qid] === 'Yes'}/>
                                <span className="thumb"/>
                            </label>
                        </span>
                            <Typography
                                className={'additional-question-text'}>{this.props.question}&nbsp;{this.props.tooltip}</Typography>
                        </div>
                    </Grid>
                    {this.props.diya_helper && (
                        <Grid item xs={12}>
                            <Typography className="custom-question-diya-helper">{this.props.diya_helper}</Typography>
                        </Grid>
                    )}
                    {this.props.answers[this.props.qid] === 'Yes' && (
                        <Grid item xs={12} className={'additional-section-item'}>
                            <Grid container p={2} spacing={2} className="additional-item">
                                {this.state.questions.map((q, i) => {
                                    if (!q.disabled) return (
                                        <Grid item xs={12} md={10} lg={8} key={i}>
                                            <Question
                                                fullWidth
                                                {...q}
                                                options={q.options}
                                                required={q.required}
                                                type={q.question_type}
                                                question={q.question_text}
                                                field_length={q.max_length}
                                                answer={this.props.answers[q.qid]}
                                                error={!!this.props.error?.[q.qid]}
                                                helperText={this.props.error?.[q.qid]}
                                                onChange={(id, value) => this.updateAnswer(q.qid, value)}
                                            />
                                        </Grid>
                                    )
                                    else return null
                                })}
                            </Grid>
                            {this.props.qid === 'hnoa.coverage' && (
                                <Grid container p={2} spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography>{t('modal.hnoa_rejection.sub_txt')}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
    subsections_questions: state.insurance.subsections_questions
})

const mapDispatchToProps = {
    changeAnswers
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckBoxQuestionSet))