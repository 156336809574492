import React from 'react'
import { includes } from 'lodash'
import ClaimCarrierItem from '../claim/ClaimCarrierItem'
import Loading from '../../Loading'
import ClaimFAQ from '../sidebar/ClaimFAQ'
import '../../../scss/v2/portal/_carrier_claim.scss'

const carriersClaimData = [
  {
    type: 'ACUITY',
    carrier: 'Acuity',
    title: 'Acuity, A Mutual Insurance Company',
    text: 'Report the loss to Acuity 24/7 by visiting their website or calling.',
    phone: '800-242-7666',
    link: 'https://www.acuity.com/claims'
  },
  {
    type: 'COTERIE',
    carrier: 'Coterie',
    title: 'Benchmark Insurance (managed by Coterie)',
    text: 'Report the loss to Benchmark 24/7 by visiting their website or calling.',
    phone: '855-680-2440',
    link: 'https://coterieinsurance.com/claims/'
  },
  {
    type: 'COTERIE_FL',
    carrier: 'Coterie',
    title: 'ClearSpring Insurance (managed by Coterie)',
    text: 'Report the loss to ClearSpring 24/7 by visiting their website or calling.',
    phone: '855-680-2440',
    link: 'https://coterieinsurance.com/claims/'
  },
  {
    type: 'CNA',
    carrier: 'CNA',
    title: 'CNA',
    text: 'Report the loss to CNA 24/7 by visiting their website, calling, or emailing.',
    email: 'ReportClaim@fnolcna.com',
    phone: '833-366-5262',
    link: 'https://www.cna.com/web/guest/cna/claim-center'
  },
  {
    type: 'CF',
    carrier: 'Crum & Forster',
    title: 'Crum & Forster',
    text: 'Report the loss to Crum & Forster 24/7 by calling, faxing, emailing, or using their secure site.',
    email: 'crumandforsternol@cfins.com',
    phone: '800-690-5520',
    link: 'https://www.cfins.com/claims-solutions/'
  },
  {
    type: 'EMPLOYERS',
    carrier: 'Employers',
    title: 'Employers Insurance Company',
    text: 'Report a workers’ compensation claim to Employers 24/7 by calling or visiting their website for more information.',
    email: 'customersupport@employers.com',
    phone: '855-365-6010',
    link: 'https://www.employers.com/claims-services/'
  },
  {
    type: 'PIE',
    carrier: 'Pie',
    title: 'Pie Insurance',
    text: 'Report a workers\' compensation claim to Pie by calling or visiting their website for more information.',
    phone: '844-581-0828',
    link: 'https://pieinsurance.com/claims/'
  },
  {
    type: 'WINGMAN',
    carrier: 'Wingman',
    title: 'Wingman',
    text: 'Report a Wingman cyber insurance or tech e&o claim to AXIS by calling, faxing, or emailing.',
    email: 'USFNOL@axiscapital.com',
    phone: '866-259-5435',
    fax: '678-746-9315',
    link: 'https://www.axiscapital.com/claims'
  },
  {
    type: 'AMTRUST',
    carrier: 'AmTrust',
    title: 'AmTrust Financial',
    text: 'Report the claim to AmTrust 24/7 by calling or visiting their website.',
    phone: '888-239-3909',
    link: 'https://amtrustfinancial.com/claims'
  },
  {
    type: 'COALITION',
    carrier: 'Coalition',
    title: 'Coalition',
    text: 'Report a cyber insurance or tech e&o claim to Coalition by calling.',
    phone: '833-866-1337',
    link: 'https://www.coalitioninc.com/contact'
  },
  {
    type: 'MARKEL',
    carrier: 'Markel',
    title: 'Markel',
    text: 'Report a workers\' compensation or business owner\'s policy claim to Markel by visiting their website or calling.',
    phone: [{txt:'(General)', number:'800-362-7535'}, {txt:'(Workers\' Compensation)', number:'888-500-3344'}],
    link: 'https://www.markel.com/insurance/claims/report-a-claim'
  }
]

const CarrierClaims = (props) => {
  //["ACUITY", "COTERIE", "EMPLOYERS", "CF", "CNA","WINGMAN", "PIE", "AMTRUST", "MARKEL", "COALITION"]
  return (
    <>
      <div className="carrier_claims_desc">
        <p className="ttl">If you need to file a claim, please contact your carrier via the methods below.</p>
        <p className="txt">We're here to help. We know having to file a claim is an unwelcome disruption. Your
          carrier is detailed on your policy document. If you have any questions, please contact us.</p>
      </div>
      <div className={'carrier_claims'}>

        <div className="content_column">
          {props.loading ? <Loading/> :
            carriersClaimData.map((item, key) => {
              if (includes(props.carriers, item.type)) {
                return (<ClaimCarrierItem {...item} key={key}/>)
              } else return null
            })}
        </div>
        <div className="sidebar_column">
          <div className="grid_item grid_promo">
            <ClaimFAQ/>
          </div>
        </div>
      </div>
    </>
  )
}

export default CarrierClaims