import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {changeAnswers} from '../../store/insurance/actions'
import {withTranslation} from 'react-i18next'
import {Box, Grid} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import SectionIndicator from '../../components/insurance/SectionIndicator'
import SectionDetailInfo from '../../components/insurance/SectionDetailInfo'
import SectionHead from '../../components/insurance/SectionHead'
import {trimBusinessName} from '../../helpers/trimBusinessName'
import Layout from '../../layouts/insurance/Layout'


const GetNotified = (props) => {

    const {t, sections, currentSection, currentSectionIndex, answers, onClose} = props
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')

    useEffect(()=>{
        setTitle(t('modal.get_notified.notify_title', { value: trimBusinessName(answers['business.name']) }))
        setDescription(t('modal.get_notified.notify_description'))
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, [t, answers])


    return (
        <div className="full-width-container get_notify_container" style={{height: '100%'}}>
            <Grid container spacing={6} alignContent="stretch"
                style={{ height: '100%', margin: '0 -24px' }}>
                <Layout
                    sidebar={<>
                        <SectionIndicator isDialogSectionIndicator
                            sections={sections}
                            current={currentSection}
                            current_index={currentSectionIndex}
                            total_progress={100}
                        />
                        {props.total_progress !== 0 && <div className="section_details_info">
                            <SectionDetailInfo sections={sections}
                                current={currentSection}
                                current_index={currentSectionIndex}
                                total_progress={props.total_progress}
                            />
                        </div>}
                    </>}
                    sections={<div className={'fields_column'}>
                        <Box px={{ xs: 3, md: 7 }}>

                            <div className={'industry_class_section'}>
                                <div className={'section_container'}>
                                    <SectionHead title={title} txt={''}/>
                                    <Grid container spacing={2} className={'section_form'}>
                                        <Grid item xs={12}>
                                            <p className={'content_txt'} dangerouslySetInnerHTML={{__html: description}}/>
                                        </Grid>
                                    </Grid>

                                    <Grid container className={'section_navigation is_back_btn'}>
                                        <button className="section_back_btn"
                                            type={'button'}
                                            onClick={onClose}>
                                            <span className={'a_btn a_btn_transparent'}>
                                                <ArrowBack/>
                                            </span>
                                            <span className="a_mob_back">Back</span>
                                        </button>
                                    </Grid>
                                </div>
                            </div>
                        </Box>
                    </div>}
                />
            </Grid>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        site: state.app.site,
        answers: state.insurance.answers,
        subsections: state.insurance.subsections,
        sections: state.insurance.sections,
        currentSection: state.insurance.current_section,
        currentSectionIndex: state.insurance.current_section_index,
        underwriterStatements: state.insurance.underwriterStatements
    }
}

const mapDispatchToProps = {
    changeAnswers
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GetNotified))