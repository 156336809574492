import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'

function Footer (props) {
  const [year, setYear] = useState('')

  const onTrustHandler = ()=>{
    window.OneTrust.ToggleInfoDisplay()
  }
  const setCopyYear = () => {
    const date = new Date()
    setYear(date.getFullYear())
  }
  useEffect(() => {
    setCopyYear()
  }, [])
  return (
    <Box py={2} px={1} textAlign="center" className={'footer_disclaimer'}>
      <p className={'disclaimer_text'}
         dangerouslySetInnerHTML={{ __html: props.t('common.footer_disclaimer') }}/>
      <p className={'disclaimer_text'}
         dangerouslySetInnerHTML={{ __html: props.t('common.footer_copy', { year: year }) }}/>
      <ul className={'footer_links'}>
        <li>
          <a className={'txt_link'} target="_blank" rel="noopener noreferrer"
             href="https://www.acrisure.com/terms-of-use">Terms of Use</a>
        </li>
        <li>
          <a className={'txt_link'} target="_blank" rel="noopener noreferrer"
             href="https://www.acrisure.com/privacy-policy">Privacy
            Policy</a>
        </li>
        <li>
          <a className={'txt_link'} target="_blank" rel="noopener noreferrer"
             href="https://www.acrisure.com/legal-notice">Legal</a>
        </li>
        <li>
          <span className={'txt_link'} rel="noopener noreferrer" data-variantitemid="{CF162128-8182-43BA-9621-2F764C7D830C}"
                data-variantfieldname="Link"
                onClick={()=>onTrustHandler()}>Do Not Sell Or Share My Personal Information (Opt-Out)</span>
        </li>
      </ul>
    </Box>
  )
}

export default withTranslation()(Footer)
