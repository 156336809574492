import React from "react";
import axios from 'axios'
import {Box, Button, Container, Dialog, Grid, Slide, Typography, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {Close} from "@material-ui/icons";
import {withTranslation} from 'react-i18next';
import Question from "../form-components/Question";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const styles = theme => ({
    header: {
        fontWeight: 900,
        fontSize: '24px',
        lineHeight: '29px',
        margin: '1rem 0 .5rem'
    },
    header_text: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        margin: '0 0 .5rem'
    }
})

class ContactUs extends React.Component {

    state = {
        is_open: false,
        name: '',
        text: '',
        email: '',
        error: {},
    }

    constructor(props) {
        super(props);

        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        document.addEventListener('open-contact-form', this.openModal)
    }

    componentWillUnmount() {
        document.removeEventListener('open-contact-form', this.openModal)
    }

    openModal() {
        let name = (this.props.name && this.props.surname)
            ? this.props.name + ' ' + this.props.surname
            : ''

        name = (this.props.answers['contact.first_name'] && this.props.answers['contact.last_name'])
            ? this.props.answers['contact.first_name'] + ' ' + this.props.answers['contact.last_name']
            : name

        let email = this.props.user_name || ''

        email = (this.props.answers['contact.email'])
            ? this.props.answers['contact.email']
            : email

        this.setState({
            is_open: true,
            name: name,
            email: email
        })
    }

    closeModal() {
        this.setState({is_open: false})
    }

    changeName(value) {
        this.setState({name: value})
    }

    changeEmail(value) {
        this.setState({email: value})
    }

    changeText(value) {
        this.setState({text: value})
    }

    validate(e) {
        e.preventDefault()

        let error = {}

        if (this.state.name.length < 3)
            error.name = this.props.t('form.error.field_req')

        if (!/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(this.state.email))
            error.email = this.props.t('form.error.invalid_email_req')

        if (this.state.text.length < 3)
            error.text = this.props.t('form.error.field_req')

        this.setState({error: error})

        if (Object.keys(error).length === 0) this.sendEmail()

    }

    async sendEmail() {
        axios.post('/api/send-email', {
            to: 'cl_service@acrisure.com',
            reply_to: this.state.email,
            template: 'contact-email',
            data: {
                username: this.state.name,
                email: this.state.email,
                text: this.state.text,
            }
        }).then(response => {
            if (response.data) {
                this.setState({text: ''}, () => {
                    this.closeModal()
                })
            }
        }).catch(e => {
            this.setState({
                error: {
                    common: this.props.t('common.something_went_wrong')
                }
            })
        })

    }

    render() {
        const {classes, t} = this.props;

        return (
            <Dialog open={this.state.is_open}
                    fullWidth
                    container={() => document.getElementById('themeAppComponent')}
                    maxWidth="xs"
                    onClose={(e) => {
                        this.closeModal(e)
                    }}
                    TransitionComponent={Transition}>
                <Container>
                    <Box pt={1} pb={2}>
                        <form autoComplete="off" onSubmit={(e) => this.validate(e)}>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={12}>
                                    <Button variant="text" color="primaryDark"
                                            style={{marginLeft: '-16px', minWidth: '32px'}}
                                            onClick={(e) => {
                                                this.closeModal(e)
                                            }}><Close/> {t('common.btn_cancel')}</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.header}>{t('modal.contact_us.title')}</Typography>
                                    <Typography className={classes.header_text}>{t('modal.contact_us.txt')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Question fullWidth
                                              type="text"
                                              question={t('modal.contact_us.full_name')}
                                              answer={this.state.name}
                                              error={!!this.state.error?.name}
                                              helperText={this.state.error?.name}
                                              onChange={(id, value) => {
                                                  this.changeName(value)
                                              }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Question fullWidth
                                              type="email"
                                              question={t('form.label.email')}
                                              answer={this.state.email}
                                              error={!!this.state.error?.email}
                                              helperText={this.state.error?.email}
                                              onChange={(id, value) => {
                                                  this.changeEmail(value)
                                              }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Question fullWidth
                                              type="text"
                                              field_length={1000}
                                              question={t('modal.contact_us.how_can_we_help')}
                                              answer={this.state.text}
                                              error={!!this.state.error?.text}
                                              helperText={this.state.error?.text}
                                              onChange={(id, value) => {
                                                  this.changeText(value)
                                              }}
                                    />
                                </Grid>
                                {this.state.error?.common && (
                                    <Grid item xs={12}>
                                        <Typography color="error">{this.state.error.common}</Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Box textAlign="right">
                                        <Button type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={(e) => {
                                                    this.validate(e)
                                                }}>{t('common.btn_send')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Container>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
    user_name: state.portal.username,
    name: state.portal.name,
    surname: state.portal.surname,
})

export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(ContactUs)))
