import React from "react";

class AnimatedText extends React.Component {

    state = {
        delay: 4000,
        typing_speed: 100,
        deleting_speed: 100,
        strings: ['Example text...'],
        current_index: 0,
        current_text: '',
    }

    constructor(props) {
        super(props);

        if (this.props.strings && this.props.strings instanceof Array) this.state.strings = this.props.strings
        if (this.props.strings && this.props.strings instanceof String) this.state.strings = [this.props.strings]

        this.typing_text = React.createRef()
    }

    componentDidMount() {
        this.typeString(this.state.strings[this.state.current_index])
    }

    async typeString(string) {
        let speed = (this.props.show_in)
            ? (parseInt(this.props.show_in) / string.length).toFixed()
            : this.state.typing_speed

        this.setState({
            current_text: string.substr(0, this.state.current_text.length + 1)
        }, () => {
            this.typing_text.current.textContent = this.state.current_text
            if (this.state.current_text === string) {
                if(this.props.onStringPrinted) this.props.onStringPrinted(string)
                setTimeout(() => {
                    if(this.props.onBeforeDelete) this.props.onBeforeDelete(string)
                    this.backSpaceString(string)
                }, this.state.delay)
            } else {
                setTimeout(() => {
                    this.typeString(string, speed)
                }, speed)
            }
        })
    }

    async backSpaceString(string) {

        if(this.state.current_index === this.state.strings.length - 1 && !this.props.loop) {
            if(this.props.onCycleEnd) this.props.onCycleEnd()

            return
        }

        let speed = (this.props.show_out)
            ? (parseInt(this.props.show_out) / string.length).toFixed()
            : this.state.deleting_speed

        this.setState({
            current_text: string.substr(0, this.state.current_text.length - 1)
        }, () => {
            this.typing_text.current.textContent = this.state.current_text
            if (this.state.current_text.length > 0) {
                setTimeout(() => {
                    this.backSpaceString(string)
                }, speed)
            } else {
                let next_index = this.state.current_index + 1
                next_index = (next_index > this.state.strings.length - 1) ? 0 : next_index
                if(this.props.onStringDeleted) this.props.onStringDeleted(string)

                this.setState({
                    current_index: next_index
                }, () => {
                    if(next_index > 0) {
                        this.typeString(this.state.strings[this.state.current_index])
                    }
                    else if (next_index === 0 && this.props.loop) this.typeString(this.state.strings[this.state.current_index])
                })
            }
        })
    }

    render() {
        return (
            <span ref={this.typing_text}/>
        )
    }
}

export default AnimatedText