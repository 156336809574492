import React from "react";
import {FormControl, Grid, Typography} from "@material-ui/core";

export default class CardsSelect extends React.Component {

    onChange(value) {
        if (this.props.onChange) this.props.onChange(this.props.id, value)
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth} error={this.props.error}>
                <Typography variant="caption" gutterBottom>{this.props.question}&nbsp;{this.props.tooltip}</Typography>
                <Grid container spacing={2}>
                    {this.props.options.map((opt, idx) => {
                        let value = (opt.hasOwnProperty('value')) ? opt.value : opt.text
                        return (
                            <Grid item xs={6} key={idx}>
                                <div className={`card_select ${this.props.answer === value ? 'selected' : ''}`}
                                      onClick={() => {
                                          this.onChange(value)
                                      }}>
                                    <p className={'card_ttl'}>{opt.text}</p>
                                    <p className={'card_txt'}>{opt.helper_text}</p>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}