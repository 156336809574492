import React from "react";
import {useTranslation} from 'react-i18next'
import Tooltip from "@material-ui/core/Tooltip";
import {MuiThemeProvider, Zoom} from "@material-ui/core";
import {createTheme} from "@material-ui/core/styles";

const IS_NEW_THEME_ENABLED = process.env?.REACT_APP_IS_NEW_THEME_ENABLED === "TRUE"
    || window.is_new_theme_enabled === 'TRUE'

function CustomTooltip(props) {
    return <Tooltip arrow {...props} />
}

const oldTheme = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "#000000",
                border: "2px solid #827252",
                backgroundColor: "#DDDBD6",
                fontFamily: "'Proxima Nova', serif",
                fontSize: "12px",
                margin: "0 0 2px -1px",
            },
            arrow:{
                color: "#827252",
            }
        }
    }
});

const newTheme = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "#FFFFFF",
                border: 0,
                backgroundColor: "#666E6A",
                fontFamily: "'TT Norms Pro', serif",
                fontSize: "9px",
                fontWeight:400,
                margin: "0 0 2px -1px",
            },
            arrow:{
                color: "#666E6A",
            }
        }
    }
});

export const AutofilledBadge = () => {

    const theme = IS_NEW_THEME_ENABLED? newTheme : oldTheme
    const {t} = useTranslation()
    const title = t('common.autofilled_title')

    return (<MuiThemeProvider theme={theme}>
        <CustomTooltip title={title} TransitionComponent={Zoom} placement='top-end' arrow disableFocusListener>
            <span className={'autofilled_badge_tooltip'}>{t('common.autofilled')}</span>
        </CustomTooltip>
    </MuiThemeProvider>)
}