const GetAdditionalInsuredList = (policy) => {
    let carrierPolicyResponse = policy?.quote?.carrier_metadata?.policy_response ? JSON.parse(policy?.quote?.carrier_metadata?.policy_response) : null
    let carrierPolicyAddInsuredList = carrierPolicyResponse?.endorsements?.additional_insureds ? carrierPolicyResponse.endorsements.additional_insureds : []
    let filteredAddInsured = []
    let answersAddInsureds = Object.fromEntries(Object.entries(policy.answers_snapshot).filter(([key]) => key.startsWith('business.additional_insured.') && !key.startsWith('business.additional_insured.*.')))
    let indexes = Object.keys(answersAddInsureds).length / 8

    let answersAddInsuredList = []
    for (let i = 0; i < indexes; i++) {
        let obj = {}
        let entries = Object.entries(answersAddInsureds).filter(([key]) => key.includes(`.${i}.`))
        entries.forEach(item => {
            let key = item[0].split('.')
            obj[key[key.length - 1]] = item[1]
        })

        // @ts-ignore
        answersAddInsuredList.push(obj)
    }

    carrierPolicyAddInsuredList.forEach((item) => {
        // @ts-ignore
        let itemAddIns = answersAddInsuredList.find(itm => `${itm.first_name} ${itm.last_name}` === item.name)
        // @ts-ignore
        filteredAddInsured.push(itemAddIns)
    })

    return filteredAddInsured.filter(item => item !== undefined)
}


export {GetAdditionalInsuredList as getAdditionalInsuredList}
