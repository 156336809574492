import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import CancelMyPolicyModal from '../modals/CancelMyPolicy'
import AddCertificateHolderModal from '../modals/AddCertificateHolder'
import Notify from '../../../common/modal/Notify'
import RequestPolicyDocumentsModal from '../modals/RequestPolicyDocuments'
import '../../../scss/v2/portal/_sidebar_helpfull_links.scss'
const HelpfulLinks = (props) => {
  const [showHelpfulModal, setShowHelpfulModal] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [additional, setAdditional] = useState(null)
  const [showNotify, setShowNotify] = useState(null)
  const [notifyContent, setNotifyContent] = useState({})

  const links = [
    {
      text: 'Add Certificate Holder',
      type: 'add_certificate_holder',
      additional: 'holder'
    },
    {
      text: 'Add Insured',
      type: 'add_certificate_holder',
      additional: 'insured'
    },
    // {
    //   text: 'Request Policy Documents',
    //   type: 'request_policy_documents'
    // },
    {
      text: 'Cancel My Policy',
      type: 'cancel_my_policy'
    }
  ]

  const onHandleSuccess = (payload) => {
    setNotifyContent(payload.notifyContent)
    setShowNotify(payload.success)
  }

  const onOpenHelpfulModal = (type, additional) => {
    let additionalUser = additional ? additional : null
    setAdditional(additionalUser)
    setModalType(type)
    setShowHelpfulModal(true)
  }
  return (
    <div className={'blk_sidebar helpful_links_sidebar portal_card'}>
      <p className="sidebar_ttl">Helpful Links</p>
      <ul className={'helpful_links_list'}>
        {links.map((link, i) => {
            return (<li className={'list_item'} key={i} onClick={() => onOpenHelpfulModal(link.type, link.additional)}>
              <span className={'item_ttl'}>{link.text}</span>
            </li>)
          }
        )}
      </ul>
      {modalType === 'cancel_my_policy' &&
        <CancelMyPolicyModal policies={props.policiesList}
                             onClose={() => setShowHelpfulModal(!showHelpfulModal)}
                             onHandleSuccess={onHandleSuccess}
                             open={showHelpfulModal}/>}
      {/*{modalType === 'request_policy_documents' &&*/}
      {/*  <RequestPolicyDocumentsModal policies={props.policiesList}*/}
      {/*                               onClose={() => setShowHelpfulModal(!showHelpfulModal)}*/}
      {/*                               open={showHelpfulModal}/>}*/}
      {modalType === 'add_certificate_holder' &&
        <AddCertificateHolderModal policies={props.policiesList}
                                   additional={additional}
                                   customerEmail={props.username}
                                   onHandleSuccess={onHandleSuccess}
                                   onClose={() => setShowHelpfulModal(!showHelpfulModal)}
                                   open={showHelpfulModal}/>}

      <Notify notifyContent={notifyContent}
              open={showNotify}
              onClose={() => setShowNotify(!showNotify)}/>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    username: state.portal.username,
    policiesList: state.policies.policiesList
  }
}
export default connect(mapStateToProps, null)(withTranslation()(HelpfulLinks))
