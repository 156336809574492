import React from 'react'
import { getLimitValue } from '../../../helpers/display'
import { withTranslation } from 'react-i18next'

const CYBERPolicyLimits = (props) => {
  const { quote: { limits } } = props

  return (<div className={'limit_info'}>
    {Object.keys(limits || {}).map((itm, index) => {
      return (
        <div className="portal_limit_card" key={index}>
          <span className="limit_name">{limits[itm].display_name}</span>
          <span className="limit_value">{getLimitValue(limits[itm])}</span>
        </div>
      )
    })}
  </div>)
}

export default withTranslation()(CYBERPolicyLimits)