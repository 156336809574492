import {createTheme} from '@material-ui/core/styles';
const marigold = '#FEA60B'
//
const green = '#6FCF97'

export const acrisureTheme = createTheme({
    palette: {
        warning: {
            main: marigold
        },
        success: {
            main: green
        }
    },
    typography: {
      fontFamily: [],
        h1: {
            fontSize: '1.5em',
            fontWeight: 900,
            lineHeight: '1.8em',
            marginBottom: '1em'
        },
        body1: {
            fontSize: '1em',
            fontWeight: 400,
        },
        subtitle2: {
            fontSize: '1em',
            fontWeight: 900,
        },
        body2: {
            fontSize: '.88em',
            fontWeight: 400,
        },
        caption: {
            fontSize: '0.9rem',
        }
    },
    props: {},
    overrides: {
        MuiPaper: {
            root: {
                borderRadius: '4px'
            },
            rounded: {
                borderRadius: '10px'
            }
        },
        MuiLinearProgress: {
            root: {
                height: '8px',
                borderRadius: '8px',
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
            }
        }
    },
});