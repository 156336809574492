import React from "react";
import NumberFormat from "react-number-format";
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, TextField} from "@material-ui/core";

function FEINNumberFormat(props) {
    return (
        <NumberFormat
            className={props.className}
            getInputRef={props.inputRef}
            thousandSeparator={false}
            allowEmptyFormatting
            format="##-#######"
            mask="_"
            placeholder="##-#######"
            value={props.value}
            onValueChange={(values) => {
                props.onChange(values)
            }}
        />
    );
}

export default class Fein extends React.Component {

    state = {
        value: ''
    }

    constructor(props) {
        super(props);

        this.state.value = this.props.answer
    }

    onChange(values) {
        this.setState({value: values.value})

        if (this.props.onChange) this.props.onChange(this.props.id, values.value)
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth}
                         className={'form_control'}
                         error={this.props.error}>
                {this.props.question && (
                    <div className="label_blk">
                        <div className="label">{this.props.question} &nbsp;{this.props.tooltip} </div>
                        {this.props.autofilled && <AutofilledBadge/>}
                    </div>
                )}
                <TextField
                    variant="outlined"
                    type="text"
                    className={'fieldText'}
                    inputRef={this.props.inputRef}
                    value={this.props.answer}
                    error={this.props.error}
                    InputProps={{
                        inputComponent: FEINNumberFormat,
                    }}
                    onChange={(values) => {
                        this.onChange(values)
                    }}
                />
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}