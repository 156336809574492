import { cloneDeep, flattenDeep, includes } from 'lodash'
import React, {Component} from "react";
import {connect} from "react-redux";
import Question from "../../Question";
import {withTranslation} from "react-i18next";
import {Button, Grid} from "@material-ui/core";
import { updateAdditionalInsureds, updateInsStoreKey } from '../../../../store/insurance/actions'
//scss
import '../../../../scss/q_custom.scss'

class BusinessAdditionalInsured extends Component {
  state = {
    questions: []
  }

  constructor(props) {
    super(props);

    let questions = this.props.subsections_questions.filter((i) => {
      let parent_key = this.props.qid.replace('.is_selected', '').replace('_is_selected', '').replace('_list', '')
      return i.qid !== this.props.qid && i.qid.startsWith(parent_key) && i.qid !== 'business.additional_insured_is_selected'
    })

    this.state.questions = cloneDeep(questions)
      .map(q => {
        q.hidden = false
        return q
      })
  }

  componentDidMount() {
    if (this.props.answers['business.additional_insured_is_selected'] === 'Yes' && this.props.business_additional_insured.length === 0) {
      this.addAdditionalLine()
    }
  }

  componentWillUnmount(){
    let indexesToDelete = this.getIndexesToRemoveFields()
    let addInsuredListData = [...this.props.business_additional_insured]

    for (let i = indexesToDelete.length -1; i >= 0; i--){
      addInsuredListData.splice(indexesToDelete[i],1);
    }

    this.props.updateAdditionalInsureds(addInsuredListData)
  }

  getKeysToDelete = (indexList)=>{
    let keysToDelete = []
    indexList.forEach(item=>{
      let keys = Object.keys(this.props.answers).filter((answerKey) => answerKey.startsWith(`business.additional_insured.${item}`))
      keysToDelete.push(keys)
    })
    return flattenDeep(keysToDelete)
  }
  getIndexesToRemoveFields = ()=>{
    let answersAddInsureds = Object.fromEntries(Object.entries(this.props.answers).filter(([key]) => key.startsWith('business.additional_insured.') && !key.startsWith('business.additional_insured.*.')))
    let removeIndexes = []
    Object.keys(answersAddInsureds).forEach(function(key) {
      if(answersAddInsureds[key] === ''){
        removeIndexes.push(Number(key.split('.')[2]))
      }
    });
    return [...new Set(removeIndexes)]
  }

  hasError() {
    let error = false
    Object.keys(this.props.error).forEach(line => {
      if (line.startsWith(this.props.qid)) error = true
    })
    return error
  }

  getCellSize(qid) {
    let key = qid.split('.')
    key = key[key.length - 1]

    return includes(['city', 'state', 'first_name', 'last_name'], key) ? 6 : 12
  }

  addAdditionalLine() {
    let updated = [...this.props.business_additional_insured]
    updated.push({
      first_name: '',
      last_name: '',
      email: '',
      street_address: '',
      city: '',
      state: '',
      zip_code: '',
      type: ''
    })

    this.props.updateAdditionalInsureds(updated)
  }

  removeAddition(idx) {
    let updated = [...this.props.business_additional_insured]
    updated.splice(idx, 1)

    this.props.updateAdditionalInsureds(updated)
  }

  getKey(qid, idx) {
    return qid.replace('*', idx)
  }

  updateAnswer(qid, index, value) {
    let key = qid.split('.')
    key = key[key.length - 1]

    let updated = [...this.props.business_additional_insured]
    updated[index][key] = value
    this.props.updateAdditionalInsureds(updated)
  }

  updateAdditionalInsuredList(index, data) {
    let updated = [...this.props.business_additional_insured]

    Object.keys(data).forEach(item => {
      if (updated[index].hasOwnProperty(item)) updated[index][item] = data[item]
    })

    this.props.updateAdditionalInsureds(updated)
  }

  render() {
    const {t} = this.props
    return (
      <Grid container className={'additional_insured_question'}>
        <Grid item><p className={'additional_insured_title'}>Enter Additional Insured Info</p></Grid>
        {this.props.business_additional_insured.map((f, index) => {
          return (
            <Grid container className={'additional-item additional_insured_item'} key={index}>
              {this.props.business_additional_insured.length > 1 && (
                <Button variant="text"
                        color="ripple"
                        size="small"
                        className="remove-additional btn__remove_additional_insured"
                        onClick={() => this.removeAddition(index)}
                >{t('common.btn_remove')}</Button>

              )}
              <Grid item xs={12} md={10}>
                <Grid container spacing={2}>
                  {this.state.questions.map((q) => (
                    <Grid item xs={this.getCellSize(q.qid)} key={q.qid}>
                      <Question
                        fullWidth
                        {...q}
                        options={q.options}
                        required={q.required}
                        type={q.question_type}
                        question={q.question_text}
                        field_length={q.max_length}
                        answer={this.props.answers[this.getKey(q.qid, index)]}
                        error={!!this.props.error?.[this.getKey(q.qid, index)]}
                        helperText={this.props.error?.[this.getKey(q.qid, index)]}
                        onChange={(id, value) => this.updateAnswer(q.qid, index, value)}
                        onChangeLocation={data => this.updateAdditionalInsuredList(index, data)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )
        })}
        {this.props.business_additional_insured.length > 0 && (
          <Grid item xs={12}>
            <button type={'button'} className={'common_btn md_btn outlined btn__add_additional_insured'}
                    onClick={() => {
                      this.addAdditionalLine()
                    }}>{t('common.btn_add_more')}</button>
          </Grid>
        )}
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  data_map: state.insurance.data_map,
  answers: state.insurance.answers,
  business_additional_insured: state.insurance.business_additional_insured,
  subsections_questions: state.insurance.subsections_questions
})

const mapDispatchToProps = {
  updateAdditionalInsureds,
  updateInsStoreKey
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BusinessAdditionalInsured))
