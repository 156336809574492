import React from "react";
import axios from "axios";
import base64 from 'base-64'
import moment from "moment";
import { datadogRum } from '@datadog/browser-rum'
import {formatCurrency} from "../../../helpers/currency";
import { getCarrierDetailInfo } from '../../../helpers/GetCarrierDetailInfo'

class CommonPolicy extends React.Component {

    state = {
        showDetails: false,
        paymentsSchedule: false,
        loadingCertificate: false,
        loadingDoc: false,
        optional_coverages: {},
        providerName: '',
        providerPortal: '',

        logoUrl: 'https://b2z-public-images.s3.amazonaws.com/acrisure/carrier_logos/'
    }

    constructor(props) {
        super(props);
        this.policyRef = React.createRef();

        let optional_coverages = {};

        (this.props.quote?.optional_coverages || []).forEach(itm => {
            optional_coverages[itm.coverage_name] = itm
        })

        this.state.optional_coverages = optional_coverages

        let provider = getCarrierDetailInfo(this.props['carrier'], this.props['answers_snapshot']['business.state'])
        this.state.providerName = provider.name
        this.state.providerPortal = provider.portal_insurer
        this.state.logoUrl = `${this.state.logoUrl}${provider.logo}.png`
    }
    componentDidMount() {
        if (this.props.selectedPolicyRef && this.props.selectedPolicyRef === this.props.quote_id) {
            window.scrollTo({top: 0});
            setTimeout(() => {
                this.policyRef.current.scrollIntoView({behavior: "smooth",})
            }, 1000)
        }
    }

    getPolicyStatus = (policyStatus)=> {
      switch (policyStatus) {
        case 'UNDERWRITER_REVIEW':
          return 'Underwriter Review'
        case 'CANCELED':
          return 'Canceled'
        case 'NON_RENEWAL':
          return 'Non-Renewal'
        default:
          return 'Active'
      }
    }

    isDocsGenerated = ()=>{
      let today = moment()
      let diff = moment(today).diff(this.props.creation_date, 'days')
      return !diff >= 2
    }

    getAddress() {
        let address = []
        address.push(this.props.answers_snapshot["business.street_address"])
        if (this.props.answers_snapshot["business.street_address2"]) address.push(this.props.answers_snapshot["business.street_address2"])
        address.push(this.props.answers_snapshot["business.city"])
        address.push(this.props.answers_snapshot["business.state"])
        address.push(this.props.answers_snapshot["business.zip_code"])

        return address.join(', ')
    }

    sendFile(data, filename) {
        try {
            const arrayBuffer = new ArrayBuffer(data.length);
            const int8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < data.length; i++) {
                int8Array[i] = data.charCodeAt(i);
            }
            const file = new Blob([int8Array], {type: 'application/pdf'});

            if (window.navigator.msSaveOrOpenBlob) // IE10+
                window.navigator.msSaveOrOpenBlob(file, filename);
            else { // Others
              const fileURL = window.URL.createObjectURL(file);
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = filename;
              alink.click();
            }
        } catch (e) {
          throw new Error(`Sending a document to a user while downloading. ${e}`)
        }
    }

    getDocuments() {
        this.setState({loadingDoc: true}, () => {

            axios.post("/api/policy/get-documents", {
                policy_id: this.props.id
            })
                .then((data) => {
                    const decodedData = base64.decode(data.data.content)
                    if (data.data.filename) this.sendFile(decodedData, data.data.filename)
                })
                .catch(e => {
                  throw new Error(`Can't download policy documents. ${e}`)
                })
                .finally(() => {
                    this.setState({loadingDoc: false})
                })
        })
    }

    getLimitValue(key, fallback) {
        let limit = this.props.quote.limits?.[key]?.value

        return limit ? limit.toString() : fallback.toString()
    }

    getBillingPeriod() {
        return (this.props?.installments && this.props.installments.length > 1)
            ? this.props.t('common.billed_monthly')
            : this.props.t('common.billed_annually')
    }

    getBillingValue() {
        return (this.props?.installments && this.props.installments.length > 1)
            ? formatCurrency((this.props.term_premium / 12), true)
            : formatCurrency(this.props.term_premium, true)
    }
    toggleDetails() {
        this.setState({showDetails: !this.state.showDetails})
    }

    checkFailedInstallment(installment) {
        let due_date = moment(installment.due_date)
        let today = moment(new Date())

        return today.diff(due_date, 'days') > 1 && installment.paid_date === null
    }

    checkFailPolicy() {
        try {
            return !!this.props.installments.some(item => this.checkFailedInstallment(item))
        } catch (e) {
          datadogRum.addError(`Something went wrong while checking failed installments. ${e}`)
          return true
        }
    }

    render() {
        return null
    }
}

export default CommonPolicy