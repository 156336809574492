import { getCarrierDetailInfo } from '../helpers/GetCarrierDetailInfo'

const getLimitValue = (quote, key, fallback) => {
    let limit = quote.limits?.[key]?.value
    return limit ? limit.toString() : fallback.toString()
}

const GetCertificatePayload = (policy: any) => {
    const {quote, answers_snapshot} = policy
    const floor = (!!answers_snapshot['business.street_address2']) ? `, ${answers_snapshot['business.street_address2']}` : ''
    const carrier = getCarrierDetailInfo(policy.carrier, answers_snapshot['business.state'])
    const commonPayload = {
        'provider': carrier?.name?.toUpperCase(),
        'business.name': answers_snapshot['business.name'],
        'b2z_class_code_description': answers_snapshot['b2z_class_code_description'] || answers_snapshot['business.description'],
        'effective_date': policy.effective_date,
        'expiration_date': policy.expiration_date,
        'policy_number': quote.policyNumber || quote.policy_number
    }
    const businessAddress = {
        'business.street_address': answers_snapshot['business.street_address'],
        'business.street_address2': answers_snapshot['business.street_address2'],
        'business.state': answers_snapshot['business.state'],
        'business.city': answers_snapshot['business.city'],
        'business.zip_code': answers_snapshot['business.zip_code']
    }

    switch (policy.type) {
    case 'BOP':
        return {
            docType: 'acord25', payload: {
                ...commonPayload, ...businessAddress,
                'occurrence_limit': policy.occurrence_limit.toString(),
                'aggregate_limit': policy.aggregate_limit.toString(),
                'medical_limit': getLimitValue(quote, 'medical_limit', '0'),
                'damage_to_premises_rented_to_you_limit': getLimitValue(quote, 'damage_to_premises_rented_to_you', '50000'),
                'aoi_list': quote?.additionalOtherInterest || [],
                'optional_coverages': quote?.optional_coverages
            }
        }
    case 'WC':
        return {
            docType: 'wc_acord25', payload: {
                ...commonPayload,
                'business.address_line1': answers_snapshot['business.street_address'] + floor,
                'business.address_line2': answers_snapshot['business.city'] + ', ' + answers_snapshot['business.state'] + ', ' + answers_snapshot['business.zip_code'],
                'holder': {
                    'name': answers_snapshot['business.name'],
                    'address_line1': answers_snapshot['business.street_address'] + floor,
                    'address_line2': answers_snapshot['business.city'] + ', ' + answers_snapshot['business.state'] + ', ' + answers_snapshot['business.zip_code']
                },
                'excluded_members': [],
                'creation_date': policy.creation_date,
                'wc_each_employee_limit': quote.limits.wc_each_employee_limit.value,
                'wc_occurrence_limit': quote.limits.wc_occurrence_limit.value,
                'wc_policy_limit': quote.limits.wc_policy_limit.value,
                'policy_number': quote.policyNumber || quote.policy_number,
                'aoi_list': [],
                'optional_coverages': []
            }
        }
    case 'PL':
        return {
            docType: 'pl_acord25', payload: {
                ...commonPayload, ...businessAddress,
                'occurrence_limit': quote.limits.professional_liability_occurrence_limit.value.toString(),
                'aggregate_limit': (quote.limits.professional_liability_occurrence_limit.value * 2).toString(),
                'b2z_class_code_description': answers_snapshot['b2z_class_code_description'] || answers_snapshot['business.description'],
                'aoi_list': quote?.additionalOtherInterest || [],
                'optional_coverages': quote?.optional_coverages || []
            }
        }
    case 'CYBER':
        return {
            docType: 'cyber_acord25', payload: {
                ...commonPayload, ...businessAddress,
                'retention_limit': quote.limits.cyber_retention_limit.value.toString(),
                'aggregate_limit': quote.limits.cyber_aggregate_limit.value.toString(),
                'aoi_list': quote?.additionalOtherInterest || [],
                'optional_coverages': []
            }
        }
    case 'GL':
        return {
            docType: 'acord25', payload: {
                ...commonPayload, ...businessAddress,
                'occurrence_limit': policy.occurrence_limit.toString(),
                'aggregate_limit': policy.aggregate_limit.toString(),
                'medical_limit': getLimitValue(quote, 'medical_limit', '5000'),
                'damage_to_premises_rented_to_you_limit': getLimitValue(quote, 'gl_damage_to_premises_rented_to_you', '50000'),
                'b2z_class_code_description': answers_snapshot['b2z_class_code_description'] || answers_snapshot['business.description'],
                'aoi_list': quote?.additionalOtherInterest || [],
                'optional_coverages': quote?.optional_coverages
            }
        }
    default:
        return {}
    }
}


export {GetCertificatePayload as getCertificatePayload}
