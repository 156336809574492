import React from "react";
import {withTranslation} from 'react-i18next';
import {getLimitValue} from "../../../helpers/display";

const PLPolicyLimits = (props) => {
    const {t, quote: {limits}} = props
    return (
        <div className={'limit_info'}>
            <div className="portal_limit_card">
                <span className="limit_name">{t('common.occurrence_limit')}</span>
                <span className="limit_value">{getLimitValue(limits?.professional_liability_occurrence_limit)}</span>
            </div>
            <div className="portal_limit_card">
                <span className="limit_name">{t('common.deductible')}</span>
                <span className="limit_value">{getLimitValue(limits?.professional_liability_deductible_amount)}</span>
            </div>
        </div>
    )
}

export default withTranslation()(PLPolicyLimits)