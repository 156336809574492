import {SET_POLICIES, SET_FAILED_INSTALLMENTS, SET_POLICIES_LOADING} from "./actions";

const initialState = {
    failedInstallmentsList:[],
    policiesList:[],
    policiesLoading: true
}

export const policiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POLICIES:
            return {
                ...state,
                policiesList: action.payload,
            }
        case SET_FAILED_INSTALLMENTS:
            return {
                ...state,
              failedInstallmentsList: action.payload,
            }
        case SET_POLICIES_LOADING:
            return {
                ...state,
                policiesLoading: action.payload,
            }
        default:
            return state
    }

}