import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import parse from 'autosuggest-highlight/parse';
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {FormControl, TextField} from "@material-ui/core";
import {QuestionTooltip} from "../../form-components/QuestionTooltip";


const SearchLocation = React.memo((props) => {
    // States & Variables
    const [options, setOptions] = useState([])

    const queryChanged = (event, newInputValue, reason) => {
        props.onChange(newInputValue, reason)
    }

    const onPlaceChange = (event, newValue, reason) => {
        if (newValue) props.onChangeLocation(newValue.description, newValue, reason)
        else if (reason === 'clear') setOptions([])
        else props.onChange('')
    }
    const getTooltip = ()=> <QuestionTooltip text={props.tooltip}/>
    const tooltip = props.tooltip ? getTooltip() : null
    return (
        <Autocomplete
            autoComplete
            disableClearable
            id="google-map-autocomplete"
            freeSolo
            value={props.answer}
            includeInputInList
            filterSelectedOptions
            filterOptions={(x) => x}
            options={options}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            onChange={(event, newValue, reason) => {
                onPlaceChange(event, newValue, reason)
            }}
            onInputChange={(event, newInputValue, reason) => {
                queryChanged(event, newInputValue, reason)
            }}
            renderInput={(params) => {
                return (
                    <FormControl fullWidth={props.fullWidth} className={'form_control autocomplete_control'}>
                        {/*<Typography variant="caption">{this.props.question}</Typography>*/}
                        <div className="label_blk">
                            <div className="label">{props.question} &nbsp;{tooltip}</div>
                        </div>
                        <TextField {...params}
                                   className={'fieldText'}
                                   error={!!props.error}
                                   variant={props.variant || 'outlined'}
                                   fullWidth={props.fullWidth}
                                   inputProps={{
                                       ...params.inputProps,
                                       autocomplete: "location-search"
                                   }}
                        />
                        {!!props.error && <p className="error_txt error_txt_md">{props.error}</p>}
                    </FormControl>
                )
            }}
            renderOption={(option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );
                const country = option.terms[option.terms.length - 1].value

                return (
                    <Grid container alignItems="center">
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span key={index}
                                      style={{fontWeight: part.highlight ? 700 : 400}}>{part.text}</span>
                            ))}
                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text.replace(country, '')}<span
                                style={{color: '#475C6E'}}>{country}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    )
})

export default SearchLocation