import React, {useState} from 'react';
import {connect, useStore} from "react-redux";
import {withTranslation} from 'react-i18next';
import AcrisureLogo from '../../components/icons/new/AcrisureLogo'
import {formatCurrency} from "../../helpers/currency";
import {makeStyles} from '@material-ui/core/styles';
import ContactUs from "../../components/modals/ContactUs";
import {Phone} from "../../components/icons/Phone";
import objReducer from "../../helpers/objReducer";
import {
    AppBar,
    Button,
    Toolbar
} from '@material-ui/core';
//scss
import "../../scss/v2/_header.scss";
import {AcrisureLogoNew} from "../../components/icons/new/AcrisureLogoNew";

const IS_NEW_THEME_ENABLED = process.env?.REACT_APP_IS_NEW_THEME_ENABLED === "TRUE"
    || window.is_new_theme_enabled === 'TRUE'

//TODO [V2 update] rewrite style to scss
const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: "white",
        color: theme.palette.primary.main,
        borderRadius: 0,
        boxShadow: '0px 1px 0px rgba(23, 33, 82, 0.4)'
    },
    link: {
        textDecoration: "none",
        color: "white"
    },
    btn: {
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            marginRight: '2em'
        },
    },
    agent: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        backgroundColor: 'red',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '10px',
        fontSize: '12px',
        color: 'white',
        lineHeight: '18px',
        padding: '2px 10px',
        margin: '0 18px'
    },
    agentNav: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    }
}))

function Header({t, answers, subsections, current_subsection}) {
    const classes = useStyles()
    const config = useStore();
    const [open] = React.useState(false);
    const [total, setTotal] = useState(0)

    let term_premium = 0

    if (answers?.selected_quotes.length > 0) {
        answers.selected_quotes.forEach(q => {
            if (q?.term_premium) term_premium += q?.term_premium
        })
    }

    React.useEffect(() => {

        const getTotal = () => {
            const selected_ocs = []
            let oc_premium_total = 0
            let oc_total = 0

            try {
                const bop = answers.selected_quotes.filter(q => q.line_of_business === 'BOP')[0]
                const oc = bop?.optional_coverages.filter(c => c.coverage_name !== 'tria')
                oc_premium_total = objReducer(oc, 'term_premium')

                if (answers['cyber.coverage.is_selected'] === 'Yes') selected_ocs.push(bop?.optional_coverages.filter(c => c.coverage_name === 'cyber'))
                if (answers['mpl.coverages.is_selected'] === 'Yes') selected_ocs.push(bop?.optional_coverages.filter(c => c.coverage_name === 'mpl'))
                selected_ocs.forEach(el => {
                    oc_total += el[0].term_premium
                })
            } catch (e) {
                return e
            }
            setTotal((term_premium - oc_premium_total + oc_total) / 12)
        }
        getTotal()

    }, [answers, term_premium, open, total, current_subsection])

    const handleScheduleCall = () => {
        return <div className="call_row">
            <Button variant="text" custom-ignore="ignore"
                    className="call_item"
                    href={"tel:" + t('common.contact_number')}>
                <Phone/>
                <span className="btn_txt">Call Us</span>
            </Button>
        </div>
    };




    const getBulkPrice = () => {
        return formatCurrency(total)
    }

    const is_bulk = config.getState().insurance?.is_from_bulk || false

    return (
        <AppBar position="static" className={`${classes.header} main-header`}>
            <Toolbar className={classes.headerInner}>
                <div className="header_logo">
                    <a href="https://www.acrisure.com/small-business-insurance"
                       rel="noopener noreferrer" className={'insurance_header_logo_link'}>
                        {IS_NEW_THEME_ENABLED? <AcrisureLogoNew className={'insurance_header_logo'}/>:<AcrisureLogo className={'insurance_header_logo'}/>}
                    </a>
                </div>
                {is_bulk && current_subsection > 5 && total > 0 && (
                    <div className="quote-price">
                        <div className="heading">Current Quote</div>
                        <div className="price"><strong>{getBulkPrice()}</strong> /mo</div>
                    </div>
                )}
                <>
                  {handleScheduleCall()}
                </>
            </Toolbar>
            <ContactUs/>
        </AppBar>
    )
}

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
        subsections: state.insurance.subsections,
        current_subsection: state.insurance.current_subsection,
    }
}

export default connect(mapStateToProps)(withTranslation()(Header))
