import React from "react";
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Slide,
    Typography
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {withTranslation} from 'react-i18next';
//sccs
import '../../scss/v2/modals/_whats_covered.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

class WhatsCoveredHNOA extends React.Component {

    state = {
        show: false,
        iconUrl: 'https://b2z-public-images.s3.amazonaws.com/icons/'
    }

    openModal(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({show: true})
    }

    closeModal(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({show: false})
    }

    render() {
        const {t} = this.props
        const btn = this.props.children ? React.Children.map(this.props.children, child => {
            const props = {
                onClick: (e) => {
                    this.openModal(e)
                }
            }

            if (React.isValidElement(child)) {
                return React.cloneElement(child, props);
            }
            return child;
        }) : (
            <div className="show-modal-button" onClick={(e) => {
                this.openModal(e)
            }}>{t('modal.cover_hnoa.btn')}
            </div>
        )
        return (
            <>
                {btn}
                <Dialog open={this.state.show}
                        fullWidth
                        maxWidth="sm"
                        className="whats-covered-dialog"
                        onClose={(e) => {
                            e.stopPropagation()
                            this.closeModal(e)
                        }}
                        TransitionComponent={Transition}>
                    <DialogActions style={{justifyContent: 'flex-start'}}>
                        <Button variant="text"
                                color="primaryDark"
                                className="close-button"
                                onClick={(e) => {
                                    this.closeModal(e)
                                }}><Close/> {t('common.btn_cancel')}</Button>
                    </DialogActions>
                    <DialogContent>
                        <Grid container spacing={2} justify="center" onClick={(e) => {
                            e.stopPropagation()
                        }}>
                            <Grid item xs={12}>
                                <Typography className="header">{t('modal.cover_hnoa.title')}</Typography>
                                <Typography className="header-text">{t('modal.cover_hnoa.txt')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="subheader">{t('modal.cover_hnoa.cover.heading')}</Typography>
                                <Card className="card">
                                    <CardContent>
                                        <ul className="card-list">
                                            {t('modal.cover_hnoa.cover.list', {returnObjects: true}).map(({
                                                                                                              icon,
                                                                                                              txt
                                                                                                          }, idx) => (
                                                <li className="item" key={idx}>
                                                    <i className="icon">
                                                        <img src={`${this.state.iconUrl}${icon}`} alt={txt}/></i>
                                                    {txt}
                                                </li>))}
                                        </ul>
                                    </CardContent>
                                </Card>

                                <Typography className="subheader">{t('modal.not_cover_hnoa.question')}</Typography>
                                <Card className="card not_covered_card">
                                    <CardContent>
                                        <Typography
                                            className="card-text">{t('modal.not_cover_hnoa.txt')}</Typography>
                                        <ul className="card-list">
                                            {t('modal.not_cover_hnoa.list',
                                                {returnObjects: true}).map(({icon, txt, tip}, idx) => (
                                                <li className="item" key={idx}>
                                                    <i className="icon">
                                                        <img src={`${this.state.iconUrl}${icon}`} alt={txt}/></i>
                                                    <span className="content">
                                                        <span className="txt">{txt}</span>
                                                        <span className="tip">{tip}</span>
                                                    </span>
                                                </li>))}
                                        </ul>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions/>
                </Dialog>
            </>
        )
    }
}

export default withTranslation()(WhatsCoveredHNOA)
