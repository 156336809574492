import React from "react"
import clsx from "clsx";
import base64 from "base-64";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import {currency} from "../../../helpers/currency";
import {getLimitValue} from "../../../helpers/display"
import {CircularProgress} from "@material-ui/core";
import GreenCheckmark from "../../icons/GreenCheckmark";
import GetPremiumTotal from "../../../hooks/get-premium-total";
import DoRequest from "../../../hooks/do-request";
import CardCommon from './CardCommon';


class CardPL extends CardCommon {

    async downloadQuote(e) {
        e.preventDefault()
        e.stopPropagation()

        let p = this.props
        let q = p.quote

        this.setState({loadingQuote: true}, async () => {
            const street2 = p.answers['business.street_address2'] ? p.answers['business.street_address2'] + ', ' : ''
            let address = p.answers['business.street_address'] + ', ' +
                street2 +
                p.answers['business.city'] + ', ' +
                p.answers['business.state'] + ' ' +
                p.answers['business.zip_code']

            let payload = {
                "username": p.answers['contact.first_name'],
                "business": p.answers['business.name'],
                "business_address": address,
                "document_date": q.creation_date,
                "effective_date": q.effective_date,
                "expiration_date": q.expiration_date,
                "provider": q.source.toUpperCase() === "CF" ? "Crum & Forster (C&F)" : this.state.providerName,
                "provider_insurer": q.source.toUpperCase() === "CF" ? p.t('common.insurer_cf') : this.state.providerInsurer,
                "provider_underwritten_by": q.source.toUpperCase() === "CF" ? p.t('common.provider_underwritten_by_cf') : this.state.providerUnderwrittenBy,
                "quote": {
                    "number": q.quote_number,
                    "occurrence_limit": q.limits.professional_liability_occurrence_limit.value,
                    "aggregate_limit": q.limits.professional_liability_occurrence_limit.value * 2, // TODO: Fix this to come from core
                    "deductible_amount": q.limits.professional_liability_deductible_amount.value,
                    "term_premium": this.props.pl_premium_total,
                    "per_month": currency(this.props.pl_premium_total)
                }
            }

            await DoRequest({
                url: '/api/quote/save-quote',
                method: 'post',
                body: {
                    strict: true,
                    template: 'pl-quote-letter',
                    payload: payload
                },
                onSuccess: (data) => {
                    const decodedData = base64.decode(data.data)
                    this.sendFile(decodedData, 'PL-QUOTE-LETTER')
                    this.setState({downloadError: false})
                },
                onError: (e) => {
                    this.setState({downloadError: true})
                    throw new Error(`Can't save user's PL quote. ${e}`)
                }
            }).finally(() => {
                this.setState({loadingQuote: false})
                this.props.downloadErrCallback({
                    type: 'PL',
                    isError: this.state.downloadError
                })
            })
        })
    }

    render() {
        const {t} = this.props

        let classes = clsx({
            'quote-card': true,
            'selected': !!this.props.selected
        })

        let download = clsx({
            "controls": true,
            "loading": this.state.loadingQuote
        })

        return (
            <div className={classes} onClick={() => this.selectPolicy()}>
                <div className="name">{t('common.pl_policy')}</div>
                <div className="price">
                    <GetPremiumTotal props={this.props} lob='pl' /><small
                    style={{fontSize: '1rem', fontWeight: 600}}>/{t('common.month_short')}</small>
                </div>
              {this.props.quote.source && this.props.quote.source === 'COTERIE' && <div className="additional_info">
                <span className={'fee_txt'}>Transactions fee will apply</span>
              </div>}
                <div className="provider">
                    <div className="insurer">{this.state.providerName}</div>
                    <div className="rate">{this.state.providerRating}</div>
                  {this.state.carrierLogoUrl &&
                    <div className={'carrier_logo'}><img src={this.state.carrierLogoUrl} alt=""/></div>}
                </div>
                <div className="is_selected" title={t('common.is_selected')}>
                    <GreenCheckmark/>
                </div>
                <div className="limits">
                    <div className="limit">
                        <div className="name">{this.props.quote.limits.professional_liability_occurrence_limit.display_name}</div>
                        <div className="value">{getLimitValue(this.props.quote.limits.professional_liability_occurrence_limit)}</div>
                    </div>
                </div>
                <div className={download}>
                    {this.state.loadingQuote ? (
                      <span className={'downloading_process'}>
                        <CircularProgress size={14}/>
                        <span>{t('common.downloading')}</span>
                      </span>
                    ) : (
                      <button type={'button'}
                              className={'download_quote_btn'}
                              onClick={e => this.downloadQuote(e)}>{t('common.btn_download_quote')}</button>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
        pl_limit_available: state.insurance.pl_limit_available,
        pl_premium_total: state.insurance.pl_premium_total,
        quotes: state.insurance.quotes
    }
}

export default connect(mapStateToProps, null)(withTranslation()(CardPL))
