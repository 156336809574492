import React from "react";
import {connect} from "react-redux";
import Question from "./Question";
import {withTranslation} from "react-i18next";
import {changeAnswers} from "../../store/insurance/actions";
import {Grid} from "@material-ui/core";
import CreditCardFields from './question/custom/CreditCardFields'

const options = {
    style: {
        base: {
            fontSize: '14px',
            fontWeight: 500,
            color: '#172152',
            width: '100%',
            border: '1px solid #ABBAC7 !important',
            margin: 0,
            display: 'block',
            padding: '18.5px 14px',
            minWidth: 0,
            background: 'none',
            boxSizing: 'content-box',
            animationName: 'mui-auto-fill-cancel',
            letterSpacing: 'inherit',
            '-webkit-tap-highlight-color': 'transparent',
            '::placeholder': {
                color: '#aab7c4',
                fontSize: '16px'
            },
        },
    }
}

class PaymentWithCard extends React.Component {

    render() {
        const {t, updateCreditCardFields, errors} = this.props

        var numberOptions = {...options}
        var expOptions = {...options}
        var cvcOptions = {...options}

        numberOptions['placeholder'] = t('common.cc_number_place_holder')
        expOptions['placeholder'] = ''
        cvcOptions['placeholder'] = ''

        return (
            <Grid container spacing={2} style={{marginTop: "0"}}>
                <Grid item xs={12}>
                    <Question fullWidth
                              type="text"
                              question={t('common.cc_holder_name')}
                              answer={this.props.answers["billing.cardholder_name"]}
                              error={!!this.props.errors['billing.cardholder_name']}
                              helperText={this.props.errors?.['billing.cardholder_name']}
                              onChange={(id, value) => this.props.onCreditCardHolderChange(value)}
                              // style={{fontSize: '14px !important'}}
                              placeholder={t('common.cc_holder_name_place_holder')}
                    />
                </Grid>

              <CreditCardFields errors={errors} onChange={value => updateCreditCardFields(value)}/>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
    }
}

const mapDispatchToProps = {
    changeAnswers,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PaymentWithCard))
