import React from "react";
import clsx from "clsx";
import {connect} from "react-redux";
import Question from "../../Question";
import {Plus} from "../../../icons/Plus";
import MultipleForms from "./MultipleForms";
import {withTranslation} from "react-i18next";
import {Chevron} from "../../../icons/Chevron";
import {updateAOILossPayableClauses as updateaoilosspayableclauses,} from "../../../../store/insurance/actions";
import {Box, Button, Divider, Grid, Typography} from "@material-ui/core";
//scss
import '../../../../scss/q_custom.scss'

class LossPayableClauses extends MultipleForms {

    updateLocation(index, data) {
        let updated = [...this.props[this.getDName(this.props.qid)]]

        Object.keys(data).forEach(item => {
            if (updated[index].hasOwnProperty(item)) updated[index][item] = data[item]
        })

        this.props[this.getAName(this.props.qid)](updated)
    }

    render() {
        let switcherCheck = clsx({
            'switch-body': true,
            'checked': this.state.checked,
        })
        let collapse = clsx({
            'additional-question-arrow': true,
            'toggle-additional-item': true,
            'open': !this.state.collapsed
        })
        const {t} = this.props

        return (
            <Grid item className={'additional-question-item'}>
                <Grid container alignItems={'center'} justify={'space-between'}>
                    <Grid item xs={12} md={12} lg={12} className={'additional-question-switcher-item collapse-item'}>
                       <span className={switcherCheck}>
                            <label htmlFor={'switch-' + this.props.qid.replace('.', '_')}>
                                <input type="checkbox" id={'switch-' + this.props.qid.replace('.', '_')}
                                       onChange={(e) => this.onChange(e.target.checked)}
                                       checked={this.state.checked}/>
                                <span className="thumb"/>
                            </label>
                        </span>
                        <Typography
                            className={'additional-question-text'}>{this.props.question}&nbsp;{this.props.tooltip}</Typography>
                        {this.props[this.getDName(this.props.qid)].length > 0 && this.state.checked && (
                            <Button variant="text"
                                    size="small"
                                    className={collapse}
                                    onClick={() => this.collapseQuestion()}>
                                <i><Chevron/></i>
                            </Button>)}
                    </Grid>
                    {this.props.diya_helper && (
                        <Grid item xs={12}>
                            <Typography className="custom-question-diya-helper">{this.props.diya_helper}</Typography>
                        </Grid>
                    )}
                    {this.state.checked && this.state.collapsed && (
                        <Grid item xs={12} className={'additional-section-item'}>
                            {this.props[this.getDName(this.props.qid)].map((f, index) => {
                                return (
                                    <Grid container p={2} className="additional-item" key={index}>
                                        <Grid item xs={12}>
                                            <Grid container justify="flex-end">
                                                <Grid item>
                                                    <Button variant="text"
                                                            color="ripple"
                                                            size="small"
                                                            className="remove-additional"
                                                            onClick={() => this.removeAddition(index)}
                                                    >{t('common.btn_remove')}</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <Grid container spacing={2}>
                                                {this.state.questions.map((q) => (
                                                    <Grid item xs={this.getCellSize(q.qid)} key={q.qid}>
                                                        <Question
                                                            fullWidth
                                                            {...q}
                                                            options={q.options}
                                                            required={q.required}
                                                            type={q.question_type}
                                                            question={q.question_text}
                                                            field_length={q.max_length}
                                                            answer={this.props.answers[this.getKey(q.qid, index)]}
                                                            error={!!this.props.error?.[this.getKey(q.qid, index)]}
                                                            helperText={this.props.error?.[this.getKey(q.qid, index)]}
                                                            onChange={(id, value) => this.updateAnswer(q.qid, index, value)}
                                                            onChangeLocation={data => this.updateLocation(index, data)}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box py={2}><Divider/></Box>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            {this.props[this.getDName(this.props.qid)].length > 0 && (
                                <Grid item xs={12}>
                                    <Button variant="text"
                                            color="primary"
                                            className="add-additional"
                                            onClick={() => {
                                                this.addAdditionalLine()
                                            }}
                                    ><i><Plus/></i> {t('common.btn_add_more')}</Button>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {this.state.checked && !this.state.collapsed && (
                        <Grid item xs={12} className={'additional-question-collapsed-blk'}>
                            <Typography
                                className={'additional-question-collapsed-text'}>{this.getCollapsedLabel()}</Typography>
                            {this.hasError() && <Typography className={'additional-question-error'} align={'center'}>{t('form.error.has_errors')}</Typography>}
                        </Grid>)}
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    data_map: state.insurance.data_map,
    answers: state.insurance.answers,
    loss_payable_clauses: state.insurance.loss_payable_clauses,
    loss_payable_clauses_template: state.insurance.loss_payable_clauses_template,
    subsections_questions: state.insurance.subsections_questions
})

const mapDispatchToProps = {
    updateaoilosspayableclauses,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LossPayableClauses))