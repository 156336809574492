import React from "react"
import {connect} from "react-redux";
import {Alert} from "@material-ui/lab";
import {Close} from "@material-ui/icons";
import {withTranslation} from 'react-i18next';
import {changeAnswers} from "../../store/insurance/actions";
import Question from "../../components/form-components/Question";
import SectionIndicator from "../../components/insurance/SectionIndicator";
import {Box, Button, Grid, Hidden, Snackbar, Typography} from "@material-ui/core";

class GetNotifiedState extends React.Component {

    state = {
        error: false,
        notification_show: false,
        sections: [],
        current_section: 100,
    }

    constructor(props) {
        super(props);

        this.state.current_section = this.props.current_section + 1
        this.state.sections = this.makeStepperOverride()
    }

    componentDidMount() {
        if (!this.props.answers['notify_email'] && this.props.answers['contact.email'])
            this.props.changeAnswers({
                'notify_email': this.props.answers['contact.email']
            })
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    makeStepperOverride() {
        let before = this.props.sections.slice(0, this.props.current_section_index)
        let after = this.props.sections.slice(this.props.current_section_index, this.props.sections.length - 1)

        before = before.map((itm) => {
            return {
                ...itm,
                progress: 100
            }
        })

        let section_idx_counter = this.state.current_section

        after = after.map((itm) => {
            return {
                ...itm,
                section_index: ++section_idx_counter,
                disabled: true
            }
        })

        const insert = {
            name: this.props.t('modal.get_notified.title'),
            section_index: this.state.current_section,
            progress: 99.99
        }

        return before.concat([insert, ...after])
    }

    setEmail(value) {
        this.props.changeAnswers({
            "notify_email": value
        })
    }

    validateEmail() {
        const re = /^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
        if (re.test(String(this.props.answers["notify_email"]).toLowerCase())) {
            this.setState({
                error: false,
                notification_show: true
            })
        } else {
            this.setState({
                error: true,
                notification_show: false
            })
        }
    }

    goHome() {
        window.location.assign(this.props.site)
    }

    closeNotification() {
        this.setState({notification_show: false}, () => {
            this.goHome()
        })
    }

    render() {
        const {t} = this.props

        return (
            <div className="full-width-container" style={{height: '100%'}}>
                <Grid container spacing={6} alignContent="stretch" style={{height: '100%', margin: '0 -24px'}}>
                    <Hidden smDown={true}>
                        <Grid item xs={12} md={4} lg={3} className="sections-indicator-container">
                            <SectionIndicator sections={this.state.sections}
                                              current={this.state.current_section}
                                              current_index={this.props.current_section_index}
                                              total_progress={100}
                            />
                            {/* <div className="section_details_info">
                                <h1>DETAILS INFO</h1>
                            </div>*/}
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={8} lg={9}>
                        <Box py={{xs: 2, md: 4}} px={{xs: 0, md: 4}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button variant="text" color="primaryDark"
                                            onClick={this.props.onClose}>
                                        <Close/> {t('common.btn_close')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h1">{t('modal.get_notified_state.title')}</Typography>
                                    <Typography>{t('modal.get_notified_state.txt', {value: this.props.answers['business.state']})}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom>{t('modal.get_notified_state.txt_2')}</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={12} sm={8} md={6} lg={5}>
                                        <Question fullWidth
                                                  type="email"
                                                  question={t('form.label.email')}
                                                  answer={this.props.answers["notify_email"]}
                                                  error={this.state.error}
                                                  helperText={this.state.error ? t('form.error.invalid_email') : null}
                                                  onChange={(id, value) => {
                                                      this.setEmail(value)
                                                  }}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={2}>
                                        <Button variant="contained"
                                                size="large"
                                                color="primary"
                                                onClick={() => {
                                                    this.validateEmail()
                                                }}
                                        >{t('common.btn_notify_me')}</Button>
                                    </Box>
                                </Grid>
                                {this.state.notification_show && (
                                    <Snackbar open autoHideDuration={2000} onClose={() => {
                                        this.closeNotification()
                                    }}>
                                        <Alert onClose={() => {
                                            this.closeNotification()
                                        }} severity="success">
                                            {t('common.alert_success')}
                                        </Alert>
                                    </Snackbar>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        site: state.app.site,
        answers: state.insurance.answers,
        sections: state.insurance.sections,
        current_section: state.insurance.current_section,
        current_section_index: state.insurance.current_section_index,
    }
}

const mapDispatchToProps = {
    changeAnswers,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GetNotifiedState))
