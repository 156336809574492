import React from "react";
import {withTranslation} from 'react-i18next';
import {formatCurrency} from "../../../helpers/currency";
import {LimitLabel} from "./limits/LimitLabel";
import {DeductibleLabel} from "./limits/DeductibleLabel";
import {PerOccurrenceLimitLabel} from "./limits/PerOccurrenceLimitLabel";

const WCPolicyDetails = (props) => {
    const {t} = props
    return (
        <div className="detail_info">
            <p className="policy_coverages">{t('common.coverages')}</p>
            <div className="detail_info_columns">
                <div className="column">
                    <div className="detail_info_group">
                        <div className="group_ttl">{t('portal.policy.wc.title')}</div>
                        <div className="group_txt">{t('portal.policy.wc.txt')}</div>
                        <div className="group_list">
                            <div className="list_item">
                                <LimitLabel name={t('common.limit')}/>
                                <span className="item_value">{t('common.unlimited')}</span>
                            </div>
                            <div className="list_item">
                                <DeductibleLabel name={t('common.deductible')}/>
                                <span
                                    className="item_value">{t('common.na')}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="detail_info_group">
                        <div className="group_ttl">{t('portal.policy.el.title')}</div>
                        <div className="group_txt">{t('portal.policy.el.txt')}</div>
                        <div className="group_list group_limit_list">
                            <div className="list_item">
                                <PerOccurrenceLimitLabel name={t('common.per_occur')}/>
                                <span className="item_value">
                                    {formatCurrency(props.quote.limits.wc_occurrence_limit?.value)}
                                </span>
                            </div>
                            <div className="list_item">
                                <span className="item_name">{t('common.per_empl')}</span>
                                <span className="item_value">
                                    {formatCurrency(props.quote.limits?.wc_each_employee_limit?.value)}
                                </span>
                            </div>
                            <div className="list_item">
                                <span className="item_name">{t('common.per_policy')}</span>
                                <span className="item_value">
                                    {formatCurrency(props.quote.limits?.wc_policy_limit?.value)}
                                </span>
                            </div>
                        </div>
                        <div className="group_list">
                            <div className="list_item">
                                <DeductibleLabel name={t('common.deductible')}/>
                                <span
                                    className="item_value">{t('common.na')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(WCPolicyDetails)