import React from "react";
import {FormControl} from "@material-ui/core";
import {formatCurrency} from "../../../helpers/currency";
import sources_update from '../../../common/decorator'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'

class Limit extends React.Component {

    state = {
        prev_opt: null,
        next_opt: null,
        options: []
    }

    constructor(props) {
        super(props);

        if (!props.options) throw new Error('Options for limit question are missing!')

        let options = [...this.props.options]

        const answer_str = this.props.answer.toString()
        if (!props.options.find(o => o.value === answer_str)) {
            options.push({
                text: answer_str,
                value: answer_str
            })
        }

        this.state.options = options.sort((a, b) => a.value - b.value)

    }

    componentDidMount() {
        this.updateStateOptions()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.answer !== this.props.answer || prevProps.options.length !== this.props.options.length) {
            this.updateStateOptions()
        }
    }

    onPrev() {
        if (this.props.hasOwnProperty('onChange'))
            this.props.onChange(this.props.id, this.state.prev_opt?.value || this.state.prev_opt?.text)
        if (this.props.hasOwnProperty('onPrev'))
            this.props.onPrev()
        sources_update(this.props.answers, this.props.qid)
    }

    onNext() {
        if (this.props.hasOwnProperty('onChange'))
            this.props.onChange(this.props.id, this.state.next_opt?.value || this.state.next_opt?.text)
        if (this.props.hasOwnProperty('onNext'))
            this.props.onNext()
        sources_update(this.props.answers, this.props.qid)
    }

    updateStateOptions() {
        let current_opt = this.state.options.findIndex(o => o.value === this.props.answer.toString() || o.text === this.props.answer.toString())
        if (current_opt !== -1) {
            this.setState({
                prev_opt: this.state.options?.[current_opt - 1],
                next_opt: this.state.options?.[current_opt + 1],
            })
        }
        this.render()
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth} error={this.props.error} className={'form_control'}>
                <div className="limit-question">
                    {this.state.prev_opt
                        ? (
                            <div className="prev-opt" onClick={this.onPrev.bind(this)}>-</div>
                        ) : (
                            <div className="prev-opt disabled" aria-disabled={true}>-</div>
                        )}
                    <div className="value">{formatCurrency(this.props.answer)}</div>
                    {(this.props?.coverageType === 'cyber.coverage.is_selected' && this.state.next_opt) ||
                        (this.state?.prev_opt?.text !== '1000000' && this.props?.coverageType === 'WC') ||
                        (['BOP', 'PL', 'GL', 'CYBER'].includes(this.props?.coverageType) && this.state.next_opt)
                        ? (
                            <div className="next-opt" onClick={this.onNext.bind(this)}>+</div>
                        )
                        : (
                            <div className="next-opt disabled" aria-disabled={true}>+</div>
                        )
                    }
                </div>
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
})
  
export default connect(mapStateToProps)(withTranslation()(Limit))
  