import React, {useEffect} from 'react'
import {Statsig} from 'statsig-react'

export const StatsigComponent = ({sectionIndex, userEmail,sessionId, businessName})=> {
    // const { config: b2zaatest } = useExperiment('b2zaatest')
    //react-hooks/exhaustive-deps
    useEffect(() => {
        Statsig.updateUser({
            userID: `${process.env.NODE_ENV}--${sessionId}`,
            email: `${process.env.NODE_ENV}--${userEmail}`
        }).then(() => true)
        // @ts-ignore
        Statsig.logEvent('b2z_visited_form_1', `${sessionId}`, {
            test_value: '1',
            b2z_visited_form_1: 'b2z_visited_form_1',
            business_name: `Test - ${businessName}`
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEmail, sectionIndex])
    return (<div></div>)
}