import React, {useState} from "react";
import {throttle} from "lodash/function";
import parse from 'autosuggest-highlight/parse';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {FormControl, TextField} from "@material-ui/core";
import {withTranslation} from "react-i18next";

// hooks
import {getPlaces, getPredictions} from "../../hooks/auto-complete";


const SearchLocationInput = React.memo((props) => {

    // States & Variables
    const [options, setOptions] = useState([])
    const {t} = props

    const fetchPredictions = (request, callback) => {
        return getPredictions(request, callback, 'establishment', props)
    }

    let getPredictionsThrottled = throttle(fetchPredictions, 500)


    const fetchPlaces = (options, status) => {
        setOptions(getPlaces(props, options, status))
    }

    const queryChanged = (event, newInputValue, reason) => {
        props.onChange(newInputValue, reason)

        getPredictionsThrottled(newInputValue, fetchPlaces)
    }

    const onPlaceChange = (event, newValue, reason) => {
        if (newValue) props.onChangeLocation(newValue.description, newValue, reason)
        else if (reason === 'clear') setOptions([])
        else props.onChange('')
    }

    return (
        <Autocomplete
            id="google-map-autocomplete"
            freeSolo
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={props.answer}
            onChange={(event, newValue, reason) => {
                onPlaceChange(event, newValue, reason)
            }}
            PaperComponent={({children}) => (
                <div className={'business_dropdown_results_list'}>{children}</div>
            )}
            onInputChange={(event, newInputValue, reason) => {
                queryChanged(event, newInputValue, reason)
            }}
            renderInput={(params) => {
                return (
                    <FormControl fullWidth={props.fullWidth} className={'form_control autocomplete_control'}>
                        <div className="label_blk">
                            <div className="label">{props.question} </div>
                        </div>
                        <TextField {...params}
                                   className={'fieldText'}
                                   error={!!props.error}
                                   variant={props.variant || 'outlined'}
                                   fullWidth={props.fullWidth}
                                   placeholder={t('form.no_options_text')}
                                   inputProps={{
                                       ...params.inputProps,
                                       autocomplete: "location-search"
                                   }
                                   }
                        />
                        {!!props.error ? (
                            <p className="error_txt error_txt_md">{props.error}</p>
                        ) : null}
                    </FormControl>
                )
            }}
            onBlur={props.onBlur}
            renderOption={(option) => {
                const matches = option.structured_formatting.main_text_matched_substrings || [];
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );
                const country = option.terms[option.terms.length - 1].value

                return (
                    <div className={'business_dropdown_item'}>
                        <p className={'result_list_business_name'}>
                            {parts.map((part, index) => (
                                <span key={index}
                                      style={{fontWeight: part.highlight ? 700 : 400}}>{part.text}</span>
                            ))}
                        </p>
                        <span className={'result_list_business_address'}>
                                {option.structured_formatting.secondary_text.replace(country, '')}<span
                            className={'result_list_business_country'}
                            style={{color: '#475C6E'}}>{country}</span>
                            </span>
                    </div>
                );
            }}


        />
    )
})

export default withTranslation()(SearchLocationInput)