import React from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    useTheme,
    Grid
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import '../../scss/v2/portal/_modals.scss'
import {ModalTransition} from '../ModalTransition'
import {Success} from '../../components/icons/new/Success'

interface NotifyModel {
    description?: any;
    isIcon: boolean
    title: string
    text?: string
    btnTxt: string
}
const Notify = ({
    open,
    onClose,
    notifyContent
}: {
    open: boolean,
    onClose: () => void,
    notifyContent: NotifyModel
}) => {

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            container={() => document.getElementById('themeAppComponent')}
            className={'modal__wrapper helpful_common__wrapper'}
            maxWidth="sm"
            scroll={'paper'}
            fullWidth
            TransitionComponent={ModalTransition}>

            <DialogActions className={'modal_head'}>
                <button className={'modal_close_btn show_scroll_paper'}
                    type={'button'}
                    onClick={onClose}><Close/></button>
            </DialogActions>
            <DialogContent className={'portal_modals_cnt'}>
                <Grid container spacing={2} className={'modal_cnt'}>
                    <Grid item className={'modal_content'}>
                        {notifyContent.isIcon? <Success/>: null}
                        <p className={'modal_ttl'}>{notifyContent.title}</p>
                        {notifyContent?.description
                            ? <div className={'modal_sub_ttl'} dangerouslySetInnerHTML={{__html: notifyContent?.description}}></div>
                            : <p className={'modal_sub_ttl'}>{notifyContent.text}</p> }
                    </Grid>
                    <Grid item xs={12}>
                        <button onClick={onClose}
                            className={'a_btn a_btn_action_1 btn__close'}>{notifyContent.btnTxt}</button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default Notify