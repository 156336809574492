import React  from 'react'
import { Box, Container, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import '../scss/v2/_did_know_loading.scss'

const Main = () => {
  return (
    <Container fixed className={'greeting_section'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p className={'ttl'}>Home page</p>
          <p className={'txt'}>Ready to easily get your insurance policy?</p>
        </Grid>
        <Grid item xs={12}>
          <Box py={4} textAlign="center">
            <Link className={'a_btn a_btn_action_1 btn_start_quoting'} to="/get-insurance/business-name-and-address">Let's
              make you protected!</Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Main


