import React from "react";

const ParameterizedJSX = (string, replacementMarker, replacementValue, className) => {
    const flatMap = (array, fn) => {
        let result = []
        array.forEach(el => {
            var mapping = fn(el)
            result = result.concat(mapping)
        })
        return result
    }
    string = flatMap(string.split(replacementMarker), (part) => {
        return [part, <span className={className}>{replacementValue}</span>]
    })
    string.pop()
    return string
}

export default ParameterizedJSX