import { formatCurrency } from "../helpers/currency";
import { datadogRum } from "@datadog/browser-rum";
import { calculatePremiumTotal } from "../hooks/get-premium-total"

export const GetAnnualPremiumTotal = (props) => {
    try {
        const total = calculatePremiumTotal(props.props, props.lob)
        return formatCurrency(total, true)
    } catch (e) {
        datadogRum.addError(`Something went wrong while calculating annual premium total. ${e}`)
    }
}
