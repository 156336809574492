import React, {useState} from 'react'
import {FormControl} from '@material-ui/core'
import {formatCurrency} from '../../../helpers/currency'
import {DoublyLinkedList, NodeElement} from '../../../helpers/linked-list'
import sources_update from '../../../common/decorator'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'


enum Action {
    increase = 'increase',
    decrease = 'decrease'
}


const LinkedLimit = ({
    options,
    id,
    fullWidth,
    limitTier,
    onChange,
    answer,
    qid,
    answers
}: {
    options: {[key: string]: any}[],
    id: string,
    fullWidth: boolean,
    limitTier: number,
    onChange: (id: string, value: string, tier: number) => void,
    answer: string | number,
    qid: string,
    answers: {[key: string]: any}
}) => {

    let limitOptions = new DoublyLinkedList()

    options.forEach(o => {
        limitOptions.insert(o.value)
    })

    const [tier, setTier] = useState(limitTier)
    const [choice, setChoice] = useState<NodeElement>(limitOptions.getNthNode(limitTier)!)


    const onUpdate = (action: Action) => {
        let value: NodeElement
        
        sources_update(answers, qid)

        switch (action) {
        case Action.decrease:
            value = limitOptions.getNthNode(tier - 1)!
            setTier(tier - 1)
            setChoice(value)
            return onChange(id, value?.value, tier - 1)

        case Action.increase:
            value = limitOptions.getNthNode(tier + 1)!
            setTier(tier + 1)
            setChoice(value)
            return onChange(id, value?.value, tier + 1)
        }
    }

    return (
        <FormControl fullWidth={fullWidth} className='form-control'>
            <div className='limit-question'>
                {
                    choice?.previous
                        ? <div className='prev-opt' onClick={() => onUpdate(Action.decrease)}>-</div>
                        : <div className='prev-opt disabled' aria-disabled={true}>-</div>
                }
                <div className='value'>{formatCurrency(answer)}</div>
                {
                    choice?.next
                        ? <div className='next-opt' onClick={() => onUpdate(Action.increase)}>+</div>
                        : <div className='next-opt disabled' aria-disabled={true}>+</div>
                }
            </div>
        </FormControl>

    )
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
})
  
export default connect(mapStateToProps)(withTranslation()(LinkedLimit))
  