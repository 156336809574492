import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {withTranslation} from "react-i18next";
import ContactUs from "../modals/ContactUs";
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import {Button, ClickAwayListener, Grow, MenuList, Paper, Popper} from "@material-ui/core";
import {User} from "../icons/User";

function UserMenu({t, anchorRef}) {

    const dispatch = useDispatch()
    const history = useHistory()
    const [open, setOpen] = React.useState(false);

    function logOut() {
        setOpen(false)
        dispatch({
            type: 'LOG_OUT'
        })
        dispatch({ type: 'SET_POLICIES', payload:[]})
        dispatch({ type: 'SET_POLICIES_LOADING', payload: true})

        setTimeout(() => {
            history.push('/sign-in')
        }, 0)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    function handleMenuLink(link) {
        setOpen(false)
        history.push(link)
    }

  /*  function handleContactForm() {
        setOpen(false)
        document.dispatchEvent(new Event('open-contact-form'))
    }*/

    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open, anchorRef])

    return (
        <>
            <Button
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            > <User/>{open ?
                <KeyboardArrowUp/> :
                <KeyboardArrowDown/>}
            </Button>
            <Popper open={open}
                    anchorEl={anchorRef.current}
                    placement="bottom-end"
                    role={undefined}
                    transition
                    disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow {...TransitionProps}
                          style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open}
                                          id="menu-list-grow"
                                          className="mobile-menu"
                                          onKeyDown={handleListKeyDown}>
                                    <li className={'menu_item'}><span onClick={() => {
                                        handleMenuLink("/portal/profile")
                                    }}>Account Info</span></li>
                                    <li className={'menu_item'}><span onClick={() => {
                                        handleMenuLink("/portal/payment-center")
                                    }}>Payment Center</span></li>
                                    <li className={'menu_item'}><span onClick={logOut}>{t('common.header_logout')}</span></li>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <ContactUs/>
        </>
    )

}

export default withTranslation()(UserMenu)