import React from 'react'
import Question from '../../form-components/Question'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {
  changeAnswers,
  updateInsStoreKey,
  updateProgress,
  updateSections,
  updateWCUWQuestions
} from '../../../store/insurance/actions'
import { formatCurrency } from '../../../helpers/currency'
import {
  getLimitsOptionsV2
} from '../../../helpers/limitsHelpers'
import { calculatePremiumTotal } from '../../../hooks/get-premium-total'
import { QuestionTooltip } from '../../form-components/QuestionTooltip'
import LoadingButton from "../../LoadingButton";

class CustomizeCYBER extends React.Component {

  state = {
    questions: [],
    questions_keys: [
      'cyber.coverage.aggregate_limit',
      'cyber.coverage.retention_limit'
    ],
    retentionMaxValue:null,
    renderComponent:false
  }

  constructor (props) {
    super(props)
    this.state.questions = this.props.subsections_questions_data.filter(q => this.state.questions_keys.includes(q.qid))
    const limits = this.props.answers.selected_quotes.filter(q => q.quote_type === 'CYBER')[0].limits

    const ocLimits = this.props.answers.selected_quotes.filter(q => q.quote_type === 'CYBER')[0].optional_coverages
    let ocLimitsValues = {}
    ocLimits.forEach((item) => {
      ocLimitsValues[`${item.qid}_aggregate_limit`] = item['aggregate_limit']
      ocLimitsValues[`${item.qid}_retention_limit`] = item['retention_limit']
    })

    this.props.changeAnswers({
      ...ocLimitsValues,
      'cyber.coverage.aggregate_limit': limits?.cyber_aggregate_limit?.value,
      'cyber.coverage.retention_limit': limits?.cyber_retention_limit?.value
    })
    this.state.questions = getLimitsOptionsV2(props, this.state.questions, 'CYBER')
    this.state.renderComponent = true
  }

/*  componentDidMount () {
    const lob_questions = getLimitsOptionsV2(this.props, this.state.questions, 'CYBER')

    this.setState({ questions: lob_questions })

  }*/

  componentDidUpdate (prevProps, prevState) {

    if (this.props.answers['cyber.coverage.aggregate_limit'] !== prevProps.answers['cyber.coverage.aggregate_limit']) {
      let lob_questions = this.props.subsections_questions_data.filter(q => this.state.questions_keys.includes(q.qid))

      lob_questions = getLimitsOptionsV2(this.props, lob_questions, 'CYBER')

      let maxRetentionOptions = lob_questions.find(item => item.qid === 'cyber.coverage.retention_limit').options

      this.setState({
        questions: lob_questions,
        retentionMaxValue: maxRetentionOptions[maxRetentionOptions.length - 1].value,
        renderComponent: false,
      },()=>{
        this.setState({renderComponent: true})
        if(Number(this.state.retentionMaxValue) <= Number(prevState.retentionMaxValue)
          && this.state.retentionMaxValue !== prevState.retentionMaxValue
          && Number(this.state.retentionMaxValue) <= this.props.answers['cyber.coverage.retention_limit']){

          this.props.changeAnswers({
            'cyber.coverage.retention_limit': this.state.retentionMaxValue
          })
        }

      })
    }
  }

  onChange (key, value) {
    this.props.changeAnswers({
      [key]: value
    })
    this.props.onChange(key, value, this.props.quote_idx)
  }

  render () {
    const { t, term_premium } = this.props

    return (
      <div className="customize-quote-card">
        <div className="desc">
          <div className="txt">
            <div className="name">{t('customize_card.cyber.title')}</div>
            <div className="help">{t('customize_card.cyber.txt')}</div>
          </div>
          <div className="policy-price">
            {this.props.isEstimatedLoading
                ? <LoadingButton size={50}/>
                : this.props.isEstimated
                    ? <div className="price">
                      <div className="oc-price-val">
                        {formatCurrency(term_premium, true)}
                      </div>
                      <small className="oc-price-period">&nbsp;/{t('common.month_short')}</small>
                    </div>
                    : <div className="price">
                      <div className="oc-price-val">
                        {formatCurrency(calculatePremiumTotal(this.props, 'CYBER'), true)}
                      </div>
                      <small className="oc-price-period">&nbsp;/{t('common.year_short')}</small>
                    </div>}
      </div>
  </div>

    <div className="limits">
      {this.state.questions.map((q, idx) =>
              q.hidden || q.disabled
                  ? null
                  : (// key property is intentionally written as so to trigger child component re-renders
                    <div className="limit" key={`${q.options?.length}${this.props.answers[q.qid]}${idx}`}>
                      <div className="name">{q.question_text}&nbsp;{q.tooltip && <QuestionTooltip text={q.tooltip}/>}</div>
                      <div className="question">
                        {this.state.renderComponent && <Question {...q}
                                  type={q.question_type}
                                  coverageType="CYBER"
                                  question={q.question_text}
                                  answer={this.props.answers[q.qid]}
                                  onChange={(id, value) => {
                                    this.onChange(q.qid, value)
                                  }}
                        />}
                      </div>
                  </div>)
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    quotes: state.insurance.quotes,
    answers: state.insurance.answers,
    session_answers: state.insurance.session_answers,
    subsections: state.insurance.subsections,
    current_subsection: state.insurance.current_subsection,
    is_session_restored: state.insurance.is_session_restored,
    subsections_questions: state.insurance.subsections_questions,
    subsections_questions_data: state.insurance.subsections_questions_data
  }
}

const mapDispatchToProps = {
  changeAnswers,
  updateProgress,
  updateInsStoreKey,
  updateWCUWQuestions,
  updateSections
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomizeCYBER))