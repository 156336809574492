import React from 'react';
import {getInsuranceCarrier} from '../../../helpers/GetInsuranceCarrier'

class CardCommon extends React.Component {
    state = {
        loadingQuote: false,
        providerName: '',
        providerRating: '',
        providerInsurer: '',
        providerUnderwrittenBy:'',
        downloadError: false,
        carrierLogoUrl:''
    }

    componentDidMount() {
        let provider_data = getInsuranceCarrier(this.props.answers, this.props.quote.quote_type)
        this.setState({
            providerName: provider_data?.name,
            providerRating: provider_data?.rating,
            providerInsurer: provider_data?.insurer,
            providerUnderwrittenBy: provider_data?.underwritten_by,
            carrierLogoUrl: provider_data?.logo ? `https://b2z-public-images.s3.amazonaws.com/acrisure/carrier_logos/${provider_data?.logo}.png`: null,
        })
    }

    selectPolicy() {
        if (this.props.onClick) this.props.onClick()
    }
    sendFile(data, filename) {
      try {
        const arrayBuffer = new ArrayBuffer(data.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < data.length; i++) {
          int8Array[i] = data.charCodeAt(i);
        }
        const file = new Blob([int8Array], {type: 'application/pdf'});

        if (window.navigator.msSaveOrOpenBlob) // IE10+
          window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
          const fileURL = window.URL.createObjectURL(file);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
        }
      } catch (e) {
        throw new Error(`Something went wrong while sending document to user. ${e}`)
        }
    }
    render() {
        return null;
    }
}

export default CardCommon