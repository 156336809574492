import React from 'react';
import clsx from "clsx";
//scss
import '../scss/v2/_switch.scss'

class Switch extends React.Component {

    state = {
        id: null,
        checked: false
    }

    constructor(props) {
        super(props);

        this.state.id = this.props.id || Math.random()
    }

    onChange() {
        if (this.props.onChange) this.props.onChange(!this.props.checked)
    }

    render() {

        let classes = clsx({
            'switch-body': true,
            'checked': this.props.checked
        })

        return (
            <span className={classes}>
            <label htmlFor={'switch-' + this.state.id}>
                <input type="checkbox" id={'switch-' + this.state.id} onChange={() => {
                    this.onChange()
                }}
                       checked={this.props.checked}/>
                <span className="thumb"/>
            </label>
        </span>
        )
    }
}

export default Switch
