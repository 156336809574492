import React, {useState, useRef, useEffect, useContext} from 'react'
import {FormControl, TextField} from '@material-ui/core'
import InputMask from 'react-input-mask'
import {
    expirationDate
    // expirationMonth,
    // expirationYear
} from 'card-validator'
import {ExpirationDateVerification} from 'card-validator/dist/expiration-date'
// import { ExpirationMonthVerification } from 'card-validator/dist/expiration-month'
// import { ExpirationYearVerification } from 'card-validator/dist/expiration-year'
import {CreditCardDataContext} from '../custom/CreditCardFields'
import {InputProps} from './helper.types'
import {absLenght} from './converters'


const CreditCardDateField = ({leaveFieldCallback, focus, tabIndex, fieldError}: InputProps) => {
    const [error, setError] = useState(false)
    const [info, setInfo] = useState('')
    const inputRef = useRef<HTMLInputElement>(null!)

    const CardContext = useContext(CreditCardDataContext)

    const handleChange = (event: any) => {
        const value: any = event?.target?.value
        const expiryDate: ExpirationDateVerification = expirationDate(value)
        const DateLength = absLenght(value)

        if (DateLength > 0 && !expiryDate.isValid) {
            setInfo('Invalid date')
            setError(true)
        } else {
            setInfo('')
            setError(false)
        }
        if (expiryDate.isValid) {
            if (leaveFieldCallback) {
                leaveFieldCallback(tabIndex + 1)
            }
        }
    }

    const handleBlur = (event: any) => {
        const value: any = event?.target?.value
        const expiryDate: ExpirationDateVerification = expirationDate(value)
        if (expiryDate.isValid) {
            setError(false)
            setInfo('')
            CardContext?.setCardData({
                ...CardContext.cardData,
                cardExpiry: {
                    month: expiryDate.month,
                    year: expiryDate.year,
                    complete: true
                }
            })
        } else {
            setError(true)
            CardContext?.setCardData({
                ...CardContext?.cardData,
                cardExpiry: {
                    month: '',
                    year: '',
                    complete: false
                }
            })
        }
    }
    useEffect(() => {
        if (fieldError) {
            setError(true)
            setInfo('Invalid card date')
        }
    }, [fieldError])

    useEffect(() => {
        if (focus) {
            inputRef.current.focus()
        }
    }, [focus])

    return (
        <FormControl fullWidth error={error} className={'form_control'}>
            <div className="label_blk">
                <div className="label">Exp.*</div>
            </div>
            <InputMask
                mask="99 / 99"
                maskChar=" "
                onChange={handleChange}
                onBlur={handleBlur}
                tabIndex={tabIndex}>
                {() =>
                    <TextField variant="outlined"
                        type={'tel'}
                        className={'fieldText'}
                        inputRef={inputRef}
                        placeholder={'XX/XX'}
                        autoFocus={focus}
                    />
                }
            </InputMask>
            {error ? (
                <p className="error_txt error_txt_md">{info}</p>
            ) : null}
        </FormControl>
    )
}

export default CreditCardDateField