import {CarrierConfig} from './carrierService'
import axios, {AxiosResponse} from 'axios'
import Stripe from 'stripe'
import {datadogRum} from '@datadog/browser-rum'



type Quote = {
    [key: string]: any,
    carrierConfig: CarrierConfig,
}

// BindableQuote: Quote object with payment token added
type BindableQuote = Quote & {
    payment_token_id: string
}

type Answers = {
    [key: string]: any
}

export type CreditCardInfo = {
    cardNumber: string,
    expYear: string,
    expMonth: string,
    cardCVC: string,
    cardName: string
}

export type BankAccountInfo = {
    publicToken: string,
    accountToken: string,
}


export enum PaymentMethod {
    Bank = 'BANK',
    Card = 'CARD'
}

const addPaymentToken = async (
    quote: Quote,
    answers: Answers,
    useDefaultAddress: boolean,
    paymentMethod: PaymentMethod,
    paymentInfo: CreditCardInfo | BankAccountInfo,
): Promise<AxiosResponse | undefined> => {

    if (paymentMethod === PaymentMethod.Card) {

        const ccInfo: CreditCardInfo = paymentInfo as CreditCardInfo
        const cardData = {
            number: ccInfo.cardNumber.replace(/\s/g, ''),
            exp_year: ccInfo.expYear,
            exp_month: ccInfo.expMonth,
            cvc: ccInfo.cardCVC
        }

        const params: Stripe.TokenCreateParams = useDefaultAddress ? {
            card: {
                ...cardData,
                name: answers['billing.cardholder_name'] || ccInfo.cardName,
                address_line1: answers['business.street_address'],
                address_line2: answers['business.street_address2'],
                address_city: answers['business.city'],
                address_state: answers['business.state'],
                address_zip: answers['business.zip_code'],
                address_country: 'US',
                currency: 'USD',
            }
        } : {
            card: {
                ...cardData,
                name: answers['billing.cardholder_name'] || ccInfo.cardName,
                address_line1: answers['billing.street_address'],
                address_line2: answers['billing.street_address2'],
                address_city: answers['billing.city'],
                address_state: answers['billing.state'],
                address_zip: answers['billing.zip_code'],
                address_country: 'US',
                currency: 'USD',
            }
        }

        const payload = Object.assign({}, ...Object.entries(params.card!).map(([key, value]) => {
            return {[`card[${key}]`]: value}
        }))

        const stripePayload = new URLSearchParams(payload).toString()

        try {
            const urlPrefix = process.env?.REACT_APP_STRIPE_API || window?.stripe_api
            return await axios.post(
                urlPrefix + '/v1/tokens',
                stripePayload,
                {
                    headers: {
                        Authorization: 'Bearer ' + quote.carrierConfig.stripePublishableToken
                    }
                }
            )
        } catch (e) {
            datadogRum.addError(`Failed to retrieve stripe card token. ${e}`)
            throw e
        }

    } else {
        const bankInfo: BankAccountInfo = paymentInfo as BankAccountInfo

        try {
            return  await axios.post('/api/plaid-create-token', bankInfo)
        } catch (e) {
            datadogRum.addError(`Failed to retrieve stripe bank account token. ${e}`)
            throw(e)
        }
    }
}

export const bindPaymentMethod = async (
    quotes: Array<Quote>,
    answers: Answers,
    useDefaultAddress: boolean,
    paymentMethod: PaymentMethod,
    paymentInfo: CreditCardInfo | BankAccountInfo,

): Promise<BindableQuote[]> => {
    return await Promise.all(quotes.map(async (q) => {
        try {
            const tokenResponse = await addPaymentToken(q, answers, useDefaultAddress, paymentMethod, paymentInfo)
            const bindableQuote: BindableQuote = {
                ...q,
                payment_token_id: tokenResponse?.data?.id || tokenResponse?.data?.stripeToken
            }
            return bindableQuote
        } catch (e) {
            datadogRum.addError(`Bind Payment Method. ${e}`)
            throw(e)
        }
    }))
}

export const updatePaymentMethod = async (
    quote: Quote,
    answers: Answers,
    useDefaultAddress: boolean,
    paymentMethod: PaymentMethod,
    paymentInfo: CreditCardInfo | BankAccountInfo,

) => {
    try {
        const tokenResponse = await addPaymentToken(quote, answers, useDefaultAddress, paymentMethod, paymentInfo)
        quote.payment_token_id = tokenResponse?.data?.id || tokenResponse?.data?.stripeToken
        return quote
    } catch (e) {
        datadogRum.addError(`Update Payment Method. ${e}`)
        throw(e)
    }
}
