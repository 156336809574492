import React from 'react'
import { connect } from 'react-redux'
import { sortBy } from 'lodash'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import SectionDetailInfoItem from './SectionDetailInfoItem'
//scss
import '../../scss/v2/_stepper.scss'

class SectionDetailnfo extends React.Component {

  getSectionNavigationLink (section) {
    let subsection = sortBy(this.props.subsections.filter(itm => itm.section_index === section.section_index), 'subsection_index')

    return (subsection.length > 0)
      ? '/get-insurance' + subsection[0].path
      : '/get-insurance/business-name-and-address' //fallback to start
  }

  getSubsectionIndex () {
    let locationStr = window.location.pathname
    let index = locationStr.lastIndexOf('/');
    let subsectionCurrentPath = `/${locationStr.slice(index + 1)}`

    return this.props.subsections.find(item => item.path === subsectionCurrentPath)?.subsection_index || 0
  }
  render () {
    const { t } = this.props
    return (
      <>
        {this.props.sections.map((itm, idx) => {
          return (
            <SectionDetailInfoItem quote_status={this.props.answers['quote_status']}
                                   session_complete={this.props.answers['session_complete']}
                                   progress={itm.progress}
                                   key={idx}
                                   section={itm}
                                   history={this.props.history}
                                   subsection_index={this.getSubsectionIndex()}
                                   section_index={itm.section_index}/>
          )
        })}
        {this.props.sidebarTotalPremiumAmount &&
          <div className={'fields'}>
            <div className={'row'}>
              <div className={'name'}>{t('common.premium_amount') + ':'}</div>
              <div className={'value'}>{this.props.sidebarTotalPremiumAmount}</div>
            </div>
          </div>}

      </>

    )
  }
}

const mapStateToProps = state => {
  return {
    sidebarTotalPremiumAmount: state.insurance.sidebarTotalPremiumAmount,
    answers: state.insurance.answers,
    subsections: state.insurance.subsections,
    industry_classes: state.insurance.industry_classes,
    wc_locations: state.insurance.wc_locations
  }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(SectionDetailnfo)))
