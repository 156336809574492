import React from "react";
import NumberQuestion from "./NumberQuestion";
import {withTranslation} from "react-i18next";
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, TextField} from "@material-ui/core";
import sources_update from '../../../common/decorator'
import {connect} from 'react-redux'

class Number extends NumberQuestion {

    onNumberChange(e) {
        if (this.props.onChange) {
            let number = parseInt(e.nativeEvent.target.value)

            if(this.props.qid === 'premises.full_time_employees'){
              if (number < 1) number = 1
            }else{
              if (number < 0) number = 0
            }

            number = this.verifyMaxValue(this.props.max_value, number)

            if (this.props.field_length) {
                if (this.props.field_length >= number.toString().length) this.props.onChange(this.props.id, number)
            } else this.props.onChange(this.props.id, number)
            sources_update(this.props.answers, this.props.qid)
        }
    }

    render() {
        const {t} = this.props

        return (
            <FormControl fullWidth={this.props.fullWidth}
                         className={'form_control'}
                         error={this.props.error}>
                <div className="label_blk">
                    <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                    {this.props.autofilled && <AutofilledBadge/>}
                </div>

                <TextField
                    className={'fieldText'}
                    variant="outlined"
                    type="number"
                    value={this.props.answer}
                    error={!!this.props.error}
                    placeholder={this.props.placeholder}
                    onChange={(e) => this.onNumberChange(e)}
                />
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
                {this.state.above_max && <p className="error_txt error_txt_md error">{t('form.error.max_permitted_value', {
                    value: this.props.max_value
                })}</p>}
            </FormControl>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
})
  
export default connect(mapStateToProps)(withTranslation()(Number))
