import React from "react";
import {withTranslation} from 'react-i18next';
import {getLimitValue} from "../../../helpers/display";

const WCPolicyLimits = (props) => {
    const {t, quote: {limits}} = props

    return (<div className={'limit_info'}>
        {props.answers_snapshot['premises.0.full_time_employees'] && (
            <div className="portal_limit_card">
                <span className="limit_name">{t('common.employees')}</span>
                <span
                    className="limit_value">{props.answers_snapshot['premises.0.full_time_employees']}</span>
            </div>
        )}

        {Object.keys(limits || {}).map((itm, index) => {
            if (itm !== "employee_limit") {
                return (<div className="portal_limit_card" key={index}>
                    <span className="limit_name">{limits[itm].display_name}</span>
                    <span className="limit_value">{getLimitValue(limits[itm])}</span>
                </div>)
            } else return null
        })}
    </div>)
}

export default withTranslation()(WCPolicyLimits)