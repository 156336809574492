import React from "react";
import {withTranslation} from 'react-i18next';
import {Carousel} from 'react-responsive-carousel';
import {ArrowLeft} from "../../icons/ArrowLeft";

const SidebarAds = () => {
    const ads = [
        {
            title: "Contact Customer Experience",
            text: "We're here to help you manage your policy & answer questions. We're available M-Th 8:30 - 5:30pm ET, F 8:30am - 5:00pm ET.",
            contacts: [
                {
                    label: "Call us:",
                    text: "800-330-1750",
                    href: "tel:800-330-1750"
                },
                {
                    label: "Email anytime: ",
                    text: "cl_service@acrisure.com",
                    href: "mailto:cl_service@acrisure.com"
                },
            ]
        },
    ]

    return (
        <div className="sidebar_ads_cnt">
            <div className="bg">
                <Carousel axis="horizontal"
                          dynamicHeight={false}
                          autoPlay
                          infiniteLoop
                          interval={5000}
                          showArrows={false}
                          showIndicators={false}
                          showThumbs={false}
                          showStatus={false}
                          stopOnHover>
                    {ads.map((item, itemKey) => {
                        return (<div key={itemKey}>
                            <div className="advert_title">{item.title}</div>
                            <div className="advert_text">{item.text}</div>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            {item.link && <a href={item.link.href} target="_blank"
                                             className={'advert_link'}>{item.link.text} <ArrowLeft/></a>}
                            {item.contacts && <ul className={'advert_contact_list'}>
                                {item.contacts.map((contact, contactKey) => {
                                    return (<span className={'advert_contact_item'} key={contactKey}>
                                    <span className="item_label">{contact.label}</span>
                                    <a href={contact.href} className="item_text">{contact.text}</a>
                                </span>)
                                })}
                            </ul>}
                        </div>)
                    })}
                </Carousel>
            </div>
        </div>
    )
}

export default withTranslation()(SidebarAds)
