import React from "react";
import Question from "../form-components/Question";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    changeAnswers,
    updateInsStoreKey,
    updateProgress,
    updateSections,
    updateWCUWQuestions
} from "../../store/insurance/actions";
import {formatCurrency} from "../../helpers/currency";
import {getLimitsOptionsV2} from "../../helpers/limitsHelpers";
import {calculatePremiumTotal} from "../../hooks/get-premium-total";
import {QuestionTooltip} from '../form-components/QuestionTooltip';
import sources_update from '../../common/decorator'


class CustomizePL extends React.Component {

    state = {
        questions: [],
        questions_keys: [
            'mpl.coverages.occurrence_limit',
            'mpl.coverages.deductible_amount'
        ]
    }

    constructor(props) {
        super(props);

        this.state.questions = this.props.subsections_questions_data.filter(q => this.state.questions_keys.includes(q.qid))
        const limits  = this.props.answers.selected_quotes.filter(q => q.quote_type === 'PL')[0].limits
        this.props.changeAnswers({
            'mpl.coverages.occurrence_limit': limits?.professional_liability_occurrence_limit?.value,
            'mpl.coverages.deductible_amount': limits?.professional_liability_deductible_amount?.value
        })
        this.state.questions = getLimitsOptionsV2(props, this.state.questions, 'PL')
    }

    onChange(key, value) {
        this.props.changeAnswers({
            [key]: value
        })
        this.props.onChange(key, value, this.props.quote_idx)
        sources_update(this.props.answers, key)
    }

    render() {
        const {t} = this.props

        return (
            <div className="customize-quote-card">
                <div className="desc">
                    <div className="txt">
                        <div className="name">{t('customize_card.pl.title')}</div>
                        <div className="help">{t('customize_card.pl.txt')}</div>
                    </div>
                    <div className="policy-price">
                        <div className="price">
                            <div className="oc-price-val">{formatCurrency(calculatePremiumTotal(this.props, 'PL') / 12, true)}</div>
                            <small className="oc-price-period">&nbsp;/{t('common.month_short')}</small>
                        </div>
                    </div>
                </div>

                <div className="limits">
                    {this.state.questions.map((q, idx) =>
                        q.hidden || q.disabled
                        ? null
                        : (
                            // key property is intentionally written as so to trigger child component re-renders
                            <div className="limit" key={`${q.options?.length}${this.props.answers[q.qid]}${idx}`}>
                                <div className="name">{q.question_text}&nbsp;{q.tooltip && <QuestionTooltip text={q.tooltip}/>}</div>
                                <div className="question">
                                    <Question {...q}
                                              type={q.question_type}
                                              coverageType='PL'
                                              question={q.question_text}
                                              answer={this.props.answers[q.qid]}
                                              onChange={(id, value) => {
                                                  this.onChange(q.qid, value)
                                              }}
                                    />
                                </div>
                            </div>
                            )
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        quotes: state.insurance.quotes,
        answers: state.insurance.answers,
        session_answers: state.insurance.session_answers,
        subsections: state.insurance.subsections,
        current_subsection: state.insurance.current_subsection,
        is_session_restored: state.insurance.is_session_restored,
        subsections_questions: state.insurance.subsections_questions,
        subsections_questions_data: state.insurance.subsections_questions_data
    }
}

const mapDispatchToProps = {
    changeAnswers,
    updateProgress,
    updateInsStoreKey,
    updateWCUWQuestions,
    updateSections
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomizePL))