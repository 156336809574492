import { getCarrierDetailInfo } from './GetCarrierDetailInfo'

interface Quote {
    line_of_business: string
}

interface Answers {
    selected_quotes: Array<Quote>
    ['business.state']: string
}

interface CarrierDetails {
    logo: string
    name: string
    rating: string
    insurer: string
    portal_insurer: string
    underwritten_by: string
}

// @ts-ignore
const GetInsuranceCarrier = (answers: Answers, policyType: string, quotes: any = null ): CarrierDetails | undefined =>{
    const {selected_quotes} = answers
    const sessionQuotes = quotes ? quotes : selected_quotes
    const quote = sessionQuotes.filter(itm => itm.line_of_business === policyType)[0]
    const carrier = quote.source
    const state = answers['business.state']

    return getCarrierDetailInfo(carrier, state)

}

export {GetInsuranceCarrier as getInsuranceCarrier}