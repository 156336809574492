import React from "react";
import Question from "../form-components/Question";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    changeAnswers,
    updateInsStoreKey,
    updateProgress,
    updateSections,
    updateWCUWQuestions
} from "../../store/insurance/actions";
import {currency, formatCurrency} from "../../helpers/currency";
import {getLimitsOptionsV2} from "../../helpers/limitsHelpers";
import {DoublyLinkedList} from '../../helpers/linked-list'
import {QuestionTooltip} from '../form-components/QuestionTooltip';
import sources_update from '../../common/decorator'



class CustomizeWC extends React.Component {

    state = {
        questions: [],
        limits: {},
        questionKeys: [
            'wc.coverage.occurrence_limit',
            'wc.coverage.each_employee_limit',
            'wc.coverage.policy_limit'
        ],
        occurrenceLimitChoices: new DoublyLinkedList(),
        selectedLimitTier: 0,
        selectedLimits: {}
    }

    constructor(props) {
        super(props)

        this.state.questions = this.props.subsections_questions_data.filter(q => this.state.questionKeys.includes(q.qid))
        this.state.questions = getLimitsOptionsV2(props, this.state.questions, 'WC')
        this.state.selectedLimits = this.props.answers.selected_quotes.filter(q => q.quote_type === 'WC')[0].limits

        const occurrenceLimitChoices = this.state.questions.filter(q => q.qid === 'wc.coverage.occurrence_limit')[0]?.options
        this.state.occurrenceLimitChoices = occurrenceLimitChoices.map(elem => {
            return this.state.occurrenceLimitChoices.insert(elem.value)
        })

        this.state.selectedLimitTier = occurrenceLimitChoices.findIndex(obj => {
            return obj.value === this.state.selectedLimits?.wc_occurrence_limit?.value
        })

        this.props.changeAnswers({
            'wc.coverage.occurrence_limit': this.state.selectedLimits?.wc_occurrence_limit?.value,
            'wc.coverage.each_employee_limit': this.state.selectedLimits?.wc_each_employee_limit?.value,
            'wc.coverage.policy_limit': this.state.selectedLimits?.wc_policy_limit?.value
        })
    }

    onChange(id, value, tier) {
        this.setState({selectedLimitTier: tier})
        let answersArr = this.state.questionKeys.map(q => {
            return {[q]: this.state.questions.filter(question => question.qid === q)[0]?.options[tier]?.value}
        })
        const answers = Object.assign({}, ...(answersArr.map(item => ({...item}))))
        this.props.changeAnswers(answers)
        this.props.onChange(id, value, this.props.quote_idx)
        sources_update(answers, id)
    }

    render() {
        const {t} = this.props

        return (
            <div className="customize-quote-card">
                <div className="desc">
                    <div className="txt">
                        <div className="name">{t('customize_card.wc.title')}</div>
                        <div className="help">{t('customize_card.wc.txt')}</div>
                    </div>
                    <div className="policy-price">
                        <div className="price">
                            <div className="oc-price-val">{formatCurrency(currency(this.props.term_premium))}</div>
                            <small className="oc-price-period">&nbsp;/{t('common.month_short')}</small>
                        </div>
                    </div>
                </div>
                <div className="limits">
                    {this.state.questions.map((q, idx) =>
                        q.hidden || q.disabled
                            ? null
                            : (
                                <>
                                    <div className='limit' key={`${idx}-${this.state.selectedLimitTier}-${this.props.answers[q.qid]}`}>
                                        <div className='name'>{q.question_text}&nbsp;{q.tooltip && <QuestionTooltip text={q.tooltip}/>}</div>
                                        <div className='question'>
                                            <Question {...q}
                                                      type={q.question_type}
                                                      coverageType='WC'
                                                      question={q.question_text}
                                                      answer={this.props.answers[q.qid]}
                                                      onChange={(id, value, tier) => {
                                                          this.onChange(id, value, tier)
                                                      }}
                                                      limitTier={this.state.selectedLimitTier}
                                            />
                                        </div>
                                    </div>
                                </>
                            ))}
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        quotes: state.insurance.quotes,
        answers: state.insurance.answers,
        session_answers: state.insurance.session_answers,
        subsections: state.insurance.subsections,
        current_subsection: state.insurance.current_subsection,
        subsections_questions: state.insurance.subsections_questions,
        subsections_questions_data: state.insurance.subsections_questions_data,

    }
}

const mapDispatchToProps = {
    changeAnswers,
    updateProgress,
    updateInsStoreKey,
    updateWCUWQuestions,
    updateSections
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomizeWC))