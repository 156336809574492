import React from "react"
import {connect} from "react-redux";
import {Info} from "../../icons/Info";
import {Plus} from "../../icons/Plus";
import {Trash} from "../../icons/Trash";
import {withTranslation} from 'react-i18next';
import CYBERDetails from '../../modals/CYBERDetails'
import CardCommon from './CardCommon';
import { updateInsStoreKey} from "../../../store/insurance/actions";
import {formatCurrency} from "../../../helpers/currency";

class CardCYBER extends CardCommon {

    getCyberPolicyPrice (){
        let additionalDescription = `<span>Rate includes financing with 10 payments and a ${formatCurrency(this.props.estimatedPremium.downpayment_cents/100, true)} down payment. Pay-in-full option available.</span>`;
        let total = this.props.estimatedPremium.term_payment_cents/100

        return <>
            <div className={'policy_total_blk'}>
        <span className="price_value">
          {formatCurrency(total, true)}
        </span>
                <small className="interval_modifier">/{this.props.t('common.month_long')}</small>
            </div>
            {this.props.quote && this.state.isAscendIntegration && <div className={'policy_additional_notify_blk'}>
                <p className="additional_txt" dangerouslySetInnerHTML={{ __html: additionalDescription }}/></div>}
        </>
    }
    render() {
        const {t} = this.props
        return (
            <>
                <div className={'fq_card'}>
                    <p className={'fq_card_ttl'}>{t('common.cyber_policy')}</p>
                  {this.getPolicyCarrier()}
                  {this.props.estimatedPremium === null
                      ? this.getPolicyPrice()
                      : this.getCyberPolicyPrice()}
                    <div className="fq_card_controls">
                      <button type={'button'}
                              className={'fq_card_btn'}
                              onClick={() => {
                                this.openQuoteDetails()
                              }}>
                        <Info className={'icon_info'}/> <span>{t('common.btn_details')}</span>
                      </button>
                      <button type={'button'}
                              className={'fq_card_btn'}
                              onClick={() => {
                                this.props.policyToggle()
                              }}
                      >{this.props.selected ? (
                        <>
                          <Trash className={'icon_trash'}/> <span>{t('common.btn_remove')}</span>
                        </>
                      ) : (
                        <>
                          <Plus className={'icon_plus'}/> <span>{t('common.btn_add')}</span>
                        </>
                      )}</button>
                    </div>
                </div>
                <CYBERDetails open={this.state.details}
                           quote={this.props.quote}
                           quotes={this.props.quotes}
                           answers={this.props.answers}
                           price={this.props.estimatedPremium === null
                               ? this.getPolicyPrice()
                               : this.getCyberPolicyPrice()}
                           onClose={() => this.closeQuoteDetails()}/>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        estimatedPremium: state.insurance.estimatedPremium,
        quotes: state.insurance.quotes,
        answers: state.insurance.answers,
        bop_limit_available: state.insurance.bop_limit_available,
        oc_availability: state.insurance.oc_availability
    }
}

const mapDispatchToProps = {
    updateInsStoreKey
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CardCYBER))
