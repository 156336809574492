import React from 'react';


const PaymentMethods = (props) => {

    return (
        <div className={'blk_sidebar payment_methods portal_card'}>
            <p className="sidebar_ttl">Payment Methods</p>
            {props.paymentMethods.map((payment, i) => (
                <div className="row payment_item_sidebar payment_method_item" key={i}>
                    <p className={'method_row'}>{payment.brand} ending in xxx{payment.last4}</p>
                    {payment.isActive ? <span className={'active_method'}>Active</span> : <span className={'inactive_method'}>Inactive</span>}
                </div>)
            )}

        </div>
    );

}

export default PaymentMethods;