import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {
    changeAnswers, updateInsStoreKey, updateProgress, updateSections, updateWCUWQuestions
} from '../../../store/insurance/actions'
import objReducer from '../../../helpers/objReducer'
import {formatCurrency} from '../../../helpers/currency'
import {QuestionTooltip} from '../../form-components/QuestionTooltip'

import {
    getLimitsOptionsV2,
    updateDamageToPremisesRentedToYouLimit,
    updateOccurrenceLimit
} from '../../../helpers/limitsHelpers'
import Question from '../../form-components/Question'

const CustomizeRateGL = (props)=> {

    const [selectedQuote, setSelectedQuote] = useState<any>(null)
    const [lobQuestionsList, setLobQuestionsList] = useState<[]>([])
    const [lobQuestionsToRender, setLobQuestionsToRender] = useState<[]>([])

    const [limitKeys, setLimitKeys] = useState<any>([])
    const [processing, setProcessing] = useState<boolean>(true)


    const getLimitAnswers = (limits) =>{
        let limitList = []

        for (const property in limits) {
            // @ts-ignore
            limitList.push(limits[property])
        }

        let answersToUpdate = {}
        limitList.forEach(limit=>{
            // @ts-ignore
            answersToUpdate[limit.question_id] = limit.value
        })

        return answersToUpdate
    }

    //GET available limits keys
    useEffect(()=>{
        const quote = props.answers.selected_quotes.filter(q => q.quote_type === 'GL')[0]

        setSelectedQuote(quote)

        const limits = quote.limits
        let limitKeysList = Object.keys(limits)
        const updatedQuestionKeys = limitKeysList.map(k => {
            return limits[k].question_id
        })
        setLimitKeys(updatedQuestionKeys)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //SET Questions filtered questions by limitsKeys
    useEffect(()=>{
        const lobQuestionsList = props.subsections_questions_data.filter(q => limitKeys.includes(q.qid))
        let updatedQuestionsList = getLimitsOptionsV2(props, lobQuestionsList, 'GL')


        setLobQuestionsList(updatedQuestionsList)
        setLobQuestionsToRender(updatedQuestionsList)
        if(limitKeys.length !==0 && selectedQuote) {
            let answersToUpdate = getLimitAnswers(selectedQuote?.limits)
            props.changeAnswers(answersToUpdate)
        }
        setProcessing(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limitKeys])

    const updateQuestionOptions =()=>{
        if(lobQuestionsList.length!==0){
            let damageToPremisesRentedToYouLimitCheck = updateDamageToPremisesRentedToYouLimit(props.answers, lobQuestionsList, 'gl.coverage.gl_damage_to_premises_rented_to_you', 'gl.coverage.gl_limit')

            let updatedQuestionsList = lobQuestionsToRender.map(q => {
                // @ts-ignore
                if (q.qid === 'gl.coverage.gl_damage_to_premises_rented_to_you') {
                    // @ts-ignore
                    return {...q, options: damageToPremisesRentedToYouLimitCheck.options}
                    // @ts-ignore
                } else {
                    return q
                }
            })
            // @ts-ignore
            setLobQuestionsToRender(updatedQuestionsList)

            props.changeAnswers({
                'gl.coverage.gl_damage_to_premises_rented_to_you': damageToPremisesRentedToYouLimitCheck.value
            })
        }
    }

    //
    //
    //First check and update options for gl.coverage.gl_damage_to_premises_rented_to_you depend on gl.coverage.gl_limit
    useEffect(()=>{
        updateQuestionOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lobQuestionsList])

    //
    //
    //Update options for gl.coverage.gl_damage_to_premises_rented_to_you depend on gl.coverage.gl_limit
    useEffect(()=>{
        updateQuestionOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.answers['gl.coverage.gl_limit'], props.answers['gl.coverage.gl_damage_to_premises_rented_to_you']])

    useEffect(()=>{
        let questionsList = getLimitsOptionsV2(props, lobQuestionsList, 'GL')
        setLobQuestionsToRender(questionsList)


        let limitCheck = updateOccurrenceLimit(props.answers, lobQuestionsList)
        props.changeAnswers({
            'gl.coverage.gl_limit': limitCheck.occurrenceLimit
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.answers['gl.coverage.gl_aggregate_limit']])


    const onChangeHandler=(key, value)=> {
        props.changeAnswers({
            [key]: value
        })
        props.onChange(key, value, props.quote_idx)
    }

    // @ts-ignore
    const getTotal=()=> {
        let oc_premium_total = 0

        const gl = props.answers.selected_quotes.filter(q => q.line_of_business === 'GL')[0]
        const { term_premium } = gl
        const oc = gl?.optional_coverages.filter(c => c.coverage_name !== 'tria')

        try {
            oc_premium_total = objReducer(oc, 'term_premium')
            return (term_premium - oc_premium_total) / 12

        } catch (e) {
            throw new Error(`Something went wrong while getting BOP premium. ${e}`)
        }
    }

    return (
        processing ? <>Loading...</>:
            <div className="customize-quote-card">
                <div className="desc">
                    <div className="txt">
                        <div className="name">{props.t('customize_card.gl.title')}</div>
                        <div className="help">{props.t('customize_card.gl.txt')}</div>
                    </div>
                    <div className="policy-price">
                        <div className="price">
                            <div className="oc-price-val">{formatCurrency(getTotal(), true)}</div>
                            <small className="oc-price-period">&nbsp;/{props.t('common.month_short')}</small>
                        </div>
                    </div>
                </div>

                <div className="limits">

                    {lobQuestionsToRender.map((q, idx) => q['hidden'] || q['disabled'] ? null : (<>
                        <div className="limit" key={`${q['options']?q['options']['length'] : null}${props.answers[q['qid']]}${idx}`}>
                            <div className="name">{q['question_text']}&nbsp;{q['tooltip'] &&
                              <QuestionTooltip text={q['tooltip']}/>}</div>
                            <div className="question">
                                <Question {...q}
                                    type={q['question_type']}
                                    coverageType="GL"
                                    question={q['question_text']}
                                    answer={props.answers[q['qid']]}
                                    onChange={(_id, value) => {
                                        onChangeHandler(q['qid'], value)
                                    }}
                                />
                            </div>
                        </div>
                    </>
                    ))}
                </div>
            </div>
    )
}

const mapStateToProps = state => {
    return {
        quotes: state.insurance.quotes,
        answers: state.insurance.answers,
        session_answers: state.insurance.session_answers,
        subsections: state.insurance.subsections,
        current_subsection: state.insurance.current_subsection,
        is_session_restored: state.insurance.is_session_restored,
        subsections_questions: state.insurance.subsections_questions,
        subsections_questions_data: state.insurance.subsections_questions_data,
        oc_availability: state.insurance.oc_availability
    }
}

const mapDispatchToProps = {
    changeAnswers, updateProgress, updateInsStoreKey, updateWCUWQuestions, updateSections
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomizeRateGL))
