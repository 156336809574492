import React from "react";
import {withTranslation} from 'react-i18next';
import LoadingButton from "../../LoadingButton";
import Question from "../../form-components/Question";
import {Box, Button, Grid, Typography} from "@material-ui/core";

class EnterEmail extends React.Component {

    render() {
        const {t} = this.props

        return (
            <form autoComplete="off" onSubmit={(e) => this.props.validate(e)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h1"
                                    style={{lineHeight: '30px'}}>{t('auth.email_enter')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={8} md={6} lg={4}>
                                <Question fullWidth
                                          type="email"
                                          question={t('form.label.e_mail')}
                                          inputRef={this.props.validated_email_ref}
                                          answer={this.props.login}
                                          error={!!this.props.errors?.login}
                                          helperText={this.props.errors?.login}
                                          onChange={(id, value) => this.props.onLoginChange(value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box py={2}>
                            {this.props.processing ? (<LoadingButton/>)
                                : (
                                    <Button type="submit"
                                            size="large"
                                            color="primary"
                                            variant="contained"
                                            onClick={(e) => {
                                                this.props.validate(e)
                                            }}>
                                        {t('common.btn_continue')}
                                    </Button>
                                )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

export default withTranslation()(EnterEmail)
