import React from "react";

export const GL = () => (
    <svg width="35" height="35" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.4202 29.2037L26.6781 6.41366C26.1225 5.54437 25.3569 4.82897 24.452 4.33342C23.547 3.83787 22.5319 3.57813 21.5002 3.57812C20.4685 3.57813 19.4534 3.83787 18.5485 4.33342C17.6436 4.82897 16.878 5.54437 16.3223 6.41366L2.58022 29.2037C2.09477 30.0129 1.83093 30.9356 1.8152 31.8792C1.79947 32.8227 2.03243 33.7537 2.49064 34.5787C3.0204 35.5072 3.78719 36.2785 4.71266 36.8136C5.63814 37.3487 6.6891 37.6286 7.75814 37.6245H35.2423C36.3043 37.6358 37.3505 37.3668 38.2754 36.8446C39.2002 36.3224 39.971 35.5655 40.5098 34.6503C40.9815 33.8168 41.2219 32.8724 41.2061 31.9148C41.1904 30.9572 40.919 30.0212 40.4202 29.2037ZM37.3744 32.8766C37.1591 33.2467 36.8466 33.5509 36.4707 33.7562C36.0949 33.9615 35.6701 34.06 35.2423 34.0412H7.75814C7.33033 34.06 6.90551 33.9615 6.5297 33.7562C6.15388 33.5509 5.84139 33.2467 5.62605 32.8766C5.4688 32.6042 5.38602 32.2952 5.38602 31.9807C5.38602 31.6662 5.4688 31.3573 5.62605 31.0849L19.3861 8.27699C19.6333 7.95038 19.9529 7.6855 20.3197 7.50312C20.6865 7.32075 21.0906 7.22584 21.5002 7.22584C21.9099 7.22584 22.3139 7.32075 22.6808 7.50312C23.0476 7.6855 23.3671 7.95038 23.6144 8.27699L37.3565 31.067C37.5198 31.3399 37.6075 31.6513 37.6107 31.9694C37.6138 32.2874 37.5323 32.6005 37.3744 32.8766Z" fill="#FFFFFF"/>
        <path d="M21.4997 30.4583C22.4892 30.4583 23.2913 29.6562 23.2913 28.6667C23.2913 27.6772 22.4892 26.875 21.4997 26.875C20.5102 26.875 19.708 27.6772 19.708 28.6667C19.708 29.6562 20.5102 30.4583 21.4997 30.4583Z" fill="#FFFFFF"/>
        <path d="M21.4997 14.332C21.0245 14.332 20.5688 14.5208 20.2328 14.8568C19.8968 15.1928 19.708 15.6485 19.708 16.1237V23.2904C19.708 23.7655 19.8968 24.2213 20.2328 24.5573C20.5688 24.8933 21.0245 25.082 21.4997 25.082C21.9749 25.082 22.4306 24.8933 22.7666 24.5573C23.1026 24.2213 23.2913 23.7655 23.2913 23.2904V16.1237C23.2913 15.6485 23.1026 15.1928 22.7666 14.8568C22.4306 14.5208 21.9749 14.332 21.4997 14.332Z" fill="#FFFFFF"/>
    </svg>

)
