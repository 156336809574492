/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSUnresolvedVariable

import React, {useEffect, useRef, useState} from "react"

import {connect} from "react-redux";
import {GoogleApiWrapper} from "google-maps-react";
import {Grid} from "@material-ui/core";
import {Statsig, useExperiment} from 'statsig-react'
import HomeBasedBusinessModal from '../../components/modals/HomeBasedBusiness';
import {
    changeAnswers,
    placeDefaultsAnswers,
    updateIndustryClasses,
    updateInsStoreKey,
    updateProgress,
    updateQuestionsAutofill,
    updateWCIndustryClasses,
    updateWCLocations,
} from "../../store/insurance/actions";
import {withTranslation} from 'react-i18next';
import Loading from "../../components/Loading";
import {cloneDeep, includes, uniq} from "lodash";
import LoadingButton from "../../components/LoadingButton";
import Question from "../../components/form-components/Question";
import SearchLocation from "../../components/portal/claim/SearchLocation";
import SearchLocationInput from "../../components/form-components/SearchLocation";
import NextSectionButton from "../../components/form-components/NextSectionButton";
import SectionHead from "../../components/insurance/SectionHead";
// hooks
import DoRequest from "../../hooks/do-request";
import PostAnswers from "../../hooks/post-answers";

//css
import '../../scss/v2/_section_business_data.scss';

const BusinessData = props => {

    const questionFilter = (qid) => {
        return props.subsections_questions.filter(q => q.qid === qid)[0]
    }

    // States & Variables
    const {t, industry_classes} = props
    const details_ref = useRef()

    const [sessionToken, setSessionToken] = useState(null)
    const [placeService, setPlaceService] = useState(null)
    const [nextUrl, setNextUrl] = useState('/results')
    const [errors, setErrors] = useState({})
    const [lastBusinessName, setLastBusinessName] = useState(null)
    const [businessNameCompleted, setBusinessNameCompleted] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [businessNameQuestion] = useState(questionFilter('business.name'))
    const [streetAddressQuestion] = useState(questionFilter('business.street_address'))
    const [streetAddress2Question] = useState(questionFilter('business.street_address2'))
    const [cityQuestion, setCityQuestion] = useState(questionFilter('business.city'))
    const [stateQuestion, setStateQuestion] = useState(questionFilter('business.state'))
    const [zipQuestion] = useState(questionFilter('business.zip_code'))
    const [homeQuestion] = useState(questionFilter('business.location.home'))
    const [locationMoreThanOneQuestion] = useState(questionFilter('business.locations'))
    const [industryClasses] = useState(industry_classes)

    const [advisorHomeBusiness, setAdvisorHomeBusiness] = useState(props?.advisorCache?.propertySearch)
    const [showPropertySearchModal, setShowPropertySearchModal] = useState(false)

    const industryClassesRef = useRef()
    industryClassesRef.current = industryClasses

    useEffect(() => {
        let placesService = new props.google.maps.places.PlacesService(details_ref.current),
            sessionToken = new props.google.maps.places.AutocompleteSessionToken()

        setPlaceService(placesService)
        setSessionToken(sessionToken)

    }, [props.google.maps.places.AutocompleteSessionToken, props.google.maps.places.PlacesService])

    useEffect(() => {
        if (props.answers['is_business_info_myself']) {
            setCityQuestion({...cityQuestion, field_disabled: false})
            setStateQuestion({...stateQuestion, field_disabled: false})
        } else {
            setDisabledCityAndState()
        }
    }, [])

    const { config: b2zaatest } = useExperiment("b2zaatest");

    useEffect(() => {
      Statsig.logEvent("b2z_visited_form_0", `${props.unique_session_id}`, {
        value: 0,
        b2z_visited_form_0: 'b2z_visited_form_0',
        business_name: `${props.answers['business.name']}`
      });

        if (!!props.answers['business.name']) setLastBusinessName(props.answers['business.name'])

        props.updateProgress({
            current_section: props.section.section_index,
            current_subsection: props.next_subsection - 1
        })

        const initializeNextUrl = "/get-insurance" + window.getNextSubSectionPath(props.subsections, props.next_subsection)
        setNextUrl(initializeNextUrl)

    }, [props.section.section_index])

    const setDisabledCityAndState = () => {
        setCityQuestion({...cityQuestion, field_disabled: props.answers['business.city'] !== ''})
        setStateQuestion({...stateQuestion, field_disabled: props.answers['business.state'] !== ''})
    }

    const onChangeLocation = (value, place, reason) => {
        clearPropertySearch()
        if (reason === 'select-option') {
            setBusinessNameCompleted(true)
            if (place) getPlaceDetails(place)
            else {
                setLastBusinessName('')
                updateInputFields(null, null)
            }
        }
    }

    const onLocationInputDone = () => {
        if (!businessNameCompleted) {
            setTimeout(() => {
                if (isBusinessChanged()) clearSessionAnswers()
                setLastBusinessName(props.answers['business.name'])
                setBusinessNameCompleted(true)
            }, 0)
        }
    }

    const clearSessionAnswers = (with_name = false) => {
        const restricted = [
            'acrisure_id',
            'business.name',
            'notify_email',
            'billing.frequency',
            'policy.desired_effective_date',
            'policy.desired_expiration_date',
            'coverage.types',
            'diya.class_found',
            'diya.greetings',
            'quote_status',
            'newCustomerSlackRequestSent',
            'contact.email',
            'business.locations',
            'business.location.home'
        ]
        let answers = {...props.answers}
        const answersKeys = Object.keys(props.answers)

        answersKeys.forEach((key, index) => {
            if (restricted.indexOf(key) === -1) answers[key] = ''
        })

        props.changeAnswers({
            ...answers,
            'business.name': with_name ? '' : props.answers['business.name']
        })

        props.updateInsStoreKey('fetched_industry_classes', [])
        props.updateInsStoreKey('quotes', [])
        props.updateInsStoreKey('full_quote', [])
        props.placeDefaultsAnswers()
    }

    const changeBusinessName = (value, reason) => {
        if (reason === 'input') {
            if (isBusinessChanged(value)) setBusinessNameCompleted(false)
            props.changeAnswers({
                'business.name': value
            })
        } else if (reason === 'clear') {
            setCityQuestion({...cityQuestion, field_disabled: false})
            setStateQuestion({...stateQuestion, field_disabled: false})
            clearPropertySearch()
            setBusinessNameCompleted(false)
            clearSessionAnswers(true)
        } else if (reason === 'reset') {
            setDisabledCityAndState()
        }
    }

    const isBusinessChanged = (new_name = null) => {
        new_name = !new_name ? props.answers['business.name'] : new_name

        return new_name !== lastBusinessName
    }

    const getPlaceDetails = (place) => {
        placeService.getDetails({
            placeId: place.place_id,
            fields: ['name', 'address_components', 'formatted_address', 'geometry', 'types', 'opening_hours', 'formatted_phone_number', 'website'],
            sessionToken: sessionToken
        }, (found_place, status) => {
            updateInputFields(found_place, status, place.place_id)
        })
    }

    const updateInputFields = (place, status, place_id = null) => {
        setDisabledCityAndState()
        setProcessing(true)
        let answers = {
            "business.name": '',
            "business.street_address": '',
            "business.street_address2": '',
            "business.state": '',
            "business.city": '',
            "contact.phone": '',
            "business.website": '',
            "business.zip_code": '',
            "business.place_id": place_id
        }

        if (status === props.google.maps.places.PlacesServiceStatus.OK && place) {
            answers["business.name"] = place.name
            answers["business.schedule"] = {
                "periods": place.opening_hours?.periods ? [...place.opening_hours.periods] : [],
                "weekday_text": place.opening_hours?.weekday_text ? [...place.opening_hours.weekday_text] : []
            }
            answers["business.g_types"] = place.types || []
            answers["contact.phone"] = place.hasOwnProperty('formatted_phone_number') ? place.formatted_phone_number.replace(/\D/g, "")?.toString() : ''
            answers["business.website"] = place?.website
            place.address_components.forEach((item) => {
                if (item.types.includes('street_number')) {
                    answers["business.street_address"] += item.long_name + ' '
                } else if (item.types.includes('route')) {
                    answers["business.street_address"] += item.short_name
                } else if (item.types.includes('administrative_area_level_1')) {
                    answers["business.state"] += item.short_name
                } else if (item.types.includes('locality')) {
                    answers["business.city"] += item.short_name
                } else if (item.types.includes('room')) {
                    answers["business.street_address2"] = item.long_name
                } else if (item.types.includes('floor')) {
                    answers["business.street_address2"] = item.long_name
                } else if (item.types.includes('postal_code')) {
                    answers["business.zip_code"] = item.long_name
                }
            })
        }

        //check if business was changed
        if (isBusinessChanged()) {
            if (answers["business.name"]) searchForBusinessPreheat({
                ...props.answers,
                ...answers
            })
            setLastBusinessName(props.answers['business.name'])
            clearSessionAnswers()

        }

        setTimeout(async () => {
            let partnerName = props.isPartner
                ? props.partnerData?.partner?.name ? props.partnerData.partner.name : 'Unknown'
                : ''
            let partnerKey = props.isPartner
                ? props.partnerData?.partner?.partner_key : ''

            PostAnswers(answers, props.answers, props.unique_session_id).then(() => {
                props.changeAnswers({
                    ...answers,
                    'partner': {
                        'name': partnerName,
                        'key': partnerKey
                    }
                })
                setProcessing(false)
            })
        }, 0)
    }

    const searchForBusinessPreheat = async () => {
        const {answers} = props

        if (answers['business.name'] && answers['business.zip_code']) {
            let address = ''
            address += answers['business.street_address'] + ', '
            if (answers['business.street_address2']) address += answers['business.street_address2'] + ', '
            address += answers['business.city'] + ', '
            address += answers['business.state'] + ', '
            address += 'USA'

            return await DoRequest({
                url: "/api/business/search",
                method: "post",
                body: {
                    name: answers['business.name'],
                    zip: answers['business.zip_code'],
                    address_str: address,
                    phone: answers['contact.phone']?.toString() || '',
                    website: answers['business.website'] || '',
                    place_id: answers['business.place_id'] || ''
                },
                onSuccess: (res) => res
            })
        }

    }

    const searchForBusiness = async (answers = null) => {
        /**
         * Function fetches industry classes from Advisor Service if the following conditions ar not met.  If the
         * following conditions are met, then the naics code data provided from bulk service is used instead and
         * variable call_advisor_service is to false to prevent use of advisor service.
         * 1. props.answers.b2z_naics_code and props.answers['business.description'] is populated with data from bulk
         * 2. props.is_from_bulk is set to true
         */
        if (!answers) answers = props.answers

        let {b2z_naics_code} = answers,
            naicsDescription = industryClassesRef.current.filter(i => i?.id === b2z_naics_code)[0]?.display_description,
            {is_from_bulk} = props,
            callAdvisorService = true

        if (is_from_bulk
            && !['', undefined, null].includes(b2z_naics_code)
            && !['', undefined, null].includes(naicsDescription)) {

            callAdvisorService = false

            let b2zClassCodes = {
                display_description: naicsDescription,
                id: b2z_naics_code,
                naics_code: b2z_naics_code
            }

            answers['business.b2z_class_codes'] = [b2zClassCodes]
            changeHandler('business.b2z_class_codes', [b2zClassCodes])
            changeHandler('b2z_class_code_id', b2z_naics_code)
        }

        if (answers['business.name'] && answers['business.zip_code']) {
            let address = ''
            address += answers['business.street_address'] + ', '
            if (answers['business.street_address2']) address += answers['business.street_address2'] + ', '
            address += answers['business.city'] + ', '
            address += answers['business.state'] + ', '
            address += 'USA'

            let coverageTypes = [];
            let coverageType;
            for (let i = 0; i < props.coverage_types.length; i++) {
                coverageType = props.coverage_types[i]
                coverageType.isDisabled = coverageType.sold_in.includes(answers['business.state']) === false;
                coverageTypes.push(coverageType)
            }

            props.updateInsStoreKey('coverage_types', coverageTypes)

            return await PostAnswers(answers, props.answers, props.unique_session_id).then(async (res) => {
                if (callAdvisorService) {

                    return await DoRequest({
                        url: "/api/business/search",
                        method: "post",
                        body: {
                            name: answers['business.name'],
                            zip: answers['business.zip_code'],
                            address_str: address,
                            phone: answers['contact.phone']?.toString() || '',
                            website: answers['business.website'] || '',
                            place_id: answers['business.place_id'] || ''
                        }
                    }).then(response => {

                        let fill = !props.is_from_bulk

                        if (response.data) {
                            let businessDescription = (answers['business.description']?.length > 0) ? answers['business.description'] : response.data['business.description']

                            let answersList = {
                                ...response.data,
                                "business.description": businessDescription
                            }
                            props.updateQuestionsAutofill({
                                answers: answersList,
                                mark: fill,
                                fill_values: fill,
                                filterBySubsection: false
                            })
                        } else {
                          throw new Error(`No defaults answers was returned.`)
                        }
                        return response
                    }).catch(e => {
                        throw new Error(`Business info not found. ${e}`)
                    })
                }
            })
        }
        return true
    }

    const changeHandler = (qid, value) => {
        props.changeAnswers({
            [qid]: value
        })
    }

    const changeLocationsHandler = (value) => {
        props.changeAnswers({
            'business.locations': value === 'Yes' ? 'Multi' : 'Single'
        })
    }

    const onStreetChange = (desc, newValue) => {
        if (newValue?.place_id) {
            getStreetDetails(newValue)
        }
    }

    const getStreetDetails = (place) => {
        placeService.getDetails({
            placeId: place.place_id,
            fields: ['address_components', 'geometry'],
            sessionToken: sessionToken
        }, (place, status) => {
            updateStreetFields(place, status)
        })
    }

    const updateStreetFields = (place, status) => {
        let answers = {
            "business.street_address": '',
            "business.street_address2": '',
            "business.state": '',
            "business.city": '',
            "business.zip_code": ''
        }

        if (status === props.google.maps.places.PlacesServiceStatus.OK && place) {
            place.address_components.forEach((item) => {
                if (item.types.includes('street_number')) {
                    answers["business.street_address"] += item.long_name + ' '
                } else if (item.types.includes('route')) {
                    answers["business.street_address"] += item.short_name
                } else if (item.types.includes('administrative_area_level_1')) {
                    answers["business.state"] += item.short_name
                } else if (item.types.includes('locality')) {
                    answers["business.city"] += item.short_name
                } else if (item.types.includes('sublocality')) {
                    answers["business.city"] += item.short_name
                } else if (item.types.includes('room')) {
                    answers["business.street_address2"] = item.long_name
                } else if (item.types.includes('floor')) {
                    answers["business.street_address2"] = item.long_name
                } else if (item.types.includes('postal_code')) {
                    answers["business.zip_code"] = item.long_name
                }
            })

            props.changeAnswers({
                ...answers
            })
        }
    }

    const businessDataSearchPreCheck = async (setCache = false) => {
        /*
        Helper function to evaluate if adviser business data is stale.  Parameter setCache
        will update advisorCache in reach redux state when set to true.
         */
        const {answers, unique_session_id, advisorCache, is_from_bulk, is_session_restored} = props
        const businessDataSearch = !!advisorCache?.businessDataSearch ? advisorCache.businessDataSearch : null
        const propertySearch = !!advisorCache?.propertySearch ? advisorCache.propertySearch : null
        const updatedAdvisorCache = {businessDataSearch, propertySearch}

        return await DoRequest({
            url: '/api/advisor-service-pre-check/business-data',
            method: 'post',
            body: {
                businessName: answers['business.name'],
                zipCode: answers['business.zip_code'],
                state: answers['business.state'],
                sessionId: unique_session_id,
                advisorCache: updatedAdvisorCache,
                isFromBulk: is_from_bulk,
                isSessionRestored: is_session_restored,
                appetite: answers['appetite'],
            },
            onSuccess: (result) => {
                const {callAdvisorService, advisorCache} = result.data
                if (setCache) props.updateInsStoreKey('advisorCache', advisorCache)
                return !!callAdvisorService;
            },
            onError: (e) => {
                throw new Error(`Failure calling /api/advisor-service-pre-check/business-data. ${e}`)
            }
        })
    }

    const propertySearchPreCheck = async () => {
        const {answers, advisorCache} = props
        return await DoRequest({
            url: '/api/advisor-service-pre-check/property-search',
            method: 'post',
            body: {
                advisorCache: advisorCache,
                isHomeBased: answers['business.location.home']
            },
            onSuccess: (result) => {
                const {callAdvisorService, advisorCache} = result.data
                if (callAdvisorService) {
                    props.updateInsStoreKey('advisorCache', advisorCache)
                    return true
                } else return false
            },
            onError: (e) => {
                throw new Error(`Failure calling /api/advisor-service-pre-check/property-search. ${e}`)
            }
        })
    }


    const propertySearchRequest = async (answers) => {
        return await DoRequest({
            url: "/api/business/property_search",
            method: "post",
            body: {
                'addressee': answers['business.name'],
                'street_address_1': answers['business.street_address'],
                'street_address_2': answers['business.street_address2'],
                'city': answers['business.city'],
                'state': answers['business.state'],
                'zip_code5': answers['business.zip_code'],
                'use_smarty': true
            },
            onSuccess: (result) => {
                return result
            },
            onError: (e) => {
              throw new Error(`Failure calling /api/business/property_search. ${e}`)
            }
        })
    }

    const clearPropertySearch = () => {
        setAdvisorHomeBusiness(null)
        const advisorCache = {
            businessDataSearch: null,
            propertySearch: null
        }
        props.updateInsStoreKey('advisorCache', advisorCache)
    }

    const allowProceedHandler = async () => {
        let allowed_states = []
        props.coverage_types.forEach(itm => {
            itm.sold_in.forEach(st => {
                allowed_states.push(st)
            })
        })

        allowed_states = uniq(allowed_states)

        if (!includes(allowed_states, props.answers['business.state'])) props.onStateReject()
        else {
            updateWCLocations()
            const advisorServicePreCheckResult = await businessDataSearchPreCheck(true)

            // evaluated as !== false to ensure advisor is called should the preCheck function eval to undefined due to an error.
            // evaluated as !== false to ensure advisor is called should the preCheck function eval to undefined due to an error.
            if (advisorServicePreCheckResult?.data?.callAdvisorService !== false) {
                await searchForBusiness()
            }

            await prepareSlackRequest()
            props.history.push(nextUrl)
        }
    }


    const validateForm = async () => {
        let errorsData = {}
        if (!/^.{2,}$/.test(props.answers["business.name"]))
            errorsData.business_name = t('form.error.bn_req')

        if (!/^.{3,}$/.test(props.answers["business.street_address"]))
            errorsData.street = t('form.error.st_address_req')

        if (!/^.{2,}$/.test(props.answers["business.city"]))
            errorsData.city = t('form.error.city_req')

        if (!/^.{2,}$/.test(props.answers["business.state"]))
            errorsData.state = t('form.error.state_req')

        if (!/^.{4,}$/.test(props.answers["business.zip_code"]))
            errorsData.zip_code = t('form.error.zip_req')

        setErrors(errorsData)

        if (Object.keys(errorsData).length === 0) {
            setProcessing(true)

            let propertySearch;
            const propertySearchPreCheckResult = await propertySearchPreCheck()
            const businessDataSearchPreCheckResult = await businessDataSearchPreCheck()

            if (advisorHomeBusiness !== null) {
                propertySearch = advisorHomeBusiness
            } else if (
                propertySearchPreCheckResult?.data?.callAdvisorService === false &&
                includes(['Yes', 'No'], props.answers["business.location.home"]) === true
            ) {
                propertySearch = props.answers["business.location.home"]
                setAdvisorHomeBusiness(propertySearch)
            } else {
                let request = await propertySearchRequest(props.answers)
                propertySearch = request.data?.home_business ? request.data.home_business : props.answers["business.location.home"]
                setAdvisorHomeBusiness(propertySearch)
            }

            if (
                /*
                We only conduct property search if propertySearch !== props.answers["business.location.home"]
                AND businessDataSearchPreCheckResult is true because a property search would have already
                been checked if business data search from advisor was conducted.
                 */
                propertySearch !== props.answers["business.location.home"]
                && businessDataSearchPreCheckResult?.data?.callAdvisorService !== false
            ) {
                setShowPropertySearchModal(true)
                setProcessing(false)
                return true
            } else {
                return false
            }
        }
    }

    const goToNextSection = async (event) => {
        event.preventDefault()

        await validateForm().then(res => {
            if (res === false) {
                allowProceedHandler().then(() => {
                    setProcessing(false)
                })
            }
        })
    }

    const prepareSlackRequest = async () => {
        if (props.answers['newCustomerSlackRequestSent'] !== true) {
            let payload = {
                answers: props.answers,
                sessionId: props.unique_session_id
            }

            await DoRequest({
                url: "/api/slack/new-customer-alert",
                method: "post",
                body: {...payload},
                onSuccess: () => props.changeAnswers({'newCustomerSlackRequestSent': true})
            })
        }
    }

    const updateWCLocations = () => {
        let locations = [...props.wc_locations]

        //Unset any primary location that already in the array
        locations = locations.map(itm => {
            itm.primary = false
            return itm
        })
        let STEStr = props.answers['business.location.home'] === 'Yes' ? '' : 'STE '

        locations.length > 0
            ? locations[0] = {
                ...locations[0],
                street_address: props.answers["business.street_address"],
                street_address2: props.answers["business.street_address2"] ? `${STEStr}${props.answers["business.street_address2"]}` : '',
                city: props.answers["business.city"],
                state: props.answers["business.state"],
                zip_code: props.answers["business.zip_code"],
                primary: true
            }
            : locations.push({
                ...cloneDeep(props.wc_locations_template),
                street_address: props.answers["business.street_address"],
                street_address2: props.answers["business.street_address2"] ? `${STEStr}${props.answers["business.street_address2"]}` : '',
                city: props.answers["business.city"],
                state: props.answers["business.state"],
                zip_code: props.answers["business.zip_code"],
                primary: true,
                rate_classes: [
                    {...props.wc_rate_classes_template}
                ]
            })
        return props.updateWCLocations(locations)
    }

    const onDisablePrefillAddress = () => {
        setCityQuestion({...cityQuestion, field_disabled: false})
        setStateQuestion({...stateQuestion, field_disabled: false})
        let answers = {
            'is_business_info_myself': true,
            "business.name": '',
            "business.street_address": '',
            "business.street_address2": '',
            "business.state": '',
            "business.city": '',
            "business.zip_code": '',
            "business.place_id": ''
        }
        clearPropertySearch()
        props.changeAnswers({
            ...answers
        })
    }

    return (
        <div className={'business_data_section'}>
            {!!zipQuestion ?
                <form className={'section_container'} autoComplete="off" onSubmit={(e) => goToNextSection(e)}>
                    <SectionHead title={t('page.bn_n_a.title')}
                                 txt={props.answers['is_business_info_myself'] ? 'Confirm or enter the name and primary address of your business below, even if it is your home.' : t('page.bn_n_a.description')}/>

                  <Grid container spacing={2} className={'section_form'}>
                        <Grid item xs={12}>
                            {props.answers['is_business_info_myself'] ?
                                <Question fullWidth
                                          {...businessNameQuestion}
                                          type={businessNameQuestion?.question_type}
                                          options={businessNameQuestion?.options}
                                          required={businessNameQuestion?.required}
                                          field_length={businessNameQuestion?.max_length}
                                          question={businessNameQuestion?.question_text}
                                          answer={props.answers['business.name']}
                                          error={!!errors.business_name}
                                          helperText={errors?.business_name}
                                          onChange={(id, value) => changeHandler('business.name', value)}
                                /> : <><SearchLocationInput
                                    fullWidth
                                    google={props.google}
                                    query={props.answers['business.name']}
                                    question={businessNameQuestion.question_text + '*'}
                                    answer={props.answers['business.name']}
                                    sessionToken={sessionToken}
                                    error={errors?.business_name}
                                    onChange={(e, reason) => changeBusinessName(e, reason)}
                                    onChangeLocation={(value, place, reason) => onChangeLocation(value, place, reason)}
                                    onBlur={() => {
                                        onLocationInputDone()
                                    }}
                                    variant="outlined"/>
                                    {!props.answers['is_business_info_myself'] && <Grid item xs={12}>
                                    <span onClick={onDisablePrefillAddress}
                                          className={'underline_link enter_business_myself'}>Enter Business Info Myself</span>
                                    </Grid>}
                                </>}
                        </Grid>
                        <Grid item xs={12}>
                            {props.answers['is_business_info_myself'] ?
                                <Question fullWidth
                                          {...streetAddressQuestion}
                                          type={streetAddressQuestion?.question_type}
                                          options={streetAddressQuestion?.options}
                                          required={streetAddressQuestion?.required}
                                          field_length={streetAddressQuestion?.max_length}
                                          question={streetAddressQuestion?.question_text}
                                          answer={props.answers['business.street_address']}
                                          error={!!errors.street}
                                          helperText={errors?.street}
                                          onChange={(id, value) => changeHandler('business.street_address', value)}
                                /> : <SearchLocation
                                    fullWidth
                                    {...streetAddressQuestion}
                                    required={streetAddressQuestion?.required}
                                    sessionToken={sessionToken}
                                    field_length={streetAddressQuestion?.max_length}
                                    google={props.google}
                                    question={streetAddressQuestion?.question_text + '*'}
                                    answer={props.answers['business.street_address']}
                                    error={errors?.street}
                                    onChangeLocation={(desc, newValue, reason) => {
                                        onStreetChange(desc, newValue, reason)
                                    }}
                                    onChange={(newValue) => {
                                        changeHandler('business.street_address', newValue)
                                    }}
                                />}
                        </Grid>
                        <Grid item xs={12}>
                            <Question fullWidth
                                      {...streetAddress2Question}
                                      type={streetAddress2Question?.question_type}
                                      options={streetAddress2Question?.options}
                                      required={streetAddress2Question?.required}
                                      field_length={streetAddress2Question?.max_length}
                                      question={streetAddress2Question?.question_text}
                                      answer={props.answers['business.street_address2']}
                                      onChange={(id, value) => changeHandler('business.street_address2', value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Question fullWidth
                                      {...cityQuestion}
                                      field_disabled={cityQuestion?.field_disabled}
                                      type={cityQuestion?.question_type}
                                      options={cityQuestion?.options}
                                      required={cityQuestion?.required}
                                      field_length={cityQuestion?.max_length}
                                      question={cityQuestion?.question_text}
                                      answer={props.answers['business.city']}
                                      error={!!errors.city}
                                      helperText={errors?.city}
                                      onChange={(id, value) => changeHandler('business.city', value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Question fullWidth
                                      {...stateQuestion}
                                      field_disabled={stateQuestion?.field_disabled}
                                      type={stateQuestion?.question_type}
                                      options={stateQuestion?.options}
                                      required={stateQuestion?.required}
                                      field_length={stateQuestion?.max_length}
                                      question={stateQuestion?.question_text}
                                      answer={props.answers['business.state']}
                                      error={!!errors.state}
                                      helperText={errors?.state}
                                      onChange={(id, value) => changeHandler('business.state', value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Question fullWidth
                                      {...zipQuestion}
                                //@TODO set question type on core service
                                // type={zipQuestion?.question_type}
                                      type={'zip'}
                                      options={zipQuestion?.options}
                                      required={zipQuestion?.required}
                                      field_length={zipQuestion?.max_length}
                                      question={zipQuestion?.question_text}
                                      answer={props.answers['business.zip_code']}
                                      variant="outlined"
                                      error={!!errors.zip_code}
                                      helperText={errors?.zip_code}
                                      onChange={(id, value) => changeHandler('business.zip_code', value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Question fullWidth
                                      {...homeQuestion}
                                      type={homeQuestion?.question_type}
                                      options={homeQuestion?.options}
                                      required={homeQuestion?.required}
                                      field_length={homeQuestion?.max_length}
                                      question={homeQuestion?.question_text}
                                      answer={props.answers['business.location.home']}
                                      variant="outlined"
                                      onChange={(id, value) => changeHandler('business.location.home', value)}/>

                            <HomeBasedBusinessModal
                                onHomeBusinessSet={(value) => changeHandler('business.location.home', value)}
                                onClose={() => {
                                    allowProceedHandler().then(() => setShowPropertySearchModal(false))
                                }}
                                businessName={props.answers['business.name']}
                                advisorHomeBusiness={advisorHomeBusiness}
                                open={showPropertySearchModal}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Question fullWidth
                                      {...locationMoreThanOneQuestion}
                                      type={locationMoreThanOneQuestion?.question_type}
                                      options={locationMoreThanOneQuestion?.options}
                                      required={locationMoreThanOneQuestion?.required}
                                      field_length={locationMoreThanOneQuestion?.max_length}
                                      question={locationMoreThanOneQuestion?.question_text}
                                      answer={props.answers['business.locations'] === 'Multi' ? 'Yes' : 'No'}
                                      variant="outlined"
                                      onChange={(_, value) => changeLocationsHandler(value)}/>
                        </Grid>
                        <div id="details" ref={details_ref}/>
                    </Grid>
                    <Grid container className={'section_navigation'}>
                        {processing ? (
                            <LoadingButton/>
                        ) : (<>
                                <NextSectionButton
                                    validateFormMethod={(e) => goToNextSection(e)}
                                    className="section_next_btn"/>
                                <NextSectionButton
                                    validateFormMethod={(e) => goToNextSection(e)}
                                    className="section_next_btn__mobile"/>
                            </>
                        )}
                    </Grid>
                </form>
                : <></>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    unique_session_id: state.insurance.unique_session_id,
    google_api_key: state.app.google_api_key,
    answers: state.insurance.answers,
    subsections: state.insurance.subsections,
    coverage_types: state.insurance.coverage_types,
    subsections_questions: state.insurance.subsections_questions_data,
    wc_locations: state.insurance.wc_locations,
    wc_locations_template: state.insurance.wc_locations_template,
    wc_rate_classes_template: state.insurance.wc_rate_classes_template,
    is_from_bulk: state.insurance.is_from_bulk,
    is_session_restored: state.insurance.is_session_restored,
    is_choose_naics_manually: state.insurance.is_choose_naics_manually,
    advisorCache: state.insurance.advisorCache,
    isPartner: state.insurance.isPartner,
    partnerData: state.insurance.partnerData
})

const mapDispatchToProps = {
    changeAnswers,
    updateProgress,
    updateInsStoreKey,
    updateIndustryClasses,
    updateWCIndustryClasses,
    placeDefaultsAnswers,
    updateWCLocations,
    updateQuestionsAutofill,
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper(
    (props) => {
        return {
            apiKey: props.google_api_key,
            language: 'us',
            libraries: ['places'],
            region: 'US',
            LoadingContainer: Loading
        }
    })(withTranslation()(BusinessData)));