import React, { Component } from 'react'
import { formatCurrency } from '../../../helpers/currency'
import { getInsuranceCarrier } from '../../../helpers/GetInsuranceCarrier'

class CardCommon extends Component {
  state = {
    details: false,
    customize_quote: this.props.quote,
    customize_index: this.props.quote_index,
    carrierLogoUrl:'',
    isAscendIntegration: false
  }

  componentDidMount() {
    let providerData = getInsuranceCarrier(this.props.answers, this.props.quote.quote_type)
    this.setState({
      isAscendIntegration: (process.env.REACT_APP_DOUBLOON_ENABLED || window.doubloon_enabled) && this.props.isAscendIntegration,
      carrierLogoUrl: providerData?.logo ? `https://b2z-public-images.s3.amazonaws.com/acrisure/carrier_logos/${providerData?.logo}.png`: null,
    })
  }
  openQuoteDetails () {
    this.setState({ details: true })
  }

  closeQuoteDetails () {
    this.setState({ details: false })
  }
  getPolicyCarrier(){
    return this.state.carrierLogoUrl? <div className={'fq_carrier_logo'}><img src={this.state.carrierLogoUrl} alt={''}/> </div>:null
  }
  getPolicyPrice () {
    let period = this.state.isAscendIntegration ? 1 : (this.props.period === 'Monthly') ? 12 : 1
    let feeDescription = (this.props.period === 'Monthly' && this.props.quote.source === 'COTERIE') ? `<span>The rate above does not include additional Coterie fees. Coterie will charge monthly fees upon each transaction and a one-time policy fee.</span>` : `<span>The rate above does not include additional Coterie fees. Coterie will charge a one-time policy fee.</span>`
    let additionalDescription = `<span>${this.props.quote.source === 'COALITION' ? 'Monthly payment' : 'Payment'} options available.</span>`;
    let total = (period === 1)
      ? parseFloat(this.props.quote.payment_formats[0].installments[0].due_amount)
      : parseFloat(this.props.quote.payment_formats[1].installments[1].due_amount)

    let price = this.state.isAscendIntegration ? total : formatCurrency(total, true)

    return <>
      <div className={'policy_total_blk'}>
        <span className="price_value">
          {this.props.quote.source === 'COALITION' && '$'}
          {price}
        </span>
        {this.props.quote.source === 'COTERIE' ? <span className={'additional_charges_mark'}>*</span> : null}
          <small className="interval_modifier">/{(period === 1) ? this.props.t('common.year_short') : this.props.t('common.month_short')}</small>
      </div>
      {this.props.quote && this.props.quote.source === 'COTERIE' && <div className={'policy_fee_blk'}><p className="fee_txt" dangerouslySetInnerHTML={{ __html: feeDescription }}/></div>}
      {this.props.quote && this.state.isAscendIntegration && <div className={'policy_additional_notify_blk'}><p className="additional_txt" dangerouslySetInnerHTML={{ __html: additionalDescription }}/></div>}
    </>
  }

  render () {
    return null
  }
}

export default CardCommon