import React from 'react';

export const Success = (props) => {
  return (
    <svg className={props.className} width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="45" cy="45" r="45" fill="#3FAA6C"/>
      <path d="M64.2857 33.752L37.7678 60.2698L25.7142 48.2162" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
