import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {AutofilledBadge} from "./AutofilledBadge";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {CircularProgress, FormControl, TextField} from "@material-ui/core";
import {matchSorter} from "match-sorter";

const Typeahead = (props) => {
    const [options, setOptions] = useState(props.options)

    useEffect(() => {
        if (props.placeholder) {
            setOptions([{text: props.placeholder}].concat(props.options))
        } else setOptions(props.options)
    }, [props.placeholder, props.options])

    useEffect(() => {
        setOptions(props.options)
    }, [options, props.options])

    const onChange = (e, text, value) => {
        if (props.onChange) props.onChange(props.id, text, value)
    }

    const onInputChange = (e, value) => {
        if (props.onInputChange) props.onInputChange(e, value);
    }

    const getValue = () => {
        if (!options.length) return null;
        if (typeof options[0] === 'string') return props.answer;
        const value = options.find(i => i.value === props.answer);
        return value ? value.text : null;
    }
    const renderOption = (option, {inputValue}) => {
        const matches = match(option.text, inputValue, {insideWords: true});
        const parts = parse(option.text, matches);

        return (
            <div>
                {parts.map((part, index) => (
                    <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                    {part.text}
                                  </span>
                ))}
            </div>
        );

    }

    const filterOptions = (options, {inputValue}) => {
        return matchSorter(options, inputValue, {
            keys: ['text'],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
        })
    }

    return (
        <FormControl fullWidth={props.fullWidth}
                     className={'form_control autocomplete_control'}
                     error={props.error}>
            {props.question && (
                <div className="label_blk">
                    <div className="label">{props.question} &nbsp;{props.tooltip} </div>
                    {props.autofilled && <AutofilledBadge/>}
                </div>
            )}
            <Autocomplete
                renderInput={(params) =>
                    <TextField id={'question-' + props.id + '-select'} {...params}
                               error={!!props.error}
                               autoFocus={props.autoFocus? props.autoFocus: false}
                               className={'fieldDropdown'}
                               placeholder={props.placeholder || null}
                               variant="outlined"
                               InputProps={{
                                   ...params.InputProps,
                                   autocomplete: "location-search",
                                   endAdornment: (
                                       <React.Fragment>
                                           {props.loading ? <CircularProgress color="inherit" style={{
                                               position: 'absolute',
                                               right: '60px'
                                           }} size={20}/> : null}
                                           {params.InputProps.endAdornment}
                                       </React.Fragment>
                                   )
                               }}
                    />
                }
                options={[getValue(), ...props.options]}
                renderOption={props.renderOption ? renderOption : null}
                filterOptions={filterOptions}
                groupBy={(option) => option.firstLetter}
                openOnFocus={true}
                // open={true}
                getOptionLabel={(option) => {
                    return (typeof option === 'string' ? option : option.text)
                }}
                getOptionSelected={(option, value) => option.hasOwnProperty('value') ? option.value === value?.value : option.text === value?.text}
                noOptionsText={props.noOptionsText}
                getOptionDisabled={(option) => {
                    return props.placeholder && option.text === props.placeholder
                }}
                value={getValue()}
                onInputChange={(e, value) => onInputChange(e, value)}
                onChange={(e, value) => {
                    onChange(e, value?.text, value?.value)
                }}
                onOpen={() => {
                    if (props.onOpen) props.onOpen(true)
                }}
                onClose={() => {
                    if (props.onOpen) props.onOpen(false)
                }}
            />
            {props.error && <p className="error_txt error_txt_md">{props.helperText}</p>}
        </FormControl>
    )
}

export default withTranslation()(Typeahead)
