import React, {useState} from 'react';
import {Collapse} from "@material-ui/core";
import {Chevron} from "../../icons/Chevron";

const ClaimFAQItem = (props) => {
    const [collapse, setCollapse] = useState(false)

    return (<div className={'claim_faq_item'}>
        <div className={`faq_ttl ${collapse ? 'collapse_faq': ''}`} onClick={() => setCollapse(!collapse)}>
            <span className={'faq_label'}>{props.question}</span>
            <span className={'icon_arrow'}><Chevron/></span>
        </div>
        <Collapse in={collapse}>
            <div className="faq_text"
                dangerouslySetInnerHTML={{__html: props.answer}}
            />
        </Collapse>
    </div>)
}

export default ClaimFAQItem;