import React from "react";
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, TextField} from "@material-ui/core";
import sources_update from '../../../common/decorator'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'

class Text extends React.Component {

    onChange(e) {
        if (this.props.onChange) {
            if (this.props.field_length) {
                if (this.props.field_length >= e.target.value.length)
                    this.props.onChange(this.props.id, e.target.value)
            } else this.props.onChange(this.props.id, e.target.value)
        }
        sources_update(this.props.answers, this.props.qid)
    }

    render() {
        if (this.props.field_length && this.props.field_length > 255)
            //return textarea field
            return (
                <FormControl fullWidth={this.props.fullWidth} error={this.props.error}
                             className={'form_control textarea_control'}>
                    <div className="label_blk">
                        <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                        <div className="textarea_length">{this.props.answer.length} / {this.props.field_length}</div>
                    </div>
                    <TextField
                        className={'fieldText'}
                        variant="outlined"
                        type="text"
                        multiline
                        rowsMin={3}
                        value={this.props.answer}
                        error={!!this.props.error}
                        placeholder={this.props.placeholder}
                        onChange={(e) => this.onChange(e)}
                    />
                    {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
                </FormControl>
            )
        //return text field instead
        else
            return (
                <FormControl fullWidth={this.props.fullWidth} error={this.props.error} className={'form_control'}>
                    <div className="label_blk">
                        <div className="label">{this.props.question} &nbsp;{this.props.tooltip} </div>
                        {this.props.autofilled && <AutofilledBadge/>}
                    </div>
                    <TextField
                        className={'fieldText'}
                        variant="outlined"
                        type="text"
                        disabled={!!this.props.field_disabled}
                        value={this.props.answer}
                        error={!!this.props.error}
                        placeholder={this.props.placeholder}
                        autoComplete={this.props.autoComplete}
                        name={'name' + this.props.id}
                        onChange={(e) => this.onChange(e)}
                    />
                    {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
                </FormControl>
            )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
})
  
export default connect(mapStateToProps)(withTranslation()(Text))