import _, {fromPairs, some} from 'lodash'
import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import B2ZLoading from '../../components/B2ZLoading'
import { addPaymentMethodAuth } from '../../services/carrierService'
import AscendCheckout from './AscendCheckout'
import Checkout from './Checkout'
import { changeAnswers, updateInsStoreKey, updateProgress } from '../../store/insurance/actions'

//scss
import '../../scss/v2/_checkout.scss'
import {datadogRum} from "@datadog/browser-rum";
import {toCent} from "../../hooks/get-premium-total";

class PaymentInformation extends React.Component {

  state = {
    payment_integrations: [],
    processing: true
  }

  constructor (props) {
    super(props)

    this.props.updateProgress({
      current_section: this.props.section.section_index,
      current_subsection: this.props.next_subsection - 1
    })
  }

  componentDidMount () {
    if (this.props.isSessionRestored) {
      this.getFullQuote().then(res => {
        let payment_integrations = this.checkPaymentIntegration(res)
        this.setState({ payment_integrations: payment_integrations, processing: false })
      })
    } else {

      let payment_integrations = this.checkPaymentIntegration(this.props.answers['selected_quotes'])
      this.setState({ payment_integrations: payment_integrations, processing: false })
    }

    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  transformQuoteResponse (quote) {
    return (!!quote?.error) ? {
      error: quote.error,
      error_code: quote.error_code,
      quote_type: quote.type
    } : {
      ...quote.data,
      quote_type: quote.type,
      ...addPaymentMethodAuth(quote)
    }
  }

  async getFullQuote () {

    return axios.post('/api/get-bindable-quote', { agent: true, answers: this.props.answers }).then((data) => {
      let quotes = data.data.map(itm => this.transformQuoteResponse(itm))
      this.props.updateInsStoreKey('full_quote', quotes)
      this.props.updateInsStoreKey('quotes', quotes)

      if(some(quotes, ['quote_type', "CYBER"])){
        this.getCyberEstimated(quotes)
      }else{
        this.props.updateInsStoreKey("estimatedPremium", null)
      }

      return quotes

    }).catch(e => {
      datadogRum.addError(`Can't get bindable quote. ${e}`)
      return []
    })
  }

  getCyberEstimated = async (selected_quotes)=>{
    let cyberQuote = selected_quotes.find(item=>item.quote_type === "CYBER")
    const premiumCents = toCent(cyberQuote.term_premium)

    axios.post('/api/ascend-finance-estimate', {premium_cents: premiumCents}).then(response => {
      let estimatedPayload = fromPairs(response.data)
      this.props.updateInsStoreKey("estimatedPremium", estimatedPayload)
    }).catch(e=>{
      this.props.updateInsStoreKey("estimatedPremium", null)
      throw new Error(e)
    })
  }

  checkPaymentIntegration (selectedQuotes) {
    let payment_integrations = []
    selectedQuotes.forEach(q => {
      if ('payment_integrations' in q) {
        payment_integrations.push(q.payment_integrations)
      }
    })
    return _.flattenDeep(payment_integrations)
  }

  onSuccess () {
    this.endCurrentSession()
  }

  endCurrentSession () {
    let upd_answers = {
      ...this.props.answers,
      'session_complete': true,
      'quote_status': 'Full Quote'
    }

    this.postAnswers(upd_answers)
      .finally(() => {
        //update answers in local store
        this.props.changeAnswers(upd_answers)
        let nextUrl = '/get-insurance' + window.getNextSubSectionPath(this.props.subsections, this.props.next_subsection)
        this.props.history.push(nextUrl)
      })
  }

  async postAnswers (answers) {
    return axios.post('/api/post-answers', {
      session_id: this.props.unique_session_id,
      answers: answers
    }).catch(e => {
        throw new Error(`Failed to post answers. ${e}`)
    })
  }

  render () {
    return (this.state.processing ? <B2ZLoading
          quoteType={'FQ'}
          loading_string={['Finalizing your paperwork...']}/> :
        this.state.payment_integrations.includes('ASCEND') && (process.env.REACT_APP_DOUBLOON_ENABLED || (window.doubloon_enabled && !window.doubloon_enabled.includes('doubloon_enabled')))
          ? <AscendCheckout sessionId={this.props.unique_session_id}/>
          : <ElementsConsumer>
            {({ stripe, elements }) => (
              <Checkout stripe={stripe}
                        elements={elements}
                        history={this.props.history}
                        onSuccess={() => {
                          this.onSuccess()
                        }}
              />
            )}
          </ElementsConsumer>
    )
  }
}

const mapStateToProps = state => {
  return {
    answers: state.insurance.answers,
    subsections: state.insurance.subsections,
    unique_session_id: state.insurance.unique_session_id,
    is_new_user: state.insurance.is_new_user,
    isSessionRestored: state.insurance.is_session_restored
  }
}

const mapDispatchToProps = {
  updateProgress,
  changeAnswers,
  updateInsStoreKey
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInformation)
