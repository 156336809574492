import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, {useState} from 'react'
import {Close} from '@material-ui/icons'
import {withTranslation} from 'react-i18next'
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    useTheme
} from '@material-ui/core'
import {ModalTransition} from '../../common/ModalTransition'

const TermsAndConditions = ({
    t,
    open,
    onClose,
    onAccept
}: {
    t: any,
    open: boolean,
    onClose: () => void,
    onAccept: () => void,
}) => {
    const [readInFull, setReadInFull] = useState(false)

    const checkIfEndIsShown = (el: EventTarget & HTMLDivElement) => {
        if (!el) return
        if (el.scrollHeight - el.scrollTop === el.clientHeight) setReadInFull(true)
    }

    const checkIfReadInFull = (e: React.UIEvent<HTMLDivElement>) => {
        if (readInFull) return
        checkIfEndIsShown(e.currentTarget)
    }

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    return (


        <Dialog open={open}
            fullScreen={fullScreen}
            maxWidth="md"
            container={() => document.getElementById('themeAppComponent')}
            className={'modal__wrapper terms_modal__wrapper'}
            onClose={onClose}
            TransitionComponent={ModalTransition}>
            <DialogActions className={'modal_head'}>
                <button className={'modal_close_btn show_scroll_paper'}
                    type={'button'}
                    onClick={onClose}><Close/>
                    <span>{t('common.btn_cancel')}</span></button>
            </DialogActions>

            <DialogContent onScroll={e => checkIfReadInFull(e)}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <div className="terms_info"
                            dangerouslySetInnerHTML={{__html: t('page.checkout.payment_terms_txt', {returnObjects: true})}}/>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Grid container className={'dialog_actions'}>
                        <button className={'a_btn a_btn_action_1 btn_accept'}
                            type={'button'}
                            onClick={onAccept}
                        >{t('common.btn_accept')}</button>
                    </Grid>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )

}

export default withTranslation()(TermsAndConditions)
