import React from 'react'
import {
    Dialog,
    DialogContent,
    useTheme,
    Grid
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {ModalTransition} from '../ModalTransition'
import '../../scss/v2/modals/_not_allow_proceeding_notify.scss'

interface NotifyModel {
    title?: string
    description?: string
}
const NotAllowProceedingNotify = ({
    open,
    notifyContent,
}: {
    open: boolean,
    notifyContent?: NotifyModel
    partnerData?: any
}) => {

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            container={() => document.getElementById('themeAppComponent')}
            className={'modal__wrapper not_allow_proceeding_notify__wrapper'}
            maxWidth="sm"
            scroll={'paper'}
            fullWidth
            TransitionComponent={ModalTransition}>

            <DialogContent className={'app_modals_cnt'}>
                <Grid container spacing={2} className={'modal_cnt'}>
                    <Grid item className={'modal_content'}>
                        {notifyContent?.title && <p className={'modal_ttl'}>{notifyContent.title}</p>}
                        {notifyContent?.description
                            ? <div className={'modal_sub_ttl'} dangerouslySetInnerHTML={{__html: notifyContent?.description}}></div>
                            : <p className={'modal_sub_ttl'}>Thank you! Our customer experience team will reach out directly to help you with your quote.</p> }
                    </Grid>
                    <Grid item xs={12}>
                        <a href='tel:800-330-1750' className={'a_btn a_btn_link a_btn_action_1 btn__close'}>Call 800-330-1750</a>
                        <a href='mailto:cl_sales@acrisure.com' className={'a_btn a_btn_link a_btn_action_4 btn__close'}>Email Us</a>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default NotAllowProceedingNotify