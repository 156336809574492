import objReducer from "../helpers/objReducer";
import {formatCurrency} from "../helpers/currency";
import {updateInsStoreKey} from "../store/insurance/actions";
import {connect} from "react-redux";

export const calculatePremiumTotal = (props, lob) => {

    const {quotes, answers} = props

    let premiumTotal     = 0,
        selected_ocs     = [],
        oc_premium_total = 0,
        oc_total         = 0,
        policy           = quotes.filter(q => q.line_of_business === lob.toUpperCase())[0]

    const {term_premium} = policy

    try {
        const oc = policy?.optional_coverages.filter(c => c.coverage_name !== 'tria')
        oc_premium_total = objReducer(oc, 'term_premium')

        if (lob === 'bop') {
            if (answers['cyber.coverage.is_selected'] === 'Yes') selected_ocs.push(policy?.optional_coverages.filter(c => c.coverage_name === 'cyber'))
            if (answers['mpl.coverages.is_selected'] === 'Yes') selected_ocs.push(policy?.optional_coverages.filter(c => c.coverage_name === 'mpl'))
            selected_ocs.forEach(el => {
                oc_total += el[0].term_premium
            })
        }

        premiumTotal = term_premium - oc_premium_total + oc_total

    } catch (e) {
      throw new Error(`Something went wrong while calculate premium total. ${e}`)
    }

    return premiumTotal
}

export const GetPremiumTotal = (props) => {
    /**
     * Component intended to render the policy premium total and update the premium total within
     * React Redux state based on policy type via props.updateInsStoreKey.  The props.updateInsStoreKey
     * is called within setTimeout to redirect additional React Redux state updates to the callback queue
     * in instances where multiple policy types are selected at same time thus causing multiple calls to
     * this function.
     */
    try {
        const total = calculatePremiumTotal(props.props, props.lob)
        setTimeout(() => {
            props.updateInsStoreKey(`${props.lob}_premium_total`, total)
        }, 0)
        return formatCurrency(total / 12, true)
    } catch (e) {
      throw new Error(`Something went wrong while get premium total. ${e}`)
    }
}

export const toCent = amount => {
    const str = amount.toString()
    const int = str.split('.')

    return Number(amount.toFixed(2).replace('.', '').padEnd(int.length === 1 ? 3 : 4, '0'))
}

const mapDispatchToProps = {
    updateInsStoreKey
}

export default connect(null, mapDispatchToProps)(GetPremiumTotal)