import {checkURLParam} from './CheckURLParam'

const CheckStartPoint = (key) => {
    let value = null
    if (window.location.search) {
        // @ts-ignore
        value = checkURLParam(key)
    }
    return value
}

export {CheckStartPoint as checkStartPoint}