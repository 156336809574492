

export function paymentAddressReducer(answer, action) {
    let {type, data} = action
    switch (type) {
        case "SET_SAVED_PAYMENT_ADDRESSES":
            return data.data.filter(itm => itm.object === 'card')
        case "GET_SAVED_PAYMENT_ADDRESS":
            let {paymentId, saved_payment_methods_addresses, updateAnswer} = action
            let address         = saved_payment_methods_addresses.filter(itm => itm.id === paymentId),
                card_addresses  = address.filter(itm => itm.object === 'card'),
                billing_address = {
                    'billing.street_address': '',
                    'billing.street_address2': '',
                    'billing.city': '',
                    'billing.state': '',
                    'billing.zip_code': ''
                }

            if (card_addresses.length > 0) {
                billing_address['billing.street_address'] = card_addresses[0].address_line1
                billing_address['billing.street_address2'] = card_addresses[0].address_line2
                billing_address['billing.city'] = card_addresses[0].address_city
                billing_address['billing.state'] = card_addresses[0].address_state
                billing_address['billing.zip_code'] = card_addresses[0].address_zip
            }

            for (let key in billing_address) {
                updateAnswer(key, billing_address[key])
            }

            return billing_address
        default:
            return data
    }
}