import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import axios from 'axios'
import {fromPairs, intersection, some} from 'lodash'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import NextSectionButton from '../../components/form-components/NextSectionButton'
import Question from '../../components/form-components/Question'
import { Update } from '../../components/icons/Update'
import CustomizePL from '../../components/insurance/CustomizePL'
import CustomizeRateBOP from '../../components/insurance/customizeRate/CustomizeRateBOP'
import CustomizeRateCYBER from '../../components/insurance/customizeRate/CustomizeRateCYBER'
import CustomizeRateGL from '../../components/insurance/customizeRate/CustomizeRateGL'
import CustomizeWC from '../../components/insurance/CustomizeWC'
import SectionHead from '../../components/insurance/SectionHead'
import LoadingButton from '../../components/LoadingButton'
import { currency, formatCurrency } from '../../helpers/currency'
import { trimBusinessName } from '../../helpers/trimBusinessName'

//scss
import '../../scss/v2/_customize_rate_cards.scss'
import {
  changeAnswers,
  updateInsStoreKey,
  updateProgress,
  updateUnderwriterStatements
} from '../../store/insurance/actions'
import {toCent} from "../../hooks/get-premium-total";

class CustomizeYourRate extends Component {
  state = {
    nextUrl: '/results',
    loading: false,
    headTitle: '',
    headTxt: '',
    total_price: 0,
    ocCyberKeys: [],
    cyberOptionalCoverages: [],
    optional_coverages: [],
    oc_keys: [
      'policy.tria_accepted',
      'cyber.coverage.is_selected',
      'hnoa.coverage.is_selected',
      'mpl.coverages.is_selected',
      'eb.coverage.is_selected'
    ],
    data_changed: false,
    updateNeeded: false,
    underwriterStatementsError: false,
    cyberPremium: null,
    isEstimated: false,
    isEstimatedLoading: false
  }

  constructor (props) {
    super(props)

    this.state.nextUrl = '/get-insurance' + window.getNextSubSectionPath(this.props.subsections, this.props.next_subsection)
    this.state.optional_coverages = this.props.subsections_questions_data.filter(q => this.state.oc_keys.includes(q.qid))

    // let cyberGroupedOc = groupBy(this.props.answers['selected_quotes'].find(itm => itm.quote_type === 'CYBER')?.optional_coverages, 'group')

    // let groupedCyberOc = {}
    // Object.keys(cyberGroupedOc).forEach(item => {
    //   let keys = cyberGroupedOc[`${item}`].map(oc => oc.qid)
    //   groupedCyberOc[`${item}`] = this.props.subsections_questions_data.filter(q => keys.includes(q.qid))
    // })
    // this.state.cyberOptionalCoverages = groupedCyberOc

    this.props.updateProgress({
      current_section: this.props.section.section_index,
      current_subsection: this.props.next_subsection - 1
    })
  }

  componentDidMount () {
    this.setState({
      headTitle: this.props.t('page.cq.title', { name: trimBusinessName(this.props.answers['contact.first_name']) }),
      headTxt: this.props.t('page.cq.txt')
    })

    if(some(this.props.answers['selected_quotes'], ['quote_type', "CYBER"]) && this.props.estimatedPremium !== null) {
      this.setState({cyberPremium: this.props.estimatedPremium.term_payment_cents/100, isEstimated: true})
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  dataChanged () {
    this.setState({ data_changed: true, updateNeeded: true })
  }

  prepareQuoteObj (quote, type) {
    return (quote?.error)
      ? {
        error: true,
        error_code: quote.error_code,
        quote_type: quote.type
      }
      : {
        ...quote.data,
        quote_type: type
      }
  }

  getQuickQuote () {
    this.setState({ loading: true, isEstimatedLoading: true })
    this.props.updateInsStoreKey('is_answers_changed', false)

    let answers = { ...this.props.answers }
    let STEStr = answers['business.location.home'] === 'Yes' ? '' : 'STE '

    answers['policy.tria_accepted'] = 'Yes'
    answers['cyber.coverage.is_selected'] = 'Yes'
    answers['mpl.coverages.is_selected'] = 'Yes'
    answers['hnoa.coverage.is_selected'] = 'Yes'
    answers['business.street_address2'] = answers['business.street_address2']?.length > 0 ?
      `${STEStr}${answers['business.street_address2']}` : answers['business.street_address2']

    axios.post('/api/quick_quote', {
      answers: answers
    }).then(
      response => {
        if (response.data && response.data.length > 0) {
          let quotes = response.data.map(itm => {
            return this.prepareQuoteObj(itm, itm.type)
          })
          this.props.updateInsStoreKey('quotes', quotes)

          if (quotes instanceof Array) this.setMultiSelected(response.data)

          let errors = quotes.filter(itm => !!itm?.error)
          if (errors.length === quotes.length) {
            this.props.onReject(quotes)
            this.setState({ loading: false, updateNeeded: false, isEstimatedLoading: false })
          } else {
            this.setState({ loading: false, updateNeeded: false, isEstimatedLoading: false })
            this.props.changeAnswers({
              'quote_status': 'Quick Quote'
            })
          }
          if(some(quotes, ['quote_type', "CYBER"])){
            this.getCyberEstimated(quotes)
          }
        } else this.onReject()
      }
    ).finally(() => {
      document.dispatchEvent(new CustomEvent('total-changed'))
    })
      .catch(e => {
        this.onReject()
        throw new Error(`Can't get quote while customizing rate. ${e}`)
      })
  }

  setMultiSelected (items) {
    let quotes = []
    let products = []

    items.forEach(itm => {
      if (!itm?.error && !products.includes(itm.type)) {
        let quote = this.prepareQuoteObj(itm, itm.type)
        quotes.push(quote)
        products.push(itm.type)
      }
    })

    this.props.changeAnswers({
      'selected_quotes': quotes
    })

    document.dispatchEvent(new CustomEvent('quotes-changed', {
      detail: quotes
    }))

    if (quotes.length > 0) this.updateSections(quotes)
    this.setState({ data_changed: false })
  }

  updateSections (quotes) {
    //Update next sections and questions accordingly to selected quotes
    let lob_list = quotes.map(q => q.quote_type)

    if (lob_list.length > 0) {
      //make next subsections skipped next to current subsection if not applicable
      let subsections = this.props.subsections.map((s, idx) => {
        if (idx > this.props.current_subsection) {
          if (s.lobs.includes('COMMON')) return s

          let intersect = intersection(s.lobs, lob_list)
          s.skipped = !(intersect.length === lob_list.length)
        }

        return s
      })

      this.props.updateInsStoreKey('subsections', subsections)
    }
  }

  onReject () {
    this.props.onReject(null, this.props.answers['coverage.types'])

    this.setState({
      loading: false
    })
  }
  getUSTitle(carrierName = null){
    switch (carrierName) {
      case 'CNA':
        return this.props.t('page.underwriterStatements.titleCNA')
      case 'COALITION':
        return this.props.t('page.underwriterStatements.titleCOALITION')
      default :
        return this.props.t('page.underwriterStatements.title')
    }
  }
  getCarriersUS(underwriterResponse){
    let list = []
    underwriterResponse.forEach(item=>{
      if(item?.statements.length > 0){
        list.push(item)
      }
    })
    return list
  }
  async proceedNext () {
    this.setState({ loading: true })
    let underwriterResponse = await this.getUnderwriterStatements()

    if (underwriterResponse) {
      let carriersUSList = this.getCarriersUS(underwriterResponse?.data?.carrier_statements)
      let underwriterTitle = carriersUSList.length === 0? '' : carriersUSList.length > 1 ? this.getUSTitle(): this.getUSTitle(carriersUSList[0].carrier_name)
      let underwriterResponseFlattened = this.flattenedObject(carriersUSList)

      this.props.updateUnderwriterStatements({title: underwriterTitle, statementsList: underwriterResponseFlattened})

    } else this.props.updateUnderwriterStatements('error')

    this.setState({ loading: false }, () => {
      setTimeout(() => {
        if (!this.state.underwriterStatementsError) this.props.history.push(this.state.nextUrl)
        else this.onReject()
      }, 0)
    })
  }

  goBack () {
    let index = this.props.current_subsection - 1

    if (this.props.subsections.length > 0 && index >= 0 && this.props.subsections[index]) {
      let path = '/get-insurance' + window.getPrevSubSectionPath(this.props.subsections, index)
      this.props.history.push(path)
    } else this.props.history.goBack()
  }

  getOCKeyFromMap (key) {
    switch (key) {
      case 'cyber.coverage.is_selected':
        return 'cyber'
      case 'mpl.coverages.is_selected':
        return 'mpl'
      case 'hnoa.coverage.is_selected':
        return 'hnoa'
      default :
        return null
    }
  }

  getOCAnswerFromMap (key) {
    switch (key) {
      case 'cyber':
        return 'cyber.coverage.is_selected'
      case 'mpl':
        return 'mpl.coverages.is_selected'
      case 'hnoa':
        return 'hnoa.coverage.is_selected'
      default :
        return null
    }
  }

  checkIfLimitIsAvailable (key) {
    const oc_key = this.getOCKeyFromMap(key)
    let status = false

    if (oc_key) {
      this.props.answers['selected_quotes'].forEach(q => {
        const oc = q.optional_coverages.find(oc => oc.coverage_name === oc_key)
        if (oc && oc.term_premium > 0) status = true
      })
    }

    return status
  }

  getOCPrice (key) {
    let quote = this.props.answers['selected_quotes'].find(itm => itm.quote_type === 'BOP')
    if (!quote) return 0
    let oc_item = quote.optional_coverages.find(itm => itm.coverage_name === this.getOCKeyFromMap(key))
    if (oc_item) return oc_item.term_premium

    return 0
  }

  getExcludedOCPrice (oc_list = []) {
    let total = 0

    oc_list.forEach(oc => {
      let answer = this.getOCAnswerFromMap(oc.coverage_name)
      if (answer && this.props.answers[answer] === 'No') {
        total += oc?.term_premium || 0
      }
    })

    if (isNaN(total?.toString())) throw new Error(`Invalid value returned for term_premium: ${total}`)

    return total
  }

  isCarrierCoalition() {
    return this.props.answers['selected_quotes'].every(q => q?.source === 'COALITION');
  }
  
  getTotalPrice () {
    let total = 0

    this.props.answers['selected_quotes'].forEach(q => {
      if (q?.term_premium) total += this.getPolicyPrice(q)
    })

    if (isNaN(total?.toString())) throw new Error(`Invalid value returned for term_premium: ${total}`)

    return total
  }

  getCyberEstimated = async (selected_quotes)=>{

    this.setState({ data_changed: true, isEstimatedLoading: true})
    let cyberQuote = selected_quotes.find(item=>item.quote_type === "CYBER")
    const premiumCents = toCent(this.getPolicyPrice(cyberQuote))

    axios.post('/api/ascend-finance-estimate', {premium_cents: premiumCents}).then(response => {
      let estimatedPayload = fromPairs(response.data)
      this.setState({cyberPremium: estimatedPayload.term_payment_cents/100, isEstimated: true, loading:false, isEstimatedLoading: false, data_changed: false})
      this.props.updateInsStoreKey("estimatedPremium", estimatedPayload)
    }).catch(e=>{
      this.setState({ isEstimatedLoading:false, isEstimated: false, data_changed: false})
      this.props.updateInsStoreKey("estimatedPremium", null)
      throw new Error(e)
    })
  }
  getPolicyPrice (policy) {
    let total = policy.term_premium
    total -= this.getExcludedOCPrice(policy.optional_coverages)

    if (isNaN(total?.toString())) throw new Error(`Invalid value returned for term_premium: ${total}`)

    return total
  }

  flattenedObject (objArr) {
    let flattened = []

    objArr.forEach(carrier => {
      carrier.statements.forEach(statement => {
        let description
        if (statement.includes(':')) {
          // eslint-disable-next-line no-unused-vars
          let [, ...description] = statement.split(':')
          description = description.join(':')
        } else {
          description = statement
        }
        flattened.push(description)
      })
    })

    if (flattened.length > 0) return [...new Set(flattened)]
    else return flattened
  }

  async getUnderwriterStatements () {
    this.setState({ underwriterStatementsError: false })
    const config = {
      method: 'post',
      url: '/api/get-underwriting-statements',
      data: {
        session_id: this.props.session_id
      }
    }

    let underwriterStatements
    try {
      underwriterStatements = await axios(config)
    } catch (e) {
      this.setState({ underwriterStatementsError: true })
      this.setState({
        loading: false
      })
      throw new Error(`Error fetching underwriter statements. ${e}`)
    }
    return underwriterStatements
  }

  render () {
    const { t } = this.props
    return (
      <div className={'customize_your_rate_section'}>

        <div className={'section_container'}>
          <SectionHead title={this.state.headTitle}
                       txt={this.state.headTxt}/>
          <Grid container spacing={2} className={'section_form'}>
            {this.props.answers['selected_quotes'].map((item, index) => {
                if (item?.error) return null
                else return (
                  <Grid item xs={12} key={index}>
                    {item.quote_type === 'WC' ? (
                      <CustomizeWC term_premium={this.getPolicyPrice(item)} quote_idx={index}
                                   onChange={this.dataChanged.bind(this)}/>
                    ) : item.quote_type === 'BOP' ? (
                      <CustomizeRateBOP term_premium={this.getPolicyPrice(item)} quote_idx={index}
                                        onChange={this.dataChanged.bind(this)}/>
                    ) : item.quote_type === 'GL' ? (
                      <CustomizeRateGL quote_idx={index}
                                       onChange={this.dataChanged.bind(this)}/>
                    ) : item.quote_type === 'CYBER' ? (
                      <CustomizeRateCYBER term_premium={this.state.cyberPremium}
                                          isEstimatedLoading={this.state.isEstimatedLoading}
                                          isEstimated={this.state.isEstimated}
                                          quote_idx={index}
                                          onChange={this.dataChanged.bind(this)}/>
                    ) : item.quote_type === 'PL' ? (
                      <CustomizePL quote_idx={index}
                                   onChange={this.dataChanged.bind(this)}/>
                    ) : null}
                  </Grid>
                )
              }
            )}

            {this.props.answers['selected_quotes'].map((item) => {
                if (item?.error) return null
                else return (
                  item.quote_type === 'CYBER' ? (
                    Object.keys(this.state.cyberOptionalCoverages).map(group => {
                      return (<>
                        <Grid item xs={12}><p className={'oc_group_ttl'}>{group} Coverages</p></Grid>
                        {this.state.cyberOptionalCoverages[`${group}`].map((oc, index) => {
                          if (!oc.hidden) {
                            return (<Grid item xs={12} key={index}>
                              <Question {...oc}
                                        type="cyberOC"
                                        updateNeeded={this.state.updateNeeded}
                                        question={oc.question_text}
                                        answer={this.props.answers[oc.qid]}
                                        onLimitChanged={this.dataChanged.bind(this)}
                              />
                            </Grid>)
                          } else {
                            return (this.state.optional_coverages.map((oc, index) => {
                                if (this.checkIfLimitIsAvailable(oc.qid)) {
                                  return (
                                    <Grid item xs={12} key={index}>
                                      <Question {...oc}
                                                type="OC"
                                                question={oc.question_text}
                                                answer={this.props.answers[oc.qid]}
                                                price={this.getOCPrice(oc.qid)}
                                                onLimitChanged={this.dataChanged.bind(this)}
                                      />
                                    </Grid>
                                  )
                                } else return null
                              })
                            )
                          }
                        })}
                      </>)
                    })
                  ) : null
                )
              }
            )}

            {this.state.data_changed && !this.state.loading && !this.state.isEstimatedLoading &&
              <Grid item xs={12}>
                <div className={'update_total'} onClick={this.getQuickQuote.bind(this)}>
                  <Update className={'icon_update_total'}/>
                  <span>Update my total</span>
                </div>
              </Grid>}
          </Grid>

          <Grid container className={'section_navigation is_back_btn is_total_price'}>
            <div className="total_price_wrapper">
              <div className="total_price">
                {this.state.loading || this.state.isEstimatedLoading
                  ? <LoadingButton size={42}/>
                  : <>
                    <span>Total: </span>
                    <span className="price">
                      {this.isCarrierCoalition()
                          ? this.state.isEstimated
                                  ? <>
                                    {formatCurrency(this.state.cyberPremium, true)}
                                    <small style={{
                                      fontSize: '14px',
                                      fontWeight: 600
                                    }}>&nbsp;/{t('common.month_short')}</small>
                                  </>
                                  : <>
                                    {formatCurrency(currency(this.getTotalPrice() * 12), true)}
                                    <small style={{
                                      fontSize: '14px',
                                      fontWeight: 600
                                    }}>&nbsp;/{t('common.year_short')}</small>
                                  </>
                          : <>
                            {formatCurrency(currency(this.getTotalPrice()), true)}
                            <small style={{fontSize: '14px', fontWeight: 600}}>&nbsp;/{t('common.month_short')}</small>
                          </>}
                    </span>
                    </>}
              </div>
            </div>

            <button className="section_back_btn"
                    type={'button'}
                    onClick={() => {
                      this.goBack()
                    }}>
              <span className={'a_btn a_btn_transparent'}>
                <ArrowBack/>
              </span>
              <span className="a_mob_back">Back</span>
            </button>
            <NextSectionButton
              nextDisabled={this.state.data_changed}
              validateForm={() => this.proceedNext()}
              className="section_next_btn"/>
            <NextSectionButton
              nextDisabled={this.state.data_changed}
              validateForm={() => this.proceedNext()}
              className="section_next_btn__mobile"/>
          </Grid>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    estimatedPremium: state.insurance.estimatedPremium,
    answers: state.insurance.answers,
    subsections: state.insurance.subsections,
    current_subsection: state.insurance.current_subsection,
    subsections_questions_data: state.insurance.subsections_questions_data,
    underwriterStatements: state.insurance.underwriterStatements,
    session_id: state.insurance.unique_session_id,
    questions: state.insurance.subsections_questions
  }
}

const mapDispatchToProps = {
  changeAnswers,
  updateProgress,
  updateInsStoreKey,
  updateUnderwriterStatements
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomizeYourRate))
