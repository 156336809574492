import DoRequest from "./do-request";

const PostAnswers = async (answers, props_answers, unique_session_id) => {
    return DoRequest({
        url: "/api/post-answers",
        method: "post",
        body: {
            session_id: unique_session_id,
            answers: {
                ...props_answers,
                ...answers
            }
        }
    }).catch(e => {
      throw new Error(`Failed to post answers. ${e}`)
    })
}

export default PostAnswers