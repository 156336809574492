import React from "react";
import {ArrowForward} from "@material-ui/icons";
import LoadingButton from "../../LoadingButton";
import {withTranslation} from 'react-i18next';
import Question from "../../form-components/Question";
import {Box, Button, Grid, Typography} from "@material-ui/core";

class SignUp extends React.Component {

    render() {
        const {t} = this.props

        return (
            <form autoComplete="off" onSubmit={(e) => this.props.validate(e)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h1" style={{lineHeight: '30px'}}>{t('auth.validation_n_setup')}</Typography>
                        <Typography variant="body1">{t('auth.sign_up_txt')}</Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Question fullWidth
                                      type="password"
                                      question={t('auth.temp_pass')}
                                      answer={this.props.temp_pass}
                                      error={!!this.props.errors?.temp_pass}
                                      helperText={this.props.errors?.temp_pass}
                                      onChange={(id, value) => this.props.onTempPasswordChange(value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Question fullWidth
                                      type="password"
                                      question={t('auth.new_pass')}
                                      tooltip={t('auth.new_pass_tooltip')}
                                      answer={this.props.pass}
                                      error={!!this.props.errors?.pass}
                                      helperText={this.props.errors?.pass}
                                      onChange={(id, value) => this.props.onPasswordChange(value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Question fullWidth
                                      type="password"
                                      question={t('auth.confirm_pass')}
                                      tooltip={t('auth.confirm_pass_tooltip')}
                                      answer={this.props.pass_repeat}
                                      error={!!this.props.errors?.pass_repeat}
                                      helperText={this.props.errors?.pass_repeat}
                                      onChange={(id, value) => this.props.onPasswordRepeatChange(value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="resend-temp-pass">
                            <Button type="button"
                                    variant="text"
                                    color="primaryDark"
                                    style={{
                                        marginLeft: '-8px',
                                        marginTop: '8px',
                                    }}
                                    onClick={() => {
                                        this.props.resendTempPasswordEmail()
                                    }}>
                                {t('auth.btn.temp_resend')}</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={2} pb={3}>
                            {this.props.processing ? (<LoadingButton/>)
                                : (
                                    <Button type="submit"
                                            size="large"
                                            color="primary"
                                            variant="contained"
                                            onClick={(e) => {
                                                this.props.validate(e)
                                            }}>
                                        {t('common.btn_continue')}<ArrowForward style={{marginLeft: '10px'}}/>
                                    </Button>
                                )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

export default withTranslation()(SignUp)
