import React, {Component} from "react";
import {connect} from "react-redux";
import AnimatedText from "./AnimatedText";
import {withTranslation} from "react-i18next";
import {Carousel} from "react-responsive-carousel";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
//TODO [V2 update] cleanup

//scss
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './../scss/v2/_did_know_loading.scss'

class B2ZLoading extends Component {

    state = {
        stages: null,
        loading_string: '',
        current_string_index: {
            index: 0,
            count: 0
        },
        timing: 0,
        start_timestamp: new Date().getTime(),
        animation_ended: false
    }

    constructor(props) {
        super(props);

        this.state.loading_string = this.props.loading_string
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            let current_string_index = {...this.state.current_string_index}

            if (current_string_index.count < 2) current_string_index.count = current_string_index.count + 1
            else {
                current_string_index.count = 0
                if (current_string_index.index < this.state.loading_string.length - 1) {
                    current_string_index.index = current_string_index.index + 1
                } else current_string_index.index = 0
            }

            this.setState({
                timing: (this.state.timing >= 3) ? 0 : this.state.timing + 1,
                current_string_index: current_string_index
            })
        }, 1000)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.animation_ended) {
            let cur_timestamp      = new Date().getTime(),
                animation_duration = this.state.loading_string.length * 4 * 1000 + 1

            if (cur_timestamp - this.state.start_timestamp > animation_duration && this.props.onCycleEnd) {
                this.setState({animation_ended: true}, () => {
                    this.props.onCycleEnd()
                })
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    getAnimationStage() {
        let stage

        switch (this.state.timing) {
            case 1:
                stage = 'stage-1'
                break;
            case 2:
                stage = 'stage-2'
                break;
            case 3:
                stage = 'stage-3'
                break;
            default:
                stage = 'stage-0'
        }

        return "logotype " + stage
    }

    render() {
        const {t} = this.props

        return (
            <div className="acrisure_loading ">
               <div className={this.getAnimationStage()}>
                  <svg className={'animationLogo'} width="121" height="102" viewBox="0 0 121 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="layer-1" d="M76.2896 26.8146L120.856 101.889H94.2609L62.9819 49.2317L76.2896 26.8146Z" fill="#3F75A0"/>
                    <path id="layer-4" d="M47.2993 75.7158L60.4411 53.5764L73.5829 75.7423L47.2993 75.7158Z" fill="#002C4D"/>
                    <path id="layer-3" d="M73.7229 22.4502L26.5623 101.889H0L60.4417 0L73.7229 22.4502Z" fill="#002C4D"/>
                    <path id="layer-2" d="M76.1776 80.1396L89.074 101.862H31.7568L44.6731 80.1066L76.1776 80.1396Z" fill="#002C4D"/>
                  </svg>
                </div>
                        <div className={'text'}>
                            <AnimatedText
                                strings={(this.props.restoring_session) ? ['Retrieving your quote...'] : this.state.loading_string}
                                show_in={1000}
                                delay={4000}
                                show_out={1000}
                                onCycleEnd={() => {
                                    if (this.props.onCycleEnd) this.props.onCycleEnd()
                                }}
                                onStringPrinted={(string) => {
                                    if (this.props.onStringPrinted) this.props.onStringPrinted(string)
                                }}
                                onBeforeDelete={(string) => {
                                    if (this.props.onBeforeDelete) this.props.onBeforeDelete(string)
                                }}
                            />
                        </div>
                        <div className="hint">{t('common.animation_await_message')}</div>
                        {this.props.quoteType !== 'FQ' && this.props.loading_cards.length > 0 && (
                            <div className="hints">
                                <Carousel axis="horizontal"
                                          dynamicHeight
                                          autoPlay
                                          infiniteLoop
                                          interval={6000}
                                          showArrows
                                          showIndicators={false}
                                          showThumbs={false}
                                          showStatus={false}
                                          stopOnHover
                                          renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                              hasPrev && (
                                                  <div className="previous-btn">
                                                      <button type="button" onClick={onClickHandler} title={label}>
                                                          <ChevronLeft/>
                                                      </button>
                                                  </div>
                                              )
                                          }
                                          renderArrowNext={(onClickHandler, hasNext, label) =>
                                              hasNext && (
                                                  <div className="next-btn">
                                                      <button type="button" onClick={onClickHandler} title={label}>
                                                          <ChevronRight/>
                                                      </button>
                                                  </div>
                                              )
                                          }
                                >
                                    {this.props.loading_cards.map((itm, idx) => {
                                        return !itm.disabled &&
                                            (<div className='slide-content' key={idx}>
                                                <div className="hint">
                                                    {itm.config?.h1 &&
                                                    <p className="header">{itm.config.h1}</p>}
                                                    <p className={"text" + (typeof itm.config.content !== "string") && ' small'}>
                                                        {/* {itm.config.h2 &&
                                                        <div className="subheader_l2">{itm.config.h2}</div>}
                                                        {(itm.config.h3 && <div
                                                            className="subheader_l3">{itm.config.h3}</div>) || null}*/}
                                                        {(typeof itm.config.content !== "string") ? (
                                                            <ul className="icon-list">
                                                                {itm.config.content.map((line, index) => (
                                                                    <li key={index}>
                                                                        <div className="icon"><img
                                                                            src={"https://b2z-public-images.s3.amazonaws.com" + line.icon}
                                                                            alt={line.text}/></div>
                                                                        <div className="text">{line.text}</div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <div className="text-hint"
                                                                 dangerouslySetInnerHTML={{__html: itm.config.content}}/>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>)
                                    })}
                                </Carousel>
                            </div>
                        )}
                   {/* </Grid>
                </Grid>*/}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading_cards: state.insurance.loading_cards,
    }
}

export default connect(mapStateToProps)(withTranslation()(B2ZLoading))
