import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import Calendar from '../../components/icons/new/Calendar'
import {FormControl} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {QuestionTooltip} from "./QuestionTooltip";
import moment from "moment";


const PolicyStartDate = (props) => {

    return (
        <>
            <FormControl className={'form_control_select_policy_date'} fullWidth>
                <div className="label_blk">
                    <div className="label"><span>{props.title}</span> &nbsp;<QuestionTooltip text={props.tooltip}  /> </div>
                </div>
                 <MuiPickersUtilsProvider utils={DateFnsUtils} >
                        <KeyboardDatePicker
                            className="KeyboardDatePicker"
                            autoOk={props.autoOk || true}
                            disableToolbar
                            views={["date"]}
                            format="MM/dd/yyyy"
                            placeholder="mm/dd/yyyy"
                            variant="inline"
                            inputVariant="outlined"
                            keyboardIcon={<Calendar/>}
                            onChange={e => props.onChange(moment(e))}
                            margin="none"
                            value={props.value}
                            minDate={props.minDate}
                            maxDate={props.maxDate}
                            minDateMessage={'Must be date in future'}
                            maxDateMessage={'Date must be within 30 days'}
                            openTo="date"
                            KeyboardButtonProps={{
                                'aria-label': 'Select date',
                            }}
                        />
                </MuiPickersUtilsProvider>
            </FormControl>
        </>
    )
}

export default PolicyStartDate

