import React from "react";
import {withTranslation} from 'react-i18next';
import {formatCurrency} from "../../../helpers/currency";
import {DeductibleLabel} from "./limits/DeductibleLabel";
import {PerOccurrenceLimitLabel} from "./limits/PerOccurrenceLimitLabel";

const PLPolicyDetails = (props) => {
    const {t} = props
    return (<div className="detail_info">
        <p className="policy_coverages">{t('common.coverages')}</p>

        <div className="detail_info_columns">
            <div className="detail_info_group">

                <div className="group_ttl">{t('portal.policy.liability.title')}</div>
                <div className="group_txt">{t('portal.policy.liability.txt')}</div>
                <div className="group_list">
                    <div className="list_item">
                        <PerOccurrenceLimitLabel name={t('common.per_occur')}/>
                        <span
                            className="item_value">{formatCurrency(props.quote.limits.professional_liability_occurrence_limit?.value || 0)}</span>
                    </div>
                    <div className="list_item">
                        <DeductibleLabel name={t('common.deductible')}/>
                        <span
                            className="item_value">{formatCurrency(props.quote.limits.professional_liability_deductible_amount?.value || 0)}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default withTranslation()(PLPolicyDetails)