import React from 'react';
import {sortBy} from "lodash";
import moment from "moment";
import {formatCurrency} from "../../../helpers/currency";

const PaymentHistoryItem = (props) => {

    const paidInstallments = sortBy(props.installments, 'payment_installment_id').filter(itm => !!itm.paid_date)

    return (
        <div className={'policy_history portal_card'}>
            <p className={'policy_history_ttl'}>Policy - {props.quote.policy_number.toUpperCase()}</p>
            {paidInstallments.map((item, index) => {
                return (<div className={'history_payment_item'} key={index}>
                    <div className="left_column">
                        <p className={'item_amount'}>{formatCurrency(item.amount)}</p>
                        <p className={'item_pay_type'}>{item.payment_type} Autopay xxx{item.last_four}</p>
                    </div>

                    <div className="right_column">
                        <p className={'item_paid_date'}>{moment(item.paid_date).format('MM/DD/YY')}</p>
                    </div>
                </div>)
            })}
        </div>
    );
}

export default PaymentHistoryItem;