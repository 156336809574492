import React from 'react';
import clsx from "clsx";
import {Step} from "../icons/Step";
import {connect} from "react-redux";
import {Box} from "@material-ui/core";
import {sortBy} from "lodash"
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import SectionIndicatorItem from "./SectionIndicatorItem";
//scss
import '../../scss/v2/_stepper.scss'

class SectionIndicator extends React.Component {

    state = {
        nav_top_position: 0
    }

    componentDidMount() {
        //TODO refactor this
        if (this.props.answers['quote_status'] === 'Rejected Quote' && this.props.isDialogSectionIndicator) {
            this.setState({nav_top_position: 150})
        }
        /* if (this.props.answers['coverage.types'].length === 2 && this.props.answers['quote_status'] === 'Rejected Quote' && this.props.isDialogSectionIndicator) {
             this.setState({nav_top_position: 375})
         }*/
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.current_section_index !== prevProps.current_section_index) {
            this.setNavPosition(this.props.current_section_index)
        }
    }

    setNavPosition(index) {
        let navItemHeight = 75
        this.setState({nav_top_position: (index * navItemHeight)})
    }

    getSectionNavigationLink(section) {

        let subsection = sortBy(this.props.subsections.filter(itm => itm.section_index === section.section_index), 'subsection_index')

        return (subsection.length > 0)
            ? '/get-insurance' + subsection[0].path
            : '/get-insurance/business-name-and-address' //fallback to start
    }

    render() {
        return (
            <Box className="sections-indicator">
                <div className="stepper" style={{marginTop: `-${this.state.nav_top_position}px`}}>
                    {this.props.sections ? this.props.sections.map((itm, idx) => {

                        let completed = itm.name === 'Request Your Quote'? false : this.props.total_progress === 100 || (itm.progress && itm.progress === 100)
                        let active = itm.section_index === this.props.current

                        let step = clsx({
                            step: true,
                            active: active,
                            completed: completed,
                            disabled: itm.disabled
                        })

                        let label = clsx({
                            label: true,
                        })

                        return (
                            <div className={step} key={idx}>
                                <div className="number">
                                    <div className="glue-top"/>
                                    <div className="glue-bottom"/>
                                    <div className="indicator">
                                        <Step/>
                                    </div>
                                </div>
                                <div className={label}>
                                    <SectionIndicatorItem completed={completed}
                                                          active={active}
                                                          progress={itm.progress}
                                                          section={itm}
                                                          section_index={itm.section_index}
                                    />
                                </div>
                            </div>
                        )
                    }) : this.props.html_content}
                </div>
            </Box>
        )
    }
}

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
        subsections: state.insurance.subsections,
        current_section_index: state.insurance.current_section_index,
        industry_classes: state.insurance.industry_classes,
        wc_locations: state.insurance.wc_locations
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(SectionIndicator)))
