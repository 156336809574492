import React from "react";
import axios from 'axios'
import {connect} from "react-redux";
import {Close} from "@material-ui/icons";
import {withTranslation} from 'react-i18next';
import { ModalTransition } from '../../common/ModalTransition'
import Question from "../form-components/Question";
import {
  Dialog,
  DialogActions, DialogContent,
  Grid,
} from '@material-ui/core'
//scss
import "../../scss/v2/modals/_save_your_progress.scss"
import {currency, formatCurrency} from "../../helpers/currency";
import LoadingButton from '../LoadingButton'

class SaveYourProgressButton extends React.Component {

    state = {
        save_modal: false,
        email: null,
        error_email: false,
        error_shared_email: false,
        share_emails: [],
        share_email_value: '',
        processing: false,
        quote_expiration_date: "30"
    }

    constructor(props) {
        super(props);

        if (!!this.props.answers['contact.email'])
            this.state.email = this.props.answers['contact.email']
    }

    openModal() {
        this.setState({save_modal: true})
    }

    closeModal(saved = false) {
        this.setState({save_modal: false}, () => {
            if (saved) this.props.onSaved()
        })
    }

    changeEmail(value) {
        this.setState({email: value})
    }

    validate(e) {
        e.preventDefault()
        setTimeout(() => {
            let error = {}
            if (!/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(this.state.email)) {
                error.email = this.props.t('form.error.invalid_email_save_progress')
                this.setState({error_email: error.email})
            } else {
                this.setState({error_email: ''})
            }

            if (this.state.share_email_value !== "" && !this.isValidShareEmailsItem(this.state.share_email_value)) {
                error.share_email = this.props.t('form.error.invalid_email')
                this.setState({error_shared_email: error.share_email})
            } else {
                this.setState({error_shared_email: ''})
            }

            if (!error.email && !error.share_email) {
                this.saveQuoteToEmail();
            }
        }, 0)
    }

    async saveQuoteToEmail() {
        this.setState({processing: true})
        let link = window.location.protocol + '//' + window.location.host
        let quotes = [];
        let promises = [];

        for (let i = 0; i < this.props.answers['selected_quotes'].length; i++) {
            let q = this.props.answers['selected_quotes'][i]
            let quote = q.quote_type === "WC"
                ? {
                    "lob": q.quote_type,
                    "type": this.props.t('product.wc.lob_policy_full'),
                    "price": formatCurrency(currency(this.props.wc_premium_total)),
                    "period": this.props.t('common.month_short'),
                    "occurrence_limit": formatCurrency(q.limits.wc_occurrence_limit.value),
                    "policy_limit": formatCurrency(q.limits.wc_each_employee_limit.value),
                    "employee_limit": formatCurrency(q.limits.wc_policy_limit.value),
                    "quote_expiration_date": this.state.quote_expiration_date
                } : q.quote_type === "GL" ?
                    {
                        "lob": q.quote_type,
                        "type": this.props.t('product.gl.gl_policy_full'),
                        "price": formatCurrency(currency(this.props.gl_premium_total)),
                        "period": this.props.t('common.month_short'),

                        //Limits
                        "gl_liability_occurrence_limit": formatCurrency(q.limits.gl_liability_occurrence_limit.value),
                        "gl_damage_to_premises_rented_to_you": formatCurrency(q.limits.gl_damage_to_premises_rented_to_you.value),
                        "gl_damage_to_premises_rented_to_you_deductible": formatCurrency(q.limits.gl_damage_to_premises_rented_to_you_deductible.value),
                        "gl_liability_aggregate": formatCurrency(q.limits.gl_liability_occurrence_limit.value * 2),
                        "quote_expiration_date": this.state.quote_expiration_date
                    }: q.quote_type === "PL" ?
                    {
                        "lob": q.quote_type,
                        "type": this.props.t('product.pl.pl_policy_full'),
                        "price": formatCurrency(currency(this.props.pl_premium_total)),
                        "period": this.props.t('common.month_short'),
                        "occurrence_limit": formatCurrency(q.limits.professional_liability_occurrence_limit.value),
                        "aggregate_limit": formatCurrency(q.limits.professional_liability_occurrence_limit.value * 2), // TODO: Fix this to come from core
                        "deductible_amount": formatCurrency(q.limits.professional_liability_deductible_amount.value),
                        "quote_expiration_date": this.state.quote_expiration_date
                    } :q.quote_type === "CYBER" ?
                    {
                      "lob": q.quote_type,
                      "type": this.props.t('product.cyber.cyber_policy_full'),
                      "price": formatCurrency(currency(this.props.cyber_premium_total)),
                      "period": this.props.t('common.month_short'),
                      "cyber_aggregate_limit": formatCurrency(q.limits.cyber_aggregate_limit.value),
                      "cyber_retention_limit": formatCurrency(q.limits.cyber_retention_limit.value),
                      "quote_expiration_date": this.state.quote_expiration_date
                    } :
                    {
                        "lob": q.quote_type,
                        "type": this.props.t('product.bop.lob_policy_full'),
                        "price": formatCurrency(currency(this.props.bop_premium_total)),
                        "period": this.props.t('common.month_short'),
                        "occurrence_limit": formatCurrency(q.limits.liability_occurrence_limit.value),
                        "aggregate_limit": formatCurrency(q.limits.liablity_aggregate.value),
                        "building_limit": formatCurrency(q.limits.liablity_bldg_limit.value),
                        "damage_to_premises_rented_to_you": formatCurrency(q.limits.damage_to_premises_rented_to_you.value),
                        "medical_limit": formatCurrency(q.limits.medical_limit.value),
                        "property_deductible": formatCurrency(q.limits.property_deductible.value),
                        "total_business_limit": formatCurrency(q.limits.liablity_personal_property_limit.value),
                        "mpl": this.props.answers['mpl.coverages.is_selected'] || this.props.t('common.no'),
                        "cyber_aggregate_limit": formatCurrency(this.props.answers['cyber.coverage.aggregate_limit']),
                        "cyber": this.props.answers['cyber.coverage.is_selected'] || this.props.t('common.no'),
                        "hnoa": this.props.answers['hnoa.coverage.is_selected'] || this.props.t('common.no'),
                        "quote_expiration_date": this.state.quote_expiration_date
                    }

            quotes.push(quote)

        }

        let res;
        res = axios.post('/api/save-your-progress', {
            recipients: [this.state.email],
            username: this.props.answers["contact.first_name"],
            business: this.props.answers["business.name"],
            share: 'false',
            link: link,
            quotes: quotes
        })

        promises.push(res)

        if (this.state.share_emails.length > 0) {
            let shareEmails = this.state.share_emails;
            res = axios.post('/api/save-your-progress', {
                recipients: shareEmails,
                username: this.props.answers["contact.first_name"],
                business: this.props.answers["business.name"],
                share: 'friend',
                link: link,
                quotes: quotes
            })
            promises.push(res)
        }

        if (promises.length > 0) this.sendEmails(promises)
    }

    async saveQuotesToEmail() {
        this.setState({processing: true})
        let link = window.location.protocol + '//' + window.location.host
        let bop_quote = this.props.answers['selected_quotes'].filter(itm => itm.quote_type === 'BOP')
        let wc_quote = this.props.answers['selected_quotes'].filter(itm => itm.quote_type === 'WC')
        let pl_quote = this.props.answers['selected_quotes'].filter(itm => itm.quote_type === 'PL')
        let cyber_quote = this.props.answers['selected_quotes'].filter(itm => itm.quote_type === 'CYBER')

        let data = {
            username: this.props.answers["contact.first_name"],
            business: this.props.answers["business.name"],
            link: link,
            quote_expiration_date: this.state.quote_expiration_date,
            bop_quote: {
                "price": currency(this.props.bop_premium_total),
                "period": this.props.t('common.month_short'),
                "occurrence_limit": bop_quote[0].limits.liability_occurrence_limit.value,
                "aggregate_limit": bop_quote[0].limits.liablity_aggregate.value,
                "building_limit": bop_quote[0].limits.liablity_bldg_limit.value,
                "damage_to_premises_rented_to_you": bop_quote[0].limits.damage_to_premises_rented_to_you.value,
                "medical_limit": bop_quote[0].limits.medical_limit.value,
                "property_deductible": bop_quote[0].limits.property_deductible.value,
                "total_business_limit": bop_quote[0].limits.liablity_personal_property_limit.value,
                "mpl": this.props.answers['mpl.coverages.is_selected'] || this.props.t('common.no'),
                "cyber_aggregate_limit": this.props.answers['cyber.coverage.aggregate_limit'],
                "cyber": this.props.answers['cyber.coverage.is_selected'] || this.props.t('common.no'),
                "hnoa": this.props.answers['hnoa.coverage.is_selected'] || this.props.t('common.no')
            },
            wc_quote: {
                "price": currency(this.props.wc_premium_total),
                "period": this.props.t('common.month_short'),
                "occurrence_limit": wc_quote[0].limits.wc_occurrence_limit.value,
                "policy_limit": wc_quote[0].limits.wc_policy_limit.value,
                "employee_limit": wc_quote[0].limits.wc_each_employee_limit.value
            },
            cyber_quote: {
                "price": currency(this.props.cyber_premium_total),
                "period": this.props.t('common.month_short'),
                "cyber_aggregate_limit": cyber_quote[0].limits.cyber_aggregate_limit.value,
                "cyber_retention_limit": cyber_quote[0].limits.cyber_retention_limit.value,
            },
            pl_quote: {
                "price": currency(this.props.bop_premium_total),
                "period": this.props.t('common.month_short'),
                "occurrence_limit": pl_quote[0].limits.professional_liability_occurrence_limit.value,
                "aggregate_limit": pl_quote[0].limits.professional_liability_occurrence_limit.value * 2, //  TODO: Fix this to come from core
                "deductible_amount": pl_quote[0].limits.professional_liability_deductible_amount.value
            }
        }

        let promises_list = []

        if (bop_quote.length > 0 && wc_quote.length > 0) {
            let user_email = axios.post('/api/save-your-progress', {
                to: [this.state.email],
                data: data
            })

            promises_list.push(user_email)

            if (this.state.share_emails.length > 0) {
                let share_email = axios.post('/api/save-your-progress', {
                    to: this.state.share_emails,
                    template: 'resume_to_quotes_share',
                    data: data,
                    attachments: []
                })
                promises_list.push(share_email)
            }
        }

        if (promises_list.length > 0) this.sendEmails(promises_list)
    }

    onKeyDownShareEmails = evt => {
        if (["Tab", " ", ","].includes(evt.key)) {
            evt.preventDefault()

            this.addShareInputValue()
        }
    }

    onBlurShareEmails = evt => {
        this.addShareInputValue()
    }

    addShareInputValue() {
        let value = this.state.share_email_value.trim()

        if (value && this.isValidShareEmailsItem(value)) {
            this.setState({
                share_emails: [...this.state.share_emails, this.state.share_email_value],
                share_email_value: ''
            })
        }
    }

    onChangeShareEmails = evt => {
        this.setState({
            share_email_value: evt.target.value,
            error_shared_email: false
        })
    }

    onPasteShareEmails = event => {
        event.preventDefault()
        let paste = event.clipboardData.getData("text")
        let emails = paste.match(/([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)+/g)
        if (emails) {
            let toBeAdded = emails.filter(email => {
                if (email !== this.state.email) return !this.isInListShareEmails(email)
                return false
            })
            this.setState({
                share_emails: [...this.state.share_emails, ...toBeAdded]
            })
        }
    }

    onDeleteShareEmail = item => {
        this.setState({
            share_emails: this.state.share_emails.filter(i => i !== item)
        });
    }

    sendEmails(promises) {
        Promise.all(promises)
            .then(value => {
                this.setState({processing: false})
                this.closeModal(true)
            })
            .catch(e=>{
                this.setState({error: this.props.t('common.something_went_wrong')})
                throw new Error(`Can't save quotes. ${e}`)
            })
    }


    isValidShareEmailsItem(email) {
        let error = null;

        if (this.isInListShareEmails(email) || this.state.email === email) {
            error = this.props.t('form.error.added_email', {val: email})
        }

        if (!this.isEmail(email)) {
            error = this.props.t('form.error.invalid_email')
        }

        if (error) {
            this.setState({error_shared_email: error})
            return false;
        }
        return true;
    }

    isInListShareEmails(email) {
        return this.state.share_emails.includes(email)
    }

    isEmail(email) {
        return /^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(email)
    }

    render() {
        const {t} = this.props;

        return (
            <>
                <div className="save_progress_btn_wrapper">
                  <button className="a_btn a_btn_transparent a_btn_outlined a_btn_save_progress"
                          type={'button'}
                          disabled={!this.props.quotes.filter(itm => !itm?.error).length > 0}
                          onClick={(e) => {
                            this.openModal()
                          }}>{t('common.btn_save_progress')}</button>
                </div>
                <Dialog open={this.state.save_modal}
                        fullWidth
                        container={() => document.getElementById('themeAppComponent')}
                        className={'modal__wrapper save_your_progress_modal__wrapper'}
                        onClose={() => {
                            this.closeModal()
                        }}
                        TransitionComponent={ModalTransition}>
                  <DialogActions className={'modal_head'}>
                    <button className={'modal_close_btn show_scroll_paper'}
                            type={'button'}
                            onClick={() => this.closeModal()}><Close/>
                      <span>{t('common.btn_close')}</span></button>

                  </DialogActions>
                  <DialogContent className={'modal_body'}>
                    <form autoComplete="off" onSubmit={(e) => this.validate(e)}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <p
                            className={'modal_ttl'}>{t('modal.save_progress.title')}</p>
                          <p className={'modal_txt'}>{this.state.email
                            ? t('modal.save_progress.txt_verify')
                            : t('modal.save_progress.txt_add')}
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <Question fullWidth
                                    type="email"
                                    question={t('form.label.e_mail_required')}
                                    answer={this.state.email}
                                    error={!!this.state.error_email}
                                    helperText={this.state.error_email}
                                    onChange={(id, value) => {
                                      this.changeEmail(value)
                                    }}
                          />
                        </Grid>
                        <Grid item xs={12} className={'form_control'}>
                          <div className="label_blk">
                            <div className="label"><span>{t('form.label.e_mail_friends')}</span></div>
                          </div>
                          <div
                            className={"email-chips " + (this.state.error_shared_email && " has-error")}>
                            {this.state.share_emails.map(item => (
                              <div className="tag-item" key={item}>
                                <span>{item}</span>
                                <div className="close"
                                     onClick={() => this.onDeleteShareEmail(item)}>
                                  <Close/>
                                </div>
                              </div>
                            ))}
                            <div className="input-item">
                              <input
                                onBlur={(event) => {
                                  this.onKeyDownShareEmails(event)
                                }}
                                value={this.state.share_email_value}
                                placeholder={this.state.share_emails.length ? '' : t('form.placeholder.share_emails')}
                                onBlurCapture={this.onBlurShareEmails}
                                onKeyDown={this.onKeyDownShareEmails}
                                onChange={this.onChangeShareEmails}
                                onPaste={this.onPasteShareEmails}
                              />
                            </div>

                          </div>
                          {this.state.error_shared_email &&
                            <p className="error_txt error_txt_md">{this.state.error_shared_email}</p>}
                        </Grid>


                        <Grid item xs={12}>
                          {this.state.processing ? (
                            <LoadingButton/>
                          ) : (<button className={'a_btn a_btn_action_1 a_btn_save'}
                                       onClick={(e) => {
                                         this.validate(e)
                                       }}>{t('common.btn_submit')}</button>)}
                        </Grid>
                      </Grid>
                    </form>
                  </DialogContent>
                </Dialog>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
        bop_limit_available: state.insurance.bop_limit_available,
        bop_premium_total: state.insurance.bop_premium_total,
        wc_premium_total: state.insurance.wc_premium_total,
        pl_premium_total: state.insurance.pl_premium_total,
        gl_premium_total: state.insurance.gl_premium_total,
        cyber_premium_total: state.insurance.cyber_premium_total
    }
}

export default connect(mapStateToProps)(withTranslation()(SaveYourProgressButton))
