import React from "react";

export const TooltipIcon = () => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.49998 11.9167C9.49152 11.9167 11.9166 9.49154 11.9166 6.5C11.9166 3.50845 9.49152 1.08333 6.49998 1.08333C3.50844 1.08333 1.08331 3.50845 1.08331 6.5C1.08331 9.49154 3.50844 11.9167 6.49998 11.9167Z"
            stroke="#827252" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M4.92377 4.875C5.05112 4.51299 5.30247 4.20773 5.63333 4.01328C5.96418 3.81884 6.35317 3.74776 6.7314 3.81264C7.10964 3.87752 7.45271 4.07416 7.69985 4.36775C7.94699 4.66133 8.08225 5.03291 8.08168 5.41667C8.08168 6.5 6.45668 7.04167 6.45668 7.04167"
            stroke="#827252" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.5 9.20833H6.50542" stroke="#827252" strokeLinecap="round" strokeLinejoin="round"/>
        {/*<defs>
            <linearGradient id="t_paint0_linear" x1="3.99477" y1="0.406245" x2="8.59894" y2="14.0156"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#00BFFF"/>
                <stop offset="1" stopColor="#003EFF"/>
            </linearGradient>
            <linearGradient id="t_paint1_linear" x1="5.77246" y1="3.58597" x2="7.18728" y2="7.64649"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#00BFFF"/>
                <stop offset="1" stopColor="#003EFF"/>
            </linearGradient>
            <linearGradient id="t_paint2_linear" x1="6.50146" y1="9.14583" x2="6.52387" y2="9.14619"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#00BFFF"/>
                <stop offset="1" stopColor="#003EFF"/>
            </linearGradient>
        </defs>*/}
    </svg>
)