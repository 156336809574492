export const UPDATE_STORE_KEY = 'UPDATE_STORE_KEY'
export const CHANGE_ANSWERS = 'CHANGE_ANSWERS'
export const SET_ANSWERS = 'SET_ANSWERS'
export const UPDATE_INDUSTRY_CLASSES = 'UPDATE_INDUSTRY_CLASSES'
export const UPDATE_WC_INDUSTRY_CLASSES = 'UPDATE_WC_INDUSTRY_CLASSES'
export const UPDATE_SECTIONS = 'UPDATE_SECTIONS'
export const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS'
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const UPDATE_QUESTIONS_DEFAULTS = 'UPDATE_QUESTIONS_DEFAULTS'
export const UPDATE_QUESTIONS_AUTOFILL = 'UPDATE_QUESTIONS_AUTOFILL'
export const PLACE_DEFAULTS_ANSWERS = 'PLACE_DEFAULTS_ANSWERS'
export const SET_RESTORE_SESSION = 'SET_RESTORE_SESSION'
export const UPDATE_WC_LOCATIONS = 'UPDATE_WC_LOCATIONS'
export const UPDATE_WC_ENDORSEMENT = 'UPDATE_WC_ENDORSEMENT'
export const UPDATE_WC_UW_QUESTIONS = 'UPDATE_WC_UW_QUESTIONS'
export const UPDATE_UW_QUESTIONS = 'UPDATE_UW_QUESTIONS'
export const UPDATE_APP_PRODUCTS = 'UPDATE_APP_PRODUCTS'
export const UPDATE_AOI_DESIGNATED_PERSON = 'UPDATE_AOI_DESIGNATED_PERSON'
export const UPDATE_AOI_MANAGERS_OR_LESSORS_PREMISES = 'UPDATE_AOI_MANAGERS_OR_LESSORS_PREMISES'
export const UPDATE_AOI_MORTGAGEE_OR_RECEIVER = 'UPDATE_AOI_MORTGAGEE_OR_RECEIVER'
export const UPDATE_AOI_MORTGAGE_HOLDER_INFORMATION = 'UPDATE_AOI_MORTGAGE_HOLDER_INFORMATION'
export const UPDATE_AOI_LOSS_PAYABLE_CLAUSES = 'UPDATE_AOI_LOSS_PAYABLE_CLAUSES'
export const UPDATE_AOI_WTRR_AGAINST_OTHERS = 'UPDATE_AOI_WTRR_AGAINST_OTHERS'
export const UPDATE_QUESTIONS_CLASS_STATE_BASED = 'UPDATE_QUESTIONS_CLASS_STATE_BASED'
export const UPDATE_BUSINESS_OWNERS = 'UPDATE_BUSINESS_OWNERS'
export const UPDATE_ADDITIONAL_INSUREDS = 'UPDATE_ADDITIONAL_INSUREDS'
export const SET_NEXT_SECTION_BUTTON_TEXT = 'SET_NEXT_SECTION_BUTTON_TEXT'
export const UPDATE_UNDERWRITER_STATEMENTS = 'UPDATE_UNDERWRITER_STATEMENTS'
export const UPDATE_ADDITIONAL_INSURED_QUESTIONS = 'UPDATE_ADDITIONAL_INSURED_QUESTIONS'
export const SET_DATA_MAP_PROP = 'SET_DATA_MAP_PROP'


export const setNexSectionButtonText = (value) => ({
    type: SET_NEXT_SECTION_BUTTON_TEXT,
    payload: value
})
export const updateInsStoreKey = (key, value) => ({
    type: UPDATE_STORE_KEY,
    payload: {
        key: key,
        value: value,
    }
})

export const changeAnswers = (value) => ({
    type: CHANGE_ANSWERS,
    payload: value
})

export const setAnswers = (value) => ({
    type: SET_ANSWERS,
    payload: value
})

export const updateIndustryClasses = (value) => ({
    type: UPDATE_INDUSTRY_CLASSES,
    payload: value
})

export const updateWCIndustryClasses = (value) => ({
    type: UPDATE_WC_INDUSTRY_CLASSES,
    payload: value
})

export const updateSections = (value) => ({
    type: UPDATE_SECTIONS,
    payload: value
})

export const updateQuestions = (value) => ({
    type: UPDATE_QUESTIONS,
    payload: value
})

export const updateQuestionsClassStateBased = (questions, lobs) => ({
    type: UPDATE_QUESTIONS_CLASS_STATE_BASED,
    payload: {
        questions: questions,
        lobs: lobs
    }
})

export const updateProgress = (value) => ({
    type: UPDATE_PROGRESS,
    payload: value
})

export const updateQuestionsAutofill = (value) => ({
    type: UPDATE_QUESTIONS_AUTOFILL,
    payload: value
})

export const placeDefaultsAnswers = (value) => ({
    type: PLACE_DEFAULTS_ANSWERS,
    payload: value
})

export const setRestoreSession = (value) => ({
    type: SET_RESTORE_SESSION,
    payload: value
})

export const updateWCLocations = (value) => ({
    type: UPDATE_WC_LOCATIONS,
    payload: value
})

export const updateWCEndorsement = (value) => ({
    type: UPDATE_WC_ENDORSEMENT,
    payload: value
})


export const updateWCUWQuestions = (value) => ({
    type: UPDATE_WC_UW_QUESTIONS,
    payload: value
})
export const updateUWQuestions = (value) => ({
    type: UPDATE_UW_QUESTIONS,
    payload: value
})

export const updateAppProducts = (value) => {
  return {
    type: UPDATE_APP_PRODUCTS,
    payload: value
  }
}

export const updateAOIDesignatedPerson = (value) => ({
    type: UPDATE_AOI_DESIGNATED_PERSON,
    payload: value
})

export const updateAOIManagersOrLessorPremises = (value) => ({
    type: UPDATE_AOI_MANAGERS_OR_LESSORS_PREMISES,
    payload: value
})

export const updateAOIMartgageeOrReceiver = (value) => ({
    type: UPDATE_AOI_MORTGAGEE_OR_RECEIVER,
    payload: value
})

export const updateAOIMartgageHolderInformation = (value) => ({
    type: UPDATE_AOI_MORTGAGE_HOLDER_INFORMATION,
    payload: value
})

export const updateAOILossPayableClauses = (value) => ({
    type: UPDATE_AOI_LOSS_PAYABLE_CLAUSES,
    payload: value
})

export const updateAOIWTTRAgainstOthers = (value) => ({
    type: UPDATE_AOI_WTRR_AGAINST_OTHERS,
    payload: value
})

export const updateBusinessOwners = (value) => ({
    type: UPDATE_BUSINESS_OWNERS,
    payload: value
})

export const updateAdditionalInsureds = (value) => ({
    type: UPDATE_ADDITIONAL_INSUREDS,
    payload: value
})

export const updateUnderwriterStatements = (value) => ({
    type: UPDATE_UNDERWRITER_STATEMENTS,
    payload: value
})

export const updateAdditionalInsuredQuestions = (value) => ({
    type: UPDATE_ADDITIONAL_INSURED_QUESTIONS,
    payload: value
})
