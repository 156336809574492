import React from "react"
import {FormControlLabel, Grid, Radio, withStyles} from "@material-ui/core";

const IS_NEW_THEME_ENABLED = process.env?.REACT_APP_IS_NEW_THEME_ENABLED === "TRUE"
        || window.is_new_theme_enabled === 'TRUE'

const NewStyledRadio = withStyles({
    root: {
        color: "#666E6A",
        "&$checked": {
            color: "#0B0C0C",
        },
    },
    checked: {},
})((props) => <Radio {...props} />);
export const IndustryClassCard = (props) => {
    return (
        IS_NEW_THEME_ENABLED ? <Grid xs={12}>
                <FormControlLabel
                     value={props.id}
                     control={<NewStyledRadio
                     onChange={e => props.onSelected ? props.onSelected(e) : false}
                     size="small"
                     checked={props.selected}/>}
                     label={props.display_description} />
              </Grid>
            : <div id={props.id} className={`industry_class_card ${props.selected && 'selected'}`}
                   onClick={e => props.onSelected ? props.onSelected(e) : false}>
                <div className="card_inner">
                    <div className="row">
                        <span>{props.display_description}</span>
                    </div>
                </div>
            </div>


)
}
