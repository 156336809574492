import React, {useEffect, useState} from 'react'
import PaymentWithCard from '../../components/form-components/PaymentWithCard'
import PaymentWithBank from '../../components/form-components/PaymentWithBank'
import BillingAddressPortal from '../form-components/BillingAddressPortal'
import LoadingButton from '../LoadingButton'
import {Grid} from '@material-ui/core'
import {updatePaymentMethodAuth} from '../../services/carrierService'
import {withTranslation} from 'react-i18next'
import {bindPaymentMethod, PaymentMethod} from '../../services/paymentService'
import axios from 'axios'


interface IErrors {
    [key: string]: string
}

interface IAddress {
    street_address: string,
    street_address2: string,
    city: string,
    state: string
    zip_code: string
}

const ChangePaymentMethod = ({
    policy,
    isEditMethod,
    t,
    onSuccess
}: { policy: any, isEditMethod: boolean, t: (string) => string, onSuccess: () => void }) => {
    //State
    const [quote, setQuote] = useState<any>({})
    const [address, setAddress] = useState<IAddress>({
        street_address: '',
        street_address2: '',
        city: '',
        state: '',
        zip_code: ''
    })
    const [paymentMethod, setPaymentMethod] = useState<any>(null)
    const [errors, setErrors] = useState<IErrors>({})
    const [processing, setProcessing] = useState<boolean>(false)
    const [useDefaultAddress, setUseDefaultAddress] = useState<boolean>(true)
    const [cardHolderName, setCardHolderName] = useState<any>(false)
    const [bankAccount, setBankAccount] = useState<string>('')
    const [bankToken, setBankToken] = useState<any>(null)
    const [creditCardFields, setCreditCardFields] = useState<any | null>(null)

    useEffect(() => {

        const defaultPaymentSourceAddress = {
            street_address: policy.answers_snapshot['business.street_address'],
            street_address2: policy.answers_snapshot['business.street_address2'],
            city: policy.answers_snapshot['business.city'],
            state: policy.answers_snapshot['business.state'],
            zip_code: policy.answers_snapshot['business.zip_code']
        }
        setAddress(defaultPaymentSourceAddress)
    }, [policy.answers_snapshot])

    useEffect(() => {
        if (!isEditMethod) {
            cleanAndClose()
        }
        transformQuote(policy.quote)
    }, [isEditMethod, policy.quote])


    const renderPaymentMethodFields = () => {
        switch (paymentMethod) {
        case 1: //Credit Card
            return (<PaymentWithCard
                onCreditCardHolderChange={value => setCardHolderName(value)}
                updateCreditCardFields={value => setCreditCardFields(value)}
                errors={errors}/>)
        case 0: //Bank Account
            return <PaymentWithBank
                onBankTokenChange={(token) => {
                    setBankToken(token)
                }}
                onBankAccountChange={(account) => {
                    setBankAccount(account)
                }}
                pushErrors={(key, value, processing) => {
                    pushErrors(key, value, processing)
                }}
                errors={errors}/>
        default:
            return null
        }
    }


    const validateBankAccount = (errors) => {
        if (!bankAccount)
            errors['bankAccount'] = t('form.error.field_req')

        if (!bankToken)
            errors['bankToken'] = t('common.connect_bank_acc_error')

        return errors
    }

    const validateCreditCard = (errorsData) => {

        const name_regex = /^(?<name>\D([a-zA-Z0-9.\s]){2,25})$/
        if (!creditCardFields?.cardNumber?.complete)
            errorsData['cardNumber'] = t('common.cc_num_req')

        if (!creditCardFields?.cardExpiry?.complete)
            errorsData['cardExpiry'] = t('common.exp_req')

        if (!creditCardFields?.cardCVC?.complete)
            errorsData['cardCVC'] = t('form.error.field_req')

        if (cardHolderName === false || name_regex.test(cardHolderName) === false)
            errorsData['billing.cardholder_name'] = t('common.cardholder_req')

        return errorsData
    }

    const validateBillingAddress = (errors) => {

        if (!/^.{3,}$/.test(address.street_address))
            errors.street_address = t('form.error.st_address_req')

        if (!/^.{2,}$/.test(address.city))
            errors.city = t('form.error.city_req')

        if (!/^.{2,}$/.test(address.state))
            errors.state = t('form.error.state_req')

        if (!/^.{4,}$/.test(address.zip_code))
            errors.zip_code = t('form.error.zip_req')

        return errors

    }


    //Validate FORM
    const validateForm = async () => {
        let errors = {}

        switch (paymentMethod) {
        case 0:
            errors = validateBankAccount(errors)
            break
        case 1:
            errors = validateCreditCard(errors)
            break
        default:
            errors['common'] = t('common.select_payment_method')
            break
        }
        errors = validateBillingAddress(errors)

        return errors

    }
    //MAKE UPDATE METHOD
    const onUpdatePaymentMethodHandler = async () => {
        // if (event) event.preventDefault()

        setProcessing(true)
        await validateForm().then(res => {
            if (Object.keys(res).length === 0) {
                makePaymentUpdate().then(() => {
                    cleanAndClose(onSuccess)
                })
            } else {
                // cleanAndClose()
                setErrors(res)
                setProcessing(false)

            }
        })
    }

    const transformQuote = (quote) => {
        setQuote(updatePaymentMethodAuth(quote))
    }
    const makePaymentUpdate = async () => {
        let method = paymentMethod === 1 ? PaymentMethod.Card : PaymentMethod.Bank
        let paymentInfo = paymentMethod === 1 ? {
            cardNumber: creditCardFields?.cardNumber?.num,
            expYear: creditCardFields.cardExpiry?.year,
            expMonth: creditCardFields.cardExpiry?.month,
            cardCVC: creditCardFields.cardCVC?.cvc,
            cardName: cardHolderName
        } : {
            publicToken: bankToken,
            accountToken: bankAccount.toString()
        }
        try {
            const quoteRes = await bindPaymentMethod([quote], policy.answers_snapshot,
                useDefaultAddress, method, paymentInfo)

            return await axios.post('/api/create-new-source', {
                'token': quoteRes[0].payment_token_id
            }).then(async result => {
                return await changeDefaultMethod(result.data.id)
            }).catch(e => {
                pushErrors('common', e.response.data.message, false)
                throw new Error(`CREATE NEW SOURCE. ${e}`)
            })
        } catch (e) {
            pushErrors('common', 'Make Payment Update. Something went wrong. Please try again later ', false)
            throw new Error(`Make Payment Update. ${e}`)
        }
    }

    const changeDefaultMethod = async (token) => {
        return await axios.post('/api/set-default-method', {
            'policy': policy.id,
            'source': token
        }).then(() => {
            return true
        }).catch(e => {
            throw new Error(`Can't set default payment method. ${e}`)
        })
    }
    const updateAddressAnswer = (key, value) => {
        let addressObj = {...address}
        addressObj[key] = value
        setAddress(addressObj)
    }

    const cleanAndClose = (callback: any = null) => {
        setPaymentMethod(null)
        setProcessing(false)
        setErrors({})
        setUseDefaultAddress(true)
        setCardHolderName(false)
        setBankAccount('')
        setBankToken('')

        setTimeout(() => {
            document.body.style.removeProperty('overflow')
        }, 350)
        if (callback) {
            callback()
            return
        } else {
            return null
        }

    }


    const pushErrors = (key, value, processing = false) => {
        const errorsObj = {
            ...errors,
            [key]: value
        }
        setErrors(errorsObj)
        setProcessing(processing)
    }
    return (
        <Grid container spacing={2}
            className={'change_payment_item'}>

            <Grid item xs={6}>
                <div className={`payment_method_selector ${paymentMethod === 0 ? 'selected' : ''}`}
                    onClick={() => setPaymentMethod(0)}>
                    <div className={'card_inner'}>
                        <span>{t('common.bank_account')}</span>
                    </div>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div
                    className={`payment_method_selector ${paymentMethod === 1 ? 'selected' : ''}`}
                    onClick={() => setPaymentMethod(1)}>
                    <div className={'card_inner'}>
                        <span>{t('common.credit_card')}</span>
                    </div>
                </div>
            </Grid>
            <Grid item md={7} xs={12}>
                <Grid item xs={12}>{renderPaymentMethodFields()}</Grid>
            </Grid>
            <Grid item md={7} xs={12}>
                <BillingAddressPortal
                    address={address}
                    answers={policy.answers_snapshot}
                    errors={errors}
                    onChange={(key, value) => updateAddressAnswer(key, value)}
                />
            </Grid>
            <Grid item md={5} xs={12} className={'payment_save_btn_cnt'}>
                {processing ? (
                    <LoadingButton/>
                ) : (
                    <button className={'a_btn a_btn_action_1 btn_save'}
                        onClick={() => onUpdatePaymentMethodHandler()}
                    >{t('common.btn_save_changes')}</button>
                )}
            </Grid>
            {!!errors['common'] ? (
                <Grid item xs={12}>
                    <p className={'error_txt error_txt_md'}>{errors['common']}</p>
                </Grid>
            ) : null}
        </Grid>
    )
}

export default withTranslation()(ChangePaymentMethod)