import React from "react";

export const PL = () => (
    <svg width="35" height="35" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32 16.1667C32.0057 18.3665 31.4918 20.5365 30.5 22.5C29.3241 24.853 27.5162 26.832 25.2791 28.2155C23.0419 29.599 20.4637 30.3324 17.8333 30.3334C15.6336 30.3391 13.4635 29.8252 11.5 28.8334L2 32L5.16667 22.5C4.17489 20.5365 3.66093 18.3665 3.66667 16.1667C3.66768 13.5363 4.40102 10.9581 5.78453 8.72096C7.16804 6.4838 9.14709 4.67599 11.5 3.50005C13.4635 2.50827 15.6336 1.99431 17.8333 2.00005H18.6667C22.1406 2.1917 25.4217 3.65798 27.8819 6.11815C30.3421 8.57831 31.8083 11.8595 32 15.3334V16.1667Z" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear" x1="21.5" y1="-5" x2="55.5" y2="95.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00BFFF" offset="1"/>
                <stop offset="1" stopColor="#003EFF"/>
            </linearGradient>
        </defs>
    </svg>
)