export const coalitionCYBER = ()=>{
  return     {
    aoi: [],
    payment_integrations:['ASCEND'],
    business_name: "Frisco Barber Shop",
    "carrier_metadata": {
      "quote_response": {
        "account_uuid": "dc8f77c9-a4e7-4779-a8bf-4a256e74055b",
        "aggregate_limit": 250000,
        "application_responses": {
          "administrative_dual_control": "N/A",
          "aware_of_new_claims": "No",
          "aware_of_new_claims_explanation": null,
          "aware_of_prior_claims": "No",
          "aware_of_prior_claims_amount": null,
          "aware_of_prior_claims_count": null,
          "aware_of_prior_claims_explanation": null,
          "backup_and_restore": "Yes",
          "cc_customer_count": null,
          "content_complaints": "No",
          "dual_control": "Yes",
          "dual_control_5k": "Yes",
          "encrypts_data": "Yes",
          "engaged_industries": [],
          "has_eo_mpl": "Yes",
          "has_tech_eo": "Yes",
          "mfa_email": "Yes",
          "mfa_other_privileged_accounts": "Yes",
          "mfa_remote_access": "No",
          "mfa_vpn": "Yes",
          "mitigating_clauses": null,
          "pii_phi": "No",
          "pii_phi_count": null,
          "prior_claims": "No",
          "prior_claims_explanation": null,
          "professional_services": null,
          "reviews_content": "Yes",
          "services_by_contract": null,
          "tech_eo_dispute": null,
          "tech_eo_dispute_explanation": null
        },
        "binder": null,
        "broker": {
          "email": "kuldeep@b2z-insurance.com",
          "external_id": null,
          "name": "Kuldeep Malik",
          "uuid": "a3b1e608-a87e-44e7-b4a4-2c847f0be471"
        },
        "bundle": null,
        "coalition_policy_number": null,
        "company_address": {
          "administrative_area": "TX",
          "city": "Frisco",
          "postcode": "75033",
          "state": "TX",
          "street_line_1": "6201 Technology Dr",
          "street_line_2": null,
          "sub_administrative_area": null
        },
        "company_employee_count": "1-25",
        "company_gross_profit_net_revenue": 97999,
        "company_industry": {
          "display_name": "Diversified Consumer Services - Specialized Consumer Services (Barber)",
          "gics_code": "25302020",
          "ibc_code": "7296",
          "id": 327,
          "naics_code": "812111",
          "reject_business_interruption": false,
          "reject_ftf": false,
          "reject_media_liability": false,
          "reject_tech_eo": false,
          "require_eo_mpl": false,
          "require_tech_eo": false,
          "use_gross_profile_net_revenue": null,
          "use_gross_profit_net_revenue": false
        },
        "company_name": "Frisco Barber Shop",
        "company_revenue": 98000,
        "coverage_instances": {
          "asset_restoration": {
            "sublimit": null,
            "subretention": null
          },
          "bipd_first_party": {
            "sublimit": 250000,
            "subretention": null
          },
          "bipd_third_party": {
            "sublimit": 250000,
            "subretention": null
          },
          "breach_response_costs": {
            "sublimit": null,
            "subretention": null
          },
          "breach_response_services": {
            "sublimit": null,
            "subretention": 0
          },
          "business_interruption": {
            "sublimit": null,
            "subretention": null
          },
          "computer_replacement": {
            "sublimit": null,
            "subretention": null
          },
          "crisis_management": {
            "sublimit": null,
            "subretention": null
          },
          "extortion": {
            "sublimit": null,
            "subretention": null
          },
          "funds_transfer": null,
          "media_liability": {
            "sublimit": null,
            "subretention": null
          },
          "network": {
            "sublimit": null,
            "subretention": null
          },
          "pci": {
            "sublimit": null,
            "subretention": null
          },
          "pollution": {
            "sublimit": 250000,
            "subretention": null
          },
          "regulatory_defense": {
            "sublimit": null,
            "subretention": null
          },
          "reputation": {
            "sublimit": null,
            "subretention": null
          },
          "reputational_harm": {
            "sublimit": null,
            "subretention": null
          }
        },
        "created_at": "2023-08-16T18:05:40Z",
        "created_by": {
          "email": "kuldeep@b2z-insurance.com",
          "external_id": null,
          "name": "Kuldeep Malik",
          "uuid": "a3b1e608-a87e-44e7-b4a4-2c847f0be471"
        },
        "currency_code": "USD",
        "default_retention": 2500,
        "documents": [],
        "domain_names": [
          "friscobarbershop.com"
        ],
        "effective_date": "2023-08-25T00:00:00Z",
        "end_date": "2024-08-16T00:00:00Z",
        "insurance_market": "ADMITTED",
        "intended_policyholder": {
          "address": {
            "administrative_area": "TX",
            "city": "Frisco",
            "postcode": "75033",
            "state": "TX",
            "street_line_1": "6201 Technology Dr",
            "street_line_2": null,
            "sub_administrative_area": null
          },
          "email": "steven@acrisuretechnology.com",
          "name": "Ernesto Fernandez",
          "phone_number": "+19723359104",
          "uuid": "24235116-9757-484c-a5d0-32e34112b873"
        },
        "kind": null,
        "lifecycle_state": "INCOMPLETE",
        "policy": null,
        "policyholder_local_currency_code": "USD",
        "premium": 296,
        "quote": null,
        "renewal_of": null,
        "renewal_quotes": [],
        "requires_review": true,
        "retroactive_date": "1970-01-01T00:00:00Z",
        "should_display_surcharges": null,
        "should_email_policyholder": false,
        "surcharges": [],
        "tria_accepted": true,
        "underwriting_complete": true,
        "updated_at": "2023-08-16T18:06:13Z",
        "uuid": "e5ad4049-c13c-41fb-b93b-3a5fd17d9790"
      }
    },
    "creation_date": "2023-08-16T18:05:40Z",
    "effective_date": "2023-08-25",
    "expiration_date": "2024-08-16",
    "limits": {
      "cyber_aggregate_limit": {
        "available_options": [
          25000,
          50000,
          100000,
          250000,
          500000,
          750000,
          1000000
        ],
        "display_name": "Aggregate Limit",
        "options_type": "Choices",
        "question_id": "cyber.coverage.aggregate_limit",
        "type": "Currency",
        "value": "250000"
      },
      "cyber_retention_limit": {
        "available_options": [
          1000,
          1500,
          2500,
          5000,
          6000,
          7500,
          10000,
          12500,
          15000,
          20000,
          25000,
          50000
        ],
        "display_name": "Retention Limit",
        "dynamic_limits": {
          "25000": {
            "options_max": 5000,
            "options_min": 1000
          },
          "250000": {
            "options_max": 12500,
            "options_min": 1000
          },
          "500000": {
            "options_max": 25000,
            "options_min": 1000
          },
          "750000": {
            "options_max": 25000,
            "options_min": 1500
          },
          "1000000": {
            "options_max": 50000,
            "options_min": 2500
          }
        },
        "options_type": "Choices",
        "question_id": "cyber.coverage.retention_limit",
        "type": "Currency",
        "value": "2500"
      }
    },
    "id": 59666,
    "line_of_business": "CYBER",
    "optional_coverages": [
      {
        "aggregate_limit": 250000,
        "coverage_name": "BIPD First Party",
        "group": "First Party",
        "qid": "cyber.coalition.coverage_instances_bipd_first_party",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Extortion",
        "group": "First Party",
        "qid": "cyber.coalition.coverage_instances_extortion",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Crisis Management",
        "group": "First Party",
        "qid": "cyber.coalition.coverage_instances_crisis_management",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Breach Response Costs",
        "group": "First Party",
        "qid": "cyber.coalition.coverage_instances_breach_response_costs",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Business Interruption",
        "group": "First Party",
        "qid": "cyber.coalition.coverage_instances_business_interruption",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Regulatory Defense",
        "group": "Third Party",
        "qid": "cyber.coalition.coverage_instances_regulatory_defense",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Media Liability",
        "group": "Third Party",
        "qid": "cyber.coalition.coverage_instances_media_liability",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "PCI Breach",
        "group": "Third Party",
        "qid": "cyber.coalition.coverage_instances_pci",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Funds Transfer",
        "group": "First Party",
        "qid": "cyber.coalition.coverage_instances_funds_transfer",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Asset Restoration",
        "group": "First Party",
        "qid": "cyber.coalition.coverage_instances_asset_restoration",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "BIPD Third Party",
        "group": "Third Party",
        "qid": "cyber.coalition.coverage_instances_bipd_third_party",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Reputation",
        "group": "First Party",
        "qid": "cyber.coalition.coverage_instances_reputation",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Reputational Harm",
        "group": "First Party",
        "qid": "cyber.coalition.coverage_instances_reputational_harm",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Computer Replacement",
        "group": "First Party",
        "qid": "cyber.coalition.coverage_instances_computer_replacement",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      },
      {
        "aggregate_limit": 250000,
        "coverage_name": "Pollution",
        "group": "Third Party",
        "qid": "cyber.coalition.coverage_instances_pollution",
        "required": false,
        "retention_limit": 2500,
        "term_premium": 0
      }
    ],
    "payment_formats": [
      {
        "id": 1,
        "installments": [
          {
            "due_amount": "296.00",
            "id": 1,
            "interval_rule": {
              "kwargs": {},
              "method": "first_payment"
            },
            "refundable_amount": "207.20"
          }
        ],
        "name": "Annual"
      },
      {
        "id": 2,
        "installments": [
          {
            "due_amount": "24.67",
            "id": 2,
            "interval_rule": {
              "kwargs": {},
              "method": "first_payment"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 3,
            "interval_rule": {
              "kwargs": {
                "months": 1
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 4,
            "interval_rule": {
              "kwargs": {
                "months": 2
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 5,
            "interval_rule": {
              "kwargs": {
                "months": 3
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 6,
            "interval_rule": {
              "kwargs": {
                "months": 4
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 7,
            "interval_rule": {
              "kwargs": {
                "months": 5
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 8,
            "interval_rule": {
              "kwargs": {
                "months": 6
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 9,
            "interval_rule": {
              "kwargs": {
                "months": 7
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 10,
            "interval_rule": {
              "kwargs": {
                "months": 8
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 11,
            "interval_rule": {
              "kwargs": {
                "months": 9
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 12,
            "interval_rule": {
              "kwargs": {
                "months": 10
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          },
          {
            "due_amount": "24.67",
            "id": 13,
            "interval_rule": {
              "kwargs": {
                "months": 11
              },
              "method": "relativedelta"
            },
            "refundable_amount": "24.67"
          }
        ],
        "name": "Monthly"
      }
    ],
    "policy_id": "",
    "policy_number": "",
    "producer_changes": [],
    "producer_messages": [],
    "quote_id": "e5ad4049-c13c-41fb-b93b-3a5fd17d9790",
    "quote_number": "CYBER-2023-59666",
    source: "COALITION",
    "status": "success",
    "street_address": "6201 Technology Dr",
    "term": "12",
    "term_premium": 296,
    "tria_premium": 0,
    "user_id": "68911f3f-bc4f-4d72-994d-092783073d4a",
    "zip_code": "75033",
    "quote_type": "CYBER",
    "data": {
      "aoi": [],
      "business_name": "Frisco Barber Shop",
      "carrier_metadata": {
        "quote_response": {
          "account_uuid": "dc8f77c9-a4e7-4779-a8bf-4a256e74055b",
          "aggregate_limit": 250000,
          "application_responses": {
            "administrative_dual_control": "N/A",
            "aware_of_new_claims": "No",
            "aware_of_new_claims_explanation": null,
            "aware_of_prior_claims": "No",
            "aware_of_prior_claims_amount": null,
            "aware_of_prior_claims_count": null,
            "aware_of_prior_claims_explanation": null,
            "backup_and_restore": "Yes",
            "cc_customer_count": null,
            "content_complaints": "No",
            "dual_control": "Yes",
            "dual_control_5k": "Yes",
            "encrypts_data": "Yes",
            "engaged_industries": [],
            "has_eo_mpl": "Yes",
            "has_tech_eo": "Yes",
            "mfa_email": "Yes",
            "mfa_other_privileged_accounts": "Yes",
            "mfa_remote_access": "No",
            "mfa_vpn": "Yes",
            "mitigating_clauses": null,
            "pii_phi": "No",
            "pii_phi_count": null,
            "prior_claims": "No",
            "prior_claims_explanation": null,
            "professional_services": null,
            "reviews_content": "Yes",
            "services_by_contract": null,
            "tech_eo_dispute": null,
            "tech_eo_dispute_explanation": null
          },
          "binder": null,
          "broker": {
            "email": "kuldeep@b2z-insurance.com",
            "external_id": null,
            "name": "Kuldeep Malik",
            "uuid": "a3b1e608-a87e-44e7-b4a4-2c847f0be471"
          },
          "bundle": null,
          "coalition_policy_number": null,
          "company_address": {
            "administrative_area": "TX",
            "city": "Frisco",
            "postcode": "75033",
            "state": "TX",
            "street_line_1": "6201 Technology Dr",
            "street_line_2": null,
            "sub_administrative_area": null
          },
          "company_employee_count": "1-25",
          "company_gross_profit_net_revenue": 97999,
          "company_industry": {
            "display_name": "Diversified Consumer Services - Specialized Consumer Services (Barber)",
            "gics_code": "25302020",
            "ibc_code": "7296",
            "id": 327,
            "naics_code": "812111",
            "reject_business_interruption": false,
            "reject_ftf": false,
            "reject_media_liability": false,
            "reject_tech_eo": false,
            "require_eo_mpl": false,
            "require_tech_eo": false,
            "use_gross_profile_net_revenue": null,
            "use_gross_profit_net_revenue": false
          },
          "company_name": "Frisco Barber Shop",
          "company_revenue": 98000,
          "coverage_instances": {
            "asset_restoration": {
              "sublimit": null,
              "subretention": null
            },
            "bipd_first_party": {
              "sublimit": 250000,
              "subretention": null
            },
            "bipd_third_party": {
              "sublimit": 250000,
              "subretention": null
            },
            "breach_response_costs": {
              "sublimit": null,
              "subretention": null
            },
            "breach_response_services": {
              "sublimit": null,
              "subretention": 0
            },
            "business_interruption": {
              "sublimit": null,
              "subretention": null
            },
            "computer_replacement": {
              "sublimit": null,
              "subretention": null
            },
            "crisis_management": {
              "sublimit": null,
              "subretention": null
            },
            "extortion": {
              "sublimit": null,
              "subretention": null
            },
            "funds_transfer": null,
            "media_liability": {
              "sublimit": null,
              "subretention": null
            },
            "network": {
              "sublimit": null,
              "subretention": null
            },
            "pci": {
              "sublimit": null,
              "subretention": null
            },
            "pollution": {
              "sublimit": 250000,
              "subretention": null
            },
            "regulatory_defense": {
              "sublimit": null,
              "subretention": null
            },
            "reputation": {
              "sublimit": null,
              "subretention": null
            },
            "reputational_harm": {
              "sublimit": null,
              "subretention": null
            }
          },
          "created_at": "2023-08-16T18:05:40Z",
          "created_by": {
            "email": "kuldeep@b2z-insurance.com",
            "external_id": null,
            "name": "Kuldeep Malik",
            "uuid": "a3b1e608-a87e-44e7-b4a4-2c847f0be471"
          },
          "currency_code": "USD",
          "default_retention": 2500,
          "documents": [],
          "domain_names": [
            "friscobarbershop.com"
          ],
          "effective_date": "2023-08-25T00:00:00Z",
          "end_date": "2024-08-16T00:00:00Z",
          "insurance_market": "ADMITTED",
          "intended_policyholder": {
            "address": {
              "administrative_area": "TX",
              "city": "Frisco",
              "postcode": "75033",
              "state": "TX",
              "street_line_1": "6201 Technology Dr",
              "street_line_2": null,
              "sub_administrative_area": null
            },
            "email": "steven@acrisuretechnology.com",
            "name": "Ernesto Fernandez",
            "phone_number": "+19723359104",
            "uuid": "24235116-9757-484c-a5d0-32e34112b873"
          },
          "kind": null,
          "lifecycle_state": "INCOMPLETE",
          "policy": null,
          "policyholder_local_currency_code": "USD",
          "premium": 296,
          "quote": null,
          "renewal_of": null,
          "renewal_quotes": [],
          "requires_review": true,
          "retroactive_date": "1970-01-01T00:00:00Z",
          "should_display_surcharges": null,
          "should_email_policyholder": false,
          "surcharges": [],
          "tria_accepted": true,
          "underwriting_complete": true,
          "updated_at": "2023-08-16T18:06:13Z",
          "uuid": "e5ad4049-c13c-41fb-b93b-3a5fd17d9790"
        }
      },
      "creation_date": "2023-08-16T18:05:40Z",
      "effective_date": "2023-08-25",
      "expiration_date": "2024-08-16",
      "id": 59666,
      "limits": {
        "cyber_aggregate_limit": {
          "available_options": [
            25000,
            50000,
            100000,
            250000,
            500000,
            750000,
            1000000
          ],
          "display_name": "Aggregate Limit",
          "options_type": "Choices",
          "question_id": "cyber.coverage.aggregate_limit",
          "type": "Currency",
          "value": "250000"
        },
        "cyber_retention_limit": {
          "available_options": [
            1000,
            1500,
            2500,
            5000,
            6000,
            7500,
            10000,
            12500,
            15000,
            20000,
            25000,
            50000
          ],
          "display_name": "Retention Limit",
          "dynamic_limits": {
            "25000": {
              "options_max": 5000,
              "options_min": 1000
            },
            "250000": {
              "options_max": 12500,
              "options_min": 1000
            },
            "500000": {
              "options_max": 25000,
              "options_min": 1000
            },
            "750000": {
              "options_max": 25000,
              "options_min": 1500
            },
            "1000000": {
              "options_max": 50000,
              "options_min": 2500
            }
          },
          "options_type": "Choices",
          "question_id": "cyber.coverage.retention_limit",
          "type": "Currency",
          "value": "2500"
        }
      },
      "line_of_business": "CYBER",
      "optional_coverages": [
        {
          "aggregate_limit": 250000,
          "coverage_name": "BIPD First Party",
          "group": "First Party",
          "qid": "cyber.coalition.coverage_instances_bipd_first_party",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Extortion",
          "group": "First Party",
          "qid": "cyber.coalition.coverage_instances_extortion",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Crisis Management",
          "group": "First Party",
          "qid": "cyber.coalition.coverage_instances_crisis_management",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Breach Response Costs",
          "group": "First Party",
          "qid": "cyber.coalition.coverage_instances_breach_response_costs",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Business Interruption",
          "group": "First Party",
          "qid": "cyber.coalition.coverage_instances_business_interruption",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Regulatory Defense",
          "group": "Third Party",
          "qid": "cyber.coalition.coverage_instances_regulatory_defense",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Media Liability",
          "group": "Third Party",
          "qid": "cyber.coalition.coverage_instances_media_liability",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "PCI Breach",
          "group": "Third Party",
          "qid": "cyber.coalition.coverage_instances_pci",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Funds Transfer",
          "group": "First Party",
          "qid": "cyber.coalition.coverage_instances_funds_transfer",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Asset Restoration",
          "group": "First Party",
          "qid": "cyber.coalition.coverage_instances_asset_restoration",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "BIPD Third Party",
          "group": "Third Party",
          "qid": "cyber.coalition.coverage_instances_bipd_third_party",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Reputation",
          "group": "First Party",
          "qid": "cyber.coalition.coverage_instances_reputation",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Reputational Harm",
          "group": "First Party",
          "qid": "cyber.coalition.coverage_instances_reputational_harm",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Computer Replacement",
          "group": "First Party",
          "qid": "cyber.coalition.coverage_instances_computer_replacement",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        },
        {
          "aggregate_limit": 250000,
          "coverage_name": "Pollution",
          "group": "Third Party",
          "qid": "cyber.coalition.coverage_instances_pollution",
          "required": false,
          "retention_limit": 2500,
          "term_premium": 0
        }
      ],
      "payment_formats": [
        {
          "id": 1,
          "installments": [
            {
              "due_amount": "296.00",
              "id": 1,
              "interval_rule": {
                "kwargs": {},
                "method": "first_payment"
              },
              "refundable_amount": "207.20"
            }
          ],
          "name": "Annual"
        },
        {
          "id": 2,
          "installments": [
            {
              "due_amount": "24.67",
              "id": 2,
              "interval_rule": {
                "kwargs": {},
                "method": "first_payment"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 3,
              "interval_rule": {
                "kwargs": {
                  "months": 1
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 4,
              "interval_rule": {
                "kwargs": {
                  "months": 2
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 5,
              "interval_rule": {
                "kwargs": {
                  "months": 3
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 6,
              "interval_rule": {
                "kwargs": {
                  "months": 4
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 7,
              "interval_rule": {
                "kwargs": {
                  "months": 5
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 8,
              "interval_rule": {
                "kwargs": {
                  "months": 6
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 9,
              "interval_rule": {
                "kwargs": {
                  "months": 7
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 10,
              "interval_rule": {
                "kwargs": {
                  "months": 8
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 11,
              "interval_rule": {
                "kwargs": {
                  "months": 9
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 12,
              "interval_rule": {
                "kwargs": {
                  "months": 10
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            },
            {
              "due_amount": "24.67",
              "id": 13,
              "interval_rule": {
                "kwargs": {
                  "months": 11
                },
                "method": "relativedelta"
              },
              "refundable_amount": "24.67"
            }
          ],
          "name": "Monthly"
        }
      ],
      "policy_id": "",
      "policy_number": "",
      "producer_changes": [],
      "producer_messages": [],
      "quote_id": "e5ad4049-c13c-41fb-b93b-3a5fd17d9790",
      "quote_number": "CYBER-2023-59666",
      source: "COALITION",
      "status": "success",
      "street_address": "6201 Technology Dr",
      "term": "12",
      "term_premium": 296,
      "tria_premium": 0,
      "user_id": "68911f3f-bc4f-4d72-994d-092783073d4a",
      "zip_code": "75033"
    },
    "type": "CYBER",
    "carrierConfig": {
      "name": "COALITION",
      "displayName": "Coalition",
      "paymentMethod": "AGENCY",
      "integrations": [
        "STRIPE"
      ],
      "stripePublicToken": process.env?.stripePublicToken, // add stripePublicToken to your local env vars prior to testing locally
      "stripePublishableToken": process.env?.stripePublishableToken // add stripePublishableToken to your local env vars prior to testing locally
    },
    "payment_token_id": "tok_1Nfo70ECqfJ8vsnJQXlqpVgY"
  }

}