import axios from "axios";

const DoRequest = async ({url, method, body={}, onSuccess, onError= undefined}) => {
    try {
        const response = await axios[method](url, body)

        if (onSuccess) {
            onSuccess(response)
        }
        return response
    } catch (e) {
        if (onError) {
            onError(e)
        }
        return e
    }
}

export default DoRequest