import React, { useEffect, useState } from 'react'
import withWidth from '@material-ui/core/withWidth'
import { TabContext, TabPanel } from '@material-ui/lab'
import { Tab, Tabs } from '@material-ui/core'
// import DownloadingCertificatesLink from '../../components/portal/DownloadingCertificatesLink'
// import DownloadingDocumentsLink from '../../components/portal/DownloadingDocumentsLink'
import FailedInstallments from '../../components/portal/payment/failedInstallments'
import SummaryTab from '../../components/portal/summary/Summary'
import Policies from '../../components/portal/summary/Policies'
import CarrierClaims from '../../components/portal/summary/CarrierClaims'
import { sortBy } from 'lodash'
import { connect, useStore } from 'react-redux'
import policyType from '../../helpers/policyType'
import '../../scss/v2/portal/_summary.scss'

const PortalHome = (props) => {

  const config = useStore()

  const [policies, setPolicies] = useState([])
  const [carriers, setCarriers] = useState([])

  const [upcomingBills, setUpcomingBills] = useState(null)
  const [paidPayments, setPaidPayments] = useState(null)
  const [feedsRSS] = useState([])

  const [currentTab, setCurrentTab] = useState('0')
  const [headTitle, setHeadTitle] = useState('')
  const [selectedPolicy, setSelectedPolicy] = useState(null)

  // const fetchRSSFeeds = () => {
  //   axios.get('/api/rss_feed').then(response => {
  //     setFeedsRSS(response.data.items)
  //   }).catch(e => {
  //
  //     console.log(`Can't load RSS feeds cards`, e)
  //     setFeedsRSS([])
  //   })
  // }

  // useEffect(() => {
  //   fetchRSSFeeds()
  // }, [])

  const onChangeTab = (event, newValue) => {
    setCurrentTab(newValue)
    setSelectedPolicy(null)
  }

  useEffect(() => {

    const getPoliciesCarriers = () => {
      let carriers = []
      policies.forEach((item) => {
        if (item.answers_snapshot['business.state'] !== 'FL') {
          if (!carriers.includes(item.carrier)) {
            carriers.push(item.carrier)
          }
        } else {
          if (!carriers.includes(`${item.carrier}_${item.answers_snapshot['business.state']}`)) {
            carriers.push(`${item.carrier}_${item.answers_snapshot['business.state']}`)
          }
        }
      })
      setCarriers(carriers)
    }

    getPoliciesCarriers()
  }, [policies])

  useEffect(() => {
    setPolicies(props.policiesList)
  }, [props.policiesLoading, props.policiesList])

  useEffect(() => {
    const onHeadTitleHandler = (tabIndex) => {
      switch (tabIndex) {
        case '0':
          return `Nice to see you, ${config.getState().portal.name}! Welcome to Coverage Central.`
        case '1':
          return 'View your current policies.'
        case '2':
          return 'Information about filing a claim.'
        default:
          return ''
      }
    }

    setHeadTitle(onHeadTitleHandler(currentTab))
  }, [currentTab, config])

  useEffect(() => {
    const getUpcomingBills = () => {
      let paid = null
      let upcoming = []

      policies.forEach(itm => {
        let paid_installments = sortBy(itm.installments, 'payment_installment_id').filter(itm => !!itm.paid_date)
        paid = paid_installments.length > 0 ? paid_installments[0] : null

        let upcoming_installments = sortBy(itm.installments, 'payment_installment_id').filter(itm => !itm.paid_date)
        if (upcoming_installments.length > 0 && itm?.billing_method === 'AGENCY') {
          upcoming_installments[0].policyType = policyType(itm.type).longName
          upcoming_installments[0].policyNumber = itm.quote.policyNumber || itm.quote.policy_number
          upcoming_installments[0].policy = itm
          if (paid) {
            upcoming_installments[0].last_four = paid.last_four_digits || paid.last_four
            upcoming_installments[0].payment_type = paid.payment_type
          }

          upcoming.push(upcoming_installments[0])
        }
      })
      setUpcomingBills(upcoming)
    }

    const getPaidPayments = () => {
      let paid = []

      policies.forEach(itm => {
        let paid_installments = sortBy(itm.installments, 'payment_installment_id').filter(itm => !!itm.paid_date)

        if (paid_installments.length > 0) {
          paid_installments = paid_installments.map(i => {
            i.policyType = policyType(itm.type).longName
            i.policyNumber = itm.quote.policyNumber || itm.quote.policy_number
            i.policy = itm

            return i
          })
          paid = paid.concat(paid_installments)
        }
      })
      let paidPayments = sortBy(paid.slice(0, 3), 'paid_date').reverse()
      setPaidPayments(paidPayments)
    }

    getUpcomingBills()
    getPaidPayments()

  }, [policies])

  const selectPolicy = (policy) => {
    setSelectedPolicy(policy)
    setCurrentTab('1')
  }

  return (
    <div className="portal_page portal_summary_page">
      {props.failedInstallmentsList.length ? <div className="cnt_full portal_failed_installments_cnt">
        <div className="cnt"><FailedInstallments failedInstallments={props.failedInstallmentsList} className={null}/></div>
      </div> : null}
      <div className="cnt_full">
        <div className="cnt head_cnt">
          <h1 className={'head_ttl'}>
            {headTitle}
          </h1>
        </div>
        <TabContext value={currentTab}>
          <div className="cnt">
            <div className="cnt_nav">
              <Tabs className={'tab_nav_cnt'} aria-label="tabs"
                    value={currentTab}
                    onChange={onChangeTab}>
                <Tab className={'tab_nav_item'} label="Summary" value="0"/>
                <Tab className={'tab_nav_item'} label="Policies " value="1"/>
                <Tab className={'tab_nav_item'} label="Claims" value="2"/>
              </Tabs>
              {/*<div className="link_nav_cnt">*/}
              {/*  <DownloadingCertificatesLink policiesList={props.policiesList}/>*/}
              {/*  <DownloadingDocumentsLink policiesList={props.policiesList}/>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="cnt">
            <TabPanel className={'cnt_portal_sidebar'} value="0" index={0}>
              <SummaryTab policies={props.policiesList}
                          selectPolicy={selectPolicy}
                          policyLoading={props.policiesLoading}
                          paidPayments={paidPayments}
                          upcomingBills={upcomingBills}
                          feedsRSS={feedsRSS}/>
            </TabPanel>
            <TabPanel className={'cnt_portal_sidebar'} value="1" index={1}>
              <Policies policies={props.policiesList}
                        selectedPolicyRef={selectedPolicy}
                        paidPayments={paidPayments}
                        policyLoading={props.policiesLoading}/>
            </TabPanel>
            <TabPanel className={'cnt_portal'} value="2" index={2}>
              <CarrierClaims
                loading={props.policiesLoading}
                carriers={carriers}/>
            </TabPanel>
          </div>
        </TabContext>
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    policiesList: state.policies.policiesList,
    failedInstallmentsList: state.policies.failedInstallmentsList,
    policiesLoading: state.policies.policiesLoading
  }
}
export default connect(mapStateToProps, null)(withWidth()(PortalHome))