import React from "react";
import clsx from "clsx";
import {FormControl, Grid, Typography} from "@material-ui/core";
import sources_update from '../../../common/decorator'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'


class StyledBoolean extends React.Component {

    state = {
        checked: false
    }

    componentDidMount() {
        if (this.props.answer === "Yes") this.setState({checked: true})
    }

    onBooleanChange(value) {
        let val = value === true ? 'Yes' : 'No'
        this.setState({checked: !this.state.checked})
        if (this.props.onChange) this.props.onChange(this.props.id, val)
        sources_update(this.props.answers, this.props.qid)
    }


    render() {
        let classes = clsx({
            'switch-body': true,
            'checked': this.state.checked
        })

        return (
            <FormControl fullWidth={this.props.fullWidth} error={this.props.error}>
                <Grid item className={'additional-question-item'}>
                    <Grid container alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item className={'additional-question-switcher-item'}>
                            <div className="switch-wrapper">
                            <span className={classes}>
                                <label htmlFor={'switch-' + this.props.id}>
                                    <input type="checkbox" id={'switch-' + this.props.id}
                                           onChange={(e) => this.onBooleanChange(e.target.checked)}
                                           checked={this.state.checked}/>
                                    <span className="thumb"/>
                                </label>
                            </span>
                            <Typography
                                className={'additional-question-text'}>{this.props.question}&nbsp;{this.props.tooltip}</Typography>
                            </div>
                            </Grid>
                        {this.props.diya_helper && <Grid item xs={12}>
                            <Typography className="custom-question-diya-helper">{this.props.diya_helper}</Typography>
                        </Grid>}
                    </Grid>
                </Grid>
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
})

export default connect(mapStateToProps)(withTranslation()(StyledBoolean))
