import React, {useState} from "react"
import {withTranslation} from 'react-i18next';
import {Box, Grid} from "@material-ui/core";
import SectionHead from "../../components/insurance/SectionHead";
import {BrokenLink} from "../../components/icons/BrokenLink";
import Layout from "../../layouts/insurance/Layout";
import SectionIndicator from "../../components/insurance/SectionIndicator";

const NotFoundError = ({t}) => {
    const insuranceErrorSidebar = <div
        className="MuiBox-root jss1385 sections-indicator">
        <div className="stepper"
             style={{marginTop: '-75px'}}>
            <div
                className="step completed">
                <div className="number">
                    <div
                        className="glue-top"></div>
                    <div
                        className="glue-bottom"></div>
                    <div
                        className="indicator">
                        <svg width="20"
                             height="20"
                             viewBox="0 0 20 20"
                             fill="#172152"
                             stroke="#ABBAC7"
                             xmlns="http://www.w3.org/2000/svg">
                            <circle
                                cx="10"
                                cy="10"
                                r="8"
                                fill="inherit"
                                stroke="inherit"
                                stroke-width="4"></circle>
                        </svg>
                    </div>
                </div>
                <div className="label">
                    <div
                        className="name">Account
                        Info
                    </div>
                </div>
            </div>
            <div
                className="step active completed">
                <div className="number">
                    <div
                        className="glue-top"></div>
                    <div
                        className="glue-bottom"></div>
                    <div
                        className="indicator">
                        <svg width="20"
                             height="20"
                             viewBox="0 0 20 20"
                             fill="#172152"
                             stroke="#ABBAC7"
                             xmlns="http://www.w3.org/2000/svg">
                            <circle
                                cx="10"
                                cy="10"
                                r="8"
                                fill="inherit"
                                stroke="inherit"
                                stroke-width="4"></circle>
                        </svg>
                    </div>
                </div>
                <div className="label">
                    <div
                        className="name">Customize Rate
                    </div>
                    <div
                        className="progress">
                        <div
                            className="MuiLinearProgress-root MuiLinearProgress-colorPrimary MuiLinearProgress-determinate"
                            role="progressbar"
                            aria-valuenow="67">
                            <div
                                className="MuiLinearProgress-bar MuiLinearProgress-barColorPrimary MuiLinearProgress-bar1Determinate"
                                style={{transform: 'translateX(-33%)'}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    const referrer = document.referrer;
    const [backLink] = useState(referrer !== window.location.href ? referrer : '/')
    return (
        <Layout
            sidebar={<SectionIndicator html_content={insuranceErrorSidebar}/>}
            sections={<Box px={{xs: 3, md: 7}}>
                <Grid container spacing={2} className={'not_found_section_head'}>
                    <SectionHead
                        title={t('page.not_found.title')}
                        txt={t('page.not_found.desc')}/>
                </Grid>
                <Grid container spacing={2} direction={'column'}
                      className={'cookies_disabled_content not_found_section_content'}>

                    {backLink && (
                        <Box pb={6} textAlign="center">
                          <a href={backLink} className={'a_btn a_btn_action_1 a_btn_not_found_back'}> Go Back</a>
                        </Box>
                    )}
                    <Box textAlign="center">
                        <BrokenLink style={{
                            marginLeft: '-3em',
                            maxWidth: '100%'
                        }}/>
                    </Box>
                </Grid>
            </Box>}
        />
    )
}

export default withTranslation()(NotFoundError)
