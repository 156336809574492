import React, {Component} from 'react';
import {PhonePortal} from "../../components/icons/PhonePortal";
import {EmailPortal} from "../../components/icons/EmailPortal";
import {HelpPortal} from "../../components/icons/HelpPortal";
import '../../scss/v2/portal/_portal_contact_footer.scss'


class ContactFooter extends Component {
    activateZendeskChat(e) {
        e.preventDefault()
        window.zE.activate()
    }

    render() {
        return (
            <div className="portal_contact_cnt">
                <div className="contact_cnt">

                    <p className="contact_ttl">Contact Us</p>
                    <div className="contact_list">
                        <div className="contact_item"><a className={'item_wrapper'} href="tel:800-330-1750">
                            <div className="item_icon">
                                <PhonePortal/>
                            </div>
                            <span className="item_ttl">Phone</span>
                            <span className="item_txt">800-330-1750</span>
                        </a></div>
                        <div className="contact_item"><a className={'item_wrapper'}
                                                         href="mailto:cl_service@acrisure.com">
                            <div className="item_icon">
                                <EmailPortal/>
                            </div>
                            <span className="item_ttl">Email</span>
                            <span className="item_txt">cl_service@acrisure.com</span>
                        </a></div>

                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <div className="contact_item"><a className={'item_wrapper'} href="https://www.b2z-insurance.com/faq" target="_blank">
                            <div className="item_icon">
                                <HelpPortal/>
                            </div>
                            <span className="item_ttl">More help</span>
                        </a></div>
                    </div>

                </div>
            </div>
        );
    }
}

export default ContactFooter;