import React from 'react'
import { some } from 'lodash'

import axios from 'axios'
import { connect } from 'react-redux'
import { Alert } from '@material-ui/lab'
import { withTranslation } from 'react-i18next'
import AcrisureLogo from '../../components/icons/new/AcrisureLogo'
import { setUserAuth } from '../../store/portal/actions'
import Question from '../../components/form-components/Question'
import { Grid, Snackbar } from '@material-ui/core'

import '../../scss/v2/_password_hints.scss'
import LoadingButton from "../../components/LoadingButton";

class FinalizeRegistration extends React.Component {

  state = {
    email: '',
    code: '',
    password: '',
    confirm: '',
    errors: {},
    notification: {
      show: false,
      type: 'success',
      text: ''
    },
    showPasswordHint: false,
    passwordHint: {

      uppercaseSymbol: {
        error: true,
        txt: 'An uppercase letter (A-Z)'
      },
      lowercaseSymbol: {
        error: true,
        txt: 'A lowercase letter (a-z)'
      },
      digitSymbol: {
        error: true,
        txt: 'A number (0-9)'
      },
      specialSymbol: {
        error: true,
        txt: 'A special character (!@#$)'
      },
      length: {
        error: true,
        txt: 'Range of 8 to 16 symbols length'
      }
    },
    reset_sent: false,
    show_code_field: false,
    processing: true
  }

  constructor (props) {
    super(props)

    if (this.props.history.location.search) {
      this.state.code = this.parseURLCode('temp_pass')
      this.state.email = this.parseURLCode('user_email')
    }
  }

  componentDidMount () {
    this.checkIfAlreadyLoggedIn()
    this.checkUserExists()
  }

  checkIfAlreadyLoggedIn () {
    if (this.props.isAuthorized) this.props.history.push('/portal/home')
  }

  checkUserExists () {
    axios.post('/api/user/check', {
      username: this.state.email
    })
      .then(response => {
        let exist = response.data['exists'] && response.data?.status !== 'FORCE_CHANGE_PASSWORD'
        //Take a next action based on result
        if (exist) {
          this.props.history.push('/sign-in')
        }
      })
      .catch(e => {
        throw new Error(`Can't check if user exists. ${e}`)
        //Else disable loading and register user
      })
      .finally(() => {
        this.setState({ processing: false })
      })
  }

  parseURLCode (key) {
    let value = new URLSearchParams(this.props.history.location.search)
    value = value.get(key)

    return value ? value : null
  }

  setCode (value) {
    this.setState({ code: value })
  }

  setConfirm (value) {
    this.setState({ confirm: value })
  }

  onChangeHint (field, value) {
    let passwordHint = { ...this.state.passwordHint }
    passwordHint[field].error = value
    this.setState({ passwordHint: { ...passwordHint } })
  }

  checkPasswordHandler = (value) => {
    let pass = value
    let lowerCaseLetters = /^(?=.*[a-z])/
    if (!lowerCaseLetters.test(pass)) {
      this.onChangeHint('lowercaseSymbol', true)
    } else {
      this.onChangeHint('lowercaseSymbol', false)
    }
    //Validate capital letters
    let upperCaseLetters = /^(?=.*[A-Z])/
    if (!upperCaseLetters.test(pass)) {
      this.onChangeHint('uppercaseSymbol', true)
    } else {
      this.onChangeHint('uppercaseSymbol', false)
    }
    //Validate a number
    let numbers = /^(?=.*\d)/
    if (!numbers.test(pass)) {
      this.onChangeHint('digitSymbol', true)
    } else {
      this.onChangeHint('digitSymbol', false)
    }
    //Validate length
    let pasLength = /^([^\s]){8,16}$/
    if (!pasLength.test(pass)) {
      this.onChangeHint('length', true)
    } else {
      this.onChangeHint('length', false)
    }
    //Validate Special Character
    let specialSymbol = /^(?=.*[^\w\d\s:])/
    if (!specialSymbol.test(pass)) {
      this.onChangeHint('specialSymbol', true)
    } else {
      this.onChangeHint('specialSymbol', false)
    }
    this.setState({ showPasswordHint: this.isPasswordHelpBoxHasErrors(), password: value })
  }

  validate (e) {
    e.preventDefault()

    let errors = {}

    const { t } = this.props

    if (this.state.code.length < 1) errors.code = t('auth.error.code_req')

    if (this.state.password !== this.state.confirm) errors.confirm = t('auth.error.pass_not_equal')

    Object.keys(this.state.passwordHint).forEach((item) => {
      if (this.state.passwordHint[item].error) {
        errors.password = 'common'
      }
    })
    if (errors.password) this.checkPasswordHandler(this.state.password)
    this.setState({ showPasswordHint: this.isPasswordHelpBoxHasErrors(), errors: errors })

    if (Object.keys(errors).length === 0) this.reset()
  }

  forgotPassword () {
    //request pass change
    axios.post('/api/reset-user-pass', {
      username: this.state.email
    })
      .then(response => {
        if (response.data.message) {
          //success notification
          this.setState({ reset_sent: true, show_code_field: true })
        }
      })
      .catch(e => {
        throw new Error(`Can't send reset pass email. ${e}`)
      })
  }

  reset () {
    const { t } = this.props

    axios.post('/api/user/confirm', {
      username: this.state.email,
      temp_password: this.state.code,
      new_password: this.state.password
    })
      .then(response => {
        this.setState({
          errors: {}
        }, () => {
          this.login()
        })
      })
      .catch(e => {
        //error notification
        this.setState({
          errors: {
            common: e.response.status === 400
              ? t('auth.error.user_not_found')
              : t('auth.error.unexpected_error_please_retry')
          }
        })
      })
  }

  login () {
    const { t } = this.props

    axios.post('/api/user/login', {
      username: this.state.email,
      password: this.state.password
    })
      .then(response => {
        if (response.data.authorized) {
          this.props.setUserAuth(response.data)
          this.props.history.push('/portal/home')
        }
      })
      .catch(e => {
        this.setState({
          errors: {
            login: e.response.status === 404
              ? t('auth.error.user_not_found')
              : t('common.something_went_wrong')
          },
          loading: false
        })
      })
  }

  closeNotification () {
    this.setState({ reset_sent: false })
  }

  isPasswordHelpBoxHasErrors () {
    let list = Object.keys(this.state.passwordHint).map((item) => {
      return { error: !!this.state.passwordHint[item].error }
    })
    return some(list, ['error', true])
  }

  onBlurPassField () {
    this.setState({ showPasswordHint: this.isPasswordHelpBoxHasErrors() })
  }

  render () {
    const { t } = this.props

    return (
      <div className={'insurance_content'}>


        {/*<div  className={'full-width-container'}>*/}
        <div className={'insurance_layout auth_layout'}>

          {this.state.processing ? (
              <Grid container spacing={6} alignContent="stretch"
                    justifyContent={'center'}
                    style={{height: '100%', margin: '0 -24px'}}>
                <Grid item>
                  <LoadingButton/>
                </Grid>
              </Grid>
          ) : (
            <form className={'auth_card finalise_registration_card'} autoComplete="off"
                  onSubmit={(e) => this.validate(e)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AcrisureLogo className={'auth_logo'}/>
                </Grid>
                <Grid item xs={12}>
                  <h1 className={'ttl'}>{t('auth.finalize_reg_title')}</h1>
                </Grid>

                {this.state.show_code_field && (
                  <Grid item xs={12}>
                    <Question fullWidth
                              type="password"
                              question={t('auth.temp_pass')}
                              answer={this.props.code}
                              error={!!this.props.errors?.code}
                              helperText={this.props.errors?.code}
                              onChange={(id, value) => this.setCode(value)}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Question fullWidth
                            type="password"
                            question={t('auth.new_pass')}
                            tooltip={t('auth.new_pass_tooltip')}
                            answer={this.state.password}
                            onBlur={() => this.onBlurPassField()}
                            onChange={(id, value) => {
                              this.checkPasswordHandler(value)
                            }}
                            passwordHint={this.state.passwordHint}
                            showPasswordHint={this.state.showPasswordHint}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Question fullWidth
                            type="password"
                            question={t('auth.confirm_pass')}
                            tooltip={t('auth.confirm_pass_tooltip')}
                            answer={this.state.confirm}
                            error={!!this.state.errors.confirm}
                            helperText={this.state.errors.confirm}
                            onChange={(id, value) => {
                              this.setConfirm(value)
                            }}
                  />
                </Grid>
                {!!this.state.errors.common && (
                  <Grid item xs={12}>
                    <p className={'error_txt error_txt_lg'}>{this.state.errors.common}</p>
                  </Grid>
                )}
                <Grid item xs={12} className={'form_actions'}>

                  <button type={'button'}
                          className={'a_btn a_btn_link a_btn_forgot_pass'}
                          onClick={() => {
                            this.forgotPassword()
                          }}>{t('auth.btn.pass_reset')}</button>

                  <button type={'button'}
                          className={'a_btn a_btn_action_1 a_btn_sign_in'}
                          onClick={(e) => {
                            this.validate(e)
                          }}>{t('auth.btn.complete_sign_in')}</button>


                </Grid>
              </Grid>
            </form>
          )}
          {this.state.reset_sent && (
            <Snackbar open autoHideDuration={2000} onClose={() => {
              this.closeNotification()
            }}>
              <Alert onClose={() => {
                this.closeNotification()
              }} severity="success">{t('auth.finalize_reg_reset_sent')}</Alert>
            </Snackbar>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthorized: state.portal.isAuthorized,
    answers: state.insurance.answers
  }
}

const mapDispatchToProps = {
  setUserAuth
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FinalizeRegistration))
