import React from "react";
import {Card, CardActionArea, CardContent, FormControl, Grid} from "@material-ui/core";
import {Bank} from "../../icons/Bank";
import {CC} from "../../icons/CC"

class BillingBoolean extends React.Component {

    state = {
        creditCardSelected: true
    }

    onBooleanChange(value) {
        if (this.props.onChange) this.props.onChange(this.props.id, value)
    }

    render() {
        //Boolean input
        return (
            <FormControl fullWidth={this.props.fullWidth}
                         className={'form_control boolean_control'}
                         error={this.props.error}
            >
                {this.props.question && (
                    <div className="label_blk">
                        <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                    </div>
                )}

                {(this.props.options && this.props.options.length > 1) ? (
                    <Grid container spacing={2}>
                        {this.props.options.map((opt, idx) => {
                            let value = (opt.hasOwnProperty('value')) ? opt.value : opt.text
                            return (
                                <Grid item xs={6} key={idx}>
                                    <Card variant="outlined" square={true}
                                          className={`payment_method_card ${this.props.answer === value ? 'selected' : null}`}
                                          onClick={() => {
                                              this.onBooleanChange(value)
                                          }}>
                                        <CardActionArea>{opt.text}</CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {/*if not provided*/}
                        <Grid item xs={6}>
                            <CardActionArea disabled={!this.props.bankEnabled}>
                                <Card variant="outlined" square={true}
                                      className={`payment_method_card ${!this.state.creditCardSelected && this.props.bankEnabled ? 'selected' : null} ${!this.props.bankEnabled && 'payment_method_disabled'}` }
                                      onClick={() => {
                                          this.onBooleanChange(true)
                                          this.setState({
                                              creditCardSelected: !this.state.creditCardSelected
                                          })
                                      }}>
                                    <CardContent>
                                        <Bank/>
                                        <span>Bank Account</span>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                        <Grid item xs={6}>
                            <CardActionArea>
                                <Card variant="outlined" square={true}
                                      className={`payment_method_card ${!this.props.bankEnabled || this.state.creditCardSelected ? 'selected' : null}`}
                                      onClick={() => {
                                          this.onBooleanChange(false)
                                          this.setState({
                                              creditCardSelected: !this.state.creditCardSelected
                                          })
                                      }}>
                                    <CardContent>
                                        <CC/>
                                        <span>Credit Card</span>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                    </Grid>
                )}
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}

export default BillingBoolean
