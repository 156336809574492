import React from "react";

export const Trash = ({className}) => (
    <svg className={className} width="29" height="29" viewBox="0 0 29 29" fill="none" stroke="#6B7D8C" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.921 6.09029L19.0587 6.25H11.7538L11.8915 6.09029L12.3265 5.58551C12.3341 5.58189 12.3444 5.57742 12.3578 5.57259C12.4204 5.54987 12.5152 5.531 12.627 5.53126H12.6292H18.1784L18.1788 5.53125C18.292 5.53121 18.3883 5.55028 18.4528 5.57354C18.4678 5.57896 18.4793 5.58397 18.4877 5.58801C18.488 5.58816 18.4883 5.58831 18.4886 5.58846L18.921 6.09029ZM18.4617 5.55726L18.4637 5.55958L18.4617 5.55726L18.4617 5.55726Z"
            stroke="inherit" strokeWidth="2"/>
        <path
            d="M7.71444 24.9106C7.47709 24.6732 7.34375 24.3513 7.34375 24.0156V9.15625H23.4688V24.0156C23.4688 24.3513 23.3354 24.6732 23.0981 24.9106C22.8607 25.1479 22.5388 25.2812 22.2031 25.2812H8.60938C8.27371 25.2812 7.9518 25.1479 7.71444 24.9106Z"
            stroke="inherit" strokeWidth="2"/>
        <path d="M10.875 13.5938V20.8438" stroke="inherit" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M15.4062 12.6875V21.75" stroke="inherit" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M19.9375 13.5938V20.8438" stroke="inherit" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>
)