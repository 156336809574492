import React from "react";
import {Dollar} from "../../icons/Dollar";
import NumberQuestion from "./NumberQuestion";
import NumberFormat from "react-number-format";
import {AutofilledBadge} from "./AutofilledBadge";
import {withTranslation} from 'react-i18next';
import {FormControl, InputAdornment, TextField} from "@material-ui/core";
import {formatCurrency} from "../../../helpers/currency";
import sources_update from '../../../common/decorator'
import {connect} from 'react-redux'


function CurrencyNumberFormat(props) {
    return (
        <NumberFormat
            className={props.className}
            getInputRef={props.inputRef}
            thousandSeparator
            allowEmptyFormatting
            value={props.value}
            placeholder={props.placeholder}
            onValueChange={(values) => {
                props.onChange(values)
            }}
            onBlur={props.onBlur}
        />
    )
}

class Currency extends NumberQuestion {

    state = {
        value: ''
    }

    constructor(props) {
        super(props);
        this.state.value = this.props.answer
    }

    onChange(values) {
        let value = this.verifyMaxValue(this.props.max_value, values.floatValue)

        this.setState({value: value})

        if (this.props.onChange) this.props.onChange(this.props.id, value)
        sources_update(this.props.answers, this.props.qid)
    }

    onBlur() {
        if (this.props.onBlur) this.props.onBlur(this.props.id, this.state.value)
    }

    render() {
        const {t} = this.props

        return (
            <FormControl fullWidth={this.props.fullWidth}
                         className={'form_control'}
                         error={this.props.error}>
                {this.props.question && (
                    <div className="label_blk">
                        <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                        {this.props.autofilled && <AutofilledBadge/>}
                    </div>
                )}
                <TextField
                    variant="outlined"
                    type="text"
                    className={'fieldText'}
                    value={this.props.answer}
                    error={this.props.error}
                    placeholder={this.props.placeholder}
                    InputProps={{
                        inputComponent: CurrencyNumberFormat,
                        startAdornment: <InputAdornment position="start"><Dollar/></InputAdornment>,
                    }}
                    onChange={(values) => {
                        this.onChange(values)
                    }}
                    onBlur={() => {
                        this.onBlur()
                    }}
                />
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
                {this.state.above_max && (
                    <p className="error_txt error_txt_md error">{t('form.error.max_permitted_value', {
                        value: formatCurrency(this.props.max_value)
                    })}</p>
                )}
            </FormControl>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
})

export default connect(mapStateToProps)(withTranslation()(Currency))

