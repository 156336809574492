import React from 'react';
import {withTranslation} from "react-i18next";
import policyType from '../../../helpers/policyType';
import {Hidden} from "@material-ui/core";
import LoadingButton from '../../LoadingButton'
import SidebarAds from "../sidebar/SidebarAds";
import {formatCurrency} from "../../../helpers/currency";
import Payments from "../sidebar/Payments";
import SummaryUpcomingBills from "../SummaryUpcomingBills";
import FeedRss from "./feedRSS";
import HelpfulLinks from "../sidebar/HelpfulLinks";

const SummaryTab = (props) => {
    const getBillingPeriod = (p) => {
        return (p?.installments && p.installments.length > 1)
            ? props.t('common.billed_monthly')
            : props.t('common.billed_annually')
    }
    const getBillingValue = (p)=> {
        return (p?.installments && p.installments.length > 1)
            ? formatCurrency((p.term_premium / 12), true)
            : formatCurrency(p.term_premium, true)
    }
    const getPolicyStatus = (policyStatus)=> {
      switch (policyStatus) {
        case 'UNDERWRITER_REVIEW':
          return 'Underwriter Review'
        case 'CANCELED':
          return 'Canceled'
        case 'NON_RENEWAL':
          return 'Non-Renewal'
        default:
          return 'Active'
      }
    }
    return (
        <>
            <div className="content_column">
                {/*POLICIES LIST*/}
                <div
                    className={`grid_policies ${props.policyLoading ? 'policies_loading' : ''} `}>
                    {props.policyLoading ? <LoadingButton/> :
                      props.policies.length>0? props.policies.map((policy, index)=><div key={index} className={'summary_policy_item_blk'}
                                                                               onClick={()=>props.selectPolicy(policy.quote_id)}>
                          <div className="item_business_name">{policy.answers_snapshot['business.name']}</div>
                          <div className="item_policy_type">{policyType(policy.type).longName}</div>
                          <div className="item_policy_number">{props.t('common.policy_number')} - {policy.quote.policy_number}</div>
                          {policy.policy_status === 'ACTIVE' && <><div className="item_policy_price">{getBillingValue(policy)}</div>
                            <div className="item_policy_billed">{getBillingPeriod(policy)}</div></>}
                          <div className={`item_policy_status status__${policy.policy_status.toLowerCase()}`}>{getPolicyStatus(policy.policy_status)}</div>
                      </div>):<div>No Policies</div>}
                </div>

                <Hidden mdUp>
                    <div className="grid_item grid_promo">
                        <SidebarAds/>
                    </div>
                </Hidden>
                {props.upcomingBills && props.upcomingBills.length > 0 &&
                    <div className="grid_item grid_bills">
                        <SummaryUpcomingBills upcomingBills={props.upcomingBills}/>
                    </div>}
                <Hidden smDown>
                    <FeedRss feedsRSS={props.feedsRSS}/>
                </Hidden>
            </div>
          <div className="sidebar_column">
            {props.policyLoading ? <LoadingButton/> : <HelpfulLinks/>}
            <Hidden smDown>
              <div className="grid_item grid_promo">
                <SidebarAds/>
              </div>
            </Hidden>
            <div className="grid_item grid_claims_payments">
              {props.policyLoading ? <LoadingButton/> :
                props.paidPayments && props.paidPayments.length > 0 && (
                  <Payments paidPayments={props.paidPayments}/>
                )}
            </div>
            <Hidden mdUp>
              <FeedRss feedsRSS={props.feedsRSS}/>
            </Hidden>
          </div>
        </>
    );
}

export default withTranslation()(SummaryTab);