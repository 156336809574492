import React from "react";
import {Box, CircularProgress, Grid} from '@material-ui/core';

class Loading extends React.Component {
    render() {
        return (
            <Box py={4} textAlign="center">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </Box>

        )
    }
}

export default Loading
