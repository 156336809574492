import React from "react";
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, MenuItem, TextField} from "@material-ui/core";
import sources_update from '../../../common/decorator'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'


class Dropdown extends React.Component {

    onChange(e) {
        if (this.props.onChange) this.props.onChange(this.props.id, e.target.value)
        sources_update(this.props.answers, this.props.qid)
    }


    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth} error={this.props.error} className={'form_control'}>
                <div className="label_blk">
                    <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                    {this.props.autofilled && <AutofilledBadge/>}
                </div>
                <TextField
                    select
                    className={'fieldDropdown'}
                    variant="outlined"
                    id={'question-' + this.props.id + '-select'}
                    value={this.props.answer}
                    error={!!this.props.error}
                    disabled={!!this.props.field_disabled}
                    SelectProps={{
                        displayEmpty: !!this.props.placeholder,
                    }}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return this.props.placeholder;
                        }

                        return selected.join(', ');
                    }}
                    onChange={(e) => this.onChange(e)}
                >
                    {this.props.placeholder &&
                    <MenuItem value="" key="disabled" disabled>{this.props.placeholder}</MenuItem>}
                    {this.props.options && this.props.options.map((opt, idx) => (
                        <MenuItem value={opt.hasOwnProperty('value') ? opt.value : opt.text}
                                  key={idx}>{opt.text}</MenuItem>
                    ))}
                </TextField>
                {this.props.helperText && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}

const mapStateToProps = state => ({
  answers: state.insurance.answers,
})

export default connect(mapStateToProps)(withTranslation()(Dropdown))
