import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import React, {useRef, useState} from "react";
import CardBOP from './form-components/cardsFQ/CardBOP';
import CardCYBER from './form-components/cardsFQ/CardCYBER'
import CardGL from './form-components/cardsFQ/CardGL';
import CardPL from './form-components/cardsFQ/CardPL';
import CardWC from './form-components/cardsFQ/CardWC';
import {useScrollPosition} from '@n8tb1t/use-scroll-position'
import ErrorInsuranceCard from './form-components/ErrorInsuranceCard'


const PolicyCheckoutSummary = (props) => {
    const t = props.t
    const answers = props.answers
    const totalRef = useRef(null)

    const renderCollapsableInsuranceCard = (itm, idx) => {
        const {line_of_business: lineOfBusiness, id} = itm
      if (itm?.error) return (
          <ErrorInsuranceCard quote_type={itm.quote_type}/>)
      else return (lineOfBusiness === 'WC') ? (
        <CardWC key={idx}
                quote={itm}
                quote_index={idx}
                answers={answers}
                period={answers["billing.frequency"]}
                selected={props.isQuoteSelected(id)}
                policyToggle={() => props.onPolicyToggle(id)}/>
        ) : (lineOfBusiness === 'PL') ? (
        <CardPL key={idx}
                quote={itm}
                quote_index={idx}
                answers={answers}
                period={answers["billing.frequency"]}
                selected={props.isQuoteSelected(id)}
                policyToggle={() => props.onPolicyToggle(id)}/>
        ) : (lineOfBusiness === 'BOP') ? (
        <CardBOP key={idx}
                 quote={itm}
                 quote_index={idx}
                 answers={answers}
                 period={answers["billing.frequency"]}
                 selected={props.isQuoteSelected(id)}
                 policyToggle={() => props.onPolicyToggle(id)}/>
        ): (lineOfBusiness === 'CYBER') ? (
        <CardCYBER key={idx}
                 quote={itm}
                 quote_index={idx}
                 answers={answers}
                 isAscendIntegration={props.isAscendIntegration}
                 period={answers["billing.frequency"]}
                 selected={props.isQuoteSelected(id)}
                 policyToggle={() => props.onPolicyToggle(id)}/>
        ): (lineOfBusiness === 'GL') ? (
        <CardGL key={idx}
                quote={itm}
                quote_index={idx}
                answers={answers}
                period={answers["billing.frequency"]}
                selected={props.isQuoteSelected(id)}
                policyToggle={() => props.onPolicyToggle(id)}/>
        ) : null
    }

    const [totalElementPosition, setTotalElementPosition] = useState(0)

    const theme = useTheme()
    const isShowStickyTotal = useMediaQuery(theme.breakpoints.down(1100))


    useScrollPosition(
        ({currPos}) => {
            setTotalElementPosition(currPos.y)
        }, [], totalRef
    )

    const totalAmountClasses = clsx({
        'sticky_mobile_total': totalElementPosition + 40 < 0
    })

    let checkoutSummaryClasses = clsx({
      'ascend_integration_wrapper': (process.env.REACT_APP_DOUBLOON_ENABLED || window.doubloon_enabled) && props.isAscendIntegration
    })
    return (
        <>
            <div className={`policies-data ${checkoutSummaryClasses}`}>
                <div className="policies">
                    {props.full_quote.map((itm, idx) => {
                        return renderCollapsableInsuranceCard(itm, idx)
                    })}
                </div>
              {!(process.env.REACT_APP_DOUBLOON_ENABLED || window.doubloon_enabled) && <>
                <div className={`total`} ref={totalRef}>
                  <div className="floating-block">
                    <div className="total-amount">
                      <span className="title">{t('common.total_due_today')}</span>{props.getTotal()}
                    </div>
                  </div>
                </div>

                {isShowStickyTotal && <div className={`mobile_total ${totalAmountClasses}`}>
                  <div className="total_amount">
                    <span className="title">{t('common.total_due_today')}</span>{props.getTotal()}
                  </div>
                </div>}
              </>}
            </div>
        </>
    )
}

export default PolicyCheckoutSummary