import React from 'react'
import { getCertificatePayload } from '../../../helpers/GetCertificatePayload'
import policyType from '../../../helpers/policyType'
import LoadingButton from '../../LoadingButton'
import CommonPolicy from './CommonPolicy'
import clsx from 'clsx'
import axios from 'axios'
import base64 from 'base-64'
import { Chevron } from '../../icons/Chevron'
import { withTranslation } from 'react-i18next'
import { QuestionTooltip } from '../../form-components/QuestionTooltip'
import { Collapse } from '@material-ui/core'
import moment from 'moment'
import PLPolicyLimits from './../policy/PLPolicyLimits'
import PLPolicyDetails from './../policy/PLPolicyDetails'

class PLPolicy extends CommonPolicy {

  getAccord25Certificate () {
    const certificatePayload = getCertificatePayload(this.props)
    this.setState({ loadingCertificate: true }, () => {
      axios.post('/api/get-certificate', {
        doc_type: certificatePayload.docType,
        payload: certificatePayload.payload,
        strict: true
      })
      .then((data) => {
        const decodedData = base64.decode(data.data)
        const fileName = `COI-${certificatePayload?.payload?.policy_number}`
        this.sendFile(decodedData, fileName)
      })
      .catch(e => {
        throw new Error(`Can't get user's policy acord25 certificate. ${e}`)
      })
      .finally(() => this.setState({ loadingCertificate: false }))
    })
  }

  render () {
    this.isDocsGenerated()
    const { t } = this.props
    let details = clsx({
      'a_btn a_btn_action_3': true,
      'open': this.state.showDetails
    })

    return (
      <>
        <div ref={this.policyRef}
             className={`portal_policy_full_item`}>
          <div className={`portal_card ${this.checkFailPolicy() && 'failed_payment_policy'}`}>
            <div className={'row short_info'}>
              <div className="column_left">
                <span className={'item_business_name'}>{this.props.quote['business_name']}</span>
                <span className={'policy_type'}>{policyType(this.props.type).longName}</span>
                <span
                  className={'policy_number'}>{this.props.t('common.policy_number')} - {this.props.quote.policy_number}</span>
                <span
                  className={'policy_address'}>{this.getAddress()}</span>
              </div>
              <div className="column_right">
                <div className={`item_policy_status status__${this.props.policy_status.toLowerCase()}`}>{this.getPolicyStatus(this.props.policy_status)}</div>
                {this.props.policy_status === 'ACTIVE' && <>
                    <span className={'policy_price'}>{this.getBillingValue()}</span>
                    <span className={'policy_billed'}>{this.getBillingPeriod()}</span>
                  </>}
              </div>
            </div>

            <div className="limits_links">

              <div className={'row short_info_2'}>
                <div className="column">
                  <span className={'column_name'}>{t('common.eff_date')}</span>
                  <span
                    className={'column_value'}>{moment(this.props.effective_date).format('MM/DD/YYYY')}</span>
                </div>
                <div className="column">
                  <span className={'column_name'}>{t('common.exp_date')}</span>
                  <span
                    className={'column_value'}>{moment(this.props.expiration_date).format('MM/DD/YYYY')}</span>
                </div>
                <div className="column">
                  <div className="column_name"><span className={'value'}>{t('common.insurer')}</span>
                    &nbsp;<QuestionTooltip text={this.state.providerPortal}/></div>
                  <span className={'column_logo'}><img src={this.state.logoUrl} alt=""/></span>
                </div>
              </div>

              <PLPolicyLimits {...this.props} />

              {/*{this.props.policy_status === 'ACTIVE' &&
              <div className="row row_actions">
                {this.props.docs ? (
                  <p className={'action_item'} onClick={() => {
                    this.getDocuments()
                  }}>
                    {this.state.loadingDoc ? (
                      <span className={'policy_loading_blk'}>
                        <LoadingButton size={15}/>
                        <span>{t('common.downloading')}</span>
                      </span>
                    ) : (<span className={'a_btn a_btn_action_3'}>
                          {t('portal.policy.doc')}</span>)}
                  </p>
                ) : (
                  this.isDocsGenerated() ? <p className={'action_item docs_generated'}>
                    <span>{t('portal.policy.doc_gen')}</span>
                    <QuestionTooltip
                      text={t('portal.policy.doc_gen_tooltip')}/></p> : null
                )}
                {(this.props.quote.policy_number || this.props.quote.policyNumber) && (
                  <p className={'action_item'} onClick={() => {
                    this.getAccord25Certificate()
                  }}>{this.state.loadingCertificate ? (
                    <span className={'policy_loading_blk'}>
                      <LoadingButton size={15}/>
                      <span>{t('common.downloading')}</span>
                    </span>
                  ) : (
                    <span className={'a_btn a_btn_action_3'}>{t('portal.policy.certificate')}</span>
                  )}</p>
                )}
              </div>}*/}
            </div>
            <Collapse in={this.state.showDetails}>
              <PLPolicyDetails {...this.props}/>
            </Collapse>
            <div className="row row_bottom_actions">
              <button className={details}
                      type={'button'}
                      onClick={() => {
                        this.toggleDetails()
                      }}>{this.state.showDetails ? t('common.hide_policy_details') : t('common.show_policy_details')}
                <i><Chevron/></i></button>

            </div>

          </div>

        </div>
      </>
    )
  }
}

export default withTranslation()(PLPolicy)