import _ from 'lodash'

export function getLimitsOptionsV2 (props, questions, lob = 'BOP') {
  /**
   * Function takes in quote and lob and performs the following actions to determine which limits
   * options to make available for customer selection based on carrier.  This shall be reviewed/updated as
   * new carriers are integrated.
   * 1. Filter selected quotes from Props object based on provided lob parameter.
   * 2. Convert nested object into array of objects:
   *      a. In map statement only push to new array if options_type property exists.  This
   *         will filter out those limits with a single hard coded value that cannot be
   *         changed since those limits do not apply to said carrier.
   * 3. Create a new mapped array of objects from array above with the following conditions:
   *      a. If options_type property is Choices, then just return the array of under the
   *         available_options property.
   *      b. If options_type property is Integer, then generate array based on the following properties:
   *          - minLimit: property options_min.
   *          - maxLimit: property options_max.
   *          - step: options_step (amount to increment)
   * 4. Map final array of objects to questions array of objects, matching on the qid key and only mapping
   *    the respective options property.
   * 5. On component updates: Ensure policy.occurrence_limit is less than or equal to policy.aggregate_limit.
   *      a. If it does not satisfy that condition then filter out policy.occurrence_limit choices that do are
   *         greater than policy.aggregate_limit.
   * 6. Return newly mutated questions object.
   */

  let quote = props.answers.selected_quotes.filter(quote => quote.line_of_business === lob)[0],
    { limits } = quote,
    mappedLimits = [],
    occLimits = undefined,
    retentionLimit = undefined,
    { answers } = props,
    aggLimit = parseInt(answers['policy.aggregate_limit']),
    cyberAggLimit = parseInt(answers['cyber.coverage.aggregate_limit'])

  for (let key in limits) { // converting nested object into array of objects
    const mappedData = {
      ...limits[key]
    }

    if (mappedData.options_type) mappedLimits.push(mappedData)
  }

  occLimits = mappedLimits.filter(q => q?.question_id === 'policy.occurrence_limit')[0]
  retentionLimit = mappedLimits.filter(q => q?.question_id === 'cyber.coverage.retention_limit')[0]

  if (!isNaN(aggLimit) && occLimits?.available_options) {
    let ops = occLimits?.available_options,
      filteredOps = ops.filter(o => o <= aggLimit)

    mappedLimits = mappedLimits.map(l =>
      l.question_id === 'policy.occurrence_limit'
        ? { ...l, available_options: filteredOps }
        : l
    )
  }
  const getRetentionAvailableOptions = (availableOptions, value) => {
    let options_max = retentionLimit.dynamic_limits[value].options_max
    let options_min = retentionLimit.dynamic_limits[value].options_min
    return availableOptions.filter(o => o <= options_max && options_min <= o)
  }
  if (!isNaN(cyberAggLimit) && retentionLimit) {
    let availableOptions = retentionLimit.available_options
    Object.keys(retentionLimit.dynamic_limits).forEach((item, key, list) => {

      if (key === 0) {
        if (cyberAggLimit <= Number(list[key + 1])) {
          retentionLimit.available_options = getRetentionAvailableOptions(availableOptions, list[key])
        }
      } else if (key === list.length - 1) {
        if (cyberAggLimit === Number(list[key])) {
          retentionLimit.available_options = getRetentionAvailableOptions(availableOptions, list[list.length - 1])
        }
      } else {
        if (Number(list[key]) <= cyberAggLimit && cyberAggLimit <= Number(list[key + 1])) {
          retentionLimit.available_options = getRetentionAvailableOptions(availableOptions, list[key])
        }
      }
    })
  }

  mappedLimits = mappedLimits.map(itm => {
    let type = itm?.options_type,
      qid = itm?.question_id,
      displayName = itm?.display_name,
      maxLimit = itm?.options_max,
      minLimit = itm?.options_min,
      value = itm?.value,
      step = itm?.options_step,
      idx = 1,
      options = type === 'Integer' ? _.range(minLimit, maxLimit + 1, step)
        : type === 'Choices' ? itm.available_options
          : null

    if (options) {
      options = options.map((op) => {
        return {
          id: idx++,
          text: op.toString(),
          value: op.toString()
        }
      })
    }

    return { qid, display_name: displayName, type, maxLimit, minLimit, value, step, options }
  })

  let newQuestions = questions.map(itm => {
    itm.options = mappedLimits.filter(l => l.qid === itm.qid)[0]?.options
    return itm
  })

  return newQuestions.filter(q => q.options !== undefined)
}

export function updateOccurrenceLimit (answers, questions) {
  /**
   * Function intended to return new limit selection value based on conditional logic below.
   * This function is triggered when policy.aggregate_limit is changed to a value less than the
   * policy.occurrence_limit.
   * 1. Get list of new options for policy.occurrence_limit
   * 2. If policy.aggregate_limit is less than policy.occurrence_limit, do the following:
   *      a. filter option to those that are <= aggregate limit
   *      b. set occurrenceLimit variable to last item on newly filtered options
   * 3. Return object telling if update is needed and new occurrence limit
   *
   */

  let options = questions.filter(q => q.qid === 'policy.occurrence_limit')[0]?.options,
    aggLimit = parseInt(answers['policy.aggregate_limit']),
    occurrenceLimit = parseInt(answers['policy.occurrence_limit']),
    updateNeeded = false

  if (occurrenceLimit > aggLimit) {
    options = options.filter(o => parseInt(o.value) <= aggLimit)
    updateNeeded = true
    occurrenceLimit = options.slice(-1)[0].value
  }

  return { updateNeeded, occurrenceLimit: occurrenceLimit.toString() }
}

export function updateDamageToPremisesRentedToYouLimit (answers, questions, damageToPremisesRentedToYouKey, occurrenceLimitKey) {
  /**
   *
   *
   * Function intended to return new limit selection value and options based on conditional logic below.
   * This function is triggered when GL and BOP occurrence_limit is changed.
   * 1. Get list of new options for damage_to_premises_rented_to_you
   * 2. If occurrence_limit is less than damage_to_premises_rented_to_you, do the following:
   *      a. filter option to those that are <= occurrence_limit
   *      b. set damage_to_premises_rented_to_you variable to last item on newly filtered options
   * 3. Return object with new damage_to_premises_rented_to_you value and options with values <= occurrence_limit
   *
   *
   * NOTE:
   * @damageToPremisesRentedToYouKey
   * @occurrenceLimitKey
   * This two params needed cuz for BOP and GL we have different keys for damage_to_premises_rented_to_you Limit
   */
  let damageToPremisesRentedToYouQuestion = questions.filter(q => q.qid === damageToPremisesRentedToYouKey)[0]
  let options = damageToPremisesRentedToYouQuestion?.options
  let damageToPremisesRentedToYouValue = parseInt(answers[damageToPremisesRentedToYouKey])
  let occurrenceLimitValue = parseInt(answers[occurrenceLimitKey])

  options = options.filter(o => parseInt(o.value) <= occurrenceLimitValue)

  if (occurrenceLimitValue <= damageToPremisesRentedToYouValue) {
    damageToPremisesRentedToYouValue = options.slice(-1)[0].value
  }

  return {
    options: options,
    value: damageToPremisesRentedToYouValue.toString()
  }
}
