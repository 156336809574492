import React, {useState} from 'react'
import {connect} from 'react-redux'
import {Grid} from '@material-ui/core'
import {withTranslation} from 'react-i18next'

import '../../scss/v2/_underwriter_statements.scss'


const UnderwriterStatements = ({
    underwriterStatements
} : {
    t: (string) => string,
    underwriterStatements: { title: string, statementsList: string[] }
}) => {
    const [readInFull, setReadInFull] = useState(false)

    const checkIfEndIsShown = (el: EventTarget & HTMLDivElement) => {
        if (!el) return
        if (el.scrollHeight - el.scrollTop === el.clientHeight) setReadInFull(true)
    }

    const checkIfReadInFull = (e: React.UIEvent<HTMLDivElement>) => {
        if (readInFull) return
        checkIfEndIsShown(e.currentTarget)
    }

    return (
        <Grid container spacing={2} justifyContent='center' onScroll={e => checkIfReadInFull(e)} className={'UnderwriterStatements'}>
            <Grid item xs={12} justifyContent='center'>
                <p className={'title'}>{underwriterStatements.title}</p>
            </Grid>
            <Grid item xs={12} justifyContent='center'>
                <ul className={'bullet_list'}>
                    {underwriterStatements.statementsList?.map(statement => {
                        return (
                            <li>{statement}</li>
                        )
                    })}
                </ul>
            </Grid>
        </Grid>
    )
}

export default connect(null, null)(withTranslation()(UnderwriterStatements))