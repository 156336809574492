import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {LinearProgress} from "@material-ui/core";
import {changeAnswers, updateInsStoreKey} from "../../store/insurance/actions";

class SectionIndicatorItem extends React.Component {

    render() {

        let completed = this.props.completed
        let progressValue = this.props.section.name === 'Request Your Quote'? 100 : this.props.progress ? this.props.progress : 0
        let sectionName = this.props.section.name === 'Request Your Quote' && this.props.active? 'All Set' : this.props.section.name

        return (
            <>
                <div className="name">{sectionName}</div>
                {completed ? null : (<div className="progress">
                    <LinearProgress variant="determinate"
                                    value={progressValue}/>
                </div>)}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        sections: state.insurance.sections,
        questions: state.insurance.subsections_questions,
        industry_classes: state.insurance.industry_classes,
        wc_locations: state.insurance.wc_locations,
        answers: state.insurance.answers,
    }
}

const mapDispatchToProps = {
    updateInsStoreKey,
    changeAnswers
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SectionIndicatorItem))
