import React from 'react';
import '../../../scss/v2/portal/_column_news.scss'
const FeedRss = (props) => {
    return (
        <div className={'grid_item grid_news'}>
            {props.feedsRSS && props.feedsRSS.map((item, key) => {
                return (<div className={'article_item portal_card'} key={key}>
                    {/* eslint-disable-next-line react/jsx-no-target-blank */} {/* security warning suppressed as the target url is ours*/}
                    <a href={item.link} target="_blank">
                        <div className="article_pic">
                            <img src={item.content} alt=""/>
                        </div>
                        <span className="article_title"><span>BLOG: </span>{item.title}</span></a>
                </div>)
            })}
        </div>
    );
}

export default FeedRss;