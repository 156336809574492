import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'
import {
  Dialog,
  DialogContent,
  useTheme
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import { ModalTransition } from '../../common/ModalTransition'
import '../../scss/v2/modals/_invalid_fien.scss'

const InvalidFEIN = (props) => {
  const { t } = props
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Dialog open={props.is_open}
            onClose={() => props.onClose()}
            container={() => document.getElementById('themeAppComponent')}
            className={'modal__wrapper invalid_fien_modal__wrapper'}
            fullScreen={fullScreen}
            scroll={'paper'}
            fullWidth
            maxWidth="xs"
            TransitionComponent={ModalTransition}>
      <button className={'modal_close_btn'}
              type={'button'}
              onClick={() => props.onClose()}><Close/>
        <span>{t('common.btn_close')}</span></button>
      <DialogContent className={'invalid_fien_modal_cnt'}>
        <p className={'modal_ttl'}>{t('modal.invalid_fein_title')}</p>

        <button type={'button'} className={'a_btn a_btn_action_1 a_btn_ok'}
                onClick={() => props.onClose(true)}>{t('common.btn_edit')}</button>


      </DialogContent>

    </Dialog>
  )
}

export default withTranslation()(InvalidFEIN)
