

export const getPlaces = (props, propOptions, status) => {
    return (status === props.google.maps.places.PlacesServiceStatus.OK)
        ? propOptions : []
}

export const getPredictions = (request, callback, types, props) => {
    const autoComplete = new props.google.maps.places.AutocompleteService()
    if (request && request.length > 1) {
        let returnedOptions = {
            input: request,
            types: [types],
            componentRestrictions: {'country': ['us']},
            fields: ['ALL'],
            sessionToken: props.sessionToken
        }
        if (props.radius && props.location) {
            returnedOptions.location = new props.google.maps.LatLng(props.location.lat, props.location.lng)
            returnedOptions.radius = props.radius
        }
        autoComplete.getPlacePredictions(returnedOptions, callback)
    }
}

