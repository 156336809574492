import React from "react";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import {updateWCLocations} from "../../store/insurance/actions";
import {Grid} from "@material-ui/core";
import PayrollQuestions from "./PayrollQuestions";
import Question from "../form-components/Question";

const wcRateClassesTemplate = {
    class_code: '',
    class_code_description: '',
    payroll_amount: '',
}

class WCPayroll extends React.Component {

    state = {
        collapsed: {},
        options: [
            {
                "id": 1,
                "text": "Yes",
                "value": "Yes"
            },
            {
                "id": 2,
                "text": "No",
                "value": "No"
            }
        ],
    }

    constructor(props) {
        super(props);

        let collapsedState = Object.assign({}, this.state.collapsed)
        this.props.wc_locations.forEach((itm, idx) => {
            collapsedState[idx] = false
            Object.assign(this.state.collapsed, collapsedState)
            //this.state.collapsed[idx] = false // deprecated this as react warns about directly mutating state. Reimplement if errors surface
        })
    }

    addPayrollClassLine(template) {
        const rate_classes = [...(this.props.wc_locations[0].rate_classes || [])]
        const locations = [...this.props.wc_locations]

        rate_classes.push({...template})
        locations[0].rate_classes = [...rate_classes]
        if (rate_classes.length > 1) locations[0].is_addition_payroll_classes = true

        this.props.updateWCLocations(locations)
    }

    isAdditionPayrollClasses(value) {
        const locations = [...this.props.wc_locations]

        if (value === "No" && locations[0].is_addition_payroll_classes) {
            locations[0].rate_classes = locations[0].rate_classes.slice(0, 1)
            locations[0].is_addition_payroll_classes = false
            this.props.updateWCLocations(locations)

        } else if (value === "Yes" && !locations[0].is_addition_payroll_classes) {
            this.addPayrollClassLine(wcRateClassesTemplate)
        }

    }

    render() {
        let {t} = this.props

        return (
            <Grid item xs={12} className="payroll-questions">
                    {this.props?.rate_classes?.length > 0 && this.props.rate_classes.slice(0, 1).map((itm, index) => (
                      <Grid item xs={12} className={'payroll_item'}>
                        <PayrollQuestions line={itm}
                                             index={index}
                                             key={index}
                                             rate_classes={this.props.rate_classes}
                                             location_index={this.props.index}
                                             collapsed={this.state.collapsed[this.props.index]}
                                             questions={this.props.questions}
                                             errors={this.props.errors}
                                             onClearErrors={() => {
                                                 this.props.clearErrors()
                                             }}
                                             onPayrollRemoved={this.props.onPayrollRemoved}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12} className={'payroll_item'}>
                        <Question fullWidth
                                  type="dropdown"
                                  question={'Does this location have additional payroll classes?'}
                                  answer={this.props.wc_locations[0].is_addition_payroll_classes ? "Yes" : "No"}
                                  variant="outlined"
                                  placeholder={null}
                                  options={this.state.options}
                                  onChange={(id, value) => {
                                      this.isAdditionPayrollClasses(value)
                                  }}
                        />
                    </Grid>
                    {this.props?.rate_classes?.length > 0 && this.props.rate_classes.slice(1).map((itm, index) => (
                        <PayrollQuestions line={itm}
                                             index={index + 1}
                                             key={index + 1}
                                             rate_classes={this.props.rate_classes}
                                             location_index={this.props.index}
                                             collapsed={this.state.collapsed[this.props.index]}
                                             questions={this.props.questions}
                                             errors={this.props.errors}
                                             clearErrors={this.props.clearErrors}
                                             onPayrollRemoved={this.props.onPayrollRemoved}
                        />
                    ))}

                    {this.props?.rate_classes?.length > 1 && <Grid item xs={12}>
                        <button type={'button'}
                                className="wc_additional_add"
                                onClick={() => {
                                    this.addPayrollClassLine(wcRateClassesTemplate)
                                }}
                        ><span>+{t('common.btn_add_payroll')}</span></button>
                    </Grid>}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    wc_locations: state.insurance.wc_locations
})

const mapDispatchToProps = {
    updateWCLocations
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WCPayroll))
