import React from "react";
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, IconButton, TextField} from "@material-ui/core";
import {ArrowUpward} from "@material-ui/icons";


export default class Chat extends React.Component {

    onChange(e) {
        if (this.props.onChange) {
            if (this.props.field_length) {
                if (this.props.field_length >= e.target.value.length)
                    this.props.onChange(this.props.id, e.target.value)
            } else this.props.onChange(this.props.id, e.target.value)
        }
    }

    render() {
        return (
            <>
                <FormControl fullWidth={this.props.fullWidth} error={this.props.error} className={'form_control'}>
                    <div className="label_blk">
                        <div className="label">{this.props.question} &nbsp;{this.props.tooltip} </div>
                        {this.props.autofilled && <AutofilledBadge/>}
                    </div>
                    <TextField
                        className="chat-input"
                        type="text"
                        value={this.props.answer}
                        error={!!this.props.error}
                        placeholder={this.props.placeholder}
                        onChange={(e) => this.onChange(e)}
                        InputProps={{
                            'aria-label': this.props.placeholder,
                            endAdornment: (
                                <IconButton
                                    type="submit"
                                    style={{width: '30px', height: '31px'}}
                                    disabled={this.props.buttonDisabled}
                                    onClick={() => this.props.buttonFunction(true)}
                                >
                                    <ArrowUpward htmlColor="#FFFFF"/>
                                </IconButton>
                            ),
                            disableUnderline: true
                        }}
                        name={'name' + this.props.id}

                    />
                    {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
                </FormControl>
            </>
        )
    }
}