export const SET_POLICIES = 'SET_POLICIES'
export const SET_POLICIES_LOADING = 'SET_POLICIES_LOADING'
export const SET_FAILED_INSTALLMENTS = 'SET_FAILED_INSTALLMENTS'

export const setPolicies = data => ({
    type: SET_POLICIES,
    payload: data
})

export const setPoliciesLoading = data => ({
    type: SET_POLICIES_LOADING,
    payload: data
})

export const setFailedInstallments = data => ({
  type: SET_FAILED_INSTALLMENTS,
  payload: data
})
