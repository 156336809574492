import React, {useState} from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
    Dialog,
    DialogContent,
    useTheme
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {withTranslation} from 'react-i18next'
import {ModalTransition} from '../../common/ModalTransition'

import '../../scss/v2/modals/_quote_not_eligible.scss'

const QuoteNotEligible = (props) => {

    const [isOpen, setIsOpen] = useState(true)

    const onClose = () => {
        setIsOpen(false)
    }

    const {t} = props
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (

        <Dialog open={isOpen}
            onClose={onClose}
            container={() => document.getElementById('themeAppComponent')}
            className={'modal__wrapper quote_not_eligible_modal__wrapper'}
            fullScreen={fullScreen}
            scroll={'paper'}
            fullWidth
            maxWidth="xs"
            TransitionComponent={ModalTransition}>
            <button className={'modal_close_btn'}
                type={'button'}
                onClick={onClose}><Close/>
                <span>{t('common.btn_close')}</span></button>
            <DialogContent className={'quote_not_eligible_modal_cnt'}>
                <p className={'modal_ttl'}>{t('modal.quote_not_eligible.title')}</p>
                <p className={'modal_txt'}>{t('modal.quote_not_eligible.desc')}</p>

                <button type={'button'} className={'a_btn a_btn_action_1 a_btn_ok'}
                    onClick={onClose}>{t('common.btn_continue_pay')}</button>

            </DialogContent>
        </Dialog>
    )
}

export default withTranslation()(QuoteNotEligible)
