import axios from "axios";
import {datadogRum} from "@datadog/browser-rum";


const emailValidator = async (email) => {
    try {
        const response = await axios.post('/api/validate-email', {
            email: email
        })
        const {status}  = response.data
        return status
    } catch (e) {
        datadogRum.addError(`Something went wrong while validating user email. ${e}`)
        return e
    }
}


export const confirmEmailHelper = async (firstEmail, secondEmail, errors, setConfirmEmail, setEmailValidated) => {
    setConfirmEmail({type: 'SET', answer: secondEmail})

  let firstEmailRegEx = /^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/

  if (firstEmail === secondEmail) {
        delete errors['mismatched.emails']
        if (!!firstEmailRegEx.test(firstEmail) && firstEmail !== '' && secondEmail !== '') {
            setEmailValidated('loading')
            const response = await emailValidator(secondEmail)

            if (response === 'valid' || response === 'unknown') {
                setEmailValidated(true)
                datadogRum.setUserProperty('email', firstEmail)

                return {status: response, email: secondEmail}

            } else if (response === 'invalid') {
                setEmailValidated(false)
                return {status: response}

            } else { // returns error when API fails
                setEmailValidated(null)
                return response
            }
        }

        return errors
    } else {
        return {
            ...errors,
            'mismatched.emails': 'Provided emails do not match.'
        }
    }
}

export const getBindableQuote = async (agent, answers) => {

        try {
            return await axios.post('/api/get-bindable-quote', {
                agent: agent,
                answers: answers
            })
        } catch (e) {
          throw new Error(`Something went wrong while getting bindable quote. ${e}`)
        }

}
