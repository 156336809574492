import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Button,
    Toolbar
} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import AcrisureLogo from '../../components/icons/new/AcrisureLogo'
import ContactUs from "../../components/modals/ContactUs";
import {Phone} from "../../components/icons/Phone";

//scss
import "../../scss/v2/_header.scss";
import {AcrisureLogoNew} from "../../components/icons/new/AcrisureLogoNew";

const IS_NEW_THEME_ENABLED = process.env?.REACT_APP_IS_NEW_THEME_ENABLED === "TRUE"
    || window.is_new_theme_enabled === 'TRUE'

//TODO [V2 update] rewrite style to scss
const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: "white",
        color: theme.palette.primary.main,
        borderRadius: 0,
        boxShadow: '0px 1px 0px rgba(23, 33, 82, 0.4)'
    },
    link: {
        textDecoration: "none",
        color: "white"
    },
    btn: {
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            marginRight: '2em'
        },
    },
    agent: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        backgroundColor: 'red',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '10px',
        fontSize: '12px',
        color: 'white',
        lineHeight: '18px',
        padding: '2px 10px',
        margin: '0 18px'
    },
    agentNav: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    }
}))

function Header({t}) {

    const classes = useStyles()
    const handleScheduleCall = () => {
        return <div className="call_row">
            <Button variant="text" custom-ignore="ignore"
                    className="call_item"
                    href={"tel:" + t('common.contact_number')}>
                <Phone/>
                <span className="btn_txt">Call Us</span>
            </Button>
        </div>
    };
    return (
        <AppBar position="static" className={`${classes.header} main-header`}>
            <Toolbar>
                <div className="header_logo">
                  <a href="https://www.acrisure.com/small-business-insurance"
                     rel="noopener noreferrer" className={'insurance_header_logo_link'}>
                      {IS_NEW_THEME_ENABLED? <AcrisureLogoNew className={'insurance_header_logo'}/>:<AcrisureLogo className={'insurance_header_logo'}/>}
                  </a>
                </div>
              <>
                {handleScheduleCall()}
              </>
            </Toolbar>
            <ContactUs/>
        </AppBar>
    )
}

export default withTranslation()(Header)