import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
//* Globals
let ignoreAlert = false;

const attributeExists = (e, attr) => {
    let exists = false;
    let elem = e.target === "" ? e : (e.currentTarget || e.target);

    for (let i = 0, atts = elem.attributes || [], n = atts.length; i < n; i++) {
        if (typeof attr === "object") {
            const index = attr.findIndex(a => a === atts[i].nodeName);
            if (index > -1) exists = true;
        } else if (atts[i].nodeName === attr) {
            exists = true;
        }
    }

    return exists;
}

const removeAttributeIgnore = e => {
    const ele = e.currentTarget || e.target || e.srcElement;
    ele.removeAttribute("ignoreTemp");
}

const BeforeUnloadComponent = (
    {
        ignoreChildrenLinks = false,
        ignoreBeforeunloadDocument = false,
        blockRoute = true,
        children,
        modalComponentHandler,
        beforeUnload,
        beforeUnloadSendBeacon,
        alertMessage = "Are you sure you want to leave? Changes will not be saved."
    }) => {

    let childEle = useRef();
    const {t} = useTranslation();
    alertMessage = t('common.leave_alert_message')
    const [showModal, setShowModal] = useState(false);
    const [originTarget, setOriginTarget] = useState({});

    const handleModalCancel = event => {
        if (event && event.preventDefault) event.preventDefault();
        setShowModal(false);
    }

    const handleModalLeave = event => {
        const ele = originTarget;
        if (event && event.preventDefault) event.preventDefault();

        setShowModal(false);

        const updateClick = eleUpdated => {
            eleUpdated.setAttribute("ignoreTemp", "true");
            eleUpdated.click();
        }

        if (beforeUnload) {
            return beforeUnload(() => {
                updateClick(ele);
            })
        }

        return updateClick(ele);
    };

    const handleIgnoreLink = event => {
        const ele = event.target || event.srcElement;
        ele.setAttribute("ignoreTemp", "true");
        ele.click();
    }

    const defaultModalHandler = () => {
        const r = window.confirm(alertMessage);
        setShowModal(false);
        if (r === true) {
            handleModalLeave();
        } else {
            handleModalCancel();
        }
    }

    const onUnload = e => {
        setShowModal(false);
        if (blockRoute && !ignoreAlert && !attributeExists(document.activeElement, ["custom-ignore", "ignore"])) {
            e.preventDefault();
            e.returnValue = alertMessage;
            return alertMessage;
        } else {
            ignoreAlert = false;
        }
    };

    const handleClickEvents = e => {
        if (attributeExists(e, "ignoretemp")) {
            removeAttributeIgnore(e);
            setOriginTarget(null);
        } else if (blockRoute && e.target.href !== window.location.href) {
            e.preventDefault();

            if (attributeExists(e, ["custom-ignore", "ignore"])) {
                ignoreAlert = true;
                return handleIgnoreLink(e);
            }

            setOriginTarget(e.currentTarget || e.target);
            return setShowModal(true);
        }

        return true;
    };

    const handleBeacon = () => {
        return navigator.sendBeacon(
            beforeUnloadSendBeacon.path,
            beforeUnloadSendBeacon.data
        )
    }

    const setEventListeners = () => {
        const links = document.getElementsByTagName("a");

        for (let i = 0; i < links.length; i++) {
            if (attributeExists(links[i], "href") && !attributeExists(links[i], ["custom-ignore", "ignore"])) {
                links[i].addEventListener("click", handleClickEvents, false);
            }
        }

        if (ignoreChildrenLinks && childEle.current) {
            const childrenLinks = childEle.current.getElementsByTagName("a");
            for (let i = 0; i < childrenLinks.length; i++) {
                childrenLinks[i].setAttribute("ignore", "true");
            }
        }

        if (!ignoreBeforeunloadDocument) {
            window.addEventListener("beforeunload", onUnload);
        }

        if (beforeUnloadSendBeacon) {
            if (!navigator.sendBeacon)
                throw new Error("sendBeacon not supported | remove beforeUnloadSendBeacon");
            window.addEventListener("unload", handleBeacon);
        }
    }

    const removeEventListeners = () => {
        const links = document.getElementsByTagName("a");
        for (let i = 0; i < links.length; i++) {
            links[i].removeEventListener("click", handleClickEvents, false);
        }

        if (!ignoreBeforeunloadDocument)
            window.removeEventListener("beforeunload", onUnload);

        if (beforeUnloadSendBeacon && navigator.sendBeacon) {
            window.removeEventListener("unload", handleBeacon);
        }
    }

    const defaultComponentAlert = modalComponentHandler || defaultModalHandler;

    useEffect(() => {
        setEventListeners();
        return () => {
            removeEventListeners();
        }
    })

    return (
        <>
            {showModal
                ? defaultComponentAlert({handleModalLeave, handleModalCancel})
                : null}
            <div className={'fields_column'} ref={childEle}>{children}</div>
        </>
    )
}

BeforeUnloadComponent.propTypes = {
    ignoreChildrenLinks: PropTypes.bool,
    blockRoute: PropTypes.bool,
    ignoreBeforeunloadDocument: PropTypes.bool,
    children: PropTypes.any.isRequired,
    alertMessage: PropTypes.string,
    modalComponentHandler: PropTypes.any,
    beforeUnload: PropTypes.func,
    beforeUnloadSendBeacon: PropTypes.object
}

export default BeforeUnloadComponent;