import React from "react"
import axios from 'axios'
import {connect} from "react-redux";
import {withCookies} from 'react-cookie'
import {withTranslation} from "react-i18next";
import Loading from "../../components/Loading";
import {setUserAuth} from "../../store/portal/actions";
//import InvalidEmail from "../../components/modals/InvalidEmail";
import SignUp from "../../components/insurance/create_account/SignUp";
import SignIn from "../../components/insurance/create_account/SignIn";
import UserEmail from "../../components/insurance/create_account/UserEmail";
import EnterEmail from "../../components/insurance/create_account/EnterEmail";
import ResetPassword from "../../components/insurance/create_account/ResetPassword";
//import EmailValidationLoading from "../../components/modals/EmailValidationLoading";
//import EmailConfirmation from "../../components/insurance/create_account/EmailConfirmation";
import {changeAnswers, updateInsStoreKey, updateProgress} from "../../store/insurance/actions";
import B2ZLoading from "../../components/B2ZLoading";
import {addPaymentMethodAuth} from '../../services/carrierService'
import {datadogRum} from "@datadog/browser-rum";
import {fromPairs, some} from "lodash";
import {toCent} from "../../hooks/get-premium-total";
import LoadingButton from "../../components/LoadingButton";
import {Grid} from "@material-ui/core";

/**
 * React components EmailConfirmation and InvalidEmail are disabled as part of the new UI design implementation.
 * These components were previously used to confirm validity of user email.  Currently, these are bypassed.
 * 1. If a user exists and has an account, a login page is displayed prior to checkout which loads previously used payment methods.
 * 2. If a user exists but does not have an account or does not exist at all, they are bypassed directly to the checkout page.
 */

class CreateAnAccount extends React.Component {

    state = {
        exists: false,
        temp_pass: '',
        pass: '',
        pass_repeat: '',
        errors: {},
        nextUrl: '/results',
        processing: false,
        form: 'blank',
        show_confirmation: false,
        show_diya_help: false,
        email_notice: false,
        email_verifying: false,
        is_agent: false,
        validation_done: false,
        validation_result: null,
        email_validation_animation_done: false,
        current_email: ''
    }

    constructor(props) {
        super(props);

        this.validated_email_ref = React.createRef();

        this.state.nextUrl = "/get-insurance" + window.getNextSubSectionPath(this.props.subsections, this.props.next_subsection)

        this.props.updateProgress({
            current_section: this.props.section.section_index,
            current_subsection: this.props.next_subsection - 1,
        })
    }

    componentDidMount() {
        this.checkUserExists()
        //else this.showEmailConfirmation()
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    checkUserExists() {
        this.setState({form: "loading"}, () => {
            axios.post("/api/user/check", {
                username: this.props.answers["contact.email"]
            })
                .then(response => {
                    let exist = response.data["exists"] && response.data?.status !== 'FORCE_CHANGE_PASSWORD' // applies to existing email in system but no actual account
                    //Take a next action based on result

                    if (exist) {
                        this.setState({
                            processing: false,
                            exists: exist,
                            form: 'sign-in',
                            email_verifying: false,
                        })
                    } else {
                        this.setState({
                            exists: exist,
                            email_verifying: false,
                        }, () => {
                            if (!response.data["exists"]) this.registerNewUser()
                            else {
                                this.props.updateInsStoreKey('is_new_user', true)
                                setTimeout(() => {
                                    this.getFullQuote()
                                }, 0)
                            }
                        })
                    }
                })
                .catch(e => {

                    //Else disable loading and register user
                    this.setState({
                        exists: false,
                        email_verifying: false,
                    }, () => {
                        this.registerNewUser()
                    })
                    throw new Error(`Can't check if user exists. ${e}`)
                })
        })
    }

    registerNewUser() {
        this.props.updateInsStoreKey('is_new_user', true)
        this.registerUser(true)
            .then(() => {
                this.getFullQuote()
            })
    }

    async registerUser(suppress_email = false) {
        return await axios.post("/api/user", {
            "username": this.props.answers["contact.email"],
            "first_name": this.props.answers["contact.first_name"],
            "last_name": this.props.answers["contact.last_name"],
            "phone_number": this.props.answers["contact.phone"].toString(),
            "street_address": this.props.answers["business.street_address"],
            "street_address2": this.props.answers["business.street_address2"],
            "city": this.props.answers["business.city"],
            "state": this.props.answers["business.state"],
            "zip": this.props.answers["business.zip_code"].toString(),
            "suppress_email": suppress_email
        })
            .then(() => true)
            .catch(e => {
                datadogRum.addError(`Can't register the user. ${e}`)
                return true
            })
    }

    setLogin(value) {
        this.props.changeAnswers({
            "contact.email": value.toLowerCase()
        })
    }

    validateForm(e) {
        if (e) e.preventDefault()

        let errors = {}

        const {t} = this.props
        const pass_re = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;

        if (this.state.temp_pass.length < 1) errors.temp_pass = t('auth.error.temp_pass_req')

        if (this.state.pass.length < 8) errors.pass = t('auth.error.pass_length')
        else if (!pass_re.test(this.state.pass)) errors.pass = t('auth.error.pass_not_contains')

        if (this.state.pass !== this.state.pass_repeat) errors.pass_repeat = t('auth.error.pass_not_equal')

        this.setState({errors: errors})

        if (Object.keys(errors).length === 0) {
            this.setState({processing: true}, () => {
                this.createUser()
            })
        }
    }

    validateResetForm(e) {
        if (e) e.preventDefault()

        let errors = {}

        const {t} = this.props
        const pass_re = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;

        if (this.state.temp_pass.length < 1) errors.temp_pass = t('auth.error.temp_pass_req')

        if (this.state.pass.length < 8) errors.pass = t('auth.error.pass_length')
        else if (!pass_re.test(this.state.pass)) errors.pass = t('auth.error.pass_not_contains')

        if (this.state.pass !== this.state.pass_repeat) errors.pass_repeat = t('auth.error.pass_not_equal')

        this.setState({errors: errors})

        if (Object.keys(errors).length === 0) {
            this.setState({processing: true}, () => {
                this.confirmPasswordReset()
            })
        }
    }

    validateLoginForm(e) {
        if (e) e.preventDefault()

        let errors = {}

        if (!/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(this.props.answers["contact.email"]))
            errors.login = this.props.t('auth.error.invalid_req_login')

        if (this.state.pass.length < 1) errors.pass = this.props.t('auth.error.pass_req')

        this.setState({errors: errors})

        if (Object.keys(errors).length === 0) {
            //try to login a user
            this.setState({processing: true}, () => {
                this.login()
            })
        }
    }

    validateEmailForm(e) {
        if (e) e.preventDefault()

        if (this.props.answers['contact.email'] === this.props.answers['validated_email']) {
            this.checkUserExists()
        } else {
            let errors = {}

            if (!/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(this.props.answers["contact.email"]))
                errors.login = this.props.t('auth.error.invalid_req_login')

            this.setState({errors: errors})

            if (Object.keys(errors).length === 0) {
                //try to login a user
                this.setState({
                    processing: true,
                    email_verifying: true
                }, () => {
                    this.validateEmail(this.props.answers['contact.email'])
                        .then(result => {
                            this.setState({
                                validation_done: true,
                                validation_result: result,
                            }, () => {
                                if (this.checkEmailValidationReadiness()) this.takeNextAction()
                                if (result === 'valid') {
                                    this.props.changeAnswers({
                                        'validated_email': this.props.answers['contact.email']
                                    })
                                }
                            })
                        })
                })
            }
        }
    }

    validateUserEmailForm(e) {
        if (e) e.preventDefault()

        let errors = {}

        if (!/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(this.props.answers["contact.email"]))
            errors.login = this.props.t('auth.error.invalid_req_email')

        this.setState({errors: errors})

        if (Object.keys(errors).length === 0) {
            //try to login a user
            this.setState({
                processing: true,
                email_verifying: true
            }, () => {
                this.validateEmail(this.props.answers['contact.email'])
                    .then(result => {
                        this.setState({
                            validation_done: true,
                            validation_result: result,
                        }, () => {
                            if (this.checkEmailValidationReadiness()) this.takeNextAction()
                        })
                    })
            })
        }
    }

    createUser() {
        //try to create a new user
        axios.post("/api/user/confirm", {
            "username": this.props.answers["contact.email"],
            "temp_password": this.state.temp_pass.trim(),
            "new_password": this.state.pass
        }).then((data) => {
            if (data.data.authorized) {
                this.props.setUserAuth(data.data)
                setTimeout(() => {
                    this.getFullQuote()
                }, 500)
            }
        }).catch(e => {

            this.setState({
                errors: {
                    temp_pass: this.props.t('auth.error.invalid_temp_pass')
                },
                processing: false
            })
            throw new Error(`Can't confirm the user. ${e}`)
        })
    }

    login() {
        axios.post('/api/user/login', {
            username: this.props.answers["contact.email"],
            password: this.state.pass,
        })
            .then(response => {
                if (response.data.authorized) {
                    this.props.setUserAuth(response.data)
                    setTimeout(() => {
                        this.getFullQuote()
                    }, 0)
                }
            })
            .catch(e => {
                this.setState({
                    errors: {
                        common: e.response.status === 401
                            ? this.props.t('auth.error.login_or_pass')
                            : this.props.t('common.something_went_wrong')
                    },
                    processing: false
                })
            })
    }

    confirmPasswordReset() {
        axios.post("/api/confirm-reset-pass", {
            username: this.props.answers["contact.email"],
            code: this.state.temp_pass,
            password: this.state.pass_repeat
        }).then((response) => {
            if (response.data.success) {
                this.login()
            } else this.setState({
                errors: {
                    temp_pass: this.props.t('common.something_went_wrong')
                },
                processing: false
            })
        }).catch(e => {
            this.setState({
                errors: {
                    temp_pass: this.props.t('auth.error.invalid_temp_pass')
                },
                processing: false
            })
            throw new Error(`Can't confirm pass change for user. ${e}`)
        })
    }

    transformQuoteResponse(quote) {
        return (!!quote?.error) ? {
            error: quote.error,
            error_code: quote.error_code,
            quote_type: quote.type
        } : {
            ...quote.data,
            quote_type: quote.type,
            ...addPaymentMethodAuth(quote)
        }
    }

    getFullQuote() {
        this.setState({form: 'fq-loading'})
        let coverage_types = this.props.answers['selected_quotes']
            .filter(itm => !itm?.error)
            .map(itm => itm.quote_type)
        let STEStr = this.props.answers['business.location.home'] === 'Yes' ? '' : 'STE '
        let payload = {
            "answers": {
                ...this.props.answers,
                'business.street_address2': this.props.answers['business.street_address2']?.length > 0 ?
                    `${STEStr}${this.props.answers['business.street_address2']}`: this.props.answers['business.street_address2'] ,
                'coverage.types': coverage_types
            }
        }

        if (this.state.is_agent || this.props.is_new_user) payload["agent"] = true

        axios.post("/api/get-bindable-quote", payload).then((data) => {
            if (data.data.length > 0) {
                let quotes = data.data.map(itm => this.transformQuoteResponse(itm))
                quotes = addPaymentMethodAuth(quotes)

                if (quotes.filter(itm => !!itm?.error).length === quotes.length) {
                    this.setState({
                        processing: false,
                    }, () => {
                        this.props.onReject(quotes)
                    })
                } else {
                    this.props.updateInsStoreKey('full_quote', quotes)
                    if(some(quotes, ['quote_type', "CYBER"])){
                        this.getCyberEstimated(quotes)
                    }else{
                        this.props.updateInsStoreKey("estimatedPremium", null)
                    }
                    setTimeout(() => {
                        this.setState({
                            processing: false
                        }, () => {
                            this.props.changeAnswers({
                                'quote_status': 'Full Quote'
                            })
                        })

                        let urlAddress = this.props.answers['coverage.types'].includes('CYBER')? `${this.state.nextUrl}?checkout_session=${this.props.unique_session_id}`: this.state.nextUrl
                        this.props.history.push(urlAddress)
                    }, 0)
                }
            } else this.setState({
                processing: false
            }, () => {
                this.props.onReject(null, coverage_types)
            })
        }).catch(e => {

            this.setState({
                processing: false
            })
            this.props.onReject(null, coverage_types)
            throw new Error(`Can't get bindable quote. ${e}`)
        })
    }

    getCyberEstimated = async (selected_quotes)=>{
        let cyberQuote = selected_quotes.find(item=>item.quote_type === "CYBER")
        const premiumCents = toCent(cyberQuote.term_premium)

        axios.post('/api/ascend-finance-estimate', {premium_cents: premiumCents}).then(response => {
            let estimatedPayload = fromPairs(response.data)
            this.props.updateInsStoreKey("estimatedPremium", estimatedPayload)
        }).catch(e=>{
            this.props.updateInsStoreKey("estimatedPremium", null)
            throw new Error(e)
        })
    }
    resendTempPasswordEmail() {
        //try to resend a new temp password for user
        axios.post("/api/user/resend-temp-pass", {
            "username": this.props.answers["contact.email"],
        }).then((data) => {
            this.setState({
                errors: {},
                temp_pass: '',
                processing: false,
                show_diya_help: true
            })
        }).catch(e => {
            this.setState({
                errors: {
                    temp_pass: e.response.data.message
                },
                processing: false
            })
            throw new Error(`Can't resend temp password. ${e}`)
        })
    }

    resetPassword() {
        this.setState({
            processing: true
        }, () => {
            axios.post("/api/reset-password", {
                "username": this.props.answers["contact.email"],
            }).then((data) => {
                this.setState({
                    errors: {},
                    temp_pass: '',
                    pass: '',
                    pass_repeat: '',
                    processing: false,
                    form: 'reset-pass'
                })
            }).catch(e => {
                this.setState({
                    processing: false,
                    errors: {
                        common: e.response.data?.message || this.props.t('common.something_went_wrong')
                    },
                })
                throw new Error(`Can't confirm password reset. ${e}`)
            })
        })
    }

    proceedWithUserEmail() {
        this.registerUser(true)
            .then(() => {
                this.getFullQuote()
            })
    }

    showEmailConfirmation() {
        this.setState({
            processing: false,
            show_confirmation: true
        })
    }

    useProvidedEmail() {
        this.setState({
            processing: true,
            show_confirmation: false
        }, () => {
            this.checkUserExists()
        })
    }

    editProvidedEmail() {
        this.setState({
            processing: false,
            show_confirmation: false,
            form: 'e-mail'
        })
    }

    getForm() {
        switch (this.state.form) {
            case 'blank':
                return null
            case 'sign-in':
                return <SignIn errors={this.state.errors}
                               processing={this.state.processing}
                               login={this.props.answers['contact.email']}
                               password={this.state.pass}
                               validate={(e) => {
                                   this.validateLoginForm(e)
                               }}
                               onLoginChange={(value) => {
                                   this.setLogin(value)
                               }}
                               onPasswordChange={(value) => {
                                   this.setState({pass: value})
                               }}
                               resetPassword={() => {
                                   this.resetPassword()
                               }}
                />
            case 'sign-up':
                return <SignUp errors={this.state.errors}
                               processing={this.state.processing}
                               show_diya={this.state.show_diya_help}
                               temp_pass={this.state.temp_pass}
                               pass={this.state.pass}
                               pass_repeat={this.state.pass_repeat}
                               validate={(e) => {
                                   this.validateForm(e)
                               }}
                               onTempPasswordChange={(value) => {
                                   this.setState({temp_pass: value})
                               }}
                               onPasswordChange={(value) => {
                                   this.setState({pass: value})
                               }}
                               onPasswordRepeatChange={(value) => {
                                   this.setState({pass_repeat: value})
                               }}
                               resendTempPasswordEmail={() => {
                                   this.resendTempPasswordEmail()
                               }}
                />
            case 'reset-pass':
                return <ResetPassword errors={this.state.errors}
                                      processing={this.state.processing}
                                      temp_pass={this.state.temp_pass}
                                      pass={this.state.pass}
                                      pass_repeat={this.state.pass_repeat}
                                      validate={(e) => {
                                          this.validateResetForm(e)
                                      }}
                                      onTempPasswordChange={(value) => {
                                          this.setState({temp_pass: value})
                                      }}
                                      onPasswordChange={(value) => {
                                          this.setState({pass: value})
                                      }}
                                      onPasswordRepeatChange={(value) => {
                                          this.setState({pass_repeat: value})
                                      }}
                                      resetPassword={() => {
                                          this.resetPassword()
                                      }}
                />
            case 'e-mail':
                return <EnterEmail errors={this.state.errors}
                                   processing={this.state.processing}
                                   login={this.props.answers['contact.email']}
                                   validated_email_ref={this.validated_email_ref}
                                   validate={(e) => {
                                       this.validateEmailForm(e)
                                   }}
                                   onLoginChange={(value) => {
                                       this.setLogin(value)
                                   }}
                />
            case 'user-email':
                return <UserEmail errors={this.state.errors}
                                  processing={this.state.processing}
                                  login={this.props.answers['contact.email']}
                                  validated_email_ref={this.validated_email_ref}
                                  validate={(e) => {
                                      this.validateUserEmailForm(e)
                                  }}
                                  onLoginChange={(value) => {
                                      this.setLogin(value)
                                  }}
                />
            case 'fq-loading':
                return <B2ZLoading
                    quoteType={'FQ'}
                    loading_string={this.props.t('common.fq_validation_animation_strings', {returnObjects: true})}/>
            default:
                return <Grid container spacing={6} justifyContent={'center'} alignContent="stretch"
                             style={{height: '100%', margin: '0 -24px'}}>
                                <Grid item>
                                    <LoadingButton/>
                                </Grid>
                </Grid>
        }
    }

    showEmailNotice() {
        this.setState({
            email_verifying: false,
            email_notice: true,
            processing: false,
        })
    }

    closeEmailNotice(focus) {
        this.setState({
            email_notice: false
        }, () => {
            if (focus) {
                setTimeout(() => {
                    this.validated_email_ref.current.focus()
                }, 300)
            }
        })
    }

    async validateEmail(email) {
        return await axios.post('/api/validate-email', {
            email: email
        })
            .then(response => {
                return response.data?.status
            })
            .catch(e => {
                datadogRum.addError(`Can't validate user email. ${e}`)
                return ''
            })
    }

    takeNextAction() {
        if (this.state.form === 'e-mail') {
            if (this.state.validation_result === 'invalid') this.showEmailNotice()
            else this.setState({
                email_verifying: false
            }, () => {
                this.checkUserExists()
            })
        } else {
            if (this.state.validation_result === 'invalid') this.showEmailNotice()
            else this.setState({
                email_verifying: false
            }, () => {
                this.proceedWithUserEmail()
            })
        }
    }

    emailAnimationDone() {
        if (!this.state.email_validation_animation_done) {
            this.setState({email_validation_animation_done: true}, () => {
                if (this.checkEmailValidationReadiness()) this.takeNextAction()
            })
        }
    }

    checkEmailValidationReadiness() {
        return this.state.validation_done
            && this.state.email_validation_animation_done
    }

    render() {

        return (<>
            {/* Email Modal popup disabled via reducers.js */}

            {this.getForm()}

            {/*<EmailConfirmation is_open={this.state.show_confirmation}
                               email={this.props.answers['contact.email']}
                               onConfirm={() => {
                                   this.useProvidedEmail()
                               }}
                               onEdit={() => {
                                   this.editProvidedEmail()
                               }}
            />*/}
            {/*<InvalidEmail is_open={this.state.email_notice}
                          email={this.props.answers['contact.email']}
                          onClose={(focus = false) => {
                              this.closeEmailNotice(focus)
                          }}
            />*/}
            {/*{this.state.email_verifying && <EmailValidationLoading open={this.state.email_verifying}
                                                                   onCycleEnd={() => {
                                                                       this.emailAnimationDone()
                                                                   }}
            />*/}
        </>)
    }
}

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
        full_quote: state.insurance.full_quote,
        subsections: state.insurance.subsections,
        is_new_user: state.insurance.is_new_user,
        unique_session_id: state.insurance.unique_session_id,

    }
}

const mapDispatchToProps = {
    changeAnswers,
    updateProgress,
    setUserAuth,
    updateInsStoreKey
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateAnAccount)))
