/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import axios from 'axios'
import React, { useEffect, useReducer, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import NextSectionButton from '../../components/form-components/NextSectionButton'
import PaymentForm from '../../components/form-components/PaymentForm'
import SectionHead from '../../components/insurance/SectionHead'
import LoadingButton from '../../components/LoadingButton'
import FraudDisclaimer from '../../components/modals/FraudDisclaimer'
import QuoteNotEligible from '../../components/modals/QuoteNotEligible'
import TermsAndConditions from '../../components/modals/TermsAndConditions'
import { confirmEmailHelper } from '../../helpers/confirmEmail'
import { formatCurrency } from '../../helpers/currency'
import { getFeeValue } from '../../helpers/GetFeeValue'
import { getLeadUnderwriter } from '../../helpers/GetLeadUnderwriter'
import { trimBusinessName } from '../../helpers/trimBusinessName'
import CheckUserExists from '../../hooks/check-user-exists'

// Hooks
import DoRequest from '../../hooks/do-request'
import RegisterNewUser from '../../hooks/register-new-user'
import { bindPaymentMethod, PaymentMethod } from '../../services/paymentService'
import { changeAnswers, updateInsStoreKey } from '../../store/insurance/actions'
import { paymentAddressReducer } from './reducers/checkout-reducers'

const quotesReducer = (quotes, action) => {
    switch (action.type) {
        case "SET":
            return action.quotes
        default:
            throw new Error('quotesReducer error')
    }
}

const answersReducer = (answer, action) => {
    switch (action.type) {
        case "SET":
            return action.answer
        case "UPDATE":
            return changeAnswers(answer)
        case "CARD":
            let key = action.data.qid
            let value = action.data.answer
            return {[key]: value}
        case "PAYMENT_ADDRESSES":
            const {data: payment_method_addresses} = action.data
            return payment_method_addresses
        default:
            throw new Error('answerReducer error')
    }
}

const Checkout = React.memo(props => {

    // variables
    const {t} = props



    const quotes = props.full_quote.filter(itm => !itm?.error)

    // reducers
    const [quotes_to_purchase, dispatchQuotesToPurchase] = useReducer(quotesReducer, [])
    const [processing, setProcessing] = useState(false)

    // TODO: refactor this.  The billing_address variable is never used, but the set_billing_address reducer triggers a critical saved billing address update.
    // eslint-disable-next-line no-unused-vars
    const [billing_address, set_billing_address] = useReducer(paymentAddressReducer, undefined)
    // form reducers
    const [policyCancellation, setPolicyCancellation] = useReducer(answersReducer, false)
    const [contactEmail, setContactEmail] = useReducer(answersReducer, '')
    const [confirmEmail, setConfirmEmail] = useReducer(answersReducer, '')
    const [billFrequency, setBillFrequency] = useReducer(answersReducer, props.answers['billing.frequency'] === 'Annual')
    const [paymentMethod, setPaymentMethod] = useReducer(answersReducer, 1)
    const [bank_account, setBankAccount] = useReducer(answersReducer, '')

    // states
    const [payment_methods_loading, ] = useState(false)
    const [show_terms_info, setShowTermsInfo] = useState(false)
    const [show_fraud_info, setShowFraudInfo] = useState(false)
    const [open_quote_warning_modal, setOpenQuoteWarningModal] = useState(false)
    const [errors, setErrors] = useState({})
    const [cardHolderName, setCardHolderName] = useState(false)
    const [cardNumber, setCardNumber] = useState('')
    const [cardExpiry, setCardExpiry] = useState('')
    const [cardCVC, setCardCVC] = useState('')
    const [use_default_address, setUseDefaultAddress] = useState(false)
    const [payment_id, ] = useState('')
    const [bank_token, setBankToken] = useState('')
    const [emailValidated, setEmailValidated] = useState(null)
    const [emailConfirmed, setEmailConfirmed] = useState(true)


    useEffect(() => {
        if (!!props.answers['selected_quotes']) {
            const selectedQuotes = props.full_quote.filter(itm => !itm?.error)
            if (selectedQuotes.length !== props.answers['selected_quotes'].length) {
                setOpenQuoteWarningModal(true)
            } else {
                setOpenQuoteWarningModal(false)
            }
        }
        if (props.answers["billing.cardholder_name"] !== '') setCardHolderName(props.answers["billing.cardholder_name"])
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatchQuotesToPurchase({type: "SET", quotes: quotes})
    }, [])

    const clearAddressErrors = async (errors) => {
        let type = 'billing'
        if (props.answers[type + '.street_address']) {
            if (props.answers[type + '.street_address'].length >= 2) delete errors[type + '.street_address']
        }
        if (props.answers[type + '.city']) {
            if (props.answers[type + '.city'].length >= 2) delete errors[type + '.city']
        }
        if (props.answers[type + '.state']) {
            if (props.answers[type + '.state'].length === 2) delete errors[type + '.state']
        }
        if (props.answers[type + '.zip_code']) {
            if (props.answers[type + '.zip_code'].length >= 5)  delete errors[type + '.zip_code']
        }
        if (use_default_address) {

        }
        await setErrors(errors)
    }


    const updateAnswer = async (k, v) => {
        return await props.changeAnswers({
            [k]: v
        })
    }

    const objArray = (obj) => {
        return Object.keys(obj).map((k) => {
            return {
                qid: k,
                answer: obj[k]
            }
        })
    }

    const showFraudInfo = () => {
        setShowFraudInfo(true)
        setShowTermsInfo(false)
    }

    const showTermsInfo = () => {
        setShowTermsInfo(true)
    }

    const makePayment = async () => {
        setShowFraudInfo(false)
        setProcessing(true)
        await makeBindAndPayment()
    }

  const slackCustomerBindAlert = async (error) => {
      let payload = {
        answers: props.answers,
        error: error
      }

      await DoRequest({
        url: "/api/slack/customer-bind-alert",
        method: "post",
        body: {...payload}
      })
  }
    const coterieErrorHandler = (errorMessage) =>{
      const breakpoint = /__root__=\['|MessageId:/
      const splitted = errorMessage.split(breakpoint)[1].trim()
      switch (splitted) {
        case 'Your card has insufficient funds.':
          return "This card has insufficient funds. Could you try another card? Reach out to support if you are stuck: 800-330-1750."
        case 'Your card\'s security code is incorrect':
          return "The security code is incorrect."
        case 'Your card was declined.':
          return "This card was declined. Could you try another card? Reach out to support if you are stuck: 800-330-1750."
        case 'Your card does not support this type of purchase.':
          return "This card does not support this type of purchase. Could you try another card? Reach out to support if you are stuck: 800-330-1750."
        case 'Invalid account.':
          return "Error processing the card: Invalid Account. Could you try again or use another card? Reach out to support if you are stuck: 800-330-1750."
        default:
          return "Something went wrong processing the transaction. Please reach out to support at +1-800-330-1750."
      }
    }


    const makeBindAndPayment = async () => {
        // TODO: we will replace makeBankAccountPayAndBind and makeCreditCardPayAndBind with just this

        const ccInfo = {
            cardNumber: cardNumber?.num,
            expYear: cardExpiry?.year,
            expMonth: cardExpiry?.month,
            cardCVC: cardCVC?.cvc
        }

        const bankInfo = {
            publicToken: bank_token,
            accountToken: bank_account.toString()
        }

        try {
            const quotes = await bindPaymentMethod(quotes_to_purchase, props.answers, use_default_address,
                (paymentMethod === 1) ? PaymentMethod.Card : PaymentMethod.Bank,
                (paymentMethod === 1) ? ccInfo : bankInfo
            )

            let payload = {
                quotes: quotes,
                payment_format_name: props.answers['billing.frequency'],

            }
            if (props.is_new_user) payload["user_email"] = props.answers["contact.email"]

            try {
                await axios.post('/api/purchase-and-bind', payload)
                return props.onSuccess()
            } catch (e) {
                let errorMessage = coterieErrorHandler(e.response.data.error) || t('common.something_went_wrong')
                pushErrors('paymentError', errorMessage, false)
                await slackCustomerBindAlert(errorMessage)
            }
        } catch (e) {
            pushErrors('paymentError', t('common.something_went_wrong'), false)
        }
    }

    const changeHandler = async (e) => {
        const {answers} = props
        let data = objArray(e)[0]
        let qid = data.qid
        let answer = data.answer
        let res;
        let errorsData;

        switch (qid) {
            case 'billing.cardholder_name':
                setCardHolderName(answer)
                return updateAnswer(qid, answer)
            case 'contact.email':
                setProcessing(true)
                res = await confirmEmailHelper(contactEmail, answer, errors, setContactEmail, setEmailValidated)

                errorsData = errors
                delete errorsData['mismatched.emails']
                delete errorsData['invalid.email']
                setProcessing(false)
                return setErrors(errorsData)
            case 'confirm.email':

                setProcessing(true)
                res = await confirmEmailHelper(contactEmail, answer, errors, setConfirmEmail, setEmailValidated)

                let contactEmailRegEx = /^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
                if (!!res.status && !!contactEmailRegEx.test(contactEmail)) {
                    if (res.status === 'valid' || res.status === 'unknown') {

                        let errorsData = errors
                        delete errorsData['invalid.email']
                        setErrors(errorsData)

                        const promise = () => {
                            return new Promise(resolve => {
                                resolve(updateAnswer('contact.email', res.email))
                            })
                        }
                        await promise()
                        const response = await promise()
                        answers['contact.email'] = response.payload['contact.email']

                        await updateQuote(answers)

                        /**
                         * @function CheckUserExists: returns boolean for status of user in Cognito
                         * @function RegisterNewUser: registers email with Cognito if user does not exist
                         */
                        const newUserStatus = await CheckUserExists(answers['contact.email'])
                        if (!newUserStatus?.data['exists']) await RegisterNewUser(answers, false)

                    } else if (res.status === 'invalid') {
                        pushErrors('invalid.email', 'Email entered is invalid')

                    } else {
                        // TODO: add error handler for failed email verify api calls
                    }
                } else {
                    pushErrors('mismatched.emails', res['mismatched.emails'])
                    setEmailValidated(null)
                }
                setProcessing(false)
                break
            case 'policy.hassle_free_policy_cancelation':
                setProcessing(true)
                await setPolicyCancellation({type: 'SET', answer: answer})

                const promise = () => {
                    return new Promise(resolve => {
                        resolve(updateAnswer(qid, answer === true ? 'Yes' : 'No'))
                    })
                }

                const response = await promise()
                answers['policy.hassle_free_policy_cancelation'] = response.payload['policy.hassle_free_policy_cancelation']
                await updateQuote(answers)
                setProcessing(false)

                return
            case 'billing.frequency':
              setProcessing(true)
              await setBillFrequency({type: 'SET', answer: answer})
              const billingFrequencyPromise = () => {
                return new Promise(resolve => {
                  resolve(updateAnswer(qid, answer === true ? 'Annual': 'Monthly'))
                })
              }

              const billingFrequencyResponse = await billingFrequencyPromise()
              answers['billing.frequency'] = billingFrequencyResponse.payload['billing.frequency']
              await updateQuote(answers)
              setProcessing(false)
              return
            case 'payment.type':
                return setPaymentMethod({type: 'SET', answer: answer})
            case 'bank.account':
                return setBankAccount({type: 'SET', answer: answer})
            default:
                return updateAnswer(qid, answer)
        }
    }

    const validateCreditCard = () => {

        let errorsData = {}

        const name_regex =  /^(?<name>\D([a-zA-Z0-9.\s]){2,25})$/
        if (!cardNumber?.complete)
            errorsData['cardNumber'] = t('common.cc_num_req')

        if (!cardExpiry?.complete)
            errorsData['cardExpiry'] = t('common.exp_req')

        if (!cardCVC?.complete)
            errorsData['cardCVC'] = t('form.error.field_req')

        if (cardHolderName === false ||  name_regex.test(cardHolderName) === false)
            errorsData['billing.cardholder_name'] = t('common.cardholder_req')

        return errorsData
    }

    const validateBankAccount = () => {
        let errorsData = {}

        if (!bank_account)
            errorsData['bankAccount'] = t('form.error.field_req')

        if (!bank_token)
            errorsData['bankToken'] = t('common.connect_bank_acc_error')

        return errorsData
    }

    const validatePaymentMethod = () => {
        if (!payment_id) return {paymentError: t('common.choose_or_add_method')}
        else return {}
    }

    const validateBillingAddress = (type = 'business') => {

        let streetAddressError = {}
        if (props.answers[type + '.street_address'] === undefined || props.answers[type + '.street_address'].length < 2)
            streetAddressError = {[type + '.street_address']: props.t('form.error.field_req')}

        let cityAddressError = {}
        if (props.answers[type + '.city'] === undefined || props.answers[type + '.city'].length < 2)
            cityAddressError = {[type + '.city']: t('form.error.field_req')}

        let stateAddressError = {}
        if (props.answers[type + '.state'] === undefined || props.answers[type + '.state'].length < 2)
            stateAddressError = {[type + '.state']: t('form.error.field_req')}

        let zipAddressError = {}
        if (props.answers[type + '.zip_code'] === undefined || props.answers[type + '.zip_code'].length < 5)
            zipAddressError = {[type + '.zip_code']: t('form.error.field_req')}

        return {
            ...streetAddressError,
            ...cityAddressError,
            ...stateAddressError,
            ...zipAddressError
        }
    }

    const updateCreditCardFields = (event) => {
        if (event) {
            if (event?.cardNumber !== undefined)
                setCardNumber(event.cardNumber)
            if (event?.cardExpiry !== undefined)
                setCardExpiry(event.cardExpiry)
            if (event?.cardCVC !== undefined)
                setCardCVC(event.cardCVC)
        }
    }

    const performFormValidation = (event) => {
        if (event) event.preventDefault()

        let paymentMethodErrors = {}

        switch (paymentMethod) {
            case 0:
                paymentMethodErrors = validateBankAccount()

                break;
            case 1:
                paymentMethodErrors = validateCreditCard()
                break;
            case 5:
                paymentMethodErrors = validatePaymentMethod()
                break;
            default:
                break;
        }

        let policySelectionErrors = {}
        if (quotes_to_purchase.length === 0) policySelectionErrors = t('common.no_polices_selected')

        let billingErrors = validateBillingAddress(use_default_address ? 'business' : 'billing')
        if (props.answers['billing.frequency'] === undefined || props.answers['billing.frequency'].length < 1)
            billingErrors = {...billingErrors, 'billing.frequency': t('form.error.field_req')}

        // Building final errors object
        const errorsData = {
            ...paymentMethodErrors,
            ...policySelectionErrors,
            ...billingErrors
        }

        if (Object.keys(errorsData).length === 0 && props.full_quote.length > 0) showTermsInfo()
        else setErrors(errorsData)
    }

    const switchAddress = () => {
        let type = 'billing'

        const {
            [type + '.street_address']: street,
            [type + '.city']: city,
            [type + '.state']: state,
            [type + '.zip_code']: zip,
            ...addressErrors
        } = errors

        setErrors(addressErrors)
        setUseDefaultAddress(!use_default_address)
    }

    const isQuoteSelected = (id) => {
        let selected = quotes_to_purchase.filter(itm => itm.id === id)

        //updateAnswer('selected_quotes', quotes_to_purchase)

        return selected.length > 0
    }

    const onCustomizeDone = (id, quote) => {
        let selected = quotes_to_purchase.findIndex(itm => itm.id === id)
        let quotes = [...quotes_to_purchase]
        quotes.splice(selected, 1)
        quotes.push(quote)

        dispatchQuotesToPurchase({type: "SET", quotes: quotes})
    }

    const onPolicyToggle = (id) => {
        let updated = [...quotes_to_purchase]
        let present = quotes_to_purchase.filter(itm => itm.id === id)
        if (present.length > 0) {
            updated = quotes_to_purchase.filter(itm => itm.id !== id)
        } else {
            let quote = props.full_quote.filter(itm => itm.id === id)
            updated.push(quote[0])
        }

        dispatchQuotesToPurchase({type: "SET", quotes: updated})
        updateAnswer('selected_quotes', updated)
        updateAnswer('underwriter', getLeadUnderwriter(updated))

        //document.dispatchEvent(new CustomEvent('quotes-changed'))
    }

    const getTotal = () => {
        let period = (props.answers["billing.frequency"] === 'Monthly') ? 12 : 1
        let fee = 0
        let total = 0

        quotes_to_purchase.forEach(itm => {
            total += (period === 1)
                ? parseFloat(itm.payment_formats[0].installments[0].due_amount)
                : parseFloat(itm.payment_formats[1].installments[1].due_amount)
        })

        quotes_to_purchase.forEach(itm => {
          fee += (itm.source === 'COTERIE') ? getFeeValue() : 0
        })

        let price = formatCurrency(total + fee, true)
        return <span className="amount">{price}</span>
    }


    const pushErrors = (key, value, processing = false) =>  {
        const errorsData = {
            ...errors,
            [key]: value
        }
        setErrors(errorsData)
        setProcessing(processing)
    }

    const goBack = () => {
        let index = props.current_subsection - 1
        if (props.subsections.length > 0 && index >= 0 && props.subsections[index]) {
            let path = "/get-insurance" + window.getPrevSubSectionPath(props.subsections, index)
            props.history.push(path)
        } else props.history.goBack()

    }

    const updateQuote = async (answers) => {
        const data = await quotes.map(quote => {
            return DoRequest({
                url: '/api/session/quote',
                method: "put",
                body: {
                    quote: quote.id,
                    data: {...answers}
                }
            })
        })
        return await Promise.all(data)
    }

    return (
        <div className={'checkout_section'}>
            <div className={'section_container'}>
                <SectionHead
                    title={t('page.checkout.title', {value: trimBusinessName(props.answers['business.name'])})}
                    txt={t('page.checkout.txt')}
                />
              <Grid container spacing={2}>
                <Grid item className="checkout_container">
                  {
                    props.questions.length > 0
                      ?
                      <PaymentForm
                        email={props.answers['contact.email']}
                        t={t}
                        questions={props.questions}
                        answers={props.answers}
                        errors={errors}
                        clearAddressErrors={clearAddressErrors}
                        is_new_user={props.is_new_user}

                        // form value states
                        emailConfirmed={emailConfirmed}
                        policyCancelation={policyCancellation}
                        contactEmail={contactEmail}
                        confirmEmail={confirmEmail}
                        billingFrequency={billFrequency}
                        paymentMethod={paymentMethod}
                        use_default_address={use_default_address}
                        payment_id={payment_id}
                        payment_methods_available={[]} // empty array since we disabled existing payments due to Direct billing
                        bank_token={bank_token}
                        payment_methods_addresses={[]}  // empty array since we disabled existing payments due to Direct billing

                        // form value change handlers
                        setEmailConfirmed={setEmailConfirmed}
                        handlePolicyCancelationChange={changeHandler}
                        handleContactEmailChange={changeHandler}
                        handleConfirmEmailChange={changeHandler}
                        handleBillingFrequencyChange={changeHandler}
                        handlePaymentMethodChange={changeHandler}
                        handleCreditCardHolderChange={changeHandler}
                        updateCreditCardFields={updateCreditCardFields}
                        setBankToken={setBankToken}
                        handleBankAccountChange={changeHandler}

                        // modals & toggles
                        full_quote={props.full_quote}
                        quotes_to_purchase={quotes_to_purchase}
                        switchAddress={switchAddress}
                        updateAddress={updateAnswer}
                        pushErrors={pushErrors}
                        payment_methods_loading={payment_methods_loading}
                        emailValidated={emailValidated}

                        // Policy Checkout Summary
                        isQuoteSelected={isQuoteSelected}
                        onPolicyToggle={onPolicyToggle}
                        onCustomizeDone={onCustomizeDone}
                        getTotal={getTotal}

                      />
                      : <></>
                  }

                </Grid>
              </Grid>


                {(errors?.common) &&
                        Object.keys(errors).map((key, _) => {
                            if (['common'].includes(key)) {
                                return (
                                  <Grid container spacing={2}>

                                  <Grid item xs={12} md={8} lg={6} className="form-input-column">
                                      <p className={'error_txt error_txt_md'}>{errors[key]}</p>
                                    </Grid>
                                  </Grid>
                                )
                            } else return null
                        })}

              <Grid container className={'section_navigation is_back_btn'}>
                {processing ? (
                  <LoadingButton/>
                ) : (<>
                    <button className="section_back_btn"
                            type={'button'}
                            onClick={() => {
                              goBack()
                            }}>
                      <span className={'a_btn a_btn_transparent'}>
                        <ArrowBack/>
                      </span>
                      <span className="a_mob_back">Back</span>
                    </button>
                    <NextSectionButton
                      nextDisabled={!props.answers['selected_quotes']?.length > 0}
                      validateFormMethod={performFormValidation}
                      className="section_next_btn"
                    />
                    <NextSectionButton
                      nextDisabled={!props.answers['selected_quotes']?.length > 0}
                      validateFormMethod={performFormValidation}
                      className="section_next_btn__mobile"
                    />
                  </>
                )}
              </Grid>

            </div>


          <FraudDisclaimer open={show_fraud_info}
                           onClose={() => {
                             setShowFraudInfo(false)
                           }}
                           onAccept={async () => {
                             await makePayment()
                           }}
          />

          <TermsAndConditions open={show_terms_info}
                              onClose={() => {
                                setShowTermsInfo(false)
                              }}
                              onAccept={() => {
                                showFraudInfo()
                              }}
          />
          {open_quote_warning_modal && <QuoteNotEligible/>}
        </div>
    )
})

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
        full_quote: state.insurance.full_quote,
        subsections: state.insurance.subsections,
        is_new_user: state.insurance.is_new_user,
        current_subsection: state.insurance.current_subsection,
        questions: state.insurance.subsections_questions
    }
}

const mapDispatchToProps = {
    changeAnswers,
    updateInsStoreKey
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Checkout))
