import React from 'react'

const AcrisureLogo = ({className}) => {
    return <svg className={className} width="173" height="28" viewBox="0 0 173 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1651_1698)">
            <path d="M50.8595 23.484H55.7037C54.606 22.9639 54.1736 21.9762 53.8305 21.2764L47.7432 8.78302L41.4949 21.3846C41.0274 22.2816 40.6125 23.0547 39.6759 23.484H44.3941C44.0159 23.2327 43.692 22.5329 43.9074 21.9587C44.0159 21.6708 44.6462 20.3427 44.6462 20.3427H50.5881C50.5881 20.3427 50.9488 21.2048 51.3269 22.1385C51.5423 22.6952 51.1466 23.3409 50.8595 23.484V23.484ZM45.5286 18.3498L46.2849 16.6623C46.7891 15.603 47.3651 14.3116 47.5997 13.7898C47.7975 14.2557 49.6708 18.3498 49.6708 18.3498H45.5286V18.3498Z" fill="#002C4D"/>
            <path d="M70.7232 19.7145C69.6605 20.882 68.3457 21.437 66.2028 21.437C63.6818 21.437 61.089 19.4981 61.089 16.3203C61.089 13.4478 63.4297 11.2576 66.2028 11.2576C67.8047 11.2576 69.0845 11.8492 70.2015 13.1965L70.3818 13.4111L70.5621 9.78476C70.5621 9.78476 69.4994 9.55091 69.067 9.42526C68.1846 9.19142 67.2848 8.92267 65.7354 8.92267C62.458 8.92267 58.6222 11.7951 58.6222 16.3185C58.6222 20.608 62.0081 23.7493 66.1678 23.7493C68.5996 23.7493 70.3993 23.0844 70.3993 23.0844L70.9753 19.4405L70.7232 19.7093V19.7145Z" fill="#002C4D"/>
            <path d="M94.6555 23.484H99.7694C98.9413 23.0181 98.4546 22.2991 98.4546 21.0251V11.673C98.4546 10.5963 98.5631 9.82491 99.5523 9.21411H94.8516C95.8968 9.80571 95.9686 10.6853 95.9686 11.673V21.0251C95.9686 22.1385 95.6447 22.9099 94.6538 23.484H94.6555Z" fill="#002C4D"/>
            <path d="M110.899 14.9049L110.827 14.8508C109.927 14.1685 109.116 13.5053 109.116 12.6432C109.116 11.7811 109.764 11.207 110.612 11.1895C112.322 11.1529 113.33 12.8404 113.33 12.8404L113.565 9.69921C113.565 9.69921 112.322 8.94531 110.342 8.94531C108.253 8.94531 106.615 10.6328 106.615 12.7863C106.615 14.6344 108.11 15.856 109.442 16.9153L109.803 17.2207C110.811 18.0287 111.639 18.7826 111.639 19.7162C111.639 20.9553 110.703 21.5294 109.82 21.5294C108.866 21.5294 107.569 21.1525 106.741 20.0757L106.489 19.7354L107.082 23.5223L107.208 23.4682C107.297 23.4316 107.371 23.4141 107.46 23.4141C107.676 23.4141 107.893 23.4682 108.162 23.5223C108.612 23.6305 109.208 23.7562 110.125 23.7562C112.448 23.7562 114.141 22.0146 114.141 19.6272C114.141 17.3289 112.268 15.9293 110.899 14.9067V14.9049Z" fill="#002C4D"/>
            <path d="M130.439 23.484H134.257C133.464 23.1071 132.925 22.1909 132.925 21.5103V11.207C132.925 10.3991 133.266 9.69926 134.113 9.23331H129.269C129.971 9.66435 130.421 10.3275 130.421 11.207V19.5365C130.097 20.0391 128.728 21.2956 126.82 21.2956C125.038 21.2956 124.173 19.9676 124.173 17.2574V11.1163C124.173 10.3799 124.479 9.69751 125.235 9.21411H120.391C121.597 9.77081 121.688 10.7219 121.688 11.7271V17.9205C121.688 21.4213 123.597 23.6114 126.659 23.6114C128.441 23.6114 129.828 22.5888 130.405 22.0862L130.442 23.4857L130.439 23.484Z" fill="#002C4D"/>
            <path d="M89.2896 23.5748C89.2721 23.5573 87.7227 21.779 84.4453 16.8613C85.382 16.3046 86.3361 15.1196 86.3361 13.378C86.3361 10.9366 84.5346 9.23157 81.9593 9.23157H77.2586C78.0692 9.71671 78.3756 10.3799 78.3756 11.1338V21.5993C78.3756 22.4247 77.5475 23.2153 77.0608 23.5015H82.0678C81.4743 23.1612 80.8616 22.334 80.8616 21.5993V17.5244H82.1221L84.3368 20.882C84.8953 21.7441 86.4972 23.772 89.2178 23.772H89.4524L89.2896 23.5748V23.5748ZM81.87 15.4791H80.8616V11.4042H81.7982C83.022 11.4042 83.9236 12.2838 83.9236 13.4146C83.9411 14.5629 83.078 15.4791 81.87 15.4791Z" fill="#002C4D"/>
            <path d="M153.11 23.5748C153.093 23.5573 151.543 21.779 148.266 16.8613C149.203 16.3046 150.157 15.1196 150.157 13.378C150.157 10.9366 148.355 9.23157 145.78 9.23157H141.079C141.89 9.71671 142.196 10.3799 142.196 11.1338V21.5993C142.196 22.4247 141.368 23.2153 140.882 23.5015H145.889C145.295 23.1612 144.682 22.334 144.682 21.5993V17.5244H145.943L148.158 20.882C148.716 21.7441 150.318 23.772 153.039 23.772H153.273L153.11 23.5748V23.5748ZM145.691 15.4791H144.682V11.4042H145.619C146.843 11.4042 147.744 12.2838 147.744 13.4146C147.762 14.5629 146.899 15.4791 145.691 15.4791Z" fill="#002C4D"/>
            <path d="M166.383 20.3253C166.022 20.8645 164.942 21.2223 163.933 21.2223H161.269V17.219H164.114C164.798 17.219 165.374 17.4336 165.752 18.027L165.735 14.275C165.446 14.9573 164.709 15.137 163.898 15.137H161.269V11.4025H163.681C164.707 11.4025 165.248 11.6904 165.661 12.371C165.661 12.371 165.607 11.8859 165.589 11.5456C165.481 10.2716 165.409 9.2298 164.599 9.2298H157.683C158.457 9.69575 158.8 10.4147 158.8 11.132V21.0949C158.8 22.1542 158.44 22.9622 157.485 23.4822H164.742C165.696 23.4822 165.913 22.2624 166.237 20.9867C166.274 20.6813 166.381 20.3218 166.381 20.3218L166.383 20.3253Z" fill="#002C4D"/>
            <path d="M172.487 10.2559C172.307 9.9331 172.072 9.6818 171.749 9.4846C171.425 9.2874 171.082 9.19666 170.723 9.19666C170.364 9.19666 170.021 9.28566 169.697 9.4846C169.39 9.66435 169.138 9.9331 168.958 10.2559C168.778 10.5962 168.688 10.9557 168.688 11.3327C168.688 11.7096 168.76 12.0691 168.958 12.4094C169.138 12.7323 169.373 13.001 169.697 13.2C170.164 13.4879 170.705 13.5595 171.318 13.4146C171.859 13.2 172.255 12.8579 172.507 12.392C172.669 12.0691 172.759 11.7096 172.759 11.3327C172.759 10.9557 172.669 10.5788 172.489 10.2559H172.487ZM172.198 12.2489C172.055 12.5368 171.857 12.7515 171.586 12.9138C171.316 13.0761 171.027 13.1476 170.721 13.1476C170.415 13.1476 170.145 13.0761 169.875 12.9138C169.606 12.7515 169.408 12.5368 169.245 12.2489C169.082 11.9609 169.01 11.6573 169.01 11.3327C169.01 11.0081 169.082 10.7044 169.245 10.4531C169.408 10.1844 169.606 9.968 169.875 9.80745C170.127 9.64515 170.416 9.5736 170.721 9.5736C171.026 9.5736 171.314 9.64515 171.586 9.80745C171.838 9.96975 172.053 10.1844 172.198 10.4531C172.344 10.7219 172.414 11.0273 172.414 11.3501C172.414 11.673 172.342 11.9784 172.198 12.2471V12.2489Z" fill="#002C4D"/>
            <path d="M171.605 12.1215V11.9418C171.587 11.6364 171.479 11.4392 171.244 11.3502C171.479 11.2245 171.605 11.0448 171.605 10.8109C171.605 10.5771 171.533 10.3973 171.37 10.2717C171.208 10.1635 170.975 10.0919 170.686 10.0919H169.893V12.5159H170.288V11.5474L170.829 11.5648C171.045 11.5648 171.171 11.673 171.208 11.8527V12.1581C171.208 12.3379 171.225 12.4635 171.244 12.5351H171.659V12.481C171.622 12.4094 171.605 12.2838 171.605 12.1215V12.1215ZM171.064 11.0622C170.992 11.1338 170.884 11.1704 170.74 11.1704H170.29V10.434H170.686C170.866 10.434 171.01 10.4706 171.081 10.523C171.153 10.5771 171.19 10.6661 171.19 10.8109C171.19 10.9191 171.136 10.9907 171.064 11.0622V11.0622Z" fill="#002C4D"/>
            <path d="M20.6333 7.44452L32.3946 27.2569H25.376L17.1213 13.3605L20.6333 7.44452V7.44452Z" fill="#3F75A0"/>
            <path d="M12.9826 20.3498L16.4508 14.5071L19.919 20.3567L12.9826 20.3498Z" fill="#002C4D"/>
            <path d="M19.9558 6.29274L7.50989 27.2569H0.5L16.4508 0.368042L19.9558 6.29274Z" fill="#002C4D"/>
            <path d="M20.6036 21.5172L24.007 27.2499H8.88072L12.2894 21.5085L20.6036 21.5172Z" fill="#002C4D"/>
        </g>
        <defs>
            <clipPath id="clip0_1651_1698">
                <rect width="172.257" height="26.8889" fill="white" transform="translate(0.5 0.368042)"/>
            </clipPath>
        </defs>
    </svg>

}

export default AcrisureLogo





