import React from "react"
import {withTranslation} from "react-i18next";

class ErrorInsuranceCard extends React.Component {

    getPolicyNameText() {
        switch (this.props.quote_type) {
            case 'BOP':
                return this.props.t('product.bop.lob_policy_full');
            case 'WC':
                return this.props.t('product.wc.lob_policy_full');
            case 'PL':
                return this.props.t('product.pl.pl_policy_full');
            case 'GL':
                return this.props.t('product.gl.gl_policy_full');
            case 'CYBER':
                return this.props.t('product.cyber.cyber_policy_full');
            default:
                return this.props.t('common.policy');
        }
    }

    getText() {
        return this.props.t('common.not_match_for_quote', {value: this.getPolicyNameText()});
    }

    render() {
        return (
            <div className={'quote-card outlined'}>
                <div className="name">{this.getPolicyNameText()}</div>
                <p className="">{this.getText()}</p>
            </div>
        )
    }
}

export default withTranslation()(ErrorInsuranceCard)
