import React, {useEffect, useState} from 'react'
import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Close} from '@material-ui/icons'
import {withTranslation} from 'react-i18next'
import {useTheme} from '@material-ui/core/styles'
import {Dialog, DialogActions, DialogContent} from '@material-ui/core'
import {ModalTransition} from '../../common/ModalTransition'
import {getLimitValue} from '../../helpers/display'
import {getInsuranceCarrier} from '../../helpers/GetInsuranceCarrier'
//scss
import '../../scss/v2/modals/_fq_policy_details.scss'
import {connect} from 'react-redux'

const CYBERDetails = (props) => {
    const {t, answers, quotes, subsections_questions} = props
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const [notCoveredCyberCommonList, setNotCoveredCyberCommonList] = useState(t('modal.not_cover_cyber.list', {returnObjects: true}))
    const [listIcons] = useState(['computer.png', 'gavel.png', 'dollar.png', 'notify.png', 'service.png',
        'pr.png', 'circle_alert.png', 'message_circle.png', 'robbery.png'])


    const provider_data = getInsuranceCarrier(answers, 'CYBER', quotes)
    const assetsUrl = 'https://b2z-public-images.s3.amazonaws.com/acrisure'

    useEffect(() => {
        const getCyberOcKeys = () => {
            return Object.keys(answers).filter(key => key.startsWith('cyber.coalition.coverage_instances_') && !key.endsWith('_limit'))
        }

        const getCyberNotCoveredOc = () => {
            let questions = subsections_questions.filter(q => getCyberOcKeys().includes(q.qid) && answers[q.qid] === 'No')

            let notCoveredCyberOcList = []
            questions.forEach(q => {
                let item: { tip: string; txt: string; icon: string } = {
                    icon: listIcons[Math.floor(Math.random() * listIcons.length)],
                    txt: q['question_text'],
                    tip: q['diya_helper']
                }

                // @ts-ignore
                notCoveredCyberOcList.push(item)
            })
            return notCoveredCyberOcList
        }
        let list = [...getCyberNotCoveredOc(), ...notCoveredCyberCommonList]
        setNotCoveredCyberCommonList(list)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog open={props.open}
            fullWidth
            fullScreen={fullScreen}
            maxWidth={'sm'}
            container={() => document.getElementById('themeAppComponent')}
            className={'modal__wrapper fq_details_modal__wrapper whats_covered_modal__wrapper'}
            scroll={'paper'}
            onClose={() => props.onClose()}
            TransitionComponent={ModalTransition}>

            <DialogActions className={'modal_head'}>
                <button className={'modal_close_btn show_scroll_paper'}
                    type={'button'}
                    onClick={() => props.onClose()}><Close/>
                    <span>{t('common.btn_cancel')}</span></button>
            </DialogActions>

            <DialogContent className={'modal_body'}>
                <div className={'fq_details_card'}>
                    <div className={'detail_name'}>{t('common.cyber_policy')}</div>
                    <div className={'mobile_price'}>{props.price}</div>
                    <div className={'insurer_rate'}>
                        <div className="row">
                            <div
                                className={'fq_insurer'}>{props?.quote?.source.toUpperCase() === 'CF' ? t('common.crum_for') : provider_data?.name}</div>
                            <div className="divider"/>
                            <div
                                className={'fq_rate'}>{props?.quote.source?.toUpperCase() === 'CF' ? t('common.rated') : provider_data?.rating}</div>
                        </div>
                        {provider_data?.logo &&
                          <div className={'fq_carrier_logo'}><img src={`${assetsUrl}/carrier_logos/${provider_data.logo}.png`} alt=""/></div>}

                    </div>
                    <div className="limits_price_dates">
                        <div className="limits">
                            {Object.keys(props.quote.limits).map((key, idx) =>
                                (<div key={idx}><p>{props.quote.limits[key].display_name}</p>
                                    {getLimitValue(props.quote.limits[key])}
                                </div>)
                            )}
                        </div>
                        <div className="price-dates">
                            <div className="price">{props.price}</div>
                            <div className="dates">
                                <div>
                                    <p>{t('common.eff_date')}</p>{moment(props.quote.effective_date).format('MM/DD/YYYY')}
                                </div>
                                <div>
                                    <p>{t('common.exp_date')}</p>{moment(props.quote.expiration_date).format('MM/DD/YYYY')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-dates">
                        <div className="dates">
                            <div>
                                <p>{t('common.eff_date')}</p>{moment(props.quote.effective_date).format('MM/DD/YYYY')}
                            </div>
                            <div>
                                <p>{t('common.exp_date')}</p>{moment(props.quote.expiration_date).format('MM/DD/YYYY')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="whats_covered_modal_cnt">
                    <p className="covered_sub_ttl">{t('modal.not_cover_cyber.question')}</p>
                    <div className="not_covered_card">
                        <p className="card_txt">{t('modal.not_cover_cyber.txt')}</p>
                        <ul className="card_list">
                            {notCoveredCyberCommonList.map(({icon, txt, tip}, idx) => (
                                <li className="card_list_item" key={idx}>
                                    <i className="list_item_icon">
                                        <img src={`${assetsUrl}/icons/${icon}`} alt={txt}/></i>
                                    <span className="list_item_tip">
                                        <span className="txt">{txt}</span>
                                        <span className="tip">{tip}</span>
                                    </span>
                                </li>))}
                        </ul>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}


const mapStateToProps = state => {
    return {
        subsections_questions: state.insurance.subsections_questions,
        subsections_questions_data: state.insurance.subsections_questions_data
    }
}


export default connect(mapStateToProps)(withTranslation()(CYBERDetails))