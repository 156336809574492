import React from "react";
import {withTranslation} from 'react-i18next';
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, TextField} from "@material-ui/core";

class ValidatedEmail extends React.Component {

    onChange(e) {
        const value = e.target.value

        if (this.props.onChange) this.props.onChange(this.props.id, value.toLowerCase())
    }

    render() {
        const {t} = this.props

        return (
            <FormControl fullWidth={this.props.fullWidth} error={this.props.error} className={'form_control'}>
                <div className="label_blk">
                    <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                    {this.props.autofilled && <AutofilledBadge/>}
                </div>
                <TextField
                    type="email"
                    className={'fieldText'}
                    variant="outlined"
                    id="validated_email"
                    inputRef={this.props.inputRef}
                    value={this.props.answer}
                    error={!!this.props.error}
                    placeholder={this.props.placeholder}
                    onChange={(e) => {
                        this.onChange(e)
                    }}
                />
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
                <p className="privacy-notice" style={{
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '16px',
                }} dangerouslySetInnerHTML={{__html: t('common.validate_email')}}/>
            </FormControl>
        )
    }
}

export default withTranslation()(ValidatedEmail)
