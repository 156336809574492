import {LOG_OUT, SET_USER_AUTH} from "./actions";
import axios from "axios";

const initialState = {
    isAuthorized: false,
    username: null,
    name: null,
    surname: null,
    token_exp: null,
    resetEmail: '',
}

export const portalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_AUTH:
            return {
                ...state,
                isAuthorized: action.payload.authorized,
                username: action.payload?.email,
                name: action.payload?.name,
                surname: action.payload?.surname,
                phone: action.payload?.phone,
                street_address: action.payload?.street_address,
                street_address2: action.payload?.street_address2,
                city: action.payload?.city,
                state: action.payload?.state,
                zip: action.payload?.zip,
                comm_pref_email: action.payload?.comm_pref_email === 'True',
                comm_pref_sms: action.payload?.comm_pref_sms === 'True',
                comm_pref_phone: action.payload?.comm_pref_phone === 'True',
                token_exp: action.payload?.exp,
            }
        case LOG_OUT:

            axios.get('/api/user/logout')

            return {
                ...initialState
            }
        default:
            return state
    }

}