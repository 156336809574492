import React from "react";
import {cloneDeep, includes} from 'lodash'
import {Box, Divider} from "@material-ui/core";

class MultipleForms extends React.Component {

    state = {
        questions: [],
        checked: false,
        collapsed: true
    }

    constructor(props) {
        super(props);

        let questions = this.props.subsections_questions.filter((i) => {
            let parent_key = this.props.qid.replace('.is_selected', '').replace('_list', '')

            return i.qid !== this.props.qid && i.qid.startsWith(parent_key)
        })
        this.state.questions = cloneDeep(questions)
            .map(q => {
                q.hidden = false
                return q
            })
    }

    componentDidMount() {
        if (this.props[this.getDName(this.props.qid)].length > 0) this.setState({
            collapsed: false,
            checked: true
        }, () => {
            this.clearEmptyForms()
        })
    }

    clearEmptyForms() {
        let updated = [...this.props[this.getDName(this.props.qid)]]

        this.props[this.getDName(this.props.qid)].forEach((item, idx) => {
            let data = ''

            Object.keys(item).forEach(line => {
                data += item[line]
            })
            if (data === '') {
                if (updated.length === 1) updated = []
                else updated.splice(idx, 1)
            }
        })
        if (updated.length === 0) this.setState({checked: false, collapsed: true});

        this.props[this.getAName(this.props.qid)](updated)
    }

    collapseQuestion() {
        this.setState({collapsed: !this.state.collapsed})

        if (this.state.collapsed) this.clearEmptyForms()
    }

    onChange(newValue) {
        this.setState({checked: newValue});
        if (newValue === false) {
            this.props[this.getAName(this.props.qid)]([])
            this.setState({collapsed: true});
        }
        if (this.props[this.getDName(this.props.qid)].length === 0) this.addAdditionalLine()
    }

    setCapitalize(s) {
        s = s.replace('_', ' ')
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    getCollapsedLabel() {
        return (
            <>
                {this.props[this.getDName(this.props.qid)].map((item, idx) => {

                    let form_data = this.getTextFromDataMapping(item, this.getDName(this.props.qid))

                    return (<div key={idx} className={'form-item'}>
                            {Object.keys(form_data).map(line => (
                                    <React.Fragment key={line}>
                                        <span
                                            className={`label-item ${line.toLowerCase().replaceAll(' ', '_')}`}><span
                                            className={'bold'}>{this.setCapitalize(line)}: </span>{form_data[line]}</span>
                                    </React.Fragment>
                                )
                            )}
                            {(idx > 0) && <Box py={1}><Divider/></Box>}
                        </div>
                    )
                })}
            </>
        )
    }

    getTextFromDataMapping(item, source) {
        switch (source) {
            case 'designated_person':
                return {
                    name: item.name,
                    address: this.getAddressString(item)
                }
            case 'managers_or_lessors_premises':
                return {
                    name: item.name,
                    address: this.getAddressString(item)
                }
            case 'mortgagee_or_receiver':
                return {
                    name: item.name,
                    address: this.getAddressString(item)
                }
            case 'mortgageholder_information':
                return {
                    name: item.name,
                    address: this.getAddressString(item)
                }
            case 'loss_payable_clauses':
                return {
                    name: item.name,
                    address: this.getAddressString(item),
                    premises_description: item.premises_description,
                    provision_applicable: item.provision_applicable
                }
            case 'wtrr_against_others':
                return {
                    name: item.name
                }
            default:
                return item
        }
    }

    getAddressString(item) {
        let address = []
        if (item['street_address'] !== '') address.push(item['street_address'])
        if (item['street_address2'] !== '') address.push(item['street_address2'])
        if (item['city'] !== '') address.push(item['city'])
        if (item['state'] !== '') address.push(item['state'])
        if (item['zip_code'] !== '') address.push(item['zip_code'])

        return address.join(', ')
    }

    hasError() {
        let error = false
        Object.keys(this.props.error).forEach(line => {
            if (line.startsWith(this.props.qid)) error = true
        })
        return error
    }

    getNameFromMap(qid, type) {
        switch (type) {
            case 'data':
                return this.props.data_map[qid]
            case 'template':
                return this.props.data_map[qid] + '_template'
            case 'action':
                return 'update' + qid.replace('.is_selected', '').replaceAll(/[._]/g, '').toLowerCase()
            default:
                return null
        }
    }
    //Shortcut for get data object name
    getDName(qid) {
        return this.getNameFromMap(qid, 'data')
    }
    //Shortcut for get data template name
    getTName(qid) {
        return this.getNameFromMap(qid, 'template')
    }
    //Shortcut for get action name
    getAName(qid) {
        return this.getNameFromMap(qid, 'action')
    }

    getCellSize(qid) {
        let key = qid.split('.')
        key = key[key.length - 1]

        return includes(['city', 'state'], key) ? 6 : 12
    }

    addAdditionalLine() {
        let updated = [...this.props[this.getDName(this.props.qid)]]
        updated.push({...this.props[this.getTName(this.props.qid)]})

        this.props[this.getAName(this.props.qid)](updated)
    }

    removeAddition(idx) {
        let updated = [...this.props[this.getDName(this.props.qid)]]
        updated.splice(idx, 1)

        this.props[this.getAName(this.props.qid)](updated)
        if (this.props[this.getDName(this.props.qid)].length === 1) {
            this.setState({checked: false})
        }
    }

    getKey(qid, idx) {
        return qid.replace('*', idx)
    }

    updateAnswer(qid, index, value) {
        let key = qid.split('.')
        key = key[key.length - 1]

        let updated = [...this.props[this.getDName(this.props.qid)]]
        updated[index][key] = value

        this.props[this.getAName(this.props.qid)](updated)
    }

    render() {
        return null
    }

}

export default MultipleForms