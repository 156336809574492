import React, {useState} from 'react'
import {Box, Collapse, IconButton} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {Alert} from '@material-ui/lab'

export enum QuoteType {
    BOP = 'BOP',
    WC  = 'WC',
    PL  = 'PL'
}


interface propsObject {
    quoteType: QuoteType
    isOpen: boolean
    errMessage: string
    errCallback: (any) => any
}


export const ErrorAlert = (props: Partial<propsObject> & Pick<propsObject, 'isOpen' | 'errMessage' | 'errCallback'>) => {
    /**
     * CAUTION: this functional hook is designed for use in multiple places in this application.  Please ensure updates
     * to this are compatible with all components that use this.
     */
    const {isOpen, errMessage, errCallback, quoteType} = props
    const [open, setOpen] = useState(isOpen)

    const ActionIcon = () => {
        return (
            <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                    setOpen(!open)
                    errCallback({
                        type: quoteType,
                        isError: !open
                    })
                }}
            >
                <Close fontSize='inherit'/>
            </IconButton>
        )
    }

    return (
        <Box sx={{maxWidth: '610px', mb: 0}}>
            <Collapse in={open} className={'error_alert'}>
                <Alert
                    action={<ActionIcon/>}
                    severity='error'
                >{errMessage}
                </Alert>
            </Collapse>
        </Box>
    )
}
