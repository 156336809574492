import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux"
import LoadingButton from "../../LoadingButton";
import Question from "../../form-components/Question";
import {Grid} from "@material-ui/core";
import SectionHead from "../SectionHead";
import NextSectionButton from "../../form-components/NextSectionButton";


class SignIn extends Component {

    render() {
        const {t} = this.props

        return (
          <div className={'sign_in_section'}>

            <form autoComplete="off" className={'section_container'} onSubmit={(e) => this.props.validate(e)}>
              <SectionHead
                title={t('auth.found_already_registered')}
                txt={t('auth.sign_in_into_acc')}
              />
              <Grid container spacing={2} className={'section_form'}>

                <Grid item xs={12}>
                  <Question fullWidth
                            type="email"
                            question={t('auth.email_login')}
                            answer={this.props.login}
                            error={!!this.props.errors?.login}
                            helperText={this.props.errors?.login}
                            onChange={(id, value) => this.props.onLoginChange(value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Question fullWidth
                            type="password"
                            question={t('auth.pass')}
                            answer={this.props.password}
                            error={!!this.props.errors?.pass}
                            helperText={this.props.errors?.pass}
                            onChange={(id, value) => this.props.onPasswordChange(value)}
                  />
                </Grid>

                {this.props.processing ? null : (
                  <Grid item xs={12} className={'reset_password_blk'}>
                                    <span className={'underline_link reset_password_link'} onClick={() => {
                                      this.props.resetPassword()
                                    }}>{t('auth.btn.pass_reset')}</span>
                  </Grid>
                )}

                {!!this.props.errors?.common && (
                  <Grid item xs={12}>
                    <p className={'error_txt error_txt_md'}>{this.props.errors.common}</p>
                  </Grid>
                )}

              </Grid>
              <Grid container className={'section_navigation is_back_btn'}>
                {this.props.processing ? (
                  <LoadingButton/>
                ) : (<>
                    <NextSectionButton
                      onClick={e => this.props.validate(e)}
                      className="section_next_btn"/>
                    <NextSectionButton
                      onClick={e => this.props.validate(e)}
                      className="section_next_btn__mobile"/>
                  </>
                )}
              </Grid>

            </form>
          </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        subsections: state.insurance.subsections,
        current_subsection: state.insurance.current_subsection
    }
}

export default connect(mapStateToProps)(withTranslation()(SignIn))
