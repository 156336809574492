import React from "react";
import clsx from "clsx";
import {connect} from "react-redux";
import {Grid} from "@material-ui/core";
import Question from "../../Question";
import {cloneDeep, isEqual} from "lodash";
import {withTranslation} from 'react-i18next';
import {changeAnswers} from "../../../../store/insurance/actions";
import {currency, formatCurrency} from "../../../../helpers/currency";
import sources_update from '../../../../common/decorator'

//scss
import '../../../../scss/q_custom.scss'

class OptionalCoverageQuestion extends React.Component {

    state = {
        questions: [],
        ocTypes: ['cyber', 'hnoa'] // optional coverages with values we want to display in the question field, But cannot yet be changed by customer
    }

    constructor(props) {
        super(props);

        let questions = this.props.subsections_questions.filter((i) => {
            let parent_key = this.props.qid.replace('.is_selected', '')

            return i.qid !== this.props.qid && i.qid.startsWith(parent_key)
        })


        this.state.questions = cloneDeep(questions)
            .map(q => {
                q.hidden = false
                return q
            })

    }

    componentDidMount() {
        let coverageType = this.state.questions[0]?.qid?.split('.')[0]
        if (coverageType && this.state.ocTypes.includes(coverageType)) this.limitsUpdate(coverageType)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.answers, prevProps.answers)) {
            let coverageType = this.state.questions[0]?.qid?.split('.')[0]
            if (coverageType && this.state.ocTypes.includes(coverageType)) this.limitsUpdate(coverageType)
        }

        let questions = this.props.subsections_questions.filter((i) => {
            let parent_key = this.props.qid.replace('.is_selected', '')

            return i.qid !== this.props.qid && i.qid.startsWith(parent_key)
        })
        let new_questions = cloneDeep(questions)
            .map(q => {
                q.hidden = false
                return q
            })

        if (!isEqual(this.state.questions, new_questions))
            this.setState({
                questions: new_questions
            })
    }

    onChange(newValue) {
        const value = newValue ? 'Yes' : 'No'
        this.props.changeAnswers({
            [this.props.qid]: value
        })
        document.dispatchEvent(new CustomEvent('total-changed'))
        sources_update(this.props.answers, this.props.qid)
    }

    updateAnswer(qid, value) {
        this.props.changeAnswers({
            [qid]: value
        })
        if (this.props.answers[qid] !== value)
            this.props.onLimitChanged()
    }

    isUnselectableQuestion(key) {
        return [
            'mpl.coverages.occurrence_limit',
            'hnoa.coverage.annual_aggregate_limit',
            'hnoa.coverage.occurrence_limit'
        ].includes(key)
    }

    limitsUpdate(ocType) {
        /**
         * Method intended to update limit/deductible values that are set by the carrier and cannot be directly
         * changed.  Cyber deductible is an example; however, this may be editable by future carriers who offer a
         * similar coverage, so we still want to display the question and its associated valued.
         *
         * This will change the answer value for those quotes values we wish to show, that can only be set by carrier.
         */
        let coverage = this.props.answers.selected_quotes?.filter(q => q?.line_of_business === 'BOP')[0]
            ?.optional_coverages?.filter(o => o?.coverage_name === ocType)[0]

        if (typeof coverage === "object" && ![null, {}].includes(coverage)) {
            let ocKeys = Object.keys(coverage)

            if (ocKeys.length > 0) {
                for (let i = 0; i < ocKeys.length; i++) {
                    let qid = `${ocType}.coverage.${ocKeys[i]}`
                    if (Object.keys(this.props.answers).includes(qid)) {
                        this.props.changeAnswers({
                            [qid]: coverage[ocKeys[i]]
                        })
                    }
                }
            }
        }
    }

    render() {
        const {t} = this.props

        let switcherCheck = clsx({
            'switch-body': true,
            'checked': this.props.answers[this.props.qid] === 'Yes'
        })

        return (
            <Grid item className="oc-question-item">
                <div className={'oc-question'}>
                    <div className="switch-wrapper">
                        <div className="oc-type">
                                <span className={switcherCheck}>
                                    <label htmlFor={'switch-' + this.props.qid}>
                                        <input type="checkbox" id={'switch-' + this.props.qid}
                                               onChange={(e) => this.onChange(e.target.checked)}
                                               checked={this.props.answers[this.props.qid] === 'Yes'}/>
                                        <span className="thumb"/>
                                    </label>
                                </span>
                            <p className="oc-text">{this.props.question}&nbsp;{this.props.tooltip}</p>

                            {/* Desktop view of total */}
                            <div className="oc-price">
                                <div className="price">
                                    {formatCurrency(currency(this.props.price))}
                                    <small className="oc-price-period">&nbsp;/{t('common.month_short')}</small>
                                </div>
                            </div>
                        </div>

                        {/* Mobile view of total */}
                        <div className="mobile-oc-price">
                            <div className="mobile-price">
                                {formatCurrency(currency(this.props.price))}
                                <small className="oc-price-period">&nbsp;/{t('common.month_short')}</small>
                            </div>
                        </div>

                        {this.props.diya_helper && (
                            <div className="oc-desc">
                                <p className="custom-question-diya-helper">{this.props.diya_helper}</p>
                            </div>
                        )}
                    </div>
                    {this.props.answers[this.props.qid] === 'Yes' && this.state.questions.length > 0 && (
                        <div className="oc-limit">
                            <div className="limit">
                                    {this.state.questions.map((q, i) => {
                                        if (!q.disabled || q.options !== 0) return (
                                            <>
                                                <div className="name">{q.question_text}</div>
                                                <div className="question">
                                                    <Grid item xs={12} md={10} lg={8} key={i}>
                                                        <Question
                                                            fullWidth
                                                            {...q}
                                                            options={q.options}
                                                            required={q.required}
                                                            type={this.isUnselectableQuestion(q.qid) ? "UnselectableLimit" : "Limit"}
                                                            question={q.question_text}
                                                            field_length={q.max_length}
                                                            answer={this.props.answers[q.qid]}
                                                            error={!!this.props.error?.[q.qid]}
                                                            helperText={this.props.error?.[q.qid]}
                                                            onChange={(id, value) => this.updateAnswer(q.qid, value)}
                                                            coverageType={this.props.qid}
                                                        />
                                                    </Grid>
                                                </div>
                                            </>
                                        )
                                        else return null
                                    })}
                            </div>
                        </div>
                    )}
                </div>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
    subsections_questions: state.insurance.subsections_questions_data
})

const mapDispatchToProps = {
    changeAnswers
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OptionalCoverageQuestion))