import React from 'react'
import axios from 'axios'
import base64 from 'base-64'
import moment from 'moment'
import clsx from 'clsx'
import objectPath from 'object-path'
import policyType from '../../../helpers/policyType'
import LoadingButton from '../../LoadingButton'
import CommonPolicy from './CommonPolicy'
import { Chevron } from '../../icons/Chevron'
import { withTranslation } from 'react-i18next'
import { QuestionTooltip } from '../../form-components/QuestionTooltip'
import { Collapse } from '@material-ui/core'
import WCPolicyDetails from './WCPolicyDetails'
import WCPolicyLimits from './WCPolicyLimits'

class WCPolicy extends CommonPolicy {

  getAccord25Certificate () {
    //@TODO Refactor it. Check excluded_members and use getCertificatePayload helper
    this.setState({ loadingCertificate: true }, () => {
      const policyNumber = this.props.quote.policyNumber || this.props.quote.policy_number
      const floor = (!!this.props.answers_snapshot['business.street_address2']) ? `, ${this.props.answers_snapshot['business.street_address2']}` : ''

      let excluded_members = []

      let locations = this.getLocations(this.props.answers_snapshot)
      locations.forEach(loc => {
        if (loc?.owners && loc.owners.length > 0) {
          loc.owners.forEach(own => {
            if (own.is_included === false && !excluded_members.includes(`${own.first_name} ${own.last_name}`)) {
              excluded_members.push(`${own.first_name} ${own.last_name}`)
            }
          })
        }
      })

      if (excluded_members.length === 0) {
        excluded_members = []
      }
      axios.post('/api/get-certificate', {
        doc_type: 'wc_acord25',
        strict: true,
        //@TODO Update and check certificate payload using getCertificatePayload helper
        payload: {
          'provider': this.state.providerName.toUpperCase(),
          'business.name': this.props.answers_snapshot['business.name'],
          'business.address_line1': this.props.answers_snapshot['business.street_address'] + floor,
          'business.address_line2': this.props.answers_snapshot['business.city'] + ', ' + this.props.answers_snapshot['business.state'] + ', ' + this.props.answers_snapshot['business.zip_code'],
          'b2z_class_code_description': this.props.answers_snapshot['b2z_class_code_description'] || this.props.answers_snapshot['business.description'],
          'holder': {
            'name': this.props.answers_snapshot['business.name'],
            'address_line1': this.props.answers_snapshot['business.street_address'] + floor,
            'address_line2': this.props.answers_snapshot['business.city'] + ', ' + this.props.answers_snapshot['business.state'] + ', ' + this.props.answers_snapshot['business.zip_code']
          },
          'excluded_members': excluded_members,
          'creation_date': this.props.creation_date,
          'effective_date': this.props.effective_date,
          'expiration_date': this.props.expiration_date,
          'wc_each_employee_limit': this.props.quote.limits.wc_each_employee_limit.value,
          'wc_occurrence_limit': this.props.quote.limits.wc_occurrence_limit.value,
          'wc_policy_limit': this.props.quote.limits.wc_policy_limit.value,
          'policy_number': policyNumber,
          'aoi_list': [],
          'optional_coverages': []
        }
      })
        .then((data) => {
          const decodedData = base64.decode(data.data)
          const fileName = `COI-${policyNumber}`
          this.sendFile(decodedData, fileName)
        })
        .catch(e => {
          throw new Error(`Can't get user's policy acord25 certificate. ${e}`)
        })
        .finally(() => {
          this.setState({ loadingCertificate: false })
        })
    })
  }

  getLocations (answers) {
    let wc_locations = [],
      keys = Object.keys(answers).filter(itm => (/\.\d\./).test(itm))

    keys.sort().forEach(key => {
      let clear_key = key.replace(/^\w*\./, '')
      objectPath.set(wc_locations, clear_key, answers[key])
    })

    return wc_locations
  }

  render () {
    const { t } = this.props
    let details = clsx({
      'a_btn a_btn_action_3': true,
      'open': this.state.showDetails
    })

    return (
      <>
        <div ref={this.policyRef}
             className={`portal_policy_full_item`}>
          <div className={`portal_card ${this.checkFailPolicy() && 'failed_payment_policy'}`}>
            <div className={'row short_info'}>
              <div className="column_left">
                <span className={'item_business_name'}>{this.props.answers_snapshot['business.name']}</span>
                <span className={'policy_type'}>{policyType(this.props.type).longName}</span>
                <span
                  className={'policy_number'}>{this.props.t('common.policy_number')} - {this.props.quote.policy_number}</span>
                <span
                  className={'policy_address'}>{this.getAddress()}</span>
              </div>
              <div className="column_right">
                <div className={`item_policy_status status__${this.props.policy_status.toLowerCase()}`}>{this.getPolicyStatus(this.props.policy_status)}</div>
                {this.props.policy_status === 'ACTIVE' && <>
                    <span className={'policy_price'}>{this.getBillingValue()}</span>
                    <span className={'policy_billed'}>{this.getBillingPeriod()}</span>
                  </>}
              </div>
            </div>

            <div className="limits_links">
              <div className={'row short_info_2'}>
                <div className="column">
                  <span className={'column_name'}>{t('common.eff_date')}</span>
                  <span
                    className={'column_value'}>{moment(this.props.effective_date).format('MM/DD/YYYY')}</span>
                </div>
                <div className="column">
                  <span className={'column_name'}>{t('common.exp_date')}</span>
                  <span
                    className={'column_value'}>{moment(this.props.expiration_date).format('MM/DD/YYYY')}</span>
                </div>
                <div className="column">
                  <div className="column_name"><span className={'value'}>{t('common.insurer')}</span>
                    &nbsp;<QuestionTooltip text={this.state.providerPortal}/></div>
                  <span className={'column_logo'}><img src={this.state.logoUrl} alt=""/></span>
                </div>
              </div>

              <WCPolicyLimits {...this.props} />

             {/* {this.props.policy_status === 'ACTIVE' && <div className="row row_actions">
                {this.props.docs ? (
                  <p className={'action_item'} onClick={() => {
                    this.getDocuments()
                  }}>
                    {this.state.loadingDoc ? (
                      <span className={'policy_loading_blk'}>
                      <LoadingButton size={15}/>
                      <span>{t('common.downloading')}</span>
                    </span>
                    ) : (<span className={'a_btn a_btn_action_3'}>
                          {t('portal.policy.doc')}</span>)}
                  </p>
                ) : (
                  this.isDocsGenerated() ? <p className={'action_item docs_generated'}>
                    <span>{t('portal.policy.doc_gen')}</span>
                    <QuestionTooltip
                      text={t('portal.policy.doc_gen_tooltip')}/></p> : null
                )}
                {(this.props.quote.policy_number || this.props.quote.policyNumber) && (
                  <p className={'action_item'} onClick={() => {
                    this.getAccord25Certificate()
                  }}>{this.state.loadingCertificate ? (
                    <span className={'policy_loading_blk'}>
                      <LoadingButton size={15}/>
                      <span>{t('common.downloading')}</span>
                    </span>
                  ) : (
                    <span className={'a_btn a_btn_action_3'}>{t('portal.policy.certificate')}</span>
                  )}</p>
                )}
              </div>}*/}
            </div>


            <Collapse in={this.state.showDetails}>
              <WCPolicyDetails {...this.props}/>
            </Collapse>
            <div className="row row_bottom_actions">
              <button type={'button'}
                      className={details}
                      onClick={() => {
                        this.toggleDetails()
                      }}>{this.state.showDetails ? t('common.hide_policy_details') : t('common.show_policy_details')}
                <i><Chevron/></i></button>
            </div>

          </div>

        </div>
      </>
    )
  }
}

export default withTranslation()(WCPolicy)