import React from "react";
import {Dollar} from "../../icons/Dollar";
import {AutofilledBadge} from "./AutofilledBadge";
import {FormControl, MenuItem, TextField} from "@material-ui/core";
import sources_update from '../../../common/decorator'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'

class CurrencyDropdown extends React.Component {

    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            return amount
        }
    }

    onChange(e) {
        if (this.props.onChange) this.props.onChange(this.props.id, e.target.value)
        sources_update(this.props.answers, this.props.qid)
    }

    render() {
        return (
            <FormControl fullWidth={this.props.fullWidth}
                         className={'form_control'}
                         error={this.props.error}>
                {this.props.question && (
                    <div className="label_blk">
                        <div className="label"><span>{this.props.question}</span> &nbsp;{this.props.tooltip} </div>
                        {this.props.autofilled && <AutofilledBadge/>}
                    </div>
                )}
                <TextField
                    select
                    className={'fieldText'}
                    variant="outlined"
                    id={'question-' + this.props.id + '-select'}
                    value={this.props.answer}
                    error={this.props.error}
                    displayEmpty={!!this.props.placeholder}
                    onChange={(e) => this.onChange(e)}
                >
                    {this.props.placeholder && (
                        <MenuItem value="" key="disabled" disabled>{this.props.placeholder}</MenuItem>
                    )}
                    {this.props.options && this.props.options.map((opt, idx) => (
                        <MenuItem value={opt.value || opt.text} key={idx}><Dollar style={{verticalAlign: 'top'}}/> {this.formatMoney(opt.text, 0)}</MenuItem>
                    ))}
                </TextField>
                {this.props.error && <p className="error_txt error_txt_md">{this.props.helperText}</p>}
            </FormControl>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
})

export default connect(mapStateToProps)(withTranslation()(CurrencyDropdown))
  