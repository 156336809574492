import React from "react";
import axios from "axios";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import Question from "../form-components/Question";
import {updateWCEndorsement, updateWCLocations} from "../../store/insurance/actions";
import {Grid} from "@material-ui/core";
import sources_update from '../../common/decorator'
import LoadingButton from "../LoadingButton";


class WCOwner extends React.Component {

    state = {
        endorsement: this.props.WCOwnerEndorsement,
        loading: true,
        titleCode: this.owner().title_code,
        legalEntity: undefined,
        openIndustryClassesDropdown: false
    }

    componentDidMount() {
        if (!this.owner().title_code) {
          this.setState({loading: false})
        } else {
            if (this.props.legal_entity) this.checkOwnerEndorsement(this.owner().title_code)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if ((this.props.legal_entity !== undefined && prevState.legalEntity !== this.state.legalEntity)
            || prevState.titleCode !== this.state.titleCode) {

            if (this.owner().title_code) {
                this.checkOwnerEndorsement(this.owner().title_code)
            }
        }

        if (!this.state.loading && this.props.titles.length === 0) {
            this.setState({loading: true})
        }

        if (this.state.loading && this.props.titles.length > 0) {
            this.setState({loading: false})
        }
    }

    owner() {
        const locations = [...this.props.wc_locations]

        return locations[this.props.locationIndex].owners[this.props.index];
    }

    getDiyaMessage() {
        if (!this.state.endorsement) return;
        let val = this.state.endorsement.included ? 'included' : 'excluded'
        return this.props.t('page.ooi.you_inc_exc', {value: val});
    }

    canIncludeExclude() {
        if (!this.state.endorsement) return false;
        return this.state.endorsement.allow_include || this.state.endorsement.allow_exclude;
    }

    onChange(key, value) {
        const locations = [...this.props.wc_locations];
        const current_owner = this.owner();

        (locations[this.props.locationIndex].owners || []).forEach((o, oidx) => {
            if (o.id === current_owner.id) {
                locations[this.props.locationIndex].owners[oidx] = {...current_owner, [key]: value};
            }
        });

        this.props.updateWCLocations(locations)
        this.props.updateWCEndorsement(this.state.endorsement)
        sources_update(this.props.answers, key)
    }

    getQuestionAnswer(qid) {
        const path_components = qid.split('.'),
            prop_name = path_components[path_components.length - 1]

        return this.owner()[prop_name]
    }

    getAnswerKey(key) {
        return this.props.index + '.' + key
    }

    hasError() {
        let has_error = []
        for (let error in this.props.errors) {
            if (this.props.errors.hasOwnProperty(error)
                && error.startsWith(this.props.index + '.')) has_error.push(error)
        }

        return has_error.length > 0
    }

    onTitleChange(value) {
        this.setState({titleCode: value});
        this.setState({legalEntity: this.props.legal_entity})
        this.onChange('title_code', value);
    }

    removeOwner() {
        const current_owner = this.owner();
        const locations = [...this.props.wc_locations];

        (locations[this.props.locationIndex].owners || []).forEach((o, idx) => {
            if (o.id === current_owner.id) {
                const owners = locations[this.props.locationIndex].owners
                owners.splice(idx, 1)
                locations[this.props.locationIndex].owners = owners
            }
        })
        if (locations[0].owners.length < 2) locations[0].is_addition_owners = false
        this.props.updateWCLocations(locations)
        this.props.updateWCEndorsement(this.state.endorsement)
        this.props.onClearErrors()
    }

    checkOwnerEndorsement(title) {
        axios.post("/api/wc_endorsement", {
            legal_entity_code: this.props.legal_entity,
            title_code: title,
            state: this.props.answers['business.state']
        }).then(response => {
            this.onChange('disclaimer_required', response.data.disclaimer)
            this.onChange('is_included', response.data.included)
            this.setState({endorsement: response.data})
            this.setState({legalEntity: this.props.legal_entity})
        }).catch(e => {
          throw new Error(`Something went wrong while checking owner endorsement. ${e}`)
        })
    }

    render() {
        const {t} = this.props

        return (
          <Grid item xs={12} className={this.props.isNotFirst && 'wc_owner_item'}>
            <Grid container spacing={2}>

              <Grid item xs={12} className={'wc_custom_item_header'}>
                <p className="wc_section_ttl">
                  {this.props.index > 0 ? t('page.ooi.officer.additional_title') : t('page.ooi.officer.title')}
                </p>
                {this.props.index > 0 && (<button className="wc_additional_remove"
                                                  onClick={() => this.removeOwner()}
                >{t('common.btn_remove')}</button>)}
              </Grid>

              {this.state.loading ? (<LoadingButton/>) : (
                <>
                  <Grid item xs={12} md={6}>
                    <Question
                      fullWidth
                      id="first_name"
                      name="first_name"
                      question={t('page.ooi.officer.first_name.question')}
                      type="text"
                      error={this.props.errors?.[this.getAnswerKey('first_name')]}
                      helperText={this.props.errors?.[this.getAnswerKey('first_name')]}
                      answer={this.getQuestionAnswer('premises.*.owners.*.first_name')}
                      onChange={(name, value) => this.onChange(name, value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Question
                      fullWidth
                      id="last_name"
                      name="last_name"
                      question={t('page.ooi.officer.last_name.question')}
                      type="text"
                      error={this.props.errors?.[this.getAnswerKey('last_name')]}
                      helperText={this.props.errors?.[this.getAnswerKey('last_name')]}
                      answer={this.getQuestionAnswer('premises.*.owners.*.last_name')}
                      onChange={(name, value) => this.onChange(name, value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Question
                      fullWidth
                      id="title_code"
                      name="title_code"
                      question={t('page.ooi.officer.title_code.question')}
                      type="typeahead"
                      open={this.state.openIndustryClassesDropdown}
                      onOpen={(val) => this.setState({ openIndustryClassesDropdown: val })}
                      noOptionsText={t('page.ooi.officer.title_code.noOptionsText')}
                      answer={this.getQuestionAnswer('premises.*.owners.*.title_code')}
                      error={this.props.errors?.[this.getAnswerKey('title_code')]}
                      helperText={this.props.errors?.[this.getAnswerKey('title_code')]}
                      options={this.props.titles.map(i => ({
                        id: i.code,
                        value: i.code,
                        text: i.description
                      }))}
                      onChange={(a, b, value) => this.onTitleChange(value)}
                    />
                  </Grid>

                  {this.canIncludeExclude() && <Grid item xs={12}>
                    <Question
                      fullWidth
                      id="is_included"
                      name="is_included"
                      question={t('page.ooi.officer.is_included.question')}
                      type="boolean"
                      error={this.props.answers?.[this.getAnswerKey('is_included')]}
                      answer={!this.getQuestionAnswer('premises.*.owners.*.is_included')}
                      onChange={(name, value) => this.onChange(name, !value)}
                    />
                  </Grid>}
                  {this.owner().is_included && <><Grid item xs={12} md={6}>
                    <Question
                      fullWidth
                      id="percent"
                      name="percent"
                      question={t('page.ooi.officer.percent.question')}
                      type="percentage"
                      placeholder="0"
                      max_value={100}
                      error={this.props.errors?.[this.getAnswerKey('percent')]}
                      helperText={this.props.errors?.[this.getAnswerKey('percent')]}
                      answer={this.getQuestionAnswer('premises.*.owners.*.percent')}
                      onChange={(name, value) => this.onChange(name, value)}
                    />
                  </Grid>
                    <Grid item xs={12} md={6}>
                      <Question
                        fullWidth
                        id="salary"
                        name="salary"
                        question={t('page.ooi.officer.salary.question')}
                        type="currency"
                        placeholder="0"
                        error={this.props.errors?.[this.getAnswerKey('salary')]}
                        helperText={this.props.errors?.[this.getAnswerKey('salary')]}
                        answer={this.getQuestionAnswer('premises.*.owners.*.salary')}
                        onChange={(name, value) => this.onChange(name, value)}
                      />
                    </Grid></>}
                </>)
              }
            </Grid>
          </Grid>
        )
    }
}

const mapStateToProps = state => ({
    answers: state.insurance.answers,
    wc_locations: state.insurance.wc_locations,
    WCOwnerEndorsement: state.insurance.WCOwnerEndorsement
})

const mapDispatchToProps = {
    updateWCLocations,
    updateWCEndorsement
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WCOwner))
