/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Button, FormControl, FormControlLabel, FormGroup, Grid, Switch} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Question from "./Question";
import paramaterizedJSX from "../../helpers/paramaterizedJSX";
import {withTranslation} from "react-i18next";
import PaymentWithCard from "../../components/form-components/PaymentWithCard";
import PaymentWithBank from "../../components/form-components/PaymentWithBank";
import BillingAddress from "./BillingAddress";
import PolicyCheckoutSummary from "../PolicyCheckoutSummary";
import B2ZLoadingEmailValidation from "../B2ZLoadingEmailValidation";
import GreenCheckmark from "../icons/GreenCheckmark";
import {ErrorAlert} from '../insurance/ErrorAlert'
import {BillingMethod} from '../../services/carrierService'
import {some} from "lodash";

const IS_NEW_THEME_ENABLED = process.env?.REACT_APP_IS_NEW_THEME_ENABLED === "TRUE"
    || window.is_new_theme_enabled === 'TRUE'

const PaymentForm = (props) => {

    const {t, questions, answers, errors} = props
    useEffect(() => {

        const statesDropdown = document.querySelector('.fieldDropdown svg path[d="M7 10l5 5 5-5z"]')
        if (!!statesDropdown) {
            statesDropdown.setAttribute('d', 'M1 1L7 7L13 1')
            statesDropdown.setAttribute('stroke', '#172152')
            statesDropdown.setAttribute('stroke-width', '2')
            statesDropdown.setAttribute('stroke-linecap', 'round')
            statesDropdown.setAttribute('stroke-linejoin', 'round')
            statesDropdown.setAttribute('fill', 'none')
            statesDropdown.setAttribute('width', "14")
            statesDropdown.setAttribute('height', '8')

            const parentElem = statesDropdown.parentElement
            parentElem.setAttribute('viewBox', '0 0 14 8')
            parentElem.setAttribute('width', "14")
            parentElem.setAttribute('height', '8')
            parentElem.style.fontSize = '16px'
            parentElem.style.marginTop = '5px'
            parentElem.style.marginRight = '8px'

        }
    }, [props])

    useEffect(() => {
        if (errors) {
            props.clearAddressErrors(errors)
        }
    }, [errors, answers])


    // variables
    const hassleFreePolicyQuestion = questions.filter(q => q.qid === 'policy.hassle_free_policy_cancelation')[0]
    const emailQuestion = questions.filter(q => q.qid === 'contact.email')[0]
    const USStates = questions.filter(q => q.qid === 'business.state')[0]
    const [saved_address, ] = useState(null)
    const [paymentError, setPaymentError] = useState(false)
    const [bankEnabled, setBankEnabled] = useState(true)

    const changeHandler = (qid, e) => {
        return {[qid]: e}
    }

    const paymentFailureCallback = (isError) => {
        setPaymentError(isError)
    }

    const handlePaymentError = (isError) => {
        setPaymentError(isError)
    }

    const conditionalFormInputHandler = (v, type) => {
        switch (type) {
            case 'email':
                if (v === true) {
                    props.handleContactEmailChange(changeHandler(emailQuestion.qid, ''))
                    props.setEmailConfirmed(v)
                } else {
                    props.handleContactEmailChange(changeHandler(emailQuestion.qid, props.contactEmail))
                    props.setEmailConfirmed(v)
                }
                break
            default:
                break
        }
    }

    const handlePaymentMethodChange = (paymentType) => {
        props.handlePaymentMethodChange({'payment.type': paymentType})
        handlePaymentError(false)
    }

    const renderPaymentMethodFields = () => {

        const isDirectBilling = some(props.quotes_to_purchase, {carrierConfig: {paymentMethod: BillingMethod.Direct}})
        if (bankEnabled === true && isDirectBilling === true) {
            setBankEnabled(!bankEnabled)
        } else if (bankEnabled === false && isDirectBilling === false) {
            setBankEnabled(!bankEnabled)
        }

        if (isDirectBilling) {
            return <PaymentWithCard
                onCreditCardHolderChange={e => props.handleCreditCardHolderChange({'billing.cardholder_name': e})}
                updateCreditCardFields={e => props.updateCreditCardFields(e)}
                errors={errors}
            />
        } else {
            switch (props.paymentMethod) {
                case 1: // Credit Card
                    return <PaymentWithCard
                        onCreditCardHolderChange={e => props.handleCreditCardHolderChange({'billing.cardholder_name': e})}
                        updateCreditCardFields={e => props.updateCreditCardFields(e)}
                        errors={errors}
                    />
                case 2: // Apple pay
                    return (
                        <Button variant="outlined">Subscribe to regular payment with your Apple Pay account</Button>
                    )
                case 3: // Amazon Pay
                    return (
                        <Button variant="outlined">Subscribe to regular payment with your Amazon Pay account</Button>
                    )
                case 4: // PayPal
                    return (
                        <Button variant="outlined">Subscribe to regular payment with your PayPal account</Button>
                    )
                default: // Bank Account
                    return <PaymentWithBank
                        onChange={(key, value) => {
                            props.updateAnswer(key, value)
                        }}
                        onBankTokenChange={(token) => {
                            props.setBankToken(token)
                        }}
                        onBankAccountChange={(account) => {
                            props.handleBankAccountChange(changeHandler('bank.account', account))}
                        }
                        pushError={(err) => handlePaymentError(err)}
                        errors={errors}
                    />
            }
        }
    }

    const textInputStyle = {
        height: '45px !important'
    }

    const emailValidationCondition = () =>{
          return props.emailValidated === false || props.emailValidated === null || props.emailValidated === 'loading'
    }

    return (
        <div className="row">
            {
                paymentError &&
                <ErrorAlert
                    isOpen={paymentError}
                    errMessage={t('page.checkout.error.paymentFailure.message')}
                    errCallback={paymentFailureCallback}
                />
            }

            <div className="column2">
                <Grid item xs={12} className="policy-summary-card">
                    <PolicyCheckoutSummary
                        full_quote={props.full_quote}
                        isQuoteSelected={props.isQuoteSelected}
                        onPolicyToggle={props.onPolicyToggle}
                        onCustomizeDone={props.onCustomizeDone}
                        getTotal={props.getTotal}
                        t={t}
                        answers={answers}
                    />
                </Grid>
            </div>

            <div className="column1">
              <Grid container spacing={2}>
                {props.is_new_user && <> {/*Show the email validation question only for new users*/}
                  {/* Confirm Email Yes/No */}
                  {emailValidationCondition() && <Grid item xs={12} className="form-input-column label-large confirm-email-column">
                    <Question
                      type="boolean"
                      fullWidth
                      answer={props.emailConfirmed}
                      question={paramaterizedJSX(t('page.checkout.email_question.txt'), '{*}', `${answers['contact.email']}`, 'bolded-email')}
                      options={t('page.checkout.email_question_opts', {returnObjects: true})}
                      onChange={(e, v) => conditionalFormInputHandler(v, 'email')}
                    />
                  </Grid>}

                  {/* Loading indicator */}
                  <Grid item xs={12} style={props.emailValidated === 'loading' ? {display: 'block'} : {display: 'none'}}>
                    <B2ZLoadingEmailValidation/>
                  </Grid>

                  {/* Enter email address */}
                  {emailValidationCondition() && <Grid item xs={12} className="form-input-column"
                                                       style={props.emailConfirmed === false ? {display: 'block'} : {display: 'none'}}>
                    <FormControl fullWidth error={!!props.contactEmail === ''}
                                 className={'form_control'}>
                      <div className="label_blk">
                        <div className="label">{t('page.checkout.email_question.label')}</div>
                      </div>
                      <TextField
                        className='fieldText'
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={props.contactEmail}
                        onChange={e => props.handleContactEmailChange(changeHandler(emailQuestion.qid, e.target.value.replace(/\s+/g, '')))}
                      />
                      {!/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(props.contactEmail) && (
                        <p className="error_txt error_txt_md">Not correct email address</p>
                      )}
                    </FormControl>
                  </Grid>}

                  {/* Confirm email address */}
                  {emailValidationCondition() && <Grid item xs={12} className="form-input-column"
                                                       style={props.emailConfirmed === false ? {display: 'block'} : {display: 'none'}}>
                    <FormControl fullWidth error={(props.confirmEmail !== '' && props.confirmEmail !== props.contactEmail)} className={'form_control'}>
                      <div className="label_blk">
                        <div className="label">{t('page.checkout.confirm_email_question')}</div>
                      </div>

                      <TextField
                        style={textInputStyle}
                        className='fieldText'
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={props.confirmEmail}
                        onChange={e => props.handleConfirmEmailChange(changeHandler('confirm.email', e.target.value.replace(/\s+/g, '')))}
                      />
                      {props.confirmEmail !== '' && props.confirmEmail !== props.contactEmail && (
                        <p className="error_txt error_txt_md">{errors['mismatched.emails'] || errors['invalid.email']}</p>
                      )}
                    </FormControl>
                  </Grid>}

                  {/* Email address change confirmed */}
                  {props.emailValidated === true && <Grid item xs={12} className="form-input-column">
                    <div className="email_confirmed">
                      <GreenCheckmark className={'icon_email_confirmed'}/>
                      <p>Your email address has been successfully updated.</p></div>
                  </Grid>}
                </>}

                {/* Payment Preferences */}
                  <Grid item xs={12} className="form-input-column payment-preferences-column" style={{paddingTop: '0'}}>
                      {IS_NEW_THEME_ENABLED && <p className={'checkout_main_ttl'}>Billing Details</p>}
                      <p className={'checkout_ttl'}>{t('page.checkout.payment_preference_question')}</p>
                      <Question
                          type="customboolean"
                          fullWidth
                          options={t('page.checkout.payment_schedule_opts', {returnObjects: true})}
                          onChange={(e, v) => props.handleBillingFrequencyChange(changeHandler('billing.frequency', v))}
                          answer={props.billingFrequency}
                      />

                  {props.payment_methods_available.length > 0 && props.paymentMethod !== 5 && (
                      <button className={'a_btn a_btn_outlined back_to_existing_methods_btn'} type={'button'}
                              onClick={() => handlePaymentMethodChange(5)}
                      >{t('common.btn_back_exit_pay_method')}</button>
                  )}
                      {/*{*/}
                      {/*    props.payment_methods_loading ? (<Loading/>) : (*/}
                      {/*        <>*/}
                      {/*            {props.paymentMethod !== 5 && (*/}
                      {/*                <>*/}
                      {/*                    <Question*/}
                      {/*                        bankEnabled={bankEnabled}*/}
                      {/*                        type="billingboolean"*/}
                      {/*                        fullWidth*/}
                      {/*                        options={t('page.checkout.email_question_opts', {returnObjects: true})}*/}
                      {/*                        // answer={props.paymentMethod !== 1}*/}
                      {/*                        onChange={(e, v) => handlePaymentMethodChange(v === false ? 1 : 0)}*/}
                      {/*                    />*/}
                      {/*                </>*/}
                      {/*            )}*/}
                      {/*        </>*/}
                      {/*    )*/}
                      {/*}*/}

                </Grid>

                {/* Payment Fields */}

                <Grid item xs={12} className="form-input-column card-address-column">
                    {!props.payment_methods_loading && (renderPaymentMethodFields())}
                    {errors['paymentError'] && <p className={'error_txt error_txt_md'}>{errors['paymentError']}</p>}
                </Grid>

                {/* Billing Address */}
                <Grid item xs={12} className="form-input-column billing-address-column">
                    <BillingAddress
                        saved_address={saved_address}
                        default={props.use_default_address}
                        errors={errors}
                        switchAddress={() => props.switchAddress()}
                        states={USStates}
                        onChange={(key, value) => {
                            props.updateAddress(key, value)
                        }}
                        use_default_address={props.use_default_address}
                    />
                </Grid>

                {/* Cancel old policy */}
                <Grid item xs={12} className="form-input-column hfp">
                  <div className={'hfp_card'}>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel control={<Switch onChange={(e) =>
                          props.handlePolicyCancelationChange(changeHandler(hassleFreePolicyQuestion.qid, e.target.checked))} />}
                                          label={hassleFreePolicyQuestion.question_text}
                                          checked={props.policyCancelation}
                                          className={props.policyCancelation === false ? "switch-wrapper" : "switch-wrapper hfp-selected"}
                                          style={{paddingLeft: '8px'}}
                        />
                      </FormGroup>
                    </FormControl>
                    <p className="hasslefree_body">
                      {hassleFreePolicyQuestion.diya_helper}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
        </div>
    )
}

export default withTranslation()(PaymentForm)