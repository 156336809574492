import React from 'react';
import { isArray } from 'lodash/lang'
import {ArrowLeft} from "../../icons/ArrowLeft";

const ClaimCarrierItem = (props) => {
    return (
        <div className={'portal_card claim_carrier_item claim_wc'}>
            <div className={'claim_row'}>
                <p className="claim_carrier_ttl">{props.title}</p>
                <p className="claim_carrier_txt">{props.text}</p>
            </div>
            <div className={'claim_row claim_row_actions'}>
                {props.email && <div className="column column_contact">
                    <span>Email</span>
                    <a href={`mailto:${props.email}`}>{props.email}</a>
                </div>}
                {props.phone && !isArray(props.phone) ? <div className="column column_contact">
                    <span>Phone</span>
                    <a href={`tel:${props.phone.split("-").join('')}`}>{props.phone}</a>
                </div>: <div className="column column_contact">
                  <span>Phone</span>
                  <ul>{props.phone.map(item=><li><span>{item.txt}: </span> <a href={`tel:${item.number.split("-").join('')}`}>{item.number}</a></li>)}</ul></div>}
                {props.fax && <div className="column column_contact">
                    <span>Fax</span>
                    <a href={`tel:${props.fax}`}>{props.fax}</a>
                </div>}
                {props.link && <div className="column column_button">
                    <a className={'a_btn a_btn_action_1'}
                       target="_blank"
                       rel="noopener noreferrer"
                       href={props.link}><span>{props.carrier} Claims Page</span>
                        <ArrowLeft/></a>
                </div>}
            </div>
        </div>

    );
}

export default ClaimCarrierItem;