import React from "react"
import {connect} from "react-redux";
import axios from "axios";
import {intersection, some} from 'lodash';
import CardCYBER from '../../components/form-components/cardsQQ/CardCYBER'
import { getLeadUnderwriter } from '../../helpers/GetLeadUnderwriter'
import {
    changeAnswers,
    updateInsStoreKey,
    updateProgress,
    updateSections,
    updateWCUWQuestions
} from "../../store/insurance/actions";
import {withTranslation} from 'react-i18next';
import B2ZLoading from "../../components/B2ZLoading";
import { Grid} from "@material-ui/core";
import {ArrowBack} from '@material-ui/icons';
import LoadingButton from "../../components/LoadingButton";
import CardBOP from "../../components/form-components/cardsQQ/CardBOP";
import CardPL from "../../components/form-components/cardsQQ/CardPL";
import CardWC from "../../components/form-components/cardsQQ/CardWC";
import CardGL from '../../components/form-components/cardsQQ/CardGL';
import SaveYourProgressButton from "../../components/modals/SaveYourProgressButton";
import ErrorInsuranceCard from "../../components/form-components/ErrorInsuranceCard";
import NextSectionButton from "../../components/form-components/NextSectionButton";
import SectionHead from "../../components/insurance/SectionHead";
import {trimBusinessName} from "../../helpers/trimBusinessName";
import {ErrorAlert} from "../../components/insurance/ErrorAlert";
import {indexProducts, sortProducts} from "../../helpers/sort-products";
import DelayedQuoteMessage from "../../components/modals/DelayedQuoteMessage";


//scss
import '../../scss/v2/_qq_cards.scss'


export class SelectYourRate extends React.Component {

    state = {
        nextUrl: '/results',
        loading: true,
        dialog_open: false,
        dialog_open_cw: false,
        customize_quote: null,
        customize_index: null,
        next_loading: false,
        animation_done: false,
        headTitle: '',
        headTxt: '',
        next_section: '',
        downloadErrors: {},
        showDelayedQuoteMessage: false
    }

    constructor(props) {
        super(props);

        this.state.nextUrl = "/get-insurance" + window.getNextSubSectionPath(this.props.subsections, this.props.next_subsection)

        let oc_availability = {
            ...props.oc_availability
        }

        if (props.answers.hasOwnProperty('eb.coverage.is_selected')
            && props.answers['eb.coverage.is_selected'].toLowerCase() === 'yes') oc_availability.eb = true
        if (props.answers.hasOwnProperty('cyber.coverage.is_selected')
            && props.answers['cyber.coverage.is_selected'].toLowerCase() === 'yes'
            && !!props.answers['cyber.coverage.aggregate_limit']) oc_availability.cyber = true
        if (props.answers.hasOwnProperty('mpl.coverages.is_selected')
            && props.answers['mpl.coverages.is_selected'].toLowerCase() === 'yes'
            && !!props.answers['mpl.coverages.description_of_service'] && !!props.answers['mpl.coverages.type_of_service']) oc_availability.pl = true
        if (props.answers.hasOwnProperty('hnoa.coverage.is_selected')
            && props.answers['hnoa.coverage.is_selected'].toLowerCase() === 'yes'
        ) oc_availability.hnoa = true

        props.updateInsStoreKey('oc_availability', oc_availability)

        this.props.updateProgress({
            current_section: this.props.section.section_index,
            current_subsection: this.props.next_subsection - 1,
        })
    }

    componentDidMount() {
        const {is_session_restored, is_from_bulk} = this.props

        //clear previous selected quote
        this.props.updateInsStoreKey('quotes', [])
        this.props.changeAnswers({
            "selected_quotes": []
        })
        //get new quotes
        this.getQuickQuote()
        window.scrollTo({top: 0, behavior: 'smooth'});

        setTimeout(() => {
            if (!is_session_restored && !is_from_bulk) {
                this.setState({showDelayedQuoteMessage: true})
            }
        }, 20 * 1000)

        this.setState({
            next_section: this.props.subsections[this.props.next_subsection]?.name
        })
    }


    downloadErrCallback(event) {
        const {type, isError} = event
        const newState = {
            ...this.state.downloadErrors,
            [type]: isError
        }
        this.setState({downloadErrors: newState})
    }

    getHeadContent(quotes) {
        let error = some(quotes, {'error': true})

        if (this.props.answers['quote_status'] === 'Rejected Quote') {
            this.setState({
                headTitle: this.props.t('page.quick_quote.reject.title', {value: trimBusinessName(this.props.answers['business.name'])}),
                headTxt: this.props.t('page.quick_quote.reject.txt', {value: trimBusinessName(this.props.answers['business.name'])})
            })
        } else { //'Quick Quote'
            if (error && quotes.length > 1) {
                this.setState({
                    headTitle: this.props.t('page.quick_quote.oneError.title', {value: trimBusinessName(this.props.answers['business.name'])}),
                    headTxt: this.props.t('page.quick_quote.oneError.txt', {value: trimBusinessName(this.props.answers['business.name'])})
                })
            } else {
                this.setState({
                    headTitle: this.props.t('page.quick_quote.qq.title', {value: trimBusinessName(this.props.answers['business.name'])}),
                    headTxt: this.props.t('page.quick_quote.qq.txt')
                })
            }
        }
    }

    setSelected(item) {
        let quotes = [...this.props.answers['selected_quotes']]

        if (!some(quotes, {'quote_number': item['quote_number']})) {
            //remove all quotes of the same product type
            quotes = quotes.filter(itm => itm['quote_type'] !== item['quote_type'])
            quotes.push(item)
        } else quotes = quotes.filter(itm => itm['quote_number'] !== item['quote_number'])

        let selectedCoverages = quotes.map(q => {
            return q.line_of_business
        })

        this.props.changeAnswers({
            "selected_quotes": quotes,
            "underwriter": getLeadUnderwriter(quotes),
            "coverage.types": selectedCoverages
        })

        document.dispatchEvent(new CustomEvent('quotes-changed', {
            detail: quotes
        }))

        if (quotes.length > 0) this.updateSections(quotes)
    }

    setMultiSelected(items) {
        let quotes = []
        let products = []

        items.forEach(itm => {
            if (!itm?.error && !products.includes(itm.type)) {
                let quote = this.prepareQuoteObj(itm, itm.type)
                quotes.push(quote)
                products.push(itm.type)
            }
        })

        this.props.changeAnswers({
            "selected_quotes": quotes,
            "underwriter": getLeadUnderwriter(quotes),
        })

        document.dispatchEvent(new CustomEvent('quotes-changed', {
            detail: quotes
        }))

        if (quotes.length > 0) this.updateSections(quotes)
    }

    prepareQuoteObj(quote, type) {
        return (quote?.error)
            ? {
                error: true,
                error_code: quote.error_code,
                quote_type: quote.type,
            }
            : {
                ...quote.data,
                quote_type: type,
            }
    }


    getQuickQuote() {
        let answers = {...this.props.answers}
        let STEStr = answers['business.location.home'] === 'Yes' ? '' : 'STE '
        answers['policy.tria_accepted'] = "Yes"
        answers['cyber.coverage.is_selected'] = "Yes"
        answers['mpl.coverages.is_selected'] = "Yes"
        answers['business.street_address2'] = answers['business.street_address2']?.length > 0 ?
            `${STEStr}${answers['business.street_address2']}` : answers['business.street_address2']

        /*
         * Prefill business.full_time_employees if premises.full_time_employees was changed
         */
        if(answers.hasOwnProperty('business.full_time_employees')
          && answers['coverage.types'].length > 1) answers['business.full_time_employees'] = answers['premises.full_time_employees']

        this.props.updateInsStoreKey('is_answers_changed', false)

        axios.post("/api/quick_quote", {
            answers: answers
        }).then(
            response => {
                this.setState({showDelayedQuoteMessage: false})
                if (response.data && response.data.length > 0) {
                    let quotes = response.data.map(itm => {
                        return this.prepareQuoteObj(itm, itm.type)
                    })

                    if (quotes instanceof Array && quotes?.length > 1) {
                        quotes = indexProducts(quotes, 'line_of_business', 'idx')
                        quotes = quotes.sort(sortProducts('idx'))
                    }

                    this.props.updateInsStoreKey('quotes', quotes)
                    if (quotes instanceof Array) this.setMultiSelected(response.data)

                    if(!some(quotes, ['quote_type', "CYBER"])){
                        this.props.updateInsStoreKey("estimatedPremium", null)
                    }
                    let errors = quotes.filter(itm => !!itm?.error)

                    if (errors.length === quotes.length) {
                        this.props.onReject(quotes)
                        this.setState({
                            animation_done: true,
                            loading: false
                        })
                    } else {
                        if (this.state.animation_done)
                            this.setState({loading: false})
                        this.props.changeAnswers({
                            'quote_status': 'Quick Quote'
                        })
                    }
                    this.getHeadContent(quotes)

                } else this.onReject()
            }
        )
            .catch(e => {
                this.setState({showDelayedQuoteMessage: false})
                this.onReject()
                throw new Error(`Can't get quotes. ${e}`)
            })
    }

    animationEnds() {
        this.setState({
            loading: !(this.props.quotes.length > 0),
            animation_done: true
        })
    }

    animationContinues() {
        this.setState({
            loading: true,
            animation_done: false
        })
    }

    onReject() {
        this.props.onReject(null, this.props.answers['coverage.types'])

        this.setState({
            loading: false,
            animation_done: true,
        })
    }

    customize(index) {
        this.setState({
            customize_quote: this.props.quotes[index],
            customize_index: index,
            dialog_open: true
        })
    }

    customizeWC(index) {
        this.setState({
            customize_quote: this.props.quotes[index],
            customize_index: index,
            dialog_open_cw: true
        })
    }

    closeCustomize() {
        this.setState({
            dialog_open: false,
            dialog_open_cw: false,
        })
    }

    onQuoteSaved() {
        this.props.changeAnswers({
            'session_saved': true
        })
    }

    updateSections(quotes) {
        //Update next sections and questions accordingly to selected quotes
        let lob_list = quotes.map(q => q.quote_type)

        if (lob_list.length > 0) {
            //make next subsections skipped next to current subsection if not applicable
            let subsections = this.props.subsections.map((s, idx) => {
                if (idx > this.props.current_subsection) {
                    if (s.lobs.includes("COMMON")) return s

                    let intersect = intersection(s.lobs, lob_list)
                    s.skipped = intersect.length === 0
                }

                return s
            })

            this.props.updateInsStoreKey('subsections', subsections)
        }
    }

    proceedNext() {
        this.setState({next_loading: true}, () => {
            if (this.props.is_session_restored) this.props.updateInsStoreKey('is_session_restored', false)
            this.props.history.push(this.state.nextUrl)
        })
    }

    getButtonText() {
        let number = this.props.answers['selected_quotes'].length

        switch (number) {
            case 0:
                return this.props.t('common.btn_get_policy')
            case 1:
                return this.props.t('common.btn_get_policy')
            default:
                return this.props.t('common.btn_get_policies')
        }
    }

    getDiyaText() {
        let number = this.props.quotes.filter(itm => !itm?.error).length

        if (this.props.is_session_restored && number === 1) return this.props.t('diya.select_rate.session_one')
        if (this.props.is_session_restored && number > 1) return this.props.t('diya.select_rate.session_default')

        switch (number) {
            case 0:
                return this.props.t('diya.select_rate.empty')
            case 1:
                return this.props.t('diya.select_rate.one')
            default:
                return this.props.t('diya.select_rate.default')
        }
    }

    goBack() {
        let index = this.props.current_subsection - 1
        if (this.props.is_session_restored) this.props.updateInsStoreKey('is_session_restored', false)
        if (this.props.subsections.length > 0 && index >= 0 && this.props.subsections[index]) {
            let path = "/get-insurance" + window.getPrevSubSectionPath(this.props.subsections, index)
            this.props.history.push(path)
        } else this.props.history.goBack()
    }

    render() {

        return (!this.state.loading) ? (
            <div className={'select_your_rate_section'}>
              <div className={'section_container'}>


              <SectionHead title={this.state.headTitle}
                             txt={this.state.headTxt}/>
              <Grid container spacing={2}>
                        {Object.keys(this.state.downloadErrors).map((key, idx) => {
                            if (this.state.downloadErrors[key] === true) {
                                return (
                                    <Grid item xs={12} key={idx} sx={{mb: 0, mt: 0}}>
                                        <ErrorAlert isOpen={this.state.downloadErrors[key]}
                                                    errMessage={`${key} quote download failed.`}
                                                    errCallback={event => this.downloadErrCallback(event)}
                                                    quoteType={key}
                                        />
                                    </Grid>
                                )
                            } else return null
                        })}

                        {this.props.quotes.map((item, index) => {
                                if (item?.error) return (
                                    <Grid item xs={12} key={index}>
                                        <ErrorInsuranceCard quote_type={item.quote_type}/>
                                    </Grid>
                                )
                                else return (
                                    <Grid item xs={12} key={index}>
                                        {(item.quote_type === 'WC') ? (
                                            <CardWC quote={item}
                                                             customizable={true}
                                                             selected={some(this.props.answers["selected_quotes"],
                                                                 {'quote_number': item['quote_number']})}
                                                             onClick={() => this.setSelected(item)}
                                                             openDialog={() => this.customizeWC(index)}
                                                             downloadErrCallback={(event) => this.downloadErrCallback(event)}
                                            />
                                        ) : (item.quote_type === 'PL') ? (
                                            <CardPL quote={item}
                                                             customizable={true}
                                                             selected={some(this.props.answers["selected_quotes"],
                                                                 {'quote_number': item['quote_number']})}
                                                             onClick={() => this.setSelected(item)}
                                                             openDialog={() => this.customize(index)}
                                                             downloadErrCallback={(event) => this.downloadErrCallback(event)}
                                            />
                                        ) : (item.quote_type === 'BOP') ? (
                                            <CardBOP quote={item}
                                                           customizable={true}
                                                           selected={some(this.props.answers["selected_quotes"],
                                                               {'quote_number': item['quote_number']})}
                                                           onClick={() => this.setSelected(item)}
                                                           openDialog={() => this.customize(index)}
                                                           downloadErrCallback={(event) => this.downloadErrCallback(event)}
                                            />
                                        ): (item.quote_type === 'GL') ? (
                                            <CardGL quote={item}
                                                           customizable={true}
                                                           selected={some(this.props.answers["selected_quotes"],
                                                               {'quote_number': item['quote_number']})}
                                                           onClick={() => this.setSelected(item)}
                                                           openDialog={() => this.customize(index)}
                                                           downloadErrCallback={(event) => this.downloadErrCallback(event)}
                                            />
                                        ) :(item.quote_type === 'CYBER') ? (
                                            <CardCYBER quote={item}
                                                           customizable={true}
                                                           selected={some(this.props.answers["selected_quotes"],
                                                               {'quote_number': item['quote_number']})}
                                                           onClick={() => this.setSelected(item)}
                                                           openDialog={() => this.customize(index)}
                                                           downloadErrCallback={(event) => this.downloadErrCallback(event)}
                                            />
                                        ) : null}
                                    </Grid>
                                )
                            }
                        )}
                    </Grid>

              <Grid container className={'section_navigation section_navigation_select_your_rate is_back_btn '}>
                {this.state.next_loading ? (
                  <LoadingButton/>
                ) : (<>
                    <button className="section_back_btn"
                            type={'button'}
                            onClick={() => {
                              this.goBack()
                            }}>
                      <span className={'a_btn a_btn_transparent'}>
                        <ArrowBack/>
                      </span>
                      <span className="a_mob_back">Back</span>
                    </button>

                    {!this.props.answers?.['session_saved'] &&
                      (<SaveYourProgressButton
                        quotes={this.props.quotes}
                        answers={this.props.answers}
                        onSaved={() => {
                          this.onQuoteSaved()
                        }}
                      />)}
                    <NextSectionButton
                      validateFormMethod={() => this.proceedNext()}
                      nextDisabled={!this.props.answers["selected_quotes"].length > 0}
                      className="section_next_btn"/>
                    <NextSectionButton
                      validateFormMethod={() => this.proceedNext()}
                      nextDisabled={!this.props.answers["selected_quotes"].length > 0}
                      className="section_next_btn__mobile"/>
                  </>
                )}
              </Grid>
            </div>
            </div>
        ) : (
            <>
                <DelayedQuoteMessage
                    onClose={() => this.setState({showDelayedQuoteMessage: !this.state.showDelayedQuoteMessage})}
                    open={this.state.showDelayedQuoteMessage}/>
                <B2ZLoading loading_string={this.props.t('common.animation_strings', {returnObjects: true})}
                            restoring_session={this.props.is_session_restored}
                    // onStringPrinted={() => {
                    //     this.animationEnds()
                    // }}
                            onCycleEnd={() => {
                                this.animationEnds()
                            }}
                    // onBeforeDelete={() => {
                    //     if (this.state.loading) this.animationContinues()
                    // }}
                />
            </>

        )
    }
}

const mapStateToProps = state => {
    return {
        quotes: state.insurance.quotes,
        answers: state.insurance.answers,
        session_answers: state.insurance.session_answers,
        subsections: state.insurance.subsections,
        current_subsection: state.insurance.current_subsection,
        is_session_restored: state.insurance.is_session_restored,
        is_from_bulk: state.insurance.is_from_bulk,
        oc_availability: state.insurance.oc_availability,
    }
}

const mapDispatchToProps = {
    changeAnswers,
    updateProgress,
    updateInsStoreKey,
    updateWCUWQuestions,
    updateSections
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectYourRate))
