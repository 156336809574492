import DateTimeHelper from "../helpers/dateTimeHelper";
import moment from "moment";

const usePolicyStartDateValidator = (props, minDate, maxDate) => {

    const desired_effective_date = moment(props.answers['policy.desired_effective_date'])
    let date = desired_effective_date

    if (props.is_from_bulk && desired_effective_date) {
        const validateDate = new DateTimeHelper(desired_effective_date)
        const bulkDate = validateDate.localTime()

        if (moment(bulkDate) >= minDate.startOf('day') && moment(bulkDate) <= maxDate.startOf('day')) {
            date = moment(bulkDate)
        } else {
            date = minDate
        }
    }

    return date
}

export default usePolicyStartDateValidator