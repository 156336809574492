import React from 'react'
import axios from 'axios'
import { Close } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import Question from '../../components/form-components/Question'
import { DialogActions, DialogContent, Grid } from '@material-ui/core'

import '../../scss/v2/modals/_sign_in_forgot_pass.scss'

class ForgotPassword extends React.Component {

  state = {
    error: null,
    sent: false,
    email: ''
  }

  setEmail (value) {
    this.setState({ email: value })
  }

  toSignIn () {
    if (window.location.pathname.indexOf('sign-in') !== -1)
      this.props.onClose()
    else this.props.history.push('/sign-in')
  }

  validate () {
    if (!/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(this.state.email)) {
      this.setState({ error: this.props.t('auth.error.invalid_email') })
    } else {
      this.setState({ error: null }, () => {
        //request pass change
        axios.post('/api/reset-user-pass', {
          username: this.state.email
        })
          .then(response => {
            if (response.data.message) {
              //success notification
              this.setState({ sent: true })
            }
          })
          .catch(e => {
            //error notification
            this.setState({
              sent: true
            })
          })
      })
    }
  }

  render () {
    const { t } = this.props

    return (
      <>
        <DialogActions className={'modal_head'}>
          <button className={'modal_close_btn'}
                  type={'button'}
                  onClick={this.props.onClose}><Close/> <span>{t('common.btn_close')}</span></button>
        </DialogActions>
        <DialogContent className={'modal_body'}>
          <Grid container className="forgot_password_modal_cnt">
            {!this.state.sent ? (
                <>
                  <Grid item xs={12}>
                    <p className={'ttl'}>{t('auth.forgot_pass_title')}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="">{t('auth.forgot_pass_txt')}</p>

                  </Grid>
                  <Grid item xs={12}>
                    <Question fullWidth
                              type="email"
                              question={t('form.label.email')}
                              answer={this.state.email}
                              error={!!this.state.error}
                              helperText={this.state.error}
                              onChange={(id, value) => {
                                this.setEmail(value)
                              }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <button type={'button'} className={'a_btn a_btn_action_1'} onClick={() => {
                      this.validate()
                    }}>{t('auth.btn.pass_reset')}</button>
                  </Grid>
                </>) :
              (<><Grid item xs={12}>
                <p className={'ttl'}>{t('auth.forgot_pass_reset_link')}</p>
              </Grid>
                <Grid item xs={12}>
                  <p className={'txt'}>{t('auth.forgot_pass_txt_2', { value: this.state.email })}</p>
                </Grid></>)}
          </Grid>
        </DialogContent>
      </>
    )
  }
}

export default withTranslation()(ForgotPassword)
