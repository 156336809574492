import React from 'react'

export const CT = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.5938 28.1875C33.6969 28.1875 32.8 28.5719 32.1594 29.0844L25.4969 25.1125C25.4969 24.8563 25.625 24.6 25.625 24.3438C25.625 24.0875 25.625 23.8312 25.4969 23.575L32.1594 19.6031C32.8 20.1156 33.6969 20.5 34.5938 20.5C36.7719 20.5 38.4375 18.8344 38.4375 16.6562C38.4375 14.4781 36.7719 12.8125 34.5938 12.8125C32.4156 12.8125 30.75 14.4781 30.75 16.6562C30.75 16.9125 30.75 17.1688 30.8781 17.425L24.2156 21.3969C23.575 20.8844 22.6781 20.5 21.7812 20.5C19.6031 20.5 17.9375 22.1656 17.9375 24.3438C17.9375 26.5219 19.6031 28.1875 21.7812 28.1875C22.6781 28.1875 23.575 27.8031 24.2156 27.2906L30.8781 31.2625C30.8781 31.5187 30.75 31.775 30.75 32.0312C30.75 34.2094 32.4156 35.875 34.5938 35.875C36.7719 35.875 38.4375 34.2094 38.4375 32.0312C38.4375 29.8531 36.7719 28.1875 34.5938 28.1875ZM34.5938 15.375C35.3625 15.375 35.875 15.8875 35.875 16.6562C35.875 17.425 35.3625 17.9375 34.5938 17.9375C33.825 17.9375 33.3125 17.425 33.3125 16.6562C33.3125 15.8875 33.825 15.375 34.5938 15.375ZM21.7812 25.625C21.0125 25.625 20.5 25.1125 20.5 24.3438C20.5 23.575 21.0125 23.0625 21.7812 23.0625C22.55 23.0625 23.0625 23.575 23.0625 24.3438C23.0625 25.1125 22.55 25.625 21.7812 25.625ZM34.5938 33.3125C33.825 33.3125 33.3125 32.8 33.3125 32.0312C33.3125 31.2625 33.825 30.75 34.5938 30.75C35.3625 30.75 35.875 31.2625 35.875 32.0312C35.875 32.8 35.3625 33.3125 34.5938 33.3125Z"
      fill="#ffffff"/>
    <path
      d="M24.3438 32.0312H10.8906C7.04688 32.0312 3.84375 28.8281 3.84375 24.9844C3.84375 21.525 6.27813 18.7063 9.60938 18.0656L11.275 17.8094L11.6594 16.1438C12.6844 11.1469 16.9125 7.6875 21.7812 7.6875C22.4219 7.6875 23.0625 7.6875 23.7031 7.81562C25.7531 8.2 27.5469 9.225 29.0844 10.6344L30.8781 8.84063C29.0844 7.04688 26.7781 5.76562 24.2156 5.38125C23.4469 5.25312 22.55 5.125 21.7812 5.125C15.7594 5.125 10.3781 9.35313 9.225 15.5031C4.6125 16.4 1.28125 20.3719 1.28125 24.9844C1.28125 30.2375 5.50938 34.5938 10.8906 34.5938H24.3438V32.0312Z"
      fill="#ffffff"/>
  </svg>

)
