import {combineReducers} from 'redux'
import {insuranceReducer} from './insurance/reducers'
import {appReducer} from './app/reducers'
import {portalReducer} from './portal/reducers'
// import {claimReducer} from "./portal/claim/reducers";
import {policiesReducer} from "./portal/policies/reducers";

export const rootReducer = combineReducers({
    app: appReducer,
    insurance: insuranceReducer,
    portal: portalReducer,
    // claims: claimReducer,
    policies: policiesReducer
})