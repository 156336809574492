import React from 'react'
import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { connect } from 'react-redux'
import { Close } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import { ModalTransition } from '../../common/ModalTransition'
import { getLimitValue } from '../../helpers/display'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { getInsuranceCarrier } from '../../helpers/GetInsuranceCarrier'

//scss
import '../../scss/v2/modals/_fq_policy_details.scss'

const WCDetails = (props) => {
    const { t, answers, quotes } = props
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const carrier = getInsuranceCarrier(answers, 'WC', quotes)
    const assetsUrl = 'https://b2z-public-images.s3.amazonaws.com/acrisure'

    return (
        <Dialog open={props.open}
            fullWidth
            fullScreen={fullScreen}
            maxWidth={'sm'}
            container={() => document.getElementById('themeAppComponent')}
            className={'modal__wrapper fq_details_modal__wrapper whats_covered_modal__wrapper'}
            scroll={'paper'}
            onClose={() => props.onClose()}
            TransitionComponent={ModalTransition}>

            <DialogActions className={'modal_head'}>
                <button className={'modal_close_btn show_scroll_paper'}
                    type={'button'}
                    onClick={() => props.onClose()}><Close/>
                    <span>{t('common.btn_cancel')}</span></button>
            </DialogActions>
            <DialogContent className={'modal_body'}>
                <div className={'fq_details_card'}>
                    <div className={'detail_name'}>{t('common.wc_policy')}</div>
                    <div className={'mobile_price'}>{props.price}</div>
                    <div className={'insurer_rate'}>
                        <div className="row">
                            <div className={'fq_insurer'}>{carrier?.name}</div>
                            <div className={'divider'}/>
                            <div className={'fq_rate'}>{t('common.rated')}</div>
                        </div>
                        {carrier?.logo &&
                          <div className={'fq_carrier_logo'}><img src={`${assetsUrl}/carrier_logos/${carrier.logo}.png`} alt=""/></div>}

                    </div>
                    <div className={'limits_price_dates'}>
                        <div className="limits">
                            <div><p>{t('common.employees')}</p>
                                {props.wc_locations[0].full_time_employees}
                            </div>
                            {Object.keys(props.quote.limits).map(itm => {
                                if (itm !== 'employee_limit') {
                                    return (
                                        <div><p>{props.quote.limits[itm].display_name}</p>
                                            {getLimitValue(props.quote.limits[itm])}
                                        </div>
                                    )
                                } else return null
                            })}
                        </div>
                        <div className="price-dates">
                            <div className="price">{props.price}</div>
                            <div className="dates">
                                <div><p>{t('common.eff_date')}</p>
                                    {moment(props.quote.effective_date).format('MM/DD/YYYY')}
                                </div>
                                <div><p>{t('common.exp_date')}</p>
                                    {moment(props.quote.expiration_date).format('MM/DD/YYYY')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-dates">
                        <div className="dates">
                            <div><p>{t('common.eff_date')}</p>
                                {moment(props.quote.effective_date).format('MM/DD/YYYY')}
                            </div>
                            <div><p>{t('common.exp_date')}</p>
                                {moment(props.quote.expiration_date).format('MM/DD/YYYY')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="whats_covered_modal_cnt">
                    <p className="covered_sub_ttl">{t('modal.not_cover_wc.question')}</p>

                    <div className="covered_card">
                        <p
                            className="card_txt">{t('modal.not_cover_wc.txt')}</p>
                        <ul className="card_list">
                            {t('modal.not_cover_wc.list',
                                { returnObjects: true }).map(({ icon, txt, tip }, idx) => (
                                <li className="card_list_item" key={idx}>
                                    <i className="list_item_icon">
                                        <img alt={txt} src={`${assetsUrl}/icons/${icon}`}/></i>
                                    <span className="list_item_tip">
                                        <span className="txt">{txt}</span>
                                        <span className="tip">{tip}</span>
                                    </span>
                                </li>))}
                        </ul>
                    </div>
                </div>

            </DialogContent>
        </Dialog>
    )
}
const mapStateToProps = state => {
    return {
        quotes: state.insurance.quotes,
        answers: state.insurance.answers,
        wc_locations: state.insurance.wc_locations
    }
}
export default connect(mapStateToProps)(withTranslation()(WCDetails))
