import React from 'react'
import clsx from 'clsx'
import axios from 'axios'
import base64 from 'base-64'
import { getCertificatePayload } from '../../../helpers/GetCertificatePayload'
import { getAdditionalInsuredList } from '../../../helpers/GetAdditionalInsuredList'
import policyType from '../../../helpers/policyType'
import { Chevron } from '../../icons/Chevron'
import { withTranslation } from 'react-i18next'
import { QuestionTooltip } from '../../form-components/QuestionTooltip'
import LoadingButton from '../../LoadingButton'
import { Collapse } from '@material-ui/core'
import moment from 'moment'
import CommonPolicy from './CommonPolicy'
import GLPPolicyLimits from './GLPolicyLimits'
import GLPPolicyDetails from './GLPolicyDetails'

class GLPPolicy extends CommonPolicy {

  getAccord25Certificate () {
    this.setState({ loadingCertificate: true }, () => {
      const additionalInsuredList = getAdditionalInsuredList(this.props)
      const certificatePayload = getCertificatePayload(this.props)

      let promises = []
      let res

      res = axios.post('/api/get-certificate', {
        doc_type: certificatePayload.docType,
        strict: true,
        payload: certificatePayload.payload
      }).then((data) => {
        const decodedData = base64.decode(data.data)
        const fileName = `COI-${certificatePayload?.payload?.policy_number}`
        this.sendFile(decodedData, fileName)
      }).catch(e => {
        throw new Error(`Can't get user's policy GL COI. ${e}`)
      })
      promises.push(res)

      if (additionalInsuredList && additionalInsuredList.length > 0) {
        for (let i = 0; i < additionalInsuredList?.length; i++) {
          res = axios.post('/api/get-certificate', {
            doc_type: certificatePayload.docType,
            strict: true,
            payload: {
              ...certificatePayload.payload,
              'addit_insured_or_holder_data': {
                name: `${additionalInsuredList[i].first_name} ${additionalInsuredList[i].last_name}`,
                addressLine1: `${additionalInsuredList[i].street_address}`,
                addressLine2: `${additionalInsuredList[i].city}, ${additionalInsuredList[i].state} ${additionalInsuredList[i].zip_code}`
              }
            }
          }).then((data) => {
            const decodedData = base64.decode(data.data)
            const fileName = `COI-${certificatePayload?.payload?.policy_number}_${additionalInsuredList[i].first_name}_${additionalInsuredList[i].last_name}`
            this.sendFile(decodedData, fileName)
          }).catch(e => {
            throw new Error(`Can't get user's policy GL (additional insured) COI. ${e}`)
          })
          promises.push(res)
        }
      }

      if (promises.length > 0) this.downloadCertificates(promises)
    })
  }

  downloadCertificates (promises) {
    Promise.all(promises)
      .then(() => {
        this.setState({ processing: false, loadingCertificate: false })
      })
      .catch((e)=>{
        throw new Error(`Can't download user's GL policy COI. ${e}`)
      })
  }

  render () {
    const { t } = this.props
    let details = clsx({
      'a_btn a_btn_action_3': true,
      'open': this.state.showDetails
    })
    return (
      <>
        <div ref={this.policyRef}
             className={`portal_policy_full_item`}>
          <div className={`portal_card ${this.checkFailPolicy() && 'failed_payment_policy'}`}>
            <div className={'row short_info'}>
              <div className="column_left">
                <span className={'item_business_name'}>{this.props.quote['business_name']}</span>
                <span className={'policy_type'}>{policyType(this.props.type).longName}</span>
                <span
                  className={'policy_number'}>{this.props.t('common.policy_number')} - {this.props.quote.policy_number}</span>
                <span
                  className={'policy_address'}>{this.getAddress()}</span>
              </div>
              <div className="column_right">
                <div className={`item_policy_status status__${this.props.policy_status.toLowerCase()}`}>{this.getPolicyStatus(this.props.policy_status)}</div>
                {this.props.policy_status === 'ACTIVE' && <>
                    <span className={'policy_price'}>{this.getBillingValue()}</span>
                    <span className={'policy_billed'}>{this.getBillingPeriod()}</span>
                  </>}
              </div>
            </div>
            <div className="limits_links">

              <div className={'row short_info_2'}>
                <div className="column">
                  <span className={'column_name'}>{t('common.eff_date')}</span>
                  <span
                    className={'column_value'}>{moment(this.props.effective_date).format('MM/DD/YYYY')}</span>
                </div>
                <div className="column">
                  <span className={'column_name'}>{t('common.exp_date')}</span>
                  <span
                    className={'column_value'}>{moment(this.props.expiration_date).format('MM/DD/YYYY')}</span>
                </div>
                <div className="column">
                  <div className="column_name"><span className={'value'}>{t('common.insurer')}</span>
                    &nbsp;<QuestionTooltip text={this.state.providerPortal}/></div>
                  <span className={'column_logo'}><img src={this.state.logoUrl} alt=""/></span>
                </div>
              </div>

              <GLPPolicyLimits {...this.props} optional_coverages={this.state.optional_coverages}/>

             {/* {this.props.policy_status === 'ACTIVE' &&
              <div className="row row_actions">
                {this.props.docs ? (
                  <p className={'action_item'} onClick={() => {
                    this.getDocuments()
                  }}>
                    {this.state.loadingDoc ? (
                      <span className={'policy_loading_blk'}>
                        <LoadingButton size={15}/>
                        <span>{t('common.downloading')}</span>
                      </span>
                    ) : (<span className={'a_btn a_btn_action_3'}>
                          {t('portal.policy.doc')}</span>)}
                  </p>
                ) : (
                  this.isDocsGenerated() ? <p className={'action_item docs_generated'}>
                    <span>{t('portal.policy.doc_gen')}</span>
                    <QuestionTooltip
                      text={t('portal.policy.doc_gen_tooltip')}/></p> : null
                )}
                {(this.props.quote.policy_number || this.props.quote.policyNumber) && (
                  <p className={'action_item'} onClick={() => {
                    this.getAccord25Certificate()
                  }}>{this.state.loadingCertificate ? (
                    <span className={'policy_loading_blk'}>
                      <LoadingButton size={15}/>
                      <span>{t('common.downloading')}</span>
                    </span>
                  ) : (
                    <span className={'a_btn a_btn_action_3'}>{t('portal.policy.certificate')}</span>
                  )}</p>
                )}
              </div>}*/}

            </div>
            <Collapse in={this.state.showDetails}>
              <GLPPolicyDetails {...this.props} optional_coverages={this.state.optional_coverages}/>
            </Collapse>
            <div className="row row_bottom_actions">
              <button type={'button'}
                      className={details}
                      onClick={() => {
                        this.toggleDetails()
                      }}>{this.state.showDetails ? t('common.hide_policy_details') : t('common.show_policy_details')}
                <i><Chevron/></i></button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(GLPPolicy)