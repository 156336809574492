import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Question from '../form-components/Question'
import { updateWCLocations } from '../../store/insurance/actions'
import { Grid } from '@material-ui/core'
import WCPayrollNEW from './WCPayroll'
import sources_update from '../../common/decorator'

class WCLocation extends Component {

  state = {
    questions: []
  }

  componentDidMount () {
    this.selectPayrollQuestions()
  }

  selectPayrollQuestions () {
    let list = [
      'premises.*.rate_classes.*.class_code_description',
      'premises.*.rate_classes.*.payroll_amount'
    ]

    let questions = this.props.subsections_questions.filter((itm, idx) => {
      return list.indexOf(itm.qid) !== -1
    })

    this.setState({ questions: questions })
  }

  getLocationAddress () {
    let address = '',
      complete = true
    //Street address
    if (this.props.wc_locations[this.props.index].street_address)
      address += this.props.wc_locations[this.props.index].street_address
    else complete = false
    //Suite/floor/office
    if (this.props.wc_locations[this.props.index].street_address2)
      address += ', ' + this.props.wc_locations[this.props.index].street_address2
    //City
    if (this.props.wc_locations[this.props.index].city)
      address += '<br/>' + this.props.wc_locations[this.props.index].city
    else complete = false
    //State
    if (this.props.wc_locations[this.props.index].state)
      address += ', ' + this.props.wc_locations[this.props.index].state
    else complete = false
    //Zip code
    if (this.props.wc_locations[this.props.index].zip_code)
      address += ', ' + this.props.wc_locations[this.props.index].zip_code
    else complete = false

    return (complete) ? <p className={'wc_address'} dangerouslySetInnerHTML={{ __html: address }}/> : (
      <p className={'error_txt error_tx_md'}>{this.props.t('page.bl.address_incomplete')}</p>)
  }

  getAnswerKey (key) {
    return this.props.index + '.' + key
  }

  onChange (key, value) {
    let locations = [...this.props.wc_locations]

    locations[this.props.index] = {
      ...locations[this.props.index],
      [key]: value
    }

    this.props.updateWCLocations(locations)
    sources_update(this.props.answers, key)
  }

  goToSection () {
    this.props.history.replace(`/get-insurance`)
  }

  render () {
    const { t } = this.props

    return (
      <>
        <Grid item xs={12}>
          <p className={'wc_section_ttl'}>{t('page.bl.name')}</p>
        </Grid>

        <Grid item xs={12}>
          {this.getLocationAddress()}
        </Grid>
        <Grid item xs={12}>
                                    <span onClick={() => {
                                      this.goToSection()
                                    }} className={'underline_link not_primary_address'}>Not your primary address?</span>
        </Grid>

        <Grid item xs={12}>
          <Question fullWidth
                    type="number"
                    question={t('form.label.employees_qty_at_location')}
                    answer={this.props.wc_locations[this.props.index].full_time_employees}
                    field_length={5}
                    variant="outlined"
                    error={!!this.props.errors?.[this.getAnswerKey('full_time_employees')]}
                    helperText={this.props.errors?.[this.getAnswerKey('full_time_employees')]}
                    onChange={(id, value) => {
                      this.onChange('full_time_employees', value)
                    }}
          />
        </Grid>


        <WCPayrollNEW {...this.props.wc_locations[this.props.index]}
                      index={this.props.index}
                      questions={this.state.questions}
                      clearErrors={this.props.clearErrors}
                      errors={this.props.errors}/>

      </>
    )
  }
}

const mapStateToProps = state => ({
  wc_locations: state.insurance.wc_locations,
  subsections_questions: state.insurance.subsections_questions
})

const mapDispatchToProps = {
  updateWCLocations
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WCLocation))
