import React from "react";

export const WC = () => (
    <svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#FFFFFF">
        <path
            d="M28.3333 35V31.6667C28.3333 29.8986 27.6309 28.2029 26.3807 26.9526C25.1304 25.7024 23.4347 25 21.6666 25H8.33329C6.56518 25 4.86949 25.7024 3.61925 26.9526C2.36901 28.2029 1.66663 29.8986 1.66663 31.6667V35"
            stroke="inherit" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M15 18.3333C18.6819 18.3333 21.6667 15.3486 21.6667 11.6667C21.6667 7.98477 18.6819 5 15 5C11.3181 5 8.33337 7.98477 8.33337 11.6667C8.33337 15.3486 11.3181 18.3333 15 18.3333Z"
            stroke="inherit" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M38.3334 35V31.6667C38.3323 30.1896 37.8406 28.7546 36.9356 27.5872C36.0307 26.4198 34.7636 25.586 33.3334 25.2167"
            stroke="inherit" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M26.6666 5.21667C28.1007 5.58384 29.3717 6.41784 30.2794 7.58719C31.187 8.75654 31.6797 10.1947 31.6797 11.675C31.6797 13.1553 31.187 14.5935 30.2794 15.7628C29.3717 16.9322 28.1007 17.7662 26.6666 18.1333"
            stroke="inherit" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)
