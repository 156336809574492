import React from "react";
import LoadingButton from '../LoadingButton'
import Question from "./Question";
import {connect} from "react-redux";
import {PlaidLink} from 'react-plaid-link';
import {withTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import DoRequest from "../../hooks/do-request";
//scss
import "../../scss/v2/_plaid.scss"

class PaymentWithBank extends React.Component {

    state = {
        loading: true,
        plaidLink: null,
        plaidError: false,
        account_valid_types: [
            'checking',
            'savings'
        ],
        accounts: [],
        account_id: ''
    }

    async componentDidMount() {
        await this.getLinkToken()
    }

    async getLinkToken() {
        await DoRequest({
            url: '/api/plaid-link',
            method: 'get',
            onSuccess: (response) => {
                this.setState({
                    plaidLink: response?.data
                }, () => this.setState({
                    loading: false,
                    plaidError: false
                }))
            },
            onError: (e) => {
                this.onPlaidFailure()
                throw new Error(`Can't get link token. ${e}`)
            }
        })
    }

    async onPlaidSuccess(public_token, metadata) {
        this.onBankTokenChange(public_token)

        if (metadata.account_id) this.props.onBankAccountChange(metadata.account_id)
        else this.getAccountOptions(metadata.accounts)
    }

    onPlaidFailure() {
        this.setState({
            plaidError: true,
            loading: false
        })
        this.props.pushError(true)
    }

    getAccountOptions(list) {
        let options = list.filter(itm => {
            return (this.state.account_valid_types.indexOf(itm.subtype) !== -1)
        })

        let account_id = null
        options.forEach((itm, key) => {
            if (itm.subtype === 'checking') {
                account_id = key
                return false
            }
        })

        if (account_id !== null) {
            let account = options[account_id]
            this.props.onBankAccountChange(account)
        }

        this.setState({
            accounts: options,
            account_id: (account_id !== null) ? account_id : null
        })
    }

    prepareOptions() {
        return this.state.accounts.map((itm, idx) => {
            return {
                value: idx,
                text: this.props.t('common.account_mask', {
                    type: itm.name,
                    mask: itm.mask
                })
            }
        })
    }

    onBankTokenChange(token) {
        this.props.onBankTokenChange(token)
    }

    onBankAccountChange(id, value) {
        let account = this.state.accounts[value]
        this.setState({account_id: value})
        this.props.onBankAccountChange(account)
    }

    render() {
        const {t} = this.props

        return this.state.loading ? (
            <Grid container spacing={2} justifyContent={'center'}>
                <Grid item>
                        <LoadingButton/>
                </Grid>
            </Grid>
        ) : this.state.plaidError ? null : (
            <Grid container spacing={2} style={{marginTop: "20px"}}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <PlaidLink
                        className="a_btn a_btn_outlined a_btn_plaid"
                        token={this.state.plaidLink.linkToken}
                        onSuccess={(public_token, metadata) => {
                            this.onPlaidSuccess(public_token, metadata)
                        }}
                    >{t('common.connect_bank_acc')}</PlaidLink>
                    {this.props.errors?.['bankToken'] && (
                      <p className={'error_txt error_txt_md'}>{this.props.errors['bankToken']}</p>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {this.state.accounts.length > 0 ? (
                        <Question fullWidth
                                  type="select"
                                  question={t('common.bank_select_account')}
                                  answer={this.state.account_id}
                                  variant="outlined"
                                  options={this.prepareOptions()}
                                  error={!!this.props.errors?.['bank_account']}
                                  helperText={this.props.errors?.['bank_account']}
                                  onChange={(id, value) => {
                                      this.onBankAccountChange(id, value)
                                  }}
                        />
                    ) : null}
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        answers: state.insurance.answers,
    }
}

export default connect(mapStateToProps)(withTranslation()(PaymentWithBank))
