import React from "react";
import axios from "axios"
import { datadogRum } from '@datadog/browser-rum'
import moment from "moment";
import {includes} from "lodash";
import {connect} from "react-redux";
import NotAllowProceedingNotify from '../../common/modal/NotAllowProceedingNotify'
import { StatsigComponent } from '../../common/StatsigComponent'
import {
  changeAnswers,
  updateAOIDesignatedPerson as updateaoidesignatedperson,
  updateAOILossPayableClauses as updateaoilosspayableclauses,
  updateAOIManagersOrLessorPremises as updateaoimanagersorlessorspremises,
  updateAOIMartgageeOrReceiver as updateaoimortgageeorreceiver,
  updateAOIMartgageHolderInformation as updateaoimortgageholderinformation,
  updateAOIWTTRAgainstOthers as updateaoiwtrragainstothers,
  updateBusinessOwners as updatebusinessownerslist,
  updateAdditionalInsureds as updatebusinessadditionalinsuredlist,
  updateInsStoreKey,
  updateProgress, updateAdditionalInsuredQuestions, setNexSectionButtonText
} from '../../store/insurance/actions'
import LoadingButton from "../LoadingButton";
import {withTranslation} from 'react-i18next';
import InvalidEmail from "../modals/InvalidEmail";
import Question from "../form-components/Question";
import HNOArejection from "../modals/HNOArejection";
import {ArrowBack} from "@material-ui/icons";
import {Grid} from "@material-ui/core";
import InvalidFEIN from "../modals/InvalidFEIN";
import NextSectionButton from "../form-components/NextSectionButton";
import SectionHead from "./SectionHead";
import {trimBusinessName} from "../../helpers/trimBusinessName";
import industryYearsCalc from "../../helpers/industry-years-calc";
import DoRequest from "../../hooks/do-request";
import DateTimeHelper from "../../helpers/dateTimeHelper";
import {formatCurrency} from "../../helpers/currency";
import UnderwriterStatements from "../../pages/insurance/UnderwriterStatements";

class Section extends React.Component {

  state = {
    questions: [],
    nextUrl: '/results',
    errors: {},
    fein_notice: false,
    email_notice: false,
    email_verifying: false,
    processing: false,
    validation_done: false,
    validation_result: null,
    email_validation_animation_done: false,
    hnoa_rejection: false,
    phone_number_validated: false,
    domain_validated: false,
    today: moment(),
    max_date: null,
    showUnderwriterStatements: false,
    moreThanOneLocation: false,
    numFullTimeEmloyeesMoreThanMaxValue: false,
    registerStatsigUser: false,
    validatedEmail:''
  }

  constructor(props) {
    super(props);

    this.validated_email_ref = React.createRef();
    this.validated_fein_ref = React.createRef();
    this.state.validatedEmail = this.props.answers['validated_email']
    this.state.nextUrl = "/get-insurance" + window.getNextSubSectionPath(this.props.subsections, this.props.next_subsection)
    this.state.max_date = new DateTimeHelper(this.state.today).futureDate(0, 'days')

    this.props.updateProgress({
      current_section: this.props.section.section_index,
      current_subsection: this.props.next_subsection - 1,
    })
    // get subsection questions
    const subsectionQuestions = this.getSubsectionsQuestions(this.props.section)

    // this is a magnificent hardcoded fix for home-base question
    this.state.questions = subsectionQuestions.map(question => {
      if (props.answers['business.location.home'] === "Yes") {
        if (question.qid === 'premises.area_occupied_sf') {
          return {
            ...question,
            tooltip: null,
            question_text: 'What is the square footage portion of your home you exclusively and regularly use for your business?',
          }
        } else if (question.qid === 'premises.is_property_owned') {
          return {
            ...question,
            hidden: true,
          }
        }
      }
      return question
    })

    // subsections[current_subsection + 1]
    // if (this.props.subsections[])

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // TODO: auto update subsections when no for additional insured


    const subsectionQuestions = this.getSubsectionsQuestions(this.props?.section)
    const prevUWStatementQuestion = prevProps.questions.filter(q => q.qid === 'uw.acknowledge_underwriter_statements')[0]
    const curUWStatementQuestion = this.props.questions.filter(q => q.qid === 'uw.acknowledge_underwriter_statements')[0]

    // TODO: Please do NOT delete!
    // TODO: The lines below will be replaced with a check for additional business owners in V2.
    // const prevAdditionalInsuredAnswer = prevProps.answers['business.additional_insured_is_selected']
    // const curAdditionalInsuredAnswer = this.props.answers['business.additional_insured_is_selected']


    if ((prevUWStatementQuestion?.hidden !== curUWStatementQuestion?.hidden)
      || (prevUWStatementQuestion?.disabled !== curUWStatementQuestion?.disabled)
      || (prevUWStatementQuestion?.required !== curUWStatementQuestion?.required)
    ) {
      this.setState({questions: subsectionQuestions})
    }

    if(this.state.validatedEmail !=='' && prevState.validatedEmail !== this.state.validatedEmail ){
      this.setState({registerStatsigUser: true})
    }
  }

  componentDidMount() {

    if (this.props.section.path === '/experience-and-revenue' || this.props.section.path === '/premises-information') {
      if(this.props.answers['coverage.types'].includes('CYBER') && this.props.answers['coverage.types'].length > 1){
        /**
         * Prefill CYBER business.full_time_employees answer the same as premises.full_time_employees
         */
        this.updateAnswer('business.full_time_employees', this.props.answers['premises.full_time_employees'])
      }
    }
    if (this.props.section.path === '/premises-information') {
      /**
       * On component mount, if url path is /premises-information then perform the following:
       * 1. Save building_limit and bpp_limit to props.data_map
       * 2. If premises.is_property_owned === 'Yes', then ensure building limit is set.
       * 3. If premises.is_property_owned === 'No', then ensure building limit is set to 0.
       */
      let data_map = {
        ...this.props.data_map,
        'premises.building_limit': this.props.answers['premises.building_limit'],
        'premises.bpp_limit': this.props.answers['premises.bpp_limit']
      }
      this.props.updateInsStoreKey('data_map', data_map)

      if (this.props.answers['premises.is_property_owned'] === 'Yes') {
        this.updateAnswer('premises.building_limit', data_map['premises.building_limit'])
      } else {
        this.updateAnswer('premises.building_limit', 0)
      }
    }


    /**
     * Our questions show dependently on "coverage.types" answer.
     * So if one quote was success and another was rejected we will do this check
     * and hide rejected quote qualifying questions
     */
    if (this.props.section.path === '/qualifying-questions') {
      const successCoverageTypes = this.props.answers['selected_quotes']
        .filter(itm => !itm?.error)
        .map(itm => itm.quote_type)

      let checkNeeded = this.props.answers['coverage.types'].every(element => {
        return successCoverageTypes.indexOf(element) !== -1;
      });

      if(!checkNeeded){
        let qualifyingQuestionsBasedOnSuccessQQCoverageType = this.state.questions.filter(item=>item.lobs.some(r=> successCoverageTypes.includes(r)))
        this.setState({questions: qualifyingQuestionsBasedOnSuccessQQCoverageType})
      }
    }


    if (this.props.location.pathname === '/get-insurance/underwriter-statements'
      && this.props.underwriterStatements?.statementsList?.length > 0
      && this.props.underwriterStatements?.statementsList !== 'error'
    ) this.setState({showUnderwriterStatements: true})


    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  getNoProceedNotifyContent(){
    let answers = this.props.answers
    if(answers['premises.full_time_employees'] > 50 ){
       return "Thank you! Due to your company size, our customer experience team will reach out directly to help you with your quote. You may also contact our customer experience team below:"
    }else if(answers['business.locations'] === 'Multi'){
       return "Thank you! Since you have more than one location, our customer experience team will reach out directly to help you with your quote."
    }
  }
  convertSectionNextButton(nextSection) {
    /**
     *
     * Check next subsection path and return value for next button
     *
     **/
    if (nextSection) {
      switch (nextSection.path) {
        case '/industry-class':
          return 'Business Type'
        case '/select-your-policy-type':
          return 'Coverage Choices'
        case '/experience-and-revenue':
          return 'Business Details'
        case '/business-locations':
          return 'Business location'
        case '/premises-information':
          return 'Business Location'
        case '/owners-and-officers':
          return 'Owner/Officer Info'
        case '/contact-information':
          return 'Contact Info'
        case '/select-your-rate':
          return 'Coverage Decision'
        case '/customize-rate':
          return 'Customize Rate'
        case '/qualifying-questions':
        case '/additional-property-information':
        case '/previous-insurer':
          return 'Qualifying Questions'
        case '/underwriter-statements':
          return 'Qualifying Questions'
        case '/create-an-account':
          return 'Payment Details'
        case '/checkout':
          return 'Sign In'
        case '/request-your-quote':
          return 'Submit Payment'
        default:
          return nextSection.name;
      }
    }

  }

  getNextSectionButtonText(current_subsection, subsections) {
    let nextSection = subsections[current_subsection + 1]

    let text = this.convertSectionNextButton(nextSection)

    this.props.setNexSectionButtonText(text)
  }

  async updateAnswer(key, value) {
    if (key === 'premises.is_property_owned' && value === 'No') {
      this.props.changeAnswers({
        'premises.building_limit': 0,
        [key]: value
      })
    } else if (key === 'business.start_date') {
      await this.props.changeAnswers({
        [key]: value
      })
      let industryYears = industryYearsCalc(this.props.answers)
      this.props.changeAnswers({
        'business.years_in_industry': industryYears
      })
    } else if (key === 'premises.is_property_owned' && value === 'Yes') {
      this.props.changeAnswers({
        'premises.building_limit': this.props.data_map['premises.building_limit'],
        'premises.bpp_limit': this.props.data_map['premises.bpp_limit'],
        [key]: value
      })
    } else if (key === 'business.additional_insured_is_selected') {
      this.props.changeAnswers({
        [key]: value
      })
    } else {
      this.props.changeAnswers({
        [key]: value
      })
    }
  }

  getSubsectionsQuestions(section) {
    return this.props.questions.filter((itm) => {
      return (itm.section === section.section_index && itm.subsection === section.subsection_index)
    })
  }

  validateForm(event) {
    event.preventDefault()

    let errors = {}
    var should_validate_email = false
    var should_validate_fein = false

    this.state.questions.forEach(item => {

      if (item.question_type.toLowerCase() === 'validatedemail')
        should_validate_email = this.props.answers[item.qid]

      if (item.question_type.toLowerCase() === 'fein')
        should_validate_fein = this.props.answers[item.qid]

      if (item.required && !item.hidden
        && !['business.owners.*.last_name',
          'business.owners.*.first_name',
          'business.additional_insured.*.first_name',
          'business.additional_insured.*.last_name',
          'business.additional_insured.*.street_address',
          'business.additional_insured.*.city',
          'business.additional_insured.*.state',
          'business.additional_insured.*.zip_code',
          'business.additional_insured.*.type',
          'business.additional_insured.*.email'
        ].includes(item.qid)) {
        let answer = (typeof this.props.answers[item.qid] === "string")
          ? this.props.answers[item.qid].trim()
          : this.props.answers[item.qid]
        errors = this.validate(item, answer, errors, item.qid)
      }
    })

    this.setState({errors: errors}, async () => {
      if (Object.keys(errors).length === 0) {

        if (should_validate_fein) {
          this.setState({processing: true})
          let status = await this.validateFein()

          if (status === 'invalid') {
            this.setState({
              processing: false,
              fein_notice: true
            })
            return false
          }
        }
        if (!this.state.domain_validated && this.props.section.path === '/experience-and-revenue' && this.props.answers['coverage.types'].includes('CYBER')) {
          this.setState({ processing: true })
          let status = await this.validateDomain(this.props.answers['business.website_url'])
          if (!status) {
            errors['business.website_url'] = 'Must be a valid url or web domain'
            this.setState({
              processing: false,
              errors: errors
            })
            return false
          } else {
            delete errors['business.website_url']
            this.setState({
              processing: false,
              domain_validated: true,
              errors: errors
            })
          }
        }
        if (this.state.phone_number_validated === false && this.props.section.section_index === 0) {
          this.setState({
            processing: true
          })
          let status = await this.validatePhoneNumber(this.props.answers['contact.phone'])

          if (status === 'invalid') {
            errors['contact.phone'] = this.props.t('form.error.incorrect_phone')
            this.setState({
              processing: false,
              errors: errors
            })
            return false
          } else {
            delete errors['contact.phone']
            this.setState({
              processing: false,
              phone_number_validated: true,
              errors: errors
            })
          }
        }

        if (this.props.answers['business.owners_is_selected'] === 'Yes' || this.props.answers['contact.title'] === 'Owner') {
          let updated = [{
            first_name: this.props.answers['contact.first_name'],
            last_name: this.props.answers['contact.last_name']
          }]
          this.props.updatebusinessownerslist(updated)
        }
        if (should_validate_email && this.props.answers['validated_email'] !== should_validate_email) {
          this.setState({
            processing: true,
            email_verifying: true
          }, () => {
            this.validateEmail(should_validate_email)
              .then(result => {
                this.setState({
                  validation_done: true,
                  validation_result: result,
                }, () => {
                  if (this.checkEmailValidationReadiness()) this.takeNextAction()
                  if (result === 'valid' || result === 'unknown') {
                    this.updateAnswer('validated_email', should_validate_email)
                    this.setState({registerStatsigUser: should_validate_email})
                    datadogRum.setUser({
                      session_id: this.props["session_id"],
                      name: `${this.props["answers"]["contact.first_name"]} ${this.props["answers"]["contact.last_name"]}`,
                      email: should_validate_email
                    })

                  }
                })
              })
          })
        } else if(this.props.answers['business.locations'] === 'Multi') {
          this.setState({moreThanOneLocation: true})
        }else if(this.props.answers['premises.full_time_employees'] > 50 && this.props.section.path === '/premises-information') {
          this.setState({numFullTimeEmloyeesMoreThanMaxValue: true})
        } else{
          this.goNext()
        }
      }
    })
  }

  parseUrl(url) {
    const urlRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
    const protocols = ['https', 'http']

    if (protocols.some(protocol => url.includes(protocol))) {
      const domain = new URL(url).hostname
      if (!urlRegex.test(domain)) {
        throw new Error('invalid url or domain')
      } else return domain
    } else if (urlRegex.test(url)) {
      return url
    } else throw new Error('invalid url or domain')
  }

  validate(question, answer, errors, error_key) {
    let q_type = question.question_type.toLowerCase()

    if (question.qid === 'business.start_date') {
      question.date_only_in_past = true
    }

    if (question.qid === 'uw.acknowledge_underwriter_statements' && this.props.answers['uw.acknowledge_underwriter_statements'] !== 'Yes') {
      errors[error_key] = this.props.t('form.error.field_req')
    }

    if (q_type === 'email') {
      if (!/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(answer) || answer.startsWith('bulk+')) {
        errors[error_key] = this.props.t('form.error.incorrect_email')
        return errors
      }
    } else if (q_type === 'validatedemail') {
      if (!/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(answer) || answer.startsWith('bulk+')) {
        errors[error_key] = this.props.t('form.error.incorrect_email')
        return errors
      }
    } else if (q_type === 'currency') {
      if (!/^[\d]{1,10}$/.test(answer)) {
        errors[error_key] = this.props.t('form.error.incorrect_amount')
        return errors
      }
    } else if (q_type === 'phone') {
      if (!/^[\d]{10}$/.test(answer)) {
        errors[error_key] = this.props.t('form.error.incorrect_phone')
        return errors
      }
    } else if (q_type === 'url') {
      try {
        this.parseUrl(answer)
      } catch (e) {
        errors[error_key] = 'Must be a valid url or web domain'
        return errors
      }
    } else if (q_type === 'date') {
      let date = moment(answer).set({
        hours: 12,
        minutes: 1,
        seconds: 0,
      })

      if (!date.isValid()) {
        errors[error_key] = question.qid === 'business.start_date'
          ? this.props.t('form.error.fien_incorrect_date')
          : this.props.t('form.error.incorrect_date', {val: question.question_text})
        return errors
      } else {
        if (question.date_only_in_future) {
          let minDate = moment().add(1, 'days').startOf('day')

          if (date < minDate) {
            errors[error_key] = this.props.t('form.error.date_in_future', {val: question.question_text})
            return errors
          }
        } else if (question.date_only_in_past) {
          let maxDate = moment().add(-1, 'days').endOf('day')

          if (date > maxDate) {
            errors[error_key] = this.props.t('form.error.date_in_past', {val: question.question_text})
            return errors
          }
        }
      }
    } else if (q_type === 'datetime') {
      let date = moment(answer)

      if (!date.isValid()) {
        errors[error_key] = this.props.t('form.error.incorrect_date', {val: question.question_text})
        return errors
      } else {
        if (question.date_only_in_future) {
          let minDate = moment().add(1, 'days').startOf('day')

          if (date < minDate) {
            errors[error_key] = this.props.t('form.error.date_in_future', {val: question.question_text})
            return errors
          }
        } else if (question.date_only_in_past) {
          let maxDate = moment().add(-1, 'days').endOf('day')

          if (date > maxDate) {
            errors[error_key] = this.props.t('form.error.date_in_past', {val: question.question_text})
            return errors
          }
        }
      }
    } else if (this.isCustomQuestion(q_type)) {
      errors = this.validateCustomQuestions(question, errors)
    } else if (answer === undefined
      || answer === null
      || answer === ''
      || Number.isNaN(answer)
    ) {
      errors[error_key] = this.props.t('form.error.field_req')
      return errors
    }

    if (question?.min_length || question?.max_length) {
      let min = question.min_length || null
      let max = question.max_length || null

      if (min && max && min === max && answer.toString().length !== max) {
        errors[error_key] = this.props.t('form.error.to_much_chars', {val: max})
        return errors
      } else if (min && answer.toString().length < min) {
        errors[error_key] = this.props.t('form.error.at_least_chars', {val: min})
        return errors
      } else if (max && answer.toString().length > max) {
        errors[error_key] = this.props.t('form.error.no_more_than_chars', {val: max})
        return errors
      }
    }

    if (question?.max_value) {
      if (q_type === 'datetime' || q_type === 'date') {
        let date = moment(answer).set({
          hours: 12,
          minutes: 1,
          seconds: 0,
        })
        let max_date = moment(question.max_value)

        if (date.diff(max_date, 'days') > 0) {
          errors[error_key] = this.props.t('form.error.max_permitted_date', {value: max_date.format('MM/DD/YYYY')})
          return errors
        }

      } else if (answer > question.max_value && error_key !== 'premises.payroll') {
        errors[error_key] = this.props.t('form.error.max_permitted_value', {value: question.max_value})
        return errors
      } else if (error_key === 'premises.payroll' && answer > question.max_value) {
        errors[error_key] = this.props.t('form.error.max_payroll_value', {value: formatCurrency(question.max_value)})
      }
    }

    if (question?.min_value) {
      if (q_type === 'datetime' || q_type === 'date') {
        let date = moment(answer)
        let min_date = moment(question.min_value)

        if (date.diff(min_date, 'days') < 0) {
          errors[error_key] = this.props.t('form.error.min_permitted_date', {value: min_date.format('MM/DD/YYYY')})
          return errors
        }

      } else if (answer < question.min_value && error_key !== 'premises.payroll') {
        errors[error_key] = this.props.t('form.error.min_permitted_value', {value: question.min_value})
        return errors
      } else if (error_key === 'premises.payroll' && answer < question.min_value) {
        errors[error_key] = this.props.t('form.error.min_payroll_value', {value: formatCurrency(question.min_value)})
      }
    }

    if (question?.conditions) errors = this.checkConditions(question, errors)
    return errors
  }

  validateCustomQuestions(question, errors) {

    let data_source = this.props.data_map[question.qid] || null

    if (data_source) {
      this.props[data_source].forEach((form, idx) => {
        Object.keys(form).forEach(field => {
          let primary_key = question.qid.replace('_list', '').replace('.is_selected', '')
          let key = [primary_key, idx, field].join('.')
          let wildcard = [primary_key, '*', field].join('.')
          let question_obj = this.props.questions.filter(q => q.qid === wildcard)

          if (question_obj.length > 0 && question_obj[0].required) {
            errors = this.validate(question_obj[0], this.props.answers[key], errors, key)
          }
        })
      })
    } else if (question.qid === 'mpl.coverages.is_selected' && this.props.answers['mpl.coverages.is_selected'] === 'Yes') {

      if (this.props.answers['mpl.coverages.type_of_service'] === undefined
        || this.props.answers['mpl.coverages.type_of_service'] === null
        || this.props.answers['mpl.coverages.type_of_service'] === '')
        errors['mpl.coverages.type_of_service'] = this.props.t('form.error.field_req')

      if (this.props.answers['mpl.coverages.description_of_service'] === undefined
        || this.props.answers['mpl.coverages.description_of_service'] === null
        || this.props.answers['mpl.coverages.description_of_service'] === '')
        errors['mpl.coverages.description_of_service'] = this.props.t('form.error.field_req')

    } else if (question.qid === 'cyber.coverage.is_selected' && this.props.answers['cyber.coverage.is_selected'] === 'Yes') {

      if (this.props.answers['cyber.coverage.aggregate_limit'] === undefined
        || this.props.answers['cyber.coverage.aggregate_limit'] === null
        || this.props.answers['cyber.coverage.aggregate_limit'] === '')
        errors['cyber.coverage.aggregate_limit'] = this.props.t('form.error.field_req')
    } else if (question.qid === 'hnoa.coverage.is_selected' && this.props.answers['hnoa.coverage.is_selected'] === 'Yes') {
      this.props.questions
        .filter((i) => i.qid !== 'hnoa.coverage.is_selected' && i.qid.startsWith('hnoa.coverage'))
        .forEach(q => {
          errors = this.validate(q, this.props.answers[q.qid], errors, q.qid)
        })
    }

    return errors
  }

  isCustomQuestion(type) {
    return (['businessadditionalinsured', 'businessnameandaddress', 'losspayableclauses', 'multipletext', 'checkboxquestionset', 'multipleownerquestionset', 'multipleaddinsuredquestionset']).includes(type.toLowerCase())
  }

  showEmailNotice() {
    this.setState({
      email_notice: true
    })
  }

  closeEmailNotice(focus) {
    this.setState({
      email_notice: false
    }, () => {
      if (focus) {
        setTimeout(() => {
          this.validated_email_ref.current.focus()
        }, 300)
      }
    })
  }

  closeFeinNotice(focus) {
    this.setState({
      fein_notice: false
    }, () => {
      if (focus) {
        setTimeout(() => {
          this.validated_fein_ref.current.focus()
        }, 300)
      }
    })
  }

  async validateEmail(email) {
    return await axios.post('/api/validate-email', {
      email: email
    })
      .then(response => {
        return response.data?.status
      })
      .catch(e => {
          throw new Error(`Can't validate user email. ${e}`);
      })
  }

  async validateDomain(domain) {
    return await axios.post('/api/validate-domain', {
      domain: domain
    }).then(response => {
        return response.data?.valid
      }).catch(e => {
      datadogRum.addError(`Can't validate domain while calling /api/validate-domain. ${e}`);
      return true
      })
  }
  async validateFein() {
    return await axios.post('/api/validate-fein', {
      business_structure: this.props.answers['business.structure'],
      fein: this.props.answers['business.fein']
    })
      .then(response => {
        return response.data?.status
      })
      .catch(e => {
        datadogRum.addError(`Can't validate user fein while calling /api/validate-fein. ${e}`);
        return ''
      })
  }

  checkConditions(q, errors) {
    (q.conditions || []).forEach(c => {
      if (c.action === 'validate') {
        c.conditions.forEach(cond => {
          if (cond.operator === 'sum_between') {
            let sum = 0
            cond.fields.forEach(f => {
              sum += this.props.answers[f]
            })

            if (sum < cond.min || sum > cond.max) errors[q.qid] = this.props.t('form.error.employees_qty', {qty: 50})
          } else if (cond.operator === "array_min_length") {
            if (
              c.operator === "in"
              && this.props.answers.hasOwnProperty(c.key)
              && c.value.includes(this.props.answers[c.key])
            ) {
              if (this.props.hasOwnProperty(cond.key)) {
                if (this.props[cond.key].length < cond.amount) errors[q.qid] = this.props.t('form.error.min_array_entries', {qty: cond.amount})
              }
            }
          }
        })
      }
    })

    return errors
  }

  goBack() {
    let index = this.props.current_subsection - 1
    if (this.props.subsections.length > 0 && index >= 0 && this.props.subsections[index]) {
      let path = "/get-insurance" + window.getPrevSubSectionPath(this.props.subsections, index)
      this.props.history.push(path)
    } else this.props.history.goBack()
  }

  goNext() {
    this.clearEmptyForms()
      .then(() => {
        if (this.state.nextUrl === "/get-insurance/select-your-rate") {
          this.setState({processing: true}, () => {
            this.getAnimationCards()
              .then(() => {
                this.setState({processing: true}, () => {
                  this.props.history.push(this.state.nextUrl)
                })
              })
          })
        } else if (this.state.nextUrl === '/get-insurance/underwriter-statements') {
          if (this.props.underwriterStatements?.statementsList?.length > 0 && this.props.underwriterStatements?.statementsList !== 'error') {
            this.setState({showUnderwriterStatements: true}, () => {
              this.props.history.push(this.state.nextUrl)
            })
          } else this.props.history.push(this.state.nextUrl)

        } else if (this.state.nextUrl === '/get-insurance/additional-insured' && this.props.answers['hnoa.coverage.is_selected'] === 'Yes') {

          if (this.props.answers['hnoa.coverage.uw.non_employee_drivers'] === 'Yes'
            || this.props.answers['hnoa.coverage.uw.own_vehicles_used_on_public_roads'] === 'Yes'
            || this.props.answers['hnoa.coverage.uw.required_to_maintain_vehicles_insurance'] === 'Yes'
            || this.props.answers['hnoa.coverage.uw.you_or_employees_drive_for_business'] === 'Yes') {
            this.setState({hnoa_rejection: true})
          } else {
            this.props.history.push(this.state.nextUrl)
          }
        } else this.props.history.push(this.state.nextUrl)
      })
  }

  hnoaRejectionContinue() {
    this.props.changeAnswers({
      'hnoa.coverage.is_selected': 'No',
    })

    this.setState({hnoa_rejection: false}, () => {
      this.goNext()
    })
  }

  shouldHideQuestion(qid) {
    if (qid === 'policy.medical_limit') return !includes(this.props.bop_limit_available, 'medical_limit');
    else if (qid === 'mpl.coverages') return !includes(this.props.bop_limit_available, 'mpl');
    /**
     * Hide business.full_time_employees question on the /experience-and-revenue page if coverage.types contains even one value from ['BOP','PL','GL'].
     * The premises.full_time_employees question will be handled on the /premises-information page and also will fill business.full_time_employees for CYBER
     **/
    else if (qid === 'business.full_time_employees') return this.props.answers['coverage.types'].some(item => ['BOP','PL','GL'].includes(item))

    return false
  }

  getCellSize(qid, selectedCol, Col) {
    let key = qid.split('.')
    key = key[key.length - 1]
    return (includes(['blanket_insured', 'designated_person',
        'managers_or_lessors_premises', 'mortgagee_or_receiver',
        'mortgageholder_information', 'loss_payable_clauses',
        'wtrr_against_others', 'tria_accepted'], key)
      || qid === 'mpl.coverages.is_selected'
      || qid === 'eb.coverage.is_selected'
      || qid === 'cyber.coverage.is_selected'
      || qid === 'hnoa.coverage.is_selected'
      || qid === 'policy.hassle_free_policy_cancelation'
      || qid === 'uw.acknowledge_underwriter_statements'
    ) ? selectedCol : Col

  }

  getAName(qid) {
    return 'update' + qid.replace('.is_selected', '').replaceAll(/[._]/g, '').toLowerCase()
  }

  async clearEmptyForms() {
    this.state.questions.forEach(q => {
      let data_object = this.props[this.props.data_map[q.qid]]
      if (data_object) {
        let updated = [...data_object]
        data_object.forEach((item, idx) => {
          let data = ''
          Object.keys(item).forEach(line => {
            data += item[line]
          })
          if (data === '') updated.splice(idx, 1)
        })
        this.props[this.getAName(q.qid)](updated)
      }
    })
    return true
  }

  takeNextAction () {
    this.setState({
      processing: false,
      email_verifying: false
    }, () => {
      if (this.state.validation_result === 'invalid') {
        this.showEmailNotice()
      } else if (this.props.answers['business.locations'] === 'Multi'
                && (this.state.validation_result === 'valid' || this.state.validation_result === 'unknown')) {
        this.setState({moreThanOneLocation: true})
      } else {
        this.goNext()
      }
    })
  }

  checkEmailValidationReadiness() {
    return this.state.validation_done
    // && this.state.email_validation_animation_done
  }

  async getAnimationCards() {
    return await axios.get('/api/get-animation-cards')
      .then(response => {
        this.props.updateInsStoreKey('loading_cards', response.data)
        return true
      })
      .catch(e => {
        datadogRum.addError(`Can't load animation cards while calling /api/get-animation-cards. ${e}`);
        return true
      })
  }

  getInputRef(qid) {
    switch (qid) {
      case 'contact.email':
        return this.validated_email_ref
      case 'business.fein':
        return this.validated_fein_ref
      default:
        return null
    }
  }

  getHeadContent(section) {
    switch (section.toString().toLowerCase()) {
      case 'contact information':
        return <SectionHead
          title={`Welcome, ${trimBusinessName(this.props.answers['business.name'])}, please enter your contact info.`}
          txt={'We use this information to create your new account, subject to the Privacy Policy and Terms of Use.'}/>
      case 'experience & revenue':
        return <SectionHead
          title={`Now, share a few more details about ${trimBusinessName(this.props.answers['business.name'])}.`}
          txt={'We use this information to try to match your business with the coverage it may need.'}/>
      case 'premises information':
        return <SectionHead
          title={`Tell me about your business location:`}
          txt={
            this.props.answers['business.location.home'] === "Yes" ?
              'Confirm your home based-business location details below.' :
              'Confirm your primary business location and add each additional location below.'
          }/>
      case 'effective dates':
        return <SectionHead
          title={`Effective Dates`}
          txt={''}/>

      case 'optional coverages':
        return <SectionHead
          title={`Would you like to adjust the coverage limits, ${trimBusinessName(this.props.answers['business.name'])}?`}
          txt={'Customizing your coverage limits will help to determine your actual rate.'}/>

      case 'additional property information':
        return <SectionHead
          title={`Just a few final questions while we prepare your policy, ${trimBusinessName(this.props.answers['business.name'])}`}
          txt={'Keep in mind, inaccurate information may void your coverage.'}/>
      case 'previous insurer':
      case 'qualifying questions':
        return <SectionHead
          title={`Just a few final questions while we prepare your policy, ${trimBusinessName(this.props.answers['business.name'])}`}
          txt={'Keep in mind that inaccurate information submitted by you may void or impact your coverage.'}/>
      case 'underwriter statements':
        return <SectionHead
          title={'Please confirm this information.'}
          txt={'Thanks for your help! Our underwriters need this information to get you the best policy.'}/>
      case 'additional insured':
        return <SectionHead
          title={'Tell me about additional insured individuals:'}
          txt={'Enter the information below to add specific insured individuals.'}/>
      default:
        return null
    }
  }

  async validatePhoneNumber(num) {
    let result = 'invalid'
    try {
      await DoRequest({
        url: '/api/validate-phone-number',
        method: 'post',
        body: {
          phoneNumber: num
        },
        onSuccess: (response) => {
          result = response
        }
      })
    } catch (e) {
      result = 'invalid'
      throw new Error(`Can't validate phone number. ${e}`);
    }
    return result
  }


  render() {
    return (
      <div className={'sections_section'}>
        <form autoComplete="off" className={'section_container'} onSubmit={(e) => {
          this.validateForm(e)
        }}>
          {this.getHeadContent(this.props.section.name)}
          {this.state.registerStatsigUser && <StatsigComponent sectionIndex={this.props.section.section_index}
                                                               sessionId={this.props.session_id}
                                                               businessName={this.props.answers['business.name']}
                                                               userEmail={this.props.answers['validated_email']}/>}
          <Grid container spacing={2}>
            {this.state.questions.map((q) => {
              return (q?.hidden || q?.disabled || this.shouldHideQuestion(q.qid) || q?.question_type === 'TextItem') ? null : (
                <Grid item xs={12} key={q.qid}>
                  <Grid container>
                    {
                      this.state.showUnderwriterStatements
                        ? <Grid item xs={12}
                                md={this.getCellSize(q.qid, 12, 8)}
                                lg={this.getCellSize(q.qid, 12, 6)}>
                          <UnderwriterStatements
                            underwriterStatements={this.props.underwriterStatements}
                          />

                        </Grid>
                        : null
                    }
                    {
                      !q?.qid?.includes('business.additional_insured.')
                        ? <Grid item xs={12}
                                md={this.getCellSize(q.qid, 12, 8)}
                                lg={this.getCellSize(q.qid, 12, 6)}>
                          <Question
                            fullWidth
                            {...q}
                            max_value={q.qid === 'business.start_date' ? this.state.max_date._d : null}
                            inputRef={this.getInputRef(q.qid)}
                            options={q.options}
                            required={q.required}
                            type={q.question_type}
                            question={q.question_text}
                            field_length={q.max_length}
                            answer={this.props.answers[q.qid]}
                            error={this.isCustomQuestion(q.question_type) ? this.state.errors : !!this.state.errors[q.qid]}
                            helperText={this.state.errors?.[q.qid]}
                            onChange={(id, value) => this.updateAnswer(q.qid, value)}
                          />
                        </Grid>
                        : null
                    }
                  </Grid>
                </Grid>
              )
            })}
          </Grid>

          <Grid container className={'section_navigation is_back_btn'}>
            {this.state.processing ? (
              <LoadingButton/>
            ) : (<>
                <button className="section_back_btn"
                        type={'button'}
                        onClick={() => {
                          this.goBack()
                        }}>
                  <span className={'a_btn a_btn_transparent'}>
                    <ArrowBack/>
                  </span>
                  <span className="a_mob_back">Back</span>
                </button>
                <NextSectionButton
                  validateFormMethod={(e) => this.validateForm(e)}
                  className="section_next_btn"/>
                <NextSectionButton
                  validateFormMethod={(e) => this.validateForm(e)}
                  className="section_next_btn__mobile"/>
              </>
            )}
          </Grid>
        </form>
        <InvalidEmail is_open={this.state.email_notice}
                      email={this.props.answers['contact.email']}
                      onClose={(focus = false) => {
                        this.closeEmailNotice(focus)
                      }}/>

        <NotAllowProceedingNotify
          partnerData={this.props.partnerData}
          notifyContent={{description: this.getNoProceedNotifyContent()}}
                                  open={this.state.moreThanOneLocation || this.state.numFullTimeEmloyeesMoreThanMaxValue}/>

        <HNOArejection is_open={this.state.hnoa_rejection}
                       onContinue={() => this.hnoaRejectionContinue()}
                       onClose={() => this.setState({hnoa_rejection: false})}/>

        <InvalidFEIN is_open={this.state.fein_notice}
                     onClose={(focus = false) => {
                       this.closeFeinNotice(focus)
                     }}/>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    partnerData: state.insurance.partnerData,
    quotes: state.insurance.quotes,
    answers: state.insurance.answers,
    data_map: state.insurance.data_map,
    subsections: state.insurance.subsections,
    legal_entities: state.insurance.legal_entities,
    questions: state.insurance.subsections_questions,
    designated_person: state.insurance.designated_person,
    current_subsection: state.insurance.current_subsection,
    wtrr_against_others: state.insurance.wtrr_against_others,
    bop_limit_available: state.insurance.bop_limit_available,
    loss_payable_clauses: state.insurance.loss_payable_clauses,
    mortgagee_or_receiver: state.insurance.mortgagee_or_receiver,
    mortgageholder_information: state.insurance.mortgageholder_information,
    is_session_restored: state.insurance.is_session_restored,
    is_effective_date_updated: state.insurance.is_effective_date_updated,
    managers_or_lessors_premises: state.insurance.managers_or_lessors_premises,
    business_owners: state.insurance.business_owners,
    business_additional_insured: state.insurance.business_additional_insured,
    underwriterStatements: state.insurance.underwriterStatements,
    session_id: state.insurance.unique_session_id
  }
}

const mapDispatchToProps = {
  changeAnswers,
  updateProgress,
  updateInsStoreKey,
  updateaoidesignatedperson,
  updateaoiwtrragainstothers,
  updateaoilosspayableclauses,
  updateaoimortgageeorreceiver,
  updateaoimanagersorlessorspremises,
  updateaoimortgageholderinformation,
  updatebusinessownerslist,
  updatebusinessadditionalinsuredlist,
  updateAdditionalInsuredQuestions,
  setNexSectionButtonText,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Section))
