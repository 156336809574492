import React from "react";

export const CC = (props) => (
    <svg width="50" height="35" viewBox="0 0 50 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M43.4912 1.59082H4.89669C3.11098 1.59082 1.61328 3.07605 1.61328 4.8469V30.1529C1.61328 31.9809 3.11098 33.409 4.89669 33.409H43.4336C45.2769 33.409 46.717 31.9238 46.717 30.1529V4.8469C46.7746 3.07605 45.2769 1.59082 43.4912 1.59082ZM44.528 30.1529C44.528 30.7242 44.0672 31.2383 43.4336 31.2383H4.89669C4.32065 31.2383 3.80222 30.7813 3.80222 30.1529V4.8469C3.80222 4.27566 4.26305 3.76154 4.89669 3.76154H43.4336C44.0096 3.76154 44.528 4.21853 44.528 4.8469V30.1529Z"
            fill="black" stroke="black" strokeWidth="0.5"/>
        <path
            d="M40.8414 25.126H19.5281C18.8944 25.126 18.4336 25.6401 18.4336 26.2113C18.4336 26.7826 18.952 27.2967 19.5281 27.2967H40.8414C41.4751 27.2967 41.9359 26.7826 41.9359 26.2113C41.9359 25.6401 41.4175 25.126 40.8414 25.126Z"
            fill="black" stroke="black" strokeWidth="0.5"/>
        <path
            d="M14.1133 25.126H7.1433C6.50966 25.126 6.04883 25.6401 6.04883 26.2113C6.04883 26.7826 6.50966 27.2967 7.1433 27.2967H14.1709C14.8046 27.2967 15.2654 26.7826 15.2654 26.2113C15.2654 25.6401 14.747 25.126 14.1133 25.126Z"
            fill="black" stroke="black" strokeWidth="0.5"/>
        <path
            d="M36.2332 16.3287C39.1134 16.3287 41.4751 13.9866 41.4751 11.1304C41.4751 8.27422 39.1134 5.93213 36.2332 5.93213C33.353 5.93213 30.9912 8.27422 30.9912 11.1304C30.9336 13.9866 33.353 16.3287 36.2332 16.3287ZM36.2332 8.04572C37.9037 8.04572 39.2862 9.4167 39.2862 11.0733C39.2862 12.7299 37.9037 14.1009 36.2332 14.1009C34.5627 14.1009 33.1802 12.7299 33.1802 11.0733C33.1802 9.4167 34.5627 8.04572 36.2332 8.04572Z"
            fill="black" stroke="black" strokeWidth="0.5"/>
    </svg>
)