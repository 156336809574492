import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useEffect, useState } from 'react'
import momentConst from '../../common/constants/momentConst'
import axios from 'axios'
import clsx from 'clsx'
import { connect } from 'react-redux'
import moment from 'moment'
import { includes, isEmpty } from 'lodash'
import { Grid, useTheme } from '@material-ui/core'
import NotAllowProceedingNotify from '../../common/modal/NotAllowProceedingNotify'
import PolicyStartDate from '../../components/form-components/PolicyStartDate'
import GreenCheckmark from '../../components/icons/GreenCheckmark'
import DateTimeHelper from '../../helpers/dateTimeHelper'
import NextSectionButton from '../../components/form-components/NextSectionButton'
import DoRequest from '../../hooks/do-request'
import usePolicyStartDateValidator from '../../hooks/use-policy-start-date-validator'
import '../../scss/v2/_coverage_cards.scss'

//scss
import {
  changeAnswers,
  updateIndustryClasses,
  updateWCIndustryClasses,
  updateInsStoreKey,
  updateProgress,
  updateQuestionsAutofill, updateUWQuestions
} from '../../store/insurance/actions'
import { withTranslation } from 'react-i18next'
import { ArrowBack } from '@material-ui/icons'
import SectionHead from '../../components/insurance/SectionHead'
import { trimBusinessName } from '../../helpers/trimBusinessName'
import LoadingButton from '../../components/LoadingButton'
import CarouselWrapper from '../../components/CarouselWrapper'
import {datadogRum} from "@datadog/browser-rum";

const unselectedCoveragesObject = {
  GL:['CYBER', 'BOP'],
  BOP:['CYBER', 'GL'],
  PL:['CYBER'],
  WC:[],
  CYBER:['GL', 'BOP', 'PL', 'WC'],
}

const SelectPolicy = React.memo(props => {
  // variables
  const today = moment()
  let { answers, filteredCoverageTypes } = props
  const minDate = new DateTimeHelper(today).futureDate(1, 'days')
  const maxDate = new DateTimeHelper(today).futureDate(30, 'days')
  const policy_start_date_question = props.subsections_questions.filter(q => q.qid === 'policy.desired_effective_date')[0]

  // states
  const [date, setDate] = useState(usePolicyStartDateValidator(props, minDate, maxDate))
  const [isValid, setIsValid] = useState(true)
  const [errors, setErrors] = useState({})
  const [nextDisabled, setNextDisabled] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [notPresentAppetite, setNotPresentAppetite] = useState(false)
  const [momentN, setMomentN] = useState(momentConst.YEAR_DAYS_NUM)
  const [momentInc, setMomentInc] = useState(momentConst.DAYS_STR)

  const { t } = props

  const fetchWCIndustryClasses = async () => {
    return await DoRequest({
      url: '/api/classes/v2',
      method: 'post',
      body: {
        payload: {
          level: '10',
          line_of_business: 'WC',
          state: props.answers['business.state']
        }
      },
      onSuccess: (response) => {
        return response.data
      },
      onError: (e) => {throw new Error(`Something went wrong while fetching WC classes. ${e}`)}
    })
  }

  useEffect(()=>{
    const setCarrierFutureDateValues=()=>{

      if(answers['appetite']['BOP']?.includes('Acuity')
        && answers['appetite']?.['BOP'].length === 1
        && answers['coverage.types'].includes('BOP')){
        setMomentN(momentConst.YEAR_NUM)
        setMomentInc(momentConst.YEAR_STR)
      }else{
        setMomentN(momentConst.YEAR_DAYS_NUM)
        setMomentInc(momentConst.DAYS_STR)
      }
    }

    setCarrierFutureDateValues()
  },[answers])

  useEffect(() => {
    const checkDate = (d) => {
      if (d >= minDate.startOf('day') && d <= maxDate.startOf('day')) {
        setIsValid(true)
      } /*
      // TODO check points for this condition
        else if (d > maxDate.startOf('day')) {
        return setIsValid(false)
      }*/ else if (d <= minDate.startOf('day')) {
        return setIsValid(false)
      } else if (d._isValid === false) {
        return setIsValid(false)
      } else {
        setIsValid(true)
      }
    }

    checkDate(date)

  }, [date, minDate, maxDate])


  useEffect(() => {
    if (!!date) {
      if (isValid === true) {
        const momentObj = new DateTimeHelper(date).localTime()
        const policyEndDate = new DateTimeHelper(date).futureDate(momentN, momentInc)
        const policyEndDateLocal = new DateTimeHelper(policyEndDate).localTime()

        props.changeAnswers({
          'policy.desired_effective_date': momentObj,
          'policy.desired_expiration_date': policyEndDateLocal
        })
      }
    }
  }, [isValid, date])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (answers['coverage.types'].length > 0) {
      /**const coverage_types_list = answers['coverage.types'].filter(itm => {
                let ct = coverage_types.filter(cov => cov.lob === itm)

                return includes(ct[0].sold_in, answers['business.state'])
            })
       changeAnswers({
                'coverage.types': answers['coverage.types']
            })*/
      getSections(answers['coverage.types'])

    }
    if(isEmpty(answers['appetite'])) {
      setNotPresentAppetite(true)
    }
    setProgress()

  }, [answers, filteredCoverageTypes, errors]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, []) // ensures scroll will only happen once

  const setProgress = () => {
    props.updateProgress({
      current_section: props.section.section_index,
      current_subsection: props.next_subsection - 1
    })
  }

  const getNextUrl = () => {
    return '/get-insurance' + window.getNextSubSectionPath(props.subsections, props.next_subsection)
  }
  /*
  * CORE 4148
  * NOTE: This is a temporary adjustment until we have developed a flow
  * for customers to both pay us directly and pay through Ascend in one experience.
  *
  * Once we implement Ascend and direct payments in one experience
  * then we need to make unselect feature coverages for BOP and GL
  * */

  const getUnselectCoveragesForAscendOrDirectPurchase = (lob)=>{
    return unselectedCoveragesObject[lob]
  }

  const getUnselectCoverage = (lob) => {
    if (lob === 'GL') {
      return 'BOP'
    } else if (lob === 'BOP') {
      return 'GL'
    } else {
      return null
    }
  }
  const selectPolicy = (policy) => {
    setNextDisabled(true)
    let coverage_types = [...props.answers['coverage.types']]
    let unselectCoverage = process.env.REACT_APP_DOUBLOON_ENABLED|| (window.doubloon_enabled && !window.doubloon_enabled.includes('doubloon_enabled'))
      ? getUnselectCoveragesForAscendOrDirectPurchase(policy.lob)
      : getUnselectCoverage(policy.lob)

    if (!includes(coverage_types, policy.lob)) coverage_types.push(policy.lob)
    else coverage_types = coverage_types.filter(itm => itm !== policy.lob)

    let coveragesToUpdate = process.env.REACT_APP_DOUBLOON_ENABLED|| (window.doubloon_enabled && !window.doubloon_enabled.includes('doubloon_enabled'))
      ?coverage_types.filter(i => !unselectCoverage.includes(i))
      :coverage_types.filter(item => item !== unselectCoverage)
    props.changeAnswers({
      'coverage.types':coveragesToUpdate
    })

    getSections(coveragesToUpdate)
  }

  const getSections = (items) => {
    props.updateSections(items)
    setNextDisabled(!items.length > 0)
    setProgress()
  }

  const validateForm = (e) => {
    e.preventDefault()

    let errors = {}
    if (!props.answers['coverage.types'].length > 0) {
      errors['common'] = props.t('page.sp.no_polices_selected')
    }
    if (isValid === false) {
      errors['common'] = 'Invalid date entered'
    }

    setErrors(errors)

    if (Object.keys(errors).length === 0) {
      setProcessing(true)
      let rate_classes = props.wc_locations[0].rate_classes

      if (props.answers['coverage.types'].includes('WC') && rate_classes.length > 1) {
        fetchWCIndustryClasses().then(res => {
          props.updateWCIndustryClasses(res.data)
          goNext()
        })
      } else {
        goNext()
      }

    }
  }

  const goBack = () => {
    let index = props.current_subsection - 1
    if (props.subsections.length > 0 && index >= 0 && props.subsections[index]) {
      let path = '/get-insurance' + window.getPrevSubSectionPath(props.subsections, index)
      props.history.push(path)
    } else props.history.goBack()
  }

  const goNext = () => {
    const momentObj = new DateTimeHelper(date).localTime()
    const policyEndDate = new DateTimeHelper(date).futureDate(momentN, momentInc)
    const policyEndDateLocal = new DateTimeHelper(policyEndDate).localTime()

    props.changeAnswers({
      'policy.desired_effective_date': momentObj,
      'policy.desired_expiration_date': policyEndDateLocal
    })
    setProcessing(true)
    updateQuestions(props.answers['coverage.types'])
      .catch(e => {
        throw new Error(`Can't retrieve questions. ${e}`)
      })
      .finally(() => {
        setProcessing(false)
        props.history.push(getNextUrl())
      })
  }

  const updateQuestions = async (coverage_types) => {

    let address = ''

    if (props.answers['business.name'] && props.answers['business.zip_code']) {
      address += props.answers['business.street_address'] + ', '
      if (props.answers['business.street_address2']) address += props.answers['business.street_address2'] + ', '
      address += props.answers['business.city'] + ', '
      address += props.answers['business.state'] + ', '
      address += 'USA'
    }

    const response = await axios.post('/api/coverage-type', {
      name: props.answers['business.name'],
      zip: props.answers['business.zip_code'],
      address_str: address,
      phone: props.answers['contact.phone']?.toString() || '',
      website: props.answers['business.website'] || '',
      place_id: props.answers['business.place_id'] || '',
      session_id: props.unique_session_id,
      lob: coverage_types,
      state: props.answers['business.state'],
      class_code: props.answers['b2z_naics_code']
    })

    if (!response.data) {
      // raise an error, try to repeat
      datadogRum.addError(`Can't load carrier-based questions, empty response.`)
    } else {
      props.updateQuestionsClassStateBased(response.data, coverage_types)
      props.updateUWQuestions(response.data)
    }
    return true
  }

  const setCoverageDescription = (item) => {
    let description
    if (!includes(props.answers['coverage.types'], 'GL') && !includes(props.answers['coverage.types'], 'BOP')) {
      description = item.description
    } else {
      if (item.lob === 'BOP' && !includes(props.answers['coverage.types'], item.lob)) {
        description = 'Cannot be purchased with General Liability. Helps provide value by combining property insurance and general liability insurance into a single cost-effective policy.'
      } else if (item.lob === 'GL' && !includes(props.answers['coverage.types'], item.lob)) {
        description = 'Cannot be purchased with a Business Owner\'s Policy. Protects your small business from claims that it caused third-party bodily injuries, property damage, or advertising injury.'
      } else {
        description = item.description
      }
    }

    return description
  }
  const theme = useTheme()
  const isBulletAnimate = useMediaQuery(theme.breakpoints.down(420))

  return (
    <div className={'select_policy_section'}>
      {filteredCoverageTypes.length > 0 ?
        <form autoComplete="off" className={'section_container'} onSubmit={e => {
          validateForm(e)
        }}>
          <SectionHead isHtml
                       title={t('page.sp.title', { value: trimBusinessName(props.answers['business.name']) })}
                       txt={props.isPartner ? 'Select the coverage you want added to your policy and provide a policy start date.' :  t('page.sp.description')}/>
          <Grid container spacing={2} className={'section_form select_coverage_wrapper'}>
            <div className={'coverage_cards_list_cnt'}>
              <CarouselWrapper
                mediaQuery={420}
                dynamicHeight={false}
                centerSlidePercentage={90}
                slides={filteredCoverageTypes.map((item, index) => {
                  const onclick = () => {
                    selectPolicy(item)
                  }
                  const itemDescription = setCoverageDescription(item)

                  const card_classes = clsx({
                    'coverage_disabled': item.isDisabled,
                    'coverage_type': true,
                    'coverage_outlined': true,
                    'coverage_featured': !onclick,
                    'coverage_selected': includes(props.answers['coverage.types'], item.lob)
                  })

                  const bulletPointsClasses = clsx({
                    'bulletsIn': includes(props.answers['coverage.types'], item.lob),
                    'bulletsOut': !includes(props.answers['coverage.types'], item.lob),
                    'noAnimation': isBulletAnimate
                  })

                  return (
                    <div key={index} className={'coverage_card_wrapper'}>
                      <div className={`coverage_card ${card_classes}`}
                           onClick={processing ? null : onclick}>
                        {item.lob === 'GL'
                          ? <span className={'assistant_badge'}>Most Businesses Start Here</span>
                          : null}
                        <GreenCheckmark className={'green_checkmark'}/>
                        <div className="coverage_icon">
                          <item.icon/>
                        </div>
                        <div className="coverage_desc">
                          <p className="coverage_title">
                            <span className={'coverage_name'}>{item.name}</span>
                            {!includes([undefined, null, ''], item?.subName)
                              ? <span
                                className={'coverage_sub_name'}>&nbsp;{item.subName}</span>
                              : null
                            }
                          </p>
                          <p className={'coverage_txt'}>{item.isDisabled ?
                            `We cannot complete your ${item.name} coverage online at this time. Please call (800) 330-1750 to speak with an agent about potential options.`
                            : itemDescription}</p>

                          {/*COVERAGE BULLETS BASE ON CARRIERS*/}
                          {item.bulletPoints && item.bulletPoints.length ? (
                            <div className={`coverage_bullets_cnt ${bulletPointsClasses}`}>
                              <div className="bullets_blk">
                                <p>For businesses with:</p>
                                <ul>
                                  {item.bulletPoints.map((point, key) => (<li key={key}>{point}</li>))}
                                </ul>
                              </div>
                              {item.appetite && <div className={'carrier_logos_blk'}>
                                <p>Carriers :</p>
                                <ul>
                                  {item.appetite.map(logo => (<li><img
                                    src={`https://b2z-public-images.s3.amazonaws.com/acrisure/carrier_logos/${logo.toLowerCase()}.png`}
                                    alt={''}/></li>))}
                                </ul>
                              </div>}
                            </div>
                          ) : null}

                          {item.hint && !item.isDisabled ? (
                            <item.hint/>
                          ) : (<span>&nbsp;</span>)}
                        </div>
                      </div>
                    </div>
                  )
                })}></CarouselWrapper>

              {errors?.common && (<p className={'error_txt error_txt_lg'}>{errors.common}</p>)}
            </div>

            <div className={'policy_start_date_cnt'}>
              <PolicyStartDate
                value={date}
                onChange={setDate}
                minDate={minDate._d}
                maxDate={maxDate._d}
                tooltip={policy_start_date_question.tooltip}
                title={policy_start_date_question.question_text + '*'}/>
            </div>
            <NotAllowProceedingNotify
              notifyContent={{description: "Thank you! Due to your business classification, our customer experience team will reach out directly to help you with your quote, or you can contact our customer experience team below."}}
              open={notPresentAppetite}/>
          </Grid>
          <Grid container className={'section_navigation is_back_btn'}>
            {processing ? (
              <LoadingButton/>
            ) : (<>
                <button className="section_back_btn"
                        type={'button'}
                        onClick={() => {
                          goBack()
                        }}>
                  <span className={'a_btn a_btn_transparent'}>
                    <ArrowBack/>
                  </span>
                  <span className="a_mob_back">Back</span>
                </button>
                <NextSectionButton
                  disabled={nextDisabled}
                  validateFormMethod={validateForm}
                  className="section_next_btn"/>
                <NextSectionButton
                  disabled={nextDisabled}
                  validateFormMethod={validateForm}
                  className="section_next_btn__mobile"/>
              </>
            )}
          </Grid>
        </form>
        :
        <LoadingButton/>
      }
    </div>
  )
})

const mapStateToProps = state => {
  return {
    isPartner: state.insurance.isPartner,
    partnerData: state.insurance.partnerData,
    answers: state.insurance.answers,
    subsections: state.insurance.subsections,
    current_subsection: state.insurance.current_subsection,
    bop_limit_available: state.insurance.bop_limit_available,
    coverage_types: state.insurance.coverage_types,
    wc_industry_classes: state.insurance.wc_industry_classes,
    wc_locations: state.insurance.wc_locations,
    unique_session_id: state.insurance.unique_session_id,
    industry_classes: state.insurance.industry_classes,
    fetched_classes: state.insurance.fetched_industry_classes,
    is_from_bulk: state.insurance.is_from_bulk,
    is_session_restored: state.insurance.is_session_restored,
    is_choose_naics_manually: state.insurance.is_choose_naics_manually,
    filteredCoverageTypes: state.insurance.filteredCoverageTypes
  }
}

const mapDispatchToProps = {
  changeAnswers,
  updateProgress,
  updateIndustryClasses,
  updateInsStoreKey,
  updateQuestionsAutofill,
  updateWCIndustryClasses,
  updateUWQuestions
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectPolicy))
