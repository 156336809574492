import React, {
    useState,
    useEffect,
    createContext
} from 'react'
import CreditCardCVVField from '../creditCard/CreditCardCVVField'
import CreditCardDateField from '../creditCard/CreditCardDateField'
import CreditCardNumberField from '../creditCard/CreditCardNumberField'
import CreditCard from '../creditCard/card.interface'
import {CardContextInterface} from '../creditCard/helper.interfaces'
import {Props} from '../creditCard/helper.types'
import {Grid} from '@material-ui/core'


export const CreditCardDataContext = createContext<CardContextInterface | null>(null)

export const CardProvider = ({children}: Props) => {
    const [cardData, setCardData] = useState<CreditCard | undefined>(undefined)

    return (
        <CreditCardDataContext.Provider value={{cardData, setCardData}}>
            {children}
        </CreditCardDataContext.Provider>
    )
}

const CreditCardInput = ({onChange, errors}: {onChange: (data) => any, errors:any}) => {
    const [focusIndex, setFocusIndex] = useState<Array<boolean>>([])
    const [cardData, setCardData] = useState<CreditCard | undefined>(undefined)

    useEffect(() => {
        onChange(cardData)

    // eslint-disable-next-line
    }, [cardData])

    const leaveField = (index: number) => {
        const indexArray = new Array<boolean>(3).fill(false)
        indexArray[index] = true
        setFocusIndex(indexArray)
    }


    return (
        <CreditCardDataContext.Provider value={{cardData, setCardData}}>
            <Grid item xs={6}>
                <CreditCardNumberField
                    fieldError={errors['cardNumber']}
                    leaveFieldCallback={leaveField}
                    focus={focusIndex[0]}
                    tabIndex={0}
                />
            </Grid>
            <Grid item xs={3}>
                <CreditCardDateField
                    fieldError={errors['cardExpiry']}
                    leaveFieldCallback={leaveField}
                    focus={focusIndex[1]}
                    tabIndex={1}
                />
            </Grid>
            <Grid item xs={3}>
                <CreditCardCVVField
                    fieldError={errors['cardCVC']}
                    focus={focusIndex[2]}
                    tabIndex={2}
                />
            </Grid>
        </CreditCardDataContext.Provider>
    )
}

export default CreditCardInput