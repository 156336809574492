import React from 'react'
import { withTranslation } from 'react-i18next'
import { Box, Grid } from '@material-ui/core'
import SectionHead from '../../components/insurance/SectionHead'
import { BrokenLink } from '../../components/icons/BrokenLink'
import Layout from '../../layouts/insurance/Layout'
import SectionIndicator from '../../components/insurance/SectionIndicator'

const InsuranceErrorBoundary = ({ t }) => {
  const insuranceErrorSidebar = <div
    className="MuiBox-root sections-indicator">
    <div className="stepper"
         style={{ marginTop: '-75px' }}>
      <div
        className="step completed">
        <div className="number">
          <div
            className="glue-top"></div>
          <div
            className="glue-bottom"></div>
          <div
            className="indicator">
            <svg width="20"
                 height="20"
                 viewBox="0 0 20 20"
                 fill="#172152"
                 stroke="#ABBAC7"
                 xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="10"
                cy="10"
                r="8"
                fill="inherit"
                stroke="inherit"
                strokeWidth="4"></circle>
            </svg>
          </div>
        </div>
        <div className="label">
          <div
            className="name">Account
            Info
          </div>
        </div>
      </div>
      <div
        className="step active completed">
        <div className="number">
          <div
            className="glue-top"></div>
          <div
            className="glue-bottom"></div>
          <div
            className="indicator">
            <svg width="20"
                 height="20"
                 viewBox="0 0 20 20"
                 fill="#172152"
                 stroke="#ABBAC7"
                 xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="10"
                cy="10"
                r="8"
                fill="inherit"
                stroke="inherit"
                strokeWidth="4"></circle>
            </svg>
          </div>
        </div>
        <div className="label">
          <div
            className="name">Customize Rate
          </div>
          <div
            className="progress">
            <div
              className="MuiLinearProgress-root MuiLinearProgress-colorPrimary MuiLinearProgress-determinate"
              role="progressbar"
              aria-valuenow="67">
              <div
                className="MuiLinearProgress-bar MuiLinearProgress-barColorPrimary MuiLinearProgress-bar1Determinate"
                style={{ transform: 'translateX(-33%)' }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  return (
    <div className="full-width-container get_notify_container" style={{ height: '100%' }}>
      <Grid container spacing={6} alignContent="stretch"
            style={{ height: '100%', margin: '0 -24px' }}>
        <Layout
          sidebar={<SectionIndicator html_content={insuranceErrorSidebar}/>}
          sections={<div className={'fields_column'}><Box px={{ xs: 3, md: 7 }}>
            <div className={'industry_class_section'}>
              <div className={'section_container'}>
                <SectionHead
                  title={t('page.insurance_error.title')}
                  txt={t('page.insurance_error.desc')}/>
                <Grid container spacing={2}
                      className={'cookies_disabled_content'}>
                  <BrokenLink/>
                </Grid>
              </div>
            </div>
          </Box></div>}
        />
      </Grid>
    </div>
  )
}

export default withTranslation()(InsuranceErrorBoundary)
