import React from "react"
import {connect} from "react-redux";
import {Info} from "../../icons/Info";
import {Plus} from "../../icons/Plus";
import {Trash} from "../../icons/Trash";
import PLDetails from "../../modals/PLDetails";
import {withTranslation} from 'react-i18next';
import CardCommon from './CardCommon';


class CardPL extends CardCommon {

    render() {
        const {t} = this.props
        return (
            <>
                <div className={'fq_card'}>
                    <p className={'fq_card_ttl'}>{t('common.pl_policy')}</p>
                  {this.getPolicyCarrier()}
                  {this.getPolicyPrice()}
                  <div className="fq_card_controls">
                    <button type={'button'}
                            className={'fq_card_btn'}
                            onClick={() => {
                              this.openQuoteDetails()
                            }}>
                      <Info className={'icon_info'}/> <span>{t('common.btn_details')}</span>
                    </button>
                    <button type={'button'}
                            className={'fq_card_btn'}
                            onClick={() => {
                              this.props.policyToggle()
                            }}
                    >{this.props.selected ? (
                      <>
                        <Trash className={'icon_trash'}/> <span>{t('common.btn_remove')}</span>
                      </>
                    ) : (
                      <>
                        <Plus className={'icon_plus'}/> <span>{t('common.btn_add')}</span>
                      </>
                    )}</button>
                  </div>
                </div>
                <PLDetails open={this.state.details}
                           quote={this.props.quote}
                           quotes={this.props.quotes}
                           answers={this.props.answers}
                           price={this.getPolicyPrice()}
                           onClose={() => this.closeQuoteDetails()}/>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        quotes: state.insurance.quotes,
        answers: state.insurance.answers
    }
}

export default connect(mapStateToProps)(withTranslation()(CardPL))
