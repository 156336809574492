import {connect} from 'react-redux'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {
    changeAnswers,
    updateInsStoreKey
} from '../../store/insurance/actions'
import { Box } from '@material-ui/core'
import B2ZLoading from '../../components/B2ZLoading'
import SectionIndicatorItem from '../../components/insurance/SectionIndicatorItem'
import Layout from '../../layouts/insurance/Layout'
import SectionIndicator from '../../components/insurance/SectionIndicator'
import YouAreCovered from '../insurance/YouAreCovered'
import {checkStartPoint} from '../../helpers/CheckStartPoint'

const AfterCheckout = (props) => {

    const [programId] = useState(checkStartPoint('program_id'))
    const [sessionId] = useState(checkStartPoint('session_id'))
    const [loading, setLoading] = useState(true)
    const location = useLocation()

    const insuranceAfterCheckoutSidebar = <div
        className="MuiBox-root jss1385 sections-indicator">
        <div className="stepper"
            style={{ marginTop: '-75px' }}>
            <div
                className="step completed">
                <div className="number">
                    <div
                        className="glue-top"></div>
                    <div
                        className="glue-bottom"></div>
                    <div
                        className="indicator">
                        <svg width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="#172152"
                            stroke="#ABBAC7"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle
                                cx="10"
                                cy="10"
                                r="8"
                                fill="inherit"
                                stroke="inherit"
                                strokeWidth="4"></circle>
                        </svg>
                    </div>
                </div>
                <div className="label">
                    <div className="name">Account Info</div>
                </div>
            </div>
            <div
                className="step active">
                <div className="number">
                    <div
                        className="glue-top"></div>
                    <div
                        className="glue-bottom"></div>
                    <div
                        className="indicator">
                        <svg width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="#172152"
                            stroke="#ABBAC7"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle
                                cx="10" cy="10"
                                r="8" fill="inherit" stroke="inherit" strokeWidth="4"></circle>
                        </svg>
                    </div>
                </div>
                <div className={'label'}>
                    <SectionIndicatorItem completed={false}
                        active={!loading}
                        progress={60}
                        section={{ name: 'Request Your Quote' }}
                        section_index={0}></SectionIndicatorItem>
                </div>
            </div>
        </div>
    </div>

    useEffect(() => {
        if(!programId && !sessionId && !props['isSessionRestored']){
            props.history.replace('/get-insurance')
        }else{
            const queryParams = new URLSearchParams(location.search)

            if (queryParams.has('session_id') && queryParams.has('program_id')) {
                queryParams.delete('session_id')
                queryParams.delete('program_id')
                props.history.replace({
                    search: queryParams.toString(),
                })
            }
            setTimeout(() => {
                setLoading(false)
                props.changeAnswers({'session_complete': true})
                props.updateInsStoreKey('is_session_restored', false)
            }, 5 * 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layout
            sidebar={<SectionIndicator html_content={insuranceAfterCheckoutSidebar}/>}
            sections={<Box px={{ xs: 3, md: 7 }}>
                {loading ? <B2ZLoading
                    quoteType={'FQ'}
                    loading_string={['Finalizing your paperwork...']}/> : <YouAreCovered/>}
            </Box>}
        />
    )
}

const mapStateToProps = state => {
    return {
        isSessionRestored: state.insurance.is_session_restored
    }
}

const mapDispatchToProps = {
    changeAnswers,
    updateInsStoreKey
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AfterCheckout))
