import React from 'react';
import PaymentItem from "./PaymentItem";
import {Link} from "react-router-dom";
import {ArrowLeft} from "../../icons/ArrowLeft";

const Payments = (props) => {
    return (
        <div className={'blk_sidebar payments_history_sidebar portal_card'}>
            <p className="sidebar_ttl">Payment History</p>
            {props.paidPayments.map((payment, i) => (
                <PaymentItem {...payment} key={i}/>)
            )}
            <Link to="/portal/payment-center" className={'go_to_center_link'}>
                <span>Go to Payment Center</span> <ArrowLeft/>
            </Link>
        </div>
    );
}
export default Payments;