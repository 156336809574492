import React from 'react'
import clsx from 'clsx'
import base64 from 'base-64'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {currency, formatCurrency} from '../../../helpers/currency'
import { getLimitValue } from '../../../helpers/display'
import { CircularProgress } from '@material-ui/core'
import GreenCheckmark from '../../icons/GreenCheckmark'
import { GetAnnualPremiumTotal } from '../../../hooks/get-annual-premium-total'
import DoRequest from '../../../hooks/do-request'
import CardCommon from './CardCommon'
import axios from "axios";
import {fromPairs} from "lodash";
import {toCent} from "../../../hooks/get-premium-total";
import LoadingButton from "../../LoadingButton";
import {updateInsStoreKey} from "../../../store/insurance/actions";
import {getInsuranceCarrier} from "../../../helpers/GetInsuranceCarrier";

class CardCYBER extends CardCommon {

  state = {
    cyberDownpayment: null,
    cyberPremium: null,
    isEstimated: false,
    isLoading: true
  }
  componentDidMount() {
    let provider_data = getInsuranceCarrier(this.props.answers, this.props.quote.quote_type)
    this.setState({
      providerName: provider_data?.name,
      providerRating: provider_data?.rating,
      providerInsurer: provider_data?.insurer,
      providerUnderwrittenBy: provider_data?.underwritten_by,
      carrierLogoUrl: provider_data?.logo ? `https://b2z-public-images.s3.amazonaws.com/acrisure/carrier_logos/${provider_data?.logo}.png`: null,
    })
    this.getAscendEstimate()
  }
  getAscendEstimate = async ()=>{
      const premiumCents = toCent(this.props.quote.term_premium)
      axios.post('/api/ascend-finance-estimate', {premium_cents: premiumCents}).then(response => {
        let estimatedPayload = fromPairs(response.data)
        this.setState({
          cyberPremium: estimatedPayload.term_payment_cents/100,
          cyberDownpayment: estimatedPayload.downpayment_cents/100,
          isEstimated: true, isLoading: false})
        this.props.updateInsStoreKey("estimatedPremium", estimatedPayload)
      }).catch(e=>{
        this.setState({isEstimated: false, isLoading:false})
        this.props.updateInsStoreKey("estimatedPremium", null)
        throw new Error(e)
      })
  }
  async downloadQuote (e) {
    e.preventDefault()
    e.stopPropagation()

    let p = this.props
    let q = p.quote

    this.setState({ loadingQuote: true }, async () => {
      const street2 = p.answers['business.street_address2'] ? p.answers['business.street_address2'] + ', ' : ''
      let address = p.answers['business.street_address'] + ', ' +
        street2 +
        p.answers['business.city'] + ', ' +
        p.answers['business.state'] + ' ' +
        p.answers['business.zip_code']

      let payload = {
        'username': p.answers['contact.first_name'],
        'business': p.answers['business.name'],
        'business_address': address,
        'document_date': q.creation_date,
        'effective_date': q.effective_date,
        'expiration_date': q.expiration_date,
        'provider': q.source.toUpperCase() === 'CF' ? 'Crum & Forster (C&F)' : this.state.providerName,
        'provider_insurer': q.source.toUpperCase() === 'CF' ? p.t('common.insurer_cf') : this.state.providerInsurer,
        'provider_underwritten_by': q.source.toUpperCase() === 'CF' ? p.t('common.provider_underwritten_by_cf') : this.state.providerUnderwrittenBy,
        'quote': {
          'number': q.quote_number,
          "term_premium": this.props.cyber_premium_total,
          "per_month": currency(this.props.cyber_premium_total),

          //Limits
          'cyber_aggregate_limit': q.limits.cyber_aggregate_limit.value,
          'cyber_retention_limit': q.limits.cyber_retention_limit.value
        }
      }

      await DoRequest({
        url: '/api/quote/save-quote',
        method: 'post',
        body: {
          strict: true,
          template: 'cyber-quote-letter',
          payload: payload
        },
        onSuccess: (data) => {
          const decodedData = base64.decode(data.data)
          this.sendFile(decodedData, 'CYBER-QUOTE-LETTER')
          this.setState({ downloadError: false })
        },
        onError: (e) => {
          this.setState({ downloadError: true })
          throw new Error(`Can't save user's CYBER quote:. ${e}`)
        }
      }).finally(() => {
        this.setState({ loadingQuote: false })
        this.props.downloadErrCallback({
          type: 'CYBER',
          isError: this.state.downloadError
        })
      })
    })
  }

  render () {
    const { t } = this.props

    let classes = clsx({
      'quote-card': true,
      'selected': !!this.props.selected
    })

    let download = clsx({
      'controls': true,
      'loading': this.state.loadingQuote
    })

    return (
      <div className={classes} onClick={() => this.selectPolicy()}>
        <div className="name">{t('common.cyber_policy')}</div>
        <div className="price">
          {this.state.isLoading
              ? <LoadingButton size={30}/>
              : this.state.isEstimated
                  ? <>
                    {formatCurrency(this.state.cyberPremium, true)}
                    <small
                        style={{fontSize: '1rem', fontWeight: 600}}>/{t('common.month_short')}</small>
                    <small>
                      <p className="additional_txt" style={{fontWeight: 400, marginTop: 0}}>
                        <span><i> * Rate includes financing with 10 payments and a {formatCurrency(this.state.cyberDownpayment, true)} down payment. Pay-in-full option available.</i></span>
                      </p>
                    </small>
                  </>
                  : <>
                    <GetAnnualPremiumTotal props={this.props} lob="cyber"/>
                    <small
                        style={{fontSize: '1rem', fontWeight: 600}}>/{t('common.year_short')}</small>
                    <small>
                      <p className="additional_txt" style={{fontWeight: 400, marginTop: 0}}>
                        <span><i> * Monthly payment options available.</i></span>
                      </p>
                    </small>
                  </>}

        </div>
        <div className="provider">
          <div className="insurer">{this.state.providerName}</div>
          <div className="rate">{this.state.providerRating}</div>
          {this.state.carrierLogoUrl &&
            <div className={'carrier_logo'}><img src={this.state.carrierLogoUrl} alt=""/></div>}
        </div>
        <div className="is_selected" title={t('common.is_selected')}>
          <GreenCheckmark/>
        </div>
        <div className="limits">
          <div className="limit">
            <div className="name">{this.props.quote.limits.cyber_aggregate_limit.display_name}</div>
            <div className="value">{getLimitValue(this.props.quote.limits.cyber_aggregate_limit)}</div>
          </div>
          <div className="limit">
            <div className="name">{this.props.quote.limits.cyber_retention_limit.display_name}</div>
            <div className="value">{getLimitValue(this.props.quote.limits.cyber_retention_limit)}</div>
          </div>
        </div>
        <div className={download}>
          {this.state.loadingQuote ? (
            <span className={'downloading_process'}>
                        <CircularProgress size={14}/>
                        <span>{t('common.downloading')}</span>
                      </span>
          ) : (
            <button type={'button'}
                    className={'download_quote_btn'}
                    onClick={e => this.downloadQuote(e)}>{t('common.btn_download_quote')}</button>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    answers: state.insurance.answers,
    cyber_limit_available: state.insurance.cyber_limit_available,
    cyber_premium_total: state.insurance.cyber_premium_total,
    quotes: state.insurance.quotes
  }
}
const mapDispatchToProps = {
  updateInsStoreKey
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CardCYBER))
