import React from "react";
import {withTranslation} from 'react-i18next';
import {formatCurrency} from "../../../helpers/currency";
import {DeductibleLabel} from "./limits/DeductibleLabel";
import {GeneralAggLimitLabel} from "./limits/GeneralAggLimitLabel";
import {ProdCompOpsAggLimitLabel} from "./limits/ProdCompOpsAggLimitLabel";
import {PerOccurrenceLimitLabel} from "./limits/PerOccurrenceLimitLabel";

const GLPolicyDetails = (props) => {
    const {t} = props
    return (<div className="detail_info">
        <p className="policy_coverages">{t('common.coverages')}</p>
        <div className="detail_info_columns">
            <div className="column">
                <div className="detail_info_group">
                    <div className="group_ttl">{t('portal.policy.drb.title')}</div>
                    <div className="group_txt">{t('portal.policy.drb.txt')}</div>
                    <div className="group_list">
                        <div className="list_item">
                            <PerOccurrenceLimitLabel name={t('common.per_occur')}/>
                            <span
                                className="item_value">{formatCurrency(props.quote.limits?.gl_damage_to_premises_rented_to_you?.value || 50000)}</span>
                        </div>
                        <div className="list_item">
                            <DeductibleLabel name={t('common.deductible')}/>
                            <span className="item_value">{formatCurrency(props.quote.limits?.gl_damage_to_premises_rented_to_you_deductible?.value || 50000)}</span>
                        </div>
                    </div>
                </div>
                {props.optional_coverages.hasOwnProperty('hnoa') && (<div className="detail_info_group">
                    <div className="group_ttl">{t('portal.policy.drb.title')}</div>
                    <div className="group_txt">{t('portal.policy.drb.txt')}</div>
                    <div className="group_list">
                        <div className="list_item">
                            <PerOccurrenceLimitLabel name={'Per Occurance Limit'}/>
                            <span
                                className="item_value">{formatCurrency(props.optional_coverages['hnoa'].occurrence_limit)}</span>
                        </div>
                        <div className="list_item">
                            <span className="item_name">Annual Aggregate Limit</span>
                            <span
                                className="item_value">{formatCurrency(props.optional_coverages['hnoa'].annual_aggregate_limit)}</span>
                        </div>
                    </div>
                </div>)}
                {props.optional_coverages.hasOwnProperty('mpl') && (<div className="detail_info_group">
                    <div className="group_ttl">{t('portal.policy.mpl.title')}</div>
                    <div className="group_txt">{t('portal.policy.mpl.txt')}</div>
                    <div className="group_list">
                        {props.optional_coverages['mpl']?.occurrence_limit !== null && (<div className="list_item">
                            <PerOccurrenceLimitLabel name={t('common.per_occur')}/>
                            <span
                                className="item_value">{formatCurrency(props.optional_coverages['mpl'].occurrence_limit)}</span>
                        </div>)}
                        {props.optional_coverages['mpl']?.annual_aggregate_limit !== null && (
                            <div className="list_item">
                                <span className="item_name">{t('common.ann_aggregate')}</span>
                                <span
                                    className="item_value">{formatCurrency(props.optional_coverages['mpl'].annual_aggregate_limit)}</span>
                            </div>)}
                    </div>
                </div>)}

            </div>
            <div className="column">
                <div className="detail_info_group">
                    <div className="group_ttl">{t('portal.policy.liability.title')}</div>
                    <div className="group_txt">{t('portal.policy.liability.txt')}</div>
                    <div className="group_list group_limit_list">
                        <div className="list_item">
                            <PerOccurrenceLimitLabel name={t('common.per_occur')}/>
                            <span
                                className="item_value">{formatCurrency(props.occurrence_limit)}</span>
                        </div>
                        <div className="list_item">
                            <GeneralAggLimitLabel name={t('portal.policy.liability.gen_agg_limit')}/>
                            <span
                                className="item_value">{formatCurrency(props.aggregate_limit)}</span>
                        </div>
                        <div className="list_item">
                            <ProdCompOpsAggLimitLabel name={t('portal.policy.liability.ops_agg_limit')}/>
                            <span
                                className="item_value">{formatCurrency(props.aggregate_limit)}</span>
                        </div>
                    </div>
                    <div className="group_list">
                        <div className="list_item">
                            <DeductibleLabel name={t('common.deductible')}/>
                            <span
                                className="item_value">{t('common.na')}</span>
                        </div>

                    </div>
                </div>
                {props.quote?.limits?.medical_limit && (<div className="detail_info_group">
                    <div className="group_ttl">{props.quote.limits.medical_limit.display_name}</div>
                    <div className="group_txt">{t('portal.policy.ml.txt')}</div>
                    <div className="group_list">
                        <div className="list_item">
                            <PerOccurrenceLimitLabel name={t('common.per_occur')}/>
                            <span
                                className="item_value">{formatCurrency(props.quote.limits.medical_limit?.value || 0)}</span>
                        </div>
                        <div className="list_item">
                            <DeductibleLabel name={t('common.deductible')}/>
                            <span
                                className="item_value">{formatCurrency(0)}</span>
                        </div>
                    </div>
                </div>)}
                {props.optional_coverages.hasOwnProperty('cyber') && (<div className="detail_info_group">
                    <div className="group_ttl">{t('portal.policy.cyber.title')}</div>
                    <div className="group_txt">{t('portal.policy.cyber.txt')}</div>
                    <div className="group_list">
                        <div className="list_item">
                            <span className="item_name">{t('common.ann_aggregate')}</span>
                            <span
                                className="item_value">{formatCurrency(props.optional_coverages['cyber'].annual_aggregate_limit)}</span>
                        </div>
                    </div>
                </div>)}

            </div>
        </div>
    </div>)
}

export default withTranslation()(GLPolicyDetails)