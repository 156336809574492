import React from 'react'
import {Carousel} from 'react-responsive-carousel'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const CarouselWrapper = ({slides, mediaQuery = 420, centerSlidePercentage = 80, dynamicHeight = true}:
                             { slides: any, mediaQuery: number, centerSlidePercentage: number, dynamicHeight:boolean }) => {
    /*
    * Wrapper for switch to carousel on mobile mode
    * and  simple markup for desktop mode
    *
    * @slides - list of items
    * @mediaQuery - value of window width. If window width will be less than this value
    * we will switch and use carousel
    * */

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(mediaQuery))
    return (
        <>
            {isMobile ?
                <Carousel
                    className={'mobile_mode'}
                    axis="horizontal"
                    dynamicHeight={dynamicHeight}
                    interval={5000}
                    centerMode={true}
                    centerSlidePercentage={centerSlidePercentage}
                    showArrows={false}
                    showIndicators={true}
                    showThumbs={false}
                    autoPlay={false}
                    showStatus={false}>
                    {slides}
                </Carousel> :
                <div className={'desktop_mode'}>{slides}</div>}

        </>
    )
}

export default CarouselWrapper