import React, {useState} from 'react'
import {AutofilledBadge} from './AutofilledBadge'
import {FormControl, TextField} from '@material-ui/core'

const URLInput = ({
    props,
    id,
    fullWidth,
    tooltip,
    autofilled,
}: {
    props: any,
    id: string,
    fullWidth: boolean,
    tooltip: string,
    autofilled: boolean
}) => {

    const onChange = (e) => {
        const validatedUrl = getUrl(e.target.value)
        setUrl(e.target.value)
        if (props.onChange) {
            props.onChange(id, validatedUrl)
        }
    }

    const parseUrl = (url: string) => {
        const urlRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
        const protocols = ['https', 'http']

        if (protocols.some(protocol => url.includes(protocol))) {
            const domain = new URL(url).hostname
            if (!urlRegex.test(domain)) {
                return url
            } else return domain
        } else if (urlRegex.test(url)) {
            return url
        } return url
    }

    const getUrl = (answer) => {
        try {
            return parseUrl(answer)
        } catch (e) {
            return ''
        }
    }

    const [url, setUrl] = useState(getUrl(props.answer))

    return (
        <FormControl fullWidth={fullWidth} className='form_control' error={props.error}>
            <div className='label_blk'>
                <div className='label'><span>{props.question}</span> &nbsp;{tooltip} </div>
                {autofilled && <AutofilledBadge/>}
            </div>
            <TextField
                id={id}
                variant='outlined'
                type='url'
                className='fieldText'
                {...props}
                value={url}
                error={!!props.error}
                placeholder={props.placeholder}
                helperText={null}
                onChange={(e) => {onChange(e)}}
            />
            { props.error && <p className='error_txt error_txt_md'>{props.helperText}</p> }
        </FormControl>
    )
}

export default URLInput
