import React from "react";
import {connect} from "react-redux";
import {changeAnswers, updateBusinessOwners as updatebusinessownerslist} from "../../../../store/insurance/actions";
import Question from "../../Question";
import MultipleForms from "./MultipleForms";
import {withTranslation} from "react-i18next";
import {AutofilledBadge} from "../AutofilledBadge";
import {FormControl, Grid} from "@material-ui/core";
//scss
import '../../../../scss/v2/_q_custom.scss'

class MultipleOwnerQuestionSet extends MultipleForms {

    componentDidMount() {
        super.componentDidMount();

        if (this.props[this.getDName(this.props.qid)].length === 0) {
            this.onChange(true)
        }

        if (this.props.answers['business.owners_is_selected'] === 'No') {
            let updated = [{
                first_name: '',
                last_name: ''
            }]
            this.props[this.getAName(this.props.qid)](updated)
        }
        if (this.props.answers['business.owners_is_selected'] === 'No' && this.props.business_owners[0] !== undefined) {
            let updated
            if (this.props.answers['contact.first_name'] === this.props.business_owners[0].first_name
                && this.props.answers['contact.last_name'] === this.props.business_owners[0].last_name) {
                updated = [{
                    first_name: '',
                    last_name: ''
                }]
            } else {
                updated = [{
                    first_name: this.props.business_owners[0].first_name,
                    last_name: this.props.business_owners[0].last_name
                }]
            }
            this.props[this.getAName(this.props.qid)](updated)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.answers['business.owners_is_selected'] === 'No' && prevProps.answers['business.owners_is_selected'] !== 'No') {
            let updated = [{
                first_name: '',
                last_name: ''
            }]
            this.props[this.getAName(this.props.qid)](updated)
        }
    }

    clearEmptyForms() {
        return true
    }

    render() {
        return (
            <>
                {(this.props.answers['business.owners_is_selected'] === 'No')
                && <FormControl fullWidth={this.props.fullWidth} error={this.hasError()} className={'owner_questions'}>
                    <Grid container spacing={2} justifyContent="space-between" wrap="nowrap">
                        <Grid item><p className={'owns_title'}>{this.props.question}&nbsp;{this.props.tooltip}</p></Grid>
                        {this.props.autofilled && <Grid item><AutofilledBadge/></Grid>}
                    </Grid>
                    <Grid container spacing={2} className={'is_owner_question_item'}>

                        {this.props[this.getDName(this.props.qid)].map((f, index) => {
                                    return (
                                        // <Grid item xs={12} key={index} style={{flexWrap: 'wrap'}}>

                                          this.state.questions.filter(i => i.qid !== "business.owners_is_selected").map((q) => {
                                                        return (
                                                            <Grid item xs={12} md={6} key={q.qid} className={'own_item'}>
                                                                <Question
                                                                    fullWidth
                                                                    {...q}
                                                                    options={q.options}
                                                                    required={q.required}
                                                                    type={q.question_type}
                                                                    question={q.question_text}
                                                                    field_length={q.max_length}
                                                                    answer={this.props.answers[this.getKey(q.qid, index)]}
                                                                    error={!!this.props.error?.[this.getKey(q.qid, index)]}
                                                                    helperText={this.props.error?.[this.getKey(q.qid, index)]}
                                                                    onChange={(id, value) => this.updateAnswer(q.qid, index, value)}
                                                                    onChangeLocation={data => this.updateLocation(index, data)}
                                                                />
                                                            </Grid>)
                                                    })

                                        // </Grid>
                                    )
                                })}

                    </Grid>
                    {this.hasError() && <div>
                        {Object.keys(this.props.error).map((key, idx) => (
                            <p key={idx} className="error_txt error_tx_md">{this.props.error[key]}</p>))}
                    </div>}
                </FormControl>}
            </>
        )
    }
}

const
    mapStateToProps = state => ({
        data_map: state.insurance.data_map,
        answers: state.insurance.answers,
        business_owners: state.insurance.business_owners,
        business_owners_template: state.insurance.business_owners_template,
        subsections_questions: state.insurance.subsections_questions
    })

const
    mapDispatchToProps = {
        changeAnswers,
        updatebusinessownerslist
    }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MultipleOwnerQuestionSet))