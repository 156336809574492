import React from "react";
import {includes} from "lodash";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import Loading from "../../components/Loading";
import {GoogleApiWrapper} from "google-maps-react";
import {ArrowBack, Close} from "@material-ui/icons";
import {updateProgress, updateWCLocations} from "../../store/insurance/actions";
import {Box, Button, Container, Dialog, Grid, Slide, Typography} from "@material-ui/core";
import NextSectionButton from "../../components/form-components/NextSectionButton";
import SectionHead from "../../components/insurance/SectionHead";
import {trimBusinessName} from "../../helpers/trimBusinessName";
//scss
import '../../scss/v2/_wc_custom.scss'
import WCLocation from "../../components/insurance/WCLocation";

import { unitedStates } from '../../common/UnitedStates'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

class WCLocations extends React.Component {

    state = {
        place_service: null,
        sessionToken: null,
        states: unitedStates,
        errors: {},
        disclaimer: false,
        rejected_states: []
    }

    constructor(props) {
        super(props);

        this.state.nextUrl = "/get-insurance" + window.getNextSubSectionPath(this.props.subsections, this.props.next_subsection)

        this.validateForm = this.validateForm.bind(this)

        this.props.updateProgress({
            current_section: this.props.section.section_index,
            current_subsection: this.props.next_subsection - 1,
        })

        this.details_ref = React.createRef()
    }

    componentDidMount() {
        this.setState({
            place_service: new this.props.google.maps.places.PlacesService(this.details_ref.current),
            sessionToken: new this.props.google.maps.places.AutocompleteSessionToken()
        })
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    validateForm(event) {
        event.preventDefault()

        let errors = {},
            primary_count = 0

        this.props.wc_locations.forEach((itm, key) => {
            if (itm.primary) primary_count++

            if (!/^.{2,}$/.test(itm.street_address))
                errors[key + '.street_address'] = this.props.t('form.error.st_address_req')

            if (!/^.{2,}$/.test(itm.city))
                errors[key + '.city'] = this.props.t('form.error.city_req')

            if (!/^.{2,}$/.test(itm.state))
                errors[key + '.state'] = this.props.t('form.error.state_req')

            if (!/^.{2,}$/.test(itm.zip_code))
                errors[key + '.zip_code'] = this.props.t('form.error.zip_req')

            if (!/^[\d]{1,5}$/.test(itm.full_time_employees))
                errors[key + '.full_time_employees'] = this.props.t('form.error.employee_num_req')

            itm.rate_classes.forEach((i, k) => {
                if ( k === 0 && i.payroll_amount === 0)
                    errors[`${key}.rate_classes.${k}.payroll_amount`] = this.props.t('page.pi.payroll_not_zero')

                if (!/^.{2,}$/.test(i.class_code_description) || !i.class_code_description)
                    errors[`${key}.rate_classes.${k}.class_code_description`] = this.props.t('page.pi.class_code_req')

                if (!/^.{2,}$/.test(i.class_code) || !i.class_code)
                    errors[`${key}.rate_classes.${k}.class_code`] = this.props.t('page.pi.select_a_class')

                if (!/^\d{1,}$/.test(i.payroll_amount))
                    errors[`${key}.rate_classes.${k}.payroll_amount`] = this.props.t('page.pi.payroll_req')

            })
        })

        if (primary_count === 0) errors.common = this.props.t('page.bl.no_primary_loc')
        else if (primary_count > 1) errors.common = this.props.t('form.error.to_many_primary_loc')

        this.setState({errors: errors})

        if (Object.keys(errors).length === 0) {
            let rejected = this.validateLocations()
            if (rejected.length > 0) {
                this.setState({rejected_states: rejected}, () => {
                    this.showStateDisclaimer()
                })
            } else {
                let rate_classes = [...this.props.wc_locations[0].rate_classes]
                let filteredRateClasses = rate_classes.filter(item => item.class_code_description !== "" || item.class_code !== "" || item.payroll_amount !== "")

                let locations = [...this.props.wc_locations]

                locations[0].rate_classes = [
                    ...filteredRateClasses
                ]

                this.props.updateWCLocations(locations)
                this.goNext()
            }
        }
    }

    clearErrors() {
        this.setState({errors: {}})
    }

    validateLocations() {
        let invalid_states = []
        let sold_in = this.props.coverage_types.filter(type => type.type === 'WC')?.[0]?.sold_in || []

        this.props.wc_locations.forEach(loc => {
            if (!includes(sold_in, loc.state)) invalid_states.push(loc.state)
        })

        return invalid_states
    }

    showStateDisclaimer() {
        this.setState({disclaimer: true})
    }

    closeStateDisclaimer() {
        this.setState({disclaimer: false})
    }

    goNext() {
        this.props.history.push(this.state.nextUrl)
    }

    locationRemoved() {
        this.setState({errors: {}})
    }

    goBack() {
        let index = this.props.current_subsection - 1
        if (this.props.subsections.length > 0 && index >= 0 && this.props.subsections[index]) {
            let path = "/get-insurance" + window.getPrevSubSectionPath(this.props.subsections, index)
            this.props.history.push(path)
        } else this.props.history.goBack()
    }

    render() {
        const {t} = this.props

        return (
            <div className="wc_locations_section wc_custom wc-locations">
                <form autoComplete="off" className={'section_container'} onSubmit={this.validateForm} >
                  <SectionHead
                      title={t('page.bl.title', {value: trimBusinessName(this.props.answers['business.name'])})}
                      txt={t('page.bl.description')}/>
                  <Grid container spacing={2} className={'section_form'} >
                          {this.props.wc_locations.map((itm, idx) => {
                              return (
                                  <WCLocation {...itm}
                                                 history={this.props.history}
                                                 key={idx}
                                                 index={idx}
                                                 errors={this.state.errors}
                                                 google={this.props.google}
                                                 g_place={this.state.place_service}
                                                 g_session={this.state.sessionToken}
                                                 states={this.state.states}
                                                 details_ref={this.details_ref}
                                                 clearErrors={() => {
                                                     this.clearErrors()
                                                 }}
                                                 onLocationRemoved={() => {
                                                     this.locationRemoved()
                                                 }}

                                  />
                              )
                          })}
                          <div id="details" ref={this.details_ref}/>
                  </Grid>
                  <Grid container>
                      {this.state.errors?.common && (
                          <p className="common-error-message">{this.state.errors.common}</p>
                      )}
                  </Grid>
                  <Grid container className={'section_navigation is_back_btn'}>
                    <button className="section_back_btn"
                            type={'button'}
                            onClick={() => {
                              this.goBack()
                            }}>
                      <span className={'a_btn a_btn_transparent'}>
                        <ArrowBack/>
                      </span>
                      <span className="a_mob_back">Back</span>
                    </button>
                    <NextSectionButton
                      validateFormMethod={(e) => this.validateForm(e)}
                      className="section_next_btn"/>
                    <NextSectionButton
                      validateFormMethod={(e) => this.validateForm(e)}
                      className="section_next_btn__mobile"/>
                  </Grid>
                </form>
                <Dialog open={this.state.disclaimer}
                        container={() => document.getElementById('themeAppComponent')}
                        fullWidth
                        maxWidth="xs"
                        onClose={(e) => {
                            this.closeStateDisclaimer()
                        }}
                        TransitionComponent={Transition}>
                    <Container>
                        <Box pt={1} pb={2}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Button variant="text" color="primaryDark"
                                            style={{marginLeft: '-16px', minWidth: '32px'}}
                                            onClick={() => {
                                                this.closeStateDisclaimer()
                                            }}><Close/> {t('common.btn_cancel')}</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{t('page.bl.not_offer', {value: this.state.rejected_states.join(', ')})}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    *<Button variant="contained"
                                             color="primary"
                                             size="medium"
                                             onClick={() => {
                                                 this.closeStateDisclaimer()
                                             }}
                                >{t('common.btn_continue')}</Button>

                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    google_api_key: state.app.google_api_key,
    subsections: state.insurance.subsections,
    current_subsection: state.insurance.current_subsection,
    wc_locations_template: state.insurance.wc_locations_template,
    wc_locations: state.insurance.wc_locations,
    coverage_types: state.insurance.coverage_types,
})

const mapDispatchToProps = {
    updateProgress,
    updateWCLocations
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper(
    (props) => {
        return {
            apiKey: props.google_api_key,
            language: 'us',
            libraries: ['places'],
            region: 'US',
            LoadingContainer: Loading
        }
    })(withTranslation()(WCLocations)));